export const PROVINCE = [
  {
    id: 1,
    name: "Hà Nội",
    region: "north",
    created_at: "2024-01-28T17:20:42.137Z",
    updated_at: "2024-10-29T10:15:06.378Z",
    lat: 21.0284793,
    lon: 105.8500046,
    deleted: null,
    slug: "ha-noi",

    district: [
      {
        id: 1,
        name: "Ba Đình",
        province_id: 1,
        created_at: "2024-01-28T17:20:42.975Z",
        updated_at: "2024-10-29T10:15:06.845Z",
        popular: true,
        slug: "ba-dinh",
        deleted: null,
      },
      {
        id: 2,
        name: "Hoàn Kiếm",
        province_id: 1,
        created_at: "2024-01-28T17:20:42.991Z",
        updated_at: "2024-10-29T10:15:06.852Z",
        popular: true,
        slug: "hoan-kiem",
        deleted: null,
      },
      {
        id: 3,
        name: "Tây Hồ",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.003Z",
        updated_at: "2024-10-29T10:15:06.857Z",
        popular: true,
        slug: "tay-ho",
        deleted: null,
      },
      {
        id: 4,
        name: "Long Biên",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.016Z",
        updated_at: "2024-10-29T10:15:06.862Z",
        popular: true,
        slug: "long-bien",
        deleted: null,
      },
      {
        id: 5,
        name: "Cầu Giấy",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.033Z",
        updated_at: "2024-10-29T10:15:06.870Z",
        popular: true,
        slug: "cau-giay",
        deleted: null,
      },
      {
        id: 6,
        name: "Đống Đa",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.046Z",
        updated_at: "2024-10-29T10:15:06.876Z",
        popular: true,
        slug: "dong-da",
        deleted: null,
      },
      {
        id: 7,
        name: "Hai Bà Trưng",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.060Z",
        updated_at: "2024-10-29T10:15:06.883Z",
        popular: true,
        slug: "hai-ba-trung",
        deleted: null,
      },
      {
        id: 8,
        name: "Hoàng Mai",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.076Z",
        updated_at: "2024-10-29T10:15:06.890Z",
        popular: true,
        slug: "hoang-mai",
        deleted: null,
      },
      {
        id: 9,
        name: "Thanh Xuân",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.087Z",
        updated_at: "2024-10-29T10:15:06.896Z",
        popular: true,
        slug: "thanh-xuan",
        deleted: null,
      },
      {
        id: 16,
        name: "Sóc Sơn",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.100Z",
        updated_at: "2024-10-29T10:15:06.901Z",
        popular: false,
        slug: "soc-son",
        deleted: null,
      },
      {
        id: 17,
        name: "Đông Anh",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.116Z",
        updated_at: "2024-10-29T10:15:06.905Z",
        popular: false,
        slug: "dong-anh",
        deleted: null,
      },
      {
        id: 18,
        name: "Gia Lâm",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.129Z",
        updated_at: "2024-10-29T10:15:06.913Z",
        popular: false,
        slug: "gia-lam",
        deleted: null,
      },
      {
        id: 19,
        name: "Nam Từ Liêm",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.143Z",
        updated_at: "2024-10-29T10:15:06.920Z",
        popular: true,
        slug: "nam-tu-liem",
        deleted: null,
      },
      {
        id: 20,
        name: "Thanh Trì",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.155Z",
        updated_at: "2024-10-29T10:15:06.926Z",
        popular: true,
        slug: "thanh-tri",
        deleted: null,
      },
      {
        id: 21,
        name: "Bắc Từ Liêm",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.168Z",
        updated_at: "2024-10-29T10:15:06.930Z",
        popular: true,
        slug: "bac-tu-liem",
        deleted: null,
      },
      {
        id: 250,
        name: "Mê Linh",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.184Z",
        updated_at: "2024-10-29T10:15:06.935Z",
        popular: true,
        slug: "me-linh",
        deleted: null,
      },
      {
        id: 268,
        name: "Hà Đông",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.196Z",
        updated_at: "2024-10-29T10:15:06.939Z",
        popular: true,
        slug: "ha-dong",
        deleted: null,
      },
      {
        id: 269,
        name: "Sơn Tây",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.211Z",
        updated_at: "2024-10-29T10:15:06.943Z",
        popular: false,
        slug: "son-tay",
        deleted: null,
      },
      {
        id: 271,
        name: "Ba Vì",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.223Z",
        updated_at: "2024-10-29T10:15:06.947Z",
        popular: false,
        slug: "ba-vi",
        deleted: null,
      },
      {
        id: 272,
        name: "Phúc Thọ",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.238Z",
        updated_at: "2024-10-29T10:15:06.952Z",
        popular: false,
        slug: "phuc-tho",
        deleted: null,
      },
      {
        id: 273,
        name: "Đan Phượng",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.250Z",
        updated_at: "2024-10-29T10:15:06.956Z",
        popular: false,
        slug: "dan-phuong",
        deleted: null,
      },
      {
        id: 274,
        name: "Hoài Đức",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.264Z",
        updated_at: "2024-10-29T10:15:06.961Z",
        popular: false,
        slug: "hoai-duc",
        deleted: null,
      },
      {
        id: 275,
        name: "Quốc Oai",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.279Z",
        updated_at: "2024-10-29T10:15:06.966Z",
        popular: false,
        slug: "quoc-oai",
        deleted: null,
      },
      {
        id: 276,
        name: "Thạch Thất",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.291Z",
        updated_at: "2024-10-29T10:15:06.972Z",
        popular: false,
        slug: "thach-that",
        deleted: null,
      },
      {
        id: 277,
        name: "Chương Mỹ",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.307Z",
        updated_at: "2024-10-29T10:15:06.977Z",
        popular: false,
        slug: "chuong-my",
        deleted: null,
      },
      {
        id: 278,
        name: "Thanh Oai",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.319Z",
        updated_at: "2024-10-29T10:15:06.982Z",
        popular: false,
        slug: "thanh-oai",
        deleted: null,
      },
      {
        id: 279,
        name: "Thường Tín",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.332Z",
        updated_at: "2024-10-29T10:15:06.987Z",
        popular: false,
        slug: "thuong-tin",
        deleted: null,
      },
      {
        id: 280,
        name: "Phú Xuyên",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.348Z",
        updated_at: "2024-10-29T10:15:06.993Z",
        popular: false,
        slug: "phu-xuyen",
        deleted: null,
      },
      {
        id: 281,
        name: "Ứng Hòa",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.360Z",
        updated_at: "2024-10-29T10:15:06.998Z",
        popular: false,
        slug: "ung-hoa",
        deleted: null,
      },
      {
        id: 282,
        name: "Mỹ Đức",
        province_id: 1,
        created_at: "2024-01-28T17:20:43.374Z",
        updated_at: "2024-10-29T10:15:07.004Z",
        popular: false,
        slug: "my-duc",
        deleted: null,
      },
    ],
  },
  {
    id: 2,
    name: "Hà Giang",
    region: "north",
    created_at: "2024-01-28T17:20:42.169Z",
    updated_at: "2024-10-29T10:15:06.384Z",
    lat: 22.802558,
    lon: 104.978449,
    deleted: null,
    slug: "ha-giang",
    district: [
      {
        id: 24,
        name: "Thành phố Hà Giang",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.386Z",
        updated_at: "2024-10-29T10:15:07.009Z",
        popular: false,
        slug: "thanh-pho-ha-giang",
        deleted: null,
      },
      {
        id: 26,
        name: "Huyện Đồng Văn",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.399Z",
        updated_at: "2024-10-29T10:15:07.014Z",
        popular: false,
        slug: "huyen-dong-van",
        deleted: null,
      },
      {
        id: 27,
        name: "Huyện Mèo Vạc",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.412Z",
        updated_at: "2024-10-29T10:15:07.019Z",
        popular: false,
        slug: "huyen-meo-vac",
        deleted: null,
      },
      {
        id: 28,
        name: "Huyện Yên Minh",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.426Z",
        updated_at: "2024-10-29T10:15:07.025Z",
        popular: false,
        slug: "huyen-yen-minh",
        deleted: null,
      },
      {
        id: 29,
        name: "Huyện Quản Bạ",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.439Z",
        updated_at: "2024-10-29T10:15:07.030Z",
        popular: false,
        slug: "huyen-quan-ba",
        deleted: null,
      },
      {
        id: 30,
        name: "Huyện Vị Xuyên",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.452Z",
        updated_at: "2024-10-29T10:15:07.036Z",
        popular: false,
        slug: "huyen-vi-xuyen",
        deleted: null,
      },
      {
        id: 31,
        name: "Huyện Bắc Mê",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.466Z",
        updated_at: "2024-10-29T10:15:07.041Z",
        popular: false,
        slug: "huyen-bac-me",
        deleted: null,
      },
      {
        id: 32,
        name: "Huyện Hoàng Su Phì",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.480Z",
        updated_at: "2024-10-29T10:15:07.047Z",
        popular: false,
        slug: "huyen-hoang-su-phi",
        deleted: null,
      },
      {
        id: 33,
        name: "Huyện Xín Mần",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.492Z",
        updated_at: "2024-10-29T10:15:07.053Z",
        popular: false,
        slug: "huyen-xin-man",
        deleted: null,
      },
      {
        id: 34,
        name: "Huyện Bắc Quang",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.504Z",
        updated_at: "2024-10-29T10:15:07.060Z",
        popular: false,
        slug: "huyen-bac-quang",
        deleted: null,
      },
      {
        id: 35,
        name: "Huyện Quang Bình",
        province_id: 2,
        created_at: "2024-01-28T17:20:43.516Z",
        updated_at: "2024-10-29T10:15:07.067Z",
        popular: false,
        slug: "huyen-quang-binh",
        deleted: null,
      },
    ],
  },
  {
    id: 4,
    name: "Cao Bằng",
    region: "north",
    created_at: "2024-01-28T17:20:42.187Z",
    updated_at: "2024-10-29T10:15:06.391Z",
    lat: 22.665575,
    lon: 106.25784,
    deleted: null,
    slug: "cao-bang",
    district: [
      {
        id: 40,
        name: "Thành phố Cao Bằng",
        province_id: 4,
        created_at: "2024-01-28T17:20:43.532Z",
        updated_at: "2024-10-29T10:15:07.072Z",
        popular: false,
        slug: "thanh-pho-cao-bang",
        deleted: null,
      },
      {
        id: 42,
        name: "Huyện Bảo Lâm",
        province_id: 4,
        created_at: "2024-01-28T17:20:43.543Z",
        updated_at: "2024-10-29T10:15:07.079Z",
        popular: false,
        slug: "huyen-bao-lam",
        deleted: null,
      },
      {
        id: 43,
        name: "Huyện Bảo Lạc",
        province_id: 4,
        created_at: "2024-01-28T17:20:43.556Z",
        updated_at: "2024-10-29T10:15:07.085Z",
        popular: false,
        slug: "huyen-bao-lac",
        deleted: null,
      },
      {
        id: 45,
        name: "Huyện Hà Quảng",
        province_id: 4,
        created_at: "2024-01-28T17:20:43.570Z",
        updated_at: "2024-10-29T10:15:07.090Z",
        popular: false,
        slug: "huyen-ha-quang",
        deleted: null,
      },
      {
        id: 47,
        name: "Huyện Trùng Khánh",
        province_id: 4,
        created_at: "2024-01-28T17:20:43.584Z",
        updated_at: "2024-10-29T10:15:07.096Z",
        popular: false,
        slug: "huyen-trung-khanh",
        deleted: null,
      },
      {
        id: 48,
        name: "Huyện Hạ Lang",
        province_id: 4,
        created_at: "2024-01-28T17:20:43.601Z",
        updated_at: "2024-10-29T10:15:07.101Z",
        popular: false,
        slug: "huyen-ha-lang",
        deleted: null,
      },
      {
        id: 49,
        name: "Huyện Quảng Hòa",
        province_id: 4,
        created_at: "2024-01-28T17:20:43.613Z",
        updated_at: "2024-10-29T10:15:07.107Z",
        popular: false,
        slug: "huyen-quang-hoa",
        deleted: null,
      },
      {
        id: 51,
        name: "Huyện Hoà An",
        province_id: 4,
        created_at: "2024-01-28T17:20:43.624Z",
        updated_at: "2024-10-29T10:15:07.111Z",
        popular: false,
        slug: "huyen-hoa-an",
        deleted: null,
      },
      {
        id: 52,
        name: "Huyện Nguyên Bình",
        province_id: 4,
        created_at: "2024-01-28T17:20:43.640Z",
        updated_at: "2024-10-29T10:15:07.117Z",
        popular: false,
        slug: "huyen-nguyen-binh",
        deleted: null,
      },
      {
        id: 53,
        name: "Huyện Thạch An",
        province_id: 4,
        created_at: "2024-01-28T17:20:43.652Z",
        updated_at: "2024-10-29T10:15:07.122Z",
        popular: false,
        slug: "huyen-thach-an",
        deleted: null,
      },
    ],
  },
  {
    id: 6,
    name: "Bắc Kạn",
    region: "north",
    created_at: "2024-01-28T17:20:42.198Z",
    updated_at: "2024-10-29T10:15:06.396Z",
    lat: 22.148512,
    lon: 105.834722,
    deleted: null,
    slug: "bac-kan",
    district: [
      {
        id: 58,
        name: "Thành Phố Bắc Kạn",
        province_id: 6,
        created_at: "2024-01-28T17:20:43.664Z",
        updated_at: "2024-10-29T10:15:07.127Z",
        popular: false,
        slug: "thanh-pho-bac-kan",
        deleted: null,
      },
      {
        id: 60,
        name: "Huyện Pác Nặm",
        province_id: 6,
        created_at: "2024-01-28T17:20:43.680Z",
        updated_at: "2024-10-29T10:15:07.133Z",
        popular: false,
        slug: "huyen-pac-nam",
        deleted: null,
      },
      {
        id: 61,
        name: "Huyện Ba Bể",
        province_id: 6,
        created_at: "2024-01-28T17:20:43.691Z",
        updated_at: "2024-10-29T10:15:07.138Z",
        popular: false,
        slug: "huyen-ba-be",
        deleted: null,
      },
      {
        id: 62,
        name: "Huyện Ngân Sơn",
        province_id: 6,
        created_at: "2024-01-28T17:20:43.704Z",
        updated_at: "2024-10-29T10:15:07.144Z",
        popular: false,
        slug: "huyen-ngan-son",
        deleted: null,
      },
      {
        id: 63,
        name: "Huyện Bạch Thông",
        province_id: 6,
        created_at: "2024-01-28T17:20:43.716Z",
        updated_at: "2024-10-29T10:15:07.150Z",
        popular: false,
        slug: "huyen-bach-thong",
        deleted: null,
      },
      {
        id: 64,
        name: "Huyện Chợ Đồn",
        province_id: 6,
        created_at: "2024-01-28T17:20:43.728Z",
        updated_at: "2024-10-29T10:15:07.156Z",
        popular: false,
        slug: "huyen-cho-don",
        deleted: null,
      },
      {
        id: 65,
        name: "Huyện Chợ Mới",
        province_id: 6,
        created_at: "2024-01-28T17:20:43.742Z",
        updated_at: "2024-10-29T10:15:07.161Z",
        popular: false,
        slug: "huyen-cho-moi",
        deleted: null,
      },
      {
        id: 66,
        name: "Huyện Na Rì",
        province_id: 6,
        created_at: "2024-01-28T17:20:43.752Z",
        updated_at: "2024-10-29T10:15:07.166Z",
        popular: false,
        slug: "huyen-na-ri",
        deleted: null,
      },
    ],
  },
  {
    id: 8,
    name: "Tuyên Quang",
    region: "north",
    created_at: "2024-01-28T17:20:42.212Z",
    updated_at: "2024-10-29T10:15:06.402Z",
    lat: 21.823811,
    lon: 105.216866,
    deleted: null,
    slug: "tuyen-quang",
    district: [
      {
        id: 70,
        name: "Thành phố Tuyên Quang",
        province_id: 8,
        created_at: "2024-01-28T17:20:43.764Z",
        updated_at: "2024-10-29T10:15:07.171Z",
        popular: false,
        slug: "thanh-pho-tuyen-quang",
        deleted: null,
      },
      {
        id: 71,
        name: "Huyện Lâm Bình",
        province_id: 8,
        created_at: "2024-01-28T17:20:43.776Z",
        updated_at: "2024-10-29T10:15:07.177Z",
        popular: false,
        slug: "huyen-lam-binh",
        deleted: null,
      },
      {
        id: 72,
        name: "Huyện Na Hang",
        province_id: 8,
        created_at: "2024-01-28T17:20:43.787Z",
        updated_at: "2024-10-29T10:15:07.182Z",
        popular: false,
        slug: "huyen-na-hang",
        deleted: null,
      },
      {
        id: 73,
        name: "Huyện Chiêm Hóa",
        province_id: 8,
        created_at: "2024-01-28T17:20:43.800Z",
        updated_at: "2024-10-29T10:15:07.188Z",
        popular: false,
        slug: "huyen-chiem-hoa",
        deleted: null,
      },
      {
        id: 74,
        name: "Huyện Hàm Yên",
        province_id: 8,
        created_at: "2024-01-28T17:20:43.816Z",
        updated_at: "2024-10-29T10:15:07.193Z",
        popular: false,
        slug: "huyen-ham-yen",
        deleted: null,
      },
      {
        id: 75,
        name: "Huyện Yên Sơn",
        province_id: 8,
        created_at: "2024-01-28T17:20:43.829Z",
        updated_at: "2024-10-29T10:15:07.199Z",
        popular: false,
        slug: "huyen-yen-son",
        deleted: null,
      },
      {
        id: 76,
        name: "Huyện Sơn Dương",
        province_id: 8,
        created_at: "2024-01-28T17:20:43.844Z",
        updated_at: "2024-10-29T10:15:07.203Z",
        popular: false,
        slug: "huyen-son-duong",
        deleted: null,
      },
    ],
  },
  {
    id: 10,
    name: "Lào Cai",
    region: "north",
    created_at: "2024-01-28T17:20:42.224Z",
    updated_at: "2024-10-29T10:15:06.408Z",
    lat: 22.486913,
    lon: 103.970315,
    deleted: null,
    slug: "lao-cai",
    district: [
      {
        id: 80,
        name: "Thành phố Lào Cai",
        province_id: 10,
        created_at: "2024-01-28T17:20:43.861Z",
        updated_at: "2024-10-29T10:15:07.209Z",
        popular: false,
        slug: "thanh-pho-lao-cai",
        deleted: null,
      },
      {
        id: 82,
        name: "Huyện Bát Xát",
        province_id: 10,
        created_at: "2024-01-28T17:20:43.872Z",
        updated_at: "2024-10-29T10:15:07.215Z",
        popular: false,
        slug: "huyen-bat-xat",
        deleted: null,
      },
      {
        id: 83,
        name: "Huyện Mường Khương",
        province_id: 10,
        created_at: "2024-01-28T17:20:43.884Z",
        updated_at: "2024-10-29T10:15:07.221Z",
        popular: false,
        slug: "huyen-muong-khuong",
        deleted: null,
      },
      {
        id: 84,
        name: "Huyện Si Ma Cai",
        province_id: 10,
        created_at: "2024-01-28T17:20:43.897Z",
        updated_at: "2024-10-29T10:15:07.227Z",
        popular: false,
        slug: "huyen-si-ma-cai",
        deleted: null,
      },
      {
        id: 85,
        name: "Huyện Bắc Hà",
        province_id: 10,
        created_at: "2024-01-28T17:20:43.908Z",
        updated_at: "2024-10-29T10:15:07.233Z",
        popular: false,
        slug: "huyen-bac-ha",
        deleted: null,
      },
      {
        id: 86,
        name: "Huyện Bảo Thắng",
        province_id: 10,
        created_at: "2024-01-28T17:20:43.924Z",
        updated_at: "2024-10-29T10:15:07.238Z",
        popular: false,
        slug: "huyen-bao-thang",
        deleted: null,
      },
      {
        id: 87,
        name: "Huyện Bảo Yên",
        province_id: 10,
        created_at: "2024-01-28T17:20:43.939Z",
        updated_at: "2024-10-29T10:15:07.243Z",
        popular: false,
        slug: "huyen-bao-yen",
        deleted: null,
      },
      {
        id: 88,
        name: "Thị xã Sa Pa",
        province_id: 10,
        created_at: "2024-01-28T17:20:43.951Z",
        updated_at: "2024-10-29T10:15:07.248Z",
        popular: false,
        slug: "thi-xa-sa-pa",
        deleted: null,
      },
      {
        id: 89,
        name: "Huyện Văn Bàn",
        province_id: 10,
        created_at: "2024-01-28T17:20:43.965Z",
        updated_at: "2024-10-29T10:15:07.254Z",
        popular: false,
        slug: "huyen-van-ban",
        deleted: null,
      },
    ],
  },
  {
    id: 11,
    name: "Điện Biên",
    region: "north",
    created_at: "2024-01-28T17:20:42.238Z",
    updated_at: "2024-10-29T10:15:06.414Z",
    lat: 21.38602,
    lon: 103.016618,
    deleted: null,
    slug: "dien-bien",
    district: [
      {
        id: 94,
        name: "Thành phố Điện Biên Phủ",
        province_id: 11,
        created_at: "2024-01-28T17:20:43.984Z",
        updated_at: "2024-10-29T10:15:07.259Z",
        popular: false,
        slug: "thanh-pho-dien-bien-phu",
        deleted: null,
      },
      {
        id: 95,
        name: "Thị xã Mường Lay",
        province_id: 11,
        created_at: "2024-01-28T17:20:43.997Z",
        updated_at: "2024-10-29T10:15:07.265Z",
        popular: false,
        slug: "thi-xa-muong-lay",
        deleted: null,
      },
      {
        id: 96,
        name: "Huyện Mường Nhé",
        province_id: 11,
        created_at: "2024-01-28T17:20:44.011Z",
        updated_at: "2024-10-29T10:15:07.270Z",
        popular: false,
        slug: "huyen-muong-nhe",
        deleted: null,
      },
      {
        id: 97,
        name: "Huyện Mường Chà",
        province_id: 11,
        created_at: "2024-01-28T17:20:44.024Z",
        updated_at: "2024-10-29T10:15:07.276Z",
        popular: false,
        slug: "huyen-muong-cha",
        deleted: null,
      },
      {
        id: 98,
        name: "Huyện Tủa Chùa",
        province_id: 11,
        created_at: "2024-01-28T17:20:44.036Z",
        updated_at: "2024-10-29T10:15:07.280Z",
        popular: false,
        slug: "huyen-tua-chua",
        deleted: null,
      },
      {
        id: 99,
        name: "Huyện Tuần Giáo",
        province_id: 11,
        created_at: "2024-01-28T17:20:44.050Z",
        updated_at: "2024-10-29T10:15:07.287Z",
        popular: false,
        slug: "huyen-tuan-giao",
        deleted: null,
      },
      {
        id: 100,
        name: "Huyện Điện Biên",
        province_id: 11,
        created_at: "2024-01-28T17:20:44.065Z",
        updated_at: "2024-10-29T10:15:07.292Z",
        popular: false,
        slug: "huyen-dien-bien",
        deleted: null,
      },
      {
        id: 101,
        name: "Huyện Điện Biên Đông",
        province_id: 11,
        created_at: "2024-01-28T17:20:44.081Z",
        updated_at: "2024-10-29T10:15:07.300Z",
        popular: false,
        slug: "huyen-dien-bien-dong",
        deleted: null,
      },
      {
        id: 102,
        name: "Huyện Mường Ảng",
        province_id: 11,
        created_at: "2024-01-28T17:20:44.093Z",
        updated_at: "2024-10-29T10:15:07.305Z",
        popular: false,
        slug: "huyen-muong-ang",
        deleted: null,
      },
      {
        id: 103,
        name: "Huyện Nậm Pồ",
        province_id: 11,
        created_at: "2024-01-28T17:20:44.109Z",
        updated_at: "2024-10-29T10:15:07.311Z",
        popular: false,
        slug: "huyen-nam-po",
        deleted: null,
      },
    ],
  },
  {
    id: 12,
    name: "Lai Châu",
    region: "north",
    created_at: "2024-01-28T17:20:42.251Z",
    updated_at: "2024-10-29T10:15:06.422Z",
    lat: 22.401093,
    lon: 103.471098,
    deleted: null,
    slug: "lai-chau",
    district: [
      {
        id: 105,
        name: "Thành phố Lai Châu",
        province_id: 12,
        created_at: "2024-01-28T17:20:44.127Z",
        updated_at: "2024-10-29T10:15:07.316Z",
        popular: false,
        slug: "thanh-pho-lai-chau",
        deleted: null,
      },
      {
        id: 106,
        name: "Huyện Tam Đường",
        province_id: 12,
        created_at: "2024-01-28T17:20:44.140Z",
        updated_at: "2024-10-29T10:15:07.321Z",
        popular: false,
        slug: "huyen-tam-duong",
        deleted: null,
      },
      {
        id: 107,
        name: "Huyện Mường Tè",
        province_id: 12,
        created_at: "2024-01-28T17:20:44.154Z",
        updated_at: "2024-10-29T10:15:07.327Z",
        popular: false,
        slug: "huyen-muong-te",
        deleted: null,
      },
      {
        id: 108,
        name: "Huyện Sìn Hồ",
        province_id: 12,
        created_at: "2024-01-28T17:20:44.168Z",
        updated_at: "2024-10-29T10:15:07.332Z",
        popular: false,
        slug: "huyen-sin-ho",
        deleted: null,
      },
      {
        id: 109,
        name: "Huyện Phong Thổ",
        province_id: 12,
        created_at: "2024-01-28T17:20:44.184Z",
        updated_at: "2024-10-29T10:15:07.337Z",
        popular: false,
        slug: "huyen-phong-tho",
        deleted: null,
      },
      {
        id: 110,
        name: "Huyện Than Uyên",
        province_id: 12,
        created_at: "2024-01-28T17:20:44.201Z",
        updated_at: "2024-10-29T10:15:07.344Z",
        popular: false,
        slug: "huyen-than-uyen",
        deleted: null,
      },
      {
        id: 111,
        name: "Huyện Tân Uyên",
        province_id: 12,
        created_at: "2024-01-28T17:20:44.215Z",
        updated_at: "2024-10-29T10:15:07.348Z",
        popular: false,
        slug: "huyen-tan-uyen",
        deleted: null,
      },
      {
        id: 112,
        name: "Huyện Nậm Nhùn",
        province_id: 12,
        created_at: "2024-01-28T17:20:44.228Z",
        updated_at: "2024-10-29T10:15:07.352Z",
        popular: false,
        slug: "huyen-nam-nhun",
        deleted: null,
      },
    ],
  },
  {
    id: 14,
    name: "Sơn La",
    region: "north",
    created_at: "2024-01-28T17:20:42.265Z",
    updated_at: "2024-10-29T10:15:06.329Z",
    lat: 21.328668,
    lon: 103.914399,
    deleted: null,
    slug: "son-la",
    district: [
      {
        id: 116,
        name: "Thành phố Sơn La",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.240Z",
        updated_at: "2024-10-29T10:15:07.357Z",
        popular: false,
        slug: "thanh-pho-son-la",
        deleted: null,
      },
      {
        id: 118,
        name: "Huyện Quỳnh Nhai",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.257Z",
        updated_at: "2024-10-29T10:15:07.363Z",
        popular: false,
        slug: "huyen-quynh-nhai",
        deleted: null,
      },
      {
        id: 119,
        name: "Huyện Thuận Châu",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.269Z",
        updated_at: "2024-10-29T10:15:07.368Z",
        popular: false,
        slug: "huyen-thuan-chau",
        deleted: null,
      },
      {
        id: 120,
        name: "Huyện Mường La",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.283Z",
        updated_at: "2024-10-29T10:15:07.373Z",
        popular: false,
        slug: "huyen-muong-la",
        deleted: null,
      },
      {
        id: 121,
        name: "Huyện Bắc Yên",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.296Z",
        updated_at: "2024-10-29T10:15:07.379Z",
        popular: false,
        slug: "huyen-bac-yen",
        deleted: null,
      },
      {
        id: 122,
        name: "Huyện Phù Yên",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.310Z",
        updated_at: "2024-10-29T10:15:07.383Z",
        popular: false,
        slug: "huyen-phu-yen",
        deleted: null,
      },
      {
        id: 123,
        name: "Huyện Mộc Châu",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.322Z",
        updated_at: "2024-10-29T10:15:07.388Z",
        popular: false,
        slug: "huyen-moc-chau",
        deleted: null,
      },
      {
        id: 124,
        name: "Huyện Yên Châu",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.336Z",
        updated_at: "2024-10-29T10:15:07.393Z",
        popular: false,
        slug: "huyen-yen-chau",
        deleted: null,
      },
      {
        id: 125,
        name: "Huyện Mai Sơn",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.348Z",
        updated_at: "2024-10-29T10:15:07.398Z",
        popular: false,
        slug: "huyen-mai-son",
        deleted: null,
      },
      {
        id: 126,
        name: "Huyện Sông Mã",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.360Z",
        updated_at: "2024-10-29T10:15:07.403Z",
        popular: false,
        slug: "huyen-song-ma",
        deleted: null,
      },
      {
        id: 127,
        name: "Huyện Sốp Cộp",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.372Z",
        updated_at: "2024-10-29T10:15:07.409Z",
        popular: false,
        slug: "huyen-sop-cop",
        deleted: null,
      },
      {
        id: 128,
        name: "Huyện Vân Hồ",
        province_id: 14,
        created_at: "2024-01-28T17:20:44.388Z",
        updated_at: "2024-10-29T10:15:07.418Z",
        popular: false,
        slug: "huyen-van-ho",
        deleted: null,
      },
    ],
  },
  {
    id: 15,
    name: "Yên Bái",
    region: "north",
    created_at: "2024-01-28T17:20:42.280Z",
    updated_at: "2024-10-29T10:15:06.336Z",
    lat: 21.72241,
    lon: 104.898348,
    deleted: null,
    slug: "yen-bai",
    district: [
      {
        id: 132,
        name: "Thành phố Yên Bái",
        province_id: 15,
        created_at: "2024-01-28T17:20:44.400Z",
        updated_at: "2024-10-29T10:15:07.423Z",
        popular: false,
        slug: "thanh-pho-yen-bai",
        deleted: null,
      },
      {
        id: 133,
        name: "Thị xã Nghĩa Lộ",
        province_id: 15,
        created_at: "2024-01-28T17:20:44.412Z",
        updated_at: "2024-10-29T10:15:07.428Z",
        popular: false,
        slug: "thi-xa-nghia-lo",
        deleted: null,
      },
      {
        id: 135,
        name: "Huyện Lục Yên",
        province_id: 15,
        created_at: "2024-01-28T17:20:44.428Z",
        updated_at: "2024-10-29T10:15:07.434Z",
        popular: false,
        slug: "huyen-luc-yen",
        deleted: null,
      },
      {
        id: 136,
        name: "Huyện Văn Yên",
        province_id: 15,
        created_at: "2024-01-28T17:20:44.439Z",
        updated_at: "2024-10-29T10:15:07.439Z",
        popular: false,
        slug: "huyen-van-yen",
        deleted: null,
      },
      {
        id: 137,
        name: "Huyện Mù Căng Chải",
        province_id: 15,
        created_at: "2024-01-28T17:20:44.452Z",
        updated_at: "2024-10-29T10:15:07.446Z",
        popular: false,
        slug: "huyen-mu-cang-chai",
        deleted: null,
      },
      {
        id: 138,
        name: "Huyện Trấn Yên",
        province_id: 15,
        created_at: "2024-01-28T17:20:44.464Z",
        updated_at: "2024-10-29T10:15:07.451Z",
        popular: false,
        slug: "huyen-tran-yen",
        deleted: null,
      },
      {
        id: 139,
        name: "Huyện Trạm Tấu",
        province_id: 15,
        created_at: "2024-01-28T17:20:44.480Z",
        updated_at: "2024-10-29T10:15:07.456Z",
        popular: false,
        slug: "huyen-tram-tau",
        deleted: null,
      },
      {
        id: 140,
        name: "Huyện Văn Chấn",
        province_id: 15,
        created_at: "2024-01-28T17:20:44.495Z",
        updated_at: "2024-10-29T10:15:07.461Z",
        popular: false,
        slug: "huyen-van-chan",
        deleted: null,
      },
      {
        id: 141,
        name: "Huyện Yên Bình",
        province_id: 15,
        created_at: "2024-01-28T17:20:44.507Z",
        updated_at: "2024-10-29T10:15:07.466Z",
        popular: false,
        slug: "huyen-yen-binh",
        deleted: null,
      },
    ],
  },
  {
    id: 17,
    name: "Hoà Bình",
    region: "north",
    created_at: "2024-01-28T17:20:42.293Z",
    updated_at: "2024-10-29T10:15:06.344Z",
    lat: 20.853889,
    lon: 105.337556,
    deleted: null,
    slug: "hoa-binh",
    district: [
      {
        id: 148,
        name: "Thành phố Hòa Bình",
        province_id: 17,
        created_at: "2024-01-28T17:20:44.523Z",
        updated_at: "2024-10-29T10:15:07.471Z",
        popular: false,
        slug: "thanh-pho-hoa-binh",
        deleted: null,
      },
      {
        id: 150,
        name: "Huyện Đà Bắc",
        province_id: 17,
        created_at: "2024-01-28T17:20:44.535Z",
        updated_at: "2024-10-29T10:15:07.477Z",
        popular: false,
        slug: "huyen-da-bac",
        deleted: null,
      },
      {
        id: 152,
        name: "Huyện Lương Sơn",
        province_id: 17,
        created_at: "2024-01-28T17:20:44.548Z",
        updated_at: "2024-10-29T10:15:07.481Z",
        popular: false,
        slug: "huyen-luong-son",
        deleted: null,
      },
      {
        id: 153,
        name: "Huyện Kim Bôi",
        province_id: 17,
        created_at: "2024-01-28T17:20:44.563Z",
        updated_at: "2024-10-29T10:15:07.487Z",
        popular: false,
        slug: "huyen-kim-boi",
        deleted: null,
      },
      {
        id: 154,
        name: "Huyện Cao Phong",
        province_id: 17,
        created_at: "2024-01-28T17:20:44.577Z",
        updated_at: "2024-10-29T10:15:07.491Z",
        popular: false,
        slug: "huyen-cao-phong",
        deleted: null,
      },
      {
        id: 155,
        name: "Huyện Tân Lạc",
        province_id: 17,
        created_at: "2024-01-28T17:20:44.591Z",
        updated_at: "2024-10-29T10:15:07.497Z",
        popular: false,
        slug: "huyen-tan-lac",
        deleted: null,
      },
      {
        id: 156,
        name: "Huyện Mai Châu",
        province_id: 17,
        created_at: "2024-01-28T17:20:44.604Z",
        updated_at: "2024-10-29T10:15:07.502Z",
        popular: false,
        slug: "huyen-mai-chau",
        deleted: null,
      },
      {
        id: 157,
        name: "Huyện Lạc Sơn",
        province_id: 17,
        created_at: "2024-01-28T17:20:44.618Z",
        updated_at: "2024-10-29T10:15:07.508Z",
        popular: false,
        slug: "huyen-lac-son",
        deleted: null,
      },
      {
        id: 158,
        name: "Huyện Yên Thủy",
        province_id: 17,
        created_at: "2024-01-28T17:20:44.632Z",
        updated_at: "2024-10-29T10:15:07.513Z",
        popular: false,
        slug: "huyen-yen-thuy",
        deleted: null,
      },
      {
        id: 159,
        name: "Huyện Lạc Thủy",
        province_id: 17,
        created_at: "2024-01-28T17:20:44.644Z",
        updated_at: "2024-10-29T10:15:07.519Z",
        popular: false,
        slug: "huyen-lac-thuy",
        deleted: null,
      },
    ],
  },
  {
    id: 19,
    name: "Thái Nguyên",
    region: "north",
    created_at: "2024-01-28T17:20:42.307Z",
    updated_at: "2024-10-29T10:15:06.350Z",
    lat: 21.594915,
    lon: 105.848793,
    deleted: null,
    slug: "thai-nguyen",
    district: [
      {
        id: 164,
        name: "Thành phố Thái Nguyên",
        province_id: 19,
        created_at: "2024-01-28T17:20:44.659Z",
        updated_at: "2024-10-29T10:15:07.526Z",
        popular: false,
        slug: "thanh-pho-thai-nguyen",
        deleted: null,
      },
      {
        id: 165,
        name: "Thành phố Sông Công",
        province_id: 19,
        created_at: "2024-01-28T17:20:44.672Z",
        updated_at: "2024-10-29T10:15:07.541Z",
        popular: false,
        slug: "thanh-pho-song-cong",
        deleted: null,
      },
      {
        id: 167,
        name: "Huyện Định Hóa",
        province_id: 19,
        created_at: "2024-01-28T17:20:44.692Z",
        updated_at: "2024-10-29T10:15:07.548Z",
        popular: false,
        slug: "huyen-dinh-hoa",
        deleted: null,
      },
      {
        id: 168,
        name: "Huyện Phú Lương",
        province_id: 19,
        created_at: "2024-01-28T17:20:44.705Z",
        updated_at: "2024-10-29T10:15:07.553Z",
        popular: false,
        slug: "huyen-phu-luong",
        deleted: null,
      },
      {
        id: 169,
        name: "Huyện Đồng Hỷ",
        province_id: 19,
        created_at: "2024-01-28T17:20:44.720Z",
        updated_at: "2024-10-29T10:15:07.559Z",
        popular: false,
        slug: "huyen-dong-hy",
        deleted: null,
      },
      {
        id: 170,
        name: "Huyện Võ Nhai",
        province_id: 19,
        created_at: "2024-01-28T17:20:44.736Z",
        updated_at: "2024-10-29T10:15:07.563Z",
        popular: false,
        slug: "huyen-vo-nhai",
        deleted: null,
      },
      {
        id: 171,
        name: "Huyện Đại Từ",
        province_id: 19,
        created_at: "2024-01-28T17:20:44.750Z",
        updated_at: "2024-10-29T10:15:07.570Z",
        popular: false,
        slug: "huyen-dai-tu",
        deleted: null,
      },
      {
        id: 172,
        name: "Thành phố Phổ Yên",
        province_id: 19,
        created_at: "2024-01-28T17:20:44.765Z",
        updated_at: "2024-10-29T10:15:07.575Z",
        popular: false,
        slug: "thanh-pho-pho-yen",
        deleted: null,
      },
      {
        id: 173,
        name: "Huyện Phú Bình",
        province_id: 19,
        created_at: "2024-01-28T17:20:44.778Z",
        updated_at: "2024-10-29T10:15:07.581Z",
        popular: false,
        slug: "huyen-phu-binh",
        deleted: null,
      },
    ],
  },
  {
    id: 20,
    name: "Lạng Sơn",
    region: "north",
    created_at: "2024-01-28T17:20:42.319Z",
    updated_at: "2024-10-29T10:15:06.355Z",
    lat: 21.853708,
    lon: 106.761519,
    deleted: null,
    slug: "lang-son",
    district: [
      {
        id: 178,
        name: "Thành phố Lạng Sơn",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.789Z",
        updated_at: "2024-10-29T10:15:07.586Z",
        popular: false,
        slug: "thanh-pho-lang-son",
        deleted: null,
      },
      {
        id: 180,
        name: "Huyện Tràng Định",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.802Z",
        updated_at: "2024-10-29T10:15:07.592Z",
        popular: false,
        slug: "huyen-trang-dinh",
        deleted: null,
      },
      {
        id: 181,
        name: "Huyện Bình Gia",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.817Z",
        updated_at: "2024-10-29T10:15:07.596Z",
        popular: false,
        slug: "huyen-binh-gia",
        deleted: null,
      },
      {
        id: 182,
        name: "Huyện Văn Lãng",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.832Z",
        updated_at: "2024-10-29T10:15:07.601Z",
        popular: false,
        slug: "huyen-van-lang",
        deleted: null,
      },
      {
        id: 183,
        name: "Huyện Cao Lộc",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.849Z",
        updated_at: "2024-10-29T10:15:07.606Z",
        popular: false,
        slug: "huyen-cao-loc",
        deleted: null,
      },
      {
        id: 184,
        name: "Huyện Văn Quan",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.876Z",
        updated_at: "2024-10-29T10:15:07.611Z",
        popular: false,
        slug: "huyen-van-quan",
        deleted: null,
      },
      {
        id: 185,
        name: "Huyện Bắc Sơn",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.897Z",
        updated_at: "2024-10-29T10:15:07.616Z",
        popular: false,
        slug: "huyen-bac-son",
        deleted: null,
      },
      {
        id: 186,
        name: "Huyện Hữu Lũng",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.913Z",
        updated_at: "2024-10-29T10:15:07.621Z",
        popular: false,
        slug: "huyen-huu-lung",
        deleted: null,
      },
      {
        id: 187,
        name: "Huyện Chi Lăng",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.928Z",
        updated_at: "2024-10-29T10:15:07.626Z",
        popular: false,
        slug: "huyen-chi-lang",
        deleted: null,
      },
      {
        id: 188,
        name: "Huyện Lộc Bình",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.940Z",
        updated_at: "2024-10-29T10:15:07.631Z",
        popular: false,
        slug: "huyen-loc-binh",
        deleted: null,
      },
      {
        id: 189,
        name: "Huyện Đình Lập",
        province_id: 20,
        created_at: "2024-01-28T17:20:44.952Z",
        updated_at: "2024-10-29T10:15:07.637Z",
        popular: false,
        slug: "huyen-dinh-lap",
        deleted: null,
      },
    ],
  },
  {
    id: 22,
    name: "Quảng Ninh",
    region: "north",
    created_at: "2024-01-28T17:20:42.333Z",
    updated_at: "2024-10-29T10:15:06.360Z",
    lat: 21.137422,
    lon: 107.3716531,
    deleted: null,
    slug: "quang-ninh",
    district: [
      {
        id: 193,
        name: "Thành phố Hạ Long",
        province_id: 22,
        created_at: "2024-01-28T17:20:44.969Z",
        updated_at: "2024-10-29T10:15:07.642Z",
        popular: false,
        slug: "thanh-pho-ha-long",
        deleted: null,
      },
      {
        id: 194,
        name: "Thành phố Móng Cái",
        province_id: 22,
        created_at: "2024-01-28T17:20:44.984Z",
        updated_at: "2024-10-29T10:15:07.648Z",
        popular: false,
        slug: "thanh-pho-mong-cai",
        deleted: null,
      },
      {
        id: 195,
        name: "Thành phố Cẩm Phả",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.001Z",
        updated_at: "2024-10-29T10:15:07.654Z",
        popular: false,
        slug: "thanh-pho-cam-pha",
        deleted: null,
      },
      {
        id: 196,
        name: "Thành phố Uông Bí",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.019Z",
        updated_at: "2024-10-29T10:15:07.660Z",
        popular: false,
        slug: "thanh-pho-uong-bi",
        deleted: null,
      },
      {
        id: 198,
        name: "Huyện Bình Liêu",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.033Z",
        updated_at: "2024-10-29T10:15:07.665Z",
        popular: false,
        slug: "huyen-binh-lieu",
        deleted: null,
      },
      {
        id: 199,
        name: "Huyện Tiên Yên",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.048Z",
        updated_at: "2024-10-29T10:15:07.671Z",
        popular: false,
        slug: "huyen-tien-yen",
        deleted: null,
      },
      {
        id: 200,
        name: "Huyện Đầm Hà",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.059Z",
        updated_at: "2024-10-29T10:15:07.677Z",
        popular: false,
        slug: "huyen-dam-ha",
        deleted: null,
      },
      {
        id: 201,
        name: "Huyện Hải Hà",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.074Z",
        updated_at: "2024-10-29T10:15:07.683Z",
        popular: false,
        slug: "huyen-hai-ha",
        deleted: null,
      },
      {
        id: 202,
        name: "Huyện Ba Chẽ",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.087Z",
        updated_at: "2024-10-29T10:15:07.688Z",
        popular: false,
        slug: "huyen-ba-che",
        deleted: null,
      },
      {
        id: 203,
        name: "Huyện Vân Đồn",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.100Z",
        updated_at: "2024-10-29T10:15:07.693Z",
        popular: false,
        slug: "huyen-van-don",
        deleted: null,
      },
      {
        id: 205,
        name: "Thị xã Đông Triều",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.115Z",
        updated_at: "2024-10-29T10:15:07.698Z",
        popular: false,
        slug: "thi-xa-dong-trieu",
        deleted: null,
      },
      {
        id: 206,
        name: "Thị xã Quảng Yên",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.127Z",
        updated_at: "2024-10-29T10:15:07.703Z",
        popular: false,
        slug: "thi-xa-quang-yen",
        deleted: null,
      },
      {
        id: 207,
        name: "Huyện Cô Tô",
        province_id: 22,
        created_at: "2024-01-28T17:20:45.140Z",
        updated_at: "2024-10-29T10:15:07.708Z",
        popular: false,
        slug: "huyen-co-to",
        deleted: null,
      },
    ],
  },
  {
    id: 24,
    name: "Bắc Giang",
    region: "north",
    created_at: "2024-01-28T17:20:42.349Z",
    updated_at: "2024-10-29T10:15:06.366Z",
    lat: 21.273279,
    lon: 106.1946,
    deleted: null,
    slug: "bac-giang",
    district: [
      {
        id: 213,
        name: "Thành phố Bắc Giang",
        province_id: 24,
        created_at: "2024-01-28T17:20:45.157Z",
        updated_at: "2024-10-29T10:15:07.713Z",
        popular: false,
        slug: "thanh-pho-bac-giang",
        deleted: null,
      },
      {
        id: 215,
        name: "Huyện Yên Thế",
        province_id: 24,
        created_at: "2024-01-28T17:20:45.169Z",
        updated_at: "2024-10-29T10:15:07.719Z",
        popular: false,
        slug: "huyen-yen-the",
        deleted: null,
      },
      {
        id: 216,
        name: "Huyện Tân Yên",
        province_id: 24,
        created_at: "2024-01-28T17:20:45.183Z",
        updated_at: "2024-10-29T10:15:07.724Z",
        popular: false,
        slug: "huyen-tan-yen",
        deleted: null,
      },
      {
        id: 217,
        name: "Huyện Lạng Giang",
        province_id: 24,
        created_at: "2024-01-28T17:20:45.195Z",
        updated_at: "2024-10-29T10:15:07.735Z",
        popular: false,
        slug: "huyen-lang-giang",
        deleted: null,
      },
      {
        id: 218,
        name: "Huyện Lục Nam",
        province_id: 24,
        created_at: "2024-01-28T17:20:45.208Z",
        updated_at: "2024-10-29T10:15:07.741Z",
        popular: false,
        slug: "huyen-luc-nam",
        deleted: null,
      },
      {
        id: 219,
        name: "Huyện Lục Ngạn",
        province_id: 24,
        created_at: "2024-01-28T17:20:45.220Z",
        updated_at: "2024-10-29T10:15:07.748Z",
        popular: false,
        slug: "huyen-luc-ngan",
        deleted: null,
      },
      {
        id: 220,
        name: "Huyện Sơn Động",
        province_id: 24,
        created_at: "2024-01-28T17:20:45.232Z",
        updated_at: "2024-10-29T10:15:07.753Z",
        popular: false,
        slug: "huyen-son-dong",
        deleted: null,
      },
      {
        id: 221,
        name: "Huyện Yên Dũng",
        province_id: 24,
        created_at: "2024-01-28T17:20:45.248Z",
        updated_at: "2024-10-29T10:15:07.759Z",
        popular: false,
        slug: "huyen-yen-dung",
        deleted: null,
      },
      {
        id: 222,
        name: "Huyện Việt Yên",
        province_id: 24,
        created_at: "2024-01-28T17:20:45.259Z",
        updated_at: "2024-10-29T10:15:07.764Z",
        popular: false,
        slug: "huyen-viet-yen",
        deleted: null,
      },
      {
        id: 223,
        name: "Huyện Hiệp Hòa",
        province_id: 24,
        created_at: "2024-01-28T17:20:45.272Z",
        updated_at: "2024-10-29T10:15:07.770Z",
        popular: false,
        slug: "huyen-hiep-hoa",
        deleted: null,
      },
    ],
  },
  {
    id: 25,
    name: "Phú Thọ",
    region: "north",
    created_at: "2024-01-28T17:20:42.362Z",
    updated_at: "2024-10-29T10:15:06.372Z",
    lat: 21.319421,
    lon: 105.228206,
    deleted: null,
    slug: "phu-tho",
    district: [
      {
        id: 227,
        name: "Thành phố Việt Trì",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.287Z",
        updated_at: "2024-10-29T10:15:07.775Z",
        popular: false,
        slug: "thanh-pho-viet-tri",
        deleted: null,
      },
      {
        id: 228,
        name: "Thị xã Phú Thọ",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.299Z",
        updated_at: "2024-10-29T10:15:07.782Z",
        popular: false,
        slug: "thi-xa-phu-tho",
        deleted: null,
      },
      {
        id: 230,
        name: "Huyện Đoan Hùng",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.314Z",
        updated_at: "2024-10-29T10:15:07.807Z",
        popular: false,
        slug: "huyen-doan-hung",
        deleted: null,
      },
      {
        id: 231,
        name: "Huyện Hạ Hoà",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.326Z",
        updated_at: "2024-10-29T10:15:07.820Z",
        popular: false,
        slug: "huyen-ha-hoa",
        deleted: null,
      },
      {
        id: 232,
        name: "Huyện Thanh Ba",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.338Z",
        updated_at: "2024-10-29T10:15:07.832Z",
        popular: false,
        slug: "huyen-thanh-ba",
        deleted: null,
      },
      {
        id: 233,
        name: "Huyện Phù Ninh",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.348Z",
        updated_at: "2024-10-29T10:15:07.837Z",
        popular: false,
        slug: "huyen-phu-ninh",
        deleted: null,
      },
      {
        id: 234,
        name: "Huyện Yên Lập",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.363Z",
        updated_at: "2024-10-29T10:15:07.843Z",
        popular: false,
        slug: "huyen-yen-lap",
        deleted: null,
      },
      {
        id: 235,
        name: "Huyện Cẩm Khê",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.374Z",
        updated_at: "2024-10-29T10:15:07.849Z",
        popular: false,
        slug: "huyen-cam-khe",
        deleted: null,
      },
      {
        id: 236,
        name: "Huyện Tam Nông",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.388Z",
        updated_at: "2024-10-29T10:15:07.855Z",
        popular: false,
        slug: "huyen-tam-nong",
        deleted: null,
      },
      {
        id: 237,
        name: "Huyện Lâm Thao",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.400Z",
        updated_at: "2024-10-29T10:15:07.860Z",
        popular: false,
        slug: "huyen-lam-thao",
        deleted: null,
      },
      {
        id: 238,
        name: "Huyện Thanh Sơn",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.414Z",
        updated_at: "2024-10-29T10:15:07.866Z",
        popular: false,
        slug: "huyen-thanh-son",
        deleted: null,
      },
      {
        id: 239,
        name: "Huyện Thanh Thuỷ",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.426Z",
        updated_at: "2024-10-29T10:15:07.874Z",
        popular: false,
        slug: "huyen-thanh-thuy",
        deleted: null,
      },
      {
        id: 240,
        name: "Huyện Tân Sơn",
        province_id: 25,
        created_at: "2024-01-28T17:20:45.440Z",
        updated_at: "2024-10-29T10:15:07.880Z",
        popular: false,
        slug: "huyen-tan-son",
        deleted: null,
      },
    ],
  },
  {
    id: 26,
    name: "Vĩnh Phúc",
    region: "north",
    created_at: "2024-01-28T17:20:42.376Z",
    updated_at: "2024-10-29T10:15:06.428Z",
    lat: 21.363121,
    lon: 105.5821071,
    deleted: null,
    slug: "vinh-phuc",
    district: [
      {
        id: 243,
        name: "Thành phố Vĩnh Yên",
        province_id: 26,
        created_at: "2024-01-28T17:20:45.454Z",
        updated_at: "2024-10-29T10:15:07.885Z",
        popular: false,
        slug: "thanh-pho-vinh-yen",
        deleted: null,
      },
      {
        id: 244,
        name: "Thành phố Phúc Yên",
        province_id: 26,
        created_at: "2024-01-28T17:20:45.465Z",
        updated_at: "2024-10-29T10:15:07.891Z",
        popular: false,
        slug: "thanh-pho-phuc-yen",
        deleted: null,
      },
      {
        id: 246,
        name: "Huyện Lập Thạch",
        province_id: 26,
        created_at: "2024-01-28T17:20:45.476Z",
        updated_at: "2024-10-29T10:15:07.897Z",
        popular: false,
        slug: "huyen-lap-thach",
        deleted: null,
      },
      {
        id: 247,
        name: "Huyện Tam Dương",
        province_id: 26,
        created_at: "2024-01-28T17:20:45.488Z",
        updated_at: "2024-10-29T10:15:07.912Z",
        popular: false,
        slug: "huyen-tam-duong",
        deleted: null,
      },
      {
        id: 248,
        name: "Huyện Tam Đảo",
        province_id: 26,
        created_at: "2024-01-28T17:20:45.502Z",
        updated_at: "2024-10-29T10:15:07.918Z",
        popular: false,
        slug: "huyen-tam-dao",
        deleted: null,
      },
      {
        id: 249,
        name: "Huyện Bình Xuyên",
        province_id: 26,
        created_at: "2024-01-28T17:20:45.514Z",
        updated_at: "2024-10-29T10:15:07.925Z",
        popular: false,
        slug: "huyen-binh-xuyen",
        deleted: null,
      },
      {
        id: 251,
        name: "Huyện Yên Lạc",
        province_id: 26,
        created_at: "2024-01-28T17:20:45.528Z",
        updated_at: "2024-10-29T10:15:07.933Z",
        popular: false,
        slug: "huyen-yen-lac",
        deleted: null,
      },
      {
        id: 252,
        name: "Huyện Vĩnh Tường",
        province_id: 26,
        created_at: "2024-01-28T17:20:45.539Z",
        updated_at: "2024-10-29T10:15:07.952Z",
        popular: false,
        slug: "huyen-vinh-tuong",
        deleted: null,
      },
      {
        id: 253,
        name: "Huyện Sông Lô",
        province_id: 26,
        created_at: "2024-01-28T17:20:45.552Z",
        updated_at: "2024-10-29T10:15:07.962Z",
        popular: false,
        slug: "huyen-song-lo",
        deleted: null,
      },
    ],
  },
  {
    id: 27,
    name: "Bắc Ninh",
    region: "north",
    created_at: "2024-01-28T17:20:42.389Z",
    updated_at: "2024-10-29T10:15:06.434Z",
    lat: 21.1587204,
    lon: 106.0615886,
    deleted: null,
    slug: "bac-ninh",
    district: [
      {
        id: 256,
        name: "Thành phố Bắc Ninh",
        province_id: 27,
        created_at: "2024-01-28T17:20:45.565Z",
        updated_at: "2024-10-29T10:15:07.969Z",
        popular: false,
        slug: "thanh-pho-bac-ninh",
        deleted: null,
      },
      {
        id: 258,
        name: "Huyện Yên Phong",
        province_id: 27,
        created_at: "2024-01-28T17:20:45.576Z",
        updated_at: "2024-10-29T10:15:07.977Z",
        popular: false,
        slug: "huyen-yen-phong",
        deleted: null,
      },
      {
        id: 259,
        name: "Thị xã Quế Võ",
        province_id: 27,
        created_at: "2024-01-28T17:20:45.588Z",
        updated_at: "2024-10-29T10:15:07.987Z",
        popular: false,
        slug: "thi-xa-que-vo",
        deleted: null,
      },
      {
        id: 260,
        name: "Huyện Tiên Du",
        province_id: 27,
        created_at: "2024-01-28T17:20:45.604Z",
        updated_at: "2024-10-29T10:15:07.992Z",
        popular: false,
        slug: "huyen-tien-du",
        deleted: null,
      },
      {
        id: 261,
        name: "Thành phố Từ Sơn",
        province_id: 27,
        created_at: "2024-01-28T17:20:45.614Z",
        updated_at: "2024-10-29T10:15:07.997Z",
        popular: false,
        slug: "thanh-pho-tu-son",
        deleted: null,
      },
      {
        id: 262,
        name: "Thị xã Thuận Thành",
        province_id: 27,
        created_at: "2024-01-28T17:20:45.628Z",
        updated_at: "2024-10-29T10:15:08.003Z",
        popular: false,
        slug: "thi-xa-thuan-thanh",
        deleted: null,
      },
      {
        id: 263,
        name: "Huyện Gia Bình",
        province_id: 27,
        created_at: "2024-01-28T17:20:45.642Z",
        updated_at: "2024-10-29T10:15:08.013Z",
        popular: false,
        slug: "huyen-gia-binh",
        deleted: null,
      },
      {
        id: 264,
        name: "Huyện Lương Tài",
        province_id: 27,
        created_at: "2024-01-28T17:20:45.653Z",
        updated_at: "2024-10-29T10:15:08.020Z",
        popular: false,
        slug: "huyen-luong-tai",
        deleted: null,
      },
    ],
  },
  {
    id: 30,
    name: "Hải Dương",
    region: "north",
    created_at: "2024-01-28T17:20:42.402Z",
    updated_at: "2024-10-29T10:15:06.440Z",
    lat: 20.93904,
    lon: 106.333179,
    deleted: null,
    slug: "hai-duong",
    district: [
      {
        id: 288,
        name: "Thành phố Hải Dương",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.672Z",
        updated_at: "2024-10-29T10:15:08.027Z",
        popular: false,
        slug: "thanh-pho-hai-duong",
        deleted: null,
      },
      {
        id: 290,
        name: "Thành phố Chí Linh",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.687Z",
        updated_at: "2024-10-29T10:15:08.060Z",
        popular: false,
        slug: "thanh-pho-chi-linh",
        deleted: null,
      },
      {
        id: 291,
        name: "Huyện Nam Sách",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.700Z",
        updated_at: "2024-10-29T10:15:08.069Z",
        popular: false,
        slug: "huyen-nam-sach",
        deleted: null,
      },
      {
        id: 292,
        name: "Thị xã Kinh Môn",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.716Z",
        updated_at: "2024-10-29T10:15:08.075Z",
        popular: false,
        slug: "thi-xa-kinh-mon",
        deleted: null,
      },
      {
        id: 293,
        name: "Huyện Kim Thành",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.730Z",
        updated_at: "2024-10-29T10:15:08.081Z",
        popular: false,
        slug: "huyen-kim-thanh",
        deleted: null,
      },
      {
        id: 294,
        name: "Huyện Thanh Hà",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.743Z",
        updated_at: "2024-10-29T10:15:08.087Z",
        popular: false,
        slug: "huyen-thanh-ha",
        deleted: null,
      },
      {
        id: 295,
        name: "Huyện Cẩm Giàng",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.756Z",
        updated_at: "2024-10-29T10:15:08.092Z",
        popular: false,
        slug: "huyen-cam-giang",
        deleted: null,
      },
      {
        id: 296,
        name: "Huyện Bình Giang",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.773Z",
        updated_at: "2024-10-29T10:15:08.101Z",
        popular: false,
        slug: "huyen-binh-giang",
        deleted: null,
      },
      {
        id: 297,
        name: "Huyện Gia Lộc",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.785Z",
        updated_at: "2024-10-29T10:15:08.107Z",
        popular: false,
        slug: "huyen-gia-loc",
        deleted: null,
      },
      {
        id: 298,
        name: "Huyện Tứ Kỳ",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.804Z",
        updated_at: "2024-10-29T10:15:08.113Z",
        popular: false,
        slug: "huyen-tu-ky",
        deleted: null,
      },
      {
        id: 299,
        name: "Huyện Ninh Giang",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.820Z",
        updated_at: "2024-10-29T10:15:08.119Z",
        popular: false,
        slug: "huyen-ninh-giang",
        deleted: null,
      },
      {
        id: 300,
        name: "Huyện Thanh Miện",
        province_id: 30,
        created_at: "2024-01-28T17:20:45.833Z",
        updated_at: "2024-10-29T10:15:08.126Z",
        popular: false,
        slug: "huyen-thanh-mien",
        deleted: null,
      },
    ],
  },
  {
    id: 31,
    name: "Hải Phòng",
    region: "north",
    created_at: "2024-01-28T17:20:42.414Z",
    updated_at: "2024-10-29T10:15:06.446Z",
    lat: 20.807195,
    lon: 106.7395801,
    deleted: null,
    slug: "hai-phong",
    district: [
      {
        id: 303,
        name: "Quận Hồng Bàng",
        province_id: 31,
        created_at: "2024-01-28T17:20:45.847Z",
        updated_at: "2024-10-29T10:15:08.132Z",
        popular: false,
        slug: "quan-hong-bang",
        deleted: null,
      },
      {
        id: 304,
        name: "Quận Ngô Quyền",
        province_id: 31,
        created_at: "2024-01-28T17:20:45.860Z",
        updated_at: "2024-10-29T10:15:08.137Z",
        popular: false,
        slug: "quan-ngo-quyen",
        deleted: null,
      },
      {
        id: 305,
        name: "Quận Lê Chân",
        province_id: 31,
        created_at: "2024-01-28T17:20:45.872Z",
        updated_at: "2024-10-29T10:15:08.143Z",
        popular: false,
        slug: "quan-le-chan",
        deleted: null,
      },
      {
        id: 306,
        name: "Quận Hải An",
        province_id: 31,
        created_at: "2024-01-28T17:20:45.890Z",
        updated_at: "2024-10-29T10:15:08.148Z",
        popular: false,
        slug: "quan-hai-an",
        deleted: null,
      },
      {
        id: 307,
        name: "Quận Kiến An",
        province_id: 31,
        created_at: "2024-01-28T17:20:45.904Z",
        updated_at: "2024-10-29T10:15:08.154Z",
        popular: false,
        slug: "quan-kien-an",
        deleted: null,
      },
      {
        id: 308,
        name: "Quận Đồ Sơn",
        province_id: 31,
        created_at: "2024-01-28T17:20:45.920Z",
        updated_at: "2024-10-29T10:15:08.160Z",
        popular: false,
        slug: "quan-do-son",
        deleted: null,
      },
      {
        id: 309,
        name: "Quận Dương Kinh",
        province_id: 31,
        created_at: "2024-01-28T17:20:45.935Z",
        updated_at: "2024-10-29T10:15:08.165Z",
        popular: false,
        slug: "quan-duong-kinh",
        deleted: null,
      },
      {
        id: 311,
        name: "Huyện Thuỷ Nguyên",
        province_id: 31,
        created_at: "2024-01-28T17:20:45.951Z",
        updated_at: "2024-10-29T10:15:08.171Z",
        popular: false,
        slug: "huyen-thuy-nguyen",
        deleted: null,
      },
      {
        id: 312,
        name: "Huyện An Dương",
        province_id: 31,
        created_at: "2024-01-28T17:20:45.965Z",
        updated_at: "2024-10-29T10:15:08.176Z",
        popular: false,
        slug: "huyen-an-duong",
        deleted: null,
      },
      {
        id: 313,
        name: "Huyện An Lão",
        province_id: 31,
        created_at: "2024-01-28T17:20:45.985Z",
        updated_at: "2024-10-29T10:15:08.181Z",
        popular: false,
        slug: "huyen-an-lao",
        deleted: null,
      },
      {
        id: 314,
        name: "Huyện Kiến Thuỵ",
        province_id: 31,
        created_at: "2024-01-28T17:20:46.002Z",
        updated_at: "2024-10-29T10:15:08.186Z",
        popular: false,
        slug: "huyen-kien-thuy",
        deleted: null,
      },
      {
        id: 315,
        name: "Huyện Tiên Lãng",
        province_id: 31,
        created_at: "2024-01-28T17:20:46.014Z",
        updated_at: "2024-10-29T10:15:08.191Z",
        popular: false,
        slug: "huyen-tien-lang",
        deleted: null,
      },
      {
        id: 316,
        name: "Huyện Vĩnh Bảo",
        province_id: 31,
        created_at: "2024-01-28T17:20:46.028Z",
        updated_at: "2024-10-29T10:15:08.196Z",
        popular: false,
        slug: "huyen-vinh-bao",
        deleted: null,
      },
      {
        id: 317,
        name: "Huyện Cát Hải",
        province_id: 31,
        created_at: "2024-01-28T17:20:46.043Z",
        updated_at: "2024-10-29T10:15:08.201Z",
        popular: false,
        slug: "huyen-cat-hai",
        deleted: null,
      },
      {
        id: 318,
        name: "Huyện Bạch Long Vĩ",
        province_id: 31,
        created_at: "2024-01-28T17:20:46.054Z",
        updated_at: "2024-10-29T10:15:08.207Z",
        popular: false,
        slug: "huyen-bach-long-vi",
        deleted: null,
      },
    ],
  },
  {
    id: 33,
    name: "Hưng Yên",
    region: "north",
    created_at: "2024-01-28T17:20:42.429Z",
    updated_at: "2024-10-29T10:15:06.452Z",
    lat: 20.646537,
    lon: 106.051134,
    deleted: null,
    slug: "hung-yen",
    district: [
      {
        id: 323,
        name: "Thành phố Hưng Yên",
        province_id: 33,
        created_at: "2024-01-28T17:20:46.068Z",
        updated_at: "2024-10-29T10:15:08.212Z",
        popular: false,
        slug: "thanh-pho-hung-yen",
        deleted: null,
      },
      {
        id: 325,
        name: "Huyện Văn Lâm",
        province_id: 33,
        created_at: "2024-01-28T17:20:46.083Z",
        updated_at: "2024-10-29T10:15:08.217Z",
        popular: false,
        slug: "huyen-van-lam",
        deleted: null,
      },
      {
        id: 326,
        name: "Huyện Văn Giang",
        province_id: 33,
        created_at: "2024-01-28T17:20:46.094Z",
        updated_at: "2024-10-29T10:15:08.222Z",
        popular: false,
        slug: "huyen-van-giang",
        deleted: null,
      },
      {
        id: 327,
        name: "Huyện Yên Mỹ",
        province_id: 33,
        created_at: "2024-01-28T17:20:46.108Z",
        updated_at: "2024-10-29T10:15:08.228Z",
        popular: false,
        slug: "huyen-yen-my",
        deleted: null,
      },
      {
        id: 328,
        name: "Thị xã Mỹ Hào",
        province_id: 33,
        created_at: "2024-01-28T17:20:46.121Z",
        updated_at: "2024-10-29T10:15:08.235Z",
        popular: false,
        slug: "thi-xa-my-hao",
        deleted: null,
      },
      {
        id: 329,
        name: "Huyện Ân Thi",
        province_id: 33,
        created_at: "2024-01-28T17:20:46.133Z",
        updated_at: "2024-10-29T10:15:08.240Z",
        popular: false,
        slug: "huyen-an-thi",
        deleted: null,
      },
      {
        id: 330,
        name: "Huyện Khoái Châu",
        province_id: 33,
        created_at: "2024-01-28T17:20:46.144Z",
        updated_at: "2024-10-29T10:15:08.246Z",
        popular: false,
        slug: "huyen-khoai-chau",
        deleted: null,
      },
      {
        id: 331,
        name: "Huyện Kim Động",
        province_id: 33,
        created_at: "2024-01-28T17:20:46.156Z",
        updated_at: "2024-10-29T10:15:08.251Z",
        popular: false,
        slug: "huyen-kim-dong",
        deleted: null,
      },
      {
        id: 332,
        name: "Huyện Tiên Lữ",
        province_id: 33,
        created_at: "2024-01-28T17:20:46.171Z",
        updated_at: "2024-10-29T10:15:08.257Z",
        popular: false,
        slug: "huyen-tien-lu",
        deleted: null,
      },
      {
        id: 333,
        name: "Huyện Phù Cừ",
        province_id: 33,
        created_at: "2024-01-28T17:20:46.184Z",
        updated_at: "2024-10-29T10:15:08.272Z",
        popular: false,
        slug: "huyen-phu-cu",
        deleted: null,
      },
    ],
  },
  {
    id: 34,
    name: "Thái Bình",
    region: "north",
    created_at: "2024-01-28T17:20:42.444Z",
    updated_at: "2024-10-29T10:15:06.458Z",
    lat: 20.446002,
    lon: 106.336582,
    deleted: null,
    slug: "thai-binh",
    district: [
      {
        id: 336,
        name: "Thành phố Thái Bình",
        province_id: 34,
        created_at: "2024-01-28T17:20:46.199Z",
        updated_at: "2024-10-29T10:15:08.279Z",
        popular: false,
        slug: "thanh-pho-thai-binh",
        deleted: null,
      },
      {
        id: 338,
        name: "Huyện Quỳnh Phụ",
        province_id: 34,
        created_at: "2024-01-28T17:20:46.212Z",
        updated_at: "2024-10-29T10:15:08.284Z",
        popular: false,
        slug: "huyen-quynh-phu",
        deleted: null,
      },
      {
        id: 339,
        name: "Huyện Hưng Hà",
        province_id: 34,
        created_at: "2024-01-28T17:20:46.228Z",
        updated_at: "2024-10-29T10:15:08.290Z",
        popular: false,
        slug: "huyen-hung-ha",
        deleted: null,
      },
      {
        id: 340,
        name: "Huyện Đông Hưng",
        province_id: 34,
        created_at: "2024-01-28T17:20:46.244Z",
        updated_at: "2024-10-29T10:15:08.296Z",
        popular: false,
        slug: "huyen-dong-hung",
        deleted: null,
      },
      {
        id: 341,
        name: "Huyện Thái Thụy",
        province_id: 34,
        created_at: "2024-01-28T17:20:46.260Z",
        updated_at: "2024-10-29T10:15:08.302Z",
        popular: false,
        slug: "huyen-thai-thuy",
        deleted: null,
      },
      {
        id: 342,
        name: "Huyện Tiền Hải",
        province_id: 34,
        created_at: "2024-01-28T17:20:46.272Z",
        updated_at: "2024-10-29T10:15:08.307Z",
        popular: false,
        slug: "huyen-tien-hai",
        deleted: null,
      },
      {
        id: 343,
        name: "Huyện Kiến Xương",
        province_id: 34,
        created_at: "2024-01-28T17:20:46.284Z",
        updated_at: "2024-10-29T10:15:08.312Z",
        popular: false,
        slug: "huyen-kien-xuong",
        deleted: null,
      },
      {
        id: 344,
        name: "Huyện Vũ Thư",
        province_id: 34,
        created_at: "2024-01-28T17:20:46.301Z",
        updated_at: "2024-10-29T10:15:08.317Z",
        popular: false,
        slug: "huyen-vu-thu",
        deleted: null,
      },
    ],
  },
  {
    id: 35,
    name: "Hà Nam",
    region: "north",
    created_at: "2024-01-28T17:20:42.456Z",
    updated_at: "2024-10-29T10:15:06.464Z",
    lat: 20.583519,
    lon: 105.92299,
    deleted: null,
    slug: "ha-nam",
    district: [
      {
        id: 347,
        name: "Thành phố Phủ Lý",
        province_id: 35,
        created_at: "2024-01-28T17:20:46.313Z",
        updated_at: "2024-10-29T10:15:08.328Z",
        popular: false,
        slug: "thanh-pho-phu-ly",
        deleted: null,
      },
      {
        id: 349,
        name: "Thị xã Duy Tiên",
        province_id: 35,
        created_at: "2024-01-28T17:20:46.328Z",
        updated_at: "2024-10-29T10:15:08.333Z",
        popular: false,
        slug: "thi-xa-duy-tien",
        deleted: null,
      },
      {
        id: 350,
        name: "Huyện Kim Bảng",
        province_id: 35,
        created_at: "2024-01-28T17:20:46.343Z",
        updated_at: "2024-10-29T10:15:08.339Z",
        popular: false,
        slug: "huyen-kim-bang",
        deleted: null,
      },
      {
        id: 351,
        name: "Huyện Thanh Liêm",
        province_id: 35,
        created_at: "2024-01-28T17:20:46.355Z",
        updated_at: "2024-10-29T10:15:08.344Z",
        popular: false,
        slug: "huyen-thanh-liem",
        deleted: null,
      },
      {
        id: 352,
        name: "Huyện Bình Lục",
        province_id: 35,
        created_at: "2024-01-28T17:20:46.368Z",
        updated_at: "2024-10-29T10:15:08.350Z",
        popular: false,
        slug: "huyen-binh-luc",
        deleted: null,
      },
      {
        id: 353,
        name: "Huyện Lý Nhân",
        province_id: 35,
        created_at: "2024-01-28T17:20:46.384Z",
        updated_at: "2024-10-29T10:15:08.355Z",
        popular: false,
        slug: "huyen-ly-nhan",
        deleted: null,
      },
    ],
  },
  {
    id: 36,
    name: "Nam Định",
    region: "north",
    created_at: "2024-01-28T17:20:42.468Z",
    updated_at: "2024-10-29T10:15:06.471Z",
    lat: 20.43882,
    lon: 106.162106,
    deleted: null,
    slug: "nam-dinh",
    district: [
      {
        id: 356,
        name: "Thành phố Nam Định",
        province_id: 36,
        created_at: "2024-01-28T17:20:46.395Z",
        updated_at: "2024-10-29T10:15:08.361Z",
        popular: false,
        slug: "thanh-pho-nam-dinh",
        deleted: null,
      },
      {
        id: 358,
        name: "Huyện Mỹ Lộc",
        province_id: 36,
        created_at: "2024-01-28T17:20:46.408Z",
        updated_at: "2024-10-29T10:15:08.366Z",
        popular: false,
        slug: "huyen-my-loc",
        deleted: null,
      },
      {
        id: 359,
        name: "Huyện Vụ Bản",
        province_id: 36,
        created_at: "2024-01-28T17:20:46.423Z",
        updated_at: "2024-10-29T10:15:08.373Z",
        popular: false,
        slug: "huyen-vu-ban",
        deleted: null,
      },
      {
        id: 360,
        name: "Huyện Ý Yên",
        province_id: 36,
        created_at: "2024-01-28T17:20:46.435Z",
        updated_at: "2024-10-29T10:15:08.378Z",
        popular: false,
        slug: "huyen-y-yen",
        deleted: null,
      },
      {
        id: 361,
        name: "Huyện Nghĩa Hưng",
        province_id: 36,
        created_at: "2024-01-28T17:20:46.448Z",
        updated_at: "2024-10-29T10:15:08.385Z",
        popular: false,
        slug: "huyen-nghia-hung",
        deleted: null,
      },
      {
        id: 362,
        name: "Huyện Nam Trực",
        province_id: 36,
        created_at: "2024-01-28T17:20:46.463Z",
        updated_at: "2024-10-29T10:15:08.390Z",
        popular: false,
        slug: "huyen-nam-truc",
        deleted: null,
      },
      {
        id: 363,
        name: "Huyện Trực Ninh",
        province_id: 36,
        created_at: "2024-01-28T17:20:46.475Z",
        updated_at: "2024-10-29T10:15:08.396Z",
        popular: false,
        slug: "huyen-truc-ninh",
        deleted: null,
      },
      {
        id: 364,
        name: "Huyện Xuân Trường",
        province_id: 36,
        created_at: "2024-01-28T17:20:46.488Z",
        updated_at: "2024-10-29T10:15:08.402Z",
        popular: false,
        slug: "huyen-xuan-truong",
        deleted: null,
      },
      {
        id: 365,
        name: "Huyện Giao Thủy",
        province_id: 36,
        created_at: "2024-01-28T17:20:46.505Z",
        updated_at: "2024-10-29T10:15:08.409Z",
        popular: false,
        slug: "huyen-giao-thuy",
        deleted: null,
      },
      {
        id: 366,
        name: "Huyện Hải Hậu",
        province_id: 36,
        created_at: "2024-01-28T17:20:46.517Z",
        updated_at: "2024-10-29T10:15:08.415Z",
        popular: false,
        slug: "huyen-hai-hau",
        deleted: null,
      },
    ],
  },
  {
    id: 37,
    name: "Ninh Bình",
    region: "north",
    created_at: "2024-01-28T17:20:42.484Z",
    updated_at: "2024-10-29T10:15:06.476Z",
    lat: 20.250614,
    lon: 105.974454,
    deleted: null,
    slug: "ninh-binh",
    district: [
      {
        id: 369,
        name: "Thành phố Ninh Bình",
        province_id: 37,
        created_at: "2024-01-28T17:20:46.531Z",
        updated_at: "2024-10-29T10:15:08.420Z",
        popular: false,
        slug: "thanh-pho-ninh-binh",
        deleted: null,
      },
      {
        id: 370,
        name: "Thành phố Tam Điệp",
        province_id: 37,
        created_at: "2024-01-28T17:20:46.544Z",
        updated_at: "2024-10-29T10:15:08.425Z",
        popular: false,
        slug: "thanh-pho-tam-diep",
        deleted: null,
      },
      {
        id: 372,
        name: "Huyện Nho Quan",
        province_id: 37,
        created_at: "2024-01-28T17:20:46.559Z",
        updated_at: "2024-10-29T10:15:08.431Z",
        popular: false,
        slug: "huyen-nho-quan",
        deleted: null,
      },
      {
        id: 373,
        name: "Huyện Gia Viễn",
        province_id: 37,
        created_at: "2024-01-28T17:20:46.572Z",
        updated_at: "2024-10-29T10:15:08.435Z",
        popular: false,
        slug: "huyen-gia-vien",
        deleted: null,
      },
      {
        id: 374,
        name: "Huyện Hoa Lư",
        province_id: 37,
        created_at: "2024-01-28T17:20:46.586Z",
        updated_at: "2024-10-29T10:15:08.441Z",
        popular: false,
        slug: "huyen-hoa-lu",
        deleted: null,
      },
      {
        id: 375,
        name: "Huyện Yên Khánh",
        province_id: 37,
        created_at: "2024-01-28T17:20:46.599Z",
        updated_at: "2024-10-29T10:15:08.446Z",
        popular: false,
        slug: "huyen-yen-khanh",
        deleted: null,
      },
      {
        id: 376,
        name: "Huyện Kim Sơn",
        province_id: 37,
        created_at: "2024-01-28T17:20:46.612Z",
        updated_at: "2024-10-29T10:15:08.452Z",
        popular: false,
        slug: "huyen-kim-son",
        deleted: null,
      },
      {
        id: 377,
        name: "Huyện Yên Mô",
        province_id: 37,
        created_at: "2024-01-28T17:20:46.625Z",
        updated_at: "2024-10-29T10:15:08.458Z",
        popular: false,
        slug: "huyen-yen-mo",
        deleted: null,
      },
    ],
  },
  {
    id: 38,
    name: "Thanh Hóa",
    region: "middle",
    created_at: "2024-01-28T17:20:42.498Z",
    updated_at: "2024-10-29T10:15:06.483Z",
    lat: 19.810843,
    lon: 105.7798792,
    deleted: null,
    slug: "thanh-hoa",
    district: [
      {
        id: 380,
        name: "Thành phố Thanh Hóa",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.638Z",
        updated_at: "2024-10-29T10:15:08.463Z",
        popular: false,
        slug: "thanh-pho-thanh-hoa",
        deleted: null,
      },
      {
        id: 381,
        name: "Thị xã Bỉm Sơn",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.651Z",
        updated_at: "2024-10-29T10:15:08.468Z",
        popular: false,
        slug: "thi-xa-bim-son",
        deleted: null,
      },
      {
        id: 382,
        name: "Thành phố Sầm Sơn",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.669Z",
        updated_at: "2024-10-29T10:15:08.474Z",
        popular: false,
        slug: "thanh-pho-sam-son",
        deleted: null,
      },
      {
        id: 384,
        name: "Huyện Mường Lát",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.683Z",
        updated_at: "2024-10-29T10:15:08.478Z",
        popular: false,
        slug: "huyen-muong-lat",
        deleted: null,
      },
      {
        id: 385,
        name: "Huyện Quan Hóa",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.695Z",
        updated_at: "2024-10-29T10:15:08.485Z",
        popular: false,
        slug: "huyen-quan-hoa",
        deleted: null,
      },
      {
        id: 386,
        name: "Huyện Bá Thước",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.708Z",
        updated_at: "2024-10-29T10:15:08.491Z",
        popular: false,
        slug: "huyen-ba-thuoc",
        deleted: null,
      },
      {
        id: 387,
        name: "Huyện Quan Sơn",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.722Z",
        updated_at: "2024-10-29T10:15:08.499Z",
        popular: false,
        slug: "huyen-quan-son",
        deleted: null,
      },
      {
        id: 388,
        name: "Huyện Lang Chánh",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.734Z",
        updated_at: "2024-10-29T10:15:08.505Z",
        popular: false,
        slug: "huyen-lang-chanh",
        deleted: null,
      },
      {
        id: 389,
        name: "Huyện Ngọc Lặc",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.747Z",
        updated_at: "2024-10-29T10:15:08.512Z",
        popular: false,
        slug: "huyen-ngoc-lac",
        deleted: null,
      },
      {
        id: 390,
        name: "Huyện Cẩm Thủy",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.760Z",
        updated_at: "2024-10-29T10:15:08.518Z",
        popular: false,
        slug: "huyen-cam-thuy",
        deleted: null,
      },
      {
        id: 391,
        name: "Huyện Thạch Thành",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.776Z",
        updated_at: "2024-10-29T10:15:08.525Z",
        popular: false,
        slug: "huyen-thach-thanh",
        deleted: null,
      },
      {
        id: 392,
        name: "Huyện Hà Trung",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.789Z",
        updated_at: "2024-10-29T10:15:08.531Z",
        popular: false,
        slug: "huyen-ha-trung",
        deleted: null,
      },
      {
        id: 393,
        name: "Huyện Vĩnh Lộc",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.803Z",
        updated_at: "2024-10-29T10:15:08.543Z",
        popular: false,
        slug: "huyen-vinh-loc",
        deleted: null,
      },
      {
        id: 394,
        name: "Huyện Yên Định",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.814Z",
        updated_at: "2024-10-29T10:15:08.550Z",
        popular: false,
        slug: "huyen-yen-dinh",
        deleted: null,
      },
      {
        id: 395,
        name: "Huyện Thọ Xuân",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.827Z",
        updated_at: "2024-10-29T10:15:08.558Z",
        popular: false,
        slug: "huyen-tho-xuan",
        deleted: null,
      },
      {
        id: 396,
        name: "Huyện Thường Xuân",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.840Z",
        updated_at: "2024-10-29T10:15:08.567Z",
        popular: false,
        slug: "huyen-thuong-xuan",
        deleted: null,
      },
      {
        id: 397,
        name: "Huyện Triệu Sơn",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.852Z",
        updated_at: "2024-10-29T10:15:08.575Z",
        popular: false,
        slug: "huyen-trieu-son",
        deleted: null,
      },
      {
        id: 398,
        name: "Huyện Thiệu Hóa",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.867Z",
        updated_at: "2024-10-29T10:15:08.583Z",
        popular: false,
        slug: "huyen-thieu-hoa",
        deleted: null,
      },
      {
        id: 399,
        name: "Huyện Hoằng Hóa",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.879Z",
        updated_at: "2024-10-29T10:15:08.589Z",
        popular: false,
        slug: "huyen-hoang-hoa",
        deleted: null,
      },
      {
        id: 400,
        name: "Huyện Hậu Lộc",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.892Z",
        updated_at: "2024-10-29T10:15:08.596Z",
        popular: false,
        slug: "huyen-hau-loc",
        deleted: null,
      },
      {
        id: 401,
        name: "Huyện Nga Sơn",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.907Z",
        updated_at: "2024-10-29T10:15:08.602Z",
        popular: false,
        slug: "huyen-nga-son",
        deleted: null,
      },
      {
        id: 402,
        name: "Huyện Như Xuân",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.919Z",
        updated_at: "2024-10-29T10:15:08.608Z",
        popular: false,
        slug: "huyen-nhu-xuan",
        deleted: null,
      },
      {
        id: 403,
        name: "Huyện Như Thanh",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.935Z",
        updated_at: "2024-10-29T10:15:08.614Z",
        popular: false,
        slug: "huyen-nhu-thanh",
        deleted: null,
      },
      {
        id: 404,
        name: "Huyện Nông Cống",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.947Z",
        updated_at: "2024-10-29T10:15:08.621Z",
        popular: false,
        slug: "huyen-nong-cong",
        deleted: null,
      },
      {
        id: 405,
        name: "Huyện Đông Sơn",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.960Z",
        updated_at: "2024-10-29T10:15:08.627Z",
        popular: false,
        slug: "huyen-dong-son",
        deleted: null,
      },
      {
        id: 406,
        name: "Huyện Quảng Xương",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.977Z",
        updated_at: "2024-10-29T10:15:08.632Z",
        popular: false,
        slug: "huyen-quang-xuong",
        deleted: null,
      },
      {
        id: 407,
        name: "Thị xã Nghi Sơn",
        province_id: 38,
        created_at: "2024-01-28T17:20:46.989Z",
        updated_at: "2024-10-29T10:15:08.640Z",
        popular: false,
        slug: "thi-xa-nghi-son",
        deleted: null,
      },
    ],
  },
  {
    id: 40,
    name: "Nghệ An",
    region: "middle",
    created_at: "2024-01-28T17:20:42.512Z",
    updated_at: "2024-10-29T10:15:06.488Z",
    lat: 19.233704,
    lon: 105.0890141,
    deleted: null,
    slug: "nghe-an",
    district: [
      {
        id: 412,
        name: "Thành phố Vinh",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.008Z",
        updated_at: "2024-10-29T10:15:08.645Z",
        popular: false,
        slug: "thanh-pho-vinh",
        deleted: null,
      },
      {
        id: 413,
        name: "Thị xã Cửa Lò",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.027Z",
        updated_at: "2024-10-29T10:15:08.655Z",
        popular: false,
        slug: "thi-xa-cua-lo",
        deleted: null,
      },
      {
        id: 414,
        name: "Thị xã Thái Hoà",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.048Z",
        updated_at: "2024-10-29T10:15:08.663Z",
        popular: false,
        slug: "thi-xa-thai-hoa",
        deleted: null,
      },
      {
        id: 415,
        name: "Huyện Quế Phong",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.066Z",
        updated_at: "2024-10-29T10:15:08.672Z",
        popular: false,
        slug: "huyen-que-phong",
        deleted: null,
      },
      {
        id: 416,
        name: "Huyện Quỳ Châu",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.084Z",
        updated_at: "2024-10-29T10:15:08.681Z",
        popular: false,
        slug: "huyen-quy-chau",
        deleted: null,
      },
      {
        id: 417,
        name: "Huyện Kỳ Sơn",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.100Z",
        updated_at: "2024-10-29T10:15:08.688Z",
        popular: false,
        slug: "huyen-ky-son",
        deleted: null,
      },
      {
        id: 418,
        name: "Huyện Tương Dương",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.117Z",
        updated_at: "2024-10-29T10:15:08.693Z",
        popular: false,
        slug: "huyen-tuong-duong",
        deleted: null,
      },
      {
        id: 419,
        name: "Huyện Nghĩa Đàn",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.129Z",
        updated_at: "2024-10-29T10:15:08.705Z",
        popular: false,
        slug: "huyen-nghia-dan",
        deleted: null,
      },
      {
        id: 420,
        name: "Huyện Quỳ Hợp",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.143Z",
        updated_at: "2024-10-29T10:15:08.711Z",
        popular: false,
        slug: "huyen-quy-hop",
        deleted: null,
      },
      {
        id: 421,
        name: "Huyện Quỳnh Lưu",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.156Z",
        updated_at: "2024-10-29T10:15:08.717Z",
        popular: false,
        slug: "huyen-quynh-luu",
        deleted: null,
      },
      {
        id: 422,
        name: "Huyện Con Cuông",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.171Z",
        updated_at: "2024-10-29T10:15:08.727Z",
        popular: false,
        slug: "huyen-con-cuong",
        deleted: null,
      },
      {
        id: 423,
        name: "Huyện Tân Kỳ",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.184Z",
        updated_at: "2024-10-29T10:15:08.735Z",
        popular: false,
        slug: "huyen-tan-ky",
        deleted: null,
      },
      {
        id: 424,
        name: "Huyện Anh Sơn",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.199Z",
        updated_at: "2024-10-29T10:15:08.744Z",
        popular: false,
        slug: "huyen-anh-son",
        deleted: null,
      },
      {
        id: 425,
        name: "Huyện Diễn Châu",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.211Z",
        updated_at: "2024-10-29T10:15:08.751Z",
        popular: false,
        slug: "huyen-dien-chau",
        deleted: null,
      },
      {
        id: 426,
        name: "Huyện Yên Thành",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.224Z",
        updated_at: "2024-10-29T10:15:08.758Z",
        popular: false,
        slug: "huyen-yen-thanh",
        deleted: null,
      },
      {
        id: 427,
        name: "Huyện Đô Lương",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.237Z",
        updated_at: "2024-10-29T10:15:08.765Z",
        popular: false,
        slug: "huyen-do-luong",
        deleted: null,
      },
      {
        id: 428,
        name: "Huyện Thanh Chương",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.252Z",
        updated_at: "2024-10-29T10:15:08.771Z",
        popular: false,
        slug: "huyen-thanh-chuong",
        deleted: null,
      },
      {
        id: 429,
        name: "Huyện Nghi Lộc",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.267Z",
        updated_at: "2024-10-29T10:15:08.776Z",
        popular: false,
        slug: "huyen-nghi-loc",
        deleted: null,
      },
      {
        id: 430,
        name: "Huyện Nam Đàn",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.279Z",
        updated_at: "2024-10-29T10:15:08.781Z",
        popular: false,
        slug: "huyen-nam-dan",
        deleted: null,
      },
      {
        id: 431,
        name: "Huyện Hưng Nguyên",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.292Z",
        updated_at: "2024-10-29T10:15:08.787Z",
        popular: false,
        slug: "huyen-hung-nguyen",
        deleted: null,
      },
      {
        id: 432,
        name: "Thị xã Hoàng Mai",
        province_id: 40,
        created_at: "2024-01-28T17:20:47.306Z",
        updated_at: "2024-10-29T10:15:08.793Z",
        popular: false,
        slug: "thi-xa-hoang-mai",
        deleted: null,
      },
    ],
  },
  {
    id: 42,
    name: "Hà Tĩnh",
    region: "middle",
    created_at: "2024-01-28T17:20:42.527Z",
    updated_at: "2024-10-29T10:15:06.495Z",
    lat: 18.355382,
    lon: 105.900938,
    deleted: null,
    slug: "ha-tinh",
    district: [
      {
        id: 436,
        name: "Thành phố Hà Tĩnh",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.318Z",
        updated_at: "2024-10-29T10:15:08.801Z",
        popular: false,
        slug: "thanh-pho-ha-tinh",
        deleted: null,
      },
      {
        id: 437,
        name: "Thị xã Hồng Lĩnh",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.331Z",
        updated_at: "2024-10-29T10:15:08.807Z",
        popular: false,
        slug: "thi-xa-hong-linh",
        deleted: null,
      },
      {
        id: 439,
        name: "Huyện Hương Sơn",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.342Z",
        updated_at: "2024-10-29T10:15:08.824Z",
        popular: false,
        slug: "huyen-huong-son",
        deleted: null,
      },
      {
        id: 440,
        name: "Huyện Đức Thọ",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.355Z",
        updated_at: "2024-10-29T10:15:08.830Z",
        popular: false,
        slug: "huyen-duc-tho",
        deleted: null,
      },
      {
        id: 441,
        name: "Huyện Vũ Quang",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.367Z",
        updated_at: "2024-10-29T10:15:08.837Z",
        popular: false,
        slug: "huyen-vu-quang",
        deleted: null,
      },
      {
        id: 442,
        name: "Huyện Nghi Xuân",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.379Z",
        updated_at: "2024-10-29T10:15:08.844Z",
        popular: false,
        slug: "huyen-nghi-xuan",
        deleted: null,
      },
      {
        id: 443,
        name: "Huyện Can Lộc",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.392Z",
        updated_at: "2024-10-29T10:15:08.851Z",
        popular: false,
        slug: "huyen-can-loc",
        deleted: null,
      },
      {
        id: 444,
        name: "Huyện Hương Khê",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.408Z",
        updated_at: "2024-10-29T10:15:08.857Z",
        popular: false,
        slug: "huyen-huong-khe",
        deleted: null,
      },
      {
        id: 445,
        name: "Huyện Thạch Hà",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.420Z",
        updated_at: "2024-10-29T10:15:08.866Z",
        popular: false,
        slug: "huyen-thach-ha",
        deleted: null,
      },
      {
        id: 446,
        name: "Huyện Cẩm Xuyên",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.452Z",
        updated_at: "2024-10-29T10:15:08.873Z",
        popular: false,
        slug: "huyen-cam-xuyen",
        deleted: null,
      },
      {
        id: 447,
        name: "Huyện Kỳ Anh",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.468Z",
        updated_at: "2024-10-29T10:15:08.880Z",
        popular: false,
        slug: "huyen-ky-anh",
        deleted: null,
      },
      {
        id: 448,
        name: "Huyện Lộc Hà",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.480Z",
        updated_at: "2024-10-29T10:15:08.886Z",
        popular: false,
        slug: "huyen-loc-ha",
        deleted: null,
      },
      {
        id: 449,
        name: "Thị xã Kỳ Anh",
        province_id: 42,
        created_at: "2024-01-28T17:20:47.492Z",
        updated_at: "2024-10-29T10:15:08.892Z",
        popular: false,
        slug: "thi-xa-ky-anh",
        deleted: null,
      },
    ],
  },
  {
    id: 44,
    name: "Quảng Bình",
    region: "middle",
    created_at: "2024-01-28T17:20:42.538Z",
    updated_at: "2024-10-29T10:15:06.500Z",
    lat: 17.470835,
    lon: 106.622125,
    deleted: null,
    slug: "quang-binh",
    district: [
      {
        id: 450,
        name: "Thành Phố Đồng Hới",
        province_id: 44,
        created_at: "2024-01-28T17:20:47.508Z",
        updated_at: "2024-10-29T10:15:08.898Z",
        popular: false,
        slug: "thanh-pho-dong-hoi",
        deleted: null,
      },
      {
        id: 452,
        name: "Huyện Minh Hóa",
        province_id: 44,
        created_at: "2024-01-28T17:20:47.520Z",
        updated_at: "2024-10-29T10:15:08.903Z",
        popular: false,
        slug: "huyen-minh-hoa",
        deleted: null,
      },
      {
        id: 453,
        name: "Huyện Tuyên Hóa",
        province_id: 44,
        created_at: "2024-01-28T17:20:47.535Z",
        updated_at: "2024-10-29T10:15:08.908Z",
        popular: false,
        slug: "huyen-tuyen-hoa",
        deleted: null,
      },
      {
        id: 454,
        name: "Huyện Quảng Trạch",
        province_id: 44,
        created_at: "2024-01-28T17:20:47.547Z",
        updated_at: "2024-10-29T10:15:08.914Z",
        popular: false,
        slug: "huyen-quang-trach",
        deleted: null,
      },
      {
        id: 455,
        name: "Huyện Bố Trạch",
        province_id: 44,
        created_at: "2024-01-28T17:20:47.560Z",
        updated_at: "2024-10-29T10:15:08.919Z",
        popular: false,
        slug: "huyen-bo-trach",
        deleted: null,
      },
      {
        id: 456,
        name: "Huyện Quảng Ninh",
        province_id: 44,
        created_at: "2024-01-28T17:20:47.576Z",
        updated_at: "2024-10-29T10:15:08.924Z",
        popular: false,
        slug: "huyen-quang-ninh",
        deleted: null,
      },
      {
        id: 457,
        name: "Huyện Lệ Thủy",
        province_id: 44,
        created_at: "2024-01-28T17:20:47.591Z",
        updated_at: "2024-10-29T10:15:08.929Z",
        popular: false,
        slug: "huyen-le-thuy",
        deleted: null,
      },
      {
        id: 458,
        name: "Thị xã Ba Đồn",
        province_id: 44,
        created_at: "2024-01-28T17:20:47.608Z",
        updated_at: "2024-10-29T10:15:08.936Z",
        popular: false,
        slug: "thi-xa-ba-don",
        deleted: null,
      },
    ],
  },
  {
    id: 45,
    name: "Quảng Trị",
    region: "middle",
    created_at: "2024-01-28T17:20:42.552Z",
    updated_at: "2024-10-29T10:15:06.506Z",
    lat: 16.787238,
    lon: 106.901729,
    deleted: null,
    slug: "quang-tri",
    district: [
      {
        id: 461,
        name: "Thành phố Đông Hà",
        province_id: 45,
        created_at: "2024-01-28T17:20:47.622Z",
        updated_at: "2024-10-29T10:15:08.942Z",
        popular: false,
        slug: "thanh-pho-dong-ha",
        deleted: null,
      },
      {
        id: 462,
        name: "Thị xã Quảng Trị",
        province_id: 45,
        created_at: "2024-01-28T17:20:47.634Z",
        updated_at: "2024-10-29T10:15:08.951Z",
        popular: false,
        slug: "thi-xa-quang-tri",
        deleted: null,
      },
      {
        id: 464,
        name: "Huyện Vĩnh Linh",
        province_id: 45,
        created_at: "2024-01-28T17:20:47.649Z",
        updated_at: "2024-10-29T10:15:08.958Z",
        popular: false,
        slug: "huyen-vinh-linh",
        deleted: null,
      },
      {
        id: 465,
        name: "Huyện Hướng Hóa",
        province_id: 45,
        created_at: "2024-01-28T17:20:47.665Z",
        updated_at: "2024-10-29T10:15:08.965Z",
        popular: false,
        slug: "huyen-huong-hoa",
        deleted: null,
      },
      {
        id: 466,
        name: "Huyện Gio Linh",
        province_id: 45,
        created_at: "2024-01-28T17:20:47.680Z",
        updated_at: "2024-10-29T10:15:08.972Z",
        popular: false,
        slug: "huyen-gio-linh",
        deleted: null,
      },
      {
        id: 467,
        name: "Huyện Đa Krông",
        province_id: 45,
        created_at: "2024-01-28T17:20:47.692Z",
        updated_at: "2024-10-29T10:15:08.981Z",
        popular: false,
        slug: "huyen-da-krong",
        deleted: null,
      },
      {
        id: 468,
        name: "Huyện Cam Lộ",
        province_id: 45,
        created_at: "2024-01-28T17:20:47.704Z",
        updated_at: "2024-10-29T10:15:08.987Z",
        popular: false,
        slug: "huyen-cam-lo",
        deleted: null,
      },
      {
        id: 469,
        name: "Huyện Triệu Phong",
        province_id: 45,
        created_at: "2024-01-28T17:20:47.721Z",
        updated_at: "2024-10-29T10:15:08.994Z",
        popular: false,
        slug: "huyen-trieu-phong",
        deleted: null,
      },
      {
        id: 470,
        name: "Huyện Hải Lăng",
        province_id: 45,
        created_at: "2024-01-28T17:20:47.734Z",
        updated_at: "2024-10-29T10:15:09.002Z",
        popular: false,
        slug: "huyen-hai-lang",
        deleted: null,
      },
      {
        id: 471,
        name: "Huyện Cồn Cỏ",
        province_id: 45,
        created_at: "2024-01-28T17:20:47.746Z",
        updated_at: "2024-10-29T10:15:09.011Z",
        popular: false,
        slug: "huyen-con-co",
        deleted: null,
      },
    ],
  },
  {
    id: 46,
    name: "Thừa Thiên Huế",
    region: "middle",
    created_at: "2024-01-28T17:20:42.565Z",
    updated_at: "2024-10-29T10:15:06.513Z",
    lat: 16.3150931,
    lon: 107.5376741,
    deleted: null,
    slug: "thua-thien-hue",
    district: [
      {
        id: 474,
        name: "Thành phố Huế",
        province_id: 46,
        created_at: "2024-01-28T17:20:47.760Z",
        updated_at: "2024-10-29T10:15:09.018Z",
        popular: false,
        slug: "thanh-pho-hue",
        deleted: null,
      },
      {
        id: 476,
        name: "Huyện Phong Điền",
        province_id: 46,
        created_at: "2024-01-28T17:20:47.778Z",
        updated_at: "2024-10-29T10:15:09.025Z",
        popular: false,
        slug: "huyen-phong-dien",
        deleted: null,
      },
      {
        id: 477,
        name: "Huyện Quảng Điền",
        province_id: 46,
        created_at: "2024-01-28T17:20:47.791Z",
        updated_at: "2024-10-29T10:15:09.034Z",
        popular: false,
        slug: "huyen-quang-dien",
        deleted: null,
      },
      {
        id: 478,
        name: "Huyện Phú Vang",
        province_id: 46,
        created_at: "2024-01-28T17:20:47.804Z",
        updated_at: "2024-10-29T10:15:09.041Z",
        popular: false,
        slug: "huyen-phu-vang",
        deleted: null,
      },
      {
        id: 479,
        name: "Thị xã Hương Thủy",
        province_id: 46,
        created_at: "2024-01-28T17:20:47.819Z",
        updated_at: "2024-10-29T10:15:09.048Z",
        popular: false,
        slug: "thi-xa-huong-thuy",
        deleted: null,
      },
      {
        id: 480,
        name: "Thị xã Hương Trà",
        province_id: 46,
        created_at: "2024-01-28T17:20:47.831Z",
        updated_at: "2024-10-29T10:15:09.056Z",
        popular: false,
        slug: "thi-xa-huong-tra",
        deleted: null,
      },
      {
        id: 481,
        name: "Huyện A Lưới",
        province_id: 46,
        created_at: "2024-01-28T17:20:47.844Z",
        updated_at: "2024-10-29T10:15:09.070Z",
        popular: false,
        slug: "huyen-a-luoi",
        deleted: null,
      },
      {
        id: 482,
        name: "Huyện Phú Lộc",
        province_id: 46,
        created_at: "2024-01-28T17:20:47.855Z",
        updated_at: "2024-10-29T10:15:09.079Z",
        popular: false,
        slug: "huyen-phu-loc",
        deleted: null,
      },
      {
        id: 483,
        name: "Huyện Nam Đông",
        province_id: 46,
        created_at: "2024-01-28T17:20:47.868Z",
        updated_at: "2024-10-29T10:15:09.086Z",
        popular: false,
        slug: "huyen-nam-dong",
        deleted: null,
      },
    ],
  },
  {
    id: 48,
    name: "Đà Nẵng",
    region: "middle",
    created_at: "2024-01-28T17:20:42.577Z",
    updated_at: "2024-10-29T10:15:06.521Z",
    lat: 16.0726671,
    lon: 108.0925761,
    deleted: null,
    slug: "da-nang",
    district: [
      {
        id: 490,
        name: "Liên Chiểu",
        province_id: 48,
        created_at: "2024-01-28T17:20:47.882Z",
        updated_at: "2024-10-29T10:15:09.093Z",
        popular: true,
        slug: "lien-chieu",
        deleted: null,
      },
      {
        id: 491,
        name: "Thanh Khê",
        province_id: 48,
        created_at: "2024-01-28T17:20:47.894Z",
        updated_at: "2024-10-29T10:15:09.098Z",
        popular: true,
        slug: "thanh-khe",
        deleted: null,
      },
      {
        id: 492,
        name: "Hải Châu",
        province_id: 48,
        created_at: "2024-01-28T17:20:47.907Z",
        updated_at: "2024-10-29T10:15:09.104Z",
        popular: true,
        slug: "hai-chau",
        deleted: null,
      },
      {
        id: 493,
        name: "Sơn Trà",
        province_id: 48,
        created_at: "2024-01-28T17:20:47.923Z",
        updated_at: "2024-10-29T10:15:09.109Z",
        popular: true,
        slug: "son-tra",
        deleted: null,
      },
      {
        id: 494,
        name: "Ngũ Hành Sơn",
        province_id: 48,
        created_at: "2024-01-28T17:20:47.935Z",
        updated_at: "2024-10-29T10:15:09.115Z",
        popular: true,
        slug: "ngu-hanh-son",
        deleted: null,
      },
      {
        id: 495,
        name: "Cẩm Lệ",
        province_id: 48,
        created_at: "2024-01-28T17:20:47.948Z",
        updated_at: "2024-10-29T10:15:09.119Z",
        popular: true,
        slug: "cam-le",
        deleted: null,
      },
      {
        id: 497,
        name: "Hòa Vang",
        province_id: 48,
        created_at: "2024-01-28T17:20:47.964Z",
        updated_at: "2024-10-29T10:15:09.125Z",
        popular: true,
        slug: "hoa-vang",
        deleted: null,
      },
      {
        id: 498,
        name: "Hoàng Sa",
        province_id: 48,
        created_at: "2024-01-28T17:20:47.977Z",
        updated_at: "2024-10-29T10:15:09.132Z",
        popular: true,
        slug: "hoang-sa",
        deleted: null,
      },
    ],
  },
  {
    id: 49,
    name: "Quảng Nam",
    region: "middle",
    created_at: "2024-01-28T17:20:42.588Z",
    updated_at: "2024-10-29T10:15:06.525Z",
    lat: 15.573857,
    lon: 108.474112,
    deleted: null,
    slug: "quang-nam",
    district: [
      {
        id: 502,
        name: "Thành phố Tam Kỳ",
        province_id: 49,
        created_at: "2024-01-28T17:20:47.990Z",
        updated_at: "2024-10-29T10:15:09.138Z",
        popular: false,
        slug: "thanh-pho-tam-ky",
        deleted: null,
      },
      {
        id: 503,
        name: "Thành phố Hội An",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.002Z",
        updated_at: "2024-10-29T10:15:09.145Z",
        popular: false,
        slug: "thanh-pho-hoi-an",
        deleted: null,
      },
      {
        id: 504,
        name: "Huyện Tây Giang",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.020Z",
        updated_at: "2024-10-29T10:15:09.151Z",
        popular: false,
        slug: "huyen-tay-giang",
        deleted: null,
      },
      {
        id: 505,
        name: "Huyện Đông Giang",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.032Z",
        updated_at: "2024-10-29T10:15:09.156Z",
        popular: false,
        slug: "huyen-dong-giang",
        deleted: null,
      },
      {
        id: 506,
        name: "Huyện Đại Lộc",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.044Z",
        updated_at: "2024-10-29T10:15:09.161Z",
        popular: false,
        slug: "huyen-dai-loc",
        deleted: null,
      },
      {
        id: 507,
        name: "Thị xã Điện Bàn",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.058Z",
        updated_at: "2024-10-29T10:15:09.168Z",
        popular: false,
        slug: "thi-xa-dien-ban",
        deleted: null,
      },
      {
        id: 508,
        name: "Huyện Duy Xuyên",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.070Z",
        updated_at: "2024-10-29T10:15:09.173Z",
        popular: false,
        slug: "huyen-duy-xuyen",
        deleted: null,
      },
      {
        id: 509,
        name: "Huyện Quế Sơn",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.081Z",
        updated_at: "2024-10-29T10:15:09.177Z",
        popular: false,
        slug: "huyen-que-son",
        deleted: null,
      },
      {
        id: 510,
        name: "Huyện Nam Giang",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.096Z",
        updated_at: "2024-10-29T10:15:09.183Z",
        popular: false,
        slug: "huyen-nam-giang",
        deleted: null,
      },
      {
        id: 511,
        name: "Huyện Phước Sơn",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.118Z",
        updated_at: "2024-10-29T10:15:09.187Z",
        popular: false,
        slug: "huyen-phuoc-son",
        deleted: null,
      },
      {
        id: 512,
        name: "Huyện Hiệp Đức",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.132Z",
        updated_at: "2024-10-29T10:15:09.191Z",
        popular: false,
        slug: "huyen-hiep-duc",
        deleted: null,
      },
      {
        id: 513,
        name: "Huyện Thăng Bình",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.148Z",
        updated_at: "2024-10-29T10:15:09.196Z",
        popular: false,
        slug: "huyen-thang-binh",
        deleted: null,
      },
      {
        id: 514,
        name: "Huyện Tiên Phước",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.165Z",
        updated_at: "2024-10-29T10:15:09.202Z",
        popular: false,
        slug: "huyen-tien-phuoc",
        deleted: null,
      },
      {
        id: 515,
        name: "Huyện Bắc Trà My",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.178Z",
        updated_at: "2024-10-29T10:15:09.207Z",
        popular: false,
        slug: "huyen-bac-tra-my",
        deleted: null,
      },
      {
        id: 516,
        name: "Huyện Nam Trà My",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.191Z",
        updated_at: "2024-10-29T10:15:09.212Z",
        popular: false,
        slug: "huyen-nam-tra-my",
        deleted: null,
      },
      {
        id: 517,
        name: "Huyện Núi Thành",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.203Z",
        updated_at: "2024-10-29T10:15:09.217Z",
        popular: false,
        slug: "huyen-nui-thanh",
        deleted: null,
      },
      {
        id: 518,
        name: "Huyện Phú Ninh",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.217Z",
        updated_at: "2024-10-29T10:15:09.222Z",
        popular: false,
        slug: "huyen-phu-ninh",
        deleted: null,
      },
      {
        id: 519,
        name: "Huyện Nông Sơn",
        province_id: 49,
        created_at: "2024-01-28T17:20:48.233Z",
        updated_at: "2024-10-29T10:15:09.227Z",
        popular: false,
        slug: "huyen-nong-son",
        deleted: null,
      },
    ],
  },
  {
    id: 51,
    name: "Quảng Ngãi",
    region: "middle",
    created_at: "2024-01-28T17:20:42.605Z",
    updated_at: "2024-10-29T10:15:06.531Z",
    lat: 15.121388,
    lon: 108.804633,
    deleted: null,
    slug: "quang-ngai",
    district: [
      {
        id: 522,
        name: "Thành phố Quảng Ngãi",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.248Z",
        updated_at: "2024-10-29T10:15:09.234Z",
        popular: false,
        slug: "thanh-pho-quang-ngai",
        deleted: null,
      },
      {
        id: 524,
        name: "Huyện Bình Sơn",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.260Z",
        updated_at: "2024-10-29T10:15:09.240Z",
        popular: false,
        slug: "huyen-binh-son",
        deleted: null,
      },
      {
        id: 525,
        name: "Huyện Trà Bồng",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.273Z",
        updated_at: "2024-10-29T10:15:09.248Z",
        popular: false,
        slug: "huyen-tra-bong",
        deleted: null,
      },
      {
        id: 527,
        name: "Huyện Sơn Tịnh",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.288Z",
        updated_at: "2024-10-29T10:15:09.254Z",
        popular: false,
        slug: "huyen-son-tinh",
        deleted: null,
      },
      {
        id: 528,
        name: "Huyện Tư Nghĩa",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.304Z",
        updated_at: "2024-10-29T10:15:09.260Z",
        popular: false,
        slug: "huyen-tu-nghia",
        deleted: null,
      },
      {
        id: 529,
        name: "Huyện Sơn Hà",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.320Z",
        updated_at: "2024-10-29T10:15:09.267Z",
        popular: false,
        slug: "huyen-son-ha",
        deleted: null,
      },
      {
        id: 530,
        name: "Huyện Sơn Tây",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.337Z",
        updated_at: "2024-10-29T10:15:09.274Z",
        popular: false,
        slug: "huyen-son-tay",
        deleted: null,
      },
      {
        id: 531,
        name: "Huyện Minh Long",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.352Z",
        updated_at: "2024-10-29T10:15:09.279Z",
        popular: false,
        slug: "huyen-minh-long",
        deleted: null,
      },
      {
        id: 532,
        name: "Huyện Nghĩa Hành",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.371Z",
        updated_at: "2024-10-29T10:15:09.285Z",
        popular: false,
        slug: "huyen-nghia-hanh",
        deleted: null,
      },
      {
        id: 533,
        name: "Huyện Mộ Đức",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.384Z",
        updated_at: "2024-10-29T10:15:09.292Z",
        popular: false,
        slug: "huyen-mo-duc",
        deleted: null,
      },
      {
        id: 534,
        name: "Thị xã Đức Phổ",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.400Z",
        updated_at: "2024-10-29T10:15:09.299Z",
        popular: false,
        slug: "thi-xa-duc-pho",
        deleted: null,
      },
      {
        id: 535,
        name: "Huyện Ba Tơ",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.414Z",
        updated_at: "2024-10-29T10:15:09.304Z",
        popular: false,
        slug: "huyen-ba-to",
        deleted: null,
      },
      {
        id: 536,
        name: "Huyện Lý Sơn",
        province_id: 51,
        created_at: "2024-01-28T17:20:48.426Z",
        updated_at: "2024-10-29T10:15:09.310Z",
        popular: false,
        slug: "huyen-ly-son",
        deleted: null,
      },
    ],
  },
  {
    id: 52,
    name: "Bình Định",
    region: "middle",
    created_at: "2024-01-28T17:20:42.617Z",
    updated_at: "2024-10-29T10:15:06.537Z",
    lat: 13.782026,
    lon: 109.21946,
    deleted: null,
    slug: "binh-dinh",
    district: [
      {
        id: 540,
        name: "Thành phố Quy Nhơn",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.438Z",
        updated_at: "2024-10-29T10:15:09.316Z",
        popular: false,
        slug: "thanh-pho-quy-nhon",
        deleted: null,
      },
      {
        id: 542,
        name: "Huyện An Lão",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.449Z",
        updated_at: "2024-10-29T10:15:09.322Z",
        popular: false,
        slug: "huyen-an-lao",
        deleted: null,
      },
      {
        id: 543,
        name: "Thị xã Hoài Nhơn",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.461Z",
        updated_at: "2024-10-29T10:15:09.328Z",
        popular: false,
        slug: "thi-xa-hoai-nhon",
        deleted: null,
      },
      {
        id: 544,
        name: "Huyện Hoài Ân",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.474Z",
        updated_at: "2024-10-29T10:15:09.338Z",
        popular: false,
        slug: "huyen-hoai-an",
        deleted: null,
      },
      {
        id: 545,
        name: "Huyện Phù Mỹ",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.485Z",
        updated_at: "2024-10-29T10:15:09.345Z",
        popular: false,
        slug: "huyen-phu-my",
        deleted: null,
      },
      {
        id: 546,
        name: "Huyện Vĩnh Thạnh",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.498Z",
        updated_at: "2024-10-29T10:15:09.352Z",
        popular: false,
        slug: "huyen-vinh-thanh",
        deleted: null,
      },
      {
        id: 547,
        name: "Huyện Tây Sơn",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.509Z",
        updated_at: "2024-10-29T10:15:09.358Z",
        popular: false,
        slug: "huyen-tay-son",
        deleted: null,
      },
      {
        id: 548,
        name: "Huyện Phù Cát",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.524Z",
        updated_at: "2024-10-29T10:15:09.364Z",
        popular: false,
        slug: "huyen-phu-cat",
        deleted: null,
      },
      {
        id: 549,
        name: "Thị xã An Nhơn",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.536Z",
        updated_at: "2024-10-29T10:15:09.370Z",
        popular: false,
        slug: "thi-xa-an-nhon",
        deleted: null,
      },
      {
        id: 550,
        name: "Huyện Tuy Phước",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.548Z",
        updated_at: "2024-10-29T10:15:09.375Z",
        popular: false,
        slug: "huyen-tuy-phuoc",
        deleted: null,
      },
      {
        id: 551,
        name: "Huyện Vân Canh",
        province_id: 52,
        created_at: "2024-01-28T17:20:48.567Z",
        updated_at: "2024-10-29T10:15:09.380Z",
        popular: false,
        slug: "huyen-van-canh",
        deleted: null,
      },
    ],
  },
  {
    id: 54,
    name: "Phú Yên",
    region: "middle",
    created_at: "2024-01-28T17:20:42.630Z",
    updated_at: "2024-10-29T10:15:06.543Z",
    lat: 13.088186,
    lon: 109.092876,
    deleted: null,
    slug: "phu-yen",
    district: [
      {
        id: 555,
        name: "Thành phố Tuy Hoà",
        province_id: 54,
        created_at: "2024-01-28T17:20:48.580Z",
        updated_at: "2024-10-29T10:15:09.384Z",
        popular: false,
        slug: "thanh-pho-tuy-hoa",
        deleted: null,
      },
      {
        id: 557,
        name: "Thị xã Sông Cầu",
        province_id: 54,
        created_at: "2024-01-28T17:20:48.592Z",
        updated_at: "2024-10-29T10:15:09.389Z",
        popular: false,
        slug: "thi-xa-song-cau",
        deleted: null,
      },
      {
        id: 558,
        name: "Huyện Đồng Xuân",
        province_id: 54,
        created_at: "2024-01-28T17:20:48.604Z",
        updated_at: "2024-10-29T10:15:09.393Z",
        popular: false,
        slug: "huyen-dong-xuan",
        deleted: null,
      },
      {
        id: 559,
        name: "Huyện Tuy An",
        province_id: 54,
        created_at: "2024-01-28T17:20:48.625Z",
        updated_at: "2024-10-29T10:15:09.398Z",
        popular: false,
        slug: "huyen-tuy-an",
        deleted: null,
      },
      {
        id: 560,
        name: "Huyện Sơn Hòa",
        province_id: 54,
        created_at: "2024-01-28T17:20:48.638Z",
        updated_at: "2024-10-29T10:15:09.403Z",
        popular: false,
        slug: "huyen-son-hoa",
        deleted: null,
      },
      {
        id: 561,
        name: "Huyện Sông Hinh",
        province_id: 54,
        created_at: "2024-01-28T17:20:48.652Z",
        updated_at: "2024-10-29T10:15:09.408Z",
        popular: false,
        slug: "huyen-song-hinh",
        deleted: null,
      },
      {
        id: 562,
        name: "Huyện Tây Hoà",
        province_id: 54,
        created_at: "2024-01-28T17:20:48.667Z",
        updated_at: "2024-10-29T10:15:09.412Z",
        popular: false,
        slug: "huyen-tay-hoa",
        deleted: null,
      },
      {
        id: 563,
        name: "Huyện Phú Hoà",
        province_id: 54,
        created_at: "2024-01-28T17:20:48.678Z",
        updated_at: "2024-10-29T10:15:09.417Z",
        popular: false,
        slug: "huyen-phu-hoa",
        deleted: null,
      },
      {
        id: 564,
        name: "Thị xã Đông Hòa",
        province_id: 54,
        created_at: "2024-01-28T17:20:48.692Z",
        updated_at: "2024-10-29T10:15:09.421Z",
        popular: false,
        slug: "thi-xa-dong-hoa",
        deleted: null,
      },
    ],
  },
  {
    id: 56,
    name: "Khánh Hòa",
    region: "middle",
    created_at: "2024-01-28T17:20:42.641Z",
    updated_at: "2024-10-29T10:15:06.549Z",
    lat: 12.258514,
    lon: 109.045158,
    deleted: null,
    slug: "khanh-hoa",
    district: [
      {
        id: 568,
        name: "Thành phố Nha Trang",
        province_id: 56,
        created_at: "2024-01-28T17:20:48.706Z",
        updated_at: "2024-10-29T10:15:09.427Z",
        popular: false,
        slug: "thanh-pho-nha-trang",
        deleted: null,
      },
      {
        id: 569,
        name: "Thành phố Cam Ranh",
        province_id: 56,
        created_at: "2024-01-28T17:20:48.717Z",
        updated_at: "2024-10-29T10:15:09.433Z",
        popular: false,
        slug: "thanh-pho-cam-ranh",
        deleted: null,
      },
      {
        id: 570,
        name: "Huyện Cam Lâm",
        province_id: 56,
        created_at: "2024-01-28T17:20:48.731Z",
        updated_at: "2024-10-29T10:15:09.440Z",
        popular: false,
        slug: "huyen-cam-lam",
        deleted: null,
      },
      {
        id: 571,
        name: "Huyện Vạn Ninh",
        province_id: 56,
        created_at: "2024-01-28T17:20:48.742Z",
        updated_at: "2024-10-29T10:15:09.448Z",
        popular: false,
        slug: "huyen-van-ninh",
        deleted: null,
      },
      {
        id: 572,
        name: "Thị xã Ninh Hòa",
        province_id: 56,
        created_at: "2024-01-28T17:20:48.756Z",
        updated_at: "2024-10-29T10:15:09.456Z",
        popular: false,
        slug: "thi-xa-ninh-hoa",
        deleted: null,
      },
      {
        id: 573,
        name: "Huyện Khánh Vĩnh",
        province_id: 56,
        created_at: "2024-01-28T17:20:48.766Z",
        updated_at: "2024-10-29T10:15:09.463Z",
        popular: false,
        slug: "huyen-khanh-vinh",
        deleted: null,
      },
      {
        id: 574,
        name: "Huyện Diên Khánh",
        province_id: 56,
        created_at: "2024-01-28T17:20:48.780Z",
        updated_at: "2024-10-29T10:15:09.469Z",
        popular: false,
        slug: "huyen-dien-khanh",
        deleted: null,
      },
      {
        id: 575,
        name: "Huyện Khánh Sơn",
        province_id: 56,
        created_at: "2024-01-28T17:20:48.792Z",
        updated_at: "2024-10-29T10:15:09.476Z",
        popular: false,
        slug: "huyen-khanh-son",
        deleted: null,
      },
      {
        id: 576,
        name: "Huyện Trường Sa",
        province_id: 56,
        created_at: "2024-01-28T17:20:48.802Z",
        updated_at: "2024-10-29T10:15:09.483Z",
        popular: false,
        slug: "huyen-truong-sa",
        deleted: null,
      },
    ],
  },
  {
    id: 58,
    name: "Ninh Thuận",
    region: "middle",
    created_at: "2024-01-28T17:20:42.652Z",
    updated_at: "2024-10-29T10:15:06.554Z",
    lat: 11.567636,
    lon: 108.990609,
    deleted: null,
    slug: "ninh-thuan",
    district: [
      {
        id: 582,
        name: "Thành phố Phan Rang-Tháp Chàm",
        province_id: 58,
        created_at: "2024-01-28T17:20:48.816Z",
        updated_at: "2024-10-29T10:15:09.488Z",
        popular: false,
        slug: "thanh-pho-phan-rang-thap-cham",
        deleted: null,
      },
      {
        id: 584,
        name: "Huyện Bác Ái",
        province_id: 58,
        created_at: "2024-01-28T17:20:48.826Z",
        updated_at: "2024-10-29T10:15:09.493Z",
        popular: false,
        slug: "huyen-bac-ai",
        deleted: null,
      },
      {
        id: 585,
        name: "Huyện Ninh Sơn",
        province_id: 58,
        created_at: "2024-01-28T17:20:48.840Z",
        updated_at: "2024-10-29T10:15:09.498Z",
        popular: false,
        slug: "huyen-ninh-son",
        deleted: null,
      },
      {
        id: 586,
        name: "Huyện Ninh Hải",
        province_id: 58,
        created_at: "2024-01-28T17:20:48.850Z",
        updated_at: "2024-10-29T10:15:09.504Z",
        popular: false,
        slug: "huyen-ninh-hai",
        deleted: null,
      },
      {
        id: 587,
        name: "Huyện Ninh Phước",
        province_id: 58,
        created_at: "2024-01-28T17:20:48.862Z",
        updated_at: "2024-10-29T10:15:09.510Z",
        popular: false,
        slug: "huyen-ninh-phuoc",
        deleted: null,
      },
      {
        id: 588,
        name: "Huyện Thuận Bắc",
        province_id: 58,
        created_at: "2024-01-28T17:20:48.874Z",
        updated_at: "2024-10-29T10:15:09.516Z",
        popular: false,
        slug: "huyen-thuan-bac",
        deleted: null,
      },
      {
        id: 589,
        name: "Huyện Thuận Nam",
        province_id: 58,
        created_at: "2024-01-28T17:20:48.888Z",
        updated_at: "2024-10-29T10:15:09.523Z",
        popular: false,
        slug: "huyen-thuan-nam",
        deleted: null,
      },
    ],
  },
  {
    id: 60,
    name: "Bình Thuận",
    region: "middle",
    created_at: "2024-01-28T17:20:42.665Z",
    updated_at: "2024-10-29T10:15:06.559Z",
    lat: 11.090088,
    lon: 108.072079,
    deleted: null,
    slug: "binh-thuan",
    district: [
      {
        id: 593,
        name: "Thành phố Phan Thiết",
        province_id: 60,
        created_at: "2024-01-28T17:20:48.898Z",
        updated_at: "2024-10-29T10:15:09.533Z",
        popular: false,
        slug: "thanh-pho-phan-thiet",
        deleted: null,
      },
      {
        id: 594,
        name: "Thị xã La Gi",
        province_id: 60,
        created_at: "2024-01-28T17:20:48.912Z",
        updated_at: "2024-10-29T10:15:09.540Z",
        popular: false,
        slug: "thi-xa-la-gi",
        deleted: null,
      },
      {
        id: 595,
        name: "Huyện Tuy Phong",
        province_id: 60,
        created_at: "2024-01-28T17:20:48.922Z",
        updated_at: "2024-10-29T10:15:09.551Z",
        popular: false,
        slug: "huyen-tuy-phong",
        deleted: null,
      },
      {
        id: 596,
        name: "Huyện Bắc Bình",
        province_id: 60,
        created_at: "2024-01-28T17:20:48.936Z",
        updated_at: "2024-10-29T10:15:09.558Z",
        popular: false,
        slug: "huyen-bac-binh",
        deleted: null,
      },
      {
        id: 597,
        name: "Huyện Hàm Thuận Bắc",
        province_id: 60,
        created_at: "2024-01-28T17:20:48.947Z",
        updated_at: "2024-10-29T10:15:09.564Z",
        popular: false,
        slug: "huyen-ham-thuan-bac",
        deleted: null,
      },
      {
        id: 598,
        name: "Huyện Hàm Thuận Nam",
        province_id: 60,
        created_at: "2024-01-28T17:20:48.960Z",
        updated_at: "2024-10-29T10:15:09.570Z",
        popular: false,
        slug: "huyen-ham-thuan-nam",
        deleted: null,
      },
      {
        id: 599,
        name: "Huyện Tánh Linh",
        province_id: 60,
        created_at: "2024-01-28T17:20:48.973Z",
        updated_at: "2024-10-29T10:15:09.575Z",
        popular: false,
        slug: "huyen-tanh-linh",
        deleted: null,
      },
      {
        id: 600,
        name: "Huyện Đức Linh",
        province_id: 60,
        created_at: "2024-01-28T17:20:48.983Z",
        updated_at: "2024-10-29T10:15:09.580Z",
        popular: false,
        slug: "huyen-duc-linh",
        deleted: null,
      },
      {
        id: 601,
        name: "Huyện Hàm Tân",
        province_id: 60,
        created_at: "2024-01-28T17:20:48.996Z",
        updated_at: "2024-10-29T10:15:09.585Z",
        popular: false,
        slug: "huyen-ham-tan",
        deleted: null,
      },
      {
        id: 602,
        name: "Huyện Phú Quí",
        province_id: 60,
        created_at: "2024-01-28T17:20:49.009Z",
        updated_at: "2024-10-29T10:15:09.588Z",
        popular: false,
        slug: "huyen-phu-qui",
        deleted: null,
      },
    ],
  },
  {
    id: 62,
    name: "Kon Tum",
    region: "middle",
    created_at: "2024-01-28T17:20:42.677Z",
    updated_at: "2024-10-29T10:15:06.566Z",
    lat: 14.35042,
    lon: 107.982337,
    deleted: null,
    slug: "kon-tum",
    district: [
      {
        id: 608,
        name: "Thành phố Kon Tum",
        province_id: 62,
        created_at: "2024-01-28T17:20:49.024Z",
        updated_at: "2024-10-29T10:15:09.593Z",
        popular: false,
        slug: "thanh-pho-kon-tum",
        deleted: null,
      },
      {
        id: 610,
        name: "Huyện Đắk Glei",
        province_id: 62,
        created_at: "2024-01-28T17:20:49.040Z",
        updated_at: "2024-10-29T10:15:09.597Z",
        popular: false,
        slug: "huyen-dak-glei",
        deleted: null,
      },
      {
        id: 611,
        name: "Huyện Ngọc Hồi",
        province_id: 62,
        created_at: "2024-01-28T17:20:49.054Z",
        updated_at: "2024-10-29T10:15:09.601Z",
        popular: false,
        slug: "huyen-ngoc-hoi",
        deleted: null,
      },
      {
        id: 612,
        name: "Huyện Đắk Tô",
        province_id: 62,
        created_at: "2024-01-28T17:20:49.068Z",
        updated_at: "2024-10-29T10:15:09.605Z",
        popular: false,
        slug: "huyen-dak-to",
        deleted: null,
      },
      {
        id: 613,
        name: "Huyện Kon Plông",
        province_id: 62,
        created_at: "2024-01-28T17:20:49.083Z",
        updated_at: "2024-10-29T10:15:09.609Z",
        popular: false,
        slug: "huyen-kon-plong",
        deleted: null,
      },
      {
        id: 614,
        name: "Huyện Kon Rẫy",
        province_id: 62,
        created_at: "2024-01-28T17:20:49.095Z",
        updated_at: "2024-10-29T10:15:09.613Z",
        popular: false,
        slug: "huyen-kon-ray",
        deleted: null,
      },
      {
        id: 615,
        name: "Huyện Đắk Hà",
        province_id: 62,
        created_at: "2024-01-28T17:20:49.108Z",
        updated_at: "2024-10-29T10:15:09.617Z",
        popular: false,
        slug: "huyen-dak-ha",
        deleted: null,
      },
      {
        id: 616,
        name: "Huyện Sa Thầy",
        province_id: 62,
        created_at: "2024-01-28T17:20:49.123Z",
        updated_at: "2024-10-29T10:15:09.622Z",
        popular: false,
        slug: "huyen-sa-thay",
        deleted: null,
      },
      {
        id: 617,
        name: "Huyện Tu Mơ Rông",
        province_id: 62,
        created_at: "2024-01-28T17:20:49.135Z",
        updated_at: "2024-10-29T10:15:09.627Z",
        popular: false,
        slug: "huyen-tu-mo-rong",
        deleted: null,
      },
      {
        id: 618,
        name: "Huyện Ia H' Drai",
        province_id: 62,
        created_at: "2024-01-28T17:20:49.148Z",
        updated_at: "2024-10-29T10:15:09.633Z",
        popular: false,
        slug: "huyen-ia-h-drai",
        deleted: null,
      },
    ],
  },
  {
    id: 64,
    name: "Gia Lai",
    region: "middle",
    created_at: "2024-01-28T17:20:42.688Z",
    updated_at: "2024-10-29T10:15:06.572Z",
    lat: 13.971199,
    lon: 108.014623,
    deleted: null,
    slug: "gia-lai",
    district: [
      {
        id: 622,
        name: "Thành phố Pleiku",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.165Z",
        updated_at: "2024-10-29T10:15:09.639Z",
        popular: false,
        slug: "thanh-pho-pleiku",
        deleted: null,
      },
      {
        id: 623,
        name: "Thị xã An Khê",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.183Z",
        updated_at: "2024-10-29T10:15:09.646Z",
        popular: false,
        slug: "thi-xa-an-khe",
        deleted: null,
      },
      {
        id: 624,
        name: "Thị xã Ayun Pa",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.196Z",
        updated_at: "2024-10-29T10:15:09.653Z",
        popular: false,
        slug: "thi-xa-ayun-pa",
        deleted: null,
      },
      {
        id: 625,
        name: "Huyện KBang",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.216Z",
        updated_at: "2024-10-29T10:15:09.659Z",
        popular: false,
        slug: "huyen-kbang",
        deleted: null,
      },
      {
        id: 626,
        name: "Huyện Đăk Đoa",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.229Z",
        updated_at: "2024-10-29T10:15:09.667Z",
        popular: false,
        slug: "huyen-dak-doa",
        deleted: null,
      },
      {
        id: 627,
        name: "Huyện Chư Păh",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.243Z",
        updated_at: "2024-10-29T10:15:09.672Z",
        popular: false,
        slug: "huyen-chu-pah",
        deleted: null,
      },
      {
        id: 628,
        name: "Huyện Ia Grai",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.256Z",
        updated_at: "2024-10-29T10:15:09.677Z",
        popular: false,
        slug: "huyen-ia-grai",
        deleted: null,
      },
      {
        id: 629,
        name: "Huyện Mang Yang",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.270Z",
        updated_at: "2024-10-29T10:15:09.682Z",
        popular: false,
        slug: "huyen-mang-yang",
        deleted: null,
      },
      {
        id: 630,
        name: "Huyện Kông Chro",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.282Z",
        updated_at: "2024-10-29T10:15:09.686Z",
        popular: false,
        slug: "huyen-kong-chro",
        deleted: null,
      },
      {
        id: 631,
        name: "Huyện Đức Cơ",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.296Z",
        updated_at: "2024-10-29T10:15:09.691Z",
        popular: false,
        slug: "huyen-duc-co",
        deleted: null,
      },
      {
        id: 632,
        name: "Huyện Chư Prông",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.312Z",
        updated_at: "2024-10-29T10:15:09.696Z",
        popular: false,
        slug: "huyen-chu-prong",
        deleted: null,
      },
      {
        id: 633,
        name: "Huyện Chư Sê",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.327Z",
        updated_at: "2024-10-29T10:15:09.701Z",
        popular: false,
        slug: "huyen-chu-se",
        deleted: null,
      },
      {
        id: 634,
        name: "Huyện Đăk Pơ",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.340Z",
        updated_at: "2024-10-29T10:15:09.707Z",
        popular: false,
        slug: "huyen-dak-po",
        deleted: null,
      },
      {
        id: 635,
        name: "Huyện Ia Pa",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.352Z",
        updated_at: "2024-10-29T10:15:09.712Z",
        popular: false,
        slug: "huyen-ia-pa",
        deleted: null,
      },
      {
        id: 637,
        name: "Huyện Krông Pa",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.366Z",
        updated_at: "2024-10-29T10:15:09.717Z",
        popular: false,
        slug: "huyen-krong-pa",
        deleted: null,
      },
      {
        id: 638,
        name: "Huyện Phú Thiện",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.380Z",
        updated_at: "2024-10-29T10:15:09.722Z",
        popular: false,
        slug: "huyen-phu-thien",
        deleted: null,
      },
      {
        id: 639,
        name: "Huyện Chư Pưh",
        province_id: 64,
        created_at: "2024-01-28T17:20:49.396Z",
        updated_at: "2024-10-29T10:15:09.728Z",
        popular: false,
        slug: "huyen-chu-puh",
        deleted: null,
      },
    ],
  },
  {
    id: 66,
    name: "Đắk Lắk",
    region: "middle",
    created_at: "2024-01-28T17:20:42.702Z",
    updated_at: "2024-10-29T10:15:06.578Z",
    lat: 12.666684,
    lon: 108.037951,
    deleted: null,
    slug: "dak-lak",
    district: [
      {
        id: 643,
        name: "Thành phố Buôn Ma Thuột",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.408Z",
        updated_at: "2024-10-29T10:15:09.734Z",
        popular: false,
        slug: "thanh-pho-buon-ma-thuot",
        deleted: null,
      },
      {
        id: 644,
        name: "Thị xã Buôn Hồ",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.424Z",
        updated_at: "2024-10-29T10:15:09.740Z",
        popular: false,
        slug: "thi-xa-buon-ho",
        deleted: null,
      },
      {
        id: 645,
        name: "Huyện Ea H'leo",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.437Z",
        updated_at: "2024-10-29T10:15:09.746Z",
        popular: false,
        slug: "huyen-ea-hleo",
        deleted: null,
      },
      {
        id: 646,
        name: "Huyện Ea Súp",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.451Z",
        updated_at: "2024-10-29T10:15:09.757Z",
        popular: false,
        slug: "huyen-ea-sup",
        deleted: null,
      },
      {
        id: 647,
        name: "Huyện Buôn Đôn",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.462Z",
        updated_at: "2024-10-29T10:15:09.764Z",
        popular: false,
        slug: "huyen-buon-don",
        deleted: null,
      },
      {
        id: 648,
        name: "Huyện Cư M'gar",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.476Z",
        updated_at: "2024-10-29T10:15:09.769Z",
        popular: false,
        slug: "huyen-cu-mgar",
        deleted: null,
      },
      {
        id: 649,
        name: "Huyện Krông Búk",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.493Z",
        updated_at: "2024-10-29T10:15:09.774Z",
        popular: false,
        slug: "huyen-krong-buk",
        deleted: null,
      },
      {
        id: 650,
        name: "Huyện Krông Năng",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.505Z",
        updated_at: "2024-10-29T10:15:09.780Z",
        popular: false,
        slug: "huyen-krong-nang",
        deleted: null,
      },
      {
        id: 651,
        name: "Huyện Ea Kar",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.519Z",
        updated_at: "2024-10-29T10:15:09.785Z",
        popular: false,
        slug: "huyen-ea-kar",
        deleted: null,
      },
      {
        id: 652,
        name: "Huyện M'Đrắk",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.531Z",
        updated_at: "2024-10-29T10:15:09.790Z",
        popular: false,
        slug: "huyen-mdrak",
        deleted: null,
      },
      {
        id: 653,
        name: "Huyện Krông Bông",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.544Z",
        updated_at: "2024-10-29T10:15:09.795Z",
        popular: false,
        slug: "huyen-krong-bong",
        deleted: null,
      },
      {
        id: 654,
        name: "Huyện Krông Pắc",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.557Z",
        updated_at: "2024-10-29T10:15:09.799Z",
        popular: false,
        slug: "huyen-krong-pac",
        deleted: null,
      },
      {
        id: 655,
        name: "Huyện Krông A Na",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.571Z",
        updated_at: "2024-10-29T10:15:09.804Z",
        popular: false,
        slug: "huyen-krong-a-na",
        deleted: null,
      },
      {
        id: 656,
        name: "Huyện Lắk",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.582Z",
        updated_at: "2024-10-29T10:15:09.810Z",
        popular: false,
        slug: "huyen-lak",
        deleted: null,
      },
      {
        id: 657,
        name: "Huyện Cư Kuin",
        province_id: 66,
        created_at: "2024-01-28T17:20:49.600Z",
        updated_at: "2024-10-29T10:15:09.815Z",
        popular: false,
        slug: "huyen-cu-kuin",
        deleted: null,
      },
    ],
  },
  {
    id: 67,
    name: "Đắk Nông",
    region: "middle",
    created_at: "2024-01-28T17:20:42.713Z",
    updated_at: "2024-10-29T10:15:06.583Z",
    lat: 12.112607,
    lon: 107.711234,
    deleted: null,
    slug: "dak-nong",
    district: [
      {
        id: 660,
        name: "Thành phố Gia Nghĩa",
        province_id: 67,
        created_at: "2024-01-28T17:20:49.616Z",
        updated_at: "2024-10-29T10:15:09.820Z",
        popular: false,
        slug: "thanh-pho-gia-nghia",
        deleted: null,
      },
      {
        id: 661,
        name: "Huyện Đăk Glong",
        province_id: 67,
        created_at: "2024-01-28T17:20:49.629Z",
        updated_at: "2024-10-29T10:15:09.826Z",
        popular: false,
        slug: "huyen-dak-glong",
        deleted: null,
      },
      {
        id: 662,
        name: "Huyện Cư Jút",
        province_id: 67,
        created_at: "2024-01-28T17:20:49.644Z",
        updated_at: "2024-10-29T10:15:09.830Z",
        popular: false,
        slug: "huyen-cu-jut",
        deleted: null,
      },
      {
        id: 663,
        name: "Huyện Đắk Mil",
        province_id: 67,
        created_at: "2024-01-28T17:20:49.657Z",
        updated_at: "2024-10-29T10:15:09.835Z",
        popular: false,
        slug: "huyen-dak-mil",
        deleted: null,
      },
      {
        id: 664,
        name: "Huyện Krông Nô",
        province_id: 67,
        created_at: "2024-01-28T17:20:49.669Z",
        updated_at: "2024-10-29T10:15:09.840Z",
        popular: false,
        slug: "huyen-krong-no",
        deleted: null,
      },
      {
        id: 665,
        name: "Huyện Đắk Song",
        province_id: 67,
        created_at: "2024-01-28T17:20:49.690Z",
        updated_at: "2024-10-29T10:15:09.844Z",
        popular: false,
        slug: "huyen-dak-song",
        deleted: null,
      },
      {
        id: 666,
        name: "Huyện Đắk R'Lấp",
        province_id: 67,
        created_at: "2024-01-28T17:20:49.732Z",
        updated_at: "2024-10-29T10:15:09.850Z",
        popular: false,
        slug: "huyen-dak-rlap",
        deleted: null,
      },
      {
        id: 667,
        name: "Huyện Tuy Đức",
        province_id: 67,
        created_at: "2024-01-28T17:20:49.791Z",
        updated_at: "2024-10-29T10:15:09.856Z",
        popular: false,
        slug: "huyen-tuy-duc",
        deleted: null,
      },
    ],
  },
  {
    id: 68,
    name: "Lâm Đồng",
    region: "middle",
    created_at: "2024-01-28T17:20:42.727Z",
    updated_at: "2024-10-29T10:15:06.589Z",
    lat: 11.575279,
    lon: 107.837982,
    deleted: null,
    slug: "lam-dong",
    district: [
      {
        id: 672,
        name: "Thành phố Đà Lạt",
        province_id: 68,
        created_at: "2024-01-28T17:20:49.834Z",
        updated_at: "2024-10-29T10:15:09.863Z",
        popular: false,
        slug: "thanh-pho-da-lat",
        deleted: null,
      },
      {
        id: 673,
        name: "Thành phố Bảo Lộc",
        province_id: 68,
        created_at: "2024-01-28T17:20:49.911Z",
        updated_at: "2024-10-29T10:15:09.868Z",
        popular: false,
        slug: "thanh-pho-bao-loc",
        deleted: null,
      },
      {
        id: 674,
        name: "Huyện Đam Rông",
        province_id: 68,
        created_at: "2024-01-28T17:20:49.964Z",
        updated_at: "2024-10-29T10:15:09.873Z",
        popular: false,
        slug: "huyen-dam-rong",
        deleted: null,
      },
      {
        id: 675,
        name: "Huyện Lạc Dương",
        province_id: 68,
        created_at: "2024-01-28T17:20:49.996Z",
        updated_at: "2024-10-29T10:15:09.878Z",
        popular: false,
        slug: "huyen-lac-duong",
        deleted: null,
      },
      {
        id: 676,
        name: "Huyện Lâm Hà",
        province_id: 68,
        created_at: "2024-01-28T17:20:50.008Z",
        updated_at: "2024-10-29T10:15:09.883Z",
        popular: false,
        slug: "huyen-lam-ha",
        deleted: null,
      },
      {
        id: 677,
        name: "Huyện Đơn Dương",
        province_id: 68,
        created_at: "2024-01-28T17:20:50.019Z",
        updated_at: "2024-10-29T10:15:09.890Z",
        popular: false,
        slug: "huyen-don-duong",
        deleted: null,
      },
      {
        id: 678,
        name: "Huyện Đức Trọng",
        province_id: 68,
        created_at: "2024-01-28T17:20:50.031Z",
        updated_at: "2024-10-29T10:15:09.895Z",
        popular: false,
        slug: "huyen-duc-trong",
        deleted: null,
      },
      {
        id: 679,
        name: "Huyện Di Linh",
        province_id: 68,
        created_at: "2024-01-28T17:20:50.045Z",
        updated_at: "2024-10-29T10:15:09.899Z",
        popular: false,
        slug: "huyen-di-linh",
        deleted: null,
      },
      {
        id: 680,
        name: "Huyện Bảo Lâm",
        province_id: 68,
        created_at: "2024-01-28T17:20:50.060Z",
        updated_at: "2024-10-29T10:15:09.904Z",
        popular: false,
        slug: "huyen-bao-lam",
        deleted: null,
      },
      {
        id: 681,
        name: "Huyện Đạ Huoai",
        province_id: 68,
        created_at: "2024-01-28T17:20:50.073Z",
        updated_at: "2024-10-29T10:15:09.908Z",
        popular: false,
        slug: "huyen-da-huoai",
        deleted: null,
      },
      {
        id: 682,
        name: "Huyện Đạ Tẻh",
        province_id: 68,
        created_at: "2024-01-28T17:20:50.086Z",
        updated_at: "2024-10-29T10:15:09.912Z",
        popular: false,
        slug: "huyen-da-teh",
        deleted: null,
      },
      {
        id: 683,
        name: "Huyện Cát Tiên",
        province_id: 68,
        created_at: "2024-01-28T17:20:50.098Z",
        updated_at: "2024-10-29T10:15:09.917Z",
        popular: false,
        slug: "huyen-cat-tien",
        deleted: null,
      },
    ],
  },
  {
    id: 70,
    name: "Bình Phước",
    region: "south",
    created_at: "2024-01-28T17:20:42.739Z",
    updated_at: "2024-10-29T10:15:06.596Z",
    lat: 11.721173,
    lon: 106.929374,
    deleted: null,
    slug: "binh-phuoc",
    district: [
      {
        id: 688,
        name: "Thị xã Phước Long",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.109Z",
        updated_at: "2024-10-29T10:15:09.922Z",
        popular: false,
        slug: "thi-xa-phuoc-long",
        deleted: null,
      },
      {
        id: 689,
        name: "Thành phố Đồng Xoài",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.134Z",
        updated_at: "2024-10-29T10:15:09.927Z",
        popular: false,
        slug: "thanh-pho-dong-xoai",
        deleted: null,
      },
      {
        id: 690,
        name: "Thị xã Bình Long",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.148Z",
        updated_at: "2024-10-29T10:15:09.933Z",
        popular: false,
        slug: "thi-xa-binh-long",
        deleted: null,
      },
      {
        id: 691,
        name: "Huyện Bù Gia Mập",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.162Z",
        updated_at: "2024-10-29T10:15:09.940Z",
        popular: false,
        slug: "huyen-bu-gia-map",
        deleted: null,
      },
      {
        id: 692,
        name: "Huyện Lộc Ninh",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.175Z",
        updated_at: "2024-10-29T10:15:09.947Z",
        popular: false,
        slug: "huyen-loc-ninh",
        deleted: null,
      },
      {
        id: 693,
        name: "Huyện Bù Đốp",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.191Z",
        updated_at: "2024-10-29T10:15:09.953Z",
        popular: false,
        slug: "huyen-bu-dop",
        deleted: null,
      },
      {
        id: 694,
        name: "Huyện Hớn Quản",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.205Z",
        updated_at: "2024-10-29T10:15:09.959Z",
        popular: false,
        slug: "huyen-hon-quan",
        deleted: null,
      },
      {
        id: 695,
        name: "Huyện Đồng Phú",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.218Z",
        updated_at: "2024-10-29T10:15:09.965Z",
        popular: false,
        slug: "huyen-dong-phu",
        deleted: null,
      },
      {
        id: 696,
        name: "Huyện Bù Đăng",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.233Z",
        updated_at: "2024-10-29T10:15:09.971Z",
        popular: false,
        slug: "huyen-bu-dang",
        deleted: null,
      },
      {
        id: 697,
        name: "Thị xã Chơn Thành",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.250Z",
        updated_at: "2024-10-29T10:15:09.976Z",
        popular: false,
        slug: "thi-xa-chon-thanh",
        deleted: null,
      },
      {
        id: 698,
        name: "Huyện Phú Riềng",
        province_id: 70,
        created_at: "2024-01-28T17:20:50.271Z",
        updated_at: "2024-10-29T10:15:09.981Z",
        popular: false,
        slug: "huyen-phu-rieng",
        deleted: null,
      },
    ],
  },
  {
    id: 72,
    name: "Tây Ninh",
    region: "south",
    created_at: "2024-01-28T17:20:42.755Z",
    updated_at: "2024-10-29T10:15:06.600Z",
    lat: 11.359056,
    lon: 106.125722,
    deleted: null,
    slug: "tay-ninh",
    district: [
      {
        id: 703,
        name: "Thành phố Tây Ninh",
        province_id: 72,
        created_at: "2024-01-28T17:20:50.287Z",
        updated_at: "2024-10-29T10:15:09.985Z",
        popular: false,
        slug: "thanh-pho-tay-ninh",
        deleted: null,
      },
      {
        id: 705,
        name: "Huyện Tân Biên",
        province_id: 72,
        created_at: "2024-01-28T17:20:50.308Z",
        updated_at: "2024-10-29T10:15:09.990Z",
        popular: false,
        slug: "huyen-tan-bien",
        deleted: null,
      },
      {
        id: 706,
        name: "Huyện Tân Châu",
        province_id: 72,
        created_at: "2024-01-28T17:20:50.323Z",
        updated_at: "2024-10-29T10:15:09.995Z",
        popular: false,
        slug: "huyen-tan-chau",
        deleted: null,
      },
      {
        id: 707,
        name: "Huyện Dương Minh Châu",
        province_id: 72,
        created_at: "2024-01-28T17:20:50.336Z",
        updated_at: "2024-10-29T10:15:10.000Z",
        popular: false,
        slug: "huyen-duong-minh-chau",
        deleted: null,
      },
      {
        id: 708,
        name: "Huyện Châu Thành",
        province_id: 72,
        created_at: "2024-01-28T17:20:50.350Z",
        updated_at: "2024-10-29T10:15:10.007Z",
        popular: false,
        slug: "huyen-chau-thanh",
        deleted: null,
      },
      {
        id: 709,
        name: "Thị xã Hòa Thành",
        province_id: 72,
        created_at: "2024-01-28T17:20:50.362Z",
        updated_at: "2024-10-29T10:15:10.012Z",
        popular: false,
        slug: "thi-xa-hoa-thanh",
        deleted: null,
      },
      {
        id: 710,
        name: "Huyện Gò Dầu",
        province_id: 72,
        created_at: "2024-01-28T17:20:50.376Z",
        updated_at: "2024-10-29T10:15:10.017Z",
        popular: false,
        slug: "huyen-go-dau",
        deleted: null,
      },
      {
        id: 711,
        name: "Huyện Bến Cầu",
        province_id: 72,
        created_at: "2024-01-28T17:20:50.389Z",
        updated_at: "2024-10-29T10:15:10.022Z",
        popular: false,
        slug: "huyen-ben-cau",
        deleted: null,
      },
      {
        id: 712,
        name: "Thị xã Trảng Bàng",
        province_id: 72,
        created_at: "2024-01-28T17:20:50.402Z",
        updated_at: "2024-10-29T10:15:10.027Z",
        popular: false,
        slug: "thi-xa-trang-bang",
        deleted: null,
      },
    ],
  },
  {
    id: 74,
    name: "Bình Dương",
    region: "south",
    created_at: "2024-01-28T17:20:42.770Z",
    updated_at: "2024-10-29T10:15:06.606Z",
    lat: 11.1707765,
    lon: 106.6698995,
    deleted: null,
    slug: "binh-duong",
    district: [
      {
        id: 718,
        name: "Thành phố Thủ Dầu Một",
        province_id: 74,
        created_at: "2024-01-28T17:20:50.414Z",
        updated_at: "2024-10-29T10:15:10.036Z",
        popular: false,
        slug: "thanh-pho-thu-dau-mot",
        deleted: null,
      },
      {
        id: 719,
        name: "Huyện Bàu Bàng",
        province_id: 74,
        created_at: "2024-01-28T17:20:50.428Z",
        updated_at: "2024-10-29T10:15:10.044Z",
        popular: false,
        slug: "huyen-bau-bang",
        deleted: null,
      },
      {
        id: 720,
        name: "Huyện Dầu Tiếng",
        province_id: 74,
        created_at: "2024-01-28T17:20:50.443Z",
        updated_at: "2024-10-29T10:15:10.050Z",
        popular: false,
        slug: "huyen-dau-tieng",
        deleted: null,
      },
      {
        id: 721,
        name: "Thị xã Bến Cát",
        province_id: 74,
        created_at: "2024-01-28T17:20:50.454Z",
        updated_at: "2024-10-29T10:15:10.065Z",
        popular: false,
        slug: "thi-xa-ben-cat",
        deleted: null,
      },
      {
        id: 722,
        name: "Huyện Phú Giáo",
        province_id: 74,
        created_at: "2024-01-28T17:20:50.467Z",
        updated_at: "2024-10-29T10:15:10.092Z",
        popular: false,
        slug: "huyen-phu-giao",
        deleted: null,
      },
      {
        id: 723,
        name: "Thành phố Tân Uyên",
        province_id: 74,
        created_at: "2024-01-28T17:20:50.479Z",
        updated_at: "2024-10-29T10:15:10.098Z",
        popular: false,
        slug: "thanh-pho-tan-uyen",
        deleted: null,
      },
      {
        id: 724,
        name: "Thành phố Dĩ An",
        province_id: 74,
        created_at: "2024-01-28T17:20:50.492Z",
        updated_at: "2024-10-29T10:15:10.105Z",
        popular: false,
        slug: "thanh-pho-di-an",
        deleted: null,
      },
      {
        id: 725,
        name: "Thành phố Thuận An",
        province_id: 74,
        created_at: "2024-01-28T17:20:50.504Z",
        updated_at: "2024-10-29T10:15:10.112Z",
        popular: false,
        slug: "thanh-pho-thuan-an",
        deleted: null,
      },
      {
        id: 726,
        name: "Huyện Bắc Tân Uyên",
        province_id: 74,
        created_at: "2024-01-28T17:20:50.520Z",
        updated_at: "2024-10-29T10:15:10.119Z",
        popular: false,
        slug: "huyen-bac-tan-uyen",
        deleted: null,
      },
    ],
  },
  {
    id: 75,
    name: "Đồng Nai",
    region: "south",
    created_at: "2024-01-28T17:20:42.785Z",
    updated_at: "2024-10-29T10:15:06.612Z",
    lat: 10.9547167,
    lon: 107.2734235,
    deleted: null,
    slug: "dong-nai",
    district: [
      {
        id: 731,
        name: "Thành phố Biên Hòa",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.535Z",
        updated_at: "2024-10-29T10:15:10.126Z",
        popular: false,
        slug: "thanh-pho-bien-hoa",
        deleted: null,
      },
      {
        id: 732,
        name: "Thành phố Long Khánh",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.548Z",
        updated_at: "2024-10-29T10:15:10.136Z",
        popular: false,
        slug: "thanh-pho-long-khanh",
        deleted: null,
      },
      {
        id: 734,
        name: "Huyện Tân Phú",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.562Z",
        updated_at: "2024-10-29T10:15:10.149Z",
        popular: false,
        slug: "huyen-tan-phu",
        deleted: null,
      },
      {
        id: 735,
        name: "Huyện Vĩnh Cửu",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.574Z",
        updated_at: "2024-10-29T10:15:10.169Z",
        popular: false,
        slug: "huyen-vinh-cuu",
        deleted: null,
      },
      {
        id: 736,
        name: "Huyện Định Quán",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.586Z",
        updated_at: "2024-10-29T10:15:10.182Z",
        popular: false,
        slug: "huyen-dinh-quan",
        deleted: null,
      },
      {
        id: 737,
        name: "Huyện Trảng Bom",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.599Z",
        updated_at: "2024-10-29T10:15:10.193Z",
        popular: false,
        slug: "huyen-trang-bom",
        deleted: null,
      },
      {
        id: 738,
        name: "Huyện Thống Nhất",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.611Z",
        updated_at: "2024-10-29T10:15:10.203Z",
        popular: false,
        slug: "huyen-thong-nhat",
        deleted: null,
      },
      {
        id: 739,
        name: "Huyện Cẩm Mỹ",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.623Z",
        updated_at: "2024-10-29T10:15:10.209Z",
        popular: false,
        slug: "huyen-cam-my",
        deleted: null,
      },
      {
        id: 740,
        name: "Huyện Long Thành",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.636Z",
        updated_at: "2024-10-29T10:15:10.216Z",
        popular: false,
        slug: "huyen-long-thanh",
        deleted: null,
      },
      {
        id: 741,
        name: "Huyện Xuân Lộc",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.651Z",
        updated_at: "2024-10-29T10:15:10.221Z",
        popular: false,
        slug: "huyen-xuan-loc",
        deleted: null,
      },
      {
        id: 742,
        name: "Huyện Nhơn Trạch",
        province_id: 75,
        created_at: "2024-01-28T17:20:50.663Z",
        updated_at: "2024-10-29T10:15:10.227Z",
        popular: false,
        slug: "huyen-nhon-trach",
        deleted: null,
      },
    ],
  },
  {
    id: 77,
    name: "Bà Rịa - Vũng Tàu",
    region: "south",
    created_at: "2024-01-28T17:20:42.797Z",
    updated_at: "2024-10-29T10:15:06.617Z",
    lat: 10.541739,
    lon: 107.242997,
    deleted: null,
    slug: "ba-ria-vung-tau",
    district: [
      {
        id: 747,
        name: "Thành phố Vũng Tàu",
        province_id: 77,
        created_at: "2024-01-28T17:20:50.676Z",
        updated_at: "2024-10-29T10:15:10.238Z",
        popular: false,
        slug: "thanh-pho-vung-tau",
        deleted: null,
      },
      {
        id: 748,
        name: "Thành phố Bà Rịa",
        province_id: 77,
        created_at: "2024-01-28T17:20:50.694Z",
        updated_at: "2024-10-29T10:15:10.247Z",
        popular: false,
        slug: "thanh-pho-ba-ria",
        deleted: null,
      },
      {
        id: 750,
        name: "Huyện Châu Đức",
        province_id: 77,
        created_at: "2024-01-28T17:20:50.708Z",
        updated_at: "2024-10-29T10:15:10.255Z",
        popular: false,
        slug: "huyen-chau-duc",
        deleted: null,
      },
      {
        id: 751,
        name: "Huyện Xuyên Mộc",
        province_id: 77,
        created_at: "2024-01-28T17:20:50.719Z",
        updated_at: "2024-10-29T10:15:10.260Z",
        popular: false,
        slug: "huyen-xuyen-moc",
        deleted: null,
      },
      {
        id: 752,
        name: "Huyện Long Điền",
        province_id: 77,
        created_at: "2024-01-28T17:20:50.732Z",
        updated_at: "2024-10-29T10:15:10.267Z",
        popular: false,
        slug: "huyen-long-dien",
        deleted: null,
      },
      {
        id: 753,
        name: "Huyện Đất Đỏ",
        province_id: 77,
        created_at: "2024-01-28T17:20:50.760Z",
        updated_at: "2024-10-29T10:15:10.272Z",
        popular: false,
        slug: "huyen-dat-do",
        deleted: null,
      },
      {
        id: 754,
        name: "Thị xã Phú Mỹ",
        province_id: 77,
        created_at: "2024-01-28T17:20:50.775Z",
        updated_at: "2024-10-29T10:15:10.278Z",
        popular: false,
        slug: "thi-xa-phu-my",
        deleted: null,
      },
      {
        id: 755,
        name: "Huyện Côn Đảo",
        province_id: 77,
        created_at: "2024-01-28T17:20:50.787Z",
        updated_at: "2024-10-29T10:15:10.284Z",
        popular: false,
        slug: "huyen-con-dao",
        deleted: null,
      },
    ],
  },
  {
    id: 79,
    name: "Hồ Chí Minh",
    region: "south",
    created_at: "2024-01-28T17:20:42.808Z",
    updated_at: "2024-10-29T10:15:06.700Z",
    lat: 10.762622,
    lon: 106.660172,
    deleted: null,
    slug: "ho-chi-minh",
    district: [
      {
        id: 760,
        name: "Quận 1",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.798Z",
        updated_at: "2024-10-29T10:15:10.290Z",
        popular: true,
        slug: "quan-1",
        deleted: null,
      },
      {
        id: 761,
        name: "Quận 12",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.811Z",
        updated_at: "2024-10-29T10:15:10.296Z",
        popular: true,
        slug: "quan-12",
        deleted: null,
      },
      {
        id: 764,
        name: "Gò Vấp",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.822Z",
        updated_at: "2024-10-29T10:15:10.302Z",
        popular: true,
        slug: "go-vap",
        deleted: null,
      },
      {
        id: 765,
        name: "Bình Thạnh",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.838Z",
        updated_at: "2024-10-29T10:15:10.307Z",
        popular: true,
        slug: "binh-thanh",
        deleted: null,
      },
      {
        id: 766,
        name: "Tân Bình",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.850Z",
        updated_at: "2024-10-29T10:15:10.312Z",
        popular: true,
        slug: "tan-binh",
        deleted: null,
      },
      {
        id: 767,
        name: "Tân Phú",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.864Z",
        updated_at: "2024-10-29T10:15:10.318Z",
        popular: true,
        slug: "tan-phu",
        deleted: null,
      },
      {
        id: 768,
        name: "Phú Nhuận",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.878Z",
        updated_at: "2024-10-29T10:15:10.324Z",
        popular: true,
        slug: "phu-nhuan",
        deleted: null,
      },
      {
        id: 769,
        name: "Thủ Đức",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.892Z",
        updated_at: "2024-10-29T10:15:10.331Z",
        popular: true,
        slug: "thu-duc",
        deleted: null,
      },
      {
        id: 770,
        name: "Quận 3",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.905Z",
        updated_at: "2024-10-29T10:15:10.338Z",
        popular: true,
        slug: "quan-3",
        deleted: null,
      },
      {
        id: 771,
        name: "Quận 10",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.920Z",
        updated_at: "2024-10-29T10:15:10.343Z",
        popular: true,
        slug: "quan-10",
        deleted: null,
      },
      {
        id: 772,
        name: "Quận 11",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.932Z",
        updated_at: "2024-10-29T10:15:10.350Z",
        popular: true,
        slug: "quan-11",
        deleted: null,
      },
      {
        id: 773,
        name: "Quận 4",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.946Z",
        updated_at: "2024-10-29T10:15:10.356Z",
        popular: true,
        slug: "quan-4",
        deleted: null,
      },
      {
        id: 774,
        name: "Quận 5",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.958Z",
        updated_at: "2024-10-29T10:15:10.361Z",
        popular: true,
        slug: "quan-5",
        deleted: null,
      },
      {
        id: 775,
        name: "Quận 6",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.972Z",
        updated_at: "2024-10-29T10:15:10.368Z",
        popular: true,
        slug: "quan-6",
        deleted: null,
      },
      {
        id: 776,
        name: "Quận 8",
        province_id: 79,
        created_at: "2024-01-28T17:20:50.988Z",
        updated_at: "2024-10-29T10:15:10.372Z",
        popular: true,
        slug: "quan-8",
        deleted: null,
      },
      {
        id: 777,
        name: "Bình Tân",
        province_id: 79,
        created_at: "2024-01-28T17:20:51.002Z",
        updated_at: "2024-10-29T10:15:10.377Z",
        popular: true,
        slug: "binh-tan",
        deleted: null,
      },
      {
        id: 778,
        name: "Quận 7",
        province_id: 79,
        created_at: "2024-01-28T17:20:51.019Z",
        updated_at: "2024-10-29T10:15:10.382Z",
        popular: true,
        slug: "quan-7",
        deleted: null,
      },
      {
        id: 783,
        name: "Củ Chi",
        province_id: 79,
        created_at: "2024-01-28T17:20:51.032Z",
        updated_at: "2024-10-29T10:15:10.388Z",
        popular: false,
        slug: "cu-chi",
        deleted: null,
      },
      {
        id: 784,
        name: "Hóc Môn",
        province_id: 79,
        created_at: "2024-01-28T17:20:51.044Z",
        updated_at: "2024-10-29T10:15:10.393Z",
        popular: false,
        slug: "hoc-mon",
        deleted: null,
      },
      {
        id: 785,
        name: "Bình Chánh",
        province_id: 79,
        created_at: "2024-01-28T17:20:51.062Z",
        updated_at: "2024-10-29T10:15:10.399Z",
        popular: false,
        slug: "binh-chanh",
        deleted: null,
      },
      {
        id: 786,
        name: "Nhà Bè",
        province_id: 79,
        created_at: "2024-01-28T17:20:51.074Z",
        updated_at: "2024-10-29T10:15:10.404Z",
        popular: false,
        slug: "nha-be",
        deleted: null,
      },
      {
        id: 787,
        name: "Cần Giờ",
        province_id: 79,
        created_at: "2024-01-28T17:20:51.088Z",
        updated_at: "2024-10-29T10:15:10.410Z",
        popular: false,
        slug: "can-gio",
        deleted: null,
      },
    ],
  },
  {
    id: 80,
    name: "Long An",
    region: "south",
    created_at: "2024-01-28T17:20:42.822Z",
    updated_at: "2024-10-29T10:15:06.624Z",
    lat: 10.535247,
    lon: 106.404732,
    deleted: null,
    slug: "long-an",
    district: [
      {
        id: 794,
        name: "Thành phố Tân An",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.103Z",
        updated_at: "2024-10-29T10:15:10.415Z",
        popular: false,
        slug: "thanh-pho-tan-an",
        deleted: null,
      },
      {
        id: 795,
        name: "Thị xã Kiến Tường",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.116Z",
        updated_at: "2024-10-29T10:15:10.420Z",
        popular: false,
        slug: "thi-xa-kien-tuong",
        deleted: null,
      },
      {
        id: 796,
        name: "Huyện Tân Hưng",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.132Z",
        updated_at: "2024-10-29T10:15:10.426Z",
        popular: false,
        slug: "huyen-tan-hung",
        deleted: null,
      },
      {
        id: 797,
        name: "Huyện Vĩnh Hưng",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.148Z",
        updated_at: "2024-10-29T10:15:10.431Z",
        popular: false,
        slug: "huyen-vinh-hung",
        deleted: null,
      },
      {
        id: 798,
        name: "Huyện Mộc Hóa",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.161Z",
        updated_at: "2024-10-29T10:15:10.440Z",
        popular: false,
        slug: "huyen-moc-hoa",
        deleted: null,
      },
      {
        id: 799,
        name: "Huyện Tân Thạnh",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.175Z",
        updated_at: "2024-10-29T10:15:10.445Z",
        popular: false,
        slug: "huyen-tan-thanh",
        deleted: null,
      },
      {
        id: 800,
        name: "Huyện Thạnh Hóa",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.187Z",
        updated_at: "2024-10-29T10:15:10.452Z",
        popular: false,
        slug: "huyen-thanh-hoa",
        deleted: null,
      },
      {
        id: 801,
        name: "Huyện Đức Huệ",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.200Z",
        updated_at: "2024-10-29T10:15:10.457Z",
        popular: false,
        slug: "huyen-duc-hue",
        deleted: null,
      },
      {
        id: 802,
        name: "Huyện Đức Hòa",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.212Z",
        updated_at: "2024-10-29T10:15:10.461Z",
        popular: false,
        slug: "huyen-duc-hoa",
        deleted: null,
      },
      {
        id: 803,
        name: "Huyện Bến Lức",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.226Z",
        updated_at: "2024-10-29T10:15:10.466Z",
        popular: false,
        slug: "huyen-ben-luc",
        deleted: null,
      },
      {
        id: 804,
        name: "Huyện Thủ Thừa",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.239Z",
        updated_at: "2024-10-29T10:15:10.471Z",
        popular: false,
        slug: "huyen-thu-thua",
        deleted: null,
      },
      {
        id: 805,
        name: "Huyện Tân Trụ",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.252Z",
        updated_at: "2024-10-29T10:15:10.476Z",
        popular: false,
        slug: "huyen-tan-tru",
        deleted: null,
      },
      {
        id: 806,
        name: "Huyện Cần Đước",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.267Z",
        updated_at: "2024-10-29T10:15:10.481Z",
        popular: false,
        slug: "huyen-can-duoc",
        deleted: null,
      },
      {
        id: 807,
        name: "Huyện Cần Giuộc",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.279Z",
        updated_at: "2024-10-29T10:15:10.486Z",
        popular: false,
        slug: "huyen-can-giuoc",
        deleted: null,
      },
      {
        id: 808,
        name: "Huyện Châu Thành",
        province_id: 80,
        created_at: "2024-01-28T17:20:51.295Z",
        updated_at: "2024-10-29T10:15:10.491Z",
        popular: false,
        slug: "huyen-chau-thanh",
        deleted: null,
      },
    ],
  },
  {
    id: 82,
    name: "Tiền Giang",
    region: "south",
    created_at: "2024-01-28T17:20:42.833Z",
    updated_at: "2024-10-29T10:15:06.629Z",
    lat: 10.358791,
    lon: 106.36528,
    deleted: null,
    slug: "tien-giang",
    district: [
      {
        id: 815,
        name: "Thành phố Mỹ Tho",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.309Z",
        updated_at: "2024-10-29T10:15:10.496Z",
        popular: false,
        slug: "thanh-pho-my-tho",
        deleted: null,
      },
      {
        id: 816,
        name: "Thị xã Gò Công",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.328Z",
        updated_at: "2024-10-29T10:15:10.501Z",
        popular: false,
        slug: "thi-xa-go-cong",
        deleted: null,
      },
      {
        id: 817,
        name: "Thị xã Cai Lậy",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.348Z",
        updated_at: "2024-10-29T10:15:10.507Z",
        popular: false,
        slug: "thi-xa-cai-lay",
        deleted: null,
      },
      {
        id: 818,
        name: "Huyện Tân Phước",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.363Z",
        updated_at: "2024-10-29T10:15:10.514Z",
        popular: false,
        slug: "huyen-tan-phuoc",
        deleted: null,
      },
      {
        id: 819,
        name: "Huyện Cái Bè",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.378Z",
        updated_at: "2024-10-29T10:15:10.520Z",
        popular: false,
        slug: "huyen-cai-be",
        deleted: null,
      },
      {
        id: 820,
        name: "Huyện Cai Lậy",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.392Z",
        updated_at: "2024-10-29T10:15:10.526Z",
        popular: false,
        slug: "huyen-cai-lay",
        deleted: null,
      },
      {
        id: 821,
        name: "Huyện Châu Thành",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.409Z",
        updated_at: "2024-10-29T10:15:10.531Z",
        popular: false,
        slug: "huyen-chau-thanh",
        deleted: null,
      },
      {
        id: 822,
        name: "Huyện Chợ Gạo",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.422Z",
        updated_at: "2024-10-29T10:15:10.536Z",
        popular: false,
        slug: "huyen-cho-gao",
        deleted: null,
      },
      {
        id: 823,
        name: "Huyện Gò Công Tây",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.435Z",
        updated_at: "2024-10-29T10:15:10.541Z",
        popular: false,
        slug: "huyen-go-cong-tay",
        deleted: null,
      },
      {
        id: 824,
        name: "Huyện Gò Công Đông",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.447Z",
        updated_at: "2024-10-29T10:15:10.546Z",
        popular: false,
        slug: "huyen-go-cong-dong",
        deleted: null,
      },
      {
        id: 825,
        name: "Huyện Tân Phú Đông",
        province_id: 82,
        created_at: "2024-01-28T17:20:51.460Z",
        updated_at: "2024-10-29T10:15:10.552Z",
        popular: false,
        slug: "huyen-tan-phu-dong",
        deleted: null,
      },
    ],
  },
  {
    id: 83,
    name: "Bến Tre",
    region: "south",
    created_at: "2024-01-28T17:20:42.844Z",
    updated_at: "2024-10-29T10:15:06.636Z",
    lat: 10.241979,
    lon: 106.375054,
    deleted: null,
    slug: "ben-tre",
    district: [
      {
        id: 829,
        name: "Thành phố Bến Tre",
        province_id: 83,
        created_at: "2024-01-28T17:20:51.473Z",
        updated_at: "2024-10-29T10:15:10.560Z",
        popular: false,
        slug: "thanh-pho-ben-tre",
        deleted: null,
      },
      {
        id: 831,
        name: "Huyện Châu Thành",
        province_id: 83,
        created_at: "2024-01-28T17:20:51.488Z",
        updated_at: "2024-10-29T10:15:10.568Z",
        popular: false,
        slug: "huyen-chau-thanh",
        deleted: null,
      },
      {
        id: 832,
        name: "Huyện Chợ Lách",
        province_id: 83,
        created_at: "2024-01-28T17:20:51.501Z",
        updated_at: "2024-10-29T10:15:10.573Z",
        popular: false,
        slug: "huyen-cho-lach",
        deleted: null,
      },
      {
        id: 833,
        name: "Huyện Mỏ Cày Nam",
        province_id: 83,
        created_at: "2024-01-28T17:20:51.515Z",
        updated_at: "2024-10-29T10:15:10.586Z",
        popular: false,
        slug: "huyen-mo-cay-nam",
        deleted: null,
      },
      {
        id: 834,
        name: "Huyện Giồng Trôm",
        province_id: 83,
        created_at: "2024-01-28T17:20:51.527Z",
        updated_at: "2024-10-29T10:15:10.594Z",
        popular: false,
        slug: "huyen-giong-trom",
        deleted: null,
      },
      {
        id: 835,
        name: "Huyện Bình Đại",
        province_id: 83,
        created_at: "2024-01-28T17:20:51.541Z",
        updated_at: "2024-10-29T10:15:10.599Z",
        popular: false,
        slug: "huyen-binh-dai",
        deleted: null,
      },
      {
        id: 836,
        name: "Huyện Ba Tri",
        province_id: 83,
        created_at: "2024-01-28T17:20:51.555Z",
        updated_at: "2024-10-29T10:15:10.604Z",
        popular: false,
        slug: "huyen-ba-tri",
        deleted: null,
      },
      {
        id: 837,
        name: "Huyện Thạnh Phú",
        province_id: 83,
        created_at: "2024-01-28T17:20:51.566Z",
        updated_at: "2024-10-29T10:15:10.627Z",
        popular: false,
        slug: "huyen-thanh-phu",
        deleted: null,
      },
      {
        id: 838,
        name: "Huyện Mỏ Cày Bắc",
        province_id: 83,
        created_at: "2024-01-28T17:20:51.579Z",
        updated_at: "2024-10-29T10:15:10.634Z",
        popular: false,
        slug: "huyen-mo-cay-bac",
        deleted: null,
      },
    ],
  },
  {
    id: 84,
    name: "Trà Vinh",
    region: "south",
    created_at: "2024-01-28T17:20:42.857Z",
    updated_at: "2024-10-29T10:15:06.641Z",
    lat: 9.935182,
    lon: 106.34139,
    deleted: null,
    slug: "tra-vinh",
    district: [
      {
        id: 842,
        name: "Thành phố Trà Vinh",
        province_id: 84,
        created_at: "2024-01-28T17:20:51.592Z",
        updated_at: "2024-10-29T10:15:10.640Z",
        popular: false,
        slug: "thanh-pho-tra-vinh",
        deleted: null,
      },
      {
        id: 844,
        name: "Huyện Càng Long",
        province_id: 84,
        created_at: "2024-01-28T17:20:51.607Z",
        updated_at: "2024-10-29T10:15:10.657Z",
        popular: false,
        slug: "huyen-cang-long",
        deleted: null,
      },
      {
        id: 845,
        name: "Huyện Cầu Kè",
        province_id: 84,
        created_at: "2024-01-28T17:20:51.619Z",
        updated_at: "2024-10-29T10:15:10.662Z",
        popular: false,
        slug: "huyen-cau-ke",
        deleted: null,
      },
      {
        id: 846,
        name: "Huyện Tiểu Cần",
        province_id: 84,
        created_at: "2024-01-28T17:20:51.632Z",
        updated_at: "2024-10-29T10:15:10.668Z",
        popular: false,
        slug: "huyen-tieu-can",
        deleted: null,
      },
      {
        id: 847,
        name: "Huyện Châu Thành",
        province_id: 84,
        created_at: "2024-01-28T17:20:51.644Z",
        updated_at: "2024-10-29T10:15:10.682Z",
        popular: false,
        slug: "huyen-chau-thanh",
        deleted: null,
      },
      {
        id: 848,
        name: "Huyện Cầu Ngang",
        province_id: 84,
        created_at: "2024-01-28T17:20:51.657Z",
        updated_at: "2024-10-29T10:15:10.688Z",
        popular: false,
        slug: "huyen-cau-ngang",
        deleted: null,
      },
      {
        id: 849,
        name: "Huyện Trà Cú",
        province_id: 84,
        created_at: "2024-01-28T17:20:51.669Z",
        updated_at: "2024-10-29T10:15:10.693Z",
        popular: false,
        slug: "huyen-tra-cu",
        deleted: null,
      },
      {
        id: 850,
        name: "Huyện Duyên Hải",
        province_id: 84,
        created_at: "2024-01-28T17:20:51.680Z",
        updated_at: "2024-10-29T10:15:10.698Z",
        popular: false,
        slug: "huyen-duyen-hai",
        deleted: null,
      },
      {
        id: 851,
        name: "Thị xã Duyên Hải",
        province_id: 84,
        created_at: "2024-01-28T17:20:51.692Z",
        updated_at: "2024-10-29T10:15:10.704Z",
        popular: false,
        slug: "thi-xa-duyen-hai",
        deleted: null,
      },
    ],
  },
  {
    id: 86,
    name: "Vĩnh Long",
    region: "south",
    created_at: "2024-01-28T17:20:42.867Z",
    updated_at: "2024-10-29T10:15:06.648Z",
    lat: 10.255093,
    lon: 105.9754,
    deleted: null,
    slug: "vinh-long",
    district: [
      {
        id: 855,
        name: "Thành phố Vĩnh Long",
        province_id: 86,
        created_at: "2024-01-28T17:20:51.706Z",
        updated_at: "2024-10-29T10:15:10.709Z",
        popular: false,
        slug: "thanh-pho-vinh-long",
        deleted: null,
      },
      {
        id: 857,
        name: "Huyện Long Hồ",
        province_id: 86,
        created_at: "2024-01-28T17:20:51.717Z",
        updated_at: "2024-10-29T10:15:10.714Z",
        popular: false,
        slug: "huyen-long-ho",
        deleted: null,
      },
      {
        id: 858,
        name: "Huyện Mang Thít",
        province_id: 86,
        created_at: "2024-01-28T17:20:51.730Z",
        updated_at: "2024-10-29T10:15:10.718Z",
        popular: false,
        slug: "huyen-mang-thit",
        deleted: null,
      },
      {
        id: 859,
        name: "Huyện Vũng Liêm",
        province_id: 86,
        created_at: "2024-01-28T17:20:51.742Z",
        updated_at: "2024-10-29T10:15:10.724Z",
        popular: false,
        slug: "huyen-vung-liem",
        deleted: null,
      },
      {
        id: 860,
        name: "Huyện Tam Bình",
        province_id: 86,
        created_at: "2024-01-28T17:20:51.754Z",
        updated_at: "2024-10-29T10:15:10.729Z",
        popular: false,
        slug: "huyen-tam-binh",
        deleted: null,
      },
      {
        id: 861,
        name: "Thị xã Bình Minh",
        province_id: 86,
        created_at: "2024-01-28T17:20:51.768Z",
        updated_at: "2024-10-29T10:15:10.735Z",
        popular: false,
        slug: "thi-xa-binh-minh",
        deleted: null,
      },
      {
        id: 862,
        name: "Huyện Trà Ôn",
        province_id: 86,
        created_at: "2024-01-28T17:20:51.783Z",
        updated_at: "2024-10-29T10:15:10.740Z",
        popular: false,
        slug: "huyen-tra-on",
        deleted: null,
      },
      {
        id: 863,
        name: "Huyện Bình Tân",
        province_id: 86,
        created_at: "2024-01-28T17:20:51.795Z",
        updated_at: "2024-10-29T10:15:10.746Z",
        popular: false,
        slug: "huyen-binh-tan",
        deleted: null,
      },
    ],
  },
  {
    id: 87,
    name: "Đồng Tháp",
    region: "south",
    created_at: "2024-01-28T17:20:42.880Z",
    updated_at: "2024-10-29T10:15:06.654Z",
    lat: 10.60017,
    lon: 105.760067,
    deleted: null,
    slug: "dong-thap",
    district: [
      {
        id: 866,
        name: "Thành phố Cao Lãnh",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.808Z",
        updated_at: "2024-10-29T10:15:10.752Z",
        popular: false,
        slug: "thanh-pho-cao-lanh",
        deleted: null,
      },
      {
        id: 867,
        name: "Thành phố Sa Đéc",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.822Z",
        updated_at: "2024-10-29T10:15:10.759Z",
        popular: false,
        slug: "thanh-pho-sa-dec",
        deleted: null,
      },
      {
        id: 868,
        name: "Thành phố Hồng Ngự",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.833Z",
        updated_at: "2024-10-29T10:15:10.764Z",
        popular: false,
        slug: "thanh-pho-hong-ngu",
        deleted: null,
      },
      {
        id: 869,
        name: "Huyện Tân Hồng",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.846Z",
        updated_at: "2024-10-29T10:15:10.769Z",
        popular: false,
        slug: "huyen-tan-hong",
        deleted: null,
      },
      {
        id: 870,
        name: "Huyện Hồng Ngự",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.857Z",
        updated_at: "2024-10-29T10:15:10.775Z",
        popular: false,
        slug: "huyen-hong-ngu",
        deleted: null,
      },
      {
        id: 871,
        name: "Huyện Tam Nông",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.870Z",
        updated_at: "2024-10-29T10:15:10.780Z",
        popular: false,
        slug: "huyen-tam-nong",
        deleted: null,
      },
      {
        id: 872,
        name: "Huyện Tháp Mười",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.881Z",
        updated_at: "2024-10-29T10:15:10.787Z",
        popular: false,
        slug: "huyen-thap-muoi",
        deleted: null,
      },
      {
        id: 873,
        name: "Huyện Cao Lãnh",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.892Z",
        updated_at: "2024-10-29T10:15:10.792Z",
        popular: false,
        slug: "huyen-cao-lanh",
        deleted: null,
      },
      {
        id: 874,
        name: "Huyện Thanh Bình",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.904Z",
        updated_at: "2024-10-29T10:15:10.799Z",
        popular: false,
        slug: "huyen-thanh-binh",
        deleted: null,
      },
      {
        id: 875,
        name: "Huyện Lấp Vò",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.920Z",
        updated_at: "2024-10-29T10:15:10.809Z",
        popular: false,
        slug: "huyen-lap-vo",
        deleted: null,
      },
      {
        id: 876,
        name: "Huyện Lai Vung",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.931Z",
        updated_at: "2024-10-29T10:15:10.814Z",
        popular: false,
        slug: "huyen-lai-vung",
        deleted: null,
      },
      {
        id: 877,
        name: "Huyện Châu Thành",
        province_id: 87,
        created_at: "2024-01-28T17:20:51.943Z",
        updated_at: "2024-10-29T10:15:10.825Z",
        popular: false,
        slug: "huyen-chau-thanh",
        deleted: null,
      },
    ],
  },
  {
    id: 89,
    name: "An Giang",
    region: "south",
    created_at: "2024-01-28T17:20:42.890Z",
    updated_at: "2024-10-29T10:15:06.660Z",
    lat: 10.521583,
    lon: 105.125896,
    deleted: null,
    slug: "an-giang",
    district: [
      {
        id: 883,
        name: "Thành phố Long Xuyên",
        province_id: 89,
        created_at: "2024-01-28T17:20:51.953Z",
        updated_at: "2024-10-29T10:15:10.831Z",
        popular: false,
        slug: "thanh-pho-long-xuyen",
        deleted: null,
      },
      {
        id: 884,
        name: "Thành phố Châu Đốc",
        province_id: 89,
        created_at: "2024-01-28T17:20:51.963Z",
        updated_at: "2024-10-29T10:15:10.837Z",
        popular: false,
        slug: "thanh-pho-chau-doc",
        deleted: null,
      },
      {
        id: 886,
        name: "Huyện An Phú",
        province_id: 89,
        created_at: "2024-01-28T17:20:51.976Z",
        updated_at: "2024-10-29T10:15:10.859Z",
        popular: false,
        slug: "huyen-an-phu",
        deleted: null,
      },
      {
        id: 887,
        name: "Thị xã Tân Châu",
        province_id: 89,
        created_at: "2024-01-28T17:20:51.990Z",
        updated_at: "2024-10-29T10:15:10.865Z",
        popular: false,
        slug: "thi-xa-tan-chau",
        deleted: null,
      },
      {
        id: 888,
        name: "Huyện Phú Tân",
        province_id: 89,
        created_at: "2024-01-28T17:20:52.002Z",
        updated_at: "2024-10-29T10:15:10.870Z",
        popular: false,
        slug: "huyen-phu-tan",
        deleted: null,
      },
      {
        id: 889,
        name: "Huyện Châu Phú",
        province_id: 89,
        created_at: "2024-01-28T17:20:52.016Z",
        updated_at: "2024-10-29T10:15:10.876Z",
        popular: false,
        slug: "huyen-chau-phu",
        deleted: null,
      },
      {
        id: 890,
        name: "Thị xã Tịnh Biên",
        province_id: 89,
        created_at: "2024-01-28T17:20:52.029Z",
        updated_at: "2024-10-29T10:15:10.881Z",
        popular: false,
        slug: "thi-xa-tinh-bien",
        deleted: null,
      },
      {
        id: 891,
        name: "Huyện Tri Tôn",
        province_id: 89,
        created_at: "2024-01-28T17:20:52.044Z",
        updated_at: "2024-10-29T10:15:10.886Z",
        popular: false,
        slug: "huyen-tri-ton",
        deleted: null,
      },
      {
        id: 892,
        name: "Huyện Châu Thành",
        province_id: 89,
        created_at: "2024-01-28T17:20:52.058Z",
        updated_at: "2024-10-29T10:15:10.891Z",
        popular: false,
        slug: "huyen-chau-thanh",
        deleted: null,
      },
      {
        id: 893,
        name: "Huyện Chợ Mới",
        province_id: 89,
        created_at: "2024-01-28T17:20:52.070Z",
        updated_at: "2024-10-29T10:15:10.897Z",
        popular: false,
        slug: "huyen-cho-moi",
        deleted: null,
      },
      {
        id: 894,
        name: "Huyện Thoại Sơn",
        province_id: 89,
        created_at: "2024-01-28T17:20:52.084Z",
        updated_at: "2024-10-29T10:15:10.902Z",
        popular: false,
        slug: "huyen-thoai-son",
        deleted: null,
      },
    ],
  },
  {
    id: 91,
    name: "Kiên Giang",
    region: "south",
    created_at: "2024-01-28T17:20:42.904Z",
    updated_at: "2024-10-29T10:15:06.665Z",
    lat: 10.015089,
    lon: 105.078899,
    deleted: null,
    slug: "kien-giang",
    district: [
      {
        id: 899,
        name: "Thành phố Rạch Giá",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.100Z",
        updated_at: "2024-10-29T10:15:10.908Z",
        popular: false,
        slug: "thanh-pho-rach-gia",
        deleted: null,
      },
      {
        id: 900,
        name: "Thành phố Hà Tiên",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.112Z",
        updated_at: "2024-10-29T10:15:10.915Z",
        popular: false,
        slug: "thanh-pho-ha-tien",
        deleted: null,
      },
      {
        id: 902,
        name: "Huyện Kiên Lương",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.136Z",
        updated_at: "2024-10-29T10:15:10.920Z",
        popular: false,
        slug: "huyen-kien-luong",
        deleted: null,
      },
      {
        id: 903,
        name: "Huyện Hòn Đất",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.168Z",
        updated_at: "2024-10-29T10:15:10.927Z",
        popular: false,
        slug: "huyen-hon-dat",
        deleted: null,
      },
      {
        id: 904,
        name: "Huyện Tân Hiệp",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.187Z",
        updated_at: "2024-10-29T10:15:10.933Z",
        popular: false,
        slug: "huyen-tan-hiep",
        deleted: null,
      },
      {
        id: 905,
        name: "Huyện Châu Thành",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.204Z",
        updated_at: "2024-10-29T10:15:10.939Z",
        popular: false,
        slug: "huyen-chau-thanh",
        deleted: null,
      },
      {
        id: 906,
        name: "Huyện Giồng Riềng",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.216Z",
        updated_at: "2024-10-29T10:15:10.945Z",
        popular: false,
        slug: "huyen-giong-rieng",
        deleted: null,
      },
      {
        id: 907,
        name: "Huyện Gò Quao",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.232Z",
        updated_at: "2024-10-29T10:15:10.962Z",
        popular: false,
        slug: "huyen-go-quao",
        deleted: null,
      },
      {
        id: 908,
        name: "Huyện An Biên",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.246Z",
        updated_at: "2024-10-29T10:15:10.970Z",
        popular: false,
        slug: "huyen-an-bien",
        deleted: null,
      },
      {
        id: 909,
        name: "Huyện An Minh",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.260Z",
        updated_at: "2024-10-29T10:15:10.980Z",
        popular: false,
        slug: "huyen-an-minh",
        deleted: null,
      },
      {
        id: 910,
        name: "Huyện Vĩnh Thuận",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.278Z",
        updated_at: "2024-10-29T10:15:10.986Z",
        popular: false,
        slug: "huyen-vinh-thuan",
        deleted: null,
      },
      {
        id: 911,
        name: "Thành phố Phú Quốc",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.292Z",
        updated_at: "2024-10-29T10:15:10.995Z",
        popular: false,
        slug: "thanh-pho-phu-quoc",
        deleted: null,
      },
      {
        id: 912,
        name: "Huyện Kiên Hải",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.307Z",
        updated_at: "2024-10-29T10:15:11.001Z",
        popular: false,
        slug: "huyen-kien-hai",
        deleted: null,
      },
      {
        id: 913,
        name: "Huyện U Minh Thượng",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.320Z",
        updated_at: "2024-10-29T10:15:11.021Z",
        popular: false,
        slug: "huyen-u-minh-thuong",
        deleted: null,
      },
      {
        id: 914,
        name: "Huyện Giang Thành",
        province_id: 91,
        created_at: "2024-01-28T17:20:52.332Z",
        updated_at: "2024-10-29T10:15:11.035Z",
        popular: false,
        slug: "huyen-giang-thanh",
        deleted: null,
      },
    ],
  },
  {
    id: 92,
    name: "Cần Thơ",
    region: "south",
    created_at: "2024-01-28T17:20:42.915Z",
    updated_at: "2024-10-29T10:15:06.672Z",
    lat: 10.0451628,
    lon: 105.7468535,
    deleted: null,
    slug: "can-tho",
    district: [
      {
        id: 916,
        name: "Quận Ninh Kiều",
        province_id: 92,
        created_at: "2024-01-28T17:20:52.349Z",
        updated_at: "2024-10-29T10:15:11.041Z",
        popular: false,
        slug: "quan-ninh-kieu",
        deleted: null,
      },
      {
        id: 917,
        name: "Quận Ô Môn",
        province_id: 92,
        created_at: "2024-01-28T17:20:52.362Z",
        updated_at: "2024-10-29T10:15:11.046Z",
        popular: false,
        slug: "quan-o-mon",
        deleted: null,
      },
      {
        id: 918,
        name: "Quận Bình Thuỷ",
        province_id: 92,
        created_at: "2024-01-28T17:20:52.379Z",
        updated_at: "2024-10-29T10:15:11.051Z",
        popular: false,
        slug: "quan-binh-thuy",
        deleted: null,
      },
      {
        id: 919,
        name: "Quận Cái Răng",
        province_id: 92,
        created_at: "2024-01-28T17:20:52.395Z",
        updated_at: "2024-10-29T10:15:11.056Z",
        popular: false,
        slug: "quan-cai-rang",
        deleted: null,
      },
      {
        id: 923,
        name: "Quận Thốt Nốt",
        province_id: 92,
        created_at: "2024-01-28T17:20:52.412Z",
        updated_at: "2024-10-29T10:15:11.074Z",
        popular: false,
        slug: "quan-thot-not",
        deleted: null,
      },
      {
        id: 924,
        name: "Huyện Vĩnh Thạnh",
        province_id: 92,
        created_at: "2024-01-28T17:20:52.430Z",
        updated_at: "2024-10-29T10:15:11.080Z",
        popular: false,
        slug: "huyen-vinh-thanh",
        deleted: null,
      },
      {
        id: 925,
        name: "Huyện Cờ Đỏ",
        province_id: 92,
        created_at: "2024-01-28T17:20:52.443Z",
        updated_at: "2024-10-29T10:15:11.086Z",
        popular: false,
        slug: "huyen-co-do",
        deleted: null,
      },
      {
        id: 926,
        name: "Huyện Phong Điền",
        province_id: 92,
        created_at: "2024-01-28T17:20:52.456Z",
        updated_at: "2024-10-29T10:15:11.091Z",
        popular: false,
        slug: "huyen-phong-dien",
        deleted: null,
      },
      {
        id: 927,
        name: "Huyện Thới Lai",
        province_id: 92,
        created_at: "2024-01-28T17:20:52.469Z",
        updated_at: "2024-10-29T10:15:11.096Z",
        popular: false,
        slug: "huyen-thoi-lai",
        deleted: null,
      },
    ],
  },
  {
    id: 93,
    name: "Hậu Giang",
    region: "south",
    created_at: "2024-01-28T17:20:42.927Z",
    updated_at: "2024-10-29T10:15:06.678Z",
    lat: 9.784634,
    lon: 105.470319,
    deleted: null,
    slug: "hau-giang",
    district: [
      {
        id: 930,
        name: "Thành phố Vị Thanh",
        province_id: 93,
        created_at: "2024-01-28T17:20:52.480Z",
        updated_at: "2024-10-29T10:15:11.100Z",
        popular: false,
        slug: "thanh-pho-vi-thanh",
        deleted: null,
      },
      {
        id: 931,
        name: "Thành phố Ngã Bảy",
        province_id: 93,
        created_at: "2024-01-28T17:20:52.494Z",
        updated_at: "2024-10-29T10:15:11.104Z",
        popular: false,
        slug: "thanh-pho-nga-bay",
        deleted: null,
      },
      {
        id: 932,
        name: "Huyện Châu Thành A",
        province_id: 93,
        created_at: "2024-01-28T17:20:52.508Z",
        updated_at: "2024-10-29T10:15:11.109Z",
        popular: false,
        slug: "huyen-chau-thanh-a",
        deleted: null,
      },
      {
        id: 933,
        name: "Huyện Châu Thành",
        province_id: 93,
        created_at: "2024-01-28T17:20:52.523Z",
        updated_at: "2024-10-29T10:15:11.115Z",
        popular: false,
        slug: "huyen-chau-thanh",
        deleted: null,
      },
      {
        id: 934,
        name: "Huyện Phụng Hiệp",
        province_id: 93,
        created_at: "2024-01-28T17:20:52.534Z",
        updated_at: "2024-10-29T10:15:11.130Z",
        popular: false,
        slug: "huyen-phung-hiep",
        deleted: null,
      },
      {
        id: 935,
        name: "Huyện Vị Thuỷ",
        province_id: 93,
        created_at: "2024-01-28T17:20:52.546Z",
        updated_at: "2024-10-29T10:15:11.136Z",
        popular: false,
        slug: "huyen-vi-thuy",
        deleted: null,
      },
      {
        id: 936,
        name: "Huyện Long Mỹ",
        province_id: 93,
        created_at: "2024-01-28T17:20:52.560Z",
        updated_at: "2024-10-29T10:15:11.140Z",
        popular: false,
        slug: "huyen-long-my",
        deleted: null,
      },
      {
        id: 937,
        name: "Thị xã Long Mỹ",
        province_id: 93,
        created_at: "2024-01-28T17:20:52.573Z",
        updated_at: "2024-10-29T10:15:11.145Z",
        popular: false,
        slug: "thi-xa-long-my",
        deleted: null,
      },
    ],
  },
  {
    id: 94,
    name: "Sóc Trăng",
    region: "south",
    created_at: "2024-01-28T17:20:42.937Z",
    updated_at: "2024-10-29T10:15:06.683Z",
    lat: 9.603554,
    lon: 105.980209,
    deleted: null,
    slug: "soc-trang",
    district: [
      {
        id: 941,
        name: "Thành phố Sóc Trăng",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.588Z",
        updated_at: "2024-10-29T10:15:11.151Z",
        popular: false,
        slug: "thanh-pho-soc-trang",
        deleted: null,
      },
      {
        id: 942,
        name: "Huyện Châu Thành",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.606Z",
        updated_at: "2024-10-29T10:15:11.156Z",
        popular: false,
        slug: "huyen-chau-thanh",
        deleted: null,
      },
      {
        id: 943,
        name: "Huyện Kế Sách",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.619Z",
        updated_at: "2024-10-29T10:15:11.162Z",
        popular: false,
        slug: "huyen-ke-sach",
        deleted: null,
      },
      {
        id: 944,
        name: "Huyện Mỹ Tú",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.634Z",
        updated_at: "2024-10-29T10:15:11.167Z",
        popular: false,
        slug: "huyen-my-tu",
        deleted: null,
      },
      {
        id: 945,
        name: "Huyện Cù Lao Dung",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.652Z",
        updated_at: "2024-10-29T10:15:11.173Z",
        popular: false,
        slug: "huyen-cu-lao-dung",
        deleted: null,
      },
      {
        id: 946,
        name: "Huyện Long Phú",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.665Z",
        updated_at: "2024-10-29T10:15:11.179Z",
        popular: false,
        slug: "huyen-long-phu",
        deleted: null,
      },
      {
        id: 947,
        name: "Huyện Mỹ Xuyên",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.684Z",
        updated_at: "2024-10-29T10:15:11.185Z",
        popular: false,
        slug: "huyen-my-xuyen",
        deleted: null,
      },
      {
        id: 948,
        name: "Thị xã Ngã Năm",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.696Z",
        updated_at: "2024-10-29T10:15:11.191Z",
        popular: false,
        slug: "thi-xa-nga-nam",
        deleted: null,
      },
      {
        id: 949,
        name: "Huyện Thạnh Trị",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.708Z",
        updated_at: "2024-10-29T10:15:11.199Z",
        popular: false,
        slug: "huyen-thanh-tri",
        deleted: null,
      },
      {
        id: 950,
        name: "Thị xã Vĩnh Châu",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.721Z",
        updated_at: "2024-10-29T10:15:11.205Z",
        popular: false,
        slug: "thi-xa-vinh-chau",
        deleted: null,
      },
      {
        id: 951,
        name: "Huyện Trần Đề",
        province_id: 94,
        created_at: "2024-01-28T17:20:52.734Z",
        updated_at: "2024-10-29T10:15:11.213Z",
        popular: false,
        slug: "huyen-tran-de",
        deleted: null,
      },
    ],
  },
  {
    id: 95,
    name: "Bạc Liêu",
    region: "south",
    created_at: "2024-01-28T17:20:42.948Z",
    updated_at: "2024-10-29T10:15:06.689Z",
    lat: 9.285275,
    lon: 105.724729,
    deleted: null,
    slug: "bac-lieu",
    district: [
      {
        id: 954,
        name: "Thành phố Bạc Liêu",
        province_id: 95,
        created_at: "2024-01-28T17:20:52.748Z",
        updated_at: "2024-10-29T10:15:11.221Z",
        popular: false,
        slug: "thanh-pho-bac-lieu",
        deleted: null,
      },
      {
        id: 956,
        name: "Huyện Hồng Dân",
        province_id: 95,
        created_at: "2024-01-28T17:20:52.764Z",
        updated_at: "2024-10-29T10:15:11.226Z",
        popular: false,
        slug: "huyen-hong-dan",
        deleted: null,
      },
      {
        id: 957,
        name: "Huyện Phước Long",
        province_id: 95,
        created_at: "2024-01-28T17:20:52.782Z",
        updated_at: "2024-10-29T10:15:11.230Z",
        popular: false,
        slug: "huyen-phuoc-long",
        deleted: null,
      },
      {
        id: 958,
        name: "Huyện Vĩnh Lợi",
        province_id: 95,
        created_at: "2024-01-28T17:20:52.794Z",
        updated_at: "2024-10-29T10:15:11.235Z",
        popular: false,
        slug: "huyen-vinh-loi",
        deleted: null,
      },
      {
        id: 959,
        name: "Thị xã Giá Rai",
        province_id: 95,
        created_at: "2024-01-28T17:20:52.808Z",
        updated_at: "2024-10-29T10:15:11.240Z",
        popular: false,
        slug: "thi-xa-gia-rai",
        deleted: null,
      },
      {
        id: 960,
        name: "Huyện Đông Hải",
        province_id: 95,
        created_at: "2024-01-28T17:20:52.825Z",
        updated_at: "2024-10-29T10:15:11.244Z",
        popular: false,
        slug: "huyen-dong-hai",
        deleted: null,
      },
      {
        id: 961,
        name: "Huyện Hoà Bình",
        province_id: 95,
        created_at: "2024-01-28T17:20:52.839Z",
        updated_at: "2024-10-29T10:15:11.249Z",
        popular: false,
        slug: "huyen-hoa-binh",
        deleted: null,
      },
    ],
  },
  {
    id: 96,
    name: "Cà Mau",
    region: "south",
    created_at: "2024-01-28T17:20:42.962Z",
    updated_at: "2024-10-29T10:15:06.695Z",
    lat: 9.176822,
    lon: 105.151365,
    deleted: null,
    slug: "ca-mau",
    district: [
      {
        id: 964,
        name: "Thành phố Cà Mau",
        province_id: 96,
        created_at: "2024-01-28T17:20:52.856Z",
        updated_at: "2024-10-29T10:15:11.255Z",
        popular: false,
        slug: "thanh-pho-ca-mau",
        deleted: null,
      },
      {
        id: 966,
        name: "Huyện U Minh",
        province_id: 96,
        created_at: "2024-01-28T17:20:52.871Z",
        updated_at: "2024-10-29T10:15:11.260Z",
        popular: false,
        slug: "huyen-u-minh",
        deleted: null,
      },
      {
        id: 967,
        name: "Huyện Thới Bình",
        province_id: 96,
        created_at: "2024-01-28T17:20:52.883Z",
        updated_at: "2024-10-29T10:15:11.265Z",
        popular: false,
        slug: "huyen-thoi-binh",
        deleted: null,
      },
      {
        id: 968,
        name: "Huyện Trần Văn Thời",
        province_id: 96,
        created_at: "2024-01-28T17:20:52.896Z",
        updated_at: "2024-10-29T10:15:11.271Z",
        popular: false,
        slug: "huyen-tran-van-thoi",
        deleted: null,
      },
      {
        id: 969,
        name: "Huyện Cái Nước",
        province_id: 96,
        created_at: "2024-01-28T17:20:52.912Z",
        updated_at: "2024-10-29T10:15:11.282Z",
        popular: false,
        slug: "huyen-cai-nuoc",
        deleted: null,
      },
      {
        id: 970,
        name: "Huyện Đầm Dơi",
        province_id: 96,
        created_at: "2024-01-28T17:20:52.923Z",
        updated_at: "2024-10-29T10:15:11.288Z",
        popular: false,
        slug: "huyen-dam-doi",
        deleted: null,
      },
      {
        id: 971,
        name: "Huyện Năm Căn",
        province_id: 96,
        created_at: "2024-01-28T17:20:52.938Z",
        updated_at: "2024-10-29T10:15:11.294Z",
        popular: false,
        slug: "huyen-nam-can",
        deleted: null,
      },
      {
        id: 972,
        name: "Huyện Phú Tân",
        province_id: 96,
        created_at: "2024-01-28T17:20:52.951Z",
        updated_at: "2024-10-29T10:15:11.299Z",
        popular: false,
        slug: "huyen-phu-tan",
        deleted: null,
      },
      {
        id: 973,
        name: "Huyện Ngọc Hiển",
        province_id: 96,
        created_at: "2024-01-28T17:20:52.964Z",
        updated_at: "2024-10-29T10:15:11.305Z",
        popular: false,
        slug: "huyen-ngoc-hien",
        deleted: null,
      },
    ],
  },
];
export const SERVICE = [
  {
    id: 147483649,
    name: "Niềng răng",
    created_at: "2024-01-28T17:20:53.320Z",
    updated_at: "2024-10-29T10:15:06.716Z",
    sort: 1,
    deleted: null,
    slug: "nieng-rang",
  },
  {
    id: 147483650,
    name: "Trồng răng Implant",
    created_at: "2024-01-28T17:20:53.332Z",
    updated_at: "2024-10-29T10:15:06.786Z",
    sort: 2,
    deleted: null,
    slug: "trong-rang-implant",
  },
  {
    id: 147483647,
    name: "Bọc răng sứ",
    created_at: "2024-01-28T17:20:53.296Z",
    updated_at: "2024-10-29T10:15:06.721Z",
    sort: 3,
    deleted: null,
    slug: "boc-rang-su",
  },
  {
    id: 3,
    name: "Nhổ răng",
    created_at: "2024-01-31T02:34:44.213Z",
    updated_at: "2024-10-29T10:15:06.795Z",
    sort: 4,
    deleted: null,
    slug: "nho-rang",
  },
  {
    id: 147483653,
    name: "Tẩy trắng răng",
    created_at: "2024-01-28T17:20:53.369Z",
    updated_at: "2024-10-29T10:15:06.790Z",
    sort: 5,
    deleted: null,
    slug: "tay-trang-rang",
  },
  {
    id: 147483652,
    name: "Trám răng",
    created_at: "2024-01-28T17:20:53.358Z",
    updated_at: "2024-10-29T10:15:06.725Z",
    sort: 6,
    deleted: null,
    slug: "tram-rang",
  },
  {
    id: 147483648,
    name: "Dán răng sứ",
    created_at: "2024-01-28T17:20:53.308Z",
    updated_at: "2024-10-29T10:15:06.730Z",
    sort: 7,
    deleted: null,
    slug: "dan-rang-su",
  },
  {
    id: 21,
    name: "Lấy cao răng",
    created_at: "2024-02-01T06:31:44.127Z",
    updated_at: "2024-10-29T10:15:06.804Z",
    sort: 8,
    deleted: null,
    slug: "lay-cao-rang",
  },
  {
    id: 12,
    name: "Điều trị cười hở lợi",
    created_at: "2024-01-31T08:16:43.977Z",
    updated_at: "2024-10-29T10:15:06.799Z",
    sort: 9,
    deleted: null,
    slug: "dieu-tri-cuoi-ho-loi",
  },
  {
    id: 15,
    name: "Chụp X-Quang răng",
    created_at: "2024-01-31T13:31:38.718Z",
    updated_at: "2024-10-29T10:15:06.773Z",
    sort: null,
    deleted: null,
    slug: "chup-x-quang-rang",
  },
  {
    id: 24,
    name: "Điều trị rối loạn khớp thái dương hàm",
    created_at: "2024-03-18T09:08:13.124Z",
    updated_at: "2024-10-29T10:15:06.777Z",
    sort: null,
    deleted: null,
    slug: "dieu-tri-roi-loan-khop-thai-duong-ham",
  },
  {
    id: 25,
    name: "Phẫu thuật hàm mặt",
    created_at: "2024-03-21T02:22:26.843Z",
    updated_at: "2024-10-29T10:15:06.781Z",
    sort: null,
    deleted: null,
    slug: "phau-thuat-ham-mat",
  },
  {
    id: 17,
    name: "Đính đá",
    created_at: "2024-01-31T13:32:08.023Z",
    updated_at: "2024-10-29T10:15:06.749Z",
    sort: null,
    deleted: null,
    slug: "dinh-da",
  },
  {
    id: 147483655,
    name: "Điều trị nha chu",
    created_at: "2024-01-28T17:20:53.395Z",
    updated_at: "2024-10-29T10:15:06.736Z",
    sort: null,
    deleted: null,
    slug: "dieu-tri-nha-chu",
  },
  {
    id: 11,
    name: "Điều trị tủy",
    created_at: "2024-01-31T08:16:22.894Z",
    updated_at: "2024-10-29T10:15:06.741Z",
    sort: null,
    deleted: null,
    slug: "dieu-tri-tuy",
  },
  {
    id: 4,
    name: "Khám răng miệng",
    created_at: "2024-01-31T08:02:05.216Z",
    updated_at: "2024-10-29T10:15:06.745Z",
    sort: null,
    deleted: null,
    slug: "kham-rang-mieng",
  },
  {
    id: 147483651,
    name: "Nhổ răng khôn",
    created_at: "2024-01-28T17:20:53.345Z",
    updated_at: "2024-10-29T10:15:06.711Z",
    sort: null,
    deleted: null,
    slug: "nho-rang-khon",
  },
  {
    id: 20,
    name: "Phục hình răng cố định",
    created_at: "2024-01-31T14:04:10.668Z",
    updated_at: "2024-10-29T10:15:06.754Z",
    sort: null,
    deleted: null,
    slug: "phuc-hinh-rang-co-dinh",
  },
  {
    id: 22,
    name: "Đánh bóng răng",
    created_at: "2024-02-01T06:31:50.658Z",
    updated_at: "2024-10-29T10:15:06.758Z",
    sort: null,
    deleted: null,
    slug: "danh-bong-rang",
  },
  {
    id: 23,
    name: "Răng giả tháo lắp",
    created_at: "2024-02-01T06:36:26.593Z",
    updated_at: "2024-10-29T10:15:06.763Z",
    sort: null,
    deleted: null,
    slug: "rang-gia-thao-lap",
  },
  {
    id: 16,
    name: "Điều trị viêm nướu",
    created_at: "2024-01-31T13:31:53.931Z",
    updated_at: "2024-10-29T10:15:06.768Z",
    sort: null,
    deleted: null,
    slug: "dieu-tri-viem-nuou",
  },
];
export const BLOG_CATEGORIES = [
  {
    term_id: 14,
    name: "Khuyến mãi",
    slug: "khuyen-mai",
    term_group: 0,
    term_taxonomy_id: 14,
    taxonomy: "category",
    description:
      "Cập nhật các tin tức khuyến mãi, ưu đãi của các nha khoa tại Việt Nam, giúp bạn tiếp cận được những gói giảm giá tốt nhất!",
    parent: 0,
    count: 8,
    filter: "raw",
  },
  {
    term_id: 2,
    name: "Kiến thức nha khoa",
    slug: "kien-thuc-nha-khoa",
    term_group: 0,
    term_taxonomy_id: 2,
    taxonomy: "category",
    description: "",
    parent: 0,
    count: 273,
    filter: "raw",
  },
  {
    term_id: 4,
    name: "Kinh doanh nha khoa",
    slug: "kinh-doanh-nha-khoa",
    term_group: 0,
    term_taxonomy_id: 4,
    taxonomy: "category",
    description: "",
    parent: 0,
    count: 7,
    filter: "raw",
  },
  {
    term_id: 3,
    name: "Review nha khoa",
    slug: "review-nha-khoa",
    term_group: 0,
    term_taxonomy_id: 3,
    taxonomy: "category",
    description: "",
    parent: 0,
    count: 128,
    filter: "raw",
  },
];
export const FIND_CLINIC_BY = [
  {
    id: 1,
    name: "Tìm theo khu vực",
    slug: "tim-theo-tinh",
  },
  {
    id: 2,
    name: "Tìm theo dịch vụ",
    slug: "tim-theo-dich-vu",
  },
];
export const SERVICE_FOR_SELECT = [
  {
    label: "Niềng răng",
    value: 147483649,
  },
  {
    label: "Trồng răng Implant",
    value: 147483650,
  },
  {
    label: "Bọc răng sứ",
    value: 147483647,
  },
  {
    label: "Nhổ răng",
    value: 3,
  },
  {
    label: "Tẩy trắng răng",
    value: 147483653,
  },
  {
    label: "Trám răng",
    value: 147483652,
  },
  {
    label: "Dán răng sứ",
    value: 147483648,
  },
  {
    label: "Lấy cao răng",
    value: 21,
  },
  {
    label: "Điều trị cười hở lợi",
    value: 12,
  },
  {
    label: "Chụp X-Quang răng",
    value: 15,
  },
  {
    label: "Điều trị rối loạn khớp thái dương hàm",
    value: 24,
  },
  {
    label: "Phẫu thuật hàm mặt",
    value: 25,
  },
  {
    label: "Đính đá",
    value: 17,
  },
  {
    label: "Điều trị nha chu",
    value: 147483655,
  },
  {
    label: "Điều trị tủy",
    value: 11,
  },
  {
    label: "Khám răng miệng",
    value: 4,
  },
  {
    label: "Nhổ răng khôn",
    value: 147483651,
  },
  {
    label: "Phục hình răng cố định",
    value: 20,
  },
  {
    label: "Đánh bóng răng",
    value: 22,
  },
  {
    label: "Răng giả tháo lắp",
    value: 23,
  },
  {
    label: "Điều trị viêm nướu",
    value: 16,
  },
];
export const PROVINCE_FOR_SELECT = [
  {
    label: "Hà Nội",
    value: 1,
    slug: "ha-noi",
    status: "filled",
    district: [
      {
        label: "Ba Đình",
        value: 1,
        slug: "ba-dinh",
        status: "filled",
        ward: [
          { label: "Phúc Xá", value: 1, slug: "phuc-xa", status: "empty" },
          { label: "Trúc Bạch", value: 4, slug: "truc-bach", status: "empty" },
          { label: "Vĩnh Phúc", value: 6, slug: "vinh-phuc", status: "empty" },
          { label: "Cống Vị", value: 7, slug: "cong-vi", status: "empty" },
          { label: "Liễu Giai", value: 8, slug: "lieu-giai", status: "empty" },
          {
            label: "Quán Thánh",
            value: 13,
            slug: "quan-thanh",
            status: "empty",
          },
          { label: "Ngọc Hà", value: 16, slug: "ngoc-ha", status: "empty" },
          { label: "Điện Biên", value: 19, slug: "dien-bien", status: "empty" },
          { label: "Đội Cấn", value: 22, slug: "doi-can", status: "empty" },
          {
            label: "Ngọc Khánh",
            value: 25,
            slug: "ngoc-khanh",
            status: "empty",
          },
          { label: "Kim Mã", value: 28, slug: "kim-ma", status: "empty" },
          { label: "Giảng Võ", value: 31, slug: "giang-vo", status: "filled" },
          {
            label: "Thành Công",
            value: 34,
            slug: "thanh-cong",
            status: "empty",
          },
        ],
      },
      {
        label: "Hoàn Kiếm",
        value: 2,
        slug: "hoan-kiem",
        status: "filled",
        ward: [
          { label: "Phúc Tân", value: 37, slug: "phuc-tan", status: "empty" },
          { label: "Đồng Xuân", value: 40, slug: "dong-xuan", status: "empty" },
          { label: "Hàng Mã", value: 43, slug: "hang-ma", status: "empty" },
          { label: "Hàng Buồm", value: 46, slug: "hang-buom", status: "empty" },
          { label: "Hàng Đào", value: 49, slug: "hang-dao", status: "empty" },
          { label: "Hàng Bồ", value: 52, slug: "hang-bo", status: "empty" },
          { label: "Cửa Đông", value: 55, slug: "cua-dong", status: "empty" },
          {
            label: "Lý Thái Tổ",
            value: 58,
            slug: "ly-thai-to",
            status: "empty",
          },
          { label: "Hàng Bạc", value: 61, slug: "hang-bac", status: "empty" },
          { label: "Hàng Gai", value: 64, slug: "hang-gai", status: "empty" },
          {
            label: "Chương Dương",
            value: 67,
            slug: "chuong-duong",
            status: "empty",
          },
          {
            label: "Hàng Trống",
            value: 70,
            slug: "hang-trong",
            status: "empty",
          },
          { label: "Cửa Nam", value: 73, slug: "cua-nam", status: "filled" },
          { label: "Hàng Bông", value: 76, slug: "hang-bong", status: "empty" },
          {
            label: "Tràng Tiền",
            value: 79,
            slug: "trang-tien",
            status: "empty",
          },
          {
            label: "Trần Hưng Đạo",
            value: 82,
            slug: "tran-hung-dao",
            status: "filled",
          },
          {
            label: "Phan Chu Trinh",
            value: 85,
            slug: "phan-chu-trinh",
            status: "empty",
          },
          { label: "Hàng Bài", value: 88, slug: "hang-bai", status: "empty" },
        ],
      },
      {
        label: "Tây Hồ",
        value: 3,
        slug: "tay-ho",
        status: "filled",
        ward: [
          {
            label: "Phú Thượng",
            value: 91,
            slug: "phu-thuong",
            status: "empty",
          },
          { label: "Nhật Tân", value: 94, slug: "nhat-tan", status: "empty" },
          { label: "Tứ Liên", value: 97, slug: "tu-lien", status: "empty" },
          { label: "Quảng An", value: 100, slug: "quang-an", status: "empty" },
          { label: "Xuân La", value: 103, slug: "xuan-la", status: "empty" },
          { label: "Yên Phụ", value: 106, slug: "yen-phu", status: "empty" },
          {
            label: "Phường Bưởi",
            value: 109,
            slug: "phuong-buoi",
            status: "filled",
          },
          {
            label: "Thụy Khuê",
            value: 112,
            slug: "thuy-khue",
            status: "empty",
          },
        ],
      },
      {
        label: "Long Biên",
        value: 4,
        slug: "long-bien",
        status: "filled",
        ward: [
          {
            label: "Thượng Thanh",
            value: 115,
            slug: "thuong-thanh",
            status: "empty",
          },
          {
            label: "Ngọc Thụy",
            value: 118,
            slug: "ngoc-thuy",
            status: "empty",
          },
          {
            label: "Giang Biên",
            value: 121,
            slug: "giang-bien",
            status: "empty",
          },
          {
            label: "Đức Giang",
            value: 124,
            slug: "duc-giang",
            status: "filled",
          },
          {
            label: "Việt Hưng",
            value: 127,
            slug: "viet-hung",
            status: "empty",
          },
          { label: "Gia Thụy", value: 130, slug: "gia-thuy", status: "empty" },
          { label: "Ngọc Lâm", value: 133, slug: "ngoc-lam", status: "empty" },
          { label: "Phúc Lợi", value: 136, slug: "phuc-loi", status: "filled" },
          { label: "Bồ Đề", value: 139, slug: "bo-de", status: "empty" },
          {
            label: "Long Biên",
            value: 145,
            slug: "long-bien",
            status: "empty",
          },
          {
            label: "Thạch Bàn",
            value: 148,
            slug: "thach-ban",
            status: "empty",
          },
          {
            label: "Phúc Đồng",
            value: 151,
            slug: "phuc-dong",
            status: "empty",
          },
          { label: "Sài Đồng", value: 152, slug: "sai-dong", status: "empty" },
          { label: "Cự Khối", value: 154, slug: "cu-khoi", status: "empty" },
        ],
      },
      {
        label: "Cầu Giấy",
        value: 5,
        slug: "cau-giay",
        status: "filled",
        ward: [
          { label: "Nghĩa Đô", value: 157, slug: "nghia-do", status: "filled" },
          {
            label: "Nghĩa Tân",
            value: 160,
            slug: "nghia-tan",
            status: "empty",
          },
          { label: "Mai Dịch", value: 163, slug: "mai-dich", status: "filled" },
          {
            label: "Dịch Vọng",
            value: 166,
            slug: "dich-vong",
            status: "filled",
          },
          {
            label: "Dịch Vọng Hậu",
            value: 167,
            slug: "dich-vong-hau",
            status: "filled",
          },
          { label: "Quan Hoa", value: 169, slug: "quan-hoa", status: "empty" },
          { label: "Yên Hoà", value: 172, slug: "yen-hoa", status: "filled" },
          {
            label: "Trung Hoà",
            value: 175,
            slug: "trung-hoa",
            status: "filled",
          },
        ],
      },
      {
        label: "Đống Đa",
        value: 6,
        slug: "dong-da",
        status: "filled",
        ward: [
          { label: "Cát Linh", value: 178, slug: "cat-linh", status: "empty" },
          {
            label: "Văn Miếu - Quốc Tử Giám",
            value: 181,
            slug: "van-mieu---quoc-tu-giam",
            status: "empty",
          },
          {
            label: "Láng Thượng",
            value: 187,
            slug: "lang-thuong",
            status: "empty",
          },
          {
            label: "Ô Chợ Dừa",
            value: 190,
            slug: "o-cho-dua",
            status: "empty",
          },
          {
            label: "Văn Chương",
            value: 193,
            slug: "van-chuong",
            status: "empty",
          },
          { label: "Hàng Bột", value: 196, slug: "hang-bot", status: "empty" },
          { label: "Láng Hạ", value: 199, slug: "lang-ha", status: "filled" },
          {
            label: "Khâm Thiên",
            value: 202,
            slug: "kham-thien",
            status: "empty",
          },
          { label: "Thổ Quan", value: 205, slug: "tho-quan", status: "empty" },
          { label: "Nam Đồng", value: 208, slug: "nam-dong", status: "filled" },
          {
            label: "Quang Trung",
            value: 214,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Trung Liệt",
            value: 217,
            slug: "trung-liet",
            status: "filled",
          },
          {
            label: "Phương Liên - Trung Tự",
            value: 226,
            slug: "phuong-lien---trung-tu",
            status: "empty",
          },
          { label: "Kim Liên", value: 229, slug: "kim-lien", status: "empty" },
          {
            label: "Phương Mai",
            value: 232,
            slug: "phuong-mai",
            status: "empty",
          },
          {
            label: "Thịnh Quang",
            value: 235,
            slug: "thinh-quang",
            status: "empty",
          },
          {
            label: "Ngã Tư Sở",
            value: 236,
            slug: "nga-tu-so",
            status: "filled",
          },
          {
            label: "Khương Thượng",
            value: 238,
            slug: "khuong-thuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Hai Bà Trưng",
        value: 7,
        slug: "hai-ba-trung",
        status: "filled",
        ward: [
          {
            label: "Nguyễn Du",
            value: 241,
            slug: "nguyen-du",
            status: "filled",
          },
          {
            label: "Bạch Đằng",
            value: 244,
            slug: "bach-dang",
            status: "empty",
          },
          {
            label: "Phạm Đình Hổ",
            value: 247,
            slug: "pham-dinh-ho",
            status: "empty",
          },
          {
            label: "Lê Đại Hành",
            value: 256,
            slug: "le-dai-hanh",
            status: "filled",
          },
          {
            label: "Đồng Nhân",
            value: 259,
            slug: "dong-nhan",
            status: "empty",
          },
          { label: "Phố Huế", value: 262, slug: "pho-hue", status: "empty" },
          {
            label: "Thanh Lương",
            value: 268,
            slug: "thanh-luong",
            status: "empty",
          },
          {
            label: "Thanh Nhàn",
            value: 271,
            slug: "thanh-nhan",
            status: "filled",
          },
          {
            label: "Bách Khoa",
            value: 277,
            slug: "bach-khoa",
            status: "empty",
          },
          { label: "Đồng Tâm", value: 280, slug: "dong-tam", status: "empty" },
          { label: "Vĩnh Tuy", value: 283, slug: "vinh-tuy", status: "empty" },
          {
            label: "Quỳnh Mai",
            value: 289,
            slug: "quynh-mai",
            status: "empty",
          },
          { label: "Bạch Mai", value: 292, slug: "bach-mai", status: "empty" },
          {
            label: "Minh Khai",
            value: 295,
            slug: "minh-khai",
            status: "empty",
          },
          {
            label: "Trương Định",
            value: 298,
            slug: "truong-dinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Hoàng Mai",
        value: 8,
        slug: "hoang-mai",
        status: "filled",
        ward: [
          {
            label: "Thanh Trì",
            value: 301,
            slug: "thanh-tri",
            status: "empty",
          },
          {
            label: "Vĩnh Hưng",
            value: 304,
            slug: "vinh-hung",
            status: "empty",
          },
          {
            label: "Định Công",
            value: 307,
            slug: "dinh-cong",
            status: "empty",
          },
          { label: "Mai Động", value: 310, slug: "mai-dong", status: "empty" },
          {
            label: "Tương Mai",
            value: 313,
            slug: "tuong-mai",
            status: "filled",
          },
          { label: "Đại Kim", value: 316, slug: "dai-kim", status: "empty" },
          { label: "Tân Mai", value: 319, slug: "tan-mai", status: "empty" },
          {
            label: "Hoàng Văn Thụ",
            value: 322,
            slug: "hoang-van-thu",
            status: "empty",
          },
          { label: "Giáp Bát", value: 325, slug: "giap-bat", status: "empty" },
          { label: "Lĩnh Nam", value: 328, slug: "linh-nam", status: "empty" },
          {
            label: "Thịnh Liệt",
            value: 331,
            slug: "thinh-liet",
            status: "filled",
          },
          { label: "Trần Phú", value: 334, slug: "tran-phu", status: "empty" },
          {
            label: "Hoàng Liệt",
            value: 337,
            slug: "hoang-liet",
            status: "filled",
          },
          { label: "Yên Sở", value: 340, slug: "yen-so", status: "empty" },
        ],
      },
      {
        label: "Thanh Xuân",
        value: 9,
        slug: "thanh-xuan",
        status: "filled",
        ward: [
          {
            label: "Nhân Chính",
            value: 343,
            slug: "nhan-chinh",
            status: "empty",
          },
          {
            label: "Thượng Đình",
            value: 346,
            slug: "thuong-dinh",
            status: "empty",
          },
          {
            label: "Khương Trung",
            value: 349,
            slug: "khuong-trung",
            status: "filled",
          },
          {
            label: "Khương Mai",
            value: 352,
            slug: "khuong-mai",
            status: "empty",
          },
          {
            label: "Thanh Xuân Trung",
            value: 355,
            slug: "thanh-xuan-trung",
            status: "filled",
          },
          {
            label: "Phương Liệt",
            value: 358,
            slug: "phuong-liet",
            status: "empty",
          },
          {
            label: "Khương Đình",
            value: 364,
            slug: "khuong-dinh",
            status: "filled",
          },
          {
            label: "Thanh Xuân Bắc",
            value: 367,
            slug: "thanh-xuan-bac",
            status: "empty",
          },
          { label: "Hạ Đình", value: 373, slug: "ha-dinh", status: "filled" },
        ],
      },
      {
        label: "Sóc Sơn",
        value: 16,
        slug: "soc-son",
        status: "filled",
        ward: [
          { label: "Sóc Sơn", value: 376, slug: "soc-son", status: "filled" },
          { label: "Bắc Sơn", value: 379, slug: "bac-son", status: "empty" },
          { label: "Minh Trí", value: 382, slug: "minh-tri", status: "empty" },
          { label: "Hồng Kỳ", value: 385, slug: "hong-ky", status: "empty" },
          { label: "Nam Sơn", value: 388, slug: "nam-son", status: "empty" },
          {
            label: "Trung Giã",
            value: 391,
            slug: "trung-gia",
            status: "filled",
          },
          { label: "Tân Hưng", value: 394, slug: "tan-hung", status: "empty" },
          { label: "Minh Phú", value: 397, slug: "minh-phu", status: "empty" },
          { label: "Phù Linh", value: 400, slug: "phu-linh", status: "empty" },
          { label: "Bắc Phú", value: 403, slug: "bac-phu", status: "empty" },
          { label: "Tân Minh", value: 406, slug: "tan-minh", status: "empty" },
          {
            label: "Quang Tiến",
            value: 409,
            slug: "quang-tien",
            status: "empty",
          },
          {
            label: "Hiền Ninh",
            value: 412,
            slug: "hien-ninh",
            status: "empty",
          },
          { label: "Tân Dân", value: 415, slug: "tan-dan", status: "empty" },
          {
            label: "Tiên Dược",
            value: 418,
            slug: "tien-duoc",
            status: "empty",
          },
          {
            label: "Việt Long",
            value: 421,
            slug: "viet-long",
            status: "empty",
          },
          {
            label: "Xuân Giang",
            value: 424,
            slug: "xuan-giang",
            status: "empty",
          },
          { label: "Mai Đình", value: 427, slug: "mai-dinh", status: "empty" },
          { label: "Đức Hoà", value: 430, slug: "duc-hoa", status: "empty" },
          {
            label: "Thanh Xuân",
            value: 433,
            slug: "thanh-xuan",
            status: "empty",
          },
          {
            label: "Đông Xuân",
            value: 436,
            slug: "dong-xuan",
            status: "empty",
          },
          { label: "Kim Lũ", value: 439, slug: "kim-lu", status: "empty" },
          {
            label: "Phú Cường",
            value: 442,
            slug: "phu-cuong",
            status: "empty",
          },
          { label: "Phú Minh", value: 445, slug: "phu-minh", status: "empty" },
          { label: "Phù Lỗ", value: 448, slug: "phu-lo", status: "filled" },
          { label: "Xuân Thu", value: 451, slug: "xuan-thu", status: "empty" },
        ],
      },
      {
        label: "Đông Anh",
        value: 17,
        slug: "dong-anh",
        status: "filled",
        ward: [
          { label: "Đông Anh", value: 454, slug: "dong-anh", status: "empty" },
          { label: "Xuân Nộn", value: 457, slug: "xuan-non", status: "empty" },
          { label: "Thuỵ Lâm", value: 460, slug: "thuy-lam", status: "empty" },
          { label: "Bắc Hồng", value: 463, slug: "bac-hong", status: "empty" },
          {
            label: "Nguyên Khê",
            value: 466,
            slug: "nguyen-khe",
            status: "filled",
          },
          { label: "Nam Hồng", value: 469, slug: "nam-hong", status: "empty" },
          {
            label: "Tiên Dương",
            value: 472,
            slug: "tien-duong",
            status: "empty",
          },
          { label: "Vân Hà", value: 475, slug: "van-ha", status: "empty" },
          { label: "Uy Nỗ", value: 478, slug: "uy-no", status: "filled" },
          { label: "Vân Nội", value: 481, slug: "van-noi", status: "empty" },
          { label: "Liên Hà", value: 484, slug: "lien-ha", status: "empty" },
          {
            label: "Việt Hùng",
            value: 487,
            slug: "viet-hung",
            status: "empty",
          },
          { label: "Kim Nỗ", value: 490, slug: "kim-no", status: "empty" },
          {
            label: "Kim Chung",
            value: 493,
            slug: "kim-chung",
            status: "empty",
          },
          { label: "Dục Tú", value: 496, slug: "duc-tu", status: "empty" },
          { label: "Đại Mạch", value: 499, slug: "dai-mach", status: "empty" },
          {
            label: "Vĩnh Ngọc",
            value: 502,
            slug: "vinh-ngoc",
            status: "empty",
          },
          { label: "Cổ Loa", value: 505, slug: "co-loa", status: "empty" },
          { label: "Hải Bối", value: 508, slug: "hai-boi", status: "empty" },
          {
            label: "Xuân Canh",
            value: 511,
            slug: "xuan-canh",
            status: "empty",
          },
          { label: "Võng La", value: 514, slug: "vong-la", status: "empty" },
          { label: "Tàm Xá", value: 517, slug: "tam-xa", status: "empty" },
          { label: "Mai Lâm", value: 520, slug: "mai-lam", status: "empty" },
          { label: "Đông Hội", value: 523, slug: "dong-hoi", status: "empty" },
        ],
      },
      {
        label: "Gia Lâm",
        value: 18,
        slug: "gia-lam",
        status: "filled",
        ward: [
          { label: "Yên Viên", value: 526, slug: "yen-vien", status: "empty" },
          {
            label: "Yên Thường",
            value: 529,
            slug: "yen-thuong",
            status: "empty",
          },
          { label: "Yên Viên", value: 532, slug: "yen-vien", status: "empty" },
          {
            label: "Ninh Hiệp",
            value: 535,
            slug: "ninh-hiep",
            status: "filled",
          },
          {
            label: "Thiên Đức",
            value: 541,
            slug: "thien-duc",
            status: "empty",
          },
          { label: "Phù Đổng", value: 544, slug: "phu-dong", status: "empty" },
          { label: "Lệ Chi", value: 550, slug: "le-chi", status: "empty" },
          { label: "Cổ Bi", value: 553, slug: "co-bi", status: "empty" },
          { label: "Đặng Xá", value: 556, slug: "dang-xa", status: "empty" },
          { label: "Phú Sơn", value: 562, slug: "phu-son", status: "empty" },
          { label: "Trâu Quỳ", value: 565, slug: "trau-quy", status: "filled" },
          {
            label: "Dương Quang",
            value: 568,
            slug: "duong-quang",
            status: "empty",
          },
          { label: "Dương Xá", value: 571, slug: "duong-xa", status: "empty" },
          { label: "Đa Tốn", value: 577, slug: "da-ton", status: "empty" },
          { label: "Kiêu Kỵ", value: 580, slug: "kieu-ky", status: "empty" },
          {
            label: "Bát Tràng",
            value: 583,
            slug: "bat-trang",
            status: "empty",
          },
          { label: "Kim Đức", value: 589, slug: "kim-duc", status: "empty" },
        ],
      },
      {
        label: "Nam Từ Liêm",
        value: 19,
        slug: "nam-tu-liem",
        status: "filled",
        ward: [
          { label: "Cầu Diễn", value: 592, slug: "cau-dien", status: "filled" },
          {
            label: "Xuân Phương",
            value: 622,
            slug: "xuan-phuong",
            status: "empty",
          },
          {
            label: "Phương Canh",
            value: 623,
            slug: "phuong-canh",
            status: "empty",
          },
          {
            label: "Mỹ Đình 1",
            value: 625,
            slug: "my-dinh-1",
            status: "filled",
          },
          {
            label: "Mỹ Đình 2",
            value: 626,
            slug: "my-dinh-2",
            status: "filled",
          },
          { label: "Tây Mỗ", value: 628, slug: "tay-mo", status: "filled" },
          { label: "Mễ Trì", value: 631, slug: "me-tri", status: "empty" },
          { label: "Phú Đô", value: 632, slug: "phu-do", status: "empty" },
          { label: "Đại Mỗ", value: 634, slug: "dai-mo", status: "empty" },
          {
            label: "Trung Văn",
            value: 637,
            slug: "trung-van",
            status: "filled",
          },
        ],
      },
      {
        label: "Thanh Trì",
        value: 20,
        slug: "thanh-tri",
        status: "filled",
        ward: [
          { label: "Văn Điển", value: 640, slug: "van-dien", status: "filled" },
          {
            label: "Tân Triều",
            value: 643,
            slug: "tan-trieu",
            status: "empty",
          },
          {
            label: "Thanh Liệt",
            value: 646,
            slug: "thanh-liet",
            status: "empty",
          },
          {
            label: "Tả Thanh Oai",
            value: 649,
            slug: "ta-thanh-oai",
            status: "empty",
          },
          { label: "Hữu Hoà", value: 652, slug: "huu-hoa", status: "empty" },
          { label: "Tam Hiệp", value: 655, slug: "tam-hiep", status: "empty" },
          { label: "Tứ Hiệp", value: 658, slug: "tu-hiep", status: "filled" },
          { label: "Yên Mỹ", value: 661, slug: "yen-my", status: "empty" },
          {
            label: "Vĩnh Quỳnh",
            value: 664,
            slug: "vinh-quynh",
            status: "empty",
          },
          { label: "Ngũ Hiệp", value: 667, slug: "ngu-hiep", status: "empty" },
          { label: "Duyên Hà", value: 670, slug: "duyen-ha", status: "empty" },
          { label: "Ngọc Hồi", value: 673, slug: "ngoc-hoi", status: "empty" },
          { label: "Vạn Phúc", value: 676, slug: "van-phuc", status: "empty" },
          { label: "Đại áng", value: 679, slug: "dai-ang", status: "empty" },
          {
            label: "Liên Ninh",
            value: 682,
            slug: "lien-ninh",
            status: "empty",
          },
          { label: "Đông Mỹ", value: 685, slug: "dong-my", status: "empty" },
        ],
      },
      {
        label: "Bắc Từ Liêm",
        value: 21,
        slug: "bac-tu-liem",
        status: "empty",
        ward: [
          {
            label: "Thượng Cát",
            value: 595,
            slug: "thuong-cat",
            status: "empty",
          },
          { label: "Liên Mạc", value: 598, slug: "lien-mac", status: "empty" },
          {
            label: "Đông Ngạc",
            value: 601,
            slug: "dong-ngac",
            status: "empty",
          },
          {
            label: "Đức Thắng",
            value: 602,
            slug: "duc-thang",
            status: "empty",
          },
          {
            label: "Thụy Phương",
            value: 604,
            slug: "thuy-phuong",
            status: "empty",
          },
          { label: "Tây Tựu", value: 607, slug: "tay-tuu", status: "empty" },
          {
            label: "Xuân Đỉnh",
            value: 610,
            slug: "xuan-dinh",
            status: "empty",
          },
          { label: "Xuân Tảo", value: 611, slug: "xuan-tao", status: "empty" },
          {
            label: "Minh Khai",
            value: 613,
            slug: "minh-khai",
            status: "empty",
          },
          {
            label: "Cổ Nhuế 1",
            value: 616,
            slug: "co-nhue-1",
            status: "empty",
          },
          {
            label: "Cổ Nhuế 2",
            value: 617,
            slug: "co-nhue-2",
            status: "empty",
          },
          { label: "Phú Diễn", value: 619, slug: "phu-dien", status: "empty" },
          {
            label: "Phúc Diễn",
            value: 620,
            slug: "phuc-dien",
            status: "empty",
          },
        ],
      },
      {
        label: "Mê Linh",
        value: 250,
        slug: "me-linh",
        status: "filled",
        ward: [
          { label: "Chi Đông", value: 8973, slug: "chi-dong", status: "empty" },
          {
            label: "Đại Thịnh",
            value: 8974,
            slug: "dai-thinh",
            status: "empty",
          },
          { label: "Kim Hoa", value: 8977, slug: "kim-hoa", status: "empty" },
          { label: "Thạch Đà", value: 8980, slug: "thach-da", status: "empty" },
          {
            label: "Tiến Thắng",
            value: 8983,
            slug: "tien-thang",
            status: "empty",
          },
          { label: "Tự Lập", value: 8986, slug: "tu-lap", status: "empty" },
          {
            label: "Quang Minh",
            value: 8989,
            slug: "quang-minh",
            status: "empty",
          },
          {
            label: "Thanh Lâm",
            value: 8992,
            slug: "thanh-lam",
            status: "empty",
          },
          { label: "Tam Đồng", value: 8995, slug: "tam-dong", status: "empty" },
          { label: "Liên Mạc", value: 8998, slug: "lien-mac", status: "empty" },
          { label: "Chu Phan", value: 9004, slug: "chu-phan", status: "empty" },
          {
            label: "Tiến Thịnh",
            value: 9007,
            slug: "tien-thinh",
            status: "empty",
          },
          { label: "Mê Linh", value: 9010, slug: "me-linh", status: "empty" },
          { label: "Văn Khê", value: 9013, slug: "van-khe", status: "empty" },
          {
            label: "Hoàng Kim",
            value: 9016,
            slug: "hoang-kim",
            status: "empty",
          },
          {
            label: "Tiền Phong",
            value: 9019,
            slug: "tien-phong",
            status: "filled",
          },
          {
            label: "Tráng Việt",
            value: 9022,
            slug: "trang-viet",
            status: "empty",
          },
        ],
      },
      {
        label: "Hà Đông",
        value: 268,
        slug: "ha-dong",
        status: "filled",
        ward: [
          {
            label: "Quang Trung",
            value: 9538,
            slug: "quang-trung",
            status: "empty",
          },
          { label: "Mộ Lao", value: 9541, slug: "mo-lao", status: "filled" },
          {
            label: "Văn Quán",
            value: 9542,
            slug: "van-quan",
            status: "filled",
          },
          {
            label: "Vạn Phúc",
            value: 9544,
            slug: "van-phuc",
            status: "filled",
          },
          { label: "La Khê", value: 9551, slug: "la-khe", status: "empty" },
          { label: "Phú La", value: 9552, slug: "phu-la", status: "filled" },
          { label: "Phúc La", value: 9553, slug: "phuc-la", status: "filled" },
          { label: "Hà Cầu", value: 9556, slug: "ha-cau", status: "empty" },
          {
            label: "Yên Nghĩa",
            value: 9562,
            slug: "yen-nghia",
            status: "empty",
          },
          {
            label: "Kiến Hưng",
            value: 9565,
            slug: "kien-hung",
            status: "empty",
          },
          { label: "Phú Lãm", value: 9568, slug: "phu-lam", status: "empty" },
          {
            label: "Phú Lương",
            value: 9571,
            slug: "phu-luong",
            status: "empty",
          },
          {
            label: "Dương Nội",
            value: 9886,
            slug: "duong-noi",
            status: "empty",
          },
          {
            label: "Đồng Mai",
            value: 10117,
            slug: "dong-mai",
            status: "empty",
          },
          {
            label: "Biên Giang",
            value: 10123,
            slug: "bien-giang",
            status: "empty",
          },
        ],
      },
      {
        label: "Sơn Tây",
        value: 269,
        slug: "son-tay",
        status: "filled",
        ward: [
          {
            label: "Ngô Quyền",
            value: 9574,
            slug: "ngo-quyen",
            status: "empty",
          },
          {
            label: "Phú Thịnh",
            value: 9577,
            slug: "phu-thinh",
            status: "filled",
          },
          { label: "Sơn Lộc", value: 9586, slug: "son-loc", status: "empty" },
          {
            label: "Xuân Khanh",
            value: 9589,
            slug: "xuan-khanh",
            status: "empty",
          },
          {
            label: "Đường Lâm",
            value: 9592,
            slug: "duong-lam",
            status: "empty",
          },
          { label: "Viên Sơn", value: 9595, slug: "vien-son", status: "empty" },
          { label: "Xuân Sơn", value: 9598, slug: "xuan-son", status: "empty" },
          {
            label: "Trung Hưng",
            value: 9601,
            slug: "trung-hung",
            status: "empty",
          },
          { label: "Thanh Mỹ", value: 9604, slug: "thanh-my", status: "empty" },
          {
            label: "Trung Sơn Trầm",
            value: 9607,
            slug: "trung-son-tram",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 9608,
            slug: "quang-trung",
            status: "empty",
          },
          { label: "Kim Sơn", value: 9610, slug: "kim-son", status: "empty" },
          { label: "Sơn Đông", value: 9613, slug: "son-dong", status: "empty" },
          { label: "Cổ Đông", value: 9616, slug: "co-dong", status: "empty" },
        ],
      },
      {
        label: "Ba Vì",
        value: 271,
        slug: "ba-vi",
        status: "empty",
        ward: [
          { label: "Tây Đằng", value: 9619, slug: "tay-dang", status: "empty" },
          {
            label: "Phú Cường",
            value: 9625,
            slug: "phu-cuong",
            status: "empty",
          },
          { label: "Cổ Đô", value: 9628, slug: "co-do", status: "empty" },
          {
            label: "Vạn Thắng",
            value: 9634,
            slug: "van-thang",
            status: "empty",
          },
          {
            label: "Phong Vân",
            value: 9640,
            slug: "phong-van",
            status: "empty",
          },
          { label: "Phú Đông", value: 9643, slug: "phu-dong", status: "empty" },
          { label: "Phú Hồng", value: 9646, slug: "phu-hong", status: "empty" },
          { label: "Phú Châu", value: 9649, slug: "phu-chau", status: "empty" },
          { label: "Thái Hòa", value: 9652, slug: "thai-hoa", status: "empty" },
          {
            label: "Đồng Thái",
            value: 9655,
            slug: "dong-thai",
            status: "empty",
          },
          { label: "Phú Sơn", value: 9658, slug: "phu-son", status: "empty" },
          {
            label: "Minh Châu",
            value: 9661,
            slug: "minh-chau",
            status: "empty",
          },
          { label: "Vật Lại", value: 9664, slug: "vat-lai", status: "empty" },
          { label: "Chu Minh", value: 9667, slug: "chu-minh", status: "empty" },
          { label: "Tòng Bạt", value: 9670, slug: "tong-bat", status: "empty" },
          { label: "Cẩm Lĩnh", value: 9673, slug: "cam-linh", status: "empty" },
          { label: "Sơn Đà", value: 9676, slug: "son-da", status: "empty" },
          {
            label: "Đông Quang",
            value: 9679,
            slug: "dong-quang",
            status: "empty",
          },
          {
            label: "Tiên Phong",
            value: 9682,
            slug: "tien-phong",
            status: "empty",
          },
          { label: "Thụy An", value: 9685, slug: "thuy-an", status: "empty" },
          {
            label: "Cam Thượng",
            value: 9688,
            slug: "cam-thuong",
            status: "empty",
          },
          { label: "Thuần Mỹ", value: 9691, slug: "thuan-my", status: "empty" },
          { label: "Tản Lĩnh", value: 9694, slug: "tan-linh", status: "empty" },
          { label: "Ba Trại", value: 9697, slug: "ba-trai", status: "empty" },
          {
            label: "Minh Quang",
            value: 9700,
            slug: "minh-quang",
            status: "empty",
          },
          { label: "Ba Vì", value: 9703, slug: "ba-vi", status: "empty" },
          { label: "Vân Hòa", value: 9706, slug: "van-hoa", status: "empty" },
          { label: "Yên Bài", value: 9709, slug: "yen-bai", status: "empty" },
          {
            label: "Khánh Thượng",
            value: 9712,
            slug: "khanh-thuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Phúc Thọ",
        value: 272,
        slug: "phuc-tho",
        status: "empty",
        ward: [
          { label: "Phúc Thọ", value: 9715, slug: "phuc-tho", status: "empty" },
          { label: "Vân Phúc", value: 9721, slug: "van-phuc", status: "empty" },
          { label: "Nam Hà", value: 9724, slug: "nam-ha", status: "empty" },
          {
            label: "Xuân Đình",
            value: 9727,
            slug: "xuan-dinh",
            status: "empty",
          },
          {
            label: "Sen Phương",
            value: 9733,
            slug: "sen-phuong",
            status: "empty",
          },
          {
            label: "Võng Xuyên",
            value: 9739,
            slug: "vong-xuyen",
            status: "empty",
          },
          { label: "Tích Lộc", value: 9742, slug: "tich-loc", status: "empty" },
          {
            label: "Long Thượng",
            value: 9745,
            slug: "long-thuong",
            status: "empty",
          },
          { label: "Hát Môn", value: 9751, slug: "hat-mon", status: "empty" },
          { label: "Thanh Đa", value: 9757, slug: "thanh-da", status: "empty" },
          {
            label: "Trạch Mỹ Lộc",
            value: 9760,
            slug: "trach-my-loc",
            status: "empty",
          },
          { label: "Phúc Hòa", value: 9763, slug: "phuc-hoa", status: "empty" },
          { label: "Ngọc Tảo", value: 9766, slug: "ngoc-tao", status: "empty" },
          {
            label: "Phụng Thượng",
            value: 9769,
            slug: "phung-thuong",
            status: "empty",
          },
          {
            label: "Tam Thuấn",
            value: 9772,
            slug: "tam-thuan",
            status: "empty",
          },
          { label: "Tam Hiệp", value: 9775, slug: "tam-hiep", status: "empty" },
          {
            label: "Hiệp Thuận",
            value: 9778,
            slug: "hiep-thuan",
            status: "empty",
          },
          {
            label: "Liên Hiệp",
            value: 9781,
            slug: "lien-hiep",
            status: "empty",
          },
        ],
      },
      {
        label: "Đan Phượng",
        value: 273,
        slug: "dan-phuong",
        status: "filled",
        ward: [
          {
            label: "Thị trấn Phùng",
            value: 9784,
            slug: "thi-tran-phung",
            status: "filled",
          },
          {
            label: "Trung Châu",
            value: 9787,
            slug: "trung-chau",
            status: "empty",
          },
          { label: "Thọ An", value: 9790, slug: "tho-an", status: "empty" },
          { label: "Thọ Xuân", value: 9793, slug: "tho-xuan", status: "empty" },
          { label: "Hồng Hà", value: 9796, slug: "hong-ha", status: "empty" },
          {
            label: "Liên Hồng",
            value: 9799,
            slug: "lien-hong",
            status: "empty",
          },
          { label: "Liên Hà", value: 9802, slug: "lien-ha", status: "empty" },
          { label: "Hạ Mỗ", value: 9805, slug: "ha-mo", status: "empty" },
          {
            label: "Liên Trung",
            value: 9808,
            slug: "lien-trung",
            status: "empty",
          },
          {
            label: "Phương Đình",
            value: 9811,
            slug: "phuong-dinh",
            status: "empty",
          },
          {
            label: "Thượng Mỗ",
            value: 9814,
            slug: "thuong-mo",
            status: "empty",
          },
          { label: "Tân Hội", value: 9817, slug: "tan-hoi", status: "empty" },
          { label: "Tân Lập", value: 9820, slug: "tan-lap", status: "filled" },
          {
            label: "Đan Phượng",
            value: 9823,
            slug: "dan-phuong",
            status: "empty",
          },
          {
            label: "Đồng Tháp",
            value: 9826,
            slug: "dong-thap",
            status: "empty",
          },
          {
            label: "Song Phượng",
            value: 9829,
            slug: "song-phuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Hoài Đức",
        value: 274,
        slug: "hoai-duc",
        status: "filled",
        ward: [
          {
            label: "Trạm Trôi",
            value: 9832,
            slug: "tram-troi",
            status: "empty",
          },
          {
            label: "Đức Thượng",
            value: 9835,
            slug: "duc-thuong",
            status: "empty",
          },
          {
            label: "Minh Khai",
            value: 9838,
            slug: "minh-khai",
            status: "empty",
          },
          {
            label: "Dương Liễu",
            value: 9841,
            slug: "duong-lieu",
            status: "empty",
          },
          { label: "Di Trạch", value: 9844, slug: "di-trach", status: "empty" },
          {
            label: "Đức Giang",
            value: 9847,
            slug: "duc-giang",
            status: "empty",
          },
          { label: "Cát Quế", value: 9850, slug: "cat-que", status: "empty" },
          {
            label: "Kim Chung",
            value: 9853,
            slug: "kim-chung",
            status: "empty",
          },
          { label: "Yên Sở", value: 9856, slug: "yen-so", status: "empty" },
          {
            label: "Sơn Đồng",
            value: 9859,
            slug: "son-dong",
            status: "filled",
          },
          { label: "Vân Canh", value: 9862, slug: "van-canh", status: "empty" },
          { label: "Đắc Sở", value: 9865, slug: "dac-so", status: "empty" },
          { label: "Lại Yên", value: 9868, slug: "lai-yen", status: "empty" },
          { label: "Tiền Yên", value: 9871, slug: "tien-yen", status: "empty" },
          {
            label: "Song Phương",
            value: 9874,
            slug: "song-phuong",
            status: "empty",
          },
          {
            label: "An Khánh",
            value: 9877,
            slug: "an-khanh",
            status: "filled",
          },
          {
            label: "An Thượng",
            value: 9880,
            slug: "an-thuong",
            status: "empty",
          },
          { label: "Vân Côn", value: 9883, slug: "van-con", status: "empty" },
          { label: "La Phù", value: 9889, slug: "la-phu", status: "empty" },
          { label: "Đông La", value: 9892, slug: "dong-la", status: "empty" },
        ],
      },
      {
        label: "Quốc Oai",
        value: 275,
        slug: "quoc-oai",
        status: "filled",
        ward: [
          {
            label: "Đông Xuân",
            value: 4939,
            slug: "dong-xuan",
            status: "empty",
          },
          { label: "Quốc Oai", value: 9895, slug: "quoc-oai", status: "empty" },
          { label: "Sài Sơn", value: 9898, slug: "sai-son", status: "empty" },
          {
            label: "Phượng Sơn",
            value: 9904,
            slug: "phuong-son",
            status: "empty",
          },
          {
            label: "Ngọc Liệp",
            value: 9907,
            slug: "ngoc-liep",
            status: "empty",
          },
          { label: "Ngọc Mỹ", value: 9910, slug: "ngoc-my", status: "filled" },
          {
            label: "Thạch Thán",
            value: 9916,
            slug: "thach-than",
            status: "empty",
          },
          {
            label: "Đồng Quang",
            value: 9919,
            slug: "dong-quang",
            status: "empty",
          },
          { label: "Phú Cát", value: 9922, slug: "phu-cat", status: "filled" },
          {
            label: "Tuyết Nghĩa",
            value: 9925,
            slug: "tuyet-nghia",
            status: "empty",
          },
          {
            label: "Liệp Nghĩa",
            value: 9928,
            slug: "liep-nghia",
            status: "empty",
          },
          { label: "Cộng Hòa", value: 9931, slug: "cong-hoa", status: "empty" },
          { label: "Hưng Đạo", value: 9934, slug: "hung-dao", status: "empty" },
          { label: "Phú Mãn", value: 9940, slug: "phu-man", status: "empty" },
          { label: "Cấn Hữu", value: 9943, slug: "can-huu", status: "empty" },
          {
            label: "Hòa Thạch",
            value: 9949,
            slug: "hoa-thach",
            status: "empty",
          },
          { label: "Đông Yên", value: 9952, slug: "dong-yen", status: "empty" },
        ],
      },
      {
        label: "Thạch Thất",
        value: 276,
        slug: "thach-that",
        status: "filled",
        ward: [
          {
            label: "Yên Trung",
            value: 4927,
            slug: "yen-trung",
            status: "empty",
          },
          { label: "Yên Bình", value: 4930, slug: "yen-binh", status: "empty" },
          {
            label: "Tiến Xuân",
            value: 4936,
            slug: "tien-xuan",
            status: "empty",
          },
          {
            label: "Liên Quan",
            value: 9955,
            slug: "lien-quan",
            status: "empty",
          },
          { label: "Đại Đồng", value: 9958, slug: "dai-dong", status: "empty" },
          { label: "Cẩm Yên", value: 9961, slug: "cam-yen", status: "empty" },
          {
            label: "Lại Thượng",
            value: 9964,
            slug: "lai-thuong",
            status: "empty",
          },
          { label: "Phú Kim", value: 9967, slug: "phu-kim", status: "empty" },
          {
            label: "Hương Ngải",
            value: 9970,
            slug: "huong-ngai",
            status: "filled",
          },
          { label: "Lam Sơn", value: 9973, slug: "lam-son", status: "empty" },
          { label: "Kim Quan", value: 9976, slug: "kim-quan", status: "empty" },
          { label: "Bình Yên", value: 9982, slug: "binh-yen", status: "empty" },
          {
            label: "Thạch Hoà",
            value: 9988,
            slug: "thach-hoa",
            status: "empty",
          },
          { label: "Cần Kiệm", value: 9991, slug: "can-kiem", status: "empty" },
          { label: "Phùng Xá", value: 9997, slug: "phung-xa", status: "empty" },
          { label: "Tân Xã", value: 10000, slug: "tan-xa", status: "filled" },
          {
            label: "Thạch Xá",
            value: 10003,
            slug: "thach-xa",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 10006,
            slug: "quang-trung",
            status: "empty",
          },
          { label: "Hạ Bằng", value: 10009, slug: "ha-bang", status: "empty" },
          {
            label: "Đồng Trúc",
            value: 10012,
            slug: "dong-truc",
            status: "empty",
          },
        ],
      },
      {
        label: "Chương Mỹ",
        value: 277,
        slug: "chuong-my",
        status: "filled",
        ward: [
          {
            label: "Chúc Sơn",
            value: 10015,
            slug: "chuc-son",
            status: "empty",
          },
          {
            label: "Xuân Mai",
            value: 10018,
            slug: "xuan-mai",
            status: "empty",
          },
          {
            label: "Phụng Châu",
            value: 10021,
            slug: "phung-chau",
            status: "empty",
          },
          {
            label: "Tiên Phương",
            value: 10024,
            slug: "tien-phuong",
            status: "empty",
          },
          {
            label: "Đông Sơn",
            value: 10027,
            slug: "dong-son",
            status: "empty",
          },
          {
            label: "Đông Phương Yên",
            value: 10030,
            slug: "dong-phuong-yen",
            status: "empty",
          },
          {
            label: "Phú Nghĩa",
            value: 10033,
            slug: "phu-nghia",
            status: "empty",
          },
          {
            label: "Trường Yên",
            value: 10039,
            slug: "truong-yen",
            status: "filled",
          },
          {
            label: "Ngọc Hòa",
            value: 10042,
            slug: "ngoc-hoa",
            status: "empty",
          },
          {
            label: "Thủy Xuân Tiên",
            value: 10045,
            slug: "thuy-xuan-tien",
            status: "empty",
          },
          {
            label: "Thanh Bình",
            value: 10048,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Trung Hòa",
            value: 10051,
            slug: "trung-hoa",
            status: "empty",
          },
          { label: "Đại Yên", value: 10054, slug: "dai-yen", status: "empty" },
          {
            label: "Thụy Hương",
            value: 10057,
            slug: "thuy-huong",
            status: "empty",
          },
          {
            label: "Tốt Động",
            value: 10060,
            slug: "tot-dong",
            status: "empty",
          },
          {
            label: "Lam Điền",
            value: 10063,
            slug: "lam-dien",
            status: "empty",
          },
          {
            label: "Tân Tiến",
            value: 10066,
            slug: "tan-tien",
            status: "empty",
          },
          {
            label: "Nam Phương Tiến",
            value: 10069,
            slug: "nam-phuong-tien",
            status: "empty",
          },
          {
            label: "Hợp Đồng",
            value: 10072,
            slug: "hop-dong",
            status: "empty",
          },
          {
            label: "Hoàng Văn Thụ",
            value: 10075,
            slug: "hoang-van-thu",
            status: "empty",
          },
          {
            label: "Hoàng Diệu",
            value: 10078,
            slug: "hoang-dieu",
            status: "empty",
          },
          { label: "Hữu Văn", value: 10081, slug: "huu-van", status: "empty" },
          {
            label: "Quảng Bị",
            value: 10084,
            slug: "quang-bi",
            status: "empty",
          },
          {
            label: "Mỹ Lương",
            value: 10087,
            slug: "my-luong",
            status: "empty",
          },
          {
            label: "Thượng Vực",
            value: 10090,
            slug: "thuong-vuc",
            status: "empty",
          },
          {
            label: "Hồng Phú",
            value: 10096,
            slug: "hong-phu",
            status: "empty",
          },
          {
            label: "Trần Phú",
            value: 10099,
            slug: "tran-phu",
            status: "empty",
          },
          { label: "Văn Võ", value: 10102, slug: "van-vo", status: "empty" },
          {
            label: "Đồng Lạc",
            value: 10105,
            slug: "dong-lac",
            status: "empty",
          },
          { label: "Hòa Phú", value: 10108, slug: "hoa-phu", status: "empty" },
        ],
      },
      {
        label: "Thanh Oai",
        value: 278,
        slug: "thanh-oai",
        status: "empty",
        ward: [
          { label: "Kim Bài", value: 10114, slug: "kim-bai", status: "empty" },
          { label: "Cự Khê", value: 10120, slug: "cu-khe", status: "empty" },
          {
            label: "Bích Hòa",
            value: 10126,
            slug: "bich-hoa",
            status: "empty",
          },
          { label: "Mỹ Hưng", value: 10129, slug: "my-hung", status: "empty" },
          {
            label: "Cao Viên",
            value: 10132,
            slug: "cao-vien",
            status: "empty",
          },
          {
            label: "Bình Minh",
            value: 10135,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Tam Hưng",
            value: 10138,
            slug: "tam-hung",
            status: "empty",
          },
          {
            label: "Thanh Cao",
            value: 10141,
            slug: "thanh-cao",
            status: "empty",
          },
          {
            label: "Thanh Thùy",
            value: 10144,
            slug: "thanh-thuy",
            status: "empty",
          },
          {
            label: "Thanh Mai",
            value: 10147,
            slug: "thanh-mai",
            status: "empty",
          },
          {
            label: "Thanh Văn",
            value: 10150,
            slug: "thanh-van",
            status: "empty",
          },
          { label: "Đỗ Động", value: 10153, slug: "do-dong", status: "empty" },
          { label: "Kim An", value: 10156, slug: "kim-an", status: "empty" },
          { label: "Kim Thư", value: 10159, slug: "kim-thu", status: "empty" },
          {
            label: "Phương Trung",
            value: 10162,
            slug: "phuong-trung",
            status: "empty",
          },
          { label: "Tân Ước", value: 10165, slug: "tan-uoc", status: "empty" },
          { label: "Dân Hòa", value: 10168, slug: "dan-hoa", status: "empty" },
          {
            label: "Liên Châu",
            value: 10171,
            slug: "lien-chau",
            status: "empty",
          },
          {
            label: "Cao Xuân Dương",
            value: 10174,
            slug: "cao-xuan-duong",
            status: "empty",
          },
          {
            label: "Hồng Dương",
            value: 10180,
            slug: "hong-duong",
            status: "empty",
          },
        ],
      },
      {
        label: "Thường Tín",
        value: 279,
        slug: "thuong-tin",
        status: "filled",
        ward: [
          {
            label: "Thường Tín",
            value: 10183,
            slug: "thuong-tin",
            status: "filled",
          },
          { label: "Ninh Sở", value: 10186, slug: "ninh-so", status: "empty" },
          { label: "Nhị Khê", value: 10189, slug: "nhi-khe", status: "empty" },
          {
            label: "Duyên Thái",
            value: 10192,
            slug: "duyen-thai",
            status: "empty",
          },
          {
            label: "Khánh Hà",
            value: 10195,
            slug: "khanh-ha",
            status: "empty",
          },
          {
            label: "Hòa Bình",
            value: 10198,
            slug: "hoa-binh",
            status: "empty",
          },
          {
            label: "Văn Bình",
            value: 10201,
            slug: "van-binh",
            status: "empty",
          },
          {
            label: "Hiền Giang",
            value: 10204,
            slug: "hien-giang",
            status: "empty",
          },
          {
            label: "Hồng Vân",
            value: 10207,
            slug: "hong-van",
            status: "empty",
          },
          { label: "Vân Tảo", value: 10210, slug: "van-tao", status: "empty" },
          {
            label: "Liên Phương",
            value: 10213,
            slug: "lien-phuong",
            status: "empty",
          },
          { label: "Văn Phú", value: 10216, slug: "van-phu", status: "empty" },
          {
            label: "Tự Nhiên",
            value: 10219,
            slug: "tu-nhien",
            status: "empty",
          },
          {
            label: "Tiền Phong",
            value: 10222,
            slug: "tien-phong",
            status: "empty",
          },
          { label: "Hà Hồi", value: 10225, slug: "ha-hoi", status: "empty" },
          {
            label: "Nguyễn Trãi",
            value: 10231,
            slug: "nguyen-trai",
            status: "empty",
          },
          {
            label: "Quất Động",
            value: 10234,
            slug: "quat-dong",
            status: "empty",
          },
          {
            label: "Chương Dương",
            value: 10237,
            slug: "chuong-duong",
            status: "empty",
          },
          {
            label: "Tân Minh",
            value: 10240,
            slug: "tan-minh",
            status: "empty",
          },
          { label: "Lê Lợi", value: 10243, slug: "le-loi", status: "empty" },
          {
            label: "Thắng Lợi",
            value: 10246,
            slug: "thang-loi",
            status: "empty",
          },
          {
            label: "Dũng Tiến",
            value: 10249,
            slug: "dung-tien",
            status: "empty",
          },
          {
            label: "Nghiêm Xuyên",
            value: 10255,
            slug: "nghiem-xuyen",
            status: "empty",
          },
          { label: "Tô Hiệu", value: 10258, slug: "to-hieu", status: "empty" },
          { label: "Văn Tự", value: 10261, slug: "van-tu", status: "empty" },
          {
            label: "Vạn Nhất",
            value: 10264,
            slug: "van-nhat",
            status: "empty",
          },
          {
            label: "Minh Cường",
            value: 10267,
            slug: "minh-cuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Phú Xuyên",
        value: 280,
        slug: "phu-xuyen",
        status: "empty",
        ward: [
          {
            label: "Phú Minh",
            value: 10270,
            slug: "phu-minh",
            status: "empty",
          },
          {
            label: "Phú Xuyên",
            value: 10273,
            slug: "phu-xuyen",
            status: "empty",
          },
          {
            label: "Hồng Minh",
            value: 10276,
            slug: "hong-minh",
            status: "empty",
          },
          {
            label: "Phượng Dực",
            value: 10279,
            slug: "phuong-duc",
            status: "empty",
          },
          {
            label: "Nam Tiến",
            value: 10282,
            slug: "nam-tien",
            status: "empty",
          },
          {
            label: "Văn Hoàng",
            value: 10291,
            slug: "van-hoang",
            status: "empty",
          },
          { label: "Phú Túc", value: 10294, slug: "phu-tuc", status: "empty" },
          {
            label: "Hồng Thái",
            value: 10300,
            slug: "hong-thai",
            status: "empty",
          },
          {
            label: "Hoàng Long",
            value: 10303,
            slug: "hoang-long",
            status: "empty",
          },
          {
            label: "Nam Phong",
            value: 10312,
            slug: "nam-phong",
            status: "empty",
          },
          { label: "Tân Dân", value: 10315, slug: "tan-dan", status: "empty" },
          {
            label: "Quang Hà",
            value: 10318,
            slug: "quang-ha",
            status: "empty",
          },
          {
            label: "Chuyên Mỹ",
            value: 10321,
            slug: "chuyen-my",
            status: "empty",
          },
          {
            label: "Khai Thái",
            value: 10324,
            slug: "khai-thai",
            status: "empty",
          },
          {
            label: "Phúc Tiến",
            value: 10327,
            slug: "phuc-tien",
            status: "empty",
          },
          { label: "Vân Từ", value: 10330, slug: "van-tu", status: "empty" },
          {
            label: "Tri Thủy",
            value: 10333,
            slug: "tri-thuy",
            status: "empty",
          },
          {
            label: "Đại Xuyên",
            value: 10336,
            slug: "dai-xuyen",
            status: "empty",
          },
          { label: "Phú Yên", value: 10339, slug: "phu-yen", status: "empty" },
          { label: "Bạch Hạ", value: 10342, slug: "bach-ha", status: "empty" },
          {
            label: "Quang Lãng",
            value: 10345,
            slug: "quang-lang",
            status: "empty",
          },
          {
            label: "Châu Can",
            value: 10348,
            slug: "chau-can",
            status: "empty",
          },
          {
            label: "Minh Tân",
            value: 10351,
            slug: "minh-tan",
            status: "empty",
          },
        ],
      },
      {
        label: "Ứng Hòa",
        value: 281,
        slug: "ung-hoa",
        status: "empty",
        ward: [
          {
            label: "Vân Đình",
            value: 10354,
            slug: "van-dinh",
            status: "empty",
          },
          {
            label: "Hoa Viên",
            value: 10363,
            slug: "hoa-vien",
            status: "empty",
          },
          {
            label: "Quảng Phú Cầu",
            value: 10366,
            slug: "quang-phu-cau",
            status: "empty",
          },
          {
            label: "Trường Thịnh",
            value: 10369,
            slug: "truong-thinh",
            status: "empty",
          },
          {
            label: "Liên Bạt",
            value: 10375,
            slug: "lien-bat",
            status: "empty",
          },
          {
            label: "Cao Sơn Tiến",
            value: 10378,
            slug: "cao-son-tien",
            status: "empty",
          },
          {
            label: "Phương Tú",
            value: 10384,
            slug: "phuong-tu",
            status: "empty",
          },
          {
            label: "Trung Tú",
            value: 10387,
            slug: "trung-tu",
            status: "empty",
          },
          {
            label: "Đồng Tân",
            value: 10390,
            slug: "dong-tan",
            status: "empty",
          },
          {
            label: "Tảo Dương Văn",
            value: 10393,
            slug: "tao-duong-van",
            status: "empty",
          },
          {
            label: "Thái Hòa",
            value: 10396,
            slug: "thai-hoa",
            status: "empty",
          },
          {
            label: "Minh Đức",
            value: 10399,
            slug: "minh-duc",
            status: "empty",
          },
          {
            label: "Trầm Lộng",
            value: 10402,
            slug: "tram-long",
            status: "empty",
          },
          {
            label: "Kim Đường",
            value: 10411,
            slug: "kim-duong",
            status: "empty",
          },
          { label: "Hòa Phú", value: 10417, slug: "hoa-phu", status: "empty" },
          {
            label: "Đại Hùng",
            value: 10423,
            slug: "dai-hung",
            status: "empty",
          },
          { label: "Đông Lỗ", value: 10426, slug: "dong-lo", status: "empty" },
          { label: "Phù Lưu", value: 10429, slug: "phu-luu", status: "empty" },
          {
            label: "Đại Cường",
            value: 10432,
            slug: "dai-cuong",
            status: "empty",
          },
          {
            label: "Bình Lưu Quang",
            value: 10435,
            slug: "binh-luu-quang",
            status: "empty",
          },
        ],
      },
      {
        label: "Mỹ Đức",
        value: 282,
        slug: "my-duc",
        status: "empty",
        ward: [
          {
            label: "Đại Nghĩa",
            value: 10441,
            slug: "dai-nghia",
            status: "empty",
          },
          {
            label: "Đồng Tâm",
            value: 10444,
            slug: "dong-tam",
            status: "empty",
          },
          {
            label: "Thượng Lâm",
            value: 10447,
            slug: "thuong-lam",
            status: "empty",
          },
          { label: "Tuy Lai", value: 10450, slug: "tuy-lai", status: "empty" },
          {
            label: "Phúc Lâm",
            value: 10453,
            slug: "phuc-lam",
            status: "empty",
          },
          {
            label: "Mỹ Xuyên",
            value: 10459,
            slug: "my-xuyen",
            status: "empty",
          },
          { label: "An Mỹ", value: 10462, slug: "an-my", status: "empty" },
          {
            label: "Hồng Sơn",
            value: 10465,
            slug: "hong-son",
            status: "empty",
          },
          {
            label: "Lê Thanh",
            value: 10468,
            slug: "le-thanh",
            status: "empty",
          },
          { label: "Xuy Xá", value: 10471, slug: "xuy-xa", status: "empty" },
          {
            label: "Phùng Xá",
            value: 10474,
            slug: "phung-xa",
            status: "empty",
          },
          {
            label: "Phù Lưu Tế",
            value: 10477,
            slug: "phu-luu-te",
            status: "empty",
          },
          {
            label: "Đại Hưng",
            value: 10480,
            slug: "dai-hung",
            status: "empty",
          },
          { label: "Vạn Tín", value: 10483, slug: "van-tin", status: "empty" },
          {
            label: "Hương Sơn",
            value: 10489,
            slug: "huong-son",
            status: "empty",
          },
          {
            label: "Hùng Tiến",
            value: 10492,
            slug: "hung-tien",
            status: "empty",
          },
          { label: "An Tiến", value: 10495, slug: "an-tien", status: "empty" },
          {
            label: "Hợp Tiến",
            value: 10498,
            slug: "hop-tien",
            status: "empty",
          },
          {
            label: "Hợp Thanh",
            value: 10501,
            slug: "hop-thanh",
            status: "empty",
          },
          { label: "An Phú", value: 10504, slug: "an-phu", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Hà Giang",
    value: 2,
    slug: "ha-giang",
    status: "filled",
    district: [
      {
        label: "Thành phố Hà Giang",
        value: 24,
        slug: "thanh-pho-ha-giang",
        status: "filled",
        ward: [
          {
            label: "Quang Trung",
            value: 688,
            slug: "quang-trung",
            status: "empty",
          },
          { label: "Trần Phú", value: 691, slug: "tran-phu", status: "filled" },
          { label: "Ngọc Hà", value: 692, slug: "ngoc-ha", status: "empty" },
          {
            label: "Nguyễn Trãi",
            value: 694,
            slug: "nguyen-trai",
            status: "empty",
          },
          {
            label: "Minh Khai",
            value: 697,
            slug: "minh-khai",
            status: "empty",
          },
          {
            label: "Ngọc Đường",
            value: 700,
            slug: "ngoc-duong",
            status: "empty",
          },
          {
            label: "Phương Độ",
            value: 946,
            slug: "phuong-do",
            status: "empty",
          },
          {
            label: "Phương Thiện",
            value: 949,
            slug: "phuong-thien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đồng Văn",
        value: 26,
        slug: "huyen-dong-van",
        status: "empty",
        ward: [
          { label: "Phó Bảng", value: 712, slug: "pho-bang", status: "empty" },
          { label: "Lũng Cú", value: 715, slug: "lung-cu", status: "empty" },
          { label: "Má Lé", value: 718, slug: "ma-le", status: "empty" },
          { label: "Đồng Văn", value: 721, slug: "dong-van", status: "empty" },
          { label: "Lũng Táo", value: 724, slug: "lung-tao", status: "empty" },
          { label: "Phố Là", value: 727, slug: "pho-la", status: "empty" },
          {
            label: "Thài Phìn Tủng",
            value: 730,
            slug: "thai-phin-tung",
            status: "empty",
          },
          { label: "Sủng Là", value: 733, slug: "sung-la", status: "empty" },
          { label: "Xà Phìn", value: 736, slug: "xa-phin", status: "empty" },
          { label: "Tả Phìn", value: 739, slug: "ta-phin", status: "empty" },
          { label: "Tả Lủng", value: 742, slug: "ta-lung", status: "empty" },
          { label: "Phố Cáo", value: 745, slug: "pho-cao", status: "empty" },
          {
            label: "Sính Lủng",
            value: 748,
            slug: "sinh-lung",
            status: "empty",
          },
          {
            label: "Sảng Tủng",
            value: 751,
            slug: "sang-tung",
            status: "empty",
          },
          {
            label: "Lũng Thầu",
            value: 754,
            slug: "lung-thau",
            status: "empty",
          },
          {
            label: "Hố Quáng Phìn",
            value: 757,
            slug: "ho-quang-phin",
            status: "empty",
          },
          { label: "Vần Chải", value: 760, slug: "van-chai", status: "empty" },
          {
            label: "Lũng Phìn",
            value: 763,
            slug: "lung-phin",
            status: "empty",
          },
          {
            label: "Sủng Trái",
            value: 766,
            slug: "sung-trai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Mèo Vạc",
        value: 27,
        slug: "huyen-meo-vac",
        status: "empty",
        ward: [
          { label: "Mèo Vạc", value: 769, slug: "meo-vac", status: "empty" },
          {
            label: "Thượng Phùng",
            value: 772,
            slug: "thuong-phung",
            status: "empty",
          },
          { label: "Pải Lủng", value: 775, slug: "pai-lung", status: "empty" },
          { label: "Xín Cái", value: 778, slug: "xin-cai", status: "empty" },
          { label: "Pả Vi", value: 781, slug: "pa-vi", status: "empty" },
          {
            label: "Giàng Chu Phìn",
            value: 784,
            slug: "giang-chu-phin",
            status: "empty",
          },
          { label: "Sủng Trà", value: 787, slug: "sung-tra", status: "empty" },
          {
            label: "Sủng Máng",
            value: 790,
            slug: "sung-mang",
            status: "empty",
          },
          { label: "Sơn Vĩ", value: 793, slug: "son-vi", status: "empty" },
          { label: "Tả Lủng", value: 796, slug: "ta-lung", status: "empty" },
          {
            label: "Cán Chu Phìn",
            value: 799,
            slug: "can-chu-phin",
            status: "empty",
          },
          { label: "Lũng Pù", value: 802, slug: "lung-pu", status: "empty" },
          {
            label: "Lũng Chinh",
            value: 805,
            slug: "lung-chinh",
            status: "empty",
          },
          { label: "Tát Ngà", value: 808, slug: "tat-nga", status: "empty" },
          { label: "Nậm Ban", value: 811, slug: "nam-ban", status: "empty" },
          { label: "Khâu Vai", value: 814, slug: "khau-vai", status: "empty" },
          {
            label: "Niêm Tòng",
            value: 815,
            slug: "niem-tong",
            status: "empty",
          },
          { label: "Niêm Sơn", value: 817, slug: "niem-son", status: "empty" },
        ],
      },
      {
        label: "Huyện Yên Minh",
        value: 28,
        slug: "huyen-yen-minh",
        status: "empty",
        ward: [
          { label: "Yên Minh", value: 820, slug: "yen-minh", status: "empty" },
          { label: "Thắng Mố", value: 823, slug: "thang-mo", status: "empty" },
          { label: "Phú Lũng", value: 826, slug: "phu-lung", status: "empty" },
          {
            label: "Sủng Tráng",
            value: 829,
            slug: "sung-trang",
            status: "empty",
          },
          {
            label: "Bạch Đích",
            value: 832,
            slug: "bach-dich",
            status: "empty",
          },
          { label: "Na Khê", value: 835, slug: "na-khe", status: "empty" },
          {
            label: "Sủng Thài",
            value: 838,
            slug: "sung-thai",
            status: "empty",
          },
          { label: "Hữu Vinh", value: 841, slug: "huu-vinh", status: "empty" },
          {
            label: "Lao Và Chải",
            value: 844,
            slug: "lao-va-chai",
            status: "empty",
          },
          { label: "Mậu Duệ", value: 847, slug: "mau-due", status: "empty" },
          {
            label: "Đông Minh",
            value: 850,
            slug: "dong-minh",
            status: "empty",
          },
          { label: "Mậu Long", value: 853, slug: "mau-long", status: "empty" },
          { label: "Ngam La", value: 856, slug: "ngam-la", status: "empty" },
          {
            label: "Ngọc Long",
            value: 859,
            slug: "ngoc-long",
            status: "empty",
          },
          {
            label: "Đường Thượng",
            value: 862,
            slug: "duong-thuong",
            status: "empty",
          },
          { label: "Lũng Hồ", value: 865, slug: "lung-ho", status: "empty" },
          { label: "Du Tiến", value: 868, slug: "du-tien", status: "empty" },
          { label: "Du Già", value: 871, slug: "du-gia", status: "empty" },
        ],
      },
      {
        label: "Huyện Quản Bạ",
        value: 29,
        slug: "huyen-quan-ba",
        status: "empty",
        ward: [
          { label: "Tam Sơn", value: 874, slug: "tam-son", status: "empty" },
          {
            label: "Bát Đại Sơn",
            value: 877,
            slug: "bat-dai-son",
            status: "empty",
          },
          {
            label: "Nghĩa Thuận",
            value: 880,
            slug: "nghia-thuan",
            status: "empty",
          },
          { label: "Cán Tỷ", value: 883, slug: "can-ty", status: "empty" },
          {
            label: "Cao Mã Pờ",
            value: 886,
            slug: "cao-ma-po",
            status: "empty",
          },
          {
            label: "Thanh Vân",
            value: 889,
            slug: "thanh-van",
            status: "empty",
          },
          { label: "Tùng Vài", value: 892, slug: "tung-vai", status: "empty" },
          { label: "Đông Hà", value: 895, slug: "dong-ha", status: "empty" },
          { label: "Quản Bạ", value: 898, slug: "quan-ba", status: "empty" },
          { label: "Lùng Tám", value: 901, slug: "lung-tam", status: "empty" },
          {
            label: "Quyết Tiến",
            value: 904,
            slug: "quyet-tien",
            status: "empty",
          },
          { label: "Tả Ván", value: 907, slug: "ta-van", status: "empty" },
          { label: "Thái An", value: 910, slug: "thai-an", status: "empty" },
        ],
      },
      {
        label: "Huyện Vị Xuyên",
        value: 30,
        slug: "huyen-vi-xuyen",
        status: "empty",
        ward: [
          {
            label: "Kim Thạch",
            value: 703,
            slug: "kim-thach",
            status: "empty",
          },
          { label: "Phú Linh", value: 706, slug: "phu-linh", status: "empty" },
          { label: "Kim Linh", value: 709, slug: "kim-linh", status: "empty" },
          { label: "Vị Xuyên", value: 913, slug: "vi-xuyen", status: "empty" },
          {
            label: "Nông Trường Việt Lâm",
            value: 916,
            slug: "nong-truong-viet-lam",
            status: "empty",
          },
          { label: "Minh Tân", value: 919, slug: "minh-tan", status: "empty" },
          {
            label: "Thuận Hoà",
            value: 922,
            slug: "thuan-hoa",
            status: "empty",
          },
          { label: "Tùng Bá", value: 925, slug: "tung-ba", status: "empty" },
          {
            label: "Thanh Thủy",
            value: 928,
            slug: "thanh-thuy",
            status: "empty",
          },
          {
            label: "Thanh Đức",
            value: 931,
            slug: "thanh-duc",
            status: "empty",
          },
          {
            label: "Phong Quang",
            value: 934,
            slug: "phong-quang",
            status: "empty",
          },
          { label: "Xín Chải", value: 937, slug: "xin-chai", status: "empty" },
          {
            label: "Phương Tiến",
            value: 940,
            slug: "phuong-tien",
            status: "empty",
          },
          { label: "Lao Chải", value: 943, slug: "lao-chai", status: "empty" },
          { label: "Cao Bồ", value: 952, slug: "cao-bo", status: "empty" },
          { label: "Đạo Đức", value: 955, slug: "dao-duc", status: "empty" },
          {
            label: "Thượng Sơn",
            value: 958,
            slug: "thuong-son",
            status: "empty",
          },
          { label: "Linh Hồ", value: 961, slug: "linh-ho", status: "empty" },
          {
            label: "Quảng Ngần",
            value: 964,
            slug: "quang-ngan",
            status: "empty",
          },
          { label: "Việt Lâm", value: 967, slug: "viet-lam", status: "empty" },
          {
            label: "Ngọc Linh",
            value: 970,
            slug: "ngoc-linh",
            status: "empty",
          },
          {
            label: "Ngọc Minh",
            value: 973,
            slug: "ngoc-minh",
            status: "empty",
          },
          {
            label: "Bạch Ngọc",
            value: 976,
            slug: "bach-ngoc",
            status: "empty",
          },
          {
            label: "Trung Thành",
            value: 979,
            slug: "trung-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bắc Mê",
        value: 31,
        slug: "huyen-bac-me",
        status: "empty",
        ward: [
          { label: "Minh Sơn", value: 982, slug: "minh-son", status: "empty" },
          {
            label: "Giáp Trung",
            value: 985,
            slug: "giap-trung",
            status: "empty",
          },
          { label: "Yên Định", value: 988, slug: "yen-dinh", status: "empty" },
          { label: "Yên Phú", value: 991, slug: "yen-phu", status: "empty" },
          {
            label: "Minh Ngọc",
            value: 994,
            slug: "minh-ngoc",
            status: "empty",
          },
          {
            label: "Yên Phong",
            value: 997,
            slug: "yen-phong",
            status: "empty",
          },
          { label: "Lạc Nông", value: 1000, slug: "lac-nong", status: "empty" },
          { label: "Phú Nam", value: 1003, slug: "phu-nam", status: "empty" },
          {
            label: "Yên Cường",
            value: 1006,
            slug: "yen-cuong",
            status: "empty",
          },
          {
            label: "Thượng Tân",
            value: 1009,
            slug: "thuong-tan",
            status: "empty",
          },
          { label: "Đường Âm", value: 1012, slug: "duong-am", status: "empty" },
          {
            label: "Đường Hồng",
            value: 1015,
            slug: "duong-hong",
            status: "empty",
          },
          {
            label: "Phiêng Luông",
            value: 1018,
            slug: "phieng-luong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hoàng Su Phì",
        value: 32,
        slug: "huyen-hoang-su-phi",
        status: "empty",
        ward: [
          {
            label: "Vinh Quang",
            value: 1021,
            slug: "vinh-quang",
            status: "empty",
          },
          { label: "Bản Máy", value: 1024, slug: "ban-may", status: "empty" },
          {
            label: "Thàng Tín",
            value: 1027,
            slug: "thang-tin",
            status: "empty",
          },
          {
            label: "Thèn Chu Phìn",
            value: 1030,
            slug: "then-chu-phin",
            status: "empty",
          },
          { label: "Pố Lồ", value: 1033, slug: "po-lo", status: "empty" },
          {
            label: "Bản Phùng",
            value: 1036,
            slug: "ban-phung",
            status: "empty",
          },
          { label: "Túng Sán", value: 1039, slug: "tung-san", status: "empty" },
          {
            label: "Chiến Phố",
            value: 1042,
            slug: "chien-pho",
            status: "empty",
          },
          { label: "Đản Ván", value: 1045, slug: "dan-van", status: "empty" },
          { label: "Tụ Nhân", value: 1048, slug: "tu-nhan", status: "empty" },
          { label: "Tân Tiến", value: 1051, slug: "tan-tien", status: "empty" },
          { label: "Nàng Đôn", value: 1054, slug: "nang-don", status: "empty" },
          {
            label: "Pờ Ly Ngài",
            value: 1057,
            slug: "po-ly-ngai",
            status: "empty",
          },
          {
            label: "Sán Xả Hồ",
            value: 1060,
            slug: "san-xa-ho",
            status: "empty",
          },
          { label: "Bản Luốc", value: 1063, slug: "ban-luoc", status: "empty" },
          {
            label: "Ngàm Đăng Vài",
            value: 1066,
            slug: "ngam-dang-vai",
            status: "empty",
          },
          {
            label: "Bản Nhùng",
            value: 1069,
            slug: "ban-nhung",
            status: "empty",
          },
          {
            label: "Tả Sử Choóng",
            value: 1072,
            slug: "ta-su-choong",
            status: "empty",
          },
          { label: "Nậm Dịch", value: 1075, slug: "nam-dich", status: "empty" },
          { label: "Hồ Thầu", value: 1081, slug: "ho-thau", status: "empty" },
          { label: "Nam Sơn", value: 1084, slug: "nam-son", status: "empty" },
          { label: "Nậm Tỵ", value: 1087, slug: "nam-ty", status: "empty" },
          {
            label: "Thông Nguyên",
            value: 1090,
            slug: "thong-nguyen",
            status: "empty",
          },
          { label: "Nậm Khòa", value: 1093, slug: "nam-khoa", status: "empty" },
        ],
      },
      {
        label: "Huyện Xín Mần",
        value: 33,
        slug: "huyen-xin-man",
        status: "empty",
        ward: [
          { label: "Cốc Pài", value: 1096, slug: "coc-pai", status: "empty" },
          { label: "Nàn Xỉn", value: 1099, slug: "nan-xin", status: "empty" },
          { label: "Bản Díu", value: 1102, slug: "ban-diu", status: "empty" },
          { label: "Chí Cà", value: 1105, slug: "chi-ca", status: "empty" },
          { label: "Xín Mần", value: 1108, slug: "xin-man", status: "empty" },
          {
            label: "Thèn Phàng",
            value: 1114,
            slug: "then-phang",
            status: "empty",
          },
          {
            label: "Trung Thịnh",
            value: 1117,
            slug: "trung-thinh",
            status: "empty",
          },
          {
            label: "Pà Vầy Sủ",
            value: 1120,
            slug: "pa-vay-su",
            status: "empty",
          },
          { label: "Cốc Rế", value: 1123, slug: "coc-re", status: "empty" },
          { label: "Thu Tà", value: 1126, slug: "thu-ta", status: "empty" },
          { label: "Nàn Ma", value: 1129, slug: "nan-ma", status: "empty" },
          { label: "Tả Nhìu", value: 1132, slug: "ta-nhiu", status: "empty" },
          { label: "Bản Ngò", value: 1135, slug: "ban-ngo", status: "empty" },
          { label: "Chế Là", value: 1138, slug: "che-la", status: "empty" },
          { label: "Nấm Dẩn", value: 1141, slug: "nam-dan", status: "empty" },
          {
            label: "Quảng Nguyên",
            value: 1144,
            slug: "quang-nguyen",
            status: "empty",
          },
          { label: "Nà Chì", value: 1147, slug: "na-chi", status: "empty" },
          {
            label: "Khuôn Lùng",
            value: 1150,
            slug: "khuon-lung",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bắc Quang",
        value: 34,
        slug: "huyen-bac-quang",
        status: "empty",
        ward: [
          {
            label: "Việt Quang",
            value: 1153,
            slug: "viet-quang",
            status: "empty",
          },
          { label: "Vĩnh Tuy", value: 1156, slug: "vinh-tuy", status: "empty" },
          { label: "Tân Lập", value: 1159, slug: "tan-lap", status: "empty" },
          {
            label: "Tân Thành",
            value: 1162,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Đồng Tiến",
            value: 1165,
            slug: "dong-tien",
            status: "empty",
          },
          { label: "Đồng Tâm", value: 1168, slug: "dong-tam", status: "empty" },
          {
            label: "Tân Quang",
            value: 1171,
            slug: "tan-quang",
            status: "empty",
          },
          {
            label: "Thượng Bình",
            value: 1174,
            slug: "thuong-binh",
            status: "empty",
          },
          { label: "Hữu Sản", value: 1177, slug: "huu-san", status: "empty" },
          { label: "Kim Ngọc", value: 1180, slug: "kim-ngoc", status: "empty" },
          {
            label: "Việt Vinh",
            value: 1183,
            slug: "viet-vinh",
            status: "empty",
          },
          {
            label: "Bằng Hành",
            value: 1186,
            slug: "bang-hanh",
            status: "empty",
          },
          {
            label: "Quang Minh",
            value: 1189,
            slug: "quang-minh",
            status: "empty",
          },
          {
            label: "Liên Hiệp",
            value: 1192,
            slug: "lien-hiep",
            status: "empty",
          },
          { label: "Vô Điếm", value: 1195, slug: "vo-diem", status: "empty" },
          {
            label: "Việt Hồng",
            value: 1198,
            slug: "viet-hong",
            status: "empty",
          },
          { label: "Hùng An", value: 1201, slug: "hung-an", status: "empty" },
          { label: "Đức Xuân", value: 1204, slug: "duc-xuan", status: "empty" },
          {
            label: "Tiên Kiều",
            value: 1207,
            slug: "tien-kieu",
            status: "empty",
          },
          { label: "Vĩnh Hảo", value: 1210, slug: "vinh-hao", status: "empty" },
          {
            label: "Vĩnh Phúc",
            value: 1213,
            slug: "vinh-phuc",
            status: "empty",
          },
          { label: "Đồng Yên", value: 1216, slug: "dong-yen", status: "empty" },
          {
            label: "Đông Thành",
            value: 1219,
            slug: "dong-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Quang Bình",
        value: 35,
        slug: "huyen-quang-binh",
        status: "empty",
        ward: [
          {
            label: "Xuân Minh",
            value: 1222,
            slug: "xuan-minh",
            status: "empty",
          },
          {
            label: "Tiên Nguyên",
            value: 1225,
            slug: "tien-nguyen",
            status: "empty",
          },
          { label: "Tân Nam", value: 1228, slug: "tan-nam", status: "empty" },
          { label: "Bản Rịa", value: 1231, slug: "ban-ria", status: "empty" },
          {
            label: "Yên Thành",
            value: 1234,
            slug: "yen-thanh",
            status: "empty",
          },
          { label: "Yên Bình", value: 1237, slug: "yen-binh", status: "empty" },
          {
            label: "Tân Trịnh",
            value: 1240,
            slug: "tan-trinh",
            status: "empty",
          },
          { label: "Tân Bắc", value: 1243, slug: "tan-bac", status: "empty" },
          {
            label: "Bằng Lang",
            value: 1246,
            slug: "bang-lang",
            status: "empty",
          },
          { label: "Yên Hà", value: 1249, slug: "yen-ha", status: "empty" },
          {
            label: "Hương Sơn",
            value: 1252,
            slug: "huong-son",
            status: "empty",
          },
          {
            label: "Xuân Giang",
            value: 1255,
            slug: "xuan-giang",
            status: "empty",
          },
          {
            label: "Nà Khương",
            value: 1258,
            slug: "na-khuong",
            status: "empty",
          },
          { label: "Tiên Yên", value: 1261, slug: "tien-yen", status: "empty" },
          {
            label: "Vĩ Thượng",
            value: 1264,
            slug: "vi-thuong",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Cao Bằng",
    value: 4,
    slug: "cao-bang",
    status: "empty",
    district: [
      {
        label: "Thành phố Cao Bằng",
        value: 40,
        slug: "thanh-pho-cao-bang",
        status: "empty",
        ward: [
          {
            label: "Sông Hiến",
            value: 1267,
            slug: "song-hien",
            status: "empty",
          },
          {
            label: "Sông Bằng",
            value: 1270,
            slug: "song-bang",
            status: "empty",
          },
          {
            label: "Hợp Giang",
            value: 1273,
            slug: "hop-giang",
            status: "empty",
          },
          {
            label: "Tân Giang",
            value: 1276,
            slug: "tan-giang",
            status: "empty",
          },
          {
            label: "Ngọc Xuân",
            value: 1279,
            slug: "ngoc-xuan",
            status: "empty",
          },
          { label: "Đề Thám", value: 1282, slug: "de-tham", status: "empty" },
          {
            label: "Hoà Chung",
            value: 1285,
            slug: "hoa-chung",
            status: "empty",
          },
          {
            label: "Duyệt Trung",
            value: 1288,
            slug: "duyet-trung",
            status: "empty",
          },
          {
            label: "Vĩnh Quang",
            value: 1693,
            slug: "vinh-quang",
            status: "empty",
          },
          { label: "Hưng Đạo", value: 1705, slug: "hung-dao", status: "empty" },
          {
            label: "Chu Trinh",
            value: 1720,
            slug: "chu-trinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bảo Lâm",
        value: 42,
        slug: "huyen-bao-lam",
        status: "empty",
        ward: [
          { label: "Pác Miầu", value: 1290, slug: "pac-miau", status: "empty" },
          { label: "Đức Hạnh", value: 1291, slug: "duc-hanh", status: "empty" },
          { label: "Lý Bôn", value: 1294, slug: "ly-bon", status: "empty" },
          { label: "Nam Cao", value: 1296, slug: "nam-cao", status: "empty" },
          {
            label: "Nam Quang",
            value: 1297,
            slug: "nam-quang",
            status: "empty",
          },
          {
            label: "Vĩnh Quang",
            value: 1300,
            slug: "vinh-quang",
            status: "empty",
          },
          {
            label: "Quảng Lâm",
            value: 1303,
            slug: "quang-lam",
            status: "empty",
          },
          {
            label: "Thạch Lâm",
            value: 1304,
            slug: "thach-lam",
            status: "empty",
          },
          {
            label: "Vĩnh Phong",
            value: 1309,
            slug: "vinh-phong",
            status: "empty",
          },
          { label: "Mông Ân", value: 1312, slug: "mong-an", status: "empty" },
          { label: "Thái Học", value: 1315, slug: "thai-hoc", status: "empty" },
          { label: "Thái Sơn", value: 1316, slug: "thai-son", status: "empty" },
          { label: "Yên Thổ", value: 1318, slug: "yen-tho", status: "empty" },
        ],
      },
      {
        label: "Huyện Bảo Lạc",
        value: 43,
        slug: "huyen-bao-lac",
        status: "empty",
        ward: [
          { label: "Bảo Lạc", value: 1321, slug: "bao-lac", status: "empty" },
          { label: "Cốc Pàng", value: 1324, slug: "coc-pang", status: "empty" },
          {
            label: "Thượng Hà",
            value: 1327,
            slug: "thuong-ha",
            status: "empty",
          },
          { label: "Cô Ba", value: 1330, slug: "co-ba", status: "empty" },
          { label: "Bảo Toàn", value: 1333, slug: "bao-toan", status: "empty" },
          {
            label: "Khánh Xuân",
            value: 1336,
            slug: "khanh-xuan",
            status: "empty",
          },
          {
            label: "Xuân Trường",
            value: 1339,
            slug: "xuan-truong",
            status: "empty",
          },
          { label: "Hồng Trị", value: 1342, slug: "hong-tri", status: "empty" },
          { label: "Kim Cúc", value: 1343, slug: "kim-cuc", status: "empty" },
          {
            label: "Phan Thanh",
            value: 1345,
            slug: "phan-thanh",
            status: "empty",
          },
          { label: "Hồng An", value: 1348, slug: "hong-an", status: "empty" },
          { label: "Hưng Đạo", value: 1351, slug: "hung-dao", status: "empty" },
          {
            label: "Hưng Thịnh",
            value: 1352,
            slug: "hung-thinh",
            status: "empty",
          },
          { label: "Huy Giáp", value: 1354, slug: "huy-giap", status: "empty" },
          {
            label: "Đình Phùng",
            value: 1357,
            slug: "dinh-phung",
            status: "empty",
          },
          { label: "Sơn Lập", value: 1359, slug: "son-lap", status: "empty" },
          { label: "Sơn Lộ", value: 1360, slug: "son-lo", status: "empty" },
        ],
      },
      {
        label: "Huyện Hà Quảng",
        value: 45,
        slug: "huyen-ha-quang",
        status: "empty",
        ward: [
          {
            label: "Thông Nông",
            value: 1363,
            slug: "thong-nong",
            status: "empty",
          },
          { label: "Cần Yên", value: 1366, slug: "can-yen", status: "empty" },
          { label: "Cần Nông", value: 1367, slug: "can-nong", status: "empty" },
          {
            label: "Lương Thông",
            value: 1372,
            slug: "luong-thong",
            status: "empty",
          },
          { label: "Đa Thông", value: 1375, slug: "da-thong", status: "empty" },
          {
            label: "Ngọc Động",
            value: 1378,
            slug: "ngoc-dong",
            status: "empty",
          },
          { label: "Yên Sơn", value: 1381, slug: "yen-son", status: "empty" },
          {
            label: "Lương Can",
            value: 1384,
            slug: "luong-can",
            status: "empty",
          },
          {
            label: "Thanh Long",
            value: 1387,
            slug: "thanh-long",
            status: "empty",
          },
          { label: "Xuân Hòa", value: 1392, slug: "xuan-hoa", status: "empty" },
          { label: "Lũng Nặm", value: 1393, slug: "lung-nam", status: "empty" },
          {
            label: "Trường Hà",
            value: 1399,
            slug: "truong-ha",
            status: "empty",
          },
          { label: "Cải Viên", value: 1402, slug: "cai-vien", status: "empty" },
          { label: "Nội Thôn", value: 1411, slug: "noi-thon", status: "empty" },
          { label: "Tổng Cọt", value: 1414, slug: "tong-cot", status: "empty" },
          { label: "Sóc Hà", value: 1417, slug: "soc-ha", status: "empty" },
          {
            label: "Thượng Thôn",
            value: 1420,
            slug: "thuong-thon",
            status: "empty",
          },
          { label: "Hồng Sỹ", value: 1429, slug: "hong-sy", status: "empty" },
          { label: "Quý Quân", value: 1432, slug: "quy-quan", status: "empty" },
          { label: "Mã Ba", value: 1435, slug: "ma-ba", status: "empty" },
          { label: "Ngọc Đào", value: 1438, slug: "ngoc-dao", status: "empty" },
        ],
      },
      {
        label: "Huyện Trùng Khánh",
        value: 47,
        slug: "huyen-trung-khanh",
        status: "empty",
        ward: [
          { label: "Trà Lĩnh", value: 1447, slug: "tra-linh", status: "empty" },
          {
            label: "Tri Phương",
            value: 1453,
            slug: "tri-phuong",
            status: "empty",
          },
          {
            label: "Quang Hán",
            value: 1456,
            slug: "quang-han",
            status: "empty",
          },
          { label: "Xuân Nội", value: 1462, slug: "xuan-noi", status: "empty" },
          {
            label: "Quang Trung",
            value: 1465,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Quang Vinh",
            value: 1468,
            slug: "quang-vinh",
            status: "empty",
          },
          {
            label: "Cao Chương",
            value: 1471,
            slug: "cao-chuong",
            status: "empty",
          },
          {
            label: "Trùng Khánh",
            value: 1477,
            slug: "trung-khanh",
            status: "empty",
          },
          { label: "Ngọc Khê", value: 1480, slug: "ngoc-khe", status: "empty" },
          { label: "Ngọc Côn", value: 1481, slug: "ngoc-con", status: "empty" },
          {
            label: "Phong Nậm",
            value: 1483,
            slug: "phong-nam",
            status: "empty",
          },
          {
            label: "Đình Phong",
            value: 1489,
            slug: "dinh-phong",
            status: "empty",
          },
          { label: "Đàm Thuỷ", value: 1495, slug: "dam-thuy", status: "empty" },
          {
            label: "Khâm Thành",
            value: 1498,
            slug: "kham-thanh",
            status: "empty",
          },
          { label: "Chí Viễn", value: 1501, slug: "chi-vien", status: "empty" },
          {
            label: "Lăng Hiếu",
            value: 1504,
            slug: "lang-hieu",
            status: "empty",
          },
          {
            label: "Phong Châu",
            value: 1507,
            slug: "phong-chau",
            status: "empty",
          },
          {
            label: "Trung Phúc",
            value: 1516,
            slug: "trung-phuc",
            status: "empty",
          },
          {
            label: "Cao Thăng",
            value: 1519,
            slug: "cao-thang",
            status: "empty",
          },
          { label: "Đức Hồng", value: 1522, slug: "duc-hong", status: "empty" },
          {
            label: "Đoài Dương",
            value: 1525,
            slug: "doai-duong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hạ Lang",
        value: 48,
        slug: "huyen-ha-lang",
        status: "empty",
        ward: [
          {
            label: "Minh Long",
            value: 1534,
            slug: "minh-long",
            status: "empty",
          },
          { label: "Lý Quốc", value: 1537, slug: "ly-quoc", status: "empty" },
          {
            label: "Thắng Lợi",
            value: 1540,
            slug: "thang-loi",
            status: "empty",
          },
          {
            label: "Đồng Loan",
            value: 1543,
            slug: "dong-loan",
            status: "empty",
          },
          {
            label: "Đức Quang",
            value: 1546,
            slug: "duc-quang",
            status: "empty",
          },
          { label: "Kim Loan", value: 1549, slug: "kim-loan", status: "empty" },
          {
            label: "Quang Long",
            value: 1552,
            slug: "quang-long",
            status: "empty",
          },
          { label: "An Lạc", value: 1555, slug: "an-lac", status: "empty" },
          {
            label: "Thanh Nhật",
            value: 1558,
            slug: "thanh-nhat",
            status: "empty",
          },
          { label: "Vinh Quý", value: 1561, slug: "vinh-quy", status: "empty" },
          {
            label: "Thống Nhất",
            value: 1564,
            slug: "thong-nhat",
            status: "empty",
          },
          { label: "Cô Ngân", value: 1567, slug: "co-ngan", status: "empty" },
          { label: "Thị Hoa", value: 1573, slug: "thi-hoa", status: "empty" },
        ],
      },
      {
        label: "Huyện Quảng Hòa",
        value: 49,
        slug: "huyen-quang-hoa",
        status: "empty",
        ward: [
          {
            label: "Quốc Toản",
            value: 1474,
            slug: "quoc-toan",
            status: "empty",
          },
          {
            label: "Quảng Uyên",
            value: 1576,
            slug: "quang-uyen",
            status: "empty",
          },
          { label: "Phi Hải", value: 1579, slug: "phi-hai", status: "empty" },
          {
            label: "Quảng Hưng",
            value: 1582,
            slug: "quang-hung",
            status: "empty",
          },
          { label: "Độc Lập", value: 1594, slug: "doc-lap", status: "empty" },
          { label: "Cai Bộ", value: 1597, slug: "cai-bo", status: "empty" },
          { label: "Phúc Sen", value: 1603, slug: "phuc-sen", status: "empty" },
          { label: "Chí Thảo", value: 1606, slug: "chi-thao", status: "empty" },
          { label: "Tự Do", value: 1609, slug: "tu-do", status: "empty" },
          {
            label: "Hồng Quang",
            value: 1615,
            slug: "hong-quang",
            status: "empty",
          },
          {
            label: "Ngọc Động",
            value: 1618,
            slug: "ngoc-dong",
            status: "empty",
          },
          {
            label: "Hạnh Phúc",
            value: 1624,
            slug: "hanh-phuc",
            status: "empty",
          },
          { label: "Tà Lùng", value: 1627, slug: "ta-lung", status: "empty" },
          {
            label: "Bế Văn Đàn",
            value: 1630,
            slug: "be-van-dan",
            status: "empty",
          },
          {
            label: "Cách Linh",
            value: 1636,
            slug: "cach-linh",
            status: "empty",
          },
          { label: "Đại Sơn", value: 1639, slug: "dai-son", status: "empty" },
          {
            label: "Tiên Thành",
            value: 1645,
            slug: "tien-thanh",
            status: "empty",
          },
          {
            label: "Hoà Thuận",
            value: 1648,
            slug: "hoa-thuan",
            status: "empty",
          },
          { label: "Mỹ Hưng", value: 1651, slug: "my-hung", status: "empty" },
        ],
      },
      {
        label: "Huyện Hoà An",
        value: 51,
        slug: "huyen-hoa-an",
        status: "empty",
        ward: [
          { label: "Nước Hai", value: 1654, slug: "nuoc-hai", status: "empty" },
          { label: "Dân Chủ", value: 1657, slug: "dan-chu", status: "empty" },
          { label: "Nam Tuấn", value: 1660, slug: "nam-tuan", status: "empty" },
          { label: "Đại Tiến", value: 1666, slug: "dai-tien", status: "empty" },
          { label: "Đức Long", value: 1669, slug: "duc-long", status: "empty" },
          { label: "Ngũ Lão", value: 1672, slug: "ngu-lao", status: "empty" },
          {
            label: "Trương Lương",
            value: 1675,
            slug: "truong-luong",
            status: "empty",
          },
          {
            label: "Hồng Việt",
            value: 1687,
            slug: "hong-viet",
            status: "empty",
          },
          {
            label: "Hoàng Tung",
            value: 1696,
            slug: "hoang-tung",
            status: "empty",
          },
          {
            label: "Nguyễn Huệ",
            value: 1699,
            slug: "nguyen-hue",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 1702,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Bạch Đằng",
            value: 1708,
            slug: "bach-dang",
            status: "empty",
          },
          {
            label: "Bình Dương",
            value: 1711,
            slug: "binh-duong",
            status: "empty",
          },
          { label: "Lê Chung", value: 1714, slug: "le-chung", status: "empty" },
          { label: "Hồng Nam", value: 1723, slug: "hong-nam", status: "empty" },
        ],
      },
      {
        label: "Huyện Nguyên Bình",
        value: 52,
        slug: "huyen-nguyen-binh",
        status: "empty",
        ward: [
          {
            label: "Nguyên Bình",
            value: 1726,
            slug: "nguyen-binh",
            status: "empty",
          },
          { label: "Tĩnh Túc", value: 1729, slug: "tinh-tuc", status: "empty" },
          { label: "Yên Lạc", value: 1732, slug: "yen-lac", status: "empty" },
          {
            label: "Triệu Nguyên",
            value: 1735,
            slug: "trieu-nguyen",
            status: "empty",
          },
          { label: "Ca Thành", value: 1738, slug: "ca-thanh", status: "empty" },
          { label: "Vũ Nông", value: 1744, slug: "vu-nong", status: "empty" },
          { label: "Minh Tâm", value: 1747, slug: "minh-tam", status: "empty" },
          { label: "Thể Dục", value: 1750, slug: "the-duc", status: "empty" },
          { label: "Mai Long", value: 1756, slug: "mai-long", status: "empty" },
          { label: "Vũ Minh", value: 1762, slug: "vu-minh", status: "empty" },
          { label: "Hoa Thám", value: 1765, slug: "hoa-tham", status: "empty" },
          {
            label: "Phan Thanh",
            value: 1768,
            slug: "phan-thanh",
            status: "empty",
          },
          {
            label: "Quang Thành",
            value: 1771,
            slug: "quang-thanh",
            status: "empty",
          },
          { label: "Tam Kim", value: 1774, slug: "tam-kim", status: "empty" },
          {
            label: "Thành Công",
            value: 1777,
            slug: "thanh-cong",
            status: "empty",
          },
          {
            label: "Thịnh Vượng",
            value: 1780,
            slug: "thinh-vuong",
            status: "empty",
          },
          { label: "Hưng Đạo", value: 1783, slug: "hung-dao", status: "empty" },
        ],
      },
      {
        label: "Huyện Thạch An",
        value: 53,
        slug: "huyen-thach-an",
        status: "empty",
        ward: [
          { label: "Đông Khê", value: 1786, slug: "dong-khe", status: "empty" },
          { label: "Canh Tân", value: 1789, slug: "canh-tan", status: "empty" },
          { label: "Kim Đồng", value: 1792, slug: "kim-dong", status: "empty" },
          {
            label: "Minh Khai",
            value: 1795,
            slug: "minh-khai",
            status: "empty",
          },
          {
            label: "Đức Thông",
            value: 1801,
            slug: "duc-thong",
            status: "empty",
          },
          {
            label: "Thái Cường",
            value: 1804,
            slug: "thai-cuong",
            status: "empty",
          },
          {
            label: "Vân Trình",
            value: 1807,
            slug: "van-trinh",
            status: "empty",
          },
          {
            label: "Thụy Hùng",
            value: 1810,
            slug: "thuy-hung",
            status: "empty",
          },
          {
            label: "Quang Trọng",
            value: 1813,
            slug: "quang-trong",
            status: "empty",
          },
          {
            label: "Trọng Con",
            value: 1816,
            slug: "trong-con",
            status: "empty",
          },
          { label: "Lê Lai", value: 1819, slug: "le-lai", status: "empty" },
          { label: "Đức Long", value: 1822, slug: "duc-long", status: "empty" },
          { label: "Lê Lợi", value: 1828, slug: "le-loi", status: "empty" },
          { label: "Đức Xuân", value: 1831, slug: "duc-xuan", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Bắc Kạn",
    value: 6,
    slug: "bac-kan",
    status: "empty",
    district: [
      {
        label: "Thành Phố Bắc Kạn",
        value: 58,
        slug: "thanh-pho-bac-kan",
        status: "empty",
        ward: [
          {
            label: "Nguyễn Thị Minh Khai",
            value: 1834,
            slug: "nguyen-thi-minh-khai",
            status: "empty",
          },
          { label: "Sông Cầu", value: 1837, slug: "song-cau", status: "empty" },
          { label: "Đức Xuân", value: 1840, slug: "duc-xuan", status: "empty" },
          {
            label: "Phùng Chí Kiên",
            value: 1843,
            slug: "phung-chi-kien",
            status: "empty",
          },
          {
            label: "Huyền Tụng",
            value: 1846,
            slug: "huyen-tung",
            status: "empty",
          },
          {
            label: "Dương Quang",
            value: 1849,
            slug: "duong-quang",
            status: "empty",
          },
          {
            label: "Nông Thượng",
            value: 1852,
            slug: "nong-thuong",
            status: "empty",
          },
          { label: "Xuất Hóa", value: 1855, slug: "xuat-hoa", status: "empty" },
        ],
      },
      {
        label: "Huyện Pác Nặm",
        value: 60,
        slug: "huyen-pac-nam",
        status: "empty",
        ward: [
          {
            label: "Bằng Thành",
            value: 1858,
            slug: "bang-thanh",
            status: "empty",
          },
          { label: "Nhạn Môn", value: 1861, slug: "nhan-mon", status: "empty" },
          { label: "Bộc Bố", value: 1864, slug: "boc-bo", status: "empty" },
          {
            label: "Công Bằng",
            value: 1867,
            slug: "cong-bang",
            status: "empty",
          },
          {
            label: "Giáo Hiệu",
            value: 1870,
            slug: "giao-hieu",
            status: "empty",
          },
          { label: "Xuân La", value: 1873, slug: "xuan-la", status: "empty" },
          { label: "An Thắng", value: 1876, slug: "an-thang", status: "empty" },
          { label: "Cổ Linh", value: 1879, slug: "co-linh", status: "empty" },
          {
            label: "Nghiên Loan",
            value: 1882,
            slug: "nghien-loan",
            status: "empty",
          },
          { label: "Cao Tân", value: 1885, slug: "cao-tan", status: "empty" },
        ],
      },
      {
        label: "Huyện Ba Bể",
        value: 61,
        slug: "huyen-ba-be",
        status: "empty",
        ward: [
          { label: "Chợ Rã", value: 1888, slug: "cho-ra", status: "empty" },
          {
            label: "Bành Trạch",
            value: 1891,
            slug: "banh-trach",
            status: "empty",
          },
          { label: "Phúc Lộc", value: 1894, slug: "phuc-loc", status: "empty" },
          { label: "Hà Hiệu", value: 1897, slug: "ha-hieu", status: "empty" },
          {
            label: "Cao Thượng",
            value: 1900,
            slug: "cao-thuong",
            status: "empty",
          },
          {
            label: "Khang Ninh",
            value: 1906,
            slug: "khang-ninh",
            status: "empty",
          },
          { label: "Nam Mẫu", value: 1909, slug: "nam-mau", status: "empty" },
          {
            label: "Thượng Giáo",
            value: 1912,
            slug: "thuong-giao",
            status: "empty",
          },
          { label: "Địa Linh", value: 1915, slug: "dia-linh", status: "empty" },
          {
            label: "Yến Dương",
            value: 1918,
            slug: "yen-duong",
            status: "empty",
          },
          {
            label: "Chu Hương",
            value: 1921,
            slug: "chu-huong",
            status: "empty",
          },
          {
            label: "Quảng Khê",
            value: 1924,
            slug: "quang-khe",
            status: "empty",
          },
          {
            label: "Mỹ Phương",
            value: 1927,
            slug: "my-phuong",
            status: "empty",
          },
          {
            label: "Hoàng Trĩ",
            value: 1930,
            slug: "hoang-tri",
            status: "empty",
          },
          {
            label: "Đồng Phúc",
            value: 1933,
            slug: "dong-phuc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ngân Sơn",
        value: 62,
        slug: "huyen-ngan-son",
        status: "empty",
        ward: [
          { label: "Nà Phặc", value: 1936, slug: "na-phac", status: "empty" },
          {
            label: "Thượng Ân",
            value: 1939,
            slug: "thuong-an",
            status: "empty",
          },
          { label: "Bằng Vân", value: 1942, slug: "bang-van", status: "empty" },
          { label: "Cốc Đán", value: 1945, slug: "coc-dan", status: "empty" },
          {
            label: "Trung Hoà",
            value: 1948,
            slug: "trung-hoa",
            status: "empty",
          },
          { label: "Đức Vân", value: 1951, slug: "duc-van", status: "empty" },
          { label: "Vân Tùng", value: 1954, slug: "van-tung", status: "empty" },
          {
            label: "Thượng Quan",
            value: 1957,
            slug: "thuong-quan",
            status: "empty",
          },
          { label: "Hiệp Lực", value: 1960, slug: "hiep-luc", status: "empty" },
          {
            label: "Thuần Mang",
            value: 1963,
            slug: "thuan-mang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bạch Thông",
        value: 63,
        slug: "huyen-bach-thong",
        status: "empty",
        ward: [
          {
            label: "Phủ Thông",
            value: 1969,
            slug: "phu-thong",
            status: "empty",
          },
          { label: "Vi Hương", value: 1975, slug: "vi-huong", status: "empty" },
          { label: "Sĩ Bình", value: 1978, slug: "si-binh", status: "empty" },
          { label: "Vũ Muộn", value: 1981, slug: "vu-muon", status: "empty" },
          {
            label: "Đôn Phong",
            value: 1984,
            slug: "don-phong",
            status: "empty",
          },
          { label: "Lục Bình", value: 1990, slug: "luc-binh", status: "empty" },
          { label: "Tân Tú", value: 1993, slug: "tan-tu", status: "empty" },
          {
            label: "Nguyên Phúc",
            value: 1999,
            slug: "nguyen-phuc",
            status: "empty",
          },
          { label: "Cao Sơn", value: 2002, slug: "cao-son", status: "empty" },
          { label: "Quân Hà", value: 2005, slug: "quan-ha", status: "empty" },
          {
            label: "Cẩm Giàng",
            value: 2008,
            slug: "cam-giang",
            status: "empty",
          },
          { label: "Mỹ Thanh", value: 2011, slug: "my-thanh", status: "empty" },
          {
            label: "Dương Phong",
            value: 2014,
            slug: "duong-phong",
            status: "empty",
          },
          {
            label: "Quang Thuận",
            value: 2017,
            slug: "quang-thuan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Chợ Đồn",
        value: 64,
        slug: "huyen-cho-don",
        status: "empty",
        ward: [
          {
            label: "Bằng Lũng",
            value: 2020,
            slug: "bang-lung",
            status: "empty",
          },
          { label: "Xuân Lạc", value: 2023, slug: "xuan-lac", status: "empty" },
          {
            label: "Nam Cường",
            value: 2026,
            slug: "nam-cuong",
            status: "empty",
          },
          { label: "Đồng Lạc", value: 2029, slug: "dong-lac", status: "empty" },
          { label: "Tân Lập", value: 2032, slug: "tan-lap", status: "empty" },
          { label: "Bản Thi", value: 2035, slug: "ban-thi", status: "empty" },
          {
            label: "Quảng Bạch",
            value: 2038,
            slug: "quang-bach",
            status: "empty",
          },
          {
            label: "Bằng Phúc",
            value: 2041,
            slug: "bang-phuc",
            status: "empty",
          },
          {
            label: "Yên Thịnh",
            value: 2044,
            slug: "yen-thinh",
            status: "empty",
          },
          {
            label: "Yên Thượng",
            value: 2047,
            slug: "yen-thuong",
            status: "empty",
          },
          {
            label: "Phương Viên",
            value: 2050,
            slug: "phuong-vien",
            status: "empty",
          },
          {
            label: "Ngọc Phái",
            value: 2053,
            slug: "ngoc-phai",
            status: "empty",
          },
          {
            label: "Đồng Thắng",
            value: 2059,
            slug: "dong-thang",
            status: "empty",
          },
          {
            label: "Lương Bằng",
            value: 2062,
            slug: "luong-bang",
            status: "empty",
          },
          {
            label: "Bằng Lãng",
            value: 2065,
            slug: "bang-lang",
            status: "empty",
          },
          { label: "Đại Sảo", value: 2068, slug: "dai-sao", status: "empty" },
          { label: "Nghĩa Tá", value: 2071, slug: "nghia-ta", status: "empty" },
          { label: "Yên Mỹ", value: 2077, slug: "yen-my", status: "empty" },
          {
            label: "Bình Trung",
            value: 2080,
            slug: "binh-trung",
            status: "empty",
          },
          {
            label: "Yên Phong",
            value: 2083,
            slug: "yen-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Chợ Mới",
        value: 65,
        slug: "huyen-cho-moi",
        status: "empty",
        ward: [
          { label: "Đồng Tâm", value: 2086, slug: "dong-tam", status: "empty" },
          { label: "Tân Sơn", value: 2089, slug: "tan-son", status: "empty" },
          {
            label: "Thanh Vận",
            value: 2092,
            slug: "thanh-van",
            status: "empty",
          },
          { label: "Mai Lạp", value: 2095, slug: "mai-lap", status: "empty" },
          { label: "Hoà Mục", value: 2098, slug: "hoa-muc", status: "empty" },
          {
            label: "Thanh Mai",
            value: 2101,
            slug: "thanh-mai",
            status: "empty",
          },
          { label: "Cao Kỳ", value: 2104, slug: "cao-ky", status: "empty" },
          { label: "Nông Hạ", value: 2107, slug: "nong-ha", status: "empty" },
          { label: "Yên Cư", value: 2110, slug: "yen-cu", status: "empty" },
          {
            label: "Thanh Thịnh",
            value: 2113,
            slug: "thanh-thinh",
            status: "empty",
          },
          { label: "Yên Hân", value: 2116, slug: "yen-han", status: "empty" },
          { label: "Như Cố", value: 2122, slug: "nhu-co", status: "empty" },
          { label: "Bình Văn", value: 2125, slug: "binh-van", status: "empty" },
          {
            label: "Quảng Chu",
            value: 2131,
            slug: "quang-chu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Na Rì",
        value: 66,
        slug: "huyen-na-ri",
        status: "empty",
        ward: [
          { label: "Văn Vũ", value: 2137, slug: "van-vu", status: "empty" },
          { label: "Văn Lang", value: 2140, slug: "van-lang", status: "empty" },
          {
            label: "Lương Thượng",
            value: 2143,
            slug: "luong-thuong",
            status: "empty",
          },
          { label: "Kim Hỷ", value: 2146, slug: "kim-hy", status: "empty" },
          {
            label: "Cường Lợi",
            value: 2152,
            slug: "cuong-loi",
            status: "empty",
          },
          { label: "Yến Lạc", value: 2155, slug: "yen-lac", status: "empty" },
          { label: "Kim Lư", value: 2158, slug: "kim-lu", status: "empty" },
          {
            label: "Sơn Thành",
            value: 2161,
            slug: "son-thanh",
            status: "empty",
          },
          { label: "Văn Minh", value: 2170, slug: "van-minh", status: "empty" },
          { label: "Côn Minh", value: 2173, slug: "con-minh", status: "empty" },
          { label: "Cư Lễ", value: 2176, slug: "cu-le", status: "empty" },
          { label: "Trần Phú", value: 2179, slug: "tran-phu", status: "empty" },
          {
            label: "Quang Phong",
            value: 2185,
            slug: "quang-phong",
            status: "empty",
          },
          {
            label: "Dương Sơn",
            value: 2188,
            slug: "duong-son",
            status: "empty",
          },
          {
            label: "Xuân Dương",
            value: 2191,
            slug: "xuan-duong",
            status: "empty",
          },
          { label: "Đổng Xá", value: 2194, slug: "dong-xa", status: "empty" },
          {
            label: "Liêm Thuỷ",
            value: 2197,
            slug: "liem-thuy",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Tuyên Quang",
    value: 8,
    slug: "tuyen-quang",
    status: "empty",
    district: [
      {
        label: "Thành phố Tuyên Quang",
        value: 70,
        slug: "thanh-pho-tuyen-quang",
        status: "empty",
        ward: [
          {
            label: "Phan Thiết",
            value: 2200,
            slug: "phan-thiet",
            status: "empty",
          },
          {
            label: "Minh Xuân",
            value: 2203,
            slug: "minh-xuan",
            status: "empty",
          },
          {
            label: "Tân Quang",
            value: 2206,
            slug: "tan-quang",
            status: "empty",
          },
          { label: "Tràng Đà", value: 2209, slug: "trang-da", status: "empty" },
          {
            label: "Nông Tiến",
            value: 2212,
            slug: "nong-tien",
            status: "empty",
          },
          { label: "Ỷ La", value: 2215, slug: "y-la", status: "empty" },
          { label: "Tân Hà", value: 2216, slug: "tan-ha", status: "empty" },
          {
            label: "Hưng Thành",
            value: 2218,
            slug: "hung-thanh",
            status: "empty",
          },
          { label: "Kim Phú", value: 2497, slug: "kim-phu", status: "empty" },
          { label: "An Khang", value: 2503, slug: "an-khang", status: "empty" },
          { label: "Mỹ Lâm", value: 2509, slug: "my-lam", status: "empty" },
          { label: "An Tường", value: 2512, slug: "an-tuong", status: "empty" },
          {
            label: "Lưỡng Vượng",
            value: 2515,
            slug: "luong-vuong",
            status: "empty",
          },
          {
            label: "Thái Long",
            value: 2521,
            slug: "thai-long",
            status: "empty",
          },
          { label: "Đội Cấn", value: 2524, slug: "doi-can", status: "empty" },
        ],
      },
      {
        label: "Huyện Lâm Bình",
        value: 71,
        slug: "huyen-lam-binh",
        status: "empty",
        ward: [
          { label: "Phúc Yên", value: 2233, slug: "phuc-yen", status: "empty" },
          { label: "Xuân Lập", value: 2242, slug: "xuan-lap", status: "empty" },
          { label: "Khuôn Hà", value: 2251, slug: "khuon-ha", status: "empty" },
          { label: "Lăng Can", value: 2266, slug: "lang-can", status: "empty" },
          {
            label: "Thượng Lâm",
            value: 2269,
            slug: "thuong-lam",
            status: "empty",
          },
          { label: "Bình An", value: 2290, slug: "binh-an", status: "empty" },
          {
            label: "Hồng Quang",
            value: 2293,
            slug: "hong-quang",
            status: "empty",
          },
          { label: "Thổ Bình", value: 2296, slug: "tho-binh", status: "empty" },
          { label: "Phúc Sơn", value: 2299, slug: "phuc-son", status: "empty" },
          {
            label: "Minh Quang",
            value: 2302,
            slug: "minh-quang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Na Hang",
        value: 72,
        slug: "huyen-na-hang",
        status: "empty",
        ward: [
          { label: "Na Hang", value: 2221, slug: "na-hang", status: "empty" },
          {
            label: "Sinh Long",
            value: 2227,
            slug: "sinh-long",
            status: "empty",
          },
          {
            label: "Thượng Giáp",
            value: 2230,
            slug: "thuong-giap",
            status: "empty",
          },
          {
            label: "Thượng Nông",
            value: 2239,
            slug: "thuong-nong",
            status: "empty",
          },
          { label: "Côn Lôn", value: 2245, slug: "con-lon", status: "empty" },
          { label: "Yên Hoa", value: 2248, slug: "yen-hoa", status: "empty" },
          {
            label: "Hồng Thái",
            value: 2254,
            slug: "hong-thai",
            status: "empty",
          },
          { label: "Đà Vị", value: 2260, slug: "da-vi", status: "empty" },
          {
            label: "Khau Tinh",
            value: 2263,
            slug: "khau-tinh",
            status: "empty",
          },
          { label: "Sơn Phú", value: 2275, slug: "son-phu", status: "empty" },
          { label: "Năng Khả", value: 2281, slug: "nang-kha", status: "empty" },
          {
            label: "Thanh Tương",
            value: 2284,
            slug: "thanh-tuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Chiêm Hóa",
        value: 73,
        slug: "huyen-chiem-hoa",
        status: "empty",
        ward: [
          { label: "Vĩnh Lộc", value: 2287, slug: "vinh-loc", status: "empty" },
          { label: "Trung Hà", value: 2305, slug: "trung-ha", status: "empty" },
          { label: "Tân Mỹ", value: 2308, slug: "tan-my", status: "empty" },
          { label: "Hà Lang", value: 2311, slug: "ha-lang", status: "empty" },
          { label: "Hùng Mỹ", value: 2314, slug: "hung-my", status: "empty" },
          { label: "Yên Lập", value: 2317, slug: "yen-lap", status: "empty" },
          { label: "Tân An", value: 2320, slug: "tan-an", status: "empty" },
          { label: "Bình Phú", value: 2323, slug: "binh-phu", status: "empty" },
          {
            label: "Xuân Quang",
            value: 2326,
            slug: "xuan-quang",
            status: "empty",
          },
          { label: "Ngọc Hội", value: 2329, slug: "ngoc-hoi", status: "empty" },
          { label: "Phú Bình", value: 2332, slug: "phu-binh", status: "empty" },
          { label: "Hòa Phú", value: 2335, slug: "hoa-phu", status: "empty" },
          {
            label: "Phúc Thịnh",
            value: 2338,
            slug: "phuc-thinh",
            status: "empty",
          },
          { label: "Kiên Đài", value: 2341, slug: "kien-dai", status: "empty" },
          {
            label: "Tân Thịnh",
            value: 2344,
            slug: "tan-thinh",
            status: "empty",
          },
          {
            label: "Trung Hòa",
            value: 2347,
            slug: "trung-hoa",
            status: "empty",
          },
          { label: "Kim Bình", value: 2350, slug: "kim-binh", status: "empty" },
          { label: "Hòa An", value: 2353, slug: "hoa-an", status: "empty" },
          {
            label: "Vinh Quang",
            value: 2356,
            slug: "vinh-quang",
            status: "empty",
          },
          { label: "Tri Phú", value: 2359, slug: "tri-phu", status: "empty" },
          { label: "Nhân Lý", value: 2362, slug: "nhan-ly", status: "empty" },
          {
            label: "Yên Nguyên",
            value: 2365,
            slug: "yen-nguyen",
            status: "empty",
          },
          { label: "Linh Phú", value: 2368, slug: "linh-phu", status: "empty" },
          {
            label: "Bình Nhân",
            value: 2371,
            slug: "binh-nhan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hàm Yên",
        value: 74,
        slug: "huyen-ham-yen",
        status: "empty",
        ward: [
          { label: "Tân Yên", value: 2374, slug: "tan-yen", status: "empty" },
          {
            label: "Yên Thuận",
            value: 2377,
            slug: "yen-thuan",
            status: "empty",
          },
          { label: "Bạch Xa", value: 2380, slug: "bach-xa", status: "empty" },
          {
            label: "Minh Khương",
            value: 2383,
            slug: "minh-khuong",
            status: "empty",
          },
          { label: "Yên Lâm", value: 2386, slug: "yen-lam", status: "empty" },
          { label: "Minh Dân", value: 2389, slug: "minh-dan", status: "empty" },
          { label: "Phù Lưu", value: 2392, slug: "phu-luu", status: "empty" },
          {
            label: "Minh Hương",
            value: 2395,
            slug: "minh-huong",
            status: "empty",
          },
          { label: "Yên Phú", value: 2398, slug: "yen-phu", status: "empty" },
          {
            label: "Tân Thành",
            value: 2401,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Bình Xa", value: 2404, slug: "binh-xa", status: "empty" },
          { label: "Thái Sơn", value: 2407, slug: "thai-son", status: "empty" },
          { label: "Nhân Mục", value: 2410, slug: "nhan-muc", status: "empty" },
          {
            label: "Thành Long",
            value: 2413,
            slug: "thanh-long",
            status: "empty",
          },
          { label: "Bằng Cốc", value: 2416, slug: "bang-coc", status: "empty" },
          { label: "Thái Hòa", value: 2419, slug: "thai-hoa", status: "empty" },
          { label: "Đức Ninh", value: 2422, slug: "duc-ninh", status: "empty" },
          { label: "Hùng Đức", value: 2425, slug: "hung-duc", status: "empty" },
        ],
      },
      {
        label: "Huyện Yên Sơn",
        value: 75,
        slug: "huyen-yen-son",
        status: "empty",
        ward: [
          { label: "Quí Quân", value: 2431, slug: "qui-quan", status: "empty" },
          { label: "Lực Hành", value: 2434, slug: "luc-hanh", status: "empty" },
          {
            label: "Kiến Thiết",
            value: 2437,
            slug: "kien-thiet",
            status: "empty",
          },
          {
            label: "Trung Minh",
            value: 2440,
            slug: "trung-minh",
            status: "empty",
          },
          {
            label: "Chiêu Yên",
            value: 2443,
            slug: "chieu-yen",
            status: "empty",
          },
          {
            label: "Trung Trực",
            value: 2446,
            slug: "trung-truc",
            status: "empty",
          },
          { label: "Xuân Vân", value: 2449, slug: "xuan-van", status: "empty" },
          {
            label: "Phúc Ninh",
            value: 2452,
            slug: "phuc-ninh",
            status: "empty",
          },
          { label: "Hùng Lợi", value: 2455, slug: "hung-loi", status: "empty" },
          {
            label: "Trung Sơn",
            value: 2458,
            slug: "trung-son",
            status: "empty",
          },
          { label: "Tân Tiến", value: 2461, slug: "tan-tien", status: "empty" },
          { label: "Tứ Quận", value: 2464, slug: "tu-quan", status: "empty" },
          { label: "Đạo Viện", value: 2467, slug: "dao-vien", status: "empty" },
          { label: "Tân Long", value: 2470, slug: "tan-long", status: "empty" },
          { label: "Yên Sơn", value: 2473, slug: "yen-son", status: "empty" },
          { label: "Kim Quan", value: 2476, slug: "kim-quan", status: "empty" },
          {
            label: "Lang Quán",
            value: 2479,
            slug: "lang-quan",
            status: "empty",
          },
          {
            label: "Phú Thịnh",
            value: 2482,
            slug: "phu-thinh",
            status: "empty",
          },
          { label: "Công Đa", value: 2485, slug: "cong-da", status: "empty" },
          {
            label: "Trung Môn",
            value: 2488,
            slug: "trung-mon",
            status: "empty",
          },
          { label: "Chân Sơn", value: 2491, slug: "chan-son", status: "empty" },
          {
            label: "Thái Bình",
            value: 2494,
            slug: "thai-binh",
            status: "empty",
          },
          { label: "Tiến Bộ", value: 2500, slug: "tien-bo", status: "empty" },
          { label: "Mỹ Bằng", value: 2506, slug: "my-bang", status: "empty" },
          {
            label: "Hoàng Khai",
            value: 2518,
            slug: "hoang-khai",
            status: "empty",
          },
          { label: "Nhữ Hán", value: 2527, slug: "nhu-han", status: "empty" },
          { label: "Nhữ Khê", value: 2530, slug: "nhu-khe", status: "empty" },
          { label: "Đội Bình", value: 2533, slug: "doi-binh", status: "empty" },
        ],
      },
      {
        label: "Huyện Sơn Dương",
        value: 76,
        slug: "huyen-son-duong",
        status: "empty",
        ward: [
          {
            label: "Sơn Dương",
            value: 2536,
            slug: "son-duong",
            status: "empty",
          },
          {
            label: "Trung Yên",
            value: 2539,
            slug: "trung-yen",
            status: "empty",
          },
          {
            label: "Minh Thanh",
            value: 2542,
            slug: "minh-thanh",
            status: "empty",
          },
          { label: "Tân Trào", value: 2545, slug: "tan-trao", status: "empty" },
          { label: "Vĩnh Lợi", value: 2548, slug: "vinh-loi", status: "empty" },
          {
            label: "Thượng Ấm",
            value: 2551,
            slug: "thuong-am",
            status: "empty",
          },
          { label: "Bình Yên", value: 2554, slug: "binh-yen", status: "empty" },
          {
            label: "Lương Thiện",
            value: 2557,
            slug: "luong-thien",
            status: "empty",
          },
          { label: "Tú Thịnh", value: 2560, slug: "tu-thinh", status: "empty" },
          { label: "Cấp Tiến", value: 2563, slug: "cap-tien", status: "empty" },
          {
            label: "Hợp Thành",
            value: 2566,
            slug: "hop-thanh",
            status: "empty",
          },
          { label: "Phúc Ứng", value: 2569, slug: "phuc-ung", status: "empty" },
          { label: "Đông Thọ", value: 2572, slug: "dong-tho", status: "empty" },
          {
            label: "Kháng Nhật",
            value: 2575,
            slug: "khang-nhat",
            status: "empty",
          },
          { label: "Hợp Hòa", value: 2578, slug: "hop-hoa", status: "empty" },
          {
            label: "Quyết Thắng",
            value: 2584,
            slug: "quyet-thang",
            status: "empty",
          },
          { label: "Đồng Quý", value: 2587, slug: "dong-quy", status: "empty" },
          {
            label: "Tân Thanh",
            value: 2590,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Văn Phú", value: 2596, slug: "van-phu", status: "empty" },
          {
            label: "Chi Thiết",
            value: 2599,
            slug: "chi-thiet",
            status: "empty",
          },
          { label: "Đông Lợi", value: 2602, slug: "dong-loi", status: "empty" },
          { label: "Thiện Kế", value: 2605, slug: "thien-ke", status: "empty" },
          { label: "Hồng Sơn", value: 2608, slug: "hong-son", status: "empty" },
          {
            label: "Phú Lương",
            value: 2611,
            slug: "phu-luong",
            status: "empty",
          },
          { label: "Ninh Lai", value: 2614, slug: "ninh-lai", status: "empty" },
          { label: "Đại Phú", value: 2617, slug: "dai-phu", status: "empty" },
          { label: "Sơn Nam", value: 2620, slug: "son-nam", status: "empty" },
          { label: "Hào Phú", value: 2623, slug: "hao-phu", status: "empty" },
          { label: "Tam Đa", value: 2626, slug: "tam-da", status: "empty" },
          {
            label: "Trường Sinh",
            value: 2632,
            slug: "truong-sinh",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Lào Cai",
    value: 10,
    slug: "lao-cai",
    status: "filled",
    district: [
      {
        label: "Thành phố Lào Cai",
        value: 80,
        slug: "thanh-pho-lao-cai",
        status: "filled",
        ward: [
          {
            label: "Duyên Hải",
            value: 2635,
            slug: "duyen-hai",
            status: "empty",
          },
          { label: "Lào Cai", value: 2641, slug: "lao-cai", status: "empty" },
          { label: "Cốc Lếu", value: 2644, slug: "coc-leu", status: "empty" },
          { label: "Kim Tân", value: 2647, slug: "kim-tan", status: "empty" },
          { label: "Bắc Lệnh", value: 2650, slug: "bac-lenh", status: "empty" },
          { label: "Pom Hán", value: 2653, slug: "pom-han", status: "empty" },
          {
            label: "Xuân Tăng",
            value: 2656,
            slug: "xuan-tang",
            status: "empty",
          },
          {
            label: "Bình Minh",
            value: 2658,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Thống Nhất",
            value: 2659,
            slug: "thong-nhat",
            status: "empty",
          },
          {
            label: "Đồng Tuyển",
            value: 2662,
            slug: "dong-tuyen",
            status: "empty",
          },
          { label: "Vạn Hoà", value: 2665, slug: "van-hoa", status: "empty" },
          {
            label: "Bắc Cường",
            value: 2668,
            slug: "bac-cuong",
            status: "filled",
          },
          {
            label: "Nam Cường",
            value: 2671,
            slug: "nam-cuong",
            status: "empty",
          },
          {
            label: "Cam Đường",
            value: 2674,
            slug: "cam-duong",
            status: "empty",
          },
          { label: "Tả Phời", value: 2677, slug: "ta-phoi", status: "empty" },
          {
            label: "Hợp Thành",
            value: 2680,
            slug: "hop-thanh",
            status: "empty",
          },
          { label: "Cốc San", value: 2746, slug: "coc-san", status: "empty" },
        ],
      },
      {
        label: "Huyện Bát Xát",
        value: 82,
        slug: "huyen-bat-xat",
        status: "empty",
        ward: [
          { label: "Bát Xát", value: 2683, slug: "bat-xat", status: "empty" },
          {
            label: "A Mú Sung",
            value: 2686,
            slug: "a-mu-sung",
            status: "empty",
          },
          { label: "Nậm Chạc", value: 2689, slug: "nam-chac", status: "empty" },
          { label: "A Lù", value: 2692, slug: "a-lu", status: "empty" },
          {
            label: "Trịnh Tường",
            value: 2695,
            slug: "trinh-tuong",
            status: "empty",
          },
          { label: "Y Tý", value: 2701, slug: "y-ty", status: "empty" },
          { label: "Cốc Mỳ", value: 2704, slug: "coc-my", status: "empty" },
          { label: "Dền Sáng", value: 2707, slug: "den-sang", status: "empty" },
          { label: "Bản Vược", value: 2710, slug: "ban-vuoc", status: "empty" },
          {
            label: "Sàng Ma Sáo",
            value: 2713,
            slug: "sang-ma-sao",
            status: "empty",
          },
          { label: "Bản Qua", value: 2716, slug: "ban-qua", status: "empty" },
          { label: "Mường Vi", value: 2719, slug: "muong-vi", status: "empty" },
          {
            label: "Dền Thàng",
            value: 2722,
            slug: "den-thang",
            status: "empty",
          },
          { label: "Bản Xèo", value: 2725, slug: "ban-xeo", status: "empty" },
          {
            label: "Mường Hum",
            value: 2728,
            slug: "muong-hum",
            status: "empty",
          },
          {
            label: "Trung Lèng Hồ",
            value: 2731,
            slug: "trung-leng-ho",
            status: "empty",
          },
          {
            label: "Quang Kim",
            value: 2734,
            slug: "quang-kim",
            status: "empty",
          },
          { label: "Pa Cheo", value: 2737, slug: "pa-cheo", status: "empty" },
          { label: "Nậm Pung", value: 2740, slug: "nam-pung", status: "empty" },
          {
            label: "Phìn Ngan",
            value: 2743,
            slug: "phin-ngan",
            status: "empty",
          },
          {
            label: "Tòng Sành",
            value: 2749,
            slug: "tong-sanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Mường Khương",
        value: 83,
        slug: "huyen-muong-khuong",
        status: "empty",
        ward: [
          { label: "Pha Long", value: 2752, slug: "pha-long", status: "empty" },
          {
            label: "Tả Ngải Chồ",
            value: 2755,
            slug: "ta-ngai-cho",
            status: "empty",
          },
          {
            label: "Tung Chung Phố",
            value: 2758,
            slug: "tung-chung-pho",
            status: "empty",
          },
          {
            label: "Mường Khương",
            value: 2761,
            slug: "muong-khuong",
            status: "empty",
          },
          { label: "Dìn Chin", value: 2764, slug: "din-chin", status: "empty" },
          {
            label: "Tả Gia Khâu",
            value: 2767,
            slug: "ta-gia-khau",
            status: "empty",
          },
          { label: "Nậm Chảy", value: 2770, slug: "nam-chay", status: "empty" },
          { label: "Nấm Lư", value: 2773, slug: "nam-lu", status: "empty" },
          {
            label: "Lùng Khấu Nhin",
            value: 2776,
            slug: "lung-khau-nhin",
            status: "empty",
          },
          {
            label: "Thanh Bình",
            value: 2779,
            slug: "thanh-binh",
            status: "empty",
          },
          { label: "Cao Sơn", value: 2782, slug: "cao-son", status: "empty" },
          { label: "Lùng Vai", value: 2785, slug: "lung-vai", status: "empty" },
          { label: "Bản Lầu", value: 2788, slug: "ban-lau", status: "empty" },
          {
            label: "La Pan Tẩn",
            value: 2791,
            slug: "la-pan-tan",
            status: "empty",
          },
          { label: "Tả Thàng", value: 2794, slug: "ta-thang", status: "empty" },
          { label: "Bản Sen", value: 2797, slug: "ban-sen", status: "empty" },
        ],
      },
      {
        label: "Huyện Si Ma Cai",
        value: 84,
        slug: "huyen-si-ma-cai",
        status: "empty",
        ward: [
          { label: "Nàn Sán", value: 2800, slug: "nan-san", status: "empty" },
          {
            label: "Thào Chư Phìn",
            value: 2803,
            slug: "thao-chu-phin",
            status: "empty",
          },
          { label: "Bản Mế", value: 2806, slug: "ban-me", status: "empty" },
          {
            label: "Si Ma Cai",
            value: 2809,
            slug: "si-ma-cai",
            status: "empty",
          },
          { label: "Sán Chải", value: 2812, slug: "san-chai", status: "empty" },
          {
            label: "Lùng Thẩn",
            value: 2818,
            slug: "lung-than",
            status: "empty",
          },
          { label: "Cán Cấu", value: 2821, slug: "can-cau", status: "empty" },
          {
            label: "Sín Chéng",
            value: 2824,
            slug: "sin-cheng",
            status: "empty",
          },
          {
            label: "Quan Hồ Thẩn",
            value: 2827,
            slug: "quan-ho-than",
            status: "empty",
          },
          { label: "Nàn Xín", value: 2836, slug: "nan-xin", status: "empty" },
        ],
      },
      {
        label: "Huyện Bắc Hà",
        value: 85,
        slug: "huyen-bac-ha",
        status: "empty",
        ward: [
          { label: "Bắc Hà", value: 2839, slug: "bac-ha", status: "empty" },
          { label: "Lùng Cải", value: 2842, slug: "lung-cai", status: "empty" },
          {
            label: "Lùng Phình",
            value: 2848,
            slug: "lung-phinh",
            status: "empty",
          },
          {
            label: "Tả Van Chư",
            value: 2851,
            slug: "ta-van-chu",
            status: "empty",
          },
          { label: "Tả Củ Tỷ", value: 2854, slug: "ta-cu-ty", status: "empty" },
          {
            label: "Thải Giàng Phố",
            value: 2857,
            slug: "thai-giang-pho",
            status: "empty",
          },
          {
            label: "Hoàng Thu Phố",
            value: 2863,
            slug: "hoang-thu-pho",
            status: "empty",
          },
          { label: "Bản Phố", value: 2866, slug: "ban-pho", status: "empty" },
          { label: "Bản Liền", value: 2869, slug: "ban-lien", status: "empty" },
          { label: "Na Hối", value: 2875, slug: "na-hoi", status: "empty" },
          { label: "Cốc Ly", value: 2878, slug: "coc-ly", status: "empty" },
          { label: "Nậm Mòn", value: 2881, slug: "nam-mon", status: "empty" },
          { label: "Nậm Đét", value: 2884, slug: "nam-det", status: "empty" },
          {
            label: "Nậm Khánh",
            value: 2887,
            slug: "nam-khanh",
            status: "empty",
          },
          { label: "Bảo Nhai", value: 2890, slug: "bao-nhai", status: "empty" },
          { label: "Nậm Lúc", value: 2893, slug: "nam-luc", status: "empty" },
          { label: "Cốc Lầu", value: 2896, slug: "coc-lau", status: "empty" },
          { label: "Bản Cái", value: 2899, slug: "ban-cai", status: "empty" },
        ],
      },
      {
        label: "Huyện Bảo Thắng",
        value: 86,
        slug: "huyen-bao-thang",
        status: "empty",
        ward: [
          {
            label: "N.T Phong Hải",
            value: 2902,
            slug: "n.t-phong-hai",
            status: "empty",
          },
          { label: "Phố Lu", value: 2905, slug: "pho-lu", status: "empty" },
          {
            label: "Tằng Loỏng",
            value: 2908,
            slug: "tang-loong",
            status: "empty",
          },
          {
            label: "Bản Phiệt",
            value: 2911,
            slug: "ban-phiet",
            status: "empty",
          },
          { label: "Bản Cầm", value: 2914, slug: "ban-cam", status: "empty" },
          {
            label: "Thái Niên",
            value: 2917,
            slug: "thai-nien",
            status: "empty",
          },
          {
            label: "Phong Niên",
            value: 2920,
            slug: "phong-nien",
            status: "empty",
          },
          { label: "Gia Phú", value: 2923, slug: "gia-phu", status: "empty" },
          {
            label: "Xuân Quang",
            value: 2926,
            slug: "xuan-quang",
            status: "empty",
          },
          { label: "Sơn Hải", value: 2929, slug: "son-hai", status: "empty" },
          {
            label: "Xuân Giao",
            value: 2932,
            slug: "xuan-giao",
            status: "empty",
          },
          {
            label: "Trì Quang",
            value: 2935,
            slug: "tri-quang",
            status: "empty",
          },
          { label: "Sơn Hà", value: 2938, slug: "son-ha", status: "empty" },
          {
            label: "Phú Nhuận",
            value: 2944,
            slug: "phu-nhuan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bảo Yên",
        value: 87,
        slug: "huyen-bao-yen",
        status: "empty",
        ward: [
          { label: "Phố Ràng", value: 2947, slug: "pho-rang", status: "empty" },
          { label: "Tân Tiến", value: 2950, slug: "tan-tien", status: "empty" },
          { label: "Nghĩa Đô", value: 2953, slug: "nghia-do", status: "empty" },
          { label: "Vĩnh Yên", value: 2956, slug: "vinh-yen", status: "empty" },
          {
            label: "Điện Quan",
            value: 2959,
            slug: "dien-quan",
            status: "empty",
          },
          { label: "Xuân Hoà", value: 2962, slug: "xuan-hoa", status: "empty" },
          {
            label: "Tân Dương",
            value: 2965,
            slug: "tan-duong",
            status: "empty",
          },
          {
            label: "Thượng Hà",
            value: 2968,
            slug: "thuong-ha",
            status: "empty",
          },
          { label: "Kim Sơn", value: 2971, slug: "kim-son", status: "empty" },
          { label: "Cam Cọn", value: 2974, slug: "cam-con", status: "empty" },
          { label: "Minh Tân", value: 2977, slug: "minh-tan", status: "empty" },
          {
            label: "Xuân Thượng",
            value: 2980,
            slug: "xuan-thuong",
            status: "empty",
          },
          {
            label: "Việt Tiến",
            value: 2983,
            slug: "viet-tien",
            status: "empty",
          },
          { label: "Yên Sơn", value: 2986, slug: "yen-son", status: "empty" },
          { label: "Bảo Hà", value: 2989, slug: "bao-ha", status: "empty" },
          {
            label: "Lương Sơn",
            value: 2992,
            slug: "luong-son",
            status: "empty",
          },
          {
            label: "Phúc Khánh",
            value: 2998,
            slug: "phuc-khanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Sa Pa",
        value: 88,
        slug: "thi-xa-sa-pa",
        status: "empty",
        ward: [
          { label: "Sa Pa", value: 3001, slug: "sa-pa", status: "empty" },
          { label: "Sa Pả", value: 3002, slug: "sa-pa", status: "empty" },
          { label: "Ô Quý Hồ", value: 3003, slug: "o-quy-ho", status: "empty" },
          {
            label: "Ngũ Chỉ Sơn",
            value: 3004,
            slug: "ngu-chi-son",
            status: "empty",
          },
          {
            label: "Phan Si Păng",
            value: 3006,
            slug: "phan-si-pang",
            status: "empty",
          },
          {
            label: "Trung Chải",
            value: 3010,
            slug: "trung-chai",
            status: "empty",
          },
          { label: "Tả Phìn", value: 3013, slug: "ta-phin", status: "empty" },
          { label: "Hàm Rồng", value: 3016, slug: "ham-rong", status: "empty" },
          {
            label: "Hoàng Liên",
            value: 3019,
            slug: "hoang-lien",
            status: "empty",
          },
          {
            label: "Thanh Bình",
            value: 3022,
            slug: "thanh-binh",
            status: "empty",
          },
          { label: "Cầu Mây", value: 3028, slug: "cau-may", status: "empty" },
          {
            label: "Mường Hoa",
            value: 3037,
            slug: "muong-hoa",
            status: "empty",
          },
          { label: "Tả Van", value: 3040, slug: "ta-van", status: "empty" },
          { label: "Mường Bo", value: 3043, slug: "muong-bo", status: "empty" },
          { label: "Bản Hồ", value: 3046, slug: "ban-ho", status: "empty" },
          {
            label: "Liên Minh",
            value: 3052,
            slug: "lien-minh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Văn Bàn",
        value: 89,
        slug: "huyen-van-ban",
        status: "empty",
        ward: [
          {
            label: "Khánh Yên",
            value: 3055,
            slug: "khanh-yen",
            status: "empty",
          },
          { label: "Võ Lao", value: 3061, slug: "vo-lao", status: "empty" },
          { label: "Sơn Thuỷ", value: 3064, slug: "son-thuy", status: "empty" },
          { label: "Nậm Mả", value: 3067, slug: "nam-ma", status: "empty" },
          {
            label: "Tân Thượng",
            value: 3070,
            slug: "tan-thuong",
            status: "empty",
          },
          { label: "Nậm Rạng", value: 3073, slug: "nam-rang", status: "empty" },
          { label: "Nậm Chầy", value: 3076, slug: "nam-chay", status: "empty" },
          { label: "Tân An", value: 3079, slug: "tan-an", status: "empty" },
          {
            label: "Khánh Yên Thượng",
            value: 3082,
            slug: "khanh-yen-thuong",
            status: "empty",
          },
          { label: "Nậm Xé", value: 3085, slug: "nam-xe", status: "empty" },
          {
            label: "Dần Thàng",
            value: 3088,
            slug: "dan-thang",
            status: "empty",
          },
          {
            label: "Chiềng Ken",
            value: 3091,
            slug: "chieng-ken",
            status: "empty",
          },
          {
            label: "Làng Giàng",
            value: 3094,
            slug: "lang-giang",
            status: "empty",
          },
          { label: "Hoà Mạc", value: 3097, slug: "hoa-mac", status: "empty" },
          {
            label: "Khánh Yên Trung",
            value: 3100,
            slug: "khanh-yen-trung",
            status: "empty",
          },
          {
            label: "Khánh Yên Hạ",
            value: 3103,
            slug: "khanh-yen-ha",
            status: "empty",
          },
          {
            label: "Dương Quỳ",
            value: 3106,
            slug: "duong-quy",
            status: "empty",
          },
          { label: "Nậm Tha", value: 3109, slug: "nam-tha", status: "empty" },
          {
            label: "Minh Lương",
            value: 3112,
            slug: "minh-luong",
            status: "empty",
          },
          {
            label: "Thẩm Dương",
            value: 3115,
            slug: "tham-duong",
            status: "empty",
          },
          { label: "Liêm Phú", value: 3118, slug: "liem-phu", status: "empty" },
          { label: "Nậm Xây", value: 3121, slug: "nam-xay", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Điện Biên",
    value: 11,
    slug: "dien-bien",
    status: "filled",
    district: [
      {
        label: "Thành phố Điện Biên Phủ",
        value: 94,
        slug: "thanh-pho-dien-bien-phu",
        status: "filled",
        ward: [
          {
            label: "Noong Bua",
            value: 3124,
            slug: "noong-bua",
            status: "empty",
          },
          { label: "Him Lam", value: 3127, slug: "him-lam", status: "empty" },
          {
            label: "Thanh Bình",
            value: 3130,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Tân Thanh",
            value: 3133,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Mường Thanh",
            value: 3136,
            slug: "muong-thanh",
            status: "filled",
          },
          {
            label: "Nam Thanh",
            value: 3139,
            slug: "nam-thanh",
            status: "empty",
          },
          {
            label: "Thanh Trường",
            value: 3142,
            slug: "thanh-truong",
            status: "empty",
          },
          {
            label: "Thanh Minh",
            value: 3145,
            slug: "thanh-minh",
            status: "empty",
          },
          { label: "Nà Tấu", value: 3316, slug: "na-tau", status: "empty" },
          { label: "Nà Nhạn", value: 3317, slug: "na-nhan", status: "empty" },
          {
            label: "Mường Phăng",
            value: 3325,
            slug: "muong-phang",
            status: "empty",
          },
          {
            label: "Pá Khoang",
            value: 3326,
            slug: "pa-khoang",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Mường Lay",
        value: 95,
        slug: "thi-xa-muong-lay",
        status: "empty",
        ward: [
          { label: "Sông Đà", value: 3148, slug: "song-da", status: "empty" },
          { label: "Na Lay", value: 3151, slug: "na-lay", status: "empty" },
          { label: "Lay Nưa", value: 3184, slug: "lay-nua", status: "empty" },
        ],
      },
      {
        label: "Huyện Mường Nhé",
        value: 96,
        slug: "huyen-muong-nhe",
        status: "empty",
        ward: [
          { label: "Sín Thầu", value: 3154, slug: "sin-thau", status: "empty" },
          {
            label: "Sen Thượng",
            value: 3155,
            slug: "sen-thuong",
            status: "empty",
          },
          {
            label: "Chung Chải",
            value: 3157,
            slug: "chung-chai",
            status: "empty",
          },
          {
            label: "Leng Su Sìn",
            value: 3158,
            slug: "leng-su-sin",
            status: "empty",
          },
          { label: "Pá Mỳ", value: 3159, slug: "pa-my", status: "empty" },
          {
            label: "Mường Nhé",
            value: 3160,
            slug: "muong-nhe",
            status: "empty",
          },
          { label: "Nậm Vì", value: 3161, slug: "nam-vi", status: "empty" },
          { label: "Nậm Kè", value: 3162, slug: "nam-ke", status: "empty" },
          {
            label: "Mường Toong",
            value: 3163,
            slug: "muong-toong",
            status: "empty",
          },
          {
            label: "Quảng Lâm",
            value: 3164,
            slug: "quang-lam",
            status: "empty",
          },
          {
            label: "Huổi Lếnh",
            value: 3177,
            slug: "huoi-lenh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Mường Chà",
        value: 97,
        slug: "huyen-muong-cha",
        status: "empty",
        ward: [
          {
            label: "Mường Chà",
            value: 3172,
            slug: "muong-cha",
            status: "empty",
          },
          { label: "Xá Tổng", value: 3178, slug: "xa-tong", status: "empty" },
          {
            label: "Mường Tùng",
            value: 3181,
            slug: "muong-tung",
            status: "empty",
          },
          { label: "Hừa Ngài", value: 3190, slug: "hua-ngai", status: "empty" },
          { label: "Huổi Mí", value: 3191, slug: "huoi-mi", status: "empty" },
          { label: "Pa Ham", value: 3193, slug: "pa-ham", status: "empty" },
          { label: "Nậm Nèn", value: 3194, slug: "nam-nen", status: "empty" },
          {
            label: "Huổi Lèng",
            value: 3196,
            slug: "huoi-leng",
            status: "empty",
          },
          { label: "Sa Lông", value: 3197, slug: "sa-long", status: "empty" },
          {
            label: "Ma Thì Hồ",
            value: 3200,
            slug: "ma-thi-ho",
            status: "empty",
          },
          { label: "Na Sang", value: 3201, slug: "na-sang", status: "empty" },
          {
            label: "Mường Mươn",
            value: 3202,
            slug: "muong-muon",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tủa Chùa",
        value: 98,
        slug: "huyen-tua-chua",
        status: "empty",
        ward: [
          { label: "Tủa Chùa", value: 3217, slug: "tua-chua", status: "empty" },
          { label: "Huổi Só", value: 3220, slug: "huoi-so", status: "empty" },
          { label: "Xín Chải", value: 3223, slug: "xin-chai", status: "empty" },
          {
            label: "Tả Sìn Thàng",
            value: 3226,
            slug: "ta-sin-thang",
            status: "empty",
          },
          {
            label: "Lao Xả Phình",
            value: 3229,
            slug: "lao-xa-phinh",
            status: "empty",
          },
          { label: "Tả Phìn", value: 3232, slug: "ta-phin", status: "empty" },
          {
            label: "Tủa Thàng",
            value: 3235,
            slug: "tua-thang",
            status: "empty",
          },
          {
            label: "Trung Thu",
            value: 3238,
            slug: "trung-thu",
            status: "empty",
          },
          {
            label: "Sính Phình",
            value: 3241,
            slug: "sinh-phinh",
            status: "empty",
          },
          { label: "Sáng Nhè", value: 3244, slug: "sang-nhe", status: "empty" },
          {
            label: "Mường Đun",
            value: 3247,
            slug: "muong-dun",
            status: "empty",
          },
          {
            label: "Mường Báng",
            value: 3250,
            slug: "muong-bang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tuần Giáo",
        value: 99,
        slug: "huyen-tuan-giao",
        status: "empty",
        ward: [
          {
            label: "Tuần Giáo",
            value: 3253,
            slug: "tuan-giao",
            status: "empty",
          },
          {
            label: "Phình Sáng",
            value: 3259,
            slug: "phinh-sang",
            status: "empty",
          },
          {
            label: "Rạng Đông",
            value: 3260,
            slug: "rang-dong",
            status: "empty",
          },
          {
            label: "Mùn Chung",
            value: 3262,
            slug: "mun-chung",
            status: "empty",
          },
          { label: "Nà Tòng", value: 3263, slug: "na-tong", status: "empty" },
          { label: "Ta Ma", value: 3265, slug: "ta-ma", status: "empty" },
          {
            label: "Mường Mùn",
            value: 3268,
            slug: "muong-mun",
            status: "empty",
          },
          { label: "Pú Xi", value: 3269, slug: "pu-xi", status: "empty" },
          { label: "Pú Nhung", value: 3271, slug: "pu-nhung", status: "empty" },
          { label: "Quài Nưa", value: 3274, slug: "quai-nua", status: "empty" },
          {
            label: "Mường Thín",
            value: 3277,
            slug: "muong-thin",
            status: "empty",
          },
          { label: "Tỏa Tình", value: 3280, slug: "toa-tinh", status: "empty" },
          { label: "Nà Sáy", value: 3283, slug: "na-say", status: "empty" },
          {
            label: "Mường Khong",
            value: 3284,
            slug: "muong-khong",
            status: "empty",
          },
          {
            label: "Quài Cang",
            value: 3289,
            slug: "quai-cang",
            status: "empty",
          },
          { label: "Quài Tở", value: 3295, slug: "quai-to", status: "empty" },
          {
            label: "Chiềng Sinh",
            value: 3298,
            slug: "chieng-sinh",
            status: "empty",
          },
          {
            label: "Chiềng Đông",
            value: 3299,
            slug: "chieng-dong",
            status: "empty",
          },
          {
            label: "Tênh Phông",
            value: 3304,
            slug: "tenh-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Điện Biên",
        value: 100,
        slug: "huyen-dien-bien",
        status: "empty",
        ward: [
          {
            label: "Mường Pồn",
            value: 3319,
            slug: "muong-pon",
            status: "empty",
          },
          {
            label: "Thanh Nưa",
            value: 3322,
            slug: "thanh-nua",
            status: "empty",
          },
          {
            label: "Hua Thanh",
            value: 3323,
            slug: "hua-thanh",
            status: "empty",
          },
          {
            label: "Thanh Luông",
            value: 3328,
            slug: "thanh-luong",
            status: "empty",
          },
          {
            label: "Thanh Hưng",
            value: 3331,
            slug: "thanh-hung",
            status: "empty",
          },
          {
            label: "Thanh Xương",
            value: 3334,
            slug: "thanh-xuong",
            status: "empty",
          },
          {
            label: "Thanh Chăn",
            value: 3337,
            slug: "thanh-chan",
            status: "empty",
          },
          { label: "Pa Thơm", value: 3340, slug: "pa-thom", status: "empty" },
          { label: "Thanh An", value: 3343, slug: "thanh-an", status: "empty" },
          {
            label: "Thanh Yên",
            value: 3346,
            slug: "thanh-yen",
            status: "empty",
          },
          {
            label: "Noong Luống",
            value: 3349,
            slug: "noong-luong",
            status: "empty",
          },
          {
            label: "Noọng Hẹt",
            value: 3352,
            slug: "noong-het",
            status: "empty",
          },
          { label: "Sam Mứn", value: 3355, slug: "sam-mun", status: "empty" },
          { label: "Pom Lót", value: 3356, slug: "pom-lot", status: "empty" },
          { label: "Núa Ngam", value: 3358, slug: "nua-ngam", status: "empty" },
          { label: "Hẹ Muông", value: 3359, slug: "he-muong", status: "empty" },
          { label: "Na Ư", value: 3361, slug: "na-u", status: "empty" },
          {
            label: "Mường Nhà",
            value: 3364,
            slug: "muong-nha",
            status: "empty",
          },
          { label: "Na Tông", value: 3365, slug: "na-tong", status: "empty" },
          {
            label: "Mường Lói",
            value: 3367,
            slug: "muong-loi",
            status: "empty",
          },
          {
            label: "Phu Luông",
            value: 3368,
            slug: "phu-luong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Điện Biên Đông",
        value: 101,
        slug: "huyen-dien-bien-dong",
        status: "empty",
        ward: [
          {
            label: "Điện Biên Đông",
            value: 3203,
            slug: "dien-bien-dong",
            status: "empty",
          },
          { label: "Na Son", value: 3205, slug: "na-son", status: "empty" },
          { label: "Phì Nhừ", value: 3208, slug: "phi-nhu", status: "empty" },
          {
            label: "Chiềng Sơ",
            value: 3211,
            slug: "chieng-so",
            status: "empty",
          },
          {
            label: "Mường Luân",
            value: 3214,
            slug: "muong-luan",
            status: "empty",
          },
          { label: "Pú Nhi", value: 3370, slug: "pu-nhi", status: "empty" },
          { label: "Nong U", value: 3371, slug: "nong-u", status: "empty" },
          { label: "Xa Dung", value: 3373, slug: "xa-dung", status: "empty" },
          { label: "Keo Lôm", value: 3376, slug: "keo-lom", status: "empty" },
          {
            label: "Luân Giới",
            value: 3379,
            slug: "luan-gioi",
            status: "empty",
          },
          {
            label: "Phình Giàng",
            value: 3382,
            slug: "phinh-giang",
            status: "empty",
          },
          { label: "Pú Hồng", value: 3383, slug: "pu-hong", status: "empty" },
          { label: "Tìa Dình", value: 3384, slug: "tia-dinh", status: "empty" },
          { label: "Háng Lìa", value: 3385, slug: "hang-lia", status: "empty" },
        ],
      },
      {
        label: "Huyện Mường Ảng",
        value: 102,
        slug: "huyen-muong-ang",
        status: "empty",
        ward: [
          {
            label: "Mường Ảng",
            value: 3256,
            slug: "muong-ang",
            status: "empty",
          },
          {
            label: "Mường Đăng",
            value: 3286,
            slug: "muong-dang",
            status: "empty",
          },
          { label: "Ngối Cáy", value: 3287, slug: "ngoi-cay", status: "empty" },
          { label: "Ẳng Tở", value: 3292, slug: "ang-to", status: "empty" },
          { label: "Búng Lao", value: 3301, slug: "bung-lao", status: "empty" },
          { label: "Xuân Lao", value: 3302, slug: "xuan-lao", status: "empty" },
          { label: "Ẳng Nưa", value: 3307, slug: "ang-nua", status: "empty" },
          { label: "Ẳng Cang", value: 3310, slug: "ang-cang", status: "empty" },
          { label: "Nặm Lịch", value: 3312, slug: "nam-lich", status: "empty" },
          {
            label: "Mường Lạn",
            value: 3313,
            slug: "muong-lan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nậm Pồ",
        value: 103,
        slug: "huyen-nam-po",
        status: "empty",
        ward: [
          { label: "Nậm Tin", value: 3156, slug: "nam-tin", status: "empty" },
          { label: "Pa Tần", value: 3165, slug: "pa-tan", status: "empty" },
          { label: "Chà Cang", value: 3166, slug: "cha-cang", status: "empty" },
          { label: "Na Cô Sa", value: 3167, slug: "na-co-sa", status: "empty" },
          { label: "Nà Khoa", value: 3168, slug: "na-khoa", status: "empty" },
          { label: "Nà Hỳ", value: 3169, slug: "na-hy", status: "empty" },
          { label: "Nà Bủng", value: 3170, slug: "na-bung", status: "empty" },
          { label: "Nậm Nhừ", value: 3171, slug: "nam-nhu", status: "empty" },
          { label: "Nậm Chua", value: 3173, slug: "nam-chua", status: "empty" },
          { label: "Nậm Khăn", value: 3174, slug: "nam-khan", status: "empty" },
          { label: "Chà Tở", value: 3175, slug: "cha-to", status: "empty" },
          { label: "Vàng Đán", value: 3176, slug: "vang-dan", status: "empty" },
          { label: "Chà Nưa", value: 3187, slug: "cha-nua", status: "empty" },
          { label: "Phìn Hồ", value: 3198, slug: "phin-ho", status: "empty" },
          {
            label: "Si Pa Phìn",
            value: 3199,
            slug: "si-pa-phin",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Lai Châu",
    value: 12,
    slug: "lai-chau",
    status: "filled",
    district: [
      {
        label: "Thành phố Lai Châu",
        value: 105,
        slug: "thanh-pho-lai-chau",
        status: "filled",
        ward: [
          {
            label: "Quyết Thắng",
            value: 3386,
            slug: "quyet-thang",
            status: "empty",
          },
          {
            label: "Tân Phong",
            value: 3387,
            slug: "tan-phong",
            status: "filled",
          },
          {
            label: "Quyết Tiến",
            value: 3388,
            slug: "quyet-tien",
            status: "empty",
          },
          { label: "Đoàn Kết", value: 3389, slug: "doan-ket", status: "empty" },
          {
            label: "Sùng Phài",
            value: 3403,
            slug: "sung-phai",
            status: "empty",
          },
          {
            label: "Đông Phong",
            value: 3408,
            slug: "dong-phong",
            status: "empty",
          },
          {
            label: "San Thàng",
            value: 3409,
            slug: "san-thang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tam Đường",
        value: 106,
        slug: "huyen-tam-duong",
        status: "empty",
        ward: [
          {
            label: "Tam Đường",
            value: 3390,
            slug: "tam-duong",
            status: "empty",
          },
          { label: "Thèn Sin", value: 3394, slug: "then-sin", status: "empty" },
          { label: "Tả Lèng", value: 3400, slug: "ta-leng", status: "empty" },
          { label: "Giang Ma", value: 3405, slug: "giang-ma", status: "empty" },
          { label: "Hồ Thầu", value: 3406, slug: "ho-thau", status: "empty" },
          { label: "Bình Lư", value: 3412, slug: "binh-lu", status: "empty" },
          { label: "Sơn Bình", value: 3413, slug: "son-binh", status: "empty" },
          {
            label: "Nùng Nàng",
            value: 3415,
            slug: "nung-nang",
            status: "empty",
          },
          {
            label: "Bản Giang",
            value: 3418,
            slug: "ban-giang",
            status: "empty",
          },
          { label: "Bản Hon", value: 3421, slug: "ban-hon", status: "empty" },
          { label: "Bản Bo", value: 3424, slug: "ban-bo", status: "empty" },
          { label: "Nà Tăm", value: 3427, slug: "na-tam", status: "empty" },
          { label: "Khun Há", value: 3430, slug: "khun-ha", status: "empty" },
        ],
      },
      {
        label: "Huyện Mường Tè",
        value: 107,
        slug: "huyen-muong-te",
        status: "empty",
        ward: [
          { label: "Mường Tè", value: 3433, slug: "muong-te", status: "empty" },
          { label: "Thu Lũm", value: 3436, slug: "thu-lum", status: "empty" },
          { label: "Ka Lăng", value: 3439, slug: "ka-lang", status: "empty" },
          { label: "Tá Bạ", value: 3440, slug: "ta-ba", status: "empty" },
          { label: "Pa ủ", value: 3442, slug: "pa-u", status: "empty" },
          { label: "Mường Tè", value: 3445, slug: "muong-te", status: "empty" },
          { label: "Pa Vệ Sử", value: 3448, slug: "pa-ve-su", status: "empty" },
          { label: "Mù Cả", value: 3451, slug: "mu-ca", status: "empty" },
          { label: "Bum Tở", value: 3454, slug: "bum-to", status: "empty" },
          { label: "Nậm Khao", value: 3457, slug: "nam-khao", status: "empty" },
          { label: "Tà Tổng", value: 3463, slug: "ta-tong", status: "empty" },
          { label: "Bum Nưa", value: 3466, slug: "bum-nua", status: "empty" },
          { label: "Vàng San", value: 3467, slug: "vang-san", status: "empty" },
          { label: "Kan Hồ", value: 3469, slug: "kan-ho", status: "empty" },
        ],
      },
      {
        label: "Huyện Sìn Hồ",
        value: 108,
        slug: "huyen-sin-ho",
        status: "empty",
        ward: [
          { label: "Sìn Hồ", value: 3478, slug: "sin-ho", status: "empty" },
          { label: "Chăn Nưa", value: 3487, slug: "chan-nua", status: "empty" },
          { label: "Pa Tần", value: 3493, slug: "pa-tan", status: "empty" },
          { label: "Phìn Hồ", value: 3496, slug: "phin-ho", status: "empty" },
          { label: "Hồng Thu", value: 3499, slug: "hong-thu", status: "empty" },
          {
            label: "Phăng Sô Lin",
            value: 3505,
            slug: "phang-so-lin",
            status: "empty",
          },
          { label: "Ma Quai", value: 3508, slug: "ma-quai", status: "empty" },
          {
            label: "Lùng Thàng",
            value: 3509,
            slug: "lung-thang",
            status: "empty",
          },
          { label: "Tả Phìn", value: 3511, slug: "ta-phin", status: "empty" },
          {
            label: "Sà Dề Phìn",
            value: 3514,
            slug: "sa-de-phin",
            status: "empty",
          },
          { label: "Nậm Tăm", value: 3517, slug: "nam-tam", status: "empty" },
          { label: "Tả Ngảo", value: 3520, slug: "ta-ngao", status: "empty" },
          {
            label: "Pu Sam Cáp",
            value: 3523,
            slug: "pu-sam-cap",
            status: "empty",
          },
          { label: "Nậm Cha", value: 3526, slug: "nam-cha", status: "empty" },
          { label: "Pa Khoá", value: 3527, slug: "pa-khoa", status: "empty" },
          { label: "Làng Mô", value: 3529, slug: "lang-mo", status: "empty" },
          {
            label: "Noong Hẻo",
            value: 3532,
            slug: "noong-heo",
            status: "empty",
          },
          { label: "Nậm Mạ", value: 3535, slug: "nam-ma", status: "empty" },
          { label: "Căn Co", value: 3538, slug: "can-co", status: "empty" },
          {
            label: "Tủa Sín Chải",
            value: 3541,
            slug: "tua-sin-chai",
            status: "empty",
          },
          { label: "Nậm Cuổi", value: 3544, slug: "nam-cuoi", status: "empty" },
          { label: "Nậm Hăn", value: 3547, slug: "nam-han", status: "empty" },
        ],
      },
      {
        label: "Huyện Phong Thổ",
        value: 109,
        slug: "huyen-phong-tho",
        status: "empty",
        ward: [
          {
            label: "Lả Nhì Thàng",
            value: 3391,
            slug: "la-nhi-thang",
            status: "empty",
          },
          {
            label: "Huổi Luông",
            value: 3490,
            slug: "huoi-luong",
            status: "empty",
          },
          {
            label: "Phong Thổ",
            value: 3549,
            slug: "phong-tho",
            status: "empty",
          },
          {
            label: "Sì Lở Lầu",
            value: 3550,
            slug: "si-lo-lau",
            status: "empty",
          },
          {
            label: "Mồ Sì San",
            value: 3553,
            slug: "mo-si-san",
            status: "empty",
          },
          {
            label: "Pa Vây Sử",
            value: 3559,
            slug: "pa-vay-su",
            status: "empty",
          },
          {
            label: "Vàng Ma Chải",
            value: 3562,
            slug: "vang-ma-chai",
            status: "empty",
          },
          {
            label: "Tông Qua Lìn",
            value: 3565,
            slug: "tong-qua-lin",
            status: "empty",
          },
          { label: "Mù Sang", value: 3568, slug: "mu-sang", status: "empty" },
          { label: "Dào San", value: 3571, slug: "dao-san", status: "empty" },
          {
            label: "Ma Ly Pho",
            value: 3574,
            slug: "ma-ly-pho",
            status: "empty",
          },
          { label: "Bản Lang", value: 3577, slug: "ban-lang", status: "empty" },
          {
            label: "Hoang Thèn",
            value: 3580,
            slug: "hoang-then",
            status: "empty",
          },
          {
            label: "Khổng Lào",
            value: 3583,
            slug: "khong-lao",
            status: "empty",
          },
          { label: "Nậm Xe", value: 3586, slug: "nam-xe", status: "empty" },
          { label: "Mường So", value: 3589, slug: "muong-so", status: "empty" },
          {
            label: "Sin Suối Hồ",
            value: 3592,
            slug: "sin-suoi-ho",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Than Uyên",
        value: 110,
        slug: "huyen-than-uyen",
        status: "empty",
        ward: [
          {
            label: "Than Uyên",
            value: 3595,
            slug: "than-uyen",
            status: "empty",
          },
          {
            label: "Phúc Than",
            value: 3618,
            slug: "phuc-than",
            status: "empty",
          },
          {
            label: "Mường Than",
            value: 3619,
            slug: "muong-than",
            status: "empty",
          },
          {
            label: "Mường Mít",
            value: 3625,
            slug: "muong-mit",
            status: "empty",
          },
          { label: "Pha Mu", value: 3628, slug: "pha-mu", status: "empty" },
          {
            label: "Mường Cang",
            value: 3631,
            slug: "muong-cang",
            status: "empty",
          },
          { label: "Hua Nà", value: 3632, slug: "hua-na", status: "empty" },
          { label: "Tà Hừa", value: 3634, slug: "ta-hua", status: "empty" },
          {
            label: "Mường Kim",
            value: 3637,
            slug: "muong-kim",
            status: "empty",
          },
          { label: "Tà Mung", value: 3638, slug: "ta-mung", status: "empty" },
          { label: "Tà Gia", value: 3640, slug: "ta-gia", status: "empty" },
          { label: "Khoen On", value: 3643, slug: "khoen-on", status: "empty" },
        ],
      },
      {
        label: "Huyện Tân Uyên",
        value: 111,
        slug: "huyen-tan-uyen",
        status: "empty",
        ward: [
          { label: "Tân Uyên", value: 3598, slug: "tan-uyen", status: "empty" },
          {
            label: "Mường Khoa",
            value: 3601,
            slug: "muong-khoa",
            status: "empty",
          },
          {
            label: "Phúc Khoa",
            value: 3602,
            slug: "phuc-khoa",
            status: "empty",
          },
          {
            label: "Thân Thuộc",
            value: 3604,
            slug: "than-thuoc",
            status: "empty",
          },
          {
            label: "Trung Đồng",
            value: 3605,
            slug: "trung-dong",
            status: "empty",
          },
          { label: "Hố Mít", value: 3607, slug: "ho-mit", status: "empty" },
          { label: "Nậm Cần", value: 3610, slug: "nam-can", status: "empty" },
          { label: "Nậm Sỏ", value: 3613, slug: "nam-so", status: "empty" },
          { label: "Pắc Ta", value: 3616, slug: "pac-ta", status: "empty" },
          { label: "Tà Mít", value: 3622, slug: "ta-mit", status: "empty" },
        ],
      },
      {
        label: "Huyện Nậm Nhùn",
        value: 112,
        slug: "huyen-nam-nhun",
        status: "empty",
        ward: [
          { label: "Nậm Nhùn", value: 3434, slug: "nam-nhun", status: "empty" },
          { label: "Hua Bun", value: 3460, slug: "hua-bun", status: "empty" },
          { label: "Mường Mô", value: 3472, slug: "muong-mo", status: "empty" },
          { label: "Nậm Chà", value: 3473, slug: "nam-cha", status: "empty" },
          { label: "Nậm Manh", value: 3474, slug: "nam-manh", status: "empty" },
          { label: "Nậm Hàng", value: 3475, slug: "nam-hang", status: "empty" },
          { label: "Lê Lợi", value: 3481, slug: "le-loi", status: "empty" },
          { label: "Pú Đao", value: 3484, slug: "pu-dao", status: "empty" },
          { label: "Nậm Pì", value: 3488, slug: "nam-pi", status: "empty" },
          { label: "Nậm Ban", value: 3502, slug: "nam-ban", status: "empty" },
          {
            label: "Trung Chải",
            value: 3503,
            slug: "trung-chai",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Sơn La",
    value: 14,
    slug: "son-la",
    status: "empty",
    district: [
      {
        label: "Thành phố Sơn La",
        value: 116,
        slug: "thanh-pho-son-la",
        status: "empty",
        ward: [
          {
            label: "Chiềng Lề",
            value: 3646,
            slug: "chieng-le",
            status: "empty",
          },
          { label: "Tô Hiệu", value: 3649, slug: "to-hieu", status: "empty" },
          {
            label: "Quyết Thắng",
            value: 3652,
            slug: "quyet-thang",
            status: "empty",
          },
          {
            label: "Quyết Tâm",
            value: 3655,
            slug: "quyet-tam",
            status: "empty",
          },
          {
            label: "Chiềng Cọ",
            value: 3658,
            slug: "chieng-co",
            status: "empty",
          },
          {
            label: "Chiềng Đen",
            value: 3661,
            slug: "chieng-den",
            status: "empty",
          },
          {
            label: "Chiềng Xôm",
            value: 3664,
            slug: "chieng-xom",
            status: "empty",
          },
          {
            label: "Chiềng An",
            value: 3667,
            slug: "chieng-an",
            status: "empty",
          },
          {
            label: "Chiềng Cơi",
            value: 3670,
            slug: "chieng-coi",
            status: "empty",
          },
          {
            label: "Chiềng Ngần",
            value: 3673,
            slug: "chieng-ngan",
            status: "empty",
          },
          { label: "Hua La", value: 3676, slug: "hua-la", status: "empty" },
          {
            label: "Chiềng Sinh",
            value: 3679,
            slug: "chieng-sinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Quỳnh Nhai",
        value: 118,
        slug: "huyen-quynh-nhai",
        status: "empty",
        ward: [
          {
            label: "Mường Chiên",
            value: 3682,
            slug: "muong-chien",
            status: "empty",
          },
          { label: "Cà Nàng", value: 3685, slug: "ca-nang", status: "empty" },
          {
            label: "Chiềng Khay",
            value: 3688,
            slug: "chieng-khay",
            status: "empty",
          },
          {
            label: "Mường Giôn",
            value: 3694,
            slug: "muong-gion",
            status: "empty",
          },
          {
            label: "Pá Ma Pha Khinh",
            value: 3697,
            slug: "pa-ma-pha-khinh",
            status: "empty",
          },
          {
            label: "Chiềng Ơn",
            value: 3700,
            slug: "chieng-on",
            status: "empty",
          },
          {
            label: "Mường Giàng",
            value: 3703,
            slug: "muong-giang",
            status: "empty",
          },
          {
            label: "Chiềng Bằng",
            value: 3706,
            slug: "chieng-bang",
            status: "empty",
          },
          {
            label: "Mường Sại",
            value: 3709,
            slug: "muong-sai",
            status: "empty",
          },
          { label: "Nậm ét", value: 3712, slug: "nam-et", status: "empty" },
          {
            label: "Chiềng Khoang",
            value: 3718,
            slug: "chieng-khoang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thuận Châu",
        value: 119,
        slug: "huyen-thuan-chau",
        status: "empty",
        ward: [
          {
            label: "Thuận Châu",
            value: 3721,
            slug: "thuan-chau",
            status: "empty",
          },
          {
            label: "Phổng Lái",
            value: 3724,
            slug: "phong-lai",
            status: "empty",
          },
          { label: "Mường é", value: 3727, slug: "muong-e", status: "empty" },
          {
            label: "Chiềng Pha",
            value: 3730,
            slug: "chieng-pha",
            status: "empty",
          },
          {
            label: "Chiềng La",
            value: 3733,
            slug: "chieng-la",
            status: "empty",
          },
          {
            label: "Chiềng Ngàm",
            value: 3736,
            slug: "chieng-ngam",
            status: "empty",
          },
          { label: "Liệp Tè", value: 3739, slug: "liep-te", status: "empty" },
          { label: "é Tòng", value: 3742, slug: "e-tong", status: "empty" },
          {
            label: "Phổng Lập",
            value: 3745,
            slug: "phong-lap",
            status: "empty",
          },
          {
            label: "Phổng Lăng",
            value: 3748,
            slug: "phong-lang",
            status: "empty",
          },
          {
            label: "Chiềng Ly",
            value: 3751,
            slug: "chieng-ly",
            status: "empty",
          },
          {
            label: "Noong Lay",
            value: 3754,
            slug: "noong-lay",
            status: "empty",
          },
          {
            label: "Mường Khiêng",
            value: 3757,
            slug: "muong-khieng",
            status: "empty",
          },
          {
            label: "Mường Bám",
            value: 3760,
            slug: "muong-bam",
            status: "empty",
          },
          { label: "Long Hẹ", value: 3763, slug: "long-he", status: "empty" },
          {
            label: "Chiềng Bôm",
            value: 3766,
            slug: "chieng-bom",
            status: "empty",
          },
          { label: "Thôm Mòn", value: 3769, slug: "thom-mon", status: "empty" },
          {
            label: "Tông Lạnh",
            value: 3772,
            slug: "tong-lanh",
            status: "empty",
          },
          { label: "Tông Cọ", value: 3775, slug: "tong-co", status: "empty" },
          { label: "Bó Mười", value: 3778, slug: "bo-muoi", status: "empty" },
          { label: "Co Mạ", value: 3781, slug: "co-ma", status: "empty" },
          { label: "Púng Tra", value: 3784, slug: "pung-tra", status: "empty" },
          {
            label: "Chiềng Pấc",
            value: 3787,
            slug: "chieng-pac",
            status: "empty",
          },
          { label: "Nậm Lầu", value: 3790, slug: "nam-lau", status: "empty" },
          {
            label: "Bon Phặng",
            value: 3793,
            slug: "bon-phang",
            status: "empty",
          },
          { label: "Co Tòng", value: 3796, slug: "co-tong", status: "empty" },
          { label: "Muổi Nọi", value: 3799, slug: "muoi-noi", status: "empty" },
          { label: "Pá Lông", value: 3802, slug: "pa-long", status: "empty" },
          { label: "Bản Lầm", value: 3805, slug: "ban-lam", status: "empty" },
        ],
      },
      {
        label: "Huyện Mường La",
        value: 120,
        slug: "huyen-muong-la",
        status: "empty",
        ward: [
          { label: "Ít Ong", value: 3808, slug: "it-ong", status: "empty" },
          { label: "Nậm Giôn", value: 3811, slug: "nam-gion", status: "empty" },
          {
            label: "Chiềng Lao",
            value: 3814,
            slug: "chieng-lao",
            status: "empty",
          },
          { label: "Hua Trai", value: 3817, slug: "hua-trai", status: "empty" },
          {
            label: "Ngọc Chiến",
            value: 3820,
            slug: "ngoc-chien",
            status: "empty",
          },
          {
            label: "Mường Trai",
            value: 3823,
            slug: "muong-trai",
            status: "empty",
          },
          { label: "Nậm Păm", value: 3826, slug: "nam-pam", status: "empty" },
          {
            label: "Chiềng Muôn",
            value: 3829,
            slug: "chieng-muon",
            status: "empty",
          },
          {
            label: "Chiềng Ân",
            value: 3832,
            slug: "chieng-an",
            status: "empty",
          },
          { label: "Pi Toong", value: 3835, slug: "pi-toong", status: "empty" },
          {
            label: "Chiềng Công",
            value: 3838,
            slug: "chieng-cong",
            status: "empty",
          },
          { label: "Tạ Bú", value: 3841, slug: "ta-bu", status: "empty" },
          {
            label: "Chiềng San",
            value: 3844,
            slug: "chieng-san",
            status: "empty",
          },
          { label: "Mường Bú", value: 3847, slug: "muong-bu", status: "empty" },
          {
            label: "Chiềng Hoa",
            value: 3850,
            slug: "chieng-hoa",
            status: "empty",
          },
          {
            label: "Mường Chùm",
            value: 3853,
            slug: "muong-chum",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bắc Yên",
        value: 121,
        slug: "huyen-bac-yen",
        status: "empty",
        ward: [
          { label: "Bắc Yên", value: 3856, slug: "bac-yen", status: "empty" },
          {
            label: "Phiêng Ban",
            value: 3859,
            slug: "phieng-ban",
            status: "empty",
          },
          { label: "Hang Chú", value: 3862, slug: "hang-chu", status: "empty" },
          { label: "Xím Vàng", value: 3865, slug: "xim-vang", status: "empty" },
          { label: "Tà Xùa", value: 3868, slug: "ta-xua", status: "empty" },
          {
            label: "Háng Đồng",
            value: 3869,
            slug: "hang-dong",
            status: "empty",
          },
          { label: "Pắc Ngà", value: 3871, slug: "pac-nga", status: "empty" },
          {
            label: "Làng Chếu",
            value: 3874,
            slug: "lang-cheu",
            status: "empty",
          },
          { label: "Chim Vàn", value: 3877, slug: "chim-van", status: "empty" },
          {
            label: "Mường Khoa",
            value: 3880,
            slug: "muong-khoa",
            status: "empty",
          },
          { label: "Song Pe", value: 3883, slug: "song-pe", status: "empty" },
          {
            label: "Hồng Ngài",
            value: 3886,
            slug: "hong-ngai",
            status: "empty",
          },
          { label: "Tạ Khoa", value: 3889, slug: "ta-khoa", status: "empty" },
          { label: "Hua Nhàn", value: 3890, slug: "hua-nhan", status: "empty" },
          {
            label: "Phiêng Côn",
            value: 3892,
            slug: "phieng-con",
            status: "empty",
          },
          {
            label: "Chiềng Sại",
            value: 3895,
            slug: "chieng-sai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phù Yên",
        value: 122,
        slug: "huyen-phu-yen",
        status: "empty",
        ward: [
          { label: "Phù Yên", value: 3898, slug: "phu-yen", status: "empty" },
          { label: "Suối Tọ", value: 3901, slug: "suoi-to", status: "empty" },
          {
            label: "Mường Thải",
            value: 3904,
            slug: "muong-thai",
            status: "empty",
          },
          {
            label: "Mường Cơi",
            value: 3907,
            slug: "muong-coi",
            status: "empty",
          },
          {
            label: "Quang Huy",
            value: 3910,
            slug: "quang-huy",
            status: "empty",
          },
          { label: "Huy Bắc", value: 3913, slug: "huy-bac", status: "empty" },
          {
            label: "Huy Thượng",
            value: 3916,
            slug: "huy-thuong",
            status: "empty",
          },
          { label: "Tân Lang", value: 3919, slug: "tan-lang", status: "empty" },
          { label: "Gia Phù", value: 3922, slug: "gia-phu", status: "empty" },
          {
            label: "Tường Phù",
            value: 3925,
            slug: "tuong-phu",
            status: "empty",
          },
          { label: "Huy Hạ", value: 3928, slug: "huy-ha", status: "empty" },
          { label: "Huy Tân", value: 3931, slug: "huy-tan", status: "empty" },
          {
            label: "Mường Lang",
            value: 3934,
            slug: "muong-lang",
            status: "empty",
          },
          { label: "Suối Bau", value: 3937, slug: "suoi-bau", status: "empty" },
          {
            label: "Huy Tường",
            value: 3940,
            slug: "huy-tuong",
            status: "empty",
          },
          { label: "Mường Do", value: 3943, slug: "muong-do", status: "empty" },
          { label: "Sập Xa", value: 3946, slug: "sap-xa", status: "empty" },
          {
            label: "Tường Thượng",
            value: 3949,
            slug: "tuong-thuong",
            status: "empty",
          },
          {
            label: "Tường Tiến",
            value: 3952,
            slug: "tuong-tien",
            status: "empty",
          },
          {
            label: "Tường Phong",
            value: 3955,
            slug: "tuong-phong",
            status: "empty",
          },
          { label: "Tường Hạ", value: 3958, slug: "tuong-ha", status: "empty" },
          { label: "Kim Bon", value: 3961, slug: "kim-bon", status: "empty" },
          {
            label: "Mường Bang",
            value: 3964,
            slug: "muong-bang",
            status: "empty",
          },
          { label: "Đá Đỏ", value: 3967, slug: "da-do", status: "empty" },
          {
            label: "Tân Phong",
            value: 3970,
            slug: "tan-phong",
            status: "empty",
          },
          {
            label: "Nam Phong",
            value: 3973,
            slug: "nam-phong",
            status: "empty",
          },
          {
            label: "Bắc Phong",
            value: 3976,
            slug: "bac-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Mộc Châu",
        value: 123,
        slug: "huyen-moc-chau",
        status: "empty",
        ward: [
          { label: "Mộc Châu", value: 3979, slug: "moc-chau", status: "empty" },
          {
            label: "NT Mộc Châu",
            value: 3982,
            slug: "nt-moc-chau",
            status: "empty",
          },
          {
            label: "Chiềng Sơn",
            value: 3985,
            slug: "chieng-son",
            status: "empty",
          },
          { label: "Tân Hợp", value: 3988, slug: "tan-hop", status: "empty" },
          {
            label: "Qui Hướng",
            value: 3991,
            slug: "qui-huong",
            status: "empty",
          },
          { label: "Tân Lập", value: 3997, slug: "tan-lap", status: "empty" },
          { label: "Nà Mường", value: 4000, slug: "na-muong", status: "empty" },
          { label: "Tà Lai", value: 4003, slug: "ta-lai", status: "empty" },
          {
            label: "Chiềng Hắc",
            value: 4012,
            slug: "chieng-hac",
            status: "empty",
          },
          { label: "Hua Păng", value: 4015, slug: "hua-pang", status: "empty" },
          {
            label: "Chiềng Khừa",
            value: 4024,
            slug: "chieng-khua",
            status: "empty",
          },
          {
            label: "Mường Sang",
            value: 4027,
            slug: "muong-sang",
            status: "empty",
          },
          {
            label: "Đông Sang",
            value: 4030,
            slug: "dong-sang",
            status: "empty",
          },
          {
            label: "Phiêng Luông",
            value: 4033,
            slug: "phieng-luong",
            status: "empty",
          },
          { label: "Lóng Sập", value: 4045, slug: "long-sap", status: "empty" },
        ],
      },
      {
        label: "Huyện Yên Châu",
        value: 124,
        slug: "huyen-yen-chau",
        status: "empty",
        ward: [
          { label: "Yên Châu", value: 4060, slug: "yen-chau", status: "empty" },
          {
            label: "Chiềng Đông",
            value: 4063,
            slug: "chieng-dong",
            status: "empty",
          },
          { label: "Sập Vạt", value: 4066, slug: "sap-vat", status: "empty" },
          {
            label: "Chiềng Sàng",
            value: 4069,
            slug: "chieng-sang",
            status: "empty",
          },
          {
            label: "Chiềng Pằn",
            value: 4072,
            slug: "chieng-pan",
            status: "empty",
          },
          {
            label: "Viêng Lán",
            value: 4075,
            slug: "vieng-lan",
            status: "empty",
          },
          {
            label: "Chiềng Hặc",
            value: 4078,
            slug: "chieng-hac",
            status: "empty",
          },
          {
            label: "Mường Lựm",
            value: 4081,
            slug: "muong-lum",
            status: "empty",
          },
          {
            label: "Chiềng On",
            value: 4084,
            slug: "chieng-on",
            status: "empty",
          },
          { label: "Yên Sơn", value: 4087, slug: "yen-son", status: "empty" },
          {
            label: "Chiềng Khoi",
            value: 4090,
            slug: "chieng-khoi",
            status: "empty",
          },
          { label: "Tú Nang", value: 4093, slug: "tu-nang", status: "empty" },
          {
            label: "Lóng Phiêng",
            value: 4096,
            slug: "long-phieng",
            status: "empty",
          },
          {
            label: "Phiêng Khoài",
            value: 4099,
            slug: "phieng-khoai",
            status: "empty",
          },
          {
            label: "Chiềng Tương",
            value: 4102,
            slug: "chieng-tuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Mai Sơn",
        value: 125,
        slug: "huyen-mai-son",
        status: "empty",
        ward: [
          { label: "Hát Lót", value: 4105, slug: "hat-lot", status: "empty" },
          {
            label: "Chiềng Sung",
            value: 4108,
            slug: "chieng-sung",
            status: "empty",
          },
          {
            label: "Mường Bằng",
            value: 4111,
            slug: "muong-bang",
            status: "empty",
          },
          {
            label: "Chiềng Chăn",
            value: 4114,
            slug: "chieng-chan",
            status: "empty",
          },
          {
            label: "Mương Chanh",
            value: 4117,
            slug: "muong-chanh",
            status: "empty",
          },
          {
            label: "Chiềng Ban",
            value: 4120,
            slug: "chieng-ban",
            status: "empty",
          },
          {
            label: "Chiềng Mung",
            value: 4123,
            slug: "chieng-mung",
            status: "empty",
          },
          {
            label: "Mường Bon",
            value: 4126,
            slug: "muong-bon",
            status: "empty",
          },
          {
            label: "Chiềng Chung",
            value: 4129,
            slug: "chieng-chung",
            status: "empty",
          },
          {
            label: "Chiềng Mai",
            value: 4132,
            slug: "chieng-mai",
            status: "empty",
          },
          { label: "Hát Lót", value: 4135, slug: "hat-lot", status: "empty" },
          { label: "Nà Pó", value: 4136, slug: "na-po", status: "empty" },
          { label: "Cò Nòi", value: 4138, slug: "co-noi", status: "empty" },
          {
            label: "Chiềng Nơi",
            value: 4141,
            slug: "chieng-noi",
            status: "empty",
          },
          {
            label: "Phiêng Cằm",
            value: 4144,
            slug: "phieng-cam",
            status: "empty",
          },
          {
            label: "Chiềng Dong",
            value: 4147,
            slug: "chieng-dong",
            status: "empty",
          },
          {
            label: "Chiềng Kheo",
            value: 4150,
            slug: "chieng-kheo",
            status: "empty",
          },
          {
            label: "Chiềng Ve",
            value: 4153,
            slug: "chieng-ve",
            status: "empty",
          },
          {
            label: "Chiềng Lương",
            value: 4156,
            slug: "chieng-luong",
            status: "empty",
          },
          {
            label: "Phiêng Pằn",
            value: 4159,
            slug: "phieng-pan",
            status: "empty",
          },
          { label: "Nà Ơt", value: 4162, slug: "na-ot", status: "empty" },
          { label: "Tà Hộc", value: 4165, slug: "ta-hoc", status: "empty" },
        ],
      },
      {
        label: "Huyện Sông Mã",
        value: 126,
        slug: "huyen-song-ma",
        status: "empty",
        ward: [
          { label: "Sông Mã", value: 4168, slug: "song-ma", status: "empty" },
          { label: "Bó Sinh", value: 4171, slug: "bo-sinh", status: "empty" },
          { label: "Pú Pẩu", value: 4174, slug: "pu-pau", status: "empty" },
          {
            label: "Chiềng Phung",
            value: 4177,
            slug: "chieng-phung",
            status: "empty",
          },
          {
            label: "Chiềng En",
            value: 4180,
            slug: "chieng-en",
            status: "empty",
          },
          {
            label: "Mường Lầm",
            value: 4183,
            slug: "muong-lam",
            status: "empty",
          },
          { label: "Nậm Ty", value: 4186, slug: "nam-ty", status: "empty" },
          { label: "Đứa Mòn", value: 4189, slug: "dua-mon", status: "empty" },
          { label: "Yên Hưng", value: 4192, slug: "yen-hung", status: "empty" },
          {
            label: "Chiềng Sơ",
            value: 4195,
            slug: "chieng-so",
            status: "empty",
          },
          { label: "Nà Nghịu", value: 4198, slug: "na-nghiu", status: "empty" },
          { label: "Nậm Mằn", value: 4201, slug: "nam-man", status: "empty" },
          {
            label: "Chiềng Khoong",
            value: 4204,
            slug: "chieng-khoong",
            status: "empty",
          },
          {
            label: "Chiềng Cang",
            value: 4207,
            slug: "chieng-cang",
            status: "empty",
          },
          { label: "Huổi Một", value: 4210, slug: "huoi-mot", status: "empty" },
          {
            label: "Mường Sai",
            value: 4213,
            slug: "muong-sai",
            status: "empty",
          },
          {
            label: "Mường Cai",
            value: 4216,
            slug: "muong-cai",
            status: "empty",
          },
          {
            label: "Mường Hung",
            value: 4219,
            slug: "muong-hung",
            status: "empty",
          },
          {
            label: "Chiềng Khương",
            value: 4222,
            slug: "chieng-khuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Sốp Cộp",
        value: 127,
        slug: "huyen-sop-cop",
        status: "empty",
        ward: [
          { label: "Sam Kha", value: 4225, slug: "sam-kha", status: "empty" },
          {
            label: "Púng Bánh",
            value: 4228,
            slug: "pung-banh",
            status: "empty",
          },
          { label: "Sốp Cộp", value: 4231, slug: "sop-cop", status: "empty" },
          { label: "Dồm Cang", value: 4234, slug: "dom-cang", status: "empty" },
          { label: "Nậm Lạnh", value: 4237, slug: "nam-lanh", status: "empty" },
          {
            label: "Mường Lèo",
            value: 4240,
            slug: "muong-leo",
            status: "empty",
          },
          { label: "Mường Và", value: 4243, slug: "muong-va", status: "empty" },
          {
            label: "Mường Lạn",
            value: 4246,
            slug: "muong-lan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vân Hồ",
        value: 128,
        slug: "huyen-van-ho",
        status: "empty",
        ward: [
          {
            label: "Suối Bàng",
            value: 3994,
            slug: "suoi-bang",
            status: "empty",
          },
          {
            label: "Song Khủa",
            value: 4006,
            slug: "song-khua",
            status: "empty",
          },
          { label: "Liên Hoà", value: 4009, slug: "lien-hoa", status: "empty" },
          { label: "Tô Múa", value: 4018, slug: "to-mua", status: "empty" },
          { label: "Mường Tè", value: 4021, slug: "muong-te", status: "empty" },
          {
            label: "Chiềng Khoa",
            value: 4036,
            slug: "chieng-khoa",
            status: "empty",
          },
          {
            label: "Mường Men",
            value: 4039,
            slug: "muong-men",
            status: "empty",
          },
          {
            label: "Quang Minh",
            value: 4042,
            slug: "quang-minh",
            status: "empty",
          },
          { label: "Vân Hồ", value: 4048, slug: "van-ho", status: "empty" },
          {
            label: "Lóng Luông",
            value: 4051,
            slug: "long-luong",
            status: "empty",
          },
          {
            label: "Chiềng Yên",
            value: 4054,
            slug: "chieng-yen",
            status: "empty",
          },
          {
            label: "Chiềng Xuân",
            value: 4056,
            slug: "chieng-xuan",
            status: "empty",
          },
          { label: "Xuân Nha", value: 4057, slug: "xuan-nha", status: "empty" },
          { label: "Tân Xuân", value: 4058, slug: "tan-xuan", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Yên Bái",
    value: 15,
    slug: "yen-bai",
    status: "filled",
    district: [
      {
        label: "Thành phố Yên Bái",
        value: 132,
        slug: "thanh-pho-yen-bai",
        status: "filled",
        ward: [
          {
            label: "Yên Thịnh",
            value: 4249,
            slug: "yen-thinh",
            status: "empty",
          },
          {
            label: "Yên Ninh",
            value: 4252,
            slug: "yen-ninh",
            status: "filled",
          },
          {
            label: "Minh Tân",
            value: 4255,
            slug: "minh-tan",
            status: "filled",
          },
          {
            label: "Nguyễn Thái Học",
            value: 4258,
            slug: "nguyen-thai-hoc",
            status: "empty",
          },
          { label: "Đồng Tâm", value: 4261, slug: "dong-tam", status: "empty" },
          { label: "Hồng Hà", value: 4264, slug: "hong-ha", status: "empty" },
          { label: "Minh Bảo", value: 4270, slug: "minh-bao", status: "empty" },
          {
            label: "Nam Cường",
            value: 4273,
            slug: "nam-cuong",
            status: "empty",
          },
          { label: "Tuy Lộc", value: 4276, slug: "tuy-loc", status: "empty" },
          {
            label: "Tân Thịnh",
            value: 4279,
            slug: "tan-thinh",
            status: "empty",
          },
          { label: "Âu Lâu", value: 4540, slug: "au-lau", status: "empty" },
          {
            label: "Giới Phiên",
            value: 4543,
            slug: "gioi-phien",
            status: "empty",
          },
          { label: "Hợp Minh", value: 4546, slug: "hop-minh", status: "empty" },
          { label: "Văn Phú", value: 4558, slug: "van-phu", status: "empty" },
        ],
      },
      {
        label: "Thị xã Nghĩa Lộ",
        value: 133,
        slug: "thi-xa-nghia-lo",
        status: "empty",
        ward: [
          { label: "Pú Trạng", value: 4282, slug: "pu-trang", status: "empty" },
          {
            label: "Trung Tâm",
            value: 4285,
            slug: "trung-tam",
            status: "empty",
          },
          { label: "Tân An", value: 4288, slug: "tan-an", status: "empty" },
          { label: "Cầu Thia", value: 4291, slug: "cau-thia", status: "empty" },
          {
            label: "Nghĩa Lợi",
            value: 4294,
            slug: "nghia-loi",
            status: "empty",
          },
          {
            label: "Nghĩa Phúc",
            value: 4297,
            slug: "nghia-phuc",
            status: "empty",
          },
          { label: "Nghĩa An", value: 4300, slug: "nghia-an", status: "empty" },
          { label: "Nghĩa Lộ", value: 4624, slug: "nghia-lo", status: "empty" },
          { label: "Sơn A", value: 4660, slug: "son-a", status: "empty" },
          { label: "Phù Nham", value: 4663, slug: "phu-nham", status: "empty" },
          {
            label: "Thanh Lương",
            value: 4675,
            slug: "thanh-luong",
            status: "empty",
          },
          { label: "Hạnh Sơn", value: 4678, slug: "hanh-son", status: "empty" },
          { label: "Phúc Sơn", value: 4681, slug: "phuc-son", status: "empty" },
          {
            label: "Thạch Lương",
            value: 4684,
            slug: "thach-luong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lục Yên",
        value: 135,
        slug: "huyen-luc-yen",
        status: "empty",
        ward: [
          { label: "Yên Thế", value: 4303, slug: "yen-the", status: "empty" },
          {
            label: "Tân Phượng",
            value: 4306,
            slug: "tan-phuong",
            status: "empty",
          },
          {
            label: "Lâm Thượng",
            value: 4309,
            slug: "lam-thuong",
            status: "empty",
          },
          {
            label: "Khánh Thiện",
            value: 4312,
            slug: "khanh-thien",
            status: "empty",
          },
          {
            label: "Minh Chuẩn",
            value: 4315,
            slug: "minh-chuan",
            status: "empty",
          },
          { label: "Mai Sơn", value: 4318, slug: "mai-son", status: "empty" },
          {
            label: "Khai Trung",
            value: 4321,
            slug: "khai-trung",
            status: "empty",
          },
          {
            label: "Mường Lai",
            value: 4324,
            slug: "muong-lai",
            status: "empty",
          },
          { label: "An Lạc", value: 4327, slug: "an-lac", status: "empty" },
          {
            label: "Minh Xuân",
            value: 4330,
            slug: "minh-xuan",
            status: "empty",
          },
          { label: "Tô Mậu", value: 4333, slug: "to-mau", status: "empty" },
          { label: "Tân Lĩnh", value: 4336, slug: "tan-linh", status: "empty" },
          {
            label: "Yên Thắng",
            value: 4339,
            slug: "yen-thang",
            status: "empty",
          },
          {
            label: "Khánh Hoà",
            value: 4342,
            slug: "khanh-hoa",
            status: "empty",
          },
          { label: "Vĩnh Lạc", value: 4345, slug: "vinh-lac", status: "empty" },
          { label: "Liễu Đô", value: 4348, slug: "lieu-do", status: "empty" },
          {
            label: "Động Quan",
            value: 4351,
            slug: "dong-quan",
            status: "empty",
          },
          { label: "Tân Lập", value: 4354, slug: "tan-lap", status: "empty" },
          {
            label: "Minh Tiến",
            value: 4357,
            slug: "minh-tien",
            status: "empty",
          },
          { label: "Trúc Lâu", value: 4360, slug: "truc-lau", status: "empty" },
          { label: "Phúc Lợi", value: 4363, slug: "phuc-loi", status: "empty" },
          {
            label: "Phan Thanh",
            value: 4366,
            slug: "phan-thanh",
            status: "empty",
          },
          { label: "An Phú", value: 4369, slug: "an-phu", status: "empty" },
          {
            label: "Trung Tâm",
            value: 4372,
            slug: "trung-tam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Văn Yên",
        value: 136,
        slug: "huyen-van-yen",
        status: "empty",
        ward: [
          { label: "Mậu A", value: 4375, slug: "mau-a", status: "empty" },
          {
            label: "Lang Thíp",
            value: 4378,
            slug: "lang-thip",
            status: "empty",
          },
          {
            label: "Lâm Giang",
            value: 4381,
            slug: "lam-giang",
            status: "empty",
          },
          {
            label: "Châu Quế Thượng",
            value: 4384,
            slug: "chau-que-thuong",
            status: "empty",
          },
          {
            label: "Châu Quế Hạ",
            value: 4387,
            slug: "chau-que-ha",
            status: "empty",
          },
          { label: "An Bình", value: 4390, slug: "an-binh", status: "empty" },
          {
            label: "Quang Minh",
            value: 4393,
            slug: "quang-minh",
            status: "empty",
          },
          { label: "Đông An", value: 4396, slug: "dong-an", status: "empty" },
          {
            label: "Đông Cuông",
            value: 4399,
            slug: "dong-cuong",
            status: "empty",
          },
          {
            label: "Phong Dụ Hạ",
            value: 4402,
            slug: "phong-du-ha",
            status: "empty",
          },
          { label: "Mậu Đông", value: 4405, slug: "mau-dong", status: "empty" },
          { label: "Ngòi A", value: 4408, slug: "ngoi-a", status: "empty" },
          { label: "Xuân Tầm", value: 4411, slug: "xuan-tam", status: "empty" },
          { label: "Tân Hợp", value: 4414, slug: "tan-hop", status: "empty" },
          { label: "An Thịnh", value: 4417, slug: "an-thinh", status: "empty" },
          { label: "Yên Thái", value: 4420, slug: "yen-thai", status: "empty" },
          {
            label: "Phong Dụ Thượng",
            value: 4423,
            slug: "phong-du-thuong",
            status: "empty",
          },
          { label: "Yên Hợp", value: 4426, slug: "yen-hop", status: "empty" },
          { label: "Đại Sơn", value: 4429, slug: "dai-son", status: "empty" },
          { label: "Đại Phác", value: 4435, slug: "dai-phac", status: "empty" },
          { label: "Yên Phú", value: 4438, slug: "yen-phu", status: "empty" },
          { label: "Xuân Ái", value: 4441, slug: "xuan-ai", status: "empty" },
          { label: "Viễn Sơn", value: 4447, slug: "vien-son", status: "empty" },
          { label: "Mỏ Vàng", value: 4450, slug: "mo-vang", status: "empty" },
          { label: "Nà Hẩu", value: 4453, slug: "na-hau", status: "empty" },
        ],
      },
      {
        label: "Huyện Mù Căng Chải",
        value: 137,
        slug: "huyen-mu-cang-chai",
        status: "empty",
        ward: [
          {
            label: "Mù Căng Chải",
            value: 4456,
            slug: "mu-cang-chai",
            status: "empty",
          },
          { label: "Hồ Bốn", value: 4459, slug: "ho-bon", status: "empty" },
          { label: "Nậm Có", value: 4462, slug: "nam-co", status: "empty" },
          {
            label: "Khao Mang",
            value: 4465,
            slug: "khao-mang",
            status: "empty",
          },
          { label: "Mồ Dề", value: 4468, slug: "mo-de", status: "empty" },
          {
            label: "Chế Cu Nha",
            value: 4471,
            slug: "che-cu-nha",
            status: "empty",
          },
          { label: "Lao Chải", value: 4474, slug: "lao-chai", status: "empty" },
          { label: "Kim Nọi", value: 4477, slug: "kim-noi", status: "empty" },
          { label: "Cao Phạ", value: 4480, slug: "cao-pha", status: "empty" },
          {
            label: "La Pán Tẩn",
            value: 4483,
            slug: "la-pan-tan",
            status: "empty",
          },
          {
            label: "Dế Su Phình",
            value: 4486,
            slug: "de-su-phinh",
            status: "empty",
          },
          { label: "Chế Tạo", value: 4489, slug: "che-tao", status: "empty" },
          {
            label: "Púng Luông",
            value: 4492,
            slug: "pung-luong",
            status: "empty",
          },
          { label: "Nậm Khắt", value: 4495, slug: "nam-khat", status: "empty" },
        ],
      },
      {
        label: "Huyện Trấn Yên",
        value: 138,
        slug: "huyen-tran-yen",
        status: "empty",
        ward: [
          { label: "Cổ Phúc", value: 4498, slug: "co-phuc", status: "empty" },
          { label: "Tân Đồng", value: 4501, slug: "tan-dong", status: "empty" },
          { label: "Báo Đáp", value: 4504, slug: "bao-dap", status: "empty" },
          {
            label: "Thành Thịnh",
            value: 4510,
            slug: "thanh-thinh",
            status: "empty",
          },
          {
            label: "Hòa Cuông",
            value: 4513,
            slug: "hoa-cuong",
            status: "empty",
          },
          {
            label: "Minh Quán",
            value: 4516,
            slug: "minh-quan",
            status: "empty",
          },
          { label: "Quy Mông", value: 4519, slug: "quy-mong", status: "empty" },
          {
            label: "Cường Thịnh",
            value: 4522,
            slug: "cuong-thinh",
            status: "empty",
          },
          {
            label: "Kiên Thành",
            value: 4525,
            slug: "kien-thanh",
            status: "empty",
          },
          { label: "Y Can", value: 4531, slug: "y-can", status: "empty" },
          {
            label: "Lương Thịnh",
            value: 4537,
            slug: "luong-thinh",
            status: "empty",
          },
          {
            label: "Việt Cường",
            value: 4564,
            slug: "viet-cuong",
            status: "empty",
          },
          {
            label: "Minh Quân",
            value: 4567,
            slug: "minh-quan",
            status: "empty",
          },
          { label: "Hồng Ca", value: 4570, slug: "hong-ca", status: "empty" },
          {
            label: "Hưng Thịnh",
            value: 4573,
            slug: "hung-thinh",
            status: "empty",
          },
          {
            label: "Hưng Khánh",
            value: 4576,
            slug: "hung-khanh",
            status: "empty",
          },
          {
            label: "Việt Hồng",
            value: 4579,
            slug: "viet-hong",
            status: "empty",
          },
          { label: "Vân Hội", value: 4582, slug: "van-hoi", status: "empty" },
        ],
      },
      {
        label: "Huyện Trạm Tấu",
        value: 139,
        slug: "huyen-tram-tau",
        status: "empty",
        ward: [
          { label: "Trạm Tấu", value: 4585, slug: "tram-tau", status: "empty" },
          { label: "Túc Đán", value: 4588, slug: "tuc-dan", status: "empty" },
          { label: "Pá Lau", value: 4591, slug: "pa-lau", status: "empty" },
          { label: "Xà Hồ", value: 4594, slug: "xa-ho", status: "empty" },
          { label: "Phình Hồ", value: 4597, slug: "phinh-ho", status: "empty" },
          { label: "Trạm Tấu", value: 4600, slug: "tram-tau", status: "empty" },
          {
            label: "Tà Si Láng",
            value: 4603,
            slug: "ta-si-lang",
            status: "empty",
          },
          { label: "Pá Hu", value: 4606, slug: "pa-hu", status: "empty" },
          { label: "Làng Nhì", value: 4609, slug: "lang-nhi", status: "empty" },
          { label: "Bản Công", value: 4612, slug: "ban-cong", status: "empty" },
          { label: "Bản Mù", value: 4615, slug: "ban-mu", status: "empty" },
          { label: "Hát Lìu", value: 4618, slug: "hat-liu", status: "empty" },
        ],
      },
      {
        label: "Huyện Văn Chấn",
        value: 140,
        slug: "huyen-van-chan",
        status: "empty",
        ward: [
          {
            label: "NT Liên Sơn",
            value: 4621,
            slug: "nt-lien-son",
            status: "empty",
          },
          {
            label: "NT Trần Phú",
            value: 4627,
            slug: "nt-tran-phu",
            status: "empty",
          },
          { label: "Tú Lệ", value: 4630, slug: "tu-le", status: "empty" },
          { label: "Nậm Búng", value: 4633, slug: "nam-bung", status: "empty" },
          { label: "Gia Hội", value: 4636, slug: "gia-hoi", status: "empty" },
          { label: "Sùng Đô", value: 4639, slug: "sung-do", status: "empty" },
          { label: "Nậm Mười", value: 4642, slug: "nam-muoi", status: "empty" },
          { label: "An Lương", value: 4645, slug: "an-luong", status: "empty" },
          { label: "Nậm Lành", value: 4648, slug: "nam-lanh", status: "empty" },
          {
            label: "Sơn Lương",
            value: 4651,
            slug: "son-luong",
            status: "empty",
          },
          {
            label: "Suối Quyền",
            value: 4654,
            slug: "suoi-quyen",
            status: "empty",
          },
          {
            label: "Suối Giàng",
            value: 4657,
            slug: "suoi-giang",
            status: "empty",
          },
          {
            label: "Nghĩa Sơn",
            value: 4666,
            slug: "nghia-son",
            status: "empty",
          },
          { label: "Suối Bu", value: 4669, slug: "suoi-bu", status: "empty" },
          {
            label: "Sơn Thịnh",
            value: 4672,
            slug: "son-thinh",
            status: "empty",
          },
          { label: "Đại Lịch", value: 4687, slug: "dai-lich", status: "empty" },
          { label: "Đồng Khê", value: 4690, slug: "dong-khe", status: "empty" },
          {
            label: "Cát Thịnh",
            value: 4693,
            slug: "cat-thinh",
            status: "empty",
          },
          {
            label: "Tân Thịnh",
            value: 4696,
            slug: "tan-thinh",
            status: "empty",
          },
          {
            label: "Chấn Thịnh",
            value: 4699,
            slug: "chan-thinh",
            status: "empty",
          },
          {
            label: "Bình Thuận",
            value: 4702,
            slug: "binh-thuan",
            status: "empty",
          },
          {
            label: "Thượng Bằng La",
            value: 4705,
            slug: "thuong-bang-la",
            status: "empty",
          },
          { label: "Minh An", value: 4708, slug: "minh-an", status: "empty" },
          {
            label: "Nghĩa Tâm",
            value: 4711,
            slug: "nghia-tam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Yên Bình",
        value: 141,
        slug: "huyen-yen-binh",
        status: "empty",
        ward: [
          { label: "Yên Bình", value: 4714, slug: "yen-binh", status: "empty" },
          { label: "Thác Bà", value: 4717, slug: "thac-ba", status: "empty" },
          {
            label: "Xuân Long",
            value: 4720,
            slug: "xuan-long",
            status: "empty",
          },
          { label: "Cảm Nhân", value: 4726, slug: "cam-nhan", status: "empty" },
          {
            label: "Ngọc Chấn",
            value: 4729,
            slug: "ngoc-chan",
            status: "empty",
          },
          {
            label: "Tân Nguyên",
            value: 4732,
            slug: "tan-nguyen",
            status: "empty",
          },
          {
            label: "Phúc Ninh",
            value: 4735,
            slug: "phuc-ninh",
            status: "empty",
          },
          { label: "Bảo Ái", value: 4738, slug: "bao-ai", status: "empty" },
          { label: "Mỹ Gia", value: 4741, slug: "my-gia", status: "empty" },
          { label: "Xuân Lai", value: 4744, slug: "xuan-lai", status: "empty" },
          { label: "Mông Sơn", value: 4747, slug: "mong-son", status: "empty" },
          { label: "Cảm Ân", value: 4750, slug: "cam-an", status: "empty" },
          {
            label: "Yên Thành",
            value: 4753,
            slug: "yen-thanh",
            status: "empty",
          },
          {
            label: "Tân Hương",
            value: 4756,
            slug: "tan-huong",
            status: "empty",
          },
          { label: "Phúc An", value: 4759, slug: "phuc-an", status: "empty" },
          { label: "Bạch Hà", value: 4762, slug: "bach-ha", status: "empty" },
          { label: "Vũ Linh", value: 4765, slug: "vu-linh", status: "empty" },
          { label: "Đại Đồng", value: 4768, slug: "dai-dong", status: "empty" },
          {
            label: "Vĩnh Kiên",
            value: 4771,
            slug: "vinh-kien",
            status: "empty",
          },
          {
            label: "Thịnh Hưng",
            value: 4777,
            slug: "thinh-hung",
            status: "empty",
          },
          { label: "Hán Đà", value: 4780, slug: "han-da", status: "empty" },
          {
            label: "Phú Thịnh",
            value: 4783,
            slug: "phu-thinh",
            status: "empty",
          },
          { label: "Đại Minh", value: 4786, slug: "dai-minh", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Hoà Bình",
    value: 17,
    slug: "hoa-binh",
    status: "empty",
    district: [
      {
        label: "Thành phố Hòa Bình",
        value: 148,
        slug: "thanh-pho-hoa-binh",
        status: "empty",
        ward: [
          {
            label: "Thái Bình",
            value: 4789,
            slug: "thai-binh",
            status: "empty",
          },
          { label: "Tân Hòa", value: 4792, slug: "tan-hoa", status: "empty" },
          {
            label: "Thịnh Lang",
            value: 4795,
            slug: "thinh-lang",
            status: "empty",
          },
          { label: "Hữu Nghị", value: 4798, slug: "huu-nghi", status: "empty" },
          {
            label: "Tân Thịnh",
            value: 4801,
            slug: "tan-thinh",
            status: "empty",
          },
          {
            label: "Đồng Tiến",
            value: 4804,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Phương Lâm",
            value: 4807,
            slug: "phuong-lam",
            status: "empty",
          },
          { label: "Yên Mông", value: 4813, slug: "yen-mong", status: "empty" },
          {
            label: "Quỳnh Lâm",
            value: 4816,
            slug: "quynh-lam",
            status: "empty",
          },
          { label: "Dân Chủ", value: 4819, slug: "dan-chu", status: "empty" },
          { label: "Hòa Bình", value: 4825, slug: "hoa-binh", status: "empty" },
          {
            label: "Thống Nhất",
            value: 4828,
            slug: "thong-nhat",
            status: "empty",
          },
          { label: "Kỳ Sơn", value: 4894, slug: "ky-son", status: "empty" },
          {
            label: "Thịnh Minh",
            value: 4897,
            slug: "thinh-minh",
            status: "empty",
          },
          {
            label: "Hợp Thành",
            value: 4903,
            slug: "hop-thanh",
            status: "empty",
          },
          {
            label: "Quang Tiến",
            value: 4906,
            slug: "quang-tien",
            status: "empty",
          },
          { label: "Mông Hóa", value: 4912, slug: "mong-hoa", status: "empty" },
          {
            label: "Trung Minh",
            value: 4918,
            slug: "trung-minh",
            status: "empty",
          },
          { label: "Độc Lập", value: 4921, slug: "doc-lap", status: "empty" },
        ],
      },
      {
        label: "Huyện Đà Bắc",
        value: 150,
        slug: "huyen-da-bac",
        status: "empty",
        ward: [
          { label: "Đà Bắc", value: 4831, slug: "da-bac", status: "empty" },
          {
            label: "Nánh Nghê",
            value: 4834,
            slug: "nanh-nghe",
            status: "empty",
          },
          { label: "Giáp Đắt", value: 4840, slug: "giap-dat", status: "empty" },
          {
            label: "Mường Chiềng",
            value: 4846,
            slug: "muong-chieng",
            status: "empty",
          },
          { label: "Tân Pheo", value: 4849, slug: "tan-pheo", status: "empty" },
          {
            label: "Đồng Chum",
            value: 4852,
            slug: "dong-chum",
            status: "empty",
          },
          { label: "Tân Minh", value: 4855, slug: "tan-minh", status: "empty" },
          { label: "Đoàn Kết", value: 4858, slug: "doan-ket", status: "empty" },
          {
            label: "Đồng Ruộng",
            value: 4861,
            slug: "dong-ruong",
            status: "empty",
          },
          { label: "Tú Lý", value: 4867, slug: "tu-ly", status: "empty" },
          {
            label: "Trung Thành",
            value: 4870,
            slug: "trung-thanh",
            status: "empty",
          },
          { label: "Yên Hòa", value: 4873, slug: "yen-hoa", status: "empty" },
          { label: "Cao Sơn", value: 4876, slug: "cao-son", status: "empty" },
          { label: "Toàn Sơn", value: 4879, slug: "toan-son", status: "empty" },
          {
            label: "Hiền Lương",
            value: 4885,
            slug: "hien-luong",
            status: "empty",
          },
          {
            label: "Tiền Phong",
            value: 4888,
            slug: "tien-phong",
            status: "empty",
          },
          { label: "Vầy Nưa", value: 4891, slug: "vay-nua", status: "empty" },
        ],
      },
      {
        label: "Huyện Lương Sơn",
        value: 152,
        slug: "huyen-luong-son",
        status: "empty",
        ward: [
          {
            label: "Lương Sơn",
            value: 4924,
            slug: "luong-son",
            status: "empty",
          },
          { label: "Lâm Sơn", value: 4942, slug: "lam-son", status: "empty" },
          { label: "Hòa Sơn", value: 4945, slug: "hoa-son", status: "empty" },
          { label: "Tân Vinh", value: 4951, slug: "tan-vinh", status: "empty" },
          {
            label: "Nhuận Trạch",
            value: 4954,
            slug: "nhuan-trach",
            status: "empty",
          },
          { label: "Cao Sơn", value: 4957, slug: "cao-son", status: "empty" },
          { label: "Cư Yên", value: 4960, slug: "cu-yen", status: "empty" },
          { label: "Liên Sơn", value: 4969, slug: "lien-son", status: "empty" },
          {
            label: "Cao Dương",
            value: 5008,
            slug: "cao-duong",
            status: "empty",
          },
          {
            label: "Thanh Sơn",
            value: 5041,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Thanh Cao",
            value: 5047,
            slug: "thanh-cao",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Kim Bôi",
        value: 153,
        slug: "huyen-kim-boi",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Bo",
            value: 4978,
            slug: "thi-tran-bo",
            status: "empty",
          },
          { label: "Đú Sáng", value: 4984, slug: "du-sang", status: "empty" },
          { label: "Hùng Sơn", value: 4987, slug: "hung-son", status: "empty" },
          { label: "Bình Sơn", value: 4990, slug: "binh-son", status: "empty" },
          { label: "Tú Sơn", value: 4999, slug: "tu-son", status: "empty" },
          {
            label: "Vĩnh Tiến",
            value: 5005,
            slug: "vinh-tien",
            status: "empty",
          },
          { label: "Đông Bắc", value: 5014, slug: "dong-bac", status: "empty" },
          {
            label: "Xuân Thủy",
            value: 5017,
            slug: "xuan-thuy",
            status: "empty",
          },
          {
            label: "Vĩnh Đồng",
            value: 5026,
            slug: "vinh-dong",
            status: "empty",
          },
          { label: "Kim Lập", value: 5035, slug: "kim-lap", status: "empty" },
          { label: "Hợp Tiến", value: 5038, slug: "hop-tien", status: "empty" },
          { label: "Kim Bôi", value: 5065, slug: "kim-boi", status: "empty" },
          {
            label: "Nam Thượng",
            value: 5068,
            slug: "nam-thuong",
            status: "empty",
          },
          { label: "Cuối Hạ", value: 5077, slug: "cuoi-ha", status: "empty" },
          { label: "Sào Báy", value: 5080, slug: "sao-bay", status: "empty" },
          { label: "Mi Hòa", value: 5083, slug: "mi-hoa", status: "empty" },
          {
            label: "Nuông Dăm",
            value: 5086,
            slug: "nuong-dam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cao Phong",
        value: 154,
        slug: "huyen-cao-phong",
        status: "empty",
        ward: [
          {
            label: "Cao Phong",
            value: 5089,
            slug: "cao-phong",
            status: "empty",
          },
          {
            label: "Bình Thanh",
            value: 5092,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Thung Nai",
            value: 5095,
            slug: "thung-nai",
            status: "empty",
          },
          {
            label: "Bắc Phong",
            value: 5098,
            slug: "bac-phong",
            status: "empty",
          },
          {
            label: "Thu Phong",
            value: 5101,
            slug: "thu-phong",
            status: "empty",
          },
          {
            label: "Hợp Phong",
            value: 5104,
            slug: "hop-phong",
            status: "empty",
          },
          {
            label: "Tây Phong",
            value: 5110,
            slug: "tay-phong",
            status: "empty",
          },
          {
            label: "Dũng Phong",
            value: 5116,
            slug: "dung-phong",
            status: "empty",
          },
          {
            label: "Nam Phong",
            value: 5119,
            slug: "nam-phong",
            status: "empty",
          },
          {
            label: "Thạch Yên",
            value: 5125,
            slug: "thach-yen",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Lạc",
        value: 155,
        slug: "huyen-tan-lac",
        status: "empty",
        ward: [
          { label: "Mãn Đức", value: 5128, slug: "man-duc", status: "empty" },
          { label: "Suối Hoa", value: 5134, slug: "suoi-hoa", status: "empty" },
          { label: "Phú Vinh", value: 5137, slug: "phu-vinh", status: "empty" },
          {
            label: "Phú Cường",
            value: 5140,
            slug: "phu-cuong",
            status: "empty",
          },
          { label: "Mỹ Hòa", value: 5143, slug: "my-hoa", status: "empty" },
          {
            label: "Quyết Chiến",
            value: 5152,
            slug: "quyet-chien",
            status: "empty",
          },
          {
            label: "Phong Phú",
            value: 5158,
            slug: "phong-phu",
            status: "empty",
          },
          { label: "Tử Nê", value: 5164, slug: "tu-ne", status: "empty" },
          {
            label: "Thanh Hối",
            value: 5167,
            slug: "thanh-hoi",
            status: "empty",
          },
          { label: "Ngọc Mỹ", value: 5170, slug: "ngoc-my", status: "empty" },
          { label: "Đông Lai", value: 5173, slug: "dong-lai", status: "empty" },
          { label: "Vân Sơn", value: 5176, slug: "van-son", status: "empty" },
          { label: "Nhân Mỹ", value: 5182, slug: "nhan-my", status: "empty" },
          { label: "Lỗ Sơn", value: 5191, slug: "lo-son", status: "empty" },
          {
            label: "Ngổ Luông",
            value: 5194,
            slug: "ngo-luong",
            status: "empty",
          },
          { label: "Gia Mô", value: 5197, slug: "gia-mo", status: "empty" },
        ],
      },
      {
        label: "Huyện Mai Châu",
        value: 156,
        slug: "huyen-mai-chau",
        status: "empty",
        ward: [
          {
            label: "Tân Thành",
            value: 4882,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Mai Châu", value: 5200, slug: "mai-chau", status: "empty" },
          { label: "Sơn Thủy", value: 5206, slug: "son-thuy", status: "empty" },
          { label: "Pà Cò", value: 5209, slug: "pa-co", status: "empty" },
          { label: "Hang Kia", value: 5212, slug: "hang-kia", status: "empty" },
          { label: "Đồng Tân", value: 5221, slug: "dong-tan", status: "empty" },
          { label: "Cun Pheo", value: 5224, slug: "cun-pheo", status: "empty" },
          { label: "Bao La", value: 5227, slug: "bao-la", status: "empty" },
          { label: "Tòng Đậu", value: 5233, slug: "tong-dau", status: "empty" },
          { label: "Nà Phòn", value: 5242, slug: "na-phon", status: "empty" },
          { label: "Săm Khóe", value: 5245, slug: "sam-khoe", status: "empty" },
          {
            label: "Chiềng Châu",
            value: 5248,
            slug: "chieng-chau",
            status: "empty",
          },
          { label: "Mai Hạ", value: 5251, slug: "mai-ha", status: "empty" },
          {
            label: "Thành Sơn",
            value: 5254,
            slug: "thanh-son",
            status: "empty",
          },
          { label: "Mai Hịch", value: 5257, slug: "mai-hich", status: "empty" },
          { label: "Vạn Mai", value: 5263, slug: "van-mai", status: "empty" },
        ],
      },
      {
        label: "Huyện Lạc Sơn",
        value: 157,
        slug: "huyen-lac-son",
        status: "empty",
        ward: [
          { label: "Vụ Bản", value: 5266, slug: "vu-ban", status: "empty" },
          { label: "Quý Hòa", value: 5269, slug: "quy-hoa", status: "empty" },
          { label: "Miền Đồi", value: 5272, slug: "mien-doi", status: "empty" },
          { label: "Mỹ Thành", value: 5275, slug: "my-thanh", status: "empty" },
          { label: "Tuân Đạo", value: 5278, slug: "tuan-dao", status: "empty" },
          {
            label: "Văn Nghĩa",
            value: 5281,
            slug: "van-nghia",
            status: "empty",
          },
          { label: "Văn Sơn", value: 5284, slug: "van-son", status: "empty" },
          { label: "Tân Lập", value: 5287, slug: "tan-lap", status: "empty" },
          {
            label: "Nhân Nghĩa",
            value: 5290,
            slug: "nhan-nghia",
            status: "empty",
          },
          {
            label: "Thượng Cốc",
            value: 5293,
            slug: "thuong-coc",
            status: "empty",
          },
          {
            label: "Quyết Thắng",
            value: 5299,
            slug: "quyet-thang",
            status: "empty",
          },
          { label: "Xuất Hóa", value: 5302, slug: "xuat-hoa", status: "empty" },
          { label: "Yên Phú", value: 5305, slug: "yen-phu", status: "empty" },
          { label: "Bình Hẻm", value: 5308, slug: "binh-hem", status: "empty" },
          { label: "Định Cư", value: 5320, slug: "dinh-cu", status: "empty" },
          { label: "Chí Đạo", value: 5323, slug: "chi-dao", status: "empty" },
          { label: "Ngọc Sơn", value: 5329, slug: "ngoc-son", status: "empty" },
          {
            label: "Hương Nhượng",
            value: 5332,
            slug: "huong-nhuong",
            status: "empty",
          },
          { label: "Vũ Bình", value: 5335, slug: "vu-binh", status: "empty" },
          { label: "Tự Do", value: 5338, slug: "tu-do", status: "empty" },
          {
            label: "Yên Nghiệp",
            value: 5341,
            slug: "yen-nghiep",
            status: "empty",
          },
          { label: "Tân Mỹ", value: 5344, slug: "tan-my", status: "empty" },
          { label: "Ân Nghĩa", value: 5347, slug: "an-nghia", status: "empty" },
          { label: "Ngọc Lâu", value: 5350, slug: "ngoc-lau", status: "empty" },
        ],
      },
      {
        label: "Huyện Yên Thủy",
        value: 158,
        slug: "huyen-yen-thuy",
        status: "empty",
        ward: [
          {
            label: "Hàng Trạm",
            value: 5353,
            slug: "hang-tram",
            status: "empty",
          },
          { label: "Lạc Sỹ", value: 5356, slug: "lac-sy", status: "empty" },
          {
            label: "Lạc Lương",
            value: 5362,
            slug: "lac-luong",
            status: "empty",
          },
          { label: "Bảo Hiệu", value: 5365, slug: "bao-hieu", status: "empty" },
          { label: "Đa Phúc", value: 5368, slug: "da-phuc", status: "empty" },
          { label: "Hữu Lợi", value: 5371, slug: "huu-loi", status: "empty" },
          {
            label: "Lạc Thịnh",
            value: 5374,
            slug: "lac-thinh",
            status: "empty",
          },
          { label: "Đoàn Kết", value: 5380, slug: "doan-ket", status: "empty" },
          { label: "Phú Lai", value: 5383, slug: "phu-lai", status: "empty" },
          { label: "Yên Trị", value: 5386, slug: "yen-tri", status: "empty" },
          {
            label: "Ngọc Lương",
            value: 5389,
            slug: "ngoc-luong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lạc Thủy",
        value: 159,
        slug: "huyen-lac-thuy",
        status: "empty",
        ward: [
          {
            label: "Ba Hàng Đồi",
            value: 4981,
            slug: "ba-hang-doi",
            status: "empty",
          },
          { label: "Chi Nê", value: 5392, slug: "chi-ne", status: "empty" },
          {
            label: "Phú Nghĩa",
            value: 5395,
            slug: "phu-nghia",
            status: "empty",
          },
          {
            label: "Phú Thành",
            value: 5398,
            slug: "phu-thanh",
            status: "empty",
          },
          { label: "Hưng Thi", value: 5404, slug: "hung-thi", status: "empty" },
          { label: "Khoan Dụ", value: 5413, slug: "khoan-du", status: "empty" },
          { label: "Đồng Tâm", value: 5419, slug: "dong-tam", status: "empty" },
          { label: "Yên Bồng", value: 5422, slug: "yen-bong", status: "empty" },
          {
            label: "Thống Nhất",
            value: 5425,
            slug: "thong-nhat",
            status: "empty",
          },
          { label: "An Bình", value: 5428, slug: "an-binh", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Thái Nguyên",
    value: 19,
    slug: "thai-nguyen",
    status: "filled",
    district: [
      {
        label: "Thành phố Thái Nguyên",
        value: 164,
        slug: "thanh-pho-thai-nguyen",
        status: "filled",
        ward: [
          {
            label: "Quán Triều",
            value: 5431,
            slug: "quan-trieu",
            status: "empty",
          },
          {
            label: "Quang Vinh",
            value: 5434,
            slug: "quang-vinh",
            status: "empty",
          },
          {
            label: "Túc Duyên",
            value: 5437,
            slug: "tuc-duyen",
            status: "empty",
          },
          {
            label: "Hoàng Văn Thụ",
            value: 5440,
            slug: "hoang-van-thu",
            status: "empty",
          },
          {
            label: "Trưng Vương",
            value: 5443,
            slug: "trung-vuong",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 5446,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Phan Đình Phùng",
            value: 5449,
            slug: "phan-dinh-phung",
            status: "filled",
          },
          {
            label: "Tân Thịnh",
            value: 5452,
            slug: "tan-thinh",
            status: "empty",
          },
          {
            label: "Thịnh Đán",
            value: 5455,
            slug: "thinh-dan",
            status: "empty",
          },
          {
            label: "Đồng Quang",
            value: 5458,
            slug: "dong-quang",
            status: "empty",
          },
          { label: "Gia Sàng", value: 5461, slug: "gia-sang", status: "empty" },
          { label: "Tân Lập", value: 5464, slug: "tan-lap", status: "empty" },
          { label: "Cam Giá", value: 5467, slug: "cam-gia", status: "empty" },
          { label: "Phú Xá", value: 5470, slug: "phu-xa", status: "empty" },
          {
            label: "Hương Sơn",
            value: 5473,
            slug: "huong-son",
            status: "empty",
          },
          {
            label: "Trung Thành",
            value: 5476,
            slug: "trung-thanh",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 5479,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Tân Long", value: 5482, slug: "tan-long", status: "empty" },
          { label: "Phúc Hà", value: 5485, slug: "phuc-ha", status: "empty" },
          {
            label: "Phúc Xuân",
            value: 5488,
            slug: "phuc-xuan",
            status: "empty",
          },
          {
            label: "Quyết Thắng",
            value: 5491,
            slug: "quyet-thang",
            status: "empty",
          },
          {
            label: "Phúc Trìu",
            value: 5494,
            slug: "phuc-triu",
            status: "empty",
          },
          {
            label: "Thịnh Đức",
            value: 5497,
            slug: "thinh-duc",
            status: "empty",
          },
          {
            label: "Tích Lương",
            value: 5500,
            slug: "tich-luong",
            status: "empty",
          },
          {
            label: "Tân Cương",
            value: 5503,
            slug: "tan-cuong",
            status: "empty",
          },
          { label: "Sơn Cẩm", value: 5653, slug: "son-cam", status: "empty" },
          {
            label: "Chùa Hang",
            value: 5659,
            slug: "chua-hang",
            status: "empty",
          },
          { label: "Cao Ngạn", value: 5695, slug: "cao-ngan", status: "empty" },
          { label: "Linh Sơn", value: 5701, slug: "linh-son", status: "empty" },
          { label: "Đồng Bẩm", value: 5710, slug: "dong-bam", status: "empty" },
          {
            label: "Huống Thượng",
            value: 5713,
            slug: "huong-thuong",
            status: "empty",
          },
          {
            label: "Đồng Liên",
            value: 5914,
            slug: "dong-lien",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Sông Công",
        value: 165,
        slug: "thanh-pho-song-cong",
        status: "empty",
        ward: [
          {
            label: "Lương Sơn",
            value: 5506,
            slug: "luong-son",
            status: "empty",
          },
          { label: "Châu Sơn", value: 5509, slug: "chau-son", status: "empty" },
          { label: "Mỏ Chè", value: 5512, slug: "mo-che", status: "empty" },
          { label: "Cải Đan", value: 5515, slug: "cai-dan", status: "empty" },
          {
            label: "Thắng Lợi",
            value: 5518,
            slug: "thang-loi",
            status: "empty",
          },
          { label: "Phố Cò", value: 5521, slug: "pho-co", status: "empty" },
          {
            label: "Tân Quang",
            value: 5527,
            slug: "tan-quang",
            status: "empty",
          },
          {
            label: "Bách Quang",
            value: 5528,
            slug: "bach-quang",
            status: "empty",
          },
          { label: "Bình Sơn", value: 5530, slug: "binh-son", status: "empty" },
          { label: "Bá Xuyên", value: 5533, slug: "ba-xuyen", status: "empty" },
        ],
      },
      {
        label: "Huyện Định Hóa",
        value: 167,
        slug: "huyen-dinh-hoa",
        status: "empty",
        ward: [
          {
            label: "Linh Thông",
            value: 5539,
            slug: "linh-thong",
            status: "empty",
          },
          { label: "Lam Vỹ", value: 5542, slug: "lam-vy", status: "empty" },
          { label: "Quy Kỳ", value: 5545, slug: "quy-ky", status: "empty" },
          {
            label: "Tân Thịnh",
            value: 5548,
            slug: "tan-thinh",
            status: "empty",
          },
          {
            label: "Kim Phượng",
            value: 5551,
            slug: "kim-phuong",
            status: "empty",
          },
          { label: "Bảo Linh", value: 5554, slug: "bao-linh", status: "empty" },
          { label: "Phúc Chu", value: 5560, slug: "phuc-chu", status: "empty" },
          {
            label: "Tân Dương",
            value: 5563,
            slug: "tan-duong",
            status: "empty",
          },
          {
            label: "Phượng Tiến",
            value: 5566,
            slug: "phuong-tien",
            status: "empty",
          },
          { label: "Chợ Chu", value: 5569, slug: "cho-chu", status: "empty" },
          {
            label: "Đồng Thịnh",
            value: 5572,
            slug: "dong-thinh",
            status: "empty",
          },
          {
            label: "Định Biên",
            value: 5575,
            slug: "dinh-bien",
            status: "empty",
          },
          {
            label: "Thanh Định",
            value: 5578,
            slug: "thanh-dinh",
            status: "empty",
          },
          {
            label: "Trung Hội",
            value: 5581,
            slug: "trung-hoi",
            status: "empty",
          },
          {
            label: "Trung Lương",
            value: 5584,
            slug: "trung-luong",
            status: "empty",
          },
          { label: "Bình Yên", value: 5587, slug: "binh-yen", status: "empty" },
          { label: "Điềm Mặc", value: 5590, slug: "diem-mac", status: "empty" },
          { label: "Phú Tiến", value: 5593, slug: "phu-tien", status: "empty" },
          {
            label: "Bộc Nhiêu",
            value: 5596,
            slug: "boc-nhieu",
            status: "empty",
          },
          { label: "Sơn Phú", value: 5599, slug: "son-phu", status: "empty" },
          { label: "Phú Đình", value: 5602, slug: "phu-dinh", status: "empty" },
          {
            label: "Bình Thành",
            value: 5605,
            slug: "binh-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phú Lương",
        value: 168,
        slug: "huyen-phu-luong",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Đu",
            value: 5611,
            slug: "thi-tran-du",
            status: "empty",
          },
          { label: "Yên Ninh", value: 5614, slug: "yen-ninh", status: "empty" },
          {
            label: "Yên Trạch",
            value: 5617,
            slug: "yen-trach",
            status: "empty",
          },
          { label: "Yên Đổ", value: 5620, slug: "yen-do", status: "empty" },
          { label: "Yên Lạc", value: 5623, slug: "yen-lac", status: "empty" },
          { label: "Ôn Lương", value: 5626, slug: "on-luong", status: "empty" },
          { label: "Động Đạt", value: 5629, slug: "dong-dat", status: "empty" },
          { label: "Phủ Lý", value: 5632, slug: "phu-ly", status: "empty" },
          { label: "Phú Đô", value: 5635, slug: "phu-do", status: "empty" },
          {
            label: "Hợp Thành",
            value: 5638,
            slug: "hop-thanh",
            status: "empty",
          },
          {
            label: "Tức Tranh",
            value: 5641,
            slug: "tuc-tranh",
            status: "empty",
          },
          {
            label: "Giang Tiên",
            value: 5644,
            slug: "giang-tien",
            status: "empty",
          },
          { label: "Vô Tranh", value: 5647, slug: "vo-tranh", status: "empty" },
          { label: "Cổ Lũng", value: 5650, slug: "co-lung", status: "empty" },
        ],
      },
      {
        label: "Huyện Đồng Hỷ",
        value: 169,
        slug: "huyen-dong-hy",
        status: "empty",
        ward: [
          { label: "Sông Cầu", value: 5656, slug: "song-cau", status: "empty" },
          { label: "Trại Cau", value: 5662, slug: "trai-cau", status: "empty" },
          { label: "Văn Lăng", value: 5665, slug: "van-lang", status: "empty" },
          { label: "Tân Long", value: 5668, slug: "tan-long", status: "empty" },
          { label: "Hòa Bình", value: 5671, slug: "hoa-binh", status: "empty" },
          {
            label: "Quang Sơn",
            value: 5674,
            slug: "quang-son",
            status: "empty",
          },
          { label: "Minh Lập", value: 5677, slug: "minh-lap", status: "empty" },
          { label: "Văn Hán", value: 5680, slug: "van-han", status: "empty" },
          {
            label: "Hóa Trung",
            value: 5683,
            slug: "hoa-trung",
            status: "empty",
          },
          { label: "Khe Mo", value: 5686, slug: "khe-mo", status: "empty" },
          { label: "Cây Thị", value: 5689, slug: "cay-thi", status: "empty" },
          {
            label: "Hóa Thượng",
            value: 5692,
            slug: "hoa-thuong",
            status: "empty",
          },
          { label: "Hợp Tiến", value: 5698, slug: "hop-tien", status: "empty" },
          { label: "Nam Hòa", value: 5707, slug: "nam-hoa", status: "empty" },
        ],
      },
      {
        label: "Huyện Võ Nhai",
        value: 170,
        slug: "huyen-vo-nhai",
        status: "empty",
        ward: [
          { label: "Đình Cả", value: 5716, slug: "dinh-ca", status: "empty" },
          { label: "Sảng Mộc", value: 5719, slug: "sang-moc", status: "empty" },
          {
            label: "Nghinh Tường",
            value: 5722,
            slug: "nghinh-tuong",
            status: "empty",
          },
          { label: "Thần Xa", value: 5725, slug: "than-xa", status: "empty" },
          { label: "Vũ Chấn", value: 5728, slug: "vu-chan", status: "empty" },
          {
            label: "Thượng Nung",
            value: 5731,
            slug: "thuong-nung",
            status: "empty",
          },
          {
            label: "Phú Thượng",
            value: 5734,
            slug: "phu-thuong",
            status: "empty",
          },
          {
            label: "Cúc Đường",
            value: 5737,
            slug: "cuc-duong",
            status: "empty",
          },
          { label: "La Hiên", value: 5740, slug: "la-hien", status: "empty" },
          {
            label: "Lâu Thượng",
            value: 5743,
            slug: "lau-thuong",
            status: "empty",
          },
          { label: "Tràng Xá", value: 5746, slug: "trang-xa", status: "empty" },
          {
            label: "Phương Giao",
            value: 5749,
            slug: "phuong-giao",
            status: "empty",
          },
          {
            label: "Liên Minh",
            value: 5752,
            slug: "lien-minh",
            status: "empty",
          },
          { label: "Dân Tiến", value: 5755, slug: "dan-tien", status: "empty" },
          {
            label: "Bình Long",
            value: 5758,
            slug: "binh-long",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đại Từ",
        value: 171,
        slug: "huyen-dai-tu",
        status: "empty",
        ward: [
          { label: "Hùng Sơn", value: 5761, slug: "hung-son", status: "empty" },
          {
            label: "Phúc Lương",
            value: 5767,
            slug: "phuc-luong",
            status: "empty",
          },
          {
            label: "Minh Tiến",
            value: 5770,
            slug: "minh-tien",
            status: "empty",
          },
          { label: "Yên Lãng", value: 5773, slug: "yen-lang", status: "empty" },
          {
            label: "Đức Lương",
            value: 5776,
            slug: "duc-luong",
            status: "empty",
          },
          {
            label: "Phú Cường",
            value: 5779,
            slug: "phu-cuong",
            status: "empty",
          },
          { label: "Phú Lạc", value: 5785, slug: "phu-lac", status: "empty" },
          { label: "Tân Linh", value: 5788, slug: "tan-linh", status: "empty" },
          {
            label: "Phú Thịnh",
            value: 5791,
            slug: "phu-thinh",
            status: "empty",
          },
          {
            label: "Phục Linh",
            value: 5794,
            slug: "phuc-linh",
            status: "empty",
          },
          {
            label: "Phú Xuyên",
            value: 5797,
            slug: "phu-xuyen",
            status: "empty",
          },
          {
            label: "Bản Ngoại",
            value: 5800,
            slug: "ban-ngoai",
            status: "empty",
          },
          { label: "Tiên Hội", value: 5803, slug: "tien-hoi", status: "empty" },
          { label: "Cù Vân", value: 5809, slug: "cu-van", status: "empty" },
          {
            label: "Hà Thượng",
            value: 5812,
            slug: "ha-thuong",
            status: "empty",
          },
          { label: "La Bằng", value: 5815, slug: "la-bang", status: "empty" },
          {
            label: "Hoàng Nông",
            value: 5818,
            slug: "hoang-nong",
            status: "empty",
          },
          { label: "Khôi Kỳ", value: 5821, slug: "khoi-ky", status: "empty" },
          { label: "An Khánh", value: 5824, slug: "an-khanh", status: "empty" },
          { label: "Tân Thái", value: 5827, slug: "tan-thai", status: "empty" },
          {
            label: "Bình Thuận",
            value: 5830,
            slug: "binh-thuan",
            status: "empty",
          },
          { label: "Lục Ba", value: 5833, slug: "luc-ba", status: "empty" },
          { label: "Mỹ Yên", value: 5836, slug: "my-yen", status: "empty" },
          { label: "Văn Yên", value: 5842, slug: "van-yen", status: "empty" },
          { label: "Vạn Phú", value: 5845, slug: "van-phu", status: "empty" },
          { label: "Cát Nê", value: 5848, slug: "cat-ne", status: "empty" },
          { label: "Quân Chu", value: 5851, slug: "quan-chu", status: "empty" },
        ],
      },
      {
        label: "Thành phố Phổ Yên",
        value: 172,
        slug: "thanh-pho-pho-yen",
        status: "empty",
        ward: [
          { label: "Bãi Bông", value: 5854, slug: "bai-bong", status: "empty" },
          { label: "Bắc Sơn", value: 5857, slug: "bac-son", status: "empty" },
          { label: "Ba Hàng", value: 5860, slug: "ba-hang", status: "empty" },
          { label: "Phúc Tân", value: 5863, slug: "phuc-tan", status: "empty" },
          {
            label: "Phúc Thuận",
            value: 5866,
            slug: "phuc-thuan",
            status: "empty",
          },
          {
            label: "Hồng Tiến",
            value: 5869,
            slug: "hong-tien",
            status: "empty",
          },
          { label: "Minh Đức", value: 5872, slug: "minh-duc", status: "empty" },
          { label: "Đắc Sơn", value: 5875, slug: "dac-son", status: "empty" },
          {
            label: "Đồng Tiến",
            value: 5878,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Thành Công",
            value: 5881,
            slug: "thanh-cong",
            status: "empty",
          },
          {
            label: "Tiên Phong",
            value: 5884,
            slug: "tien-phong",
            status: "empty",
          },
          { label: "Vạn Phái", value: 5887, slug: "van-phai", status: "empty" },
          { label: "Nam Tiến", value: 5890, slug: "nam-tien", status: "empty" },
          {
            label: "Tân Hương",
            value: 5893,
            slug: "tan-huong",
            status: "empty",
          },
          { label: "Đông Cao", value: 5896, slug: "dong-cao", status: "empty" },
          {
            label: "Trung Thành",
            value: 5899,
            slug: "trung-thanh",
            status: "empty",
          },
          { label: "Tân Phú", value: 5902, slug: "tan-phu", status: "empty" },
          {
            label: "Thuận Thành",
            value: 5905,
            slug: "thuan-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phú Bình",
        value: 173,
        slug: "huyen-phu-binh",
        status: "filled",
        ward: [
          {
            label: "Hương Sơn",
            value: 5908,
            slug: "huong-son",
            status: "filled",
          },
          { label: "Bàn Đạt", value: 5911, slug: "ban-dat", status: "empty" },
          {
            label: "Tân Khánh",
            value: 5917,
            slug: "tan-khanh",
            status: "empty",
          },
          { label: "Tân Kim", value: 5920, slug: "tan-kim", status: "empty" },
          {
            label: "Tân Thành",
            value: 5923,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Đào Xá", value: 5926, slug: "dao-xa", status: "empty" },
          { label: "Bảo Lý", value: 5929, slug: "bao-ly", status: "empty" },
          {
            label: "Thượng Đình",
            value: 5932,
            slug: "thuong-dinh",
            status: "empty",
          },
          { label: "Tân Hòa", value: 5935, slug: "tan-hoa", status: "empty" },
          { label: "Nhã Lộng", value: 5938, slug: "nha-long", status: "empty" },
          {
            label: "Điềm Thụy",
            value: 5941,
            slug: "diem-thuy",
            status: "empty",
          },
          {
            label: "Xuân Phương",
            value: 5944,
            slug: "xuan-phuong",
            status: "empty",
          },
          { label: "Tân Đức", value: 5947, slug: "tan-duc", status: "empty" },
          { label: "Úc Kỳ", value: 5950, slug: "uc-ky", status: "empty" },
          {
            label: "Lương Phú",
            value: 5953,
            slug: "luong-phu",
            status: "empty",
          },
          { label: "Nga My", value: 5956, slug: "nga-my", status: "empty" },
          { label: "Kha Sơn", value: 5959, slug: "kha-son", status: "empty" },
          {
            label: "Thanh Ninh",
            value: 5962,
            slug: "thanh-ninh",
            status: "empty",
          },
          {
            label: "Dương Thành",
            value: 5965,
            slug: "duong-thanh",
            status: "empty",
          },
          { label: "Hà Châu", value: 5968, slug: "ha-chau", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Lạng Sơn",
    value: 20,
    slug: "lang-son",
    status: "filled",
    district: [
      {
        label: "Thành phố Lạng Sơn",
        value: 178,
        slug: "thanh-pho-lang-son",
        status: "filled",
        ward: [
          {
            label: "Hoàng Văn Thụ",
            value: 5971,
            slug: "hoang-van-thu",
            status: "filled",
          },
          {
            label: "Tam Thanh",
            value: 5974,
            slug: "tam-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Trại",
            value: 5977,
            slug: "vinh-trai",
            status: "empty",
          },
          {
            label: "Đông Kinh",
            value: 5980,
            slug: "dong-kinh",
            status: "empty",
          },
          { label: "Chi Lăng", value: 5983, slug: "chi-lang", status: "empty" },
          {
            label: "Hoàng Đồng",
            value: 5986,
            slug: "hoang-dong",
            status: "empty",
          },
          {
            label: "Quảng Lạc",
            value: 5989,
            slug: "quang-lac",
            status: "empty",
          },
          { label: "Mai Pha", value: 5992, slug: "mai-pha", status: "empty" },
        ],
      },
      {
        label: "Huyện Tràng Định",
        value: 180,
        slug: "huyen-trang-dinh",
        status: "empty",
        ward: [
          {
            label: "Khánh Long",
            value: 5998,
            slug: "khanh-long",
            status: "empty",
          },
          { label: "Đoàn Kết", value: 6001, slug: "doan-ket", status: "empty" },
          {
            label: "Quốc Khánh",
            value: 6004,
            slug: "quoc-khanh",
            status: "empty",
          },
          { label: "Cao Minh", value: 6010, slug: "cao-minh", status: "empty" },
          { label: "Chí Minh", value: 6013, slug: "chi-minh", status: "empty" },
          {
            label: "Tri Phương",
            value: 6016,
            slug: "tri-phuong",
            status: "empty",
          },
          { label: "Tân Tiến", value: 6019, slug: "tan-tien", status: "empty" },
          { label: "Tân Yên", value: 6022, slug: "tan-yen", status: "empty" },
          { label: "Đội Cấn", value: 6025, slug: "doi-can", status: "empty" },
          { label: "Tân Minh", value: 6028, slug: "tan-minh", status: "empty" },
          { label: "Kim Đồng", value: 6031, slug: "kim-dong", status: "empty" },
          { label: "Chi Lăng", value: 6034, slug: "chi-lang", status: "empty" },
          {
            label: "Trung Thành",
            value: 6037,
            slug: "trung-thanh",
            status: "empty",
          },
          { label: "Thất Khê", value: 6040, slug: "that-khe", status: "empty" },
          { label: "Đào Viên", value: 6043, slug: "dao-vien", status: "empty" },
          { label: "Đề Thám", value: 6046, slug: "de-tham", status: "empty" },
          {
            label: "Kháng Chiến",
            value: 6049,
            slug: "khang-chien",
            status: "empty",
          },
          { label: "Hùng Sơn", value: 6055, slug: "hung-son", status: "empty" },
          {
            label: "Quốc Việt",
            value: 6058,
            slug: "quoc-viet",
            status: "empty",
          },
          {
            label: "Hùng Việt",
            value: 6061,
            slug: "hung-viet",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bình Gia",
        value: 181,
        slug: "huyen-binh-gia",
        status: "empty",
        ward: [
          { label: "Hưng Đạo", value: 6067, slug: "hung-dao", status: "empty" },
          { label: "Vĩnh Yên", value: 6070, slug: "vinh-yen", status: "empty" },
          { label: "Hoa Thám", value: 6073, slug: "hoa-tham", status: "empty" },
          { label: "Quý Hòa", value: 6076, slug: "quy-hoa", status: "empty" },
          {
            label: "Hồng Phong",
            value: 6079,
            slug: "hong-phong",
            status: "empty",
          },
          { label: "Yên Lỗ", value: 6082, slug: "yen-lo", status: "empty" },
          {
            label: "Thiện Hòa",
            value: 6085,
            slug: "thien-hoa",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 6088,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Thiện Thuật",
            value: 6091,
            slug: "thien-thuat",
            status: "empty",
          },
          {
            label: "Minh Khai",
            value: 6094,
            slug: "minh-khai",
            status: "empty",
          },
          {
            label: "Thiện Long",
            value: 6097,
            slug: "thien-long",
            status: "empty",
          },
          {
            label: "Hoàng Văn Thụ",
            value: 6100,
            slug: "hoang-van-thu",
            status: "empty",
          },
          { label: "Hòa Bình", value: 6103, slug: "hoa-binh", status: "empty" },
          { label: "Mông Ân", value: 6106, slug: "mong-an", status: "empty" },
          { label: "Tân Hòa", value: 6109, slug: "tan-hoa", status: "empty" },
          { label: "Bình Gia", value: 6112, slug: "binh-gia", status: "empty" },
          {
            label: "Hồng Thái",
            value: 6115,
            slug: "hong-thai",
            status: "empty",
          },
          { label: "Bình La", value: 6118, slug: "binh-la", status: "empty" },
          { label: "Tân Văn", value: 6121, slug: "tan-van", status: "empty" },
        ],
      },
      {
        label: "Huyện Văn Lãng",
        value: 182,
        slug: "huyen-van-lang",
        status: "empty",
        ward: [
          { label: "Na Sầm", value: 6124, slug: "na-sam", status: "empty" },
          {
            label: "Trùng Khánh",
            value: 6127,
            slug: "trung-khanh",
            status: "empty",
          },
          { label: "Bắc La", value: 6133, slug: "bac-la", status: "empty" },
          {
            label: "Thụy Hùng",
            value: 6136,
            slug: "thuy-hung",
            status: "empty",
          },
          { label: "Bắc Hùng", value: 6139, slug: "bac-hung", status: "empty" },
          { label: "Tân Tác", value: 6142, slug: "tan-tac", status: "empty" },
          {
            label: "Thanh Long",
            value: 6148,
            slug: "thanh-long",
            status: "empty",
          },
          { label: "Hội Hoan", value: 6151, slug: "hoi-hoan", status: "empty" },
          { label: "Bắc Việt", value: 6154, slug: "bac-viet", status: "empty" },
          {
            label: "Hoàng Việt",
            value: 6157,
            slug: "hoang-viet",
            status: "empty",
          },
          { label: "Gia Miễn", value: 6160, slug: "gia-mien", status: "empty" },
          {
            label: "Thành Hòa",
            value: 6163,
            slug: "thanh-hoa",
            status: "empty",
          },
          {
            label: "Tân Thanh",
            value: 6166,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Tân Mỹ", value: 6172, slug: "tan-my", status: "empty" },
          {
            label: "Hồng Thái",
            value: 6175,
            slug: "hong-thai",
            status: "empty",
          },
          {
            label: "Hoàng Văn Thụ",
            value: 6178,
            slug: "hoang-van-thu",
            status: "empty",
          },
          { label: "Nhạc Kỳ", value: 6181, slug: "nhac-ky", status: "empty" },
        ],
      },
      {
        label: "Huyện Cao Lộc",
        value: 183,
        slug: "huyen-cao-loc",
        status: "empty",
        ward: [
          {
            label: "Đồng Đăng",
            value: 6184,
            slug: "dong-dang",
            status: "empty",
          },
          { label: "Cao Lộc", value: 6187, slug: "cao-loc", status: "empty" },
          { label: "Bảo Lâm", value: 6190, slug: "bao-lam", status: "empty" },
          {
            label: "Thanh Lòa",
            value: 6193,
            slug: "thanh-loa",
            status: "empty",
          },
          { label: "Cao Lâu", value: 6196, slug: "cao-lau", status: "empty" },
          {
            label: "Thạch Đạn",
            value: 6199,
            slug: "thach-dan",
            status: "empty",
          },
          { label: "Xuất Lễ", value: 6202, slug: "xuat-le", status: "empty" },
          {
            label: "Hồng Phong",
            value: 6205,
            slug: "hong-phong",
            status: "empty",
          },
          {
            label: "Thụy Hùng",
            value: 6208,
            slug: "thuy-hung",
            status: "empty",
          },
          { label: "Lộc Yên", value: 6211, slug: "loc-yen", status: "empty" },
          { label: "Phú Xá", value: 6214, slug: "phu-xa", status: "empty" },
          {
            label: "Bình Trung",
            value: 6217,
            slug: "binh-trung",
            status: "empty",
          },
          { label: "Hải Yến", value: 6220, slug: "hai-yen", status: "empty" },
          { label: "Hòa Cư", value: 6223, slug: "hoa-cu", status: "empty" },
          {
            label: "Hợp Thành",
            value: 6226,
            slug: "hop-thanh",
            status: "empty",
          },
          { label: "Công Sơn", value: 6232, slug: "cong-son", status: "empty" },
          { label: "Gia Cát", value: 6235, slug: "gia-cat", status: "empty" },
          { label: "Mẫu Sơn", value: 6238, slug: "mau-son", status: "empty" },
          {
            label: "Xuân Long",
            value: 6241,
            slug: "xuan-long",
            status: "empty",
          },
          { label: "Tân Liên", value: 6244, slug: "tan-lien", status: "empty" },
          {
            label: "Yên Trạch",
            value: 6247,
            slug: "yen-trach",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 6250,
            slug: "tan-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Văn Quan",
        value: 184,
        slug: "huyen-van-quan",
        status: "empty",
        ward: [
          { label: "Văn Quan", value: 6253, slug: "van-quan", status: "empty" },
          {
            label: "Trấn Ninh",
            value: 6256,
            slug: "tran-ninh",
            status: "empty",
          },
          { label: "Liên Hội", value: 6268, slug: "lien-hoi", status: "empty" },
          { label: "Hòa Bình", value: 6274, slug: "hoa-binh", status: "empty" },
          { label: "Tú Xuyên", value: 6277, slug: "tu-xuyen", status: "empty" },
          { label: "Điềm He", value: 6280, slug: "diem-he", status: "empty" },
          { label: "An Sơn", value: 6283, slug: "an-son", status: "empty" },
          {
            label: "Khánh Khê",
            value: 6286,
            slug: "khanh-khe",
            status: "empty",
          },
          {
            label: "Lương Năng",
            value: 6292,
            slug: "luong-nang",
            status: "empty",
          },
          {
            label: "Bình Phúc",
            value: 6298,
            slug: "binh-phuc",
            status: "empty",
          },
          { label: "Tân Đoàn", value: 6307, slug: "tan-doan", status: "empty" },
          { label: "Tri Lễ", value: 6313, slug: "tri-le", status: "empty" },
          {
            label: "Tràng Phái",
            value: 6316,
            slug: "trang-phai",
            status: "empty",
          },
          { label: "Yên Phúc", value: 6319, slug: "yen-phuc", status: "empty" },
          { label: "Hữu Lễ", value: 6322, slug: "huu-le", status: "empty" },
        ],
      },
      {
        label: "Huyện Bắc Sơn",
        value: 185,
        slug: "huyen-bac-son",
        status: "empty",
        ward: [
          { label: "Bắc Sơn", value: 6325, slug: "bac-son", status: "empty" },
          {
            label: "Long Đống",
            value: 6328,
            slug: "long-dong",
            status: "empty",
          },
          { label: "Vạn Thủy", value: 6331, slug: "van-thuy", status: "empty" },
          { label: "Đồng ý", value: 6337, slug: "dong-y", status: "empty" },
          { label: "Tân Tri", value: 6340, slug: "tan-tri", status: "empty" },
          {
            label: "Bắc Quỳnh",
            value: 6343,
            slug: "bac-quynh",
            status: "empty",
          },
          { label: "Hưng Vũ", value: 6349, slug: "hung-vu", status: "empty" },
          { label: "Tân Lập", value: 6352, slug: "tan-lap", status: "empty" },
          { label: "Vũ Sơn", value: 6355, slug: "vu-son", status: "empty" },
          { label: "Chiêu Vũ", value: 6358, slug: "chieu-vu", status: "empty" },
          {
            label: "Tân Hương",
            value: 6361,
            slug: "tan-huong",
            status: "empty",
          },
          {
            label: "Chiến Thắng",
            value: 6364,
            slug: "chien-thang",
            status: "empty",
          },
          { label: "Vũ Lăng", value: 6367, slug: "vu-lang", status: "empty" },
          { label: "Trấn Yên", value: 6370, slug: "tran-yen", status: "empty" },
          { label: "Vũ Lễ", value: 6373, slug: "vu-le", status: "empty" },
          { label: "Nhất Hòa", value: 6376, slug: "nhat-hoa", status: "empty" },
          {
            label: "Tân Thành",
            value: 6379,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Nhất Tiến",
            value: 6382,
            slug: "nhat-tien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hữu Lũng",
        value: 186,
        slug: "huyen-huu-lung",
        status: "empty",
        ward: [
          { label: "Hữu Lũng", value: 6385, slug: "huu-lung", status: "empty" },
          { label: "Hữu Liên", value: 6388, slug: "huu-lien", status: "empty" },
          { label: "Yên Bình", value: 6391, slug: "yen-binh", status: "empty" },
          {
            label: "Quyết Thắng",
            value: 6394,
            slug: "quyet-thang",
            status: "empty",
          },
          { label: "Hòa Bình", value: 6397, slug: "hoa-binh", status: "empty" },
          {
            label: "Yên Thịnh",
            value: 6400,
            slug: "yen-thinh",
            status: "empty",
          },
          { label: "Yên Sơn", value: 6403, slug: "yen-son", status: "empty" },
          {
            label: "Thiện Tân",
            value: 6406,
            slug: "thien-tan",
            status: "empty",
          },
          {
            label: "Yên Vượng",
            value: 6412,
            slug: "yen-vuong",
            status: "empty",
          },
          {
            label: "Minh Tiến",
            value: 6415,
            slug: "minh-tien",
            status: "empty",
          },
          {
            label: "Nhật Tiến",
            value: 6418,
            slug: "nhat-tien",
            status: "empty",
          },
          {
            label: "Thanh Sơn",
            value: 6421,
            slug: "thanh-son",
            status: "empty",
          },
          { label: "Đồng Tân", value: 6424, slug: "dong-tan", status: "empty" },
          { label: "Cai Kinh", value: 6427, slug: "cai-kinh", status: "empty" },
          { label: "Hòa Lạc", value: 6430, slug: "hoa-lac", status: "empty" },
          { label: "Vân Nham", value: 6433, slug: "van-nham", status: "empty" },
          {
            label: "Đồng Tiến",
            value: 6436,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 6442,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Hòa Sơn", value: 6445, slug: "hoa-son", status: "empty" },
          { label: "Minh Sơn", value: 6448, slug: "minh-son", status: "empty" },
          { label: "Hồ Sơn", value: 6451, slug: "ho-son", status: "empty" },
          { label: "Minh Hòa", value: 6457, slug: "minh-hoa", status: "empty" },
          {
            label: "Hòa Thắng",
            value: 6460,
            slug: "hoa-thang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Chi Lăng",
        value: 187,
        slug: "huyen-chi-lang",
        status: "empty",
        ward: [
          { label: "Đồng Mỏ", value: 6463, slug: "dong-mo", status: "empty" },
          { label: "Chi Lăng", value: 6466, slug: "chi-lang", status: "empty" },
          { label: "Vân An", value: 6469, slug: "van-an", status: "empty" },
          { label: "Vân Thủy", value: 6472, slug: "van-thuy", status: "empty" },
          { label: "Gia Lộc", value: 6475, slug: "gia-loc", status: "empty" },
          { label: "Bắc Thủy", value: 6478, slug: "bac-thuy", status: "empty" },
          {
            label: "Chiến Thắng",
            value: 6481,
            slug: "chien-thang",
            status: "empty",
          },
          { label: "Mai Sao", value: 6484, slug: "mai-sao", status: "empty" },
          { label: "Bằng Hữu", value: 6487, slug: "bang-huu", status: "empty" },
          {
            label: "Thượng Cường",
            value: 6490,
            slug: "thuong-cuong",
            status: "empty",
          },
          { label: "Bằng Mạc", value: 6493, slug: "bang-mac", status: "empty" },
          { label: "Nhân Lý", value: 6496, slug: "nhan-ly", status: "empty" },
          { label: "Lâm Sơn", value: 6499, slug: "lam-son", status: "empty" },
          { label: "Liên Sơn", value: 6502, slug: "lien-son", status: "empty" },
          { label: "Vạn Linh", value: 6505, slug: "van-linh", status: "empty" },
          { label: "Hòa Bình", value: 6508, slug: "hoa-binh", status: "empty" },
          { label: "Hữu Kiên", value: 6514, slug: "huu-kien", status: "empty" },
          { label: "Quan Sơn", value: 6517, slug: "quan-son", status: "empty" },
          { label: "Y Tịch", value: 6520, slug: "y-tich", status: "empty" },
          { label: "Chi Lăng", value: 6523, slug: "chi-lang", status: "empty" },
        ],
      },
      {
        label: "Huyện Lộc Bình",
        value: 188,
        slug: "huyen-loc-binh",
        status: "empty",
        ward: [
          { label: "Na Dương", value: 6526, slug: "na-duong", status: "empty" },
          { label: "Lộc Bình", value: 6529, slug: "loc-binh", status: "empty" },
          { label: "Mẫu Sơn", value: 6532, slug: "mau-son", status: "empty" },
          {
            label: "Yên Khoái",
            value: 6541,
            slug: "yen-khoai",
            status: "empty",
          },
          {
            label: "Khánh Xuân",
            value: 6544,
            slug: "khanh-xuan",
            status: "empty",
          },
          { label: "Tú Mịch", value: 6547, slug: "tu-mich", status: "empty" },
          {
            label: "Hữu Khánh",
            value: 6550,
            slug: "huu-khanh",
            status: "empty",
          },
          { label: "Đồng Bục", value: 6553, slug: "dong-buc", status: "empty" },
          { label: "Tam Gia", value: 6559, slug: "tam-gia", status: "empty" },
          { label: "Tú Đoạn", value: 6562, slug: "tu-doan", status: "empty" },
          { label: "Khuất Xá", value: 6565, slug: "khuat-xa", status: "empty" },
          {
            label: "Thống Nhất",
            value: 6577,
            slug: "thong-nhat",
            status: "empty",
          },
          { label: "Sàn Viên", value: 6589, slug: "san-vien", status: "empty" },
          {
            label: "Đông Quan",
            value: 6592,
            slug: "dong-quan",
            status: "empty",
          },
          {
            label: "Minh Hiệp",
            value: 6595,
            slug: "minh-hiep",
            status: "empty",
          },
          { label: "Hữu Lân", value: 6598, slug: "huu-lan", status: "empty" },
          { label: "Lợi Bác", value: 6601, slug: "loi-bac", status: "empty" },
          { label: "Nam Quan", value: 6604, slug: "nam-quan", status: "empty" },
          {
            label: "Xuân Dương",
            value: 6607,
            slug: "xuan-duong",
            status: "empty",
          },
          { label: "Ái Quốc", value: 6610, slug: "ai-quoc", status: "empty" },
        ],
      },
      {
        label: "Huyện Đình Lập",
        value: 189,
        slug: "huyen-dinh-lap",
        status: "empty",
        ward: [
          { label: "Đình Lập", value: 6613, slug: "dinh-lap", status: "empty" },
          {
            label: "NT Thái Bình",
            value: 6616,
            slug: "nt-thai-binh",
            status: "empty",
          },
          { label: "Bắc Xa", value: 6619, slug: "bac-xa", status: "empty" },
          { label: "Bính Xá", value: 6622, slug: "binh-xa", status: "empty" },
          { label: "Kiên Mộc", value: 6625, slug: "kien-moc", status: "empty" },
          { label: "Đình Lập", value: 6628, slug: "dinh-lap", status: "empty" },
          {
            label: "Thái Bình",
            value: 6631,
            slug: "thai-binh",
            status: "empty",
          },
          {
            label: "Cường Lợi",
            value: 6634,
            slug: "cuong-loi",
            status: "empty",
          },
          { label: "Châu Sơn", value: 6637, slug: "chau-son", status: "empty" },
          { label: "Lâm Ca", value: 6640, slug: "lam-ca", status: "empty" },
          {
            label: "Đồng Thắng",
            value: 6643,
            slug: "dong-thang",
            status: "empty",
          },
          { label: "Bắc Lãng", value: 6646, slug: "bac-lang", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Quảng Ninh",
    value: 22,
    slug: "quang-ninh",
    status: "filled",
    district: [
      {
        label: "Thành phố Hạ Long",
        value: 193,
        slug: "thanh-pho-ha-long",
        status: "filled",
        ward: [
          { label: "Hà Khánh", value: 6649, slug: "ha-khanh", status: "empty" },
          { label: "Hà Phong", value: 6652, slug: "ha-phong", status: "empty" },
          { label: "Hà Khẩu", value: 6655, slug: "ha-khau", status: "empty" },
          { label: "Cao Xanh", value: 6658, slug: "cao-xanh", status: "empty" },
          {
            label: "Giếng Đáy",
            value: 6661,
            slug: "gieng-day",
            status: "empty",
          },
          { label: "Hà Tu", value: 6664, slug: "ha-tu", status: "empty" },
          { label: "Hà Trung", value: 6667, slug: "ha-trung", status: "empty" },
          { label: "Hà Lầm", value: 6670, slug: "ha-lam", status: "empty" },
          {
            label: "Bãi Cháy",
            value: 6673,
            slug: "bai-chay",
            status: "filled",
          },
          {
            label: "Cao Thắng",
            value: 6676,
            slug: "cao-thang",
            status: "empty",
          },
          {
            label: "Hùng Thắng",
            value: 6679,
            slug: "hung-thang",
            status: "empty",
          },
          {
            label: "Trần Hưng Đạo",
            value: 6685,
            slug: "tran-hung-dao",
            status: "filled",
          },
          {
            label: "Hồng Hải",
            value: 6688,
            slug: "hong-hai",
            status: "filled",
          },
          { label: "Hồng Gai", value: 6691, slug: "hong-gai", status: "empty" },
          {
            label: "Bạch Đằng",
            value: 6694,
            slug: "bach-dang",
            status: "filled",
          },
          { label: "Hồng Hà", value: 6697, slug: "hong-ha", status: "empty" },
          {
            label: "Tuần Châu",
            value: 6700,
            slug: "tuan-chau",
            status: "filled",
          },
          {
            label: "Việt Hưng",
            value: 6703,
            slug: "viet-hung",
            status: "empty",
          },
          { label: "Đại Yên", value: 6706, slug: "dai-yen", status: "empty" },
          { label: "Hoành Bồ", value: 7030, slug: "hoanh-bo", status: "empty" },
          {
            label: "Kỳ Thượng",
            value: 7033,
            slug: "ky-thuong",
            status: "empty",
          },
          { label: "Đồng Sơn", value: 7036, slug: "dong-son", status: "empty" },
          { label: "Tân Dân", value: 7039, slug: "tan-dan", status: "empty" },
          { label: "Đồng Lâm", value: 7042, slug: "dong-lam", status: "empty" },
          { label: "Hòa Bình", value: 7045, slug: "hoa-binh", status: "empty" },
          { label: "Vũ Oai", value: 7048, slug: "vu-oai", status: "empty" },
          { label: "Dân Chủ", value: 7051, slug: "dan-chu", status: "empty" },
          { label: "Quảng La", value: 7054, slug: "quang-la", status: "empty" },
          { label: "Bằng Cả", value: 7057, slug: "bang-ca", status: "empty" },
          {
            label: "Thống Nhất",
            value: 7060,
            slug: "thong-nhat",
            status: "empty",
          },
          {
            label: "Sơn Dương",
            value: 7063,
            slug: "son-duong",
            status: "empty",
          },
          { label: "Lê Lợi", value: 7066, slug: "le-loi", status: "empty" },
        ],
      },
      {
        label: "Thành phố Móng Cái",
        value: 194,
        slug: "thanh-pho-mong-cai",
        status: "filled",
        ward: [
          { label: "Ka Long", value: 6709, slug: "ka-long", status: "empty" },
          {
            label: "Trần Phú",
            value: 6712,
            slug: "tran-phu",
            status: "filled",
          },
          {
            label: "Ninh Dương",
            value: 6715,
            slug: "ninh-duong",
            status: "empty",
          },
          { label: "Trà Cổ", value: 6721, slug: "tra-co", status: "empty" },
          { label: "Hải Sơn", value: 6724, slug: "hai-son", status: "empty" },
          { label: "Bắc Sơn", value: 6727, slug: "bac-son", status: "empty" },
          { label: "Hải Đông", value: 6730, slug: "hai-dong", status: "empty" },
          { label: "Hải Tiến", value: 6733, slug: "hai-tien", status: "empty" },
          { label: "Hải Yên", value: 6736, slug: "hai-yen", status: "empty" },
          {
            label: "Quảng Nghĩa",
            value: 6739,
            slug: "quang-nghia",
            status: "empty",
          },
          { label: "Hải Hoà", value: 6742, slug: "hai-hoa", status: "empty" },
          { label: "Hải Xuân", value: 6745, slug: "hai-xuan", status: "empty" },
          { label: "Vạn Ninh", value: 6748, slug: "van-ninh", status: "empty" },
          {
            label: "Bình Ngọc",
            value: 6751,
            slug: "binh-ngoc",
            status: "empty",
          },
          {
            label: "Vĩnh Trung",
            value: 6754,
            slug: "vinh-trung",
            status: "empty",
          },
          {
            label: "Vĩnh Thực",
            value: 6757,
            slug: "vinh-thuc",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Cẩm Phả",
        value: 195,
        slug: "thanh-pho-cam-pha",
        status: "filled",
        ward: [
          {
            label: "Mông Dương",
            value: 6760,
            slug: "mong-duong",
            status: "empty",
          },
          { label: "Cửa Ông", value: 6763, slug: "cua-ong", status: "empty" },
          { label: "Cẩm Sơn", value: 6766, slug: "cam-son", status: "empty" },
          {
            label: "Cẩm Đông",
            value: 6769,
            slug: "cam-dong",
            status: "filled",
          },
          { label: "Cẩm Phú", value: 6772, slug: "cam-phu", status: "empty" },
          { label: "Cẩm Tây", value: 6775, slug: "cam-tay", status: "empty" },
          {
            label: "Quang Hanh",
            value: 6778,
            slug: "quang-hanh",
            status: "empty",
          },
          {
            label: "Cẩm Thịnh",
            value: 6781,
            slug: "cam-thinh",
            status: "empty",
          },
          { label: "Cẩm Thủy", value: 6784, slug: "cam-thuy", status: "empty" },
          {
            label: "Cẩm Thạch",
            value: 6787,
            slug: "cam-thach",
            status: "empty",
          },
          {
            label: "Cẩm Thành",
            value: 6790,
            slug: "cam-thanh",
            status: "empty",
          },
          {
            label: "Cẩm Trung",
            value: 6793,
            slug: "cam-trung",
            status: "empty",
          },
          { label: "Cẩm Bình", value: 6796, slug: "cam-binh", status: "empty" },
          { label: "Hải Hòa", value: 6799, slug: "hai-hoa", status: "empty" },
          {
            label: "Dương Huy",
            value: 6805,
            slug: "duong-huy",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Uông Bí",
        value: 196,
        slug: "thanh-pho-uong-bi",
        status: "filled",
        ward: [
          {
            label: "Vàng Danh",
            value: 6808,
            slug: "vang-danh",
            status: "empty",
          },
          {
            label: "Thanh Sơn",
            value: 6811,
            slug: "thanh-son",
            status: "empty",
          },
          { label: "Bắc Sơn", value: 6814, slug: "bac-son", status: "empty" },
          {
            label: "Quang Trung",
            value: 6817,
            slug: "quang-trung",
            status: "filled",
          },
          {
            label: "Trưng Vương",
            value: 6820,
            slug: "trung-vuong",
            status: "empty",
          },
          { label: "Nam Khê", value: 6823, slug: "nam-khe", status: "empty" },
          {
            label: "Yên Thanh",
            value: 6826,
            slug: "yen-thanh",
            status: "empty",
          },
          {
            label: "Thượng Yên Công",
            value: 6829,
            slug: "thuong-yen-cong",
            status: "empty",
          },
          {
            label: "Phương Đông",
            value: 6832,
            slug: "phuong-dong",
            status: "empty",
          },
          {
            label: "Phương Nam",
            value: 6835,
            slug: "phuong-nam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bình Liêu",
        value: 198,
        slug: "huyen-binh-lieu",
        status: "empty",
        ward: [
          {
            label: "Bình Liêu",
            value: 6838,
            slug: "binh-lieu",
            status: "empty",
          },
          { label: "Hoành Mô", value: 6841, slug: "hoanh-mo", status: "empty" },
          { label: "Đồng Tâm", value: 6844, slug: "dong-tam", status: "empty" },
          { label: "Đồng Văn", value: 6847, slug: "dong-van", status: "empty" },
          { label: "Vô Ngại", value: 6853, slug: "vo-ngai", status: "empty" },
          { label: "Lục Hồn", value: 6856, slug: "luc-hon", status: "empty" },
          { label: "Húc Động", value: 6859, slug: "huc-dong", status: "empty" },
        ],
      },
      {
        label: "Huyện Tiên Yên",
        value: 199,
        slug: "huyen-tien-yen",
        status: "empty",
        ward: [
          { label: "Tiên Yên", value: 6862, slug: "tien-yen", status: "empty" },
          { label: "Hà Lâu", value: 6865, slug: "ha-lau", status: "empty" },
          { label: "Đại Dực", value: 6868, slug: "dai-duc", status: "empty" },
          { label: "Phong Dụ", value: 6871, slug: "phong-du", status: "empty" },
          { label: "Điền Xá", value: 6874, slug: "dien-xa", status: "empty" },
          { label: "Đông Ngũ", value: 6877, slug: "dong-ngu", status: "empty" },
          { label: "Yên Than", value: 6880, slug: "yen-than", status: "empty" },
          { label: "Đông Hải", value: 6883, slug: "dong-hai", status: "empty" },
          { label: "Hải Lạng", value: 6886, slug: "hai-lang", status: "empty" },
          {
            label: "Tiên Lãng",
            value: 6889,
            slug: "tien-lang",
            status: "empty",
          },
          { label: "Đồng Rui", value: 6892, slug: "dong-rui", status: "empty" },
        ],
      },
      {
        label: "Huyện Đầm Hà",
        value: 200,
        slug: "huyen-dam-ha",
        status: "empty",
        ward: [
          { label: "Đầm Hà", value: 6895, slug: "dam-ha", status: "empty" },
          {
            label: "Quảng Lâm",
            value: 6898,
            slug: "quang-lam",
            status: "empty",
          },
          { label: "Quảng An", value: 6901, slug: "quang-an", status: "empty" },
          { label: "Tân Bình", value: 6904, slug: "tan-binh", status: "empty" },
          { label: "Dực Yên", value: 6910, slug: "duc-yen", status: "empty" },
          {
            label: "Quảng Tân",
            value: 6913,
            slug: "quang-tan",
            status: "empty",
          },
          { label: "Đầm Hà", value: 6916, slug: "dam-ha", status: "empty" },
          { label: "Tân Lập", value: 6917, slug: "tan-lap", status: "empty" },
          { label: "Đại Bình", value: 6919, slug: "dai-binh", status: "empty" },
        ],
      },
      {
        label: "Huyện Hải Hà",
        value: 201,
        slug: "huyen-hai-ha",
        status: "empty",
        ward: [
          { label: "Quảng Hà", value: 6922, slug: "quang-ha", status: "empty" },
          {
            label: "Quảng Đức",
            value: 6925,
            slug: "quang-duc",
            status: "empty",
          },
          {
            label: "Quảng Sơn",
            value: 6928,
            slug: "quang-son",
            status: "empty",
          },
          {
            label: "Quảng Thành",
            value: 6931,
            slug: "quang-thanh",
            status: "empty",
          },
          {
            label: "Quảng Thịnh",
            value: 6937,
            slug: "quang-thinh",
            status: "empty",
          },
          {
            label: "Quảng Minh",
            value: 6940,
            slug: "quang-minh",
            status: "empty",
          },
          {
            label: "Quảng Chính",
            value: 6943,
            slug: "quang-chinh",
            status: "empty",
          },
          {
            label: "Quảng Long",
            value: 6946,
            slug: "quang-long",
            status: "empty",
          },
          {
            label: "Đường Hoa",
            value: 6949,
            slug: "duong-hoa",
            status: "empty",
          },
          {
            label: "Quảng Phong",
            value: 6952,
            slug: "quang-phong",
            status: "empty",
          },
          {
            label: "Cái Chiên",
            value: 6967,
            slug: "cai-chien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ba Chẽ",
        value: 202,
        slug: "huyen-ba-che",
        status: "empty",
        ward: [
          { label: "Ba Chẽ", value: 6970, slug: "ba-che", status: "empty" },
          {
            label: "Thanh Sơn",
            value: 6973,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Thanh Lâm",
            value: 6976,
            slug: "thanh-lam",
            status: "empty",
          },
          {
            label: "Đạp Thanh",
            value: 6979,
            slug: "dap-thanh",
            status: "empty",
          },
          { label: "Nam Sơn", value: 6982, slug: "nam-son", status: "empty" },
          {
            label: "Lương Minh",
            value: 6985,
            slug: "luong-minh",
            status: "empty",
          },
          { label: "Đồn Đạc", value: 6988, slug: "don-dac", status: "empty" },
        ],
      },
      {
        label: "Huyện Vân Đồn",
        value: 203,
        slug: "huyen-van-don",
        status: "empty",
        ward: [
          { label: "Cái Rồng", value: 6994, slug: "cai-rong", status: "empty" },
          {
            label: "Đài Xuyên",
            value: 6997,
            slug: "dai-xuyen",
            status: "empty",
          },
          { label: "Bình Dân", value: 7000, slug: "binh-dan", status: "empty" },
          { label: "Vạn Yên", value: 7003, slug: "van-yen", status: "empty" },
          {
            label: "Minh Châu",
            value: 7006,
            slug: "minh-chau",
            status: "empty",
          },
          { label: "Đoàn Kết", value: 7009, slug: "doan-ket", status: "empty" },
          { label: "Hạ Long", value: 7012, slug: "ha-long", status: "empty" },
          { label: "Đông Xá", value: 7015, slug: "dong-xa", status: "empty" },
          { label: "Bản Sen", value: 7018, slug: "ban-sen", status: "empty" },
          {
            label: "Thắng Lợi",
            value: 7021,
            slug: "thang-loi",
            status: "empty",
          },
          { label: "Quan Lạn", value: 7024, slug: "quan-lan", status: "empty" },
          {
            label: "Ngọc Vừng",
            value: 7027,
            slug: "ngoc-vung",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Đông Triều",
        value: 205,
        slug: "thi-xa-dong-trieu",
        status: "filled",
        ward: [
          { label: "Mạo Khê", value: 7069, slug: "mao-khe", status: "filled" },
          { label: "An Sinh", value: 7075, slug: "an-sinh", status: "empty" },
          {
            label: "Tràng Lương",
            value: 7078,
            slug: "trang-luong",
            status: "empty",
          },
          { label: "Bình Khê", value: 7081, slug: "binh-khe", status: "empty" },
          { label: "Việt Dân", value: 7084, slug: "viet-dan", status: "empty" },
          {
            label: "Bình Dương",
            value: 7090,
            slug: "binh-duong",
            status: "empty",
          },
          {
            label: "Đức Chính",
            value: 7093,
            slug: "duc-chinh",
            status: "empty",
          },
          { label: "Tràng An", value: 7096, slug: "trang-an", status: "empty" },
          {
            label: "Nguyễn Huệ",
            value: 7099,
            slug: "nguyen-hue",
            status: "empty",
          },
          { label: "Thủy An", value: 7102, slug: "thuy-an", status: "empty" },
          { label: "Xuân Sơn", value: 7105, slug: "xuan-son", status: "empty" },
          {
            label: "Hồng Thái Tây",
            value: 7108,
            slug: "hong-thai-tay",
            status: "empty",
          },
          {
            label: "Hồng Thái Đông",
            value: 7111,
            slug: "hong-thai-dong",
            status: "empty",
          },
          {
            label: "Hoàng Quế",
            value: 7114,
            slug: "hoang-que",
            status: "empty",
          },
          { label: "Yên Thọ", value: 7117, slug: "yen-tho", status: "empty" },
          {
            label: "Hồng Phong",
            value: 7120,
            slug: "hong-phong",
            status: "empty",
          },
          { label: "Kim Sơn", value: 7123, slug: "kim-son", status: "empty" },
          { label: "Hưng Đạo", value: 7126, slug: "hung-dao", status: "empty" },
          { label: "Yên Đức", value: 7129, slug: "yen-duc", status: "empty" },
        ],
      },
      {
        label: "Thị xã Quảng Yên",
        value: 206,
        slug: "thi-xa-quang-yen",
        status: "empty",
        ward: [
          {
            label: "Quảng Yên",
            value: 7132,
            slug: "quang-yen",
            status: "empty",
          },
          { label: "Đông Mai", value: 7135, slug: "dong-mai", status: "empty" },
          {
            label: "Minh Thành",
            value: 7138,
            slug: "minh-thanh",
            status: "empty",
          },
          {
            label: "Sông Khoai",
            value: 7144,
            slug: "song-khoai",
            status: "empty",
          },
          { label: "Hiệp Hòa", value: 7147, slug: "hiep-hoa", status: "empty" },
          { label: "Cộng Hòa", value: 7150, slug: "cong-hoa", status: "empty" },
          { label: "Tiền An", value: 7153, slug: "tien-an", status: "empty" },
          {
            label: "Hoàng Tân",
            value: 7156,
            slug: "hoang-tan",
            status: "empty",
          },
          { label: "Tân An", value: 7159, slug: "tan-an", status: "empty" },
          {
            label: "Yên Giang",
            value: 7162,
            slug: "yen-giang",
            status: "empty",
          },
          { label: "Nam Hoà", value: 7165, slug: "nam-hoa", status: "empty" },
          { label: "Hà An", value: 7168, slug: "ha-an", status: "empty" },
          { label: "Cẩm La", value: 7171, slug: "cam-la", status: "empty" },
          {
            label: "Phong Hải",
            value: 7174,
            slug: "phong-hai",
            status: "empty",
          },
          { label: "Yên Hải", value: 7177, slug: "yen-hai", status: "empty" },
          { label: "Liên Hòa", value: 7180, slug: "lien-hoa", status: "empty" },
          {
            label: "Phong Cốc",
            value: 7183,
            slug: "phong-coc",
            status: "empty",
          },
          { label: "Liên Vị", value: 7186, slug: "lien-vi", status: "empty" },
          {
            label: "Tiền Phong",
            value: 7189,
            slug: "tien-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cô Tô",
        value: 207,
        slug: "huyen-co-to",
        status: "empty",
        ward: [
          { label: "Cô Tô", value: 7192, slug: "co-to", status: "empty" },
          {
            label: "Đồng Tiến",
            value: 7195,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Thanh Lân",
            value: 7198,
            slug: "thanh-lan",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Bắc Giang",
    value: 24,
    slug: "bac-giang",
    status: "filled",
    district: [
      {
        label: "Thành phố Bắc Giang",
        value: 213,
        slug: "thanh-pho-bac-giang",
        status: "empty",
        ward: [
          {
            label: "Thọ Xương",
            value: 7201,
            slug: "tho-xuong",
            status: "empty",
          },
          {
            label: "Ngô Quyền",
            value: 7207,
            slug: "ngo-quyen",
            status: "empty",
          },
          {
            label: "Hoàng Văn Thụ",
            value: 7210,
            slug: "hoang-van-thu",
            status: "empty",
          },
          { label: "Trần Phú", value: 7213, slug: "tran-phu", status: "empty" },
          { label: "Mỹ Độ", value: 7216, slug: "my-do", status: "empty" },
          { label: "Song Mai", value: 7222, slug: "song-mai", status: "empty" },
          {
            label: "Xương Giang",
            value: 7225,
            slug: "xuong-giang",
            status: "empty",
          },
          { label: "Đa Mai", value: 7228, slug: "da-mai", status: "empty" },
          { label: "Dĩnh Kế", value: 7231, slug: "dinh-ke", status: "empty" },
          { label: "Dĩnh Trì", value: 7441, slug: "dinh-tri", status: "empty" },
          {
            label: "Nham Biền",
            value: 7681,
            slug: "nham-bien",
            status: "empty",
          },
          { label: "Tân An", value: 7682, slug: "tan-an", status: "empty" },
          { label: "Tân Mỹ", value: 7687, slug: "tan-my", status: "empty" },
          {
            label: "Hương Gián",
            value: 7690,
            slug: "huong-gian",
            status: "empty",
          },
          { label: "Tân An", value: 7693, slug: "tan-an", status: "empty" },
          { label: "Đồng Sơn", value: 7696, slug: "dong-son", status: "empty" },
          { label: "Tân Tiến", value: 7699, slug: "tan-tien", status: "empty" },
          {
            label: "Quỳnh Sơn",
            value: 7702,
            slug: "quynh-son",
            status: "empty",
          },
          { label: "Song Khê", value: 7705, slug: "song-khe", status: "empty" },
          {
            label: "Nội Hoàng",
            value: 7708,
            slug: "noi-hoang",
            status: "empty",
          },
          {
            label: "Tiền Phong",
            value: 7711,
            slug: "tien-phong",
            status: "empty",
          },
          { label: "Xuân Phú", value: 7714, slug: "xuan-phu", status: "empty" },
          { label: "Tân Liễu", value: 7717, slug: "tan-lieu", status: "empty" },
          { label: "Trí Yên", value: 7720, slug: "tri-yen", status: "empty" },
          { label: "Lãng Sơn", value: 7723, slug: "lang-son", status: "empty" },
          { label: "Yên Lư", value: 7726, slug: "yen-lu", status: "empty" },
          {
            label: "Tiến Dũng",
            value: 7729,
            slug: "tien-dung",
            status: "empty",
          },
          { label: "Nham Sơn", value: 7732, slug: "nham-son", status: "empty" },
          {
            label: "Đức Giang",
            value: 7735,
            slug: "duc-giang",
            status: "empty",
          },
          {
            label: "Cảnh Thụy",
            value: 7738,
            slug: "canh-thuy",
            status: "empty",
          },
          { label: "Tư Mại", value: 7741, slug: "tu-mai", status: "empty" },
          {
            label: "Thắng Cương",
            value: 7744,
            slug: "thang-cuong",
            status: "empty",
          },
          {
            label: "Đồng Việt",
            value: 7747,
            slug: "dong-viet",
            status: "empty",
          },
          {
            label: "Đồng Phúc",
            value: 7750,
            slug: "dong-phuc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Yên Thế",
        value: 215,
        slug: "huyen-yen-the",
        status: "empty",
        ward: [
          {
            label: "Đồng Tiến",
            value: 7243,
            slug: "dong-tien",
            status: "empty",
          },
          { label: "Canh Nậu", value: 7246, slug: "canh-nau", status: "empty" },
          {
            label: "Xuân Lương",
            value: 7249,
            slug: "xuan-luong",
            status: "empty",
          },
          { label: "Tam Tiến", value: 7252, slug: "tam-tien", status: "empty" },
          {
            label: "Đồng Vương",
            value: 7255,
            slug: "dong-vuong",
            status: "empty",
          },
          { label: "Đồng Hưu", value: 7258, slug: "dong-huu", status: "empty" },
          { label: "Đồng Tâm", value: 7260, slug: "dong-tam", status: "empty" },
          { label: "Tân Hiệp", value: 7261, slug: "tan-hiep", status: "empty" },
          {
            label: "Tiến Thắng",
            value: 7264,
            slug: "tien-thang",
            status: "empty",
          },
          { label: "Đồng Lạc", value: 7270, slug: "dong-lac", status: "empty" },
          { label: "Đông Sơn", value: 7273, slug: "dong-son", status: "empty" },
          { label: "Hương Vĩ", value: 7279, slug: "huong-vi", status: "empty" },
          { label: "Đồng Kỳ", value: 7282, slug: "dong-ky", status: "empty" },
          {
            label: "An Thượng",
            value: 7285,
            slug: "an-thuong",
            status: "empty",
          },
          {
            label: "Phồn Xương",
            value: 7288,
            slug: "phon-xuong",
            status: "empty",
          },
          { label: "Tân Sỏi", value: 7291, slug: "tan-soi", status: "empty" },
          { label: "Bố Hạ", value: 7294, slug: "bo-ha", status: "empty" },
        ],
      },
      {
        label: "Huyện Tân Yên",
        value: 216,
        slug: "huyen-tan-yen",
        status: "filled",
        ward: [
          { label: "Nhã Nam", value: 7306, slug: "nha-nam", status: "filled" },
          {
            label: "Tân Trung",
            value: 7309,
            slug: "tan-trung",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 7315,
            slug: "quang-trung",
            status: "empty",
          },
          { label: "An Dương", value: 7321, slug: "an-duong", status: "empty" },
          { label: "Phúc Hòa", value: 7324, slug: "phuc-hoa", status: "empty" },
          { label: "Liên Sơn", value: 7327, slug: "lien-son", status: "empty" },
          { label: "Hợp Đức", value: 7330, slug: "hop-duc", status: "empty" },
          { label: "Lam Sơn", value: 7333, slug: "lam-son", status: "empty" },
          { label: "Cao Xá", value: 7336, slug: "cao-xa", status: "empty" },
          {
            label: "Cao Thượng",
            value: 7339,
            slug: "cao-thuong",
            status: "empty",
          },
          {
            label: "Việt Ngọc",
            value: 7342,
            slug: "viet-ngoc",
            status: "empty",
          },
          { label: "Song Vân", value: 7345, slug: "song-van", status: "empty" },
          {
            label: "Ngọc Châu",
            value: 7348,
            slug: "ngoc-chau",
            status: "empty",
          },
          { label: "Ngọc Vân", value: 7351, slug: "ngoc-van", status: "empty" },
          { label: "Việt Lập", value: 7354, slug: "viet-lap", status: "empty" },
          {
            label: "Liên Chung",
            value: 7357,
            slug: "lien-chung",
            status: "empty",
          },
          {
            label: "Ngọc Thiện",
            value: 7360,
            slug: "ngoc-thien",
            status: "empty",
          },
          { label: "Ngọc Lý", value: 7363, slug: "ngoc-ly", status: "empty" },
          { label: "Quế Nham", value: 7366, slug: "que-nham", status: "empty" },
        ],
      },
      {
        label: "Huyện Lạng Giang",
        value: 217,
        slug: "huyen-lang-giang",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Vôi",
            value: 7375,
            slug: "thi-tran-voi",
            status: "empty",
          },
          {
            label: "Nghĩa Hòa",
            value: 7378,
            slug: "nghia-hoa",
            status: "empty",
          },
          {
            label: "Nghĩa Hưng",
            value: 7381,
            slug: "nghia-hung",
            status: "empty",
          },
          {
            label: "Quang Thịnh",
            value: 7384,
            slug: "quang-thinh",
            status: "empty",
          },
          {
            label: "Hương Sơn",
            value: 7387,
            slug: "huong-son",
            status: "empty",
          },
          { label: "Đào Mỹ", value: 7390, slug: "dao-my", status: "empty" },
          { label: "Tiên Lục", value: 7393, slug: "tien-luc", status: "empty" },
          { label: "An Hà", value: 7396, slug: "an-ha", status: "empty" },
          {
            label: "Thị trấn Kép",
            value: 7399,
            slug: "thi-tran-kep",
            status: "empty",
          },
          {
            label: "Hương Lạc",
            value: 7405,
            slug: "huong-lac",
            status: "empty",
          },
          {
            label: "Dương Đức",
            value: 7408,
            slug: "duong-duc",
            status: "empty",
          },
          {
            label: "Tân Thanh",
            value: 7411,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Tân Hưng", value: 7417, slug: "tan-hung", status: "empty" },
          { label: "Mỹ Thái", value: 7420, slug: "my-thai", status: "empty" },
          {
            label: "Xương Lâm",
            value: 7426,
            slug: "xuong-lam",
            status: "empty",
          },
          {
            label: "Xuân Hương",
            value: 7429,
            slug: "xuan-huong",
            status: "empty",
          },
          { label: "Tân Dĩnh", value: 7432, slug: "tan-dinh", status: "empty" },
          { label: "Đại Lâm", value: 7435, slug: "dai-lam", status: "empty" },
          { label: "Thái Đào", value: 7438, slug: "thai-dao", status: "empty" },
        ],
      },
      {
        label: "Huyện Lục Nam",
        value: 218,
        slug: "huyen-luc-nam",
        status: "empty",
        ward: [
          { label: "Đồi Ngô", value: 7444, slug: "doi-ngo", status: "empty" },
          {
            label: "Đông Hưng",
            value: 7450,
            slug: "dong-hung",
            status: "empty",
          },
          { label: "Đông Phú", value: 7453, slug: "dong-phu", status: "empty" },
          { label: "Tam Dị", value: 7456, slug: "tam-di", status: "empty" },
          { label: "Bảo Sơn", value: 7459, slug: "bao-son", status: "empty" },
          { label: "Bảo Đài", value: 7462, slug: "bao-dai", status: "empty" },
          {
            label: "Thanh Lâm",
            value: 7465,
            slug: "thanh-lam",
            status: "empty",
          },
          { label: "Tiên Nha", value: 7468, slug: "tien-nha", status: "empty" },
          {
            label: "Trường Giang",
            value: 7471,
            slug: "truong-giang",
            status: "empty",
          },
          {
            label: "Phương Sơn",
            value: 7477,
            slug: "phuong-son",
            status: "empty",
          },
          { label: "Chu Điện", value: 7480, slug: "chu-dien", status: "empty" },
          {
            label: "Cương Sơn",
            value: 7483,
            slug: "cuong-son",
            status: "empty",
          },
          {
            label: "Nghĩa Phương",
            value: 7486,
            slug: "nghia-phuong",
            status: "empty",
          },
          { label: "Vô Tranh", value: 7489, slug: "vo-tranh", status: "empty" },
          { label: "Bình Sơn", value: 7492, slug: "binh-son", status: "empty" },
          { label: "Lan Mẫu", value: 7495, slug: "lan-mau", status: "empty" },
          { label: "Yên Sơn", value: 7498, slug: "yen-son", status: "empty" },
          {
            label: "Khám Lạng",
            value: 7501,
            slug: "kham-lang",
            status: "empty",
          },
          {
            label: "Huyền Sơn",
            value: 7504,
            slug: "huyen-son",
            status: "empty",
          },
          {
            label: "Trường Sơn",
            value: 7507,
            slug: "truong-son",
            status: "empty",
          },
          { label: "Lục Sơn", value: 7510, slug: "luc-son", status: "empty" },
          { label: "Bắc Lũng", value: 7513, slug: "bac-lung", status: "empty" },
          { label: "Cẩm Lý", value: 7519, slug: "cam-ly", status: "empty" },
          { label: "Đan Hội", value: 7522, slug: "dan-hoi", status: "empty" },
        ],
      },
      {
        label: "Huyện Lục Ngạn",
        value: 219,
        slug: "huyen-luc-ngan",
        status: "empty",
        ward: [
          { label: "Cấm Sơn", value: 7528, slug: "cam-son", status: "empty" },
          { label: "Tân Sơn", value: 7531, slug: "tan-son", status: "empty" },
          {
            label: "Phong Minh",
            value: 7534,
            slug: "phong-minh",
            status: "empty",
          },
          {
            label: "Phong Vân",
            value: 7537,
            slug: "phong-van",
            status: "empty",
          },
          { label: "Xa Lý", value: 7540, slug: "xa-ly", status: "empty" },
          { label: "Hộ Đáp", value: 7543, slug: "ho-dap", status: "empty" },
          { label: "Sơn Hải", value: 7546, slug: "son-hai", status: "empty" },
          { label: "Biên Sơn", value: 7555, slug: "bien-son", status: "empty" },
          { label: "Kim Sơn", value: 7564, slug: "kim-son", status: "empty" },
          { label: "Tân Hoa", value: 7567, slug: "tan-hoa", status: "empty" },
          { label: "Giáp Sơn", value: 7570, slug: "giap-son", status: "empty" },
          {
            label: "Biển Động",
            value: 7573,
            slug: "bien-dong",
            status: "empty",
          },
          { label: "Phì Điền", value: 7582, slug: "phi-dien", status: "empty" },
          {
            label: "Tân Quang",
            value: 7588,
            slug: "tan-quang",
            status: "empty",
          },
          { label: "Đồng Cốc", value: 7591, slug: "dong-coc", status: "empty" },
          { label: "Tân Lập", value: 7594, slug: "tan-lap", status: "empty" },
          {
            label: "Phú Nhuận",
            value: 7597,
            slug: "phu-nhuan",
            status: "empty",
          },
          { label: "Tân Mộc", value: 7606, slug: "tan-moc", status: "empty" },
          { label: "Đèo Gia", value: 7609, slug: "deo-gia", status: "empty" },
        ],
      },
      {
        label: "Huyện Sơn Động",
        value: 220,
        slug: "huyen-son-dong",
        status: "empty",
        ward: [
          { label: "An Châu", value: 7615, slug: "an-chau", status: "empty" },
          {
            label: "Tây Yên Tử",
            value: 7616,
            slug: "tay-yen-tu",
            status: "empty",
          },
          { label: "Vân Sơn", value: 7621, slug: "van-son", status: "empty" },
          { label: "Hữu Sản", value: 7624, slug: "huu-san", status: "empty" },
          { label: "Đại Sơn", value: 7627, slug: "dai-son", status: "empty" },
          { label: "Phúc Sơn", value: 7630, slug: "phuc-son", status: "empty" },
          {
            label: "Giáo Liêm",
            value: 7636,
            slug: "giao-liem",
            status: "empty",
          },
          { label: "Cẩm Đàn", value: 7642, slug: "cam-dan", status: "empty" },
          { label: "An Lạc", value: 7645, slug: "an-lac", status: "empty" },
          { label: "Vĩnh An", value: 7648, slug: "vinh-an", status: "empty" },
          { label: "Yên Định", value: 7651, slug: "yen-dinh", status: "empty" },
          { label: "Lệ Viễn", value: 7654, slug: "le-vien", status: "empty" },
          { label: "An Bá", value: 7660, slug: "an-ba", status: "empty" },
          { label: "Tuấn Đạo", value: 7663, slug: "tuan-dao", status: "empty" },
          {
            label: "Dương Hưu",
            value: 7666,
            slug: "duong-huu",
            status: "empty",
          },
          { label: "Long Sơn", value: 7672, slug: "long-son", status: "empty" },
          {
            label: "Thanh Luận",
            value: 7678,
            slug: "thanh-luan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Yên Dũng",
        value: 221,
        slug: "huyen-yen-dung",
        status: "empty",
        ward: [
          {
            label: "Nham Biền",
            value: 100000,
            slug: "nham-bien",
            status: "empty",
          },
          { label: "Tân An", value: 100001, slug: "tan-an", status: "empty" },
          {
            label: "Cảnh Thụy",
            value: 100002,
            slug: "canh-thuy",
            status: "empty",
          },
          {
            label: "Đồng Phúc",
            value: 100003,
            slug: "dong-phuc",
            status: "empty",
          },
          {
            label: "Đồng Việt",
            value: 100004,
            slug: "dong-viet",
            status: "empty",
          },
          {
            label: "Đức Giang",
            value: 100005,
            slug: "duc-giang",
            status: "empty",
          },
          {
            label: "Hương Gián",
            value: 100006,
            slug: "huong-gian",
            status: "empty",
          },
          {
            label: "Lãng Sơn",
            value: 100007,
            slug: "lang-son",
            status: "empty",
          },
          { label: "Lão Hộ", value: 100008, slug: "lao-ho", status: "empty" },
          {
            label: "Nội Hoàng",
            value: 100009,
            slug: "noi-hoang",
            status: "empty",
          },
          {
            label: "Quỳnh Sơn",
            value: 100010,
            slug: "quynh-son",
            status: "empty",
          },
          {
            label: "Tân Liễu",
            value: 100011,
            slug: "tan-lieu",
            status: "empty",
          },
          {
            label: "Tiến Dũng",
            value: 100012,
            slug: "tien-dung",
            status: "empty",
          },
          {
            label: "Tiền Phong",
            value: 100013,
            slug: "tien-phong",
            status: "empty",
          },
          { label: "Trí Yên", value: 100014, slug: "tri-yen", status: "empty" },
          { label: "Tư Mại", value: 100015, slug: "tu-mai", status: "empty" },
          {
            label: "Xuân Phú",
            value: 100016,
            slug: "xuan-phu",
            status: "empty",
          },
          { label: "Yên Lư", value: 100017, slug: "yen-lu", status: "empty" },
        ],
      },
      {
        label: "Huyện Việt Yên",
        value: 222,
        slug: "huyen-viet-yen",
        status: "empty",
        ward: [
          {
            label: "Thượng Lan",
            value: 7759,
            slug: "thuong-lan",
            status: "empty",
          },
          {
            label: "Việt Tiến",
            value: 7762,
            slug: "viet-tien",
            status: "empty",
          },
          {
            label: "Nghĩa Trung",
            value: 7765,
            slug: "nghia-trung",
            status: "empty",
          },
          { label: "Minh Đức", value: 7768, slug: "minh-duc", status: "empty" },
          {
            label: "Hương Mai",
            value: 7771,
            slug: "huong-mai",
            status: "empty",
          },
          { label: "Tự Lạn", value: 7774, slug: "tu-lan", status: "empty" },
          {
            label: "Bích Động",
            value: 7777,
            slug: "bich-dong",
            status: "empty",
          },
          {
            label: "Trung Sơn",
            value: 7780,
            slug: "trung-son",
            status: "empty",
          },
          {
            label: "Hồng Thái",
            value: 7783,
            slug: "hong-thai",
            status: "empty",
          },
          { label: "Tiên Sơn", value: 7786, slug: "tien-son", status: "empty" },
          {
            label: "Tăng Tiến",
            value: 7789,
            slug: "tang-tien",
            status: "empty",
          },
          {
            label: "Quảng Minh",
            value: 7792,
            slug: "quang-minh",
            status: "empty",
          },
          {
            label: "Phường Nếnh",
            value: 7795,
            slug: "phuong-nenh",
            status: "empty",
          },
          { label: "Ninh Sơn", value: 7798, slug: "ninh-son", status: "empty" },
          {
            label: "Vân Trung",
            value: 7801,
            slug: "van-trung",
            status: "empty",
          },
          { label: "Vân Hà", value: 7804, slug: "van-ha", status: "empty" },
          {
            label: "Quang Châu",
            value: 7807,
            slug: "quang-chau",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hiệp Hòa",
        value: 223,
        slug: "huyen-hiep-hoa",
        status: "empty",
        ward: [
          {
            label: "Đồng Tiến",
            value: 7816,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Hoàng Vân",
            value: 7822,
            slug: "hoang-van",
            status: "empty",
          },
          {
            label: "Toàn Thắng",
            value: 7825,
            slug: "toan-thang",
            status: "empty",
          },
          { label: "Ngọc Sơn", value: 7831, slug: "ngoc-son", status: "empty" },
          {
            label: "Thị trấn Thắng",
            value: 7840,
            slug: "thi-tran-thang",
            status: "empty",
          },
          {
            label: "Sơn Thịnh",
            value: 7843,
            slug: "son-thinh",
            status: "empty",
          },
          {
            label: "Lương Phong",
            value: 7846,
            slug: "luong-phong",
            status: "empty",
          },
          {
            label: "Hùng Thái",
            value: 7849,
            slug: "hung-thai",
            status: "empty",
          },
          {
            label: "Thường Thắng",
            value: 7855,
            slug: "thuong-thang",
            status: "empty",
          },
          {
            label: "Hợp Thịnh",
            value: 7858,
            slug: "hop-thinh",
            status: "empty",
          },
          {
            label: "Danh Thắng",
            value: 7861,
            slug: "danh-thang",
            status: "empty",
          },
          {
            label: "Mai Trung",
            value: 7864,
            slug: "mai-trung",
            status: "empty",
          },
          { label: "Đoan Bái", value: 7867, slug: "doan-bai", status: "empty" },
          { label: "Bắc Lý", value: 7870, slug: "bac-ly", status: "empty" },
          { label: "Xuân Cẩm", value: 7873, slug: "xuan-cam", status: "empty" },
          {
            label: "Hương Lâm",
            value: 7876,
            slug: "huong-lam",
            status: "empty",
          },
          { label: "Đông Lỗ", value: 7879, slug: "dong-lo", status: "empty" },
          {
            label: "Châu Minh",
            value: 7882,
            slug: "chau-minh",
            status: "empty",
          },
          { label: "Mai Đình", value: 7885, slug: "mai-dinh", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Phú Thọ",
    value: 25,
    slug: "phu-tho",
    status: "filled",
    district: [
      {
        label: "Thành phố Việt Trì",
        value: 227,
        slug: "thanh-pho-viet-tri",
        status: "filled",
        ward: [
          { label: "Dữu Lâu", value: 7888, slug: "duu-lau", status: "filled" },
          {
            label: "Nông Trang",
            value: 7894,
            slug: "nong-trang",
            status: "empty",
          },
          { label: "Tân Dân", value: 7897, slug: "tan-dan", status: "empty" },
          { label: "Gia Cẩm", value: 7900, slug: "gia-cam", status: "filled" },
          { label: "Tiên Cát", value: 7903, slug: "tien-cat", status: "empty" },
          { label: "Thọ Sơn", value: 7906, slug: "tho-son", status: "empty" },
          {
            label: "Thanh Miếu",
            value: 7909,
            slug: "thanh-mieu",
            status: "empty",
          },
          { label: "Bạch Hạc", value: 7912, slug: "bach-hac", status: "empty" },
          { label: "Vân Phú", value: 7918, slug: "van-phu", status: "empty" },
          {
            label: "Phượng Lâu",
            value: 7921,
            slug: "phuong-lau",
            status: "empty",
          },
          { label: "Thụy Vân", value: 7924, slug: "thuy-van", status: "empty" },
          {
            label: "Minh Phương",
            value: 7927,
            slug: "minh-phuong",
            status: "empty",
          },
          {
            label: "Trưng Vương",
            value: 7930,
            slug: "trung-vuong",
            status: "empty",
          },
          {
            label: "Minh Nông",
            value: 7933,
            slug: "minh-nong",
            status: "empty",
          },
          { label: "Sông Lô", value: 7936, slug: "song-lo", status: "empty" },
          { label: "Kim Đức", value: 8281, slug: "kim-duc", status: "empty" },
          { label: "Hùng Lô", value: 8287, slug: "hung-lo", status: "empty" },
          { label: "Hy Cương", value: 8503, slug: "hy-cuong", status: "empty" },
          { label: "Chu Hóa", value: 8506, slug: "chu-hoa", status: "empty" },
          {
            label: "Thanh Đình",
            value: 8515,
            slug: "thanh-dinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Phú Thọ",
        value: 228,
        slug: "thi-xa-phu-tho",
        status: "empty",
        ward: [
          {
            label: "Hùng Vương",
            value: 7942,
            slug: "hung-vuong",
            status: "empty",
          },
          {
            label: "Phong Châu",
            value: 7945,
            slug: "phong-chau",
            status: "empty",
          },
          { label: "Âu Cơ", value: 7948, slug: "au-co", status: "empty" },
          { label: "Hà Lộc", value: 7951, slug: "ha-loc", status: "empty" },
          { label: "Phú Hộ", value: 7954, slug: "phu-ho", status: "empty" },
          { label: "Văn Lung", value: 7957, slug: "van-lung", status: "empty" },
          {
            label: "Thanh Minh",
            value: 7960,
            slug: "thanh-minh",
            status: "empty",
          },
          { label: "Hà Thạch", value: 7963, slug: "ha-thach", status: "empty" },
          {
            label: "Thanh Vinh",
            value: 7966,
            slug: "thanh-vinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đoan Hùng",
        value: 230,
        slug: "huyen-doan-hung",
        status: "filled",
        ward: [
          {
            label: "Đoan Hùng",
            value: 7969,
            slug: "doan-hung",
            status: "filled",
          },
          {
            label: "Hùng Xuyên",
            value: 7975,
            slug: "hung-xuyen",
            status: "empty",
          },
          {
            label: "Bằng Luân",
            value: 7981,
            slug: "bang-luan",
            status: "empty",
          },
          { label: "Phú Lâm", value: 7987, slug: "phu-lam", status: "empty" },
          {
            label: "Bằng Doãn",
            value: 7996,
            slug: "bang-doan",
            status: "empty",
          },
          { label: "Chí Đám", value: 7999, slug: "chi-dam", status: "empty" },
          { label: "Phúc Lai", value: 8005, slug: "phuc-lai", status: "empty" },
          {
            label: "Ngọc Quan",
            value: 8008,
            slug: "ngoc-quan",
            status: "empty",
          },
          { label: "Hợp Nhất", value: 8014, slug: "hop-nhat", status: "empty" },
          { label: "Tây Cốc", value: 8023, slug: "tay-coc", status: "empty" },
          {
            label: "Hùng Long",
            value: 8035,
            slug: "hung-long",
            status: "empty",
          },
          { label: "Yên Kiện", value: 8038, slug: "yen-kien", status: "empty" },
          {
            label: "Chân Mộng",
            value: 8044,
            slug: "chan-mong",
            status: "empty",
          },
          { label: "Ca Đình", value: 8050, slug: "ca-dinh", status: "empty" },
        ],
      },
      {
        label: "Huyện Hạ Hoà",
        value: 231,
        slug: "huyen-ha-hoa",
        status: "empty",
        ward: [
          { label: "Hạ Hoà", value: 8053, slug: "ha-hoa", status: "empty" },
          { label: "Đại Phạm", value: 8056, slug: "dai-pham", status: "empty" },
          {
            label: "Đan Thượng",
            value: 8062,
            slug: "dan-thuong",
            status: "empty",
          },
          { label: "Hà Lương", value: 8065, slug: "ha-luong", status: "empty" },
          { label: "Tứ Hiệp", value: 8071, slug: "tu-hiep", status: "empty" },
          {
            label: "Hiền Lương",
            value: 8080,
            slug: "hien-luong",
            status: "empty",
          },
          {
            label: "Phương Viên",
            value: 8089,
            slug: "phuong-vien",
            status: "empty",
          },
          { label: "Gia Điền", value: 8092, slug: "gia-dien", status: "empty" },
          { label: "Ấm Hạ", value: 8095, slug: "am-ha", status: "empty" },
          { label: "Hương Xạ", value: 8104, slug: "huong-xa", status: "empty" },
          { label: "Xuân Áng", value: 8110, slug: "xuan-ang", status: "empty" },
          { label: "Yên Kỳ", value: 8113, slug: "yen-ky", status: "empty" },
          { label: "Minh Hạc", value: 8119, slug: "minh-hac", status: "empty" },
          { label: "Lang Sơn", value: 8122, slug: "lang-son", status: "empty" },
          { label: "Bằng Giã", value: 8125, slug: "bang-gia", status: "empty" },
          { label: "Yên Luật", value: 8128, slug: "yen-luat", status: "empty" },
          { label: "Vô Tranh", value: 8131, slug: "vo-tranh", status: "empty" },
          { label: "Văn Lang", value: 8134, slug: "van-lang", status: "empty" },
          { label: "Minh Côi", value: 8140, slug: "minh-coi", status: "empty" },
          {
            label: "Vĩnh Chân",
            value: 8143,
            slug: "vinh-chan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thanh Ba",
        value: 232,
        slug: "huyen-thanh-ba",
        status: "empty",
        ward: [
          { label: "Thanh Ba", value: 8152, slug: "thanh-ba", status: "empty" },
          { label: "Vân Lĩnh", value: 8156, slug: "van-linh", status: "empty" },
          {
            label: "Đông Lĩnh",
            value: 8158,
            slug: "dong-linh",
            status: "empty",
          },
          { label: "Đại An", value: 8161, slug: "dai-an", status: "empty" },
          { label: "Hanh Cù", value: 8164, slug: "hanh-cu", status: "empty" },
          {
            label: "Đồng Xuân",
            value: 8170,
            slug: "dong-xuan",
            status: "empty",
          },
          {
            label: "Quảng Yên",
            value: 8173,
            slug: "quang-yen",
            status: "empty",
          },
          { label: "Ninh Dân", value: 8179, slug: "ninh-dan", status: "empty" },
          { label: "Võ Lao", value: 8194, slug: "vo-lao", status: "empty" },
          {
            label: "Khải Xuân",
            value: 8197,
            slug: "khai-xuan",
            status: "empty",
          },
          { label: "Mạn Lạn", value: 8200, slug: "man-lan", status: "empty" },
          {
            label: "Hoàng Cương",
            value: 8203,
            slug: "hoang-cuong",
            status: "empty",
          },
          { label: "Chí Tiên", value: 8206, slug: "chi-tien", status: "empty" },
          {
            label: "Đông Thành",
            value: 8209,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Sơn Cương",
            value: 8215,
            slug: "son-cuong",
            status: "empty",
          },
          { label: "Thanh Hà", value: 8218, slug: "thanh-ha", status: "empty" },
          { label: "Đỗ Sơn", value: 8221, slug: "do-son", status: "empty" },
          { label: "Đỗ Xuyên", value: 8224, slug: "do-xuyen", status: "empty" },
          { label: "Lương Lỗ", value: 8227, slug: "luong-lo", status: "empty" },
        ],
      },
      {
        label: "Huyện Phù Ninh",
        value: 233,
        slug: "huyen-phu-ninh",
        status: "empty",
        ward: [
          {
            label: "Phong Châu",
            value: 8230,
            slug: "phong-chau",
            status: "empty",
          },
          { label: "Phú Mỹ", value: 8233, slug: "phu-my", status: "empty" },
          { label: "Lệ Mỹ", value: 8234, slug: "le-my", status: "empty" },
          { label: "Liên Hoa", value: 8236, slug: "lien-hoa", status: "empty" },
          {
            label: "Trạm Thản",
            value: 8239,
            slug: "tram-than",
            status: "empty",
          },
          { label: "Trị Quận", value: 8242, slug: "tri-quan", status: "empty" },
          {
            label: "Trung Giáp",
            value: 8245,
            slug: "trung-giap",
            status: "empty",
          },
          { label: "Tiên Phú", value: 8248, slug: "tien-phu", status: "empty" },
          { label: "Hạ Giáp", value: 8251, slug: "ha-giap", status: "empty" },
          {
            label: "Bảo Thanh",
            value: 8254,
            slug: "bao-thanh",
            status: "empty",
          },
          { label: "Phú Lộc", value: 8257, slug: "phu-loc", status: "empty" },
          {
            label: "Gia Thanh",
            value: 8260,
            slug: "gia-thanh",
            status: "empty",
          },
          { label: "Tiên Du", value: 8263, slug: "tien-du", status: "empty" },
          { label: "Phú Nham", value: 8266, slug: "phu-nham", status: "empty" },
          { label: "An Đạo", value: 8272, slug: "an-dao", status: "empty" },
          { label: "Bình Phú", value: 8275, slug: "binh-phu", status: "empty" },
          { label: "Phù Ninh", value: 8278, slug: "phu-ninh", status: "empty" },
        ],
      },
      {
        label: "Huyện Yên Lập",
        value: 234,
        slug: "huyen-yen-lap",
        status: "empty",
        ward: [
          { label: "Yên Lập", value: 8290, slug: "yen-lap", status: "empty" },
          { label: "Mỹ Lung", value: 8293, slug: "my-lung", status: "empty" },
          { label: "Mỹ Lương", value: 8296, slug: "my-luong", status: "empty" },
          {
            label: "Lương Sơn",
            value: 8299,
            slug: "luong-son",
            status: "empty",
          },
          { label: "Xuân An", value: 8302, slug: "xuan-an", status: "empty" },
          {
            label: "Xuân Viên",
            value: 8305,
            slug: "xuan-vien",
            status: "empty",
          },
          {
            label: "Xuân Thủy",
            value: 8308,
            slug: "xuan-thuy",
            status: "empty",
          },
          {
            label: "Trung Sơn",
            value: 8311,
            slug: "trung-son",
            status: "empty",
          },
          {
            label: "Hưng Long",
            value: 8314,
            slug: "hung-long",
            status: "empty",
          },
          {
            label: "Nga Hoàng",
            value: 8317,
            slug: "nga-hoang",
            status: "empty",
          },
          { label: "Đồng Lạc", value: 8320, slug: "dong-lac", status: "empty" },
          {
            label: "Thượng Long",
            value: 8323,
            slug: "thuong-long",
            status: "empty",
          },
          {
            label: "Đồng Thịnh",
            value: 8326,
            slug: "dong-thinh",
            status: "empty",
          },
          {
            label: "Phúc Khánh",
            value: 8329,
            slug: "phuc-khanh",
            status: "empty",
          },
          { label: "Minh Hòa", value: 8332, slug: "minh-hoa", status: "empty" },
          { label: "Ngọc Lập", value: 8335, slug: "ngoc-lap", status: "empty" },
          {
            label: "Ngọc Đồng",
            value: 8338,
            slug: "ngoc-dong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cẩm Khê",
        value: 235,
        slug: "huyen-cam-khe",
        status: "empty",
        ward: [
          { label: "Cẩm Khê", value: 8341, slug: "cam-khe", status: "empty" },
          {
            label: "Tiên Lương",
            value: 8344,
            slug: "tien-luong",
            status: "empty",
          },
          {
            label: "Minh Thắng",
            value: 8350,
            slug: "minh-thang",
            status: "empty",
          },
          { label: "Minh Tân", value: 8353, slug: "minh-tan", status: "empty" },
          {
            label: "Phượng Vĩ",
            value: 8356,
            slug: "phuong-vi",
            status: "empty",
          },
          { label: "Tùng Khê", value: 8374, slug: "tung-khe", status: "empty" },
          { label: "Tam Sơn", value: 8377, slug: "tam-son", status: "empty" },
          { label: "Văn Bán", value: 8380, slug: "van-ban", status: "empty" },
          {
            label: "Phong Thịnh",
            value: 8389,
            slug: "phong-thinh",
            status: "empty",
          },
          { label: "Phú Khê", value: 8398, slug: "phu-khe", status: "empty" },
          {
            label: "Hương Lung",
            value: 8401,
            slug: "huong-lung",
            status: "empty",
          },
          {
            label: "Nhật Tiến",
            value: 8413,
            slug: "nhat-tien",
            status: "empty",
          },
          {
            label: "Hùng Việt",
            value: 8416,
            slug: "hung-viet",
            status: "empty",
          },
          {
            label: "Yên Dưỡng",
            value: 8422,
            slug: "yen-duong",
            status: "empty",
          },
          {
            label: "Điêu Lương",
            value: 8428,
            slug: "dieu-luong",
            status: "empty",
          },
          {
            label: "Đồng Lương",
            value: 8431,
            slug: "dong-luong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tam Nông",
        value: 236,
        slug: "huyen-tam-nong",
        status: "empty",
        ward: [
          { label: "Hưng Hoá", value: 8434, slug: "hung-hoa", status: "empty" },
          {
            label: "Hiền Quan",
            value: 8440,
            slug: "hien-quan",
            status: "empty",
          },
          { label: "Bắc Sơn", value: 8443, slug: "bac-son", status: "empty" },
          {
            label: "Thanh Uyên",
            value: 8446,
            slug: "thanh-uyen",
            status: "empty",
          },
          { label: "Lam Sơn", value: 8461, slug: "lam-son", status: "empty" },
          { label: "Vạn Xuân", value: 8467, slug: "van-xuan", status: "empty" },
          {
            label: "Quang Húc",
            value: 8470,
            slug: "quang-huc",
            status: "empty",
          },
          {
            label: "Hương Nộn",
            value: 8473,
            slug: "huong-non",
            status: "empty",
          },
          { label: "Tề Lễ", value: 8476, slug: "te-le", status: "empty" },
          { label: "Thọ Văn", value: 8479, slug: "tho-van", status: "empty" },
          { label: "Dị Nậu", value: 8482, slug: "di-nau", status: "empty" },
          {
            label: "Dân Quyền",
            value: 8491,
            slug: "dan-quyen",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lâm Thao",
        value: 237,
        slug: "huyen-lam-thao",
        status: "empty",
        ward: [
          { label: "Lâm Thao", value: 8494, slug: "lam-thao", status: "empty" },
          {
            label: "Tiên Kiên",
            value: 8497,
            slug: "tien-kien",
            status: "empty",
          },
          { label: "Hùng Sơn", value: 8498, slug: "hung-son", status: "empty" },
          {
            label: "Xuân Lũng",
            value: 8500,
            slug: "xuan-lung",
            status: "empty",
          },
          { label: "Xuân Huy", value: 8509, slug: "xuan-huy", status: "empty" },
          {
            label: "Thạch Sơn",
            value: 8512,
            slug: "thach-son",
            status: "empty",
          },
          { label: "Sơn Vi", value: 8518, slug: "son-vi", status: "empty" },
          {
            label: "Phùng Nguyên",
            value: 8521,
            slug: "phung-nguyen",
            status: "empty",
          },
          { label: "Cao Xá", value: 8527, slug: "cao-xa", status: "empty" },
          { label: "Vĩnh Lại", value: 8533, slug: "vinh-lai", status: "empty" },
          { label: "Tứ Xã", value: 8536, slug: "tu-xa", status: "empty" },
          {
            label: "Bản Nguyên",
            value: 8539,
            slug: "ban-nguyen",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thanh Sơn",
        value: 238,
        slug: "huyen-thanh-son",
        status: "empty",
        ward: [
          {
            label: "Thanh Sơn",
            value: 8542,
            slug: "thanh-son",
            status: "empty",
          },
          { label: "Sơn Hùng", value: 8563, slug: "son-hung", status: "empty" },
          { label: "Địch Quả", value: 8572, slug: "dich-qua", status: "empty" },
          { label: "Giáp Lai", value: 8575, slug: "giap-lai", status: "empty" },
          {
            label: "Thục Luyện",
            value: 8581,
            slug: "thuc-luyen",
            status: "empty",
          },
          { label: "Võ Miếu", value: 8584, slug: "vo-mieu", status: "empty" },
          {
            label: "Thạch Khoán",
            value: 8587,
            slug: "thach-khoan",
            status: "empty",
          },
          { label: "Cự Thắng", value: 8602, slug: "cu-thang", status: "empty" },
          {
            label: "Tất Thắng",
            value: 8605,
            slug: "tat-thang",
            status: "empty",
          },
          { label: "Văn Miếu", value: 8611, slug: "van-mieu", status: "empty" },
          { label: "Cự Đồng", value: 8614, slug: "cu-dong", status: "empty" },
          {
            label: "Thắng Sơn",
            value: 8623,
            slug: "thang-son",
            status: "empty",
          },
          { label: "Tân Minh", value: 8629, slug: "tan-minh", status: "empty" },
          {
            label: "Hương Cần",
            value: 8632,
            slug: "huong-can",
            status: "empty",
          },
          { label: "Khả Cửu", value: 8635, slug: "kha-cuu", status: "empty" },
          { label: "Đông Cửu", value: 8638, slug: "dong-cuu", status: "empty" },
          { label: "Tân Lập", value: 8641, slug: "tan-lap", status: "empty" },
          { label: "Yên Lãng", value: 8644, slug: "yen-lang", status: "empty" },
          {
            label: "Yên Lương",
            value: 8647,
            slug: "yen-luong",
            status: "empty",
          },
          {
            label: "Thượng Cửu",
            value: 8650,
            slug: "thuong-cuu",
            status: "empty",
          },
          {
            label: "Lương Nha",
            value: 8653,
            slug: "luong-nha",
            status: "empty",
          },
          { label: "Yên Sơn", value: 8656, slug: "yen-son", status: "empty" },
          {
            label: "Tinh Nhuệ",
            value: 8659,
            slug: "tinh-nhue",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thanh Thuỷ",
        value: 239,
        slug: "huyen-thanh-thuy",
        status: "empty",
        ward: [
          { label: "Đào Xá", value: 8662, slug: "dao-xa", status: "empty" },
          {
            label: "Thạch Đồng",
            value: 8665,
            slug: "thach-dong",
            status: "empty",
          },
          { label: "Xuân Lộc", value: 8668, slug: "xuan-loc", status: "empty" },
          {
            label: "Tân Phương",
            value: 8671,
            slug: "tan-phuong",
            status: "empty",
          },
          {
            label: "Thanh Thủy",
            value: 8674,
            slug: "thanh-thuy",
            status: "empty",
          },
          { label: "Sơn Thủy", value: 8677, slug: "son-thuy", status: "empty" },
          { label: "Bảo Yên", value: 8680, slug: "bao-yen", status: "empty" },
          { label: "Đoan Hạ", value: 8683, slug: "doan-ha", status: "empty" },
          {
            label: "Đồng Trung",
            value: 8686,
            slug: "dong-trung",
            status: "empty",
          },
          { label: "Hoàng Xá", value: 8689, slug: "hoang-xa", status: "empty" },
          { label: "Tu Vũ", value: 8701, slug: "tu-vu", status: "empty" },
        ],
      },
      {
        label: "Huyện Tân Sơn",
        value: 240,
        slug: "huyen-tan-son",
        status: "empty",
        ward: [
          { label: "Thu Cúc", value: 8545, slug: "thu-cuc", status: "empty" },
          {
            label: "Thạch Kiệt",
            value: 8548,
            slug: "thach-kiet",
            status: "empty",
          },
          { label: "Thu Ngạc", value: 8551, slug: "thu-ngac", status: "empty" },
          { label: "Kiệt Sơn", value: 8554, slug: "kiet-son", status: "empty" },
          { label: "Đồng Sơn", value: 8557, slug: "dong-son", status: "empty" },
          { label: "Lai Đồng", value: 8560, slug: "lai-dong", status: "empty" },
          { label: "Tân Phú", value: 8566, slug: "tan-phu", status: "empty" },
          { label: "Mỹ Thuận", value: 8569, slug: "my-thuan", status: "empty" },
          { label: "Tân Sơn", value: 8578, slug: "tan-son", status: "empty" },
          { label: "Xuân Đài", value: 8590, slug: "xuan-dai", status: "empty" },
          { label: "Minh Đài", value: 8593, slug: "minh-dai", status: "empty" },
          {
            label: "Văn Luông",
            value: 8596,
            slug: "van-luong",
            status: "empty",
          },
          { label: "Xuân Sơn", value: 8599, slug: "xuan-son", status: "empty" },
          { label: "Long Cốc", value: 8608, slug: "long-coc", status: "empty" },
          {
            label: "Kim Thượng",
            value: 8617,
            slug: "kim-thuong",
            status: "empty",
          },
          {
            label: "Tam Thanh",
            value: 8620,
            slug: "tam-thanh",
            status: "empty",
          },
          {
            label: "Vinh Tiền",
            value: 8626,
            slug: "vinh-tien",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Vĩnh Phúc",
    value: 26,
    slug: "vinh-phuc",
    status: "filled",
    district: [
      {
        label: "Thành phố Vĩnh Yên",
        value: 243,
        slug: "thanh-pho-vinh-yen",
        status: "filled",
        ward: [
          { label: "Tích Sơn", value: 8707, slug: "tich-son", status: "empty" },
          {
            label: "Liên Bảo",
            value: 8710,
            slug: "lien-bao",
            status: "filled",
          },
          { label: "Hội Hợp", value: 8713, slug: "hoi-hop", status: "empty" },
          { label: "Đống Đa", value: 8716, slug: "dong-da", status: "empty" },
          {
            label: "Ngô Quyền",
            value: 8719,
            slug: "ngo-quyen",
            status: "empty",
          },
          { label: "Đồng Tâm", value: 8722, slug: "dong-tam", status: "empty" },
          {
            label: "Định Trung",
            value: 8725,
            slug: "dinh-trung",
            status: "empty",
          },
          {
            label: "Khai Quang",
            value: 8728,
            slug: "khai-quang",
            status: "empty",
          },
          {
            label: "Thanh Trù",
            value: 8731,
            slug: "thanh-tru",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Phúc Yên",
        value: 244,
        slug: "thanh-pho-phuc-yen",
        status: "filled",
        ward: [
          {
            label: "Hùng Vương",
            value: 8737,
            slug: "hung-vuong",
            status: "empty",
          },
          {
            label: "Hai Bà Trưng",
            value: 8740,
            slug: "hai-ba-trung",
            status: "filled",
          },
          {
            label: "Phúc Thắng",
            value: 8743,
            slug: "phuc-thang",
            status: "empty",
          },
          {
            label: "Xuân Hoà",
            value: 8746,
            slug: "xuan-hoa",
            status: "filled",
          },
          {
            label: "Đồng Xuân",
            value: 8747,
            slug: "dong-xuan",
            status: "filled",
          },
          {
            label: "Ngọc Thanh",
            value: 8749,
            slug: "ngoc-thanh",
            status: "empty",
          },
          { label: "Cao Minh", value: 8752, slug: "cao-minh", status: "empty" },
          { label: "Nam Viêm", value: 8755, slug: "nam-viem", status: "empty" },
          {
            label: "Tiền Châu",
            value: 8758,
            slug: "tien-chau",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lập Thạch",
        value: 246,
        slug: "huyen-lap-thach",
        status: "empty",
        ward: [
          {
            label: "Lập Thạch",
            value: 8761,
            slug: "lap-thach",
            status: "empty",
          },
          {
            label: "Quang Sơn",
            value: 8764,
            slug: "quang-son",
            status: "empty",
          },
          { label: "Ngọc Mỹ", value: 8767, slug: "ngoc-my", status: "empty" },
          { label: "Hợp Lý", value: 8770, slug: "hop-ly", status: "empty" },
          { label: "Bắc Bình", value: 8785, slug: "bac-binh", status: "empty" },
          { label: "Thái Hòa", value: 8788, slug: "thai-hoa", status: "empty" },
          { label: "Hoa Sơn", value: 8789, slug: "hoa-son", status: "empty" },
          { label: "Liễn Sơn", value: 8791, slug: "lien-son", status: "empty" },
          { label: "Xuân Hòa", value: 8794, slug: "xuan-hoa", status: "empty" },
          { label: "Vân Trục", value: 8797, slug: "van-truc", status: "empty" },
          { label: "Liên Hòa", value: 8812, slug: "lien-hoa", status: "empty" },
          { label: "Tử Du", value: 8815, slug: "tu-du", status: "empty" },
          { label: "Bàn Giản", value: 8833, slug: "ban-gian", status: "empty" },
          { label: "Xuân Lôi", value: 8836, slug: "xuan-loi", status: "empty" },
          { label: "Đồng Ích", value: 8839, slug: "dong-ich", status: "empty" },
          { label: "Tiên Lữ", value: 8842, slug: "tien-lu", status: "empty" },
          { label: "Văn Quán", value: 8845, slug: "van-quan", status: "empty" },
          { label: "Tây Sơn", value: 8863, slug: "tay-son", status: "empty" },
          { label: "Sơn Đông", value: 8866, slug: "son-dong", status: "empty" },
        ],
      },
      {
        label: "Huyện Tam Dương",
        value: 247,
        slug: "huyen-tam-duong",
        status: "filled",
        ward: [
          { label: "Hợp Hòa", value: 8869, slug: "hop-hoa", status: "empty" },
          {
            label: "Hoàng Hoa",
            value: 8872,
            slug: "hoang-hoa",
            status: "empty",
          },
          {
            label: "Đồng Tĩnh",
            value: 8875,
            slug: "dong-tinh",
            status: "empty",
          },
          {
            label: "Kim Long",
            value: 8878,
            slug: "kim-long",
            status: "filled",
          },
          {
            label: "Hướng Đạo",
            value: 8881,
            slug: "huong-dao",
            status: "empty",
          },
          { label: "Đạo Tú", value: 8884, slug: "dao-tu", status: "empty" },
          { label: "An Hòa", value: 8887, slug: "an-hoa", status: "empty" },
          {
            label: "Thanh Vân",
            value: 8890,
            slug: "thanh-van",
            status: "empty",
          },
          {
            label: "Duy Phiên",
            value: 8893,
            slug: "duy-phien",
            status: "empty",
          },
          {
            label: "Hoàng Đan",
            value: 8896,
            slug: "hoang-dan",
            status: "empty",
          },
          {
            label: "Hoàng Lâu",
            value: 8899,
            slug: "hoang-lau",
            status: "empty",
          },
          {
            label: "Hội Thịnh",
            value: 8905,
            slug: "hoi-thinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tam Đảo",
        value: 248,
        slug: "huyen-tam-dao",
        status: "empty",
        ward: [
          { label: "Tam Đảo", value: 8908, slug: "tam-dao", status: "empty" },
          { label: "Hợp Châu", value: 8911, slug: "hop-chau", status: "empty" },
          { label: "Đạo Trù", value: 8914, slug: "dao-tru", status: "empty" },
          {
            label: "Yên Dương",
            value: 8917,
            slug: "yen-duong",
            status: "empty",
          },
          { label: "Bồ Lý", value: 8920, slug: "bo-ly", status: "empty" },
          { label: "Đại Đình", value: 8923, slug: "dai-dinh", status: "empty" },
          { label: "Tam Quan", value: 8926, slug: "tam-quan", status: "empty" },
          { label: "Hồ Sơn", value: 8929, slug: "ho-son", status: "empty" },
          {
            label: "Minh Quang",
            value: 8932,
            slug: "minh-quang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bình Xuyên",
        value: 249,
        slug: "huyen-binh-xuyen",
        status: "empty",
        ward: [
          {
            label: "Hương Canh",
            value: 8935,
            slug: "huong-canh",
            status: "empty",
          },
          {
            label: "Gia Khánh",
            value: 8936,
            slug: "gia-khanh",
            status: "empty",
          },
          { label: "Trung Mỹ", value: 8938, slug: "trung-my", status: "empty" },
          { label: "Bá Hiến", value: 8944, slug: "ba-hien", status: "empty" },
          { label: "Thiện Kế", value: 8947, slug: "thien-ke", status: "empty" },
          {
            label: "Hương Sơn",
            value: 8950,
            slug: "huong-son",
            status: "empty",
          },
          { label: "Tam Hợp", value: 8953, slug: "tam-hop", status: "empty" },
          { label: "Quất Lưu", value: 8956, slug: "quat-luu", status: "empty" },
          { label: "Sơn Lôi", value: 8959, slug: "son-loi", status: "empty" },
          { label: "Đạo Đức", value: 8962, slug: "dao-duc", status: "empty" },
          {
            label: "Tân Phong",
            value: 8965,
            slug: "tan-phong",
            status: "empty",
          },
          {
            label: "Thanh Lãng",
            value: 8968,
            slug: "thanh-lang",
            status: "empty",
          },
          { label: "Phú Xuân", value: 8971, slug: "phu-xuan", status: "empty" },
        ],
      },
      {
        label: "Huyện Yên Lạc",
        value: 251,
        slug: "huyen-yen-lac",
        status: "filled",
        ward: [
          { label: "Yên Lạc", value: 9025, slug: "yen-lac", status: "filled" },
          {
            label: "Đồng Cương",
            value: 9028,
            slug: "dong-cuong",
            status: "empty",
          },
          { label: "Đồng Văn", value: 9031, slug: "dong-van", status: "empty" },
          {
            label: "Bình Định",
            value: 9034,
            slug: "binh-dinh",
            status: "empty",
          },
          {
            label: "Trung Nguyên",
            value: 9037,
            slug: "trung-nguyen",
            status: "empty",
          },
          { label: "Tề Lỗ", value: 9040, slug: "te-lo", status: "empty" },
          { label: "Tam Hồng", value: 9043, slug: "tam-hong", status: "empty" },
          { label: "Yên Đồng", value: 9046, slug: "yen-dong", status: "empty" },
          { label: "Văn Tiến", value: 9049, slug: "van-tien", status: "empty" },
          {
            label: "Nguyệt Đức",
            value: 9052,
            slug: "nguyet-duc",
            status: "empty",
          },
          {
            label: "Yên Phương",
            value: 9055,
            slug: "yen-phuong",
            status: "empty",
          },
          {
            label: "Trung Kiên",
            value: 9061,
            slug: "trung-kien",
            status: "empty",
          },
          {
            label: "Liên Châu",
            value: 9064,
            slug: "lien-chau",
            status: "empty",
          },
          { label: "Đại Tự", value: 9067, slug: "dai-tu", status: "empty" },
          {
            label: "Hồng Châu",
            value: 9070,
            slug: "hong-chau",
            status: "empty",
          },
          { label: "Trung Hà", value: 9073, slug: "trung-ha", status: "empty" },
        ],
      },
      {
        label: "Huyện Vĩnh Tường",
        value: 252,
        slug: "huyen-vinh-tuong",
        status: "filled",
        ward: [
          {
            label: "Vĩnh Tường",
            value: 9076,
            slug: "vinh-tuong",
            status: "empty",
          },
          { label: "Kim Xá", value: 9079, slug: "kim-xa", status: "empty" },
          { label: "Yên Bình", value: 9082, slug: "yen-binh", status: "empty" },
          {
            label: "Chấn Hưng",
            value: 9085,
            slug: "chan-hung",
            status: "empty",
          },
          {
            label: "Nghĩa Hưng",
            value: 9088,
            slug: "nghia-hung",
            status: "empty",
          },
          { label: "Yên Lập", value: 9091, slug: "yen-lap", status: "empty" },
          {
            label: "Sao Đại Việt",
            value: 9097,
            slug: "sao-dai-viet",
            status: "empty",
          },
          {
            label: "Đại Đồng",
            value: 9100,
            slug: "dai-dong",
            status: "filled",
          },
          { label: "Lũng Hoà", value: 9106, slug: "lung-hoa", status: "empty" },
          { label: "Thổ Tang", value: 9112, slug: "tho-tang", status: "empty" },
          {
            label: "Lương Điền",
            value: 9118,
            slug: "luong-dien",
            status: "empty",
          },
          { label: "Tân Phú", value: 9124, slug: "tan-phu", status: "empty" },
          {
            label: "Thượng Trưng",
            value: 9127,
            slug: "thuong-trung",
            status: "empty",
          },
          { label: "Vũ Di", value: 9130, slug: "vu-di", status: "empty" },
          {
            label: "Tuân Chính",
            value: 9136,
            slug: "tuan-chinh",
            status: "empty",
          },
          { label: "Tứ Trưng", value: 9145, slug: "tu-trung", status: "empty" },
          { label: "Ngũ Kiên", value: 9148, slug: "ngu-kien", status: "empty" },
          { label: "An Nhân", value: 9151, slug: "an-nhan", status: "empty" },
          {
            label: "Vĩnh Thịnh",
            value: 9154,
            slug: "vinh-thinh",
            status: "empty",
          },
          { label: "Vĩnh Phú", value: 9157, slug: "vinh-phu", status: "empty" },
        ],
      },
      {
        label: "Huyện Sông Lô",
        value: 253,
        slug: "huyen-song-lo",
        status: "empty",
        ward: [
          {
            label: "Lãng Công",
            value: 8773,
            slug: "lang-cong",
            status: "empty",
          },
          {
            label: "Quang Yên",
            value: 8776,
            slug: "quang-yen",
            status: "empty",
          },
          { label: "Hải Lựu", value: 8782, slug: "hai-luu", status: "empty" },
          { label: "Đồng Quế", value: 8800, slug: "dong-que", status: "empty" },
          { label: "Nhân Đạo", value: 8803, slug: "nhan-dao", status: "empty" },
          { label: "Đôn Nhân", value: 8806, slug: "don-nhan", status: "empty" },
          {
            label: "Phương Khoan",
            value: 8809,
            slug: "phuong-khoan",
            status: "empty",
          },
          { label: "Tân Lập", value: 8818, slug: "tan-lap", status: "empty" },
          { label: "Tam Sơn", value: 8824, slug: "tam-son", status: "empty" },
          {
            label: "Yên Thạch",
            value: 8830,
            slug: "yen-thach",
            status: "empty",
          },
          {
            label: "Đồng Thịnh",
            value: 8848,
            slug: "dong-thinh",
            status: "empty",
          },
          { label: "Tứ Yên", value: 8851, slug: "tu-yen", status: "empty" },
          { label: "Đức Bác", value: 8854, slug: "duc-bac", status: "empty" },
          {
            label: "Cao Phong",
            value: 8860,
            slug: "cao-phong",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Bắc Ninh",
    value: 27,
    slug: "bac-ninh",
    status: "filled",
    district: [
      {
        label: "Thành phố Bắc Ninh",
        value: 256,
        slug: "thanh-pho-bac-ninh",
        status: "filled",
        ward: [
          { label: "Vũ Ninh", value: 9163, slug: "vu-ninh", status: "filled" },
          { label: "Đáp Cầu", value: 9166, slug: "dap-cau", status: "empty" },
          { label: "Thị Cầu", value: 9169, slug: "thi-cau", status: "empty" },
          { label: "Kinh Bắc", value: 9172, slug: "kinh-bac", status: "empty" },
          { label: "Đại Phúc", value: 9181, slug: "dai-phuc", status: "empty" },
          {
            label: "Tiền Ninh Vệ",
            value: 9184,
            slug: "tien-ninh-ve",
            status: "filled",
          },
          { label: "Suối Hoa", value: 9187, slug: "suoi-hoa", status: "empty" },
          {
            label: "Võ Cường",
            value: 9190,
            slug: "vo-cuong",
            status: "filled",
          },
          { label: "Hòa Long", value: 9214, slug: "hoa-long", status: "empty" },
          { label: "Vạn An", value: 9226, slug: "van-an", status: "empty" },
          {
            label: "Khúc Xuyên",
            value: 9235,
            slug: "khuc-xuyen",
            status: "empty",
          },
          {
            label: "Phong Khê",
            value: 9244,
            slug: "phong-khe",
            status: "empty",
          },
          { label: "Kim Chân", value: 9256, slug: "kim-chan", status: "empty" },
          {
            label: "Vân Dương",
            value: 9271,
            slug: "van-duong",
            status: "empty",
          },
          { label: "Nam Sơn", value: 9286, slug: "nam-son", status: "empty" },
          {
            label: "Khắc Niệm",
            value: 9325,
            slug: "khac-niem",
            status: "empty",
          },
          { label: "Hạp Lĩnh", value: 9331, slug: "hap-linh", status: "empty" },
        ],
      },
      {
        label: "Huyện Yên Phong",
        value: 258,
        slug: "huyen-yen-phong",
        status: "filled",
        ward: [
          {
            label: "Thị trấn Chờ",
            value: 9193,
            slug: "thi-tran-cho",
            status: "filled",
          },
          {
            label: "Dũng Liệt",
            value: 9196,
            slug: "dung-liet",
            status: "empty",
          },
          { label: "Tam Đa", value: 9199, slug: "tam-da", status: "empty" },
          {
            label: "Tam Giang",
            value: 9202,
            slug: "tam-giang",
            status: "empty",
          },
          {
            label: "Yên Trung",
            value: 9205,
            slug: "yen-trung",
            status: "empty",
          },
          { label: "Thụy Hòa", value: 9208, slug: "thuy-hoa", status: "empty" },
          { label: "Hòa Tiến", value: 9211, slug: "hoa-tien", status: "empty" },
          {
            label: "Đông Tiến",
            value: 9217,
            slug: "dong-tien",
            status: "empty",
          },
          { label: "Yên Phụ", value: 9220, slug: "yen-phu", status: "empty" },
          {
            label: "Trung Nghĩa",
            value: 9223,
            slug: "trung-nghia",
            status: "empty",
          },
          {
            label: "Đông Phong",
            value: 9229,
            slug: "dong-phong",
            status: "empty",
          },
          {
            label: "Long Châu",
            value: 9232,
            slug: "long-chau",
            status: "empty",
          },
          { label: "Văn Môn", value: 9238, slug: "van-mon", status: "empty" },
          { label: "Đông Thọ", value: 9241, slug: "dong-tho", status: "empty" },
        ],
      },
      {
        label: "Thị xã Quế Võ",
        value: 259,
        slug: "thi-xa-que-vo",
        status: "filled",
        ward: [
          { label: "Phố Mới", value: 9247, slug: "pho-moi", status: "empty" },
          {
            label: "Việt Thống",
            value: 9250,
            slug: "viet-thong",
            status: "empty",
          },
          { label: "Đại Xuân", value: 9253, slug: "dai-xuan", status: "empty" },
          { label: "Nhân Hòa", value: 9259, slug: "nhan-hoa", status: "empty" },
          { label: "Bằng An", value: 9262, slug: "bang-an", status: "empty" },
          {
            label: "Phương Liễu",
            value: 9265,
            slug: "phuong-lieu",
            status: "filled",
          },
          { label: "Quế Tân", value: 9268, slug: "que-tan", status: "empty" },
          {
            label: "Phù Lương",
            value: 9274,
            slug: "phu-luong",
            status: "empty",
          },
          { label: "Phù Lãng", value: 9277, slug: "phu-lang", status: "empty" },
          {
            label: "Phượng Mao",
            value: 9280,
            slug: "phuong-mao",
            status: "filled",
          },
          {
            label: "Việt Hùng",
            value: 9283,
            slug: "viet-hung",
            status: "empty",
          },
          { label: "Ngọc Xá", value: 9289, slug: "ngoc-xa", status: "empty" },
          {
            label: "Châu Phong",
            value: 9292,
            slug: "chau-phong",
            status: "empty",
          },
          { label: "Bồng Lai", value: 9295, slug: "bong-lai", status: "empty" },
          { label: "Cách Bi", value: 9298, slug: "cach-bi", status: "empty" },
          { label: "Đào Viên", value: 9301, slug: "dao-vien", status: "empty" },
          { label: "Yên Giả", value: 9304, slug: "yen-gia", status: "empty" },
          { label: "Mộ Đạo", value: 9307, slug: "mo-dao", status: "empty" },
          { label: "Đức Long", value: 9310, slug: "duc-long", status: "empty" },
          { label: "Chi Lăng", value: 9313, slug: "chi-lang", status: "empty" },
        ],
      },
      {
        label: "Huyện Tiên Du",
        value: 260,
        slug: "huyen-tien-du",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Lim",
            value: 9319,
            slug: "thi-tran-lim",
            status: "empty",
          },
          { label: "Phú Lâm", value: 9322, slug: "phu-lam", status: "empty" },
          { label: "Nội Duệ", value: 9328, slug: "noi-due", status: "empty" },
          { label: "Liên Bão", value: 9334, slug: "lien-bao", status: "empty" },
          { label: "Hiên Vân", value: 9337, slug: "hien-van", status: "empty" },
          { label: "Hoàn Sơn", value: 9340, slug: "hoan-son", status: "empty" },
          { label: "Lạc Vệ", value: 9343, slug: "lac-ve", status: "empty" },
          {
            label: "Việt Đoàn",
            value: 9346,
            slug: "viet-doan",
            status: "empty",
          },
          {
            label: "Phật Tích",
            value: 9349,
            slug: "phat-tich",
            status: "empty",
          },
          { label: "Tân Chi", value: 9352, slug: "tan-chi", status: "empty" },
          { label: "Đại Đồng", value: 9355, slug: "dai-dong", status: "empty" },
          {
            label: "Tri Phương",
            value: 9358,
            slug: "tri-phuong",
            status: "empty",
          },
          { label: "Minh Đạo", value: 9361, slug: "minh-dao", status: "empty" },
          {
            label: "Cảnh Hưng",
            value: 9364,
            slug: "canh-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Từ Sơn",
        value: 261,
        slug: "thanh-pho-tu-son",
        status: "filled",
        ward: [
          {
            label: "Đông Ngàn",
            value: 9367,
            slug: "dong-ngan",
            status: "empty",
          },
          { label: "Tam Sơn", value: 9370, slug: "tam-son", status: "empty" },
          {
            label: "Hương Mạc",
            value: 9373,
            slug: "huong-mac",
            status: "empty",
          },
          {
            label: "Tương Giang",
            value: 9376,
            slug: "tuong-giang",
            status: "empty",
          },
          { label: "Phù Khê", value: 9379, slug: "phu-khe", status: "empty" },
          { label: "Đồng Kỵ", value: 9382, slug: "dong-ky", status: "empty" },
          { label: "Trang Hạ", value: 9383, slug: "trang-ha", status: "empty" },
          {
            label: "Đồng Nguyên",
            value: 9385,
            slug: "dong-nguyen",
            status: "filled",
          },
          { label: "Châu Khê", value: 9388, slug: "chau-khe", status: "empty" },
          { label: "Tân Hồng", value: 9391, slug: "tan-hong", status: "empty" },
          {
            label: "Đình Bảng",
            value: 9394,
            slug: "dinh-bang",
            status: "filled",
          },
          { label: "Phù Chẩn", value: 9397, slug: "phu-chan", status: "empty" },
        ],
      },
      {
        label: "Thị xã Thuận Thành",
        value: 262,
        slug: "thi-xa-thuan-thanh",
        status: "empty",
        ward: [
          {
            label: "Phường Hồ",
            value: 9400,
            slug: "phuong-ho",
            status: "empty",
          },
          {
            label: "Hoài Thượng",
            value: 9403,
            slug: "hoai-thuong",
            status: "empty",
          },
          {
            label: "Đại Đồng Thành",
            value: 9406,
            slug: "dai-dong-thanh",
            status: "empty",
          },
          { label: "Mão Điền", value: 9409, slug: "mao-dien", status: "empty" },
          { label: "Song Hồ", value: 9412, slug: "song-ho", status: "empty" },
          { label: "Đình Tổ", value: 9415, slug: "dinh-to", status: "empty" },
          { label: "An Bình", value: 9418, slug: "an-binh", status: "empty" },
          { label: "Trí Quả", value: 9421, slug: "tri-qua", status: "empty" },
          { label: "Gia Đông", value: 9424, slug: "gia-dong", status: "empty" },
          {
            label: "Thanh Khương",
            value: 9427,
            slug: "thanh-khuong",
            status: "empty",
          },
          { label: "Trạm Lộ", value: 9430, slug: "tram-lo", status: "empty" },
          { label: "Xuân Lâm", value: 9433, slug: "xuan-lam", status: "empty" },
          { label: "Hà Mãn", value: 9436, slug: "ha-man", status: "empty" },
          { label: "Ngũ Thái", value: 9439, slug: "ngu-thai", status: "empty" },
          {
            label: "Nguyệt Đức",
            value: 9442,
            slug: "nguyet-duc",
            status: "empty",
          },
          { label: "Ninh Xá", value: 9445, slug: "ninh-xa", status: "empty" },
          {
            label: "Nghĩa Đạo",
            value: 9448,
            slug: "nghia-dao",
            status: "empty",
          },
          {
            label: "Song Liễu",
            value: 9451,
            slug: "song-lieu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Gia Bình",
        value: 263,
        slug: "huyen-gia-binh",
        status: "empty",
        ward: [
          { label: "Gia Bình", value: 9454, slug: "gia-binh", status: "empty" },
          { label: "Vạn Ninh", value: 9457, slug: "van-ninh", status: "empty" },
          { label: "Thái Bảo", value: 9460, slug: "thai-bao", status: "empty" },
          {
            label: "Giang Sơn",
            value: 9463,
            slug: "giang-son",
            status: "empty",
          },
          { label: "Cao Đức", value: 9466, slug: "cao-duc", status: "empty" },
          { label: "Đại Lai", value: 9469, slug: "dai-lai", status: "empty" },
          {
            label: "Song Giang",
            value: 9472,
            slug: "song-giang",
            status: "empty",
          },
          {
            label: "Bình Dương",
            value: 9475,
            slug: "binh-duong",
            status: "empty",
          },
          {
            label: "Lãng Ngâm",
            value: 9478,
            slug: "lang-ngam",
            status: "empty",
          },
          {
            label: "Nhân Thắng",
            value: 9481,
            slug: "nhan-thang",
            status: "empty",
          },
          { label: "Xuân Lai", value: 9484, slug: "xuan-lai", status: "empty" },
          { label: "Đông Cứu", value: 9487, slug: "dong-cuu", status: "empty" },
          { label: "Đại Bái", value: 9490, slug: "dai-bai", status: "empty" },
          {
            label: "Quỳnh Phú",
            value: 9493,
            slug: "quynh-phu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lương Tài",
        value: 264,
        slug: "huyen-luong-tai",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Thứa",
            value: 9496,
            slug: "thi-tran-thua",
            status: "empty",
          },
          { label: "An Thịnh", value: 9499, slug: "an-thinh", status: "empty" },
          {
            label: "Trung Kênh",
            value: 9502,
            slug: "trung-kenh",
            status: "empty",
          },
          { label: "Phú Hòa", value: 9505, slug: "phu-hoa", status: "empty" },
          { label: "An Tập", value: 9508, slug: "an-tap", status: "empty" },
          { label: "Tân Lãng", value: 9511, slug: "tan-lang", status: "empty" },
          {
            label: "Quảng Phú",
            value: 9514,
            slug: "quang-phu",
            status: "empty",
          },
          {
            label: "Quang Minh",
            value: 9517,
            slug: "quang-minh",
            status: "empty",
          },
          {
            label: "Trung Chính",
            value: 9523,
            slug: "trung-chinh",
            status: "empty",
          },
          {
            label: "Bình Định",
            value: 9529,
            slug: "binh-dinh",
            status: "empty",
          },
          {
            label: "Phú Lương",
            value: 9532,
            slug: "phu-luong",
            status: "empty",
          },
          { label: "Lâm Thao", value: 9535, slug: "lam-thao", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Hải Dương",
    value: 30,
    slug: "hai-duong",
    status: "filled",
    district: [
      {
        label: "Thành phố Hải Dương",
        value: 288,
        slug: "thanh-pho-hai-duong",
        status: "empty",
        ward: [
          {
            label: "Cẩm Thượng",
            value: 10507,
            slug: "cam-thuong",
            status: "empty",
          },
          {
            label: "Bình Hàn",
            value: 10510,
            slug: "binh-han",
            status: "empty",
          },
          {
            label: "Ngọc Châu",
            value: 10513,
            slug: "ngoc-chau",
            status: "empty",
          },
          {
            label: "Nhị Châu",
            value: 10514,
            slug: "nhi-chau",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 10516,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Nguyễn Trãi",
            value: 10519,
            slug: "nguyen-trai",
            status: "empty",
          },
          {
            label: "Trần Hưng Đạo",
            value: 10525,
            slug: "tran-hung-dao",
            status: "empty",
          },
          {
            label: "Trần Phú",
            value: 10528,
            slug: "tran-phu",
            status: "empty",
          },
          {
            label: "Thanh Bình",
            value: 10531,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 10532,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Lê Thanh Nghị",
            value: 10534,
            slug: "le-thanh-nghi",
            status: "empty",
          },
          { label: "Hải Tân", value: 10537, slug: "hai-tan", status: "empty" },
          { label: "Tứ Minh", value: 10540, slug: "tu-minh", status: "empty" },
          {
            label: "Việt Hoà",
            value: 10543,
            slug: "viet-hoa",
            status: "empty",
          },
          { label: "Ái Quốc", value: 10660, slug: "ai-quoc", status: "empty" },
          {
            label: "An Thượng",
            value: 10663,
            slug: "an-thuong",
            status: "empty",
          },
          {
            label: "Nam Đồng",
            value: 10672,
            slug: "nam-dong",
            status: "empty",
          },
          {
            label: "Quyết Thắng",
            value: 10822,
            slug: "quyet-thang",
            status: "empty",
          },
          {
            label: "Tiền Tiến",
            value: 10837,
            slug: "tien-tien",
            status: "empty",
          },
          {
            label: "Thạch Khôi",
            value: 11002,
            slug: "thach-khoi",
            status: "empty",
          },
          {
            label: "Liên Hồng",
            value: 11005,
            slug: "lien-hong",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 11011,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "Gia Xuyên",
            value: 11017,
            slug: "gia-xuyen",
            status: "empty",
          },
          {
            label: "Ngọc Sơn",
            value: 11077,
            slug: "ngoc-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Chí Linh",
        value: 290,
        slug: "thanh-pho-chi-linh",
        status: "empty",
        ward: [
          { label: "Phả Lại", value: 10546, slug: "pha-lai", status: "empty" },
          { label: "Sao Đỏ", value: 10549, slug: "sao-do", status: "empty" },
          { label: "Bến Tắm", value: 10552, slug: "ben-tam", status: "empty" },
          {
            label: "Hoàng Hoa Thám",
            value: 10555,
            slug: "hoang-hoa-tham",
            status: "empty",
          },
          { label: "Bắc An", value: 10558, slug: "bac-an", status: "empty" },
          {
            label: "Hưng Đạo",
            value: 10561,
            slug: "hung-dao",
            status: "empty",
          },
          { label: "Lê Lợi", value: 10564, slug: "le-loi", status: "empty" },
          {
            label: "Hoàng Tiến",
            value: 10567,
            slug: "hoang-tien",
            status: "empty",
          },
          {
            label: "Cộng Hoà",
            value: 10570,
            slug: "cong-hoa",
            status: "empty",
          },
          {
            label: "Hoàng Tân",
            value: 10573,
            slug: "hoang-tan",
            status: "empty",
          },
          {
            label: "Cổ Thành",
            value: 10576,
            slug: "co-thanh",
            status: "empty",
          },
          { label: "Văn An", value: 10579, slug: "van-an", status: "empty" },
          {
            label: "Chí Minh",
            value: 10582,
            slug: "chi-minh",
            status: "empty",
          },
          { label: "Văn Đức", value: 10585, slug: "van-duc", status: "empty" },
          {
            label: "Thái Học",
            value: 10588,
            slug: "thai-hoc",
            status: "empty",
          },
          {
            label: "Nhân Huệ",
            value: 10591,
            slug: "nhan-hue",
            status: "empty",
          },
          { label: "An Lạc", value: 10594, slug: "an-lac", status: "empty" },
          {
            label: "Đồng Lạc",
            value: 10600,
            slug: "dong-lac",
            status: "empty",
          },
          { label: "Tân Dân", value: 10603, slug: "tan-dan", status: "empty" },
        ],
      },
      {
        label: "Huyện Nam Sách",
        value: 291,
        slug: "huyen-nam-sach",
        status: "empty",
        ward: [
          {
            label: "Nam Sách",
            value: 10606,
            slug: "nam-sach",
            status: "empty",
          },
          {
            label: "Nam Hưng",
            value: 10609,
            slug: "nam-hung",
            status: "empty",
          },
          { label: "Nam Tân", value: 10612, slug: "nam-tan", status: "empty" },
          {
            label: "Hợp Tiến",
            value: 10615,
            slug: "hop-tien",
            status: "empty",
          },
          {
            label: "Hiệp Cát",
            value: 10618,
            slug: "hiep-cat",
            status: "empty",
          },
          {
            label: "Quốc Tuấn",
            value: 10621,
            slug: "quoc-tuan",
            status: "empty",
          },
          { label: "An Bình", value: 10630, slug: "an-binh", status: "empty" },
          {
            label: "Trần Phú",
            value: 10633,
            slug: "tran-phu",
            status: "empty",
          },
          { label: "An Sơn", value: 10636, slug: "an-son", status: "empty" },
          {
            label: "Cộng Hòa",
            value: 10639,
            slug: "cong-hoa",
            status: "empty",
          },
          {
            label: "Thái Tân",
            value: 10642,
            slug: "thai-tan",
            status: "empty",
          },
          { label: "An Phú", value: 10645, slug: "an-phu", status: "empty" },
          {
            label: "Hồng Phong",
            value: 10654,
            slug: "hong-phong",
            status: "empty",
          },
          {
            label: "Đồng Lạc",
            value: 10657,
            slug: "dong-lac",
            status: "empty",
          },
          {
            label: "Minh Tân",
            value: 10666,
            slug: "minh-tan",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Kinh Môn",
        value: 292,
        slug: "thi-xa-kinh-mon",
        status: "filled",
        ward: [
          { label: "An Lưu", value: 10675, slug: "an-luu", status: "filled" },
          {
            label: "Bạch Đằng",
            value: 10678,
            slug: "bach-dang",
            status: "empty",
          },
          {
            label: "Thất Hùng",
            value: 10681,
            slug: "that-hung",
            status: "empty",
          },
          { label: "Lê Ninh", value: 10684, slug: "le-ninh", status: "empty" },
          {
            label: "Phạm Thái",
            value: 10693,
            slug: "pham-thai",
            status: "empty",
          },
          { label: "Duy Tân", value: 10696, slug: "duy-tan", status: "empty" },
          { label: "Tân Dân", value: 10699, slug: "tan-dan", status: "empty" },
          {
            label: "Minh Tân",
            value: 10702,
            slug: "minh-tan",
            status: "empty",
          },
          {
            label: "Quang Thành",
            value: 10705,
            slug: "quang-thanh",
            status: "empty",
          },
          {
            label: "Hiệp Hòa",
            value: 10708,
            slug: "hiep-hoa",
            status: "empty",
          },
          { label: "Phú Thứ", value: 10714, slug: "phu-thu", status: "empty" },
          {
            label: "Thăng Long",
            value: 10717,
            slug: "thang-long",
            status: "empty",
          },
          {
            label: "Lạc Long",
            value: 10720,
            slug: "lac-long",
            status: "empty",
          },
          { label: "An Sinh", value: 10723, slug: "an-sinh", status: "empty" },
          {
            label: "Hiệp Sơn",
            value: 10726,
            slug: "hiep-son",
            status: "empty",
          },
          {
            label: "Thượng Quận",
            value: 10729,
            slug: "thuong-quan",
            status: "empty",
          },
          { label: "An Phụ", value: 10732, slug: "an-phu", status: "empty" },
          { label: "Hiệp An", value: 10735, slug: "hiep-an", status: "empty" },
          {
            label: "Long Xuyên",
            value: 10738,
            slug: "long-xuyen",
            status: "empty",
          },
          {
            label: "Thái Thịnh",
            value: 10741,
            slug: "thai-thinh",
            status: "empty",
          },
          {
            label: "Hiến Thành",
            value: 10744,
            slug: "hien-thanh",
            status: "empty",
          },
          {
            label: "Minh Hòa",
            value: 10747,
            slug: "minh-hoa",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Kim Thành",
        value: 293,
        slug: "huyen-kim-thanh",
        status: "empty",
        ward: [
          {
            label: "Phú Thái",
            value: 10750,
            slug: "phu-thai",
            status: "empty",
          },
          { label: "Lai Khê", value: 10756, slug: "lai-khe", status: "empty" },
          { label: "Vũ Dũng", value: 10762, slug: "vu-dung", status: "empty" },
          {
            label: "Tuấn Việt",
            value: 10768,
            slug: "tuan-viet",
            status: "empty",
          },
          {
            label: "Kim Xuyên",
            value: 10771,
            slug: "kim-xuyen",
            status: "empty",
          },
          {
            label: "Ngũ Phúc",
            value: 10777,
            slug: "ngu-phuc",
            status: "empty",
          },
          { label: "Kim Anh", value: 10780, slug: "kim-anh", status: "empty" },
          {
            label: "Kim Liên",
            value: 10783,
            slug: "kim-lien",
            status: "empty",
          },
          { label: "Kim Tân", value: 10786, slug: "kim-tan", status: "empty" },
          {
            label: "Kim Đính",
            value: 10792,
            slug: "kim-dinh",
            status: "empty",
          },
          {
            label: "Hòa Bình",
            value: 10798,
            slug: "hoa-binh",
            status: "empty",
          },
          { label: "Tam Kỳ", value: 10801, slug: "tam-ky", status: "empty" },
          {
            label: "Đồng Cẩm",
            value: 10804,
            slug: "dong-cam",
            status: "empty",
          },
          { label: "Đại Đức", value: 10810, slug: "dai-duc", status: "empty" },
        ],
      },
      {
        label: "Huyện Thanh Hà",
        value: 294,
        slug: "huyen-thanh-ha",
        status: "empty",
        ward: [
          {
            label: "Thanh Hà",
            value: 10813,
            slug: "thanh-ha",
            status: "empty",
          },
          {
            label: "Hồng Lạc",
            value: 10816,
            slug: "hong-lac",
            status: "empty",
          },
          {
            label: "Tân Việt",
            value: 10825,
            slug: "tan-viet",
            status: "empty",
          },
          {
            label: "Cẩm Việt",
            value: 10828,
            slug: "cam-viet",
            status: "empty",
          },
          {
            label: "Thanh An",
            value: 10831,
            slug: "thanh-an",
            status: "empty",
          },
          {
            label: "Thanh Lang",
            value: 10834,
            slug: "thanh-lang",
            status: "empty",
          },
          { label: "Tân An", value: 10840, slug: "tan-an", status: "empty" },
          {
            label: "Liên Mạc",
            value: 10843,
            slug: "lien-mac",
            status: "empty",
          },
          {
            label: "Thanh Hải",
            value: 10846,
            slug: "thanh-hai",
            status: "empty",
          },
          {
            label: "Thanh Xuân",
            value: 10855,
            slug: "thanh-xuan",
            status: "empty",
          },
          {
            label: "Thanh Tân",
            value: 10861,
            slug: "thanh-tan",
            status: "empty",
          },
          {
            label: "An Phượng",
            value: 10864,
            slug: "an-phuong",
            status: "empty",
          },
          {
            label: "Thanh Sơn",
            value: 10867,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Thanh Quang",
            value: 10876,
            slug: "thanh-quang",
            status: "empty",
          },
          {
            label: "Thanh Hồng",
            value: 10879,
            slug: "thanh-hong",
            status: "empty",
          },
          {
            label: "Vĩnh Cường",
            value: 10882,
            slug: "vinh-cuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cẩm Giàng",
        value: 295,
        slug: "huyen-cam-giang",
        status: "filled",
        ward: [
          {
            label: "Cẩm Giang",
            value: 10888,
            slug: "cam-giang",
            status: "empty",
          },
          {
            label: "Lai Cách",
            value: 10891,
            slug: "lai-cach",
            status: "filled",
          },
          {
            label: "Cẩm Hưng",
            value: 10894,
            slug: "cam-hung",
            status: "empty",
          },
          {
            label: "Cẩm Hoàng",
            value: 10897,
            slug: "cam-hoang",
            status: "empty",
          },
          { label: "Cẩm Văn", value: 10900, slug: "cam-van", status: "empty" },
          {
            label: "Ngọc Liên",
            value: 10903,
            slug: "ngoc-lien",
            status: "empty",
          },
          { label: "Cẩm Vũ", value: 10909, slug: "cam-vu", status: "empty" },
          {
            label: "Đức Chính",
            value: 10912,
            slug: "duc-chinh",
            status: "empty",
          },
          {
            label: "Định Sơn",
            value: 10918,
            slug: "dinh-son",
            status: "empty",
          },
          {
            label: "Lương Điền",
            value: 10924,
            slug: "luong-dien",
            status: "empty",
          },
          { label: "Cao An", value: 10927, slug: "cao-an", status: "empty" },
          {
            label: "Tân Trường",
            value: 10930,
            slug: "tan-truong",
            status: "empty",
          },
          {
            label: "Phúc Điền",
            value: 10933,
            slug: "phuc-dien",
            status: "empty",
          },
          {
            label: "Cẩm Đông",
            value: 10939,
            slug: "cam-dong",
            status: "empty",
          },
          {
            label: "Cẩm Đoài",
            value: 10942,
            slug: "cam-doai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bình Giang",
        value: 296,
        slug: "huyen-binh-giang",
        status: "empty",
        ward: [
          { label: "Kẻ Sặt", value: 10945, slug: "ke-sat", status: "empty" },
          {
            label: "Vĩnh Hưng",
            value: 10951,
            slug: "vinh-hung",
            status: "empty",
          },
          {
            label: "Hùng Thắng",
            value: 10954,
            slug: "hung-thang",
            status: "empty",
          },
          {
            label: "Vĩnh Hồng",
            value: 10960,
            slug: "vinh-hong",
            status: "empty",
          },
          {
            label: "Long Xuyên",
            value: 10963,
            slug: "long-xuyen",
            status: "empty",
          },
          {
            label: "Tân Việt",
            value: 10966,
            slug: "tan-viet",
            status: "empty",
          },
          {
            label: "Thúc Kháng",
            value: 10969,
            slug: "thuc-khang",
            status: "empty",
          },
          {
            label: "Tân Hồng",
            value: 10972,
            slug: "tan-hong",
            status: "empty",
          },
          {
            label: "Hồng Khê",
            value: 10978,
            slug: "hong-khe",
            status: "empty",
          },
          {
            label: "Thái Minh",
            value: 10981,
            slug: "thai-minh",
            status: "empty",
          },
          { label: "Cổ Bì", value: 10984, slug: "co-bi", status: "empty" },
          {
            label: "Nhân Quyền",
            value: 10987,
            slug: "nhan-quyen",
            status: "empty",
          },
          {
            label: "Thái Dương",
            value: 10990,
            slug: "thai-duong",
            status: "empty",
          },
          {
            label: "Thái Hòa",
            value: 10993,
            slug: "thai-hoa",
            status: "empty",
          },
          {
            label: "Bình Xuyên",
            value: 10996,
            slug: "binh-xuyen",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Gia Lộc",
        value: 297,
        slug: "huyen-gia-loc",
        status: "empty",
        ward: [
          { label: "Gia Lộc", value: 10999, slug: "gia-loc", status: "empty" },
          {
            label: "Thống Nhất",
            value: 11008,
            slug: "thong-nhat",
            status: "empty",
          },
          {
            label: "Yết Kiêu",
            value: 11020,
            slug: "yet-kieu",
            status: "empty",
          },
          {
            label: "Gia Phúc",
            value: 11035,
            slug: "gia-phuc",
            status: "empty",
          },
          {
            label: "Gia Tiến",
            value: 11038,
            slug: "gia-tien",
            status: "empty",
          },
          { label: "Lê Lợi", value: 11041, slug: "le-loi", status: "empty" },
          {
            label: "Toàn Thắng",
            value: 11044,
            slug: "toan-thang",
            status: "empty",
          },
          {
            label: "Hoàng Diệu",
            value: 11047,
            slug: "hoang-dieu",
            status: "empty",
          },
          {
            label: "Hồng Hưng",
            value: 11050,
            slug: "hong-hung",
            status: "empty",
          },
          {
            label: "Phạm Trấn",
            value: 11053,
            slug: "pham-tran",
            status: "empty",
          },
          {
            label: "Đoàn Thượng",
            value: 11056,
            slug: "doan-thuong",
            status: "empty",
          },
          {
            label: "Thống Kênh",
            value: 11059,
            slug: "thong-kenh",
            status: "empty",
          },
          {
            label: "Nhật Quang",
            value: 11065,
            slug: "nhat-quang",
            status: "empty",
          },
          {
            label: "Quang Đức",
            value: 11071,
            slug: "quang-duc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tứ Kỳ",
        value: 298,
        slug: "huyen-tu-ky",
        status: "empty",
        ward: [
          { label: "Tứ Kỳ", value: 11074, slug: "tu-ky", status: "empty" },
          { label: "Đại Sơn", value: 11083, slug: "dai-son", status: "empty" },
          {
            label: "Hưng Đạo",
            value: 11086,
            slug: "hung-dao",
            status: "empty",
          },
          {
            label: "Bình Lăng",
            value: 11092,
            slug: "binh-lang",
            status: "empty",
          },
          {
            label: "Chí Minh",
            value: 11095,
            slug: "chi-minh",
            status: "empty",
          },
          { label: "Kỳ Sơn", value: 11098, slug: "ky-son", status: "empty" },
          {
            label: "Quang Phục",
            value: 11101,
            slug: "quang-phuc",
            status: "empty",
          },
          { label: "Tân Kỳ", value: 11113, slug: "tan-ky", status: "empty" },
          {
            label: "Quang Khải",
            value: 11116,
            slug: "quang-khai",
            status: "empty",
          },
          { label: "Đại Hợp", value: 11119, slug: "dai-hop", status: "empty" },
          { label: "Dân An", value: 11122, slug: "dan-an", status: "empty" },
          {
            label: "An Thanh",
            value: 11125,
            slug: "an-thanh",
            status: "empty",
          },
          {
            label: "Minh Đức",
            value: 11128,
            slug: "minh-duc",
            status: "empty",
          },
          { label: "Văn Tố", value: 11131, slug: "van-to", status: "empty" },
          {
            label: "Quang Trung",
            value: 11134,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Lạc Phượng",
            value: 11140,
            slug: "lac-phuong",
            status: "empty",
          },
          {
            label: "Tiên Động",
            value: 11143,
            slug: "tien-dong",
            status: "empty",
          },
          {
            label: "Nguyên Giáp",
            value: 11146,
            slug: "nguyen-giap",
            status: "empty",
          },
          { label: "Hà Kỳ", value: 11149, slug: "ha-ky", status: "empty" },
          {
            label: "Hà Thanh",
            value: 11152,
            slug: "ha-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ninh Giang",
        value: 299,
        slug: "huyen-ninh-giang",
        status: "empty",
        ward: [
          { label: "Ứng Hoè", value: 11161, slug: "ung-hoe", status: "empty" },
          {
            label: "Nghĩa An",
            value: 11164,
            slug: "nghia-an",
            status: "empty",
          },
          {
            label: "Đức Phúc",
            value: 11167,
            slug: "duc-phuc",
            status: "empty",
          },
          { label: "An Đức", value: 11173, slug: "an-duc", status: "empty" },
          {
            label: "Tân Hương",
            value: 11179,
            slug: "tan-huong",
            status: "empty",
          },
          {
            label: "Vĩnh Hòa",
            value: 11185,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Bình Xuyên",
            value: 11188,
            slug: "binh-xuyen",
            status: "empty",
          },
          {
            label: "Tân Phong",
            value: 11197,
            slug: "tan-phong",
            status: "empty",
          },
          {
            label: "Ninh Giang",
            value: 11203,
            slug: "ninh-giang",
            status: "empty",
          },
          {
            label: "Tân Quang",
            value: 11206,
            slug: "tan-quang",
            status: "empty",
          },
          { label: "Hồng Dụ", value: 11215, slug: "hong-du", status: "empty" },
          { label: "Văn Hội", value: 11218, slug: "van-hoi", status: "empty" },
          {
            label: "Hồng Phong",
            value: 11224,
            slug: "hong-phong",
            status: "empty",
          },
          {
            label: "Hiệp Lực",
            value: 11227,
            slug: "hiep-luc",
            status: "empty",
          },
          {
            label: "Kiến Phúc",
            value: 11230,
            slug: "kien-phuc",
            status: "empty",
          },
          {
            label: "Hưng Long",
            value: 11233,
            slug: "hung-long",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thanh Miện",
        value: 300,
        slug: "huyen-thanh-mien",
        status: "empty",
        ward: [
          {
            label: "Thanh Miện",
            value: 11239,
            slug: "thanh-mien",
            status: "empty",
          },
          {
            label: "Thanh Tùng",
            value: 11242,
            slug: "thanh-tung",
            status: "empty",
          },
          {
            label: "Phạm Kha",
            value: 11245,
            slug: "pham-kha",
            status: "empty",
          },
          {
            label: "Ngô Quyền",
            value: 11248,
            slug: "ngo-quyen",
            status: "empty",
          },
          {
            label: "Đoàn Tùng",
            value: 11251,
            slug: "doan-tung",
            status: "empty",
          },
          {
            label: "Hồng Quang",
            value: 11254,
            slug: "hong-quang",
            status: "empty",
          },
          {
            label: "Tân Trào",
            value: 11257,
            slug: "tan-trao",
            status: "empty",
          },
          { label: "Lam Sơn", value: 11260, slug: "lam-son", status: "empty" },
          {
            label: "Đoàn Kết",
            value: 11263,
            slug: "doan-ket",
            status: "empty",
          },
          { label: "Lê Hồng", value: 11266, slug: "le-hong", status: "empty" },
          {
            label: "Tứ Cường",
            value: 11269,
            slug: "tu-cuong",
            status: "empty",
          },
          {
            label: "Ngũ Hùng",
            value: 11275,
            slug: "ngu-hung",
            status: "empty",
          },
          {
            label: "Cao Thắng",
            value: 11278,
            slug: "cao-thang",
            status: "empty",
          },
          {
            label: "Chi Lăng Bắc",
            value: 11281,
            slug: "chi-lang-bac",
            status: "empty",
          },
          {
            label: "Chi Lăng Nam",
            value: 11284,
            slug: "chi-lang-nam",
            status: "empty",
          },
          {
            label: "Thanh Giang",
            value: 11287,
            slug: "thanh-giang",
            status: "empty",
          },
          {
            label: "Hồng Phong",
            value: 11293,
            slug: "hong-phong",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Hải Phòng",
    value: 31,
    slug: "hai-phong",
    status: "filled",
    district: [
      {
        label: "Quận Hồng Bàng",
        value: 303,
        slug: "quan-hong-bang",
        status: "filled",
        ward: [
          {
            label: "Quán Toan",
            value: 11296,
            slug: "quan-toan",
            status: "empty",
          },
          {
            label: "Hùng Vương",
            value: 11299,
            slug: "hung-vuong",
            status: "empty",
          },
          { label: "Sở Dầu", value: 11302, slug: "so-dau", status: "empty" },
          {
            label: "Thượng Lý",
            value: 11305,
            slug: "thuong-ly",
            status: "filled",
          },
          {
            label: "Minh Khai",
            value: 11311,
            slug: "minh-khai",
            status: "empty",
          },
          {
            label: "Hoàng Văn Thụ",
            value: 11320,
            slug: "hoang-van-thu",
            status: "empty",
          },
          {
            label: "Phan Bội Châu",
            value: 11323,
            slug: "phan-boi-chau",
            status: "empty",
          },
          { label: "Đại Bản", value: 11587, slug: "dai-ban", status: "empty" },
          { label: "An Hưng", value: 11599, slug: "an-hung", status: "empty" },
          { label: "An Hồng", value: 11602, slug: "an-hong", status: "empty" },
        ],
      },
      {
        label: "Quận Ngô Quyền",
        value: 304,
        slug: "quan-ngo-quyen",
        status: "filled",
        ward: [
          {
            label: "Máy Chai",
            value: 11329,
            slug: "may-chai",
            status: "empty",
          },
          { label: "Vạn Mỹ", value: 11335, slug: "van-my", status: "empty" },
          { label: "Cầu Tre", value: 11338, slug: "cau-tre", status: "filled" },
          {
            label: "Gia Viên",
            value: 11341,
            slug: "gia-vien",
            status: "empty",
          },
          { label: "Cầu Đất", value: 11344, slug: "cau-dat", status: "filled" },
          {
            label: "Đông Khê",
            value: 11350,
            slug: "dong-khe",
            status: "empty",
          },
          {
            label: "Đằng Giang",
            value: 11359,
            slug: "dang-giang",
            status: "empty",
          },
          {
            label: "Lạch Tray",
            value: 11365,
            slug: "lach-tray",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận Lê Chân",
        value: 305,
        slug: "quan-le-chan",
        status: "filled",
        ward: [
          { label: "An Biên", value: 11371, slug: "an-bien", status: "filled" },
          {
            label: "Trần Nguyên Hãn",
            value: 11383,
            slug: "tran-nguyen-han",
            status: "empty",
          },
          {
            label: "Hàng Kênh",
            value: 11395,
            slug: "hang-kenh",
            status: "empty",
          },
          {
            label: "An Dương",
            value: 11401,
            slug: "an-duong",
            status: "filled",
          },
          { label: "Lê Chân", value: 11402, slug: "le-chan", status: "empty" },
          {
            label: "Dư Hàng Kênh",
            value: 11404,
            slug: "du-hang-kenh",
            status: "empty",
          },
          {
            label: "Kênh Dương",
            value: 11405,
            slug: "kenh-duong",
            status: "empty",
          },
          { label: "Hồ Nam", value: 11406, slug: "ho-nam", status: "filled" },
          {
            label: "Vĩnh Niệm",
            value: 11407,
            slug: "vinh-niem",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận Hải An",
        value: 306,
        slug: "quan-hai-an",
        status: "filled",
        ward: [
          {
            label: "Đông Hải 1",
            value: 11410,
            slug: "dong-hai-1",
            status: "empty",
          },
          {
            label: "Đông Hải 2",
            value: 11411,
            slug: "dong-hai-2",
            status: "empty",
          },
          {
            label: "Đằng Lâm",
            value: 11413,
            slug: "dang-lam",
            status: "empty",
          },
          {
            label: "Thành Tô",
            value: 11414,
            slug: "thanh-to",
            status: "empty",
          },
          {
            label: "Đằng Hải",
            value: 11416,
            slug: "dang-hai",
            status: "filled",
          },
          { label: "Nam Hải", value: 11419, slug: "nam-hai", status: "empty" },
          { label: "Cát Bi", value: 11422, slug: "cat-bi", status: "filled" },
          {
            label: "Tràng Cát",
            value: 11425,
            slug: "trang-cat",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận Kiến An",
        value: 307,
        slug: "quan-kien-an",
        status: "empty",
        ward: [
          {
            label: "Đồng Hoà",
            value: 11431,
            slug: "dong-hoa",
            status: "empty",
          },
          { label: "Bắc Sơn", value: 11434, slug: "bac-son", status: "empty" },
          { label: "Nam Sơn", value: 11437, slug: "nam-son", status: "empty" },
          {
            label: "Ngọc Sơn",
            value: 11440,
            slug: "ngoc-son",
            status: "empty",
          },
          {
            label: "Trần Thành Ngọ",
            value: 11443,
            slug: "tran-thanh-ngo",
            status: "empty",
          },
          { label: "Văn Đẩu", value: 11446, slug: "van-dau", status: "empty" },
          { label: "Bắc Hà", value: 11449, slug: "bac-ha", status: "empty" },
        ],
      },
      {
        label: "Quận Đồ Sơn",
        value: 308,
        slug: "quan-do-son",
        status: "empty",
        ward: [
          {
            label: "Ngọc Xuyên",
            value: 11455,
            slug: "ngoc-xuyen",
            status: "empty",
          },
          { label: "Hải Sơn", value: 11458, slug: "hai-son", status: "empty" },
          {
            label: "Vạn Hương",
            value: 11461,
            slug: "van-huong",
            status: "empty",
          },
          {
            label: "Minh Đức",
            value: 11465,
            slug: "minh-duc",
            status: "empty",
          },
          { label: "Bàng La", value: 11467, slug: "bang-la", status: "empty" },
          { label: "Hợp Đức", value: 11737, slug: "hop-duc", status: "empty" },
        ],
      },
      {
        label: "Quận Dương Kinh",
        value: 309,
        slug: "quan-duong-kinh",
        status: "empty",
        ward: [
          { label: "Đa Phúc", value: 11683, slug: "da-phuc", status: "empty" },
          {
            label: "Hưng Đạo",
            value: 11686,
            slug: "hung-dao",
            status: "empty",
          },
          {
            label: "Anh Dũng",
            value: 11689,
            slug: "anh-dung",
            status: "empty",
          },
          {
            label: "Hải Thành",
            value: 11692,
            slug: "hai-thanh",
            status: "empty",
          },
          {
            label: "Hoà Nghĩa",
            value: 11707,
            slug: "hoa-nghia",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 11740,
            slug: "tan-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thuỷ Nguyên",
        value: 311,
        slug: "huyen-thuy-nguyen",
        status: "filled",
        ward: [
          {
            label: "Minh Đức",
            value: 11473,
            slug: "minh-duc",
            status: "empty",
          },
          {
            label: "Liên Xuân",
            value: 11485,
            slug: "lien-xuan",
            status: "empty",
          },
          {
            label: "Lưu Kiếm",
            value: 11488,
            slug: "luu-kiem",
            status: "empty",
          },
          {
            label: "Bạch Đằng",
            value: 11500,
            slug: "bach-dang",
            status: "empty",
          },
          {
            label: "Ninh Sơn",
            value: 11503,
            slug: "ninh-son",
            status: "empty",
          },
          {
            label: "Quảng Thanh",
            value: 11506,
            slug: "quang-thanh",
            status: "empty",
          },
          {
            label: "Trần Hưng Đạo",
            value: 11512,
            slug: "tran-hung-dao",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 11518,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Lê Hồng Phong",
            value: 11521,
            slug: "le-hong-phong",
            status: "empty",
          },
          {
            label: "Hoà Bình",
            value: 11527,
            slug: "hoa-binh",
            status: "empty",
          },
          { label: "Thủy Hà", value: 11530, slug: "thuy-ha", status: "empty" },
          { label: "An Lư", value: 11533, slug: "an-lu", status: "empty" },
          {
            label: "Phạm Ngũ Lão",
            value: 11539,
            slug: "pham-ngu-lao",
            status: "empty",
          },
          {
            label: "Nam Triệu Giang",
            value: 11542,
            slug: "nam-trieu-giang",
            status: "empty",
          },
          {
            label: "Tam Hưng",
            value: 11545,
            slug: "tam-hung",
            status: "empty",
          },
          { label: "Lập Lễ", value: 11551, slug: "lap-le", status: "empty" },
          {
            label: "Thiên Hương",
            value: 11557,
            slug: "thien-huong",
            status: "empty",
          },
          {
            label: "Thuỷ Đường",
            value: 11560,
            slug: "thuy-duong",
            status: "filled",
          },
          {
            label: "Hoàng Lâm",
            value: 11569,
            slug: "hoang-lam",
            status: "empty",
          },
          {
            label: "Hoa Động",
            value: 11572,
            slug: "hoa-dong",
            status: "empty",
          },
          {
            label: "Dương Quan",
            value: 11578,
            slug: "duong-quan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện An Dương",
        value: 312,
        slug: "huyen-an-duong",
        status: "empty",
        ward: [
          { label: "Lê Lợi", value: 11581, slug: "le-loi", status: "empty" },
          {
            label: "Lê Thiện",
            value: 11584,
            slug: "le-thien",
            status: "empty",
          },
          { label: "An Hoà", value: 11590, slug: "an-hoa", status: "empty" },
          {
            label: "Hồng Phong",
            value: 11593,
            slug: "hong-phong",
            status: "empty",
          },
          {
            label: "Tân Tiến",
            value: 11596,
            slug: "tan-tien",
            status: "empty",
          },
          { label: "Nam Sơn", value: 11608, slug: "nam-son", status: "empty" },
          { label: "An Hải", value: 11614, slug: "an-hai", status: "empty" },
          {
            label: "Đồng Thái",
            value: 11617,
            slug: "dong-thai",
            status: "empty",
          },
          { label: "An Đồng", value: 11623, slug: "an-dong", status: "empty" },
          {
            label: "Hồng Thái",
            value: 11626,
            slug: "hong-thai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện An Lão",
        value: 313,
        slug: "huyen-an-lao",
        status: "empty",
        ward: [
          { label: "An Lão", value: 11629, slug: "an-lao", status: "empty" },
          {
            label: "Bát Trang",
            value: 11632,
            slug: "bat-trang",
            status: "empty",
          },
          {
            label: "Trường Thọ",
            value: 11635,
            slug: "truong-tho",
            status: "empty",
          },
          {
            label: "Trường Thành",
            value: 11638,
            slug: "truong-thanh",
            status: "empty",
          },
          { label: "An Tiến", value: 11641, slug: "an-tien", status: "empty" },
          {
            label: "Quang Hưng",
            value: 11644,
            slug: "quang-hung",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 11647,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Quốc Tuấn",
            value: 11650,
            slug: "quoc-tuan",
            status: "empty",
          },
          {
            label: "An Thắng",
            value: 11653,
            slug: "an-thang",
            status: "empty",
          },
          {
            label: "Trường Sơn",
            value: 11656,
            slug: "truong-son",
            status: "empty",
          },
          { label: "Tân Dân", value: 11659, slug: "tan-dan", status: "empty" },
          {
            label: "Thái Sơn",
            value: 11662,
            slug: "thai-son",
            status: "empty",
          },
          {
            label: "Tân Viên",
            value: 11665,
            slug: "tan-vien",
            status: "empty",
          },
          { label: "Mỹ Đức", value: 11668, slug: "my-duc", status: "empty" },
          {
            label: "Chiến Thắng",
            value: 11671,
            slug: "chien-thang",
            status: "empty",
          },
          { label: "An Thọ", value: 11674, slug: "an-tho", status: "empty" },
          { label: "An Thái", value: 11677, slug: "an-thai", status: "empty" },
        ],
      },
      {
        label: "Huyện Kiến Thuỵ",
        value: 314,
        slug: "huyen-kien-thuy",
        status: "empty",
        ward: [
          { label: "Núi Đối", value: 11680, slug: "nui-doi", status: "empty" },
          {
            label: "Đông Phương",
            value: 11695,
            slug: "dong-phuong",
            status: "empty",
          },
          {
            label: "Thuận Thiên",
            value: 11698,
            slug: "thuan-thien",
            status: "empty",
          },
          {
            label: "Hữu Bằng",
            value: 11701,
            slug: "huu-bang",
            status: "empty",
          },
          {
            label: "Đại Đồng",
            value: 11704,
            slug: "dai-dong",
            status: "empty",
          },
          {
            label: "Ngũ Phúc",
            value: 11710,
            slug: "ngu-phuc",
            status: "empty",
          },
          {
            label: "Kiến Quốc",
            value: 11713,
            slug: "kien-quoc",
            status: "empty",
          },
          { label: "Du Lễ", value: 11716, slug: "du-le", status: "empty" },
          {
            label: "Thanh Sơn",
            value: 11722,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Minh Tân",
            value: 11725,
            slug: "minh-tan",
            status: "empty",
          },
          {
            label: "Kiến Hưng",
            value: 11728,
            slug: "kien-hung",
            status: "empty",
          },
          {
            label: "Tân Phong",
            value: 11734,
            slug: "tan-phong",
            status: "empty",
          },
          {
            label: "Tân Trào",
            value: 11743,
            slug: "tan-trao",
            status: "empty",
          },
          { label: "Đoàn Xá", value: 11746, slug: "doan-xa", status: "empty" },
          { label: "Tú Sơn", value: 11749, slug: "tu-son", status: "empty" },
          { label: "Đại Hợp", value: 11752, slug: "dai-hop", status: "empty" },
        ],
      },
      {
        label: "Huyện Tiên Lãng",
        value: 315,
        slug: "huyen-tien-lang",
        status: "empty",
        ward: [
          {
            label: "Tiên Lãng",
            value: 11755,
            slug: "tien-lang",
            status: "empty",
          },
          {
            label: "Đại Thắng",
            value: 11758,
            slug: "dai-thang",
            status: "empty",
          },
          {
            label: "Tiên Cường",
            value: 11761,
            slug: "tien-cuong",
            status: "empty",
          },
          {
            label: "Tự Cường",
            value: 11764,
            slug: "tu-cuong",
            status: "empty",
          },
          {
            label: "Quyết Tiến",
            value: 11770,
            slug: "quyet-tien",
            status: "empty",
          },
          {
            label: "Khởi Nghĩa",
            value: 11773,
            slug: "khoi-nghia",
            status: "empty",
          },
          {
            label: "Tiên Thanh",
            value: 11776,
            slug: "tien-thanh",
            status: "empty",
          },
          {
            label: "Cấp Tiến",
            value: 11779,
            slug: "cap-tien",
            status: "empty",
          },
          {
            label: "Kiến Thiết",
            value: 11782,
            slug: "kien-thiet",
            status: "empty",
          },
          {
            label: "Đoàn Lập",
            value: 11785,
            slug: "doan-lap",
            status: "empty",
          },
          {
            label: "Tân Minh",
            value: 11791,
            slug: "tan-minh",
            status: "empty",
          },
          {
            label: "Tiên Thắng",
            value: 11797,
            slug: "tien-thang",
            status: "empty",
          },
          {
            label: "Tiên Minh",
            value: 11800,
            slug: "tien-minh",
            status: "empty",
          },
          {
            label: "Bắc Hưng",
            value: 11803,
            slug: "bac-hung",
            status: "empty",
          },
          {
            label: "Nam Hưng",
            value: 11806,
            slug: "nam-hung",
            status: "empty",
          },
          {
            label: "Hùng Thắng",
            value: 11809,
            slug: "hung-thang",
            status: "empty",
          },
          {
            label: "Tây Hưng",
            value: 11812,
            slug: "tay-hung",
            status: "empty",
          },
          {
            label: "Đông Hưng",
            value: 11815,
            slug: "dong-hung",
            status: "empty",
          },
          {
            label: "Vinh Quang",
            value: 11821,
            slug: "vinh-quang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vĩnh Bảo",
        value: 316,
        slug: "huyen-vinh-bao",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Bảo",
            value: 11824,
            slug: "vinh-bao",
            status: "empty",
          },
          {
            label: "Dũng Tiến",
            value: 11827,
            slug: "dung-tien",
            status: "empty",
          },
          {
            label: "Giang Biên",
            value: 11830,
            slug: "giang-bien",
            status: "empty",
          },
          {
            label: "Thắng Thuỷ",
            value: 11833,
            slug: "thang-thuy",
            status: "empty",
          },
          {
            label: "Trung Lập",
            value: 11836,
            slug: "trung-lap",
            status: "empty",
          },
          {
            label: "Việt Tiến",
            value: 11839,
            slug: "viet-tien",
            status: "empty",
          },
          { label: "Vĩnh An", value: 11842, slug: "vinh-an", status: "empty" },
          {
            label: "Vĩnh Hoà",
            value: 11848,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Hùng Tiến",
            value: 11851,
            slug: "hung-tien",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 11857,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "Tân Liên",
            value: 11860,
            slug: "tan-lien",
            status: "empty",
          },
          {
            label: "Vĩnh Hưng",
            value: 11866,
            slug: "vinh-hung",
            status: "empty",
          },
          {
            label: "Vĩnh Hải",
            value: 11875,
            slug: "vinh-hai",
            status: "empty",
          },
          { label: "Liên Am", value: 11881, slug: "lien-am", status: "empty" },
          { label: "Lý Học", value: 11884, slug: "ly-hoc", status: "empty" },
          {
            label: "Tam Cường",
            value: 11887,
            slug: "tam-cuong",
            status: "empty",
          },
          {
            label: "Hoà Bình",
            value: 11890,
            slug: "hoa-binh",
            status: "empty",
          },
          {
            label: "Tiền Phong",
            value: 11893,
            slug: "tien-phong",
            status: "empty",
          },
          {
            label: "Cao Minh",
            value: 11902,
            slug: "cao-minh",
            status: "empty",
          },
          {
            label: "Trấn Dương",
            value: 11911,
            slug: "tran-duong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cát Hải",
        value: 317,
        slug: "huyen-cat-hai",
        status: "empty",
        ward: [
          { label: "Cát Bà", value: 11914, slug: "cat-ba", status: "empty" },
          { label: "Cát Hải", value: 11917, slug: "cat-hai", status: "empty" },
          {
            label: "Nghĩa Lộ",
            value: 11920,
            slug: "nghia-lo",
            status: "empty",
          },
          {
            label: "Đồng Bài",
            value: 11923,
            slug: "dong-bai",
            status: "empty",
          },
          {
            label: "Hoàng Châu",
            value: 11926,
            slug: "hoang-chau",
            status: "empty",
          },
          {
            label: "Văn Phong",
            value: 11929,
            slug: "van-phong",
            status: "empty",
          },
          {
            label: "Phù Long",
            value: 11932,
            slug: "phu-long",
            status: "empty",
          },
          {
            label: "Gia Luận",
            value: 11935,
            slug: "gia-luan",
            status: "empty",
          },
          {
            label: "Hiền Hào",
            value: 11938,
            slug: "hien-hao",
            status: "empty",
          },
          {
            label: "Trân Châu",
            value: 11941,
            slug: "tran-chau",
            status: "empty",
          },
          {
            label: "Việt Hải",
            value: 11944,
            slug: "viet-hai",
            status: "empty",
          },
          {
            label: "Xuân Đám",
            value: 11947,
            slug: "xuan-dam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bạch Long Vĩ",
        value: 318,
        slug: "huyen-bach-long-vi",
        status: "empty",
        ward: [],
      },
    ],
  },
  {
    label: "Hưng Yên",
    value: 33,
    slug: "hung-yen",
    status: "empty",
    district: [
      {
        label: "Thành phố Hưng Yên",
        value: 323,
        slug: "thanh-pho-hung-yen",
        status: "empty",
        ward: [
          { label: "Lam Sơn", value: 11950, slug: "lam-son", status: "empty" },
          {
            label: "Hiến Nam",
            value: 11953,
            slug: "hien-nam",
            status: "empty",
          },
          { label: "An Tảo", value: 11956, slug: "an-tao", status: "empty" },
          { label: "Lê Lợi", value: 11959, slug: "le-loi", status: "empty" },
          {
            label: "Minh Khai",
            value: 11962,
            slug: "minh-khai",
            status: "empty",
          },
          {
            label: "Hồng Châu",
            value: 11968,
            slug: "hong-chau",
            status: "empty",
          },
          {
            label: "Trung Nghĩa",
            value: 11971,
            slug: "trung-nghia",
            status: "empty",
          },
          {
            label: "Liên Phương",
            value: 11974,
            slug: "lien-phuong",
            status: "empty",
          },
          {
            label: "Phương Nam",
            value: 11977,
            slug: "phuong-nam",
            status: "empty",
          },
          {
            label: "Quảng Châu",
            value: 11980,
            slug: "quang-chau",
            status: "empty",
          },
          { label: "Bảo Khê", value: 11983, slug: "bao-khe", status: "empty" },
          {
            label: "Phú Cường",
            value: 12331,
            slug: "phu-cuong",
            status: "empty",
          },
          {
            label: "Hùng Cường",
            value: 12334,
            slug: "hung-cuong",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 12385,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "Hoàng Hanh",
            value: 12388,
            slug: "hoang-hanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Văn Lâm",
        value: 325,
        slug: "huyen-van-lam",
        status: "empty",
        ward: [
          {
            label: "Như Quỳnh",
            value: 11986,
            slug: "nhu-quynh",
            status: "empty",
          },
          { label: "Lạc Đạo", value: 11989, slug: "lac-dao", status: "empty" },
          { label: "Chỉ Đạo", value: 11992, slug: "chi-dao", status: "empty" },
          {
            label: "Đại Đồng",
            value: 11995,
            slug: "dai-dong",
            status: "empty",
          },
          {
            label: "Việt Hưng",
            value: 11998,
            slug: "viet-hung",
            status: "empty",
          },
          {
            label: "Tân Quang",
            value: 12001,
            slug: "tan-quang",
            status: "empty",
          },
          { label: "Đình Dù", value: 12004, slug: "dinh-du", status: "empty" },
          {
            label: "Minh Hải",
            value: 12007,
            slug: "minh-hai",
            status: "empty",
          },
          {
            label: "Lương Tài",
            value: 12010,
            slug: "luong-tai",
            status: "empty",
          },
          {
            label: "Trưng Trắc",
            value: 12013,
            slug: "trung-trac",
            status: "empty",
          },
          {
            label: "Lạc Hồng",
            value: 12016,
            slug: "lac-hong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Văn Giang",
        value: 326,
        slug: "huyen-van-giang",
        status: "empty",
        ward: [
          {
            label: "Văn Giang",
            value: 12019,
            slug: "van-giang",
            status: "empty",
          },
          {
            label: "Xuân Quan",
            value: 12022,
            slug: "xuan-quan",
            status: "empty",
          },
          { label: "Cửu Cao", value: 12025, slug: "cuu-cao", status: "empty" },
          {
            label: "Phụng Công",
            value: 12028,
            slug: "phung-cong",
            status: "empty",
          },
          {
            label: "Nghĩa Trụ",
            value: 12031,
            slug: "nghia-tru",
            status: "empty",
          },
          {
            label: "Long Hưng",
            value: 12034,
            slug: "long-hung",
            status: "empty",
          },
          {
            label: "Vĩnh Khúc",
            value: 12037,
            slug: "vinh-khuc",
            status: "empty",
          },
          {
            label: "Liên Nghĩa",
            value: 12040,
            slug: "lien-nghia",
            status: "empty",
          },
          {
            label: "Tân Tiến",
            value: 12043,
            slug: "tan-tien",
            status: "empty",
          },
          {
            label: "Thắng Lợi",
            value: 12046,
            slug: "thang-loi",
            status: "empty",
          },
          { label: "Mễ Sở", value: 12049, slug: "me-so", status: "empty" },
        ],
      },
      {
        label: "Huyện Yên Mỹ",
        value: 327,
        slug: "huyen-yen-my",
        status: "empty",
        ward: [
          { label: "Yên Mỹ", value: 12052, slug: "yen-my", status: "empty" },
          {
            label: "Nguyễn Văn Linh",
            value: 12055,
            slug: "nguyen-van-linh",
            status: "empty",
          },
          {
            label: "Đồng Than",
            value: 12061,
            slug: "dong-than",
            status: "empty",
          },
          {
            label: "Ngọc Long",
            value: 12064,
            slug: "ngoc-long",
            status: "empty",
          },
          { label: "Liêu Xá", value: 12067, slug: "lieu-xa", status: "empty" },
          {
            label: "Hoàn Long",
            value: 12070,
            slug: "hoan-long",
            status: "empty",
          },
          { label: "Tân Lập", value: 12073, slug: "tan-lap", status: "empty" },
          {
            label: "Thanh Long",
            value: 12076,
            slug: "thanh-long",
            status: "empty",
          },
          { label: "Yên Phú", value: 12079, slug: "yen-phu", status: "empty" },
          {
            label: "Trung Hòa",
            value: 12085,
            slug: "trung-hoa",
            status: "empty",
          },
          {
            label: "Việt Yên",
            value: 12091,
            slug: "viet-yen",
            status: "empty",
          },
          {
            label: "Tân Minh",
            value: 12100,
            slug: "tan-minh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Mỹ Hào",
        value: 328,
        slug: "thi-xa-my-hao",
        status: "empty",
        ward: [
          {
            label: "Bần Yên Nhân",
            value: 12103,
            slug: "ban-yen-nhan",
            status: "empty",
          },
          {
            label: "Phan Đình Phùng",
            value: 12106,
            slug: "phan-dinh-phung",
            status: "empty",
          },
          { label: "Cẩm Xá", value: 12109, slug: "cam-xa", status: "empty" },
          {
            label: "Dương Quang",
            value: 12112,
            slug: "duong-quang",
            status: "empty",
          },
          {
            label: "Hòa Phong",
            value: 12115,
            slug: "hoa-phong",
            status: "empty",
          },
          {
            label: "Nhân Hòa",
            value: 12118,
            slug: "nhan-hoa",
            status: "empty",
          },
          { label: "Dị Sử", value: 12121, slug: "di-su", status: "empty" },
          {
            label: "Bạch Sam",
            value: 12124,
            slug: "bach-sam",
            status: "empty",
          },
          {
            label: "Minh Đức",
            value: 12127,
            slug: "minh-duc",
            status: "empty",
          },
          {
            label: "Phùng Chí Kiên",
            value: 12130,
            slug: "phung-chi-kien",
            status: "empty",
          },
          {
            label: "Xuân Dục",
            value: 12133,
            slug: "xuan-duc",
            status: "empty",
          },
          {
            label: "Ngọc Lâm",
            value: 12136,
            slug: "ngoc-lam",
            status: "empty",
          },
          {
            label: "Hưng Long",
            value: 12139,
            slug: "hung-long",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ân Thi",
        value: 329,
        slug: "huyen-an-thi",
        status: "empty",
        ward: [
          { label: "Ân Thi", value: 12142, slug: "an-thi", status: "empty" },
          { label: "Phù Ủng", value: 12145, slug: "phu-ung", status: "empty" },
          { label: "Bắc Sơn", value: 12148, slug: "bac-son", status: "empty" },
          { label: "Bãi Sậy", value: 12151, slug: "bai-say", status: "empty" },
          {
            label: "Đào Dương",
            value: 12154,
            slug: "dao-duong",
            status: "empty",
          },
          {
            label: "Quang Vinh",
            value: 12157,
            slug: "quang-vinh",
            status: "empty",
          },
          { label: "Vân Du", value: 12160, slug: "van-du", status: "empty" },
          {
            label: "Xuân Trúc",
            value: 12166,
            slug: "xuan-truc",
            status: "empty",
          },
          {
            label: "Hoàng Hoa Thám",
            value: 12169,
            slug: "hoang-hoa-tham",
            status: "empty",
          },
          {
            label: "Quảng Lãng",
            value: 12172,
            slug: "quang-lang",
            status: "empty",
          },
          { label: "Đa Lộc", value: 12175, slug: "da-loc", status: "empty" },
          { label: "Đặng Lễ", value: 12178, slug: "dang-le", status: "empty" },
          {
            label: "Cẩm Ninh",
            value: 12181,
            slug: "cam-ninh",
            status: "empty",
          },
          {
            label: "Nguyễn Trãi",
            value: 12184,
            slug: "nguyen-trai",
            status: "empty",
          },
          {
            label: "Hồ Tùng Mậu",
            value: 12190,
            slug: "ho-tung-mau",
            status: "empty",
          },
          {
            label: "Tiền Phong",
            value: 12193,
            slug: "tien-phong",
            status: "empty",
          },
          {
            label: "Hồng Quang",
            value: 12196,
            slug: "hong-quang",
            status: "empty",
          },
          { label: "Hạ Lễ", value: 12202, slug: "ha-le", status: "empty" },
        ],
      },
      {
        label: "Huyện Khoái Châu",
        value: 330,
        slug: "huyen-khoai-chau",
        status: "empty",
        ward: [
          {
            label: "Khoái Châu",
            value: 12205,
            slug: "khoai-chau",
            status: "empty",
          },
          {
            label: "Đông Tảo",
            value: 12208,
            slug: "dong-tao",
            status: "empty",
          },
          {
            label: "Bình Minh",
            value: 12211,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Phạm Hồng Thái",
            value: 12214,
            slug: "pham-hong-thai",
            status: "empty",
          },
          {
            label: "Ông Đình",
            value: 12220,
            slug: "ong-dinh",
            status: "empty",
          },
          { label: "Tân Dân", value: 12223, slug: "tan-dan", status: "empty" },
          { label: "Tứ Dân", value: 12226, slug: "tu-dan", status: "empty" },
          { label: "An Vĩ", value: 12229, slug: "an-vi", status: "empty" },
          {
            label: "Đông Kết",
            value: 12232,
            slug: "dong-ket",
            status: "empty",
          },
          {
            label: "Dân Tiến",
            value: 12238,
            slug: "dan-tien",
            status: "empty",
          },
          {
            label: "Đồng Tiến",
            value: 12244,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Tân Châu",
            value: 12247,
            slug: "tan-chau",
            status: "empty",
          },
          {
            label: "Liên Khê",
            value: 12250,
            slug: "lien-khe",
            status: "empty",
          },
          {
            label: "Phùng Hưng",
            value: 12253,
            slug: "phung-hung",
            status: "empty",
          },
          {
            label: "Việt Hòa",
            value: 12256,
            slug: "viet-hoa",
            status: "empty",
          },
          {
            label: "Đông Ninh",
            value: 12259,
            slug: "dong-ninh",
            status: "empty",
          },
          { label: "Đại Tập", value: 12262, slug: "dai-tap", status: "empty" },
          {
            label: "Chí Minh",
            value: 12265,
            slug: "chi-minh",
            status: "empty",
          },
          {
            label: "Thuần Hưng",
            value: 12271,
            slug: "thuan-hung",
            status: "empty",
          },
          {
            label: "Nguyễn Huệ",
            value: 12274,
            slug: "nguyen-hue",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Kim Động",
        value: 331,
        slug: "huyen-kim-dong",
        status: "empty",
        ward: [
          {
            label: "Lương Bằng",
            value: 12280,
            slug: "luong-bang",
            status: "empty",
          },
          {
            label: "Nghĩa Dân",
            value: 12283,
            slug: "nghia-dan",
            status: "empty",
          },
          {
            label: "Toàn Thắng",
            value: 12286,
            slug: "toan-thang",
            status: "empty",
          },
          { label: "Vĩnh Xá", value: 12289, slug: "vinh-xa", status: "empty" },
          {
            label: "Phạm Ngũ Lão",
            value: 12292,
            slug: "pham-ngu-lao",
            status: "empty",
          },
          { label: "Phú Thọ", value: 12295, slug: "phu-tho", status: "empty" },
          {
            label: "Đồng Thanh",
            value: 12298,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Song Mai",
            value: 12301,
            slug: "song-mai",
            status: "empty",
          },
          {
            label: "Chính Nghĩa",
            value: 12304,
            slug: "chinh-nghia",
            status: "empty",
          },
          {
            label: "Mai Động",
            value: 12313,
            slug: "mai-dong",
            status: "empty",
          },
          { label: "Đức Hợp", value: 12316, slug: "duc-hop", status: "empty" },
          { label: "Hùng An", value: 12319, slug: "hung-an", status: "empty" },
          {
            label: "Ngọc Thanh",
            value: 12322,
            slug: "ngoc-thanh",
            status: "empty",
          },
          {
            label: "Diên Hồng",
            value: 12325,
            slug: "dien-hong",
            status: "empty",
          },
          {
            label: "Hiệp Cường",
            value: 12328,
            slug: "hiep-cuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tiên Lữ",
        value: 332,
        slug: "huyen-tien-lu",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Vương",
            value: 12337,
            slug: "thi-tran-vuong",
            status: "empty",
          },
          {
            label: "Hưng Đạo",
            value: 12340,
            slug: "hung-dao",
            status: "empty",
          },
          {
            label: "Nhật Tân",
            value: 12346,
            slug: "nhat-tan",
            status: "empty",
          },
          { label: "Lệ Xá", value: 12352, slug: "le-xa", status: "empty" },
          { label: "An Viên", value: 12355, slug: "an-vien", status: "empty" },
          {
            label: "Trung Dũng",
            value: 12361,
            slug: "trung-dung",
            status: "empty",
          },
          {
            label: "Hải Thắng",
            value: 12364,
            slug: "hai-thang",
            status: "empty",
          },
          { label: "Thủ Sỹ", value: 12367, slug: "thu-sy", status: "empty" },
          {
            label: "Thiện Phiến",
            value: 12370,
            slug: "thien-phien",
            status: "empty",
          },
          {
            label: "Thụy Lôi",
            value: 12373,
            slug: "thuy-loi",
            status: "empty",
          },
          {
            label: "Cương Chính",
            value: 12376,
            slug: "cuong-chinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phù Cừ",
        value: 333,
        slug: "huyen-phu-cu",
        status: "empty",
        ward: [
          {
            label: "Trần Cao",
            value: 12391,
            slug: "tran-cao",
            status: "empty",
          },
          {
            label: "Minh Tân",
            value: 12394,
            slug: "minh-tan",
            status: "empty",
          },
          {
            label: "Phan Sào Nam",
            value: 12397,
            slug: "phan-sao-nam",
            status: "empty",
          },
          {
            label: "Quang Hưng",
            value: 12400,
            slug: "quang-hung",
            status: "empty",
          },
          {
            label: "Minh Hoàng",
            value: 12403,
            slug: "minh-hoang",
            status: "empty",
          },
          {
            label: "Đoàn Đào",
            value: 12406,
            slug: "doan-dao",
            status: "empty",
          },
          {
            label: "Tống Phan",
            value: 12409,
            slug: "tong-phan",
            status: "empty",
          },
          {
            label: "Đình Cao",
            value: 12412,
            slug: "dinh-cao",
            status: "empty",
          },
          {
            label: "Nhật Quang",
            value: 12415,
            slug: "nhat-quang",
            status: "empty",
          },
          { label: "Tam Đa", value: 12421, slug: "tam-da", status: "empty" },
          {
            label: "Tiên Tiến",
            value: 12424,
            slug: "tien-tien",
            status: "empty",
          },
          {
            label: "Nguyên Hòa",
            value: 12427,
            slug: "nguyen-hoa",
            status: "empty",
          },
          {
            label: "Tống Trân",
            value: 12430,
            slug: "tong-tran",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Thái Bình",
    value: 34,
    slug: "thai-binh",
    status: "filled",
    district: [
      {
        label: "Thành phố Thái Bình",
        value: 336,
        slug: "thanh-pho-thai-binh",
        status: "empty",
        ward: [
          {
            label: "Lê Hồng Phong",
            value: 12433,
            slug: "le-hong-phong",
            status: "empty",
          },
          {
            label: "Bồ Xuyên",
            value: 12436,
            slug: "bo-xuyen",
            status: "empty",
          },
          { label: "Đề Thám", value: 12439, slug: "de-tham", status: "empty" },
          { label: "Kỳ Bá", value: 12442, slug: "ky-ba", status: "empty" },
          {
            label: "Quang Trung",
            value: 12445,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Phú Khánh",
            value: 12448,
            slug: "phu-khanh",
            status: "empty",
          },
          {
            label: "Tiền Phong",
            value: 12451,
            slug: "tien-phong",
            status: "empty",
          },
          {
            label: "Trần Hưng Đạo",
            value: 12452,
            slug: "tran-hung-dao",
            status: "empty",
          },
          {
            label: "Trần Lãm",
            value: 12454,
            slug: "tran-lam",
            status: "empty",
          },
          {
            label: "Đông Hòa",
            value: 12457,
            slug: "dong-hoa",
            status: "empty",
          },
          {
            label: "Hoàng Diệu",
            value: 12460,
            slug: "hoang-dieu",
            status: "empty",
          },
          {
            label: "Phú Xuân",
            value: 12463,
            slug: "phu-xuan",
            status: "empty",
          },
          { label: "Vũ Phúc", value: 12466, slug: "vu-phuc", status: "empty" },
          {
            label: "Vũ Chính",
            value: 12469,
            slug: "vu-chinh",
            status: "empty",
          },
          { label: "Đông Mỹ", value: 12817, slug: "dong-my", status: "empty" },
          {
            label: "Đông Thọ",
            value: 12820,
            slug: "dong-tho",
            status: "empty",
          },
          { label: "Vũ Đông", value: 13084, slug: "vu-dong", status: "empty" },
          { label: "Vũ Lạc", value: 13108, slug: "vu-lac", status: "empty" },
          {
            label: "Tân Bình",
            value: 13225,
            slug: "tan-binh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Quỳnh Phụ",
        value: 338,
        slug: "huyen-quynh-phu",
        status: "filled",
        ward: [
          {
            label: "Quỳnh Côi",
            value: 12472,
            slug: "quynh-coi",
            status: "filled",
          },
          { label: "An Khê", value: 12475, slug: "an-khe", status: "empty" },
          { label: "An Đồng", value: 12478, slug: "an-dong", status: "empty" },
          {
            label: "Quỳnh Hoa",
            value: 12481,
            slug: "quynh-hoa",
            status: "empty",
          },
          {
            label: "Quỳnh Lâm",
            value: 12484,
            slug: "quynh-lam",
            status: "empty",
          },
          {
            label: "Quỳnh Thọ",
            value: 12487,
            slug: "quynh-tho",
            status: "empty",
          },
          { label: "An Hiệp", value: 12490, slug: "an-hiep", status: "empty" },
          {
            label: "Quỳnh Hoàng",
            value: 12493,
            slug: "quynh-hoang",
            status: "empty",
          },
          {
            label: "Quỳnh Giao",
            value: 12496,
            slug: "quynh-giao",
            status: "empty",
          },
          { label: "An Thái", value: 12499, slug: "an-thai", status: "empty" },
          { label: "An Cầu", value: 12502, slug: "an-cau", status: "empty" },
          {
            label: "Quỳnh Hồng",
            value: 12505,
            slug: "quynh-hong",
            status: "empty",
          },
          {
            label: "Quỳnh Khê",
            value: 12508,
            slug: "quynh-khe",
            status: "empty",
          },
          {
            label: "Quỳnh Minh",
            value: 12511,
            slug: "quynh-minh",
            status: "empty",
          },
          { label: "An Ninh", value: 12514, slug: "an-ninh", status: "empty" },
          {
            label: "Quỳnh Ngọc",
            value: 12517,
            slug: "quynh-ngoc",
            status: "empty",
          },
          {
            label: "Quỳnh Hải",
            value: 12520,
            slug: "quynh-hai",
            status: "empty",
          },
          { label: "An Bài", value: 12523, slug: "an-bai", status: "empty" },
          { label: "An Ấp", value: 12526, slug: "an-ap", status: "empty" },
          {
            label: "Quỳnh Hội",
            value: 12529,
            slug: "quynh-hoi",
            status: "empty",
          },
          {
            label: "Châu Sơn",
            value: 12532,
            slug: "chau-son",
            status: "empty",
          },
          {
            label: "Quỳnh Mỹ",
            value: 12535,
            slug: "quynh-my",
            status: "empty",
          },
          { label: "An Quí", value: 12538, slug: "an-qui", status: "empty" },
          {
            label: "An Thanh",
            value: 12541,
            slug: "an-thanh",
            status: "empty",
          },
          { label: "An Vũ", value: 12547, slug: "an-vu", status: "empty" },
          { label: "An Lễ", value: 12550, slug: "an-le", status: "empty" },
          {
            label: "Quỳnh Hưng",
            value: 12553,
            slug: "quynh-hung",
            status: "empty",
          },
          { label: "An Mỹ", value: 12559, slug: "an-my", status: "empty" },
          {
            label: "Quỳnh Nguyên",
            value: 12562,
            slug: "quynh-nguyen",
            status: "empty",
          },
          { label: "An Vinh", value: 12565, slug: "an-vinh", status: "empty" },
          { label: "An Dục", value: 12571, slug: "an-duc", status: "empty" },
          {
            label: "Đông Hải",
            value: 12574,
            slug: "dong-hai",
            status: "empty",
          },
          {
            label: "Trang Bảo Xá",
            value: 12577,
            slug: "trang-bao-xa",
            status: "empty",
          },
          {
            label: "An Tràng",
            value: 12580,
            slug: "an-trang",
            status: "empty",
          },
          {
            label: "Đồng Tiến",
            value: 12583,
            slug: "dong-tien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hưng Hà",
        value: 339,
        slug: "huyen-hung-ha",
        status: "empty",
        ward: [
          { label: "Hưng Hà", value: 12586, slug: "hung-ha", status: "empty" },
          {
            label: "Quang Trung",
            value: 12589,
            slug: "quang-trung",
            status: "empty",
          },
          { label: "Tân Lễ", value: 12592, slug: "tan-le", status: "empty" },
          {
            label: "Cộng Hòa",
            value: 12595,
            slug: "cong-hoa",
            status: "empty",
          },
          {
            label: "Canh Tân",
            value: 12601,
            slug: "canh-tan",
            status: "empty",
          },
          {
            label: "Hòa Tiến",
            value: 12604,
            slug: "hoa-tien",
            status: "empty",
          },
          {
            label: "Tân Tiến",
            value: 12610,
            slug: "tan-tien",
            status: "empty",
          },
          {
            label: "Hưng Nhân",
            value: 12613,
            slug: "hung-nhan",
            status: "empty",
          },
          {
            label: "Đoan Hùng",
            value: 12616,
            slug: "doan-hung",
            status: "empty",
          },
          {
            label: "Duyên Hải",
            value: 12619,
            slug: "duyen-hai",
            status: "empty",
          },
          { label: "Tân Hòa", value: 12622, slug: "tan-hoa", status: "empty" },
          { label: "Văn Cẩm", value: 12625, slug: "van-cam", status: "empty" },
          { label: "Bắc Sơn", value: 12628, slug: "bac-son", status: "empty" },
          { label: "Đông Đô", value: 12631, slug: "dong-do", status: "empty" },
          {
            label: "Phúc Khánh",
            value: 12634,
            slug: "phuc-khanh",
            status: "empty",
          },
          {
            label: "Liên Hiệp",
            value: 12637,
            slug: "lien-hiep",
            status: "empty",
          },
          { label: "Tây Đô", value: 12640, slug: "tay-do", status: "empty" },
          {
            label: "Thống Nhất",
            value: 12643,
            slug: "thong-nhat",
            status: "empty",
          },
          {
            label: "Tiến Đức",
            value: 12646,
            slug: "tien-duc",
            status: "empty",
          },
          {
            label: "Thái Hưng",
            value: 12649,
            slug: "thai-hung",
            status: "empty",
          },
          {
            label: "Thái Phương",
            value: 12652,
            slug: "thai-phuong",
            status: "empty",
          },
          {
            label: "Hòa Bình",
            value: 12655,
            slug: "hoa-binh",
            status: "empty",
          },
          {
            label: "Chi Lăng",
            value: 12656,
            slug: "chi-lang",
            status: "empty",
          },
          {
            label: "Minh Khai",
            value: 12658,
            slug: "minh-khai",
            status: "empty",
          },
          { label: "Hồng An", value: 12661, slug: "hong-an", status: "empty" },
          {
            label: "Kim Chung",
            value: 12664,
            slug: "kim-chung",
            status: "empty",
          },
          {
            label: "Hồng Lĩnh",
            value: 12667,
            slug: "hong-linh",
            status: "empty",
          },
          {
            label: "Minh Tân",
            value: 12670,
            slug: "minh-tan",
            status: "empty",
          },
          {
            label: "Văn Lang",
            value: 12673,
            slug: "van-lang",
            status: "empty",
          },
          { label: "Độc Lập", value: 12676, slug: "doc-lap", status: "empty" },
          { label: "Chí Hòa", value: 12679, slug: "chi-hoa", status: "empty" },
          {
            label: "Minh Hòa",
            value: 12682,
            slug: "minh-hoa",
            status: "empty",
          },
          {
            label: "Hồng Minh",
            value: 12685,
            slug: "hong-minh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đông Hưng",
        value: 340,
        slug: "huyen-dong-hung",
        status: "empty",
        ward: [
          {
            label: "Đông Hưng",
            value: 12688,
            slug: "dong-hung",
            status: "empty",
          },
          {
            label: "Đông Phương",
            value: 12694,
            slug: "dong-phuong",
            status: "empty",
          },
          {
            label: "Liên An Đô",
            value: 12700,
            slug: "lien-an-do",
            status: "empty",
          },
          {
            label: "Đông Sơn",
            value: 12703,
            slug: "dong-son",
            status: "empty",
          },
          {
            label: "Đông Cường",
            value: 12706,
            slug: "dong-cuong",
            status: "empty",
          },
          {
            label: "Phú Lương",
            value: 12709,
            slug: "phu-luong",
            status: "empty",
          },
          { label: "Mê Linh", value: 12712, slug: "me-linh", status: "empty" },
          {
            label: "Lô Giang",
            value: 12715,
            slug: "lo-giang",
            status: "empty",
          },
          { label: "Đông La", value: 12718, slug: "dong-la", status: "empty" },
          {
            label: "Minh Tân",
            value: 12721,
            slug: "minh-tan",
            status: "empty",
          },
          { label: "Đông Xá", value: 12724, slug: "dong-xa", status: "empty" },
          {
            label: "Nguyên Xá",
            value: 12730,
            slug: "nguyen-xa",
            status: "empty",
          },
          {
            label: "Phong Dương Tiến",
            value: 12736,
            slug: "phong-duong-tien",
            status: "empty",
          },
          {
            label: "Hồng Việt",
            value: 12739,
            slug: "hong-viet",
            status: "empty",
          },
          {
            label: "Hà Giang",
            value: 12745,
            slug: "ha-giang",
            status: "empty",
          },
          {
            label: "Đông Kinh",
            value: 12748,
            slug: "dong-kinh",
            status: "empty",
          },
          {
            label: "Đông Hợp",
            value: 12751,
            slug: "dong-hop",
            status: "empty",
          },
          {
            label: "Thăng Long",
            value: 12754,
            slug: "thang-long",
            status: "empty",
          },
          {
            label: "Đông Các",
            value: 12757,
            slug: "dong-cac",
            status: "empty",
          },
          {
            label: "Phú Châu",
            value: 12760,
            slug: "phu-chau",
            status: "empty",
          },
          {
            label: "Liên Hoa",
            value: 12763,
            slug: "lien-hoa",
            status: "empty",
          },
          {
            label: "Đông Tân",
            value: 12769,
            slug: "dong-tan",
            status: "empty",
          },
          {
            label: "Đông Vinh",
            value: 12772,
            slug: "dong-vinh",
            status: "empty",
          },
          {
            label: "Xuân Quang Động",
            value: 12775,
            slug: "xuan-quang-dong",
            status: "empty",
          },
          {
            label: "Hồng Bạch",
            value: 12778,
            slug: "hong-bach",
            status: "empty",
          },
          {
            label: "Trọng Quan",
            value: 12784,
            slug: "trong-quan",
            status: "empty",
          },
          {
            label: "Hồng Giang",
            value: 12790,
            slug: "hong-giang",
            status: "empty",
          },
          {
            label: "Đông Quan",
            value: 12793,
            slug: "dong-quan",
            status: "empty",
          },
          { label: "Đông Á", value: 12802, slug: "dong-a", status: "empty" },
          {
            label: "Đông Hoàng",
            value: 12808,
            slug: "dong-hoang",
            status: "empty",
          },
          {
            label: "Đông Dương",
            value: 12811,
            slug: "dong-duong",
            status: "empty",
          },
          {
            label: "Minh Phú",
            value: 12823,
            slug: "minh-phu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thái Thụy",
        value: 341,
        slug: "huyen-thai-thuy",
        status: "filled",
        ward: [
          {
            label: "Diêm Điền",
            value: 12826,
            slug: "diem-dien",
            status: "empty",
          },
          {
            label: "Thụy Trường",
            value: 12832,
            slug: "thuy-truong",
            status: "empty",
          },
          {
            label: "Hồng Dũng",
            value: 12841,
            slug: "hong-dung",
            status: "empty",
          },
          {
            label: "Thụy Quỳnh",
            value: 12844,
            slug: "thuy-quynh",
            status: "empty",
          },
          { label: "An Tân", value: 12847, slug: "an-tan", status: "empty" },
          {
            label: "Thụy Ninh",
            value: 12850,
            slug: "thuy-ninh",
            status: "empty",
          },
          {
            label: "Thụy Hưng",
            value: 12853,
            slug: "thuy-hung",
            status: "empty",
          },
          {
            label: "Thụy Việt",
            value: 12856,
            slug: "thuy-viet",
            status: "empty",
          },
          {
            label: "Thụy Văn",
            value: 12859,
            slug: "thuy-van",
            status: "empty",
          },
          {
            label: "Thụy Xuân",
            value: 12862,
            slug: "thuy-xuan",
            status: "empty",
          },
          {
            label: "Dương Phúc",
            value: 12865,
            slug: "duong-phuc",
            status: "empty",
          },
          {
            label: "Thụy Trình",
            value: 12868,
            slug: "thuy-trinh",
            status: "empty",
          },
          {
            label: "Thụy Bình",
            value: 12871,
            slug: "thuy-binh",
            status: "empty",
          },
          {
            label: "Thụy Chính",
            value: 12874,
            slug: "thuy-chinh",
            status: "empty",
          },
          {
            label: "Thụy Dân",
            value: 12877,
            slug: "thuy-dan",
            status: "empty",
          },
          {
            label: "Thụy Hải",
            value: 12880,
            slug: "thuy-hai",
            status: "empty",
          },
          {
            label: "Thụy Liên",
            value: 12889,
            slug: "thuy-lien",
            status: "empty",
          },
          {
            label: "Thụy Duyên",
            value: 12892,
            slug: "thuy-duyen",
            status: "empty",
          },
          {
            label: "Thụy Thanh",
            value: 12898,
            slug: "thuy-thanh",
            status: "empty",
          },
          {
            label: "Thụy Sơn",
            value: 12901,
            slug: "thuy-son",
            status: "empty",
          },
          {
            label: "Thụy Phong",
            value: 12904,
            slug: "thuy-phong",
            status: "empty",
          },
          {
            label: "Thái Thượng",
            value: 12907,
            slug: "thai-thuong",
            status: "empty",
          },
          {
            label: "Thái Nguyên",
            value: 12910,
            slug: "thai-nguyen",
            status: "empty",
          },
          {
            label: "Dương Hồng Thủy",
            value: 12916,
            slug: "duong-hong-thuy",
            status: "empty",
          },
          {
            label: "Thái Giang",
            value: 12919,
            slug: "thai-giang",
            status: "empty",
          },
          { label: "Hòa An", value: 12922, slug: "hoa-an", status: "empty" },
          { label: "Sơn Hà", value: 12925, slug: "son-ha", status: "empty" },
          {
            label: "Thái Phúc",
            value: 12934,
            slug: "thai-phuc",
            status: "empty",
          },
          {
            label: "Thái Hưng",
            value: 12937,
            slug: "thai-hung",
            status: "empty",
          },
          { label: "Thái Đô", value: 12940, slug: "thai-do", status: "empty" },
          {
            label: "Thái Xuyên",
            value: 12943,
            slug: "thai-xuyen",
            status: "empty",
          },
          { label: "Mỹ Lộc", value: 12949, slug: "my-loc", status: "empty" },
          { label: "Tân Học", value: 12958, slug: "tan-hoc", status: "filled" },
          {
            label: "Thái Thịnh",
            value: 12961,
            slug: "thai-thinh",
            status: "empty",
          },
          {
            label: "Thuần Thành",
            value: 12964,
            slug: "thuan-thanh",
            status: "empty",
          },
          {
            label: "Thái Thọ",
            value: 12967,
            slug: "thai-tho",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tiền Hải",
        value: 342,
        slug: "huyen-tien-hai",
        status: "filled",
        ward: [
          {
            label: "Tiền Hải",
            value: 12970,
            slug: "tien-hai",
            status: "empty",
          },
          {
            label: "Đông Trà",
            value: 12976,
            slug: "dong-tra",
            status: "empty",
          },
          {
            label: "Đông Long",
            value: 12979,
            slug: "dong-long",
            status: "empty",
          },
          { label: "Vũ Lăng", value: 12985, slug: "vu-lang", status: "empty" },
          {
            label: "Đông Xuyên",
            value: 12988,
            slug: "dong-xuyen",
            status: "empty",
          },
          {
            label: "Tây Lương",
            value: 12991,
            slug: "tay-luong",
            status: "empty",
          },
          {
            label: "Tây Ninh",
            value: 12994,
            slug: "tay-ninh",
            status: "empty",
          },
          {
            label: "Đông Quang",
            value: 12997,
            slug: "dong-quang",
            status: "empty",
          },
          {
            label: "Đông Hoàng",
            value: 13000,
            slug: "dong-hoang",
            status: "empty",
          },
          {
            label: "Đông Minh",
            value: 13003,
            slug: "dong-minh",
            status: "empty",
          },
          { label: "An Ninh", value: 13012, slug: "an-ninh", status: "empty" },
          { label: "Đông Cơ", value: 13018, slug: "dong-co", status: "empty" },
          {
            label: "Tây Giang",
            value: 13021,
            slug: "tay-giang",
            status: "filled",
          },
          {
            label: "Đông Lâm",
            value: 13024,
            slug: "dong-lam",
            status: "empty",
          },
          {
            label: "Phương Công",
            value: 13027,
            slug: "phuong-cong",
            status: "empty",
          },
          { label: "Ái Quốc", value: 13030, slug: "ai-quoc", status: "empty" },
          {
            label: "Nam Cường",
            value: 13036,
            slug: "nam-cuong",
            status: "empty",
          },
          {
            label: "Vân Trường",
            value: 13039,
            slug: "van-truong",
            status: "empty",
          },
          {
            label: "Nam Chính",
            value: 13045,
            slug: "nam-chinh",
            status: "empty",
          },
          { label: "Bắc Hải", value: 13048, slug: "bac-hai", status: "empty" },
          {
            label: "Nam Thịnh",
            value: 13051,
            slug: "nam-thinh",
            status: "empty",
          },
          { label: "Nam Hà", value: 13054, slug: "nam-ha", status: "empty" },
          {
            label: "Nam Tiến",
            value: 13057,
            slug: "nam-tien",
            status: "empty",
          },
          {
            label: "Nam Trung",
            value: 13060,
            slug: "nam-trung",
            status: "empty",
          },
          {
            label: "Nam Hồng",
            value: 13063,
            slug: "nam-hong",
            status: "empty",
          },
          {
            label: "Nam Hưng",
            value: 13066,
            slug: "nam-hung",
            status: "empty",
          },
          { label: "Nam Hải", value: 13069, slug: "nam-hai", status: "empty" },
          { label: "Nam Phú", value: 13072, slug: "nam-phu", status: "empty" },
        ],
      },
      {
        label: "Huyện Kiến Xương",
        value: 343,
        slug: "huyen-kien-xuong",
        status: "empty",
        ward: [
          {
            label: "Kiến Xương",
            value: 13075,
            slug: "kien-xuong",
            status: "empty",
          },
          {
            label: "Trà Giang",
            value: 13078,
            slug: "tra-giang",
            status: "empty",
          },
          {
            label: "Quốc Tuấn",
            value: 13081,
            slug: "quoc-tuan",
            status: "empty",
          },
          { label: "An Bình", value: 13087, slug: "an-binh", status: "empty" },
          { label: "Tây Sơn", value: 13090, slug: "tay-son", status: "empty" },
          {
            label: "Hồng Thái",
            value: 13093,
            slug: "hong-thai",
            status: "empty",
          },
          {
            label: "Bình Nguyên",
            value: 13096,
            slug: "binh-nguyen",
            status: "empty",
          },
          { label: "Lê Lợi", value: 13102, slug: "le-loi", status: "empty" },
          { label: "Vũ Lễ", value: 13111, slug: "vu-le", status: "empty" },
          {
            label: "Thanh Tân",
            value: 13114,
            slug: "thanh-tan",
            status: "empty",
          },
          {
            label: "Thống Nhất",
            value: 13120,
            slug: "thong-nhat",
            status: "empty",
          },
          { label: "Vũ Ninh", value: 13126, slug: "vu-ninh", status: "empty" },
          { label: "Vũ An", value: 13129, slug: "vu-an", status: "empty" },
          {
            label: "Quang Lịch",
            value: 13132,
            slug: "quang-lich",
            status: "empty",
          },
          {
            label: "Hòa Bình",
            value: 13135,
            slug: "hoa-binh",
            status: "empty",
          },
          {
            label: "Bình Minh",
            value: 13138,
            slug: "binh-minh",
            status: "empty",
          },
          { label: "Vũ Quí", value: 13141, slug: "vu-qui", status: "empty" },
          {
            label: "Quang Bình",
            value: 13144,
            slug: "quang-binh",
            status: "empty",
          },
          {
            label: "Vũ Trung",
            value: 13150,
            slug: "vu-trung",
            status: "empty",
          },
          { label: "Vũ Công", value: 13156, slug: "vu-cong", status: "empty" },
          { label: "Hồng Vũ", value: 13159, slug: "hong-vu", status: "empty" },
          {
            label: "Quang Minh",
            value: 13162,
            slug: "quang-minh",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 13165,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Minh Quang",
            value: 13171,
            slug: "minh-quang",
            status: "empty",
          },
          {
            label: "Minh Tân",
            value: 13177,
            slug: "minh-tan",
            status: "empty",
          },
          {
            label: "Nam Bình",
            value: 13180,
            slug: "nam-binh",
            status: "empty",
          },
          {
            label: "Bình Thanh",
            value: 13183,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Bình Định",
            value: 13186,
            slug: "binh-dinh",
            status: "empty",
          },
          {
            label: "Hồng Tiến",
            value: 13189,
            slug: "hong-tien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vũ Thư",
        value: 344,
        slug: "huyen-vu-thu",
        status: "empty",
        ward: [
          { label: "Vũ Thư", value: 13192, slug: "vu-thu", status: "empty" },
          { label: "Hồng Lý", value: 13195, slug: "hong-ly", status: "empty" },
          {
            label: "Đồng Thanh",
            value: 13198,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Xuân Hòa",
            value: 13201,
            slug: "xuan-hoa",
            status: "empty",
          },
          {
            label: "Hiệp Hòa",
            value: 13204,
            slug: "hiep-hoa",
            status: "empty",
          },
          {
            label: "Phúc Thành",
            value: 13207,
            slug: "phuc-thanh",
            status: "empty",
          },
          {
            label: "Tân Phong",
            value: 13210,
            slug: "tan-phong",
            status: "empty",
          },
          {
            label: "Song Lãng",
            value: 13213,
            slug: "song-lang",
            status: "empty",
          },
          { label: "Tân Hòa", value: 13216, slug: "tan-hoa", status: "empty" },
          {
            label: "Việt Hùng",
            value: 13219,
            slug: "viet-hung",
            status: "empty",
          },
          {
            label: "Minh Lãng",
            value: 13222,
            slug: "minh-lang",
            status: "empty",
          },
          {
            label: "Minh Khai",
            value: 13228,
            slug: "minh-khai",
            status: "empty",
          },
          {
            label: "Dũng Nghĩa",
            value: 13231,
            slug: "dung-nghia",
            status: "empty",
          },
          {
            label: "Minh Quang",
            value: 13234,
            slug: "minh-quang",
            status: "empty",
          },
          {
            label: "Tam Quang",
            value: 13237,
            slug: "tam-quang",
            status: "empty",
          },
          { label: "Tân Lập", value: 13240, slug: "tan-lap", status: "empty" },
          {
            label: "Bách Thuận",
            value: 13243,
            slug: "bach-thuan",
            status: "empty",
          },
          { label: "Tự Tân", value: 13246, slug: "tu-tan", status: "empty" },
          { label: "Song An", value: 13249, slug: "song-an", status: "empty" },
          {
            label: "Trung An",
            value: 13252,
            slug: "trung-an",
            status: "empty",
          },
          { label: "Vũ Hội", value: 13255, slug: "vu-hoi", status: "empty" },
          {
            label: "Hòa Bình",
            value: 13258,
            slug: "hoa-binh",
            status: "empty",
          },
          {
            label: "Nguyên Xá",
            value: 13261,
            slug: "nguyen-xa",
            status: "empty",
          },
          {
            label: "Việt Thuận",
            value: 13264,
            slug: "viet-thuan",
            status: "empty",
          },
          { label: "Vũ Vinh", value: 13267, slug: "vu-vinh", status: "empty" },
          { label: "Vũ Đoài", value: 13270, slug: "vu-doai", status: "empty" },
          { label: "Vũ Tiến", value: 13273, slug: "vu-tien", status: "empty" },
          { label: "Vũ Vân", value: 13276, slug: "vu-van", status: "empty" },
          {
            label: "Duy Nhất",
            value: 13279,
            slug: "duy-nhat",
            status: "empty",
          },
          {
            label: "Hồng Phong",
            value: 13282,
            slug: "hong-phong",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Hà Nam",
    value: 35,
    slug: "ha-nam",
    status: "empty",
    district: [
      {
        label: "Thành phố Phủ Lý",
        value: 347,
        slug: "thanh-pho-phu-ly",
        status: "empty",
        ward: [
          {
            label: "Quang Trung",
            value: 13285,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Lê Hồng Phong",
            value: 13291,
            slug: "le-hong-phong",
            status: "empty",
          },
          {
            label: "Châu Cầu",
            value: 13294,
            slug: "chau-cau",
            status: "empty",
          },
          { label: "Lam Hạ", value: 13303, slug: "lam-ha", status: "empty" },
          { label: "Phù Vân", value: 13306, slug: "phu-van", status: "empty" },
          {
            label: "Liêm Chính",
            value: 13309,
            slug: "liem-chinh",
            status: "empty",
          },
          {
            label: "Thanh Châu",
            value: 13315,
            slug: "thanh-chau",
            status: "empty",
          },
          {
            label: "Châu Sơn",
            value: 13318,
            slug: "chau-son",
            status: "empty",
          },
          {
            label: "Tân Hiệp",
            value: 13366,
            slug: "tan-hiep",
            status: "empty",
          },
          {
            label: "Kim Bình",
            value: 13426,
            slug: "kim-binh",
            status: "empty",
          },
          {
            label: "Tân Liêm",
            value: 13444,
            slug: "tan-liem",
            status: "empty",
          },
          {
            label: "Thanh Tuyền",
            value: 13459,
            slug: "thanh-tuyen",
            status: "empty",
          },
          { label: "Đinh Xá", value: 13507, slug: "dinh-xa", status: "empty" },
          {
            label: "Trịnh Xá",
            value: 13513,
            slug: "trinh-xa",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Duy Tiên",
        value: 349,
        slug: "thi-xa-duy-tien",
        status: "empty",
        ward: [
          {
            label: "Đồng Văn",
            value: 13321,
            slug: "dong-van",
            status: "empty",
          },
          { label: "Hòa Mạc", value: 13324, slug: "hoa-mac", status: "empty" },
          {
            label: "Mộc Hoàn",
            value: 13327,
            slug: "moc-hoan",
            status: "empty",
          },
          {
            label: "Châu Giang",
            value: 13330,
            slug: "chau-giang",
            status: "empty",
          },
          {
            label: "Bạch Thượng",
            value: 13333,
            slug: "bach-thuong",
            status: "empty",
          },
          {
            label: "Duy Minh",
            value: 13336,
            slug: "duy-minh",
            status: "empty",
          },
          { label: "Duy Hải", value: 13342, slug: "duy-hai", status: "empty" },
          {
            label: "Chuyên Ngoại",
            value: 13345,
            slug: "chuyen-ngoai",
            status: "empty",
          },
          { label: "Yên Bắc", value: 13348, slug: "yen-bac", status: "empty" },
          {
            label: "Trác Văn",
            value: 13351,
            slug: "trac-van",
            status: "empty",
          },
          {
            label: "Tiên Nội",
            value: 13354,
            slug: "tien-noi",
            status: "empty",
          },
          {
            label: "Hoàng Đông",
            value: 13357,
            slug: "hoang-dong",
            status: "empty",
          },
          { label: "Yên Nam", value: 13360, slug: "yen-nam", status: "empty" },
          {
            label: "Tiên Ngoại",
            value: 13363,
            slug: "tien-ngoai",
            status: "empty",
          },
          {
            label: "Tiên Sơn",
            value: 13369,
            slug: "tien-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Kim Bảng",
        value: 350,
        slug: "huyen-kim-bang",
        status: "empty",
        ward: [
          {
            label: "Phường Quế",
            value: 13384,
            slug: "phuong-que",
            status: "empty",
          },
          {
            label: "Nguyễn Úy",
            value: 13387,
            slug: "nguyen-uy",
            status: "empty",
          },
          {
            label: "Đại Cương",
            value: 13390,
            slug: "dai-cuong",
            status: "empty",
          },
          { label: "Lê Hồ", value: 13393, slug: "le-ho", status: "empty" },
          {
            label: "Tượng Lĩnh",
            value: 13396,
            slug: "tuong-linh",
            status: "empty",
          },
          { label: "Tân Tựu", value: 13402, slug: "tan-tuu", status: "empty" },
          {
            label: "Đồng Hóa",
            value: 13405,
            slug: "dong-hoa",
            status: "empty",
          },
          {
            label: "Hoàng Tây",
            value: 13408,
            slug: "hoang-tay",
            status: "empty",
          },
          { label: "Tân Sơn", value: 13411, slug: "tan-son", status: "empty" },
          {
            label: "Thụy Lôi",
            value: 13414,
            slug: "thuy-loi",
            status: "empty",
          },
          { label: "Văn Xá", value: 13417, slug: "van-xa", status: "empty" },
          {
            label: "Khả Phong",
            value: 13420,
            slug: "kha-phong",
            status: "empty",
          },
          {
            label: "Ngọc Sơn",
            value: 13423,
            slug: "ngoc-son",
            status: "empty",
          },
          { label: "Ba Sao", value: 13429, slug: "ba-sao", status: "empty" },
          {
            label: "Liên Sơn",
            value: 13432,
            slug: "lien-son",
            status: "empty",
          },
          { label: "Thi Sơn", value: 13435, slug: "thi-son", status: "empty" },
          {
            label: "Thanh Sơn",
            value: 13438,
            slug: "thanh-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thanh Liêm",
        value: 351,
        slug: "huyen-thanh-liem",
        status: "empty",
        ward: [
          {
            label: "Kiện Khê",
            value: 13441,
            slug: "kien-khe",
            status: "empty",
          },
          {
            label: "Liêm Phong",
            value: 13450,
            slug: "liem-phong",
            status: "empty",
          },
          {
            label: "Thanh Hà",
            value: 13453,
            slug: "thanh-ha",
            status: "empty",
          },
          {
            label: "Liêm Cần",
            value: 13456,
            slug: "liem-can",
            status: "empty",
          },
          {
            label: "Liêm Thuận",
            value: 13465,
            slug: "liem-thuan",
            status: "empty",
          },
          {
            label: "Thanh Thủy",
            value: 13468,
            slug: "thanh-thuy",
            status: "empty",
          },
          {
            label: "Thanh Phong",
            value: 13471,
            slug: "thanh-phong",
            status: "empty",
          },
          {
            label: "Tân Thanh",
            value: 13474,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Thanh Tân",
            value: 13477,
            slug: "thanh-tan",
            status: "empty",
          },
          {
            label: "Liêm Túc",
            value: 13480,
            slug: "liem-tuc",
            status: "empty",
          },
          {
            label: "Liêm Sơn",
            value: 13483,
            slug: "liem-son",
            status: "empty",
          },
          {
            label: "Thanh Hương",
            value: 13486,
            slug: "thanh-huong",
            status: "empty",
          },
          {
            label: "Thanh Nghị",
            value: 13489,
            slug: "thanh-nghi",
            status: "empty",
          },
          {
            label: "Thanh Tâm",
            value: 13492,
            slug: "thanh-tam",
            status: "empty",
          },
          {
            label: "Thanh Nguyên",
            value: 13495,
            slug: "thanh-nguyen",
            status: "empty",
          },
          {
            label: "Thanh Hải",
            value: 13498,
            slug: "thanh-hai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bình Lục",
        value: 352,
        slug: "huyen-binh-luc",
        status: "empty",
        ward: [
          { label: "Bình Mỹ", value: 13501, slug: "binh-my", status: "empty" },
          {
            label: "Bình Nghĩa",
            value: 13504,
            slug: "binh-nghia",
            status: "empty",
          },
          {
            label: "Tràng An",
            value: 13510,
            slug: "trang-an",
            status: "empty",
          },
          { label: "Đồng Du", value: 13516, slug: "dong-du", status: "empty" },
          { label: "Ngọc Lũ", value: 13519, slug: "ngoc-lu", status: "empty" },
          { label: "Đồn Xá", value: 13525, slug: "don-xa", status: "empty" },
          { label: "An Ninh", value: 13528, slug: "an-ninh", status: "empty" },
          { label: "Bồ Đề", value: 13531, slug: "bo-de", status: "empty" },
          { label: "Bình An", value: 13540, slug: "binh-an", status: "empty" },
          { label: "Vũ Bản", value: 13543, slug: "vu-ban", status: "empty" },
          {
            label: "Trung Lương",
            value: 13546,
            slug: "trung-luong",
            status: "empty",
          },
          { label: "An Đổ", value: 13552, slug: "an-do", status: "empty" },
          { label: "La Sơn", value: 13555, slug: "la-son", status: "empty" },
          {
            label: "Tiêu Động",
            value: 13558,
            slug: "tieu-dong",
            status: "empty",
          },
          { label: "An Lão", value: 13561, slug: "an-lao", status: "empty" },
        ],
      },
      {
        label: "Huyện Lý Nhân",
        value: 353,
        slug: "huyen-ly-nhan",
        status: "empty",
        ward: [
          { label: "Hợp Lý", value: 13567, slug: "hop-ly", status: "empty" },
          {
            label: "Nguyên Lý",
            value: 13570,
            slug: "nguyen-ly",
            status: "empty",
          },
          {
            label: "Chính Lý",
            value: 13573,
            slug: "chinh-ly",
            status: "empty",
          },
          { label: "Chân Lý", value: 13576, slug: "chan-ly", status: "empty" },
          { label: "Đạo Lý", value: 13579, slug: "dao-ly", status: "empty" },
          { label: "Công Lý", value: 13582, slug: "cong-ly", status: "empty" },
          { label: "Văn Lý", value: 13585, slug: "van-ly", status: "empty" },
          { label: "Bắc Lý", value: 13588, slug: "bac-ly", status: "empty" },
          { label: "Đức Lý", value: 13591, slug: "duc-ly", status: "empty" },
          {
            label: "Trần Hưng Đạo",
            value: 13594,
            slug: "tran-hung-dao",
            status: "empty",
          },
          {
            label: "Vĩnh Trụ",
            value: 13597,
            slug: "vinh-tru",
            status: "empty",
          },
          {
            label: "Nhân Thịnh",
            value: 13600,
            slug: "nhan-thinh",
            status: "empty",
          },
          {
            label: "Nhân Khang",
            value: 13606,
            slug: "nhan-khang",
            status: "empty",
          },
          { label: "Nhân Mỹ", value: 13609, slug: "nhan-my", status: "empty" },
          {
            label: "Nhân Nghĩa",
            value: 13612,
            slug: "nhan-nghia",
            status: "empty",
          },
          {
            label: "Nhân Chính",
            value: 13615,
            slug: "nhan-chinh",
            status: "empty",
          },
          {
            label: "Nhân Bình",
            value: 13618,
            slug: "nhan-binh",
            status: "empty",
          },
          {
            label: "Phú Phúc",
            value: 13621,
            slug: "phu-phuc",
            status: "empty",
          },
          {
            label: "Xuân Khê",
            value: 13624,
            slug: "xuan-khe",
            status: "empty",
          },
          {
            label: "Tiến Thắng",
            value: 13627,
            slug: "tien-thang",
            status: "empty",
          },
          { label: "Hòa Hậu", value: 13630, slug: "hoa-hau", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Nam Định",
    value: 36,
    slug: "nam-dinh",
    status: "filled",
    district: [
      {
        label: "Thành phố Nam Định",
        value: 356,
        slug: "thanh-pho-nam-dinh",
        status: "filled",
        ward: [
          {
            label: "Vị Xuyên",
            value: 13636,
            slug: "vi-xuyen",
            status: "empty",
          },
          {
            label: "Trường Thi",
            value: 13657,
            slug: "truong-thi",
            status: "empty",
          },
          {
            label: "Trần Hưng Đạo",
            value: 13666,
            slug: "tran-hung-dao",
            status: "empty",
          },
          { label: "Cửa Bắc", value: 13669, slug: "cua-bac", status: "empty" },
          {
            label: "Năng Tĩnh",
            value: 13678,
            slug: "nang-tinh",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 13681,
            slug: "quang-trung",
            status: "empty",
          },
          { label: "Lộc Hạ", value: 13684, slug: "loc-ha", status: "empty" },
          {
            label: "Lộc Vượng",
            value: 13687,
            slug: "loc-vuong",
            status: "empty",
          },
          { label: "Cửa Nam", value: 13690, slug: "cua-nam", status: "filled" },
          { label: "Lộc Hòa", value: 13693, slug: "loc-hoa", status: "empty" },
          {
            label: "Nam Phong",
            value: 13696,
            slug: "nam-phong",
            status: "empty",
          },
          { label: "Mỹ Xá", value: 13699, slug: "my-xa", status: "empty" },
          { label: "Nam Vân", value: 13705, slug: "nam-van", status: "empty" },
          {
            label: "Hưng Lộc",
            value: 13708,
            slug: "hung-loc",
            status: "empty",
          },
          { label: "Mỹ Hà", value: 13711, slug: "my-ha", status: "empty" },
          {
            label: "Mỹ Thắng",
            value: 13717,
            slug: "my-thang",
            status: "empty",
          },
          {
            label: "Mỹ Trung",
            value: 13720,
            slug: "my-trung",
            status: "empty",
          },
          { label: "Mỹ Tân", value: 13723, slug: "my-tan", status: "empty" },
          { label: "Mỹ Phúc", value: 13726, slug: "my-phuc", status: "empty" },
          {
            label: "Mỹ Thuận",
            value: 13732,
            slug: "my-thuan",
            status: "empty",
          },
          { label: "Mỹ Lộc", value: 13735, slug: "my-loc", status: "empty" },
          { label: "Cửa Nam", value: 150000, slug: "cua-nam", status: "empty" },
        ],
      },
      {
        label: "Huyện Mỹ Lộc",
        value: 358,
        slug: "huyen-my-loc",
        status: "empty",
        ward: [
          { label: "Mỹ Lộc", value: 100200, slug: "my-loc", status: "empty" },
          {
            label: "Trung Đông",
            value: 100201,
            slug: "trung-dong",
            status: "empty",
          },
          {
            label: "Liên Bảo",
            value: 100202,
            slug: "lien-bao",
            status: "empty",
          },
          {
            label: "Thọ Xương",
            value: 100203,
            slug: "tho-xuong",
            status: "empty",
          },
          {
            label: "Hưng Lộc",
            value: 100204,
            slug: "hung-loc",
            status: "empty",
          },
          {
            label: "Duy Tiên",
            value: 100205,
            slug: "duy-tien",
            status: "empty",
          },
          { label: "Mỹ Tân", value: 100206, slug: "my-tan", status: "empty" },
          { label: "Mỹ Hưng", value: 100207, slug: "my-hung", status: "empty" },
          { label: "Mỹ Hà", value: 100208, slug: "my-ha", status: "empty" },
          { label: "Mỹ Phúc", value: 100209, slug: "my-phuc", status: "empty" },
          { label: "Mỹ Lộc", value: 100210, slug: "my-loc", status: "empty" },
        ],
      },
      {
        label: "Huyện Vụ Bản",
        value: 359,
        slug: "huyen-vu-ban",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Gôi",
            value: 13741,
            slug: "thi-tran-goi",
            status: "empty",
          },
          {
            label: "Hiển Khánh",
            value: 13747,
            slug: "hien-khanh",
            status: "empty",
          },
          {
            label: "Minh Tân",
            value: 13750,
            slug: "minh-tan",
            status: "empty",
          },
          {
            label: "Hợp Hưng",
            value: 13753,
            slug: "hop-hung",
            status: "empty",
          },
          { label: "Đại An", value: 13756, slug: "dai-an", status: "empty" },
          {
            label: "Cộng Hòa",
            value: 13762,
            slug: "cong-hoa",
            status: "empty",
          },
          {
            label: "Trung Thành",
            value: 13765,
            slug: "trung-thanh",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 13768,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Thành Lợi",
            value: 13777,
            slug: "thanh-loi",
            status: "empty",
          },
          {
            label: "Kim Thái",
            value: 13780,
            slug: "kim-thai",
            status: "empty",
          },
          {
            label: "Liên Minh",
            value: 13783,
            slug: "lien-minh",
            status: "empty",
          },
          {
            label: "Đại Thắng",
            value: 13786,
            slug: "dai-thang",
            status: "empty",
          },
          {
            label: "Tam Thanh",
            value: 13789,
            slug: "tam-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Hào",
            value: 13792,
            slug: "vinh-hao",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ý Yên",
        value: 360,
        slug: "huyen-y-yen",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Lâm",
            value: 13795,
            slug: "thi-tran-lam",
            status: "empty",
          },
          {
            label: "Trung Nghĩa",
            value: 13801,
            slug: "trung-nghia",
            status: "empty",
          },
          {
            label: "Tân Minh",
            value: 13807,
            slug: "tan-minh",
            status: "empty",
          },
          { label: "Yên Thọ", value: 13810, slug: "yen-tho", status: "empty" },
          {
            label: "Phú Hưng",
            value: 13819,
            slug: "phu-hung",
            status: "empty",
          },
          {
            label: "Yên Chính",
            value: 13822,
            slug: "yen-chinh",
            status: "empty",
          },
          {
            label: "Yên Bình",
            value: 13825,
            slug: "yen-binh",
            status: "empty",
          },
          { label: "Yên Mỹ", value: 13831, slug: "yen-my", status: "empty" },
          {
            label: "Yên Dương",
            value: 13834,
            slug: "yen-duong",
            status: "empty",
          },
          {
            label: "Yên Khánh",
            value: 13843,
            slug: "yen-khanh",
            status: "empty",
          },
          {
            label: "Yên Phong",
            value: 13846,
            slug: "yen-phong",
            status: "empty",
          },
          {
            label: "Yên Ninh",
            value: 13849,
            slug: "yen-ninh",
            status: "empty",
          },
          {
            label: "Yên Lương",
            value: 13852,
            slug: "yen-luong",
            status: "empty",
          },
          {
            label: "Yên Tiến",
            value: 13861,
            slug: "yen-tien",
            status: "empty",
          },
          {
            label: "Yên Thắng",
            value: 13864,
            slug: "yen-thang",
            status: "empty",
          },
          {
            label: "Yên Phúc",
            value: 13867,
            slug: "yen-phuc",
            status: "empty",
          },
          {
            label: "Yên Cường",
            value: 13870,
            slug: "yen-cuong",
            status: "empty",
          },
          { label: "Yên Lộc", value: 13873, slug: "yen-loc", status: "empty" },
          {
            label: "Hồng Quang",
            value: 13876,
            slug: "hong-quang",
            status: "empty",
          },
          {
            label: "Yên Đồng",
            value: 13879,
            slug: "yen-dong",
            status: "empty",
          },
          {
            label: "Yên Khang",
            value: 13882,
            slug: "yen-khang",
            status: "empty",
          },
          {
            label: "Yên Nhân",
            value: 13885,
            slug: "yen-nhan",
            status: "empty",
          },
          { label: "Yên Trị", value: 13888, slug: "yen-tri", status: "empty" },
        ],
      },
      {
        label: "Huyện Nghĩa Hưng",
        value: 361,
        slug: "huyen-nghia-hung",
        status: "empty",
        ward: [
          { label: "Liễu Đề", value: 13891, slug: "lieu-de", status: "empty" },
          {
            label: "Rạng Đông",
            value: 13894,
            slug: "rang-dong",
            status: "empty",
          },
          {
            label: "Đồng Thịnh",
            value: 13900,
            slug: "dong-thinh",
            status: "empty",
          },
          {
            label: "Nghĩa Thái",
            value: 13906,
            slug: "nghia-thai",
            status: "empty",
          },
          {
            label: "Hoàng Nam",
            value: 13909,
            slug: "hoang-nam",
            status: "empty",
          },
          {
            label: "Nghĩa Châu",
            value: 13912,
            slug: "nghia-chau",
            status: "empty",
          },
          {
            label: "Nghĩa Trung",
            value: 13915,
            slug: "nghia-trung",
            status: "empty",
          },
          {
            label: "Nghĩa Sơn",
            value: 13918,
            slug: "nghia-son",
            status: "empty",
          },
          {
            label: "Nghĩa Lạc",
            value: 13921,
            slug: "nghia-lac",
            status: "empty",
          },
          {
            label: "Nghĩa Hồng",
            value: 13924,
            slug: "nghia-hong",
            status: "empty",
          },
          {
            label: "Nghĩa Phong",
            value: 13927,
            slug: "nghia-phong",
            status: "empty",
          },
          {
            label: "Nghĩa Phú",
            value: 13930,
            slug: "nghia-phu",
            status: "empty",
          },
          {
            label: "Quỹ Nhất",
            value: 13939,
            slug: "quy-nhat",
            status: "empty",
          },
          {
            label: "Nghĩa Hùng",
            value: 13942,
            slug: "nghia-hung",
            status: "empty",
          },
          {
            label: "Nghĩa Lâm",
            value: 13945,
            slug: "nghia-lam",
            status: "empty",
          },
          {
            label: "Nghĩa Thành",
            value: 13948,
            slug: "nghia-thanh",
            status: "empty",
          },
          {
            label: "Phúc Thắng",
            value: 13951,
            slug: "phuc-thang",
            status: "empty",
          },
          {
            label: "Nghĩa Lợi",
            value: 13954,
            slug: "nghia-loi",
            status: "empty",
          },
          {
            label: "Nghĩa Hải",
            value: 13957,
            slug: "nghia-hai",
            status: "empty",
          },
          {
            label: "Nam Điền",
            value: 13963,
            slug: "nam-dien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nam Trực",
        value: 362,
        slug: "huyen-nam-truc",
        status: "empty",
        ward: [
          {
            label: "Nam Giang",
            value: 13966,
            slug: "nam-giang",
            status: "empty",
          },
          {
            label: "Nam Điền",
            value: 13972,
            slug: "nam-dien",
            status: "empty",
          },
          {
            label: "Nghĩa An",
            value: 13975,
            slug: "nghia-an",
            status: "empty",
          },
          {
            label: "Nam Thắng",
            value: 13978,
            slug: "nam-thang",
            status: "empty",
          },
          {
            label: "Hồng Quang",
            value: 13984,
            slug: "hong-quang",
            status: "empty",
          },
          {
            label: "Tân Thịnh",
            value: 13987,
            slug: "tan-thinh",
            status: "empty",
          },
          {
            label: "Nam Cường",
            value: 13990,
            slug: "nam-cuong",
            status: "empty",
          },
          {
            label: "Nam Hồng",
            value: 13993,
            slug: "nam-hong",
            status: "empty",
          },
          {
            label: "Nam Hùng",
            value: 13996,
            slug: "nam-hung",
            status: "empty",
          },
          { label: "Nam Hoa", value: 13999, slug: "nam-hoa", status: "empty" },
          {
            label: "Nam Dương",
            value: 14002,
            slug: "nam-duong",
            status: "empty",
          },
          {
            label: "Nam Thanh",
            value: 14005,
            slug: "nam-thanh",
            status: "empty",
          },
          { label: "Nam Lợi", value: 14008, slug: "nam-loi", status: "empty" },
          {
            label: "Bình Minh",
            value: 14011,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Đồng Sơn",
            value: 14014,
            slug: "dong-son",
            status: "empty",
          },
          {
            label: "Nam Tiến",
            value: 14017,
            slug: "nam-tien",
            status: "empty",
          },
          { label: "Nam Hải", value: 14020, slug: "nam-hai", status: "empty" },
          {
            label: "Nam Thái",
            value: 14023,
            slug: "nam-thai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Trực Ninh",
        value: 363,
        slug: "huyen-truc-ninh",
        status: "empty",
        ward: [
          { label: "Cổ Lễ", value: 14026, slug: "co-le", status: "empty" },
          {
            label: "Phương Định",
            value: 14029,
            slug: "phuong-dinh",
            status: "empty",
          },
          {
            label: "Trực Chính",
            value: 14032,
            slug: "truc-chinh",
            status: "empty",
          },
          {
            label: "Trung Đông",
            value: 14035,
            slug: "trung-dong",
            status: "empty",
          },
          {
            label: "Liêm Hải",
            value: 14038,
            slug: "liem-hai",
            status: "empty",
          },
          {
            label: "Trực Tuấn",
            value: 14041,
            slug: "truc-tuan",
            status: "empty",
          },
          {
            label: "Việt Hùng",
            value: 14044,
            slug: "viet-hung",
            status: "empty",
          },
          {
            label: "Trực Đạo",
            value: 14047,
            slug: "truc-dao",
            status: "empty",
          },
          {
            label: "Trực Hưng",
            value: 14050,
            slug: "truc-hung",
            status: "empty",
          },
          {
            label: "Trực Nội",
            value: 14053,
            slug: "truc-noi",
            status: "empty",
          },
          {
            label: "Cát Thành",
            value: 14056,
            slug: "cat-thanh",
            status: "empty",
          },
          {
            label: "Trực Thanh",
            value: 14059,
            slug: "truc-thanh",
            status: "empty",
          },
          {
            label: "Trực Khang",
            value: 14062,
            slug: "truc-khang",
            status: "empty",
          },
          {
            label: "Trực Thuận",
            value: 14065,
            slug: "truc-thuan",
            status: "empty",
          },
          { label: "Trực Mỹ", value: 14068, slug: "truc-my", status: "empty" },
          {
            label: "Trực Đại",
            value: 14071,
            slug: "truc-dai",
            status: "empty",
          },
          {
            label: "Trực Cường",
            value: 14074,
            slug: "truc-cuong",
            status: "empty",
          },
          {
            label: "Ninh Cường",
            value: 14077,
            slug: "ninh-cuong",
            status: "empty",
          },
          {
            label: "Trực Thái",
            value: 14080,
            slug: "truc-thai",
            status: "empty",
          },
          {
            label: "Trực Hùng",
            value: 14083,
            slug: "truc-hung",
            status: "empty",
          },
          {
            label: "Trực Thắng",
            value: 14086,
            slug: "truc-thang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Xuân Trường",
        value: 364,
        slug: "huyen-xuan-truong",
        status: "empty",
        ward: [
          {
            label: "Xuân Trường",
            value: 14089,
            slug: "xuan-truong",
            status: "empty",
          },
          {
            label: "Xuân Châu",
            value: 14092,
            slug: "xuan-chau",
            status: "empty",
          },
          {
            label: "Xuân Hồng",
            value: 14095,
            slug: "xuan-hong",
            status: "empty",
          },
          {
            label: "Xuân Thành",
            value: 14098,
            slug: "xuan-thanh",
            status: "empty",
          },
          {
            label: "Xuân Thượng",
            value: 14101,
            slug: "xuan-thuong",
            status: "empty",
          },
          {
            label: "Xuân Giang",
            value: 14104,
            slug: "xuan-giang",
            status: "empty",
          },
          {
            label: "Xuân Tân",
            value: 14110,
            slug: "xuan-tan",
            status: "empty",
          },
          {
            label: "Xuân Ngọc",
            value: 14116,
            slug: "xuan-ngoc",
            status: "empty",
          },
          { label: "Trà Lũ", value: 14122, slug: "tra-lu", status: "empty" },
          {
            label: "Thọ Nghiệp",
            value: 14125,
            slug: "tho-nghiep",
            status: "empty",
          },
          {
            label: "Xuân Phú",
            value: 14128,
            slug: "xuan-phu",
            status: "empty",
          },
          {
            label: "Xuân Vinh",
            value: 14134,
            slug: "xuan-vinh",
            status: "empty",
          },
          {
            label: "Xuân Ninh",
            value: 14143,
            slug: "xuan-ninh",
            status: "empty",
          },
          {
            label: "Xuân Phúc",
            value: 14146,
            slug: "xuan-phuc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Giao Thủy",
        value: 365,
        slug: "huyen-giao-thuy",
        status: "empty",
        ward: [
          {
            label: "Quất Lâm",
            value: 14152,
            slug: "quat-lam",
            status: "empty",
          },
          {
            label: "Giao Hương",
            value: 14155,
            slug: "giao-huong",
            status: "empty",
          },
          {
            label: "Hồng Thuận",
            value: 14158,
            slug: "hong-thuan",
            status: "empty",
          },
          {
            label: "Giao Thiện",
            value: 14161,
            slug: "giao-thien",
            status: "empty",
          },
          {
            label: "Giao Thanh",
            value: 14164,
            slug: "giao-thanh",
            status: "empty",
          },
          {
            label: "Giao Thủy",
            value: 14167,
            slug: "giao-thuy",
            status: "empty",
          },
          {
            label: "Bình Hòa",
            value: 14170,
            slug: "binh-hoa",
            status: "empty",
          },
          { label: "Giao Hà", value: 14176, slug: "giao-ha", status: "empty" },
          {
            label: "Giao Nhân",
            value: 14179,
            slug: "giao-nhan",
            status: "empty",
          },
          { label: "Giao An", value: 14182, slug: "giao-an", status: "empty" },
          {
            label: "Giao Lạc",
            value: 14185,
            slug: "giao-lac",
            status: "empty",
          },
          {
            label: "Giao Châu",
            value: 14188,
            slug: "giao-chau",
            status: "empty",
          },
          {
            label: "Giao Tân",
            value: 14191,
            slug: "giao-tan",
            status: "empty",
          },
          {
            label: "Giao Yến",
            value: 14194,
            slug: "giao-yen",
            status: "empty",
          },
          {
            label: "Giao Xuân",
            value: 14197,
            slug: "giao-xuan",
            status: "empty",
          },
          {
            label: "Giao Thịnh",
            value: 14200,
            slug: "giao-thinh",
            status: "empty",
          },
          {
            label: "Giao Hải",
            value: 14203,
            slug: "giao-hai",
            status: "empty",
          },
          {
            label: "Bạch Long",
            value: 14206,
            slug: "bach-long",
            status: "empty",
          },
          {
            label: "Giao Long",
            value: 14209,
            slug: "giao-long",
            status: "empty",
          },
          {
            label: "Giao Phong",
            value: 14212,
            slug: "giao-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hải Hậu",
        value: 366,
        slug: "huyen-hai-hau",
        status: "empty",
        ward: [
          {
            label: "Yên Định",
            value: 14215,
            slug: "yen-dinh",
            status: "empty",
          },
          {
            label: "Thị trấn Cồn",
            value: 14218,
            slug: "thi-tran-con",
            status: "empty",
          },
          {
            label: "Thịnh Long",
            value: 14221,
            slug: "thinh-long",
            status: "empty",
          },
          { label: "Hải Nam", value: 14224, slug: "hai-nam", status: "empty" },
          {
            label: "Hải Trung",
            value: 14227,
            slug: "hai-trung",
            status: "empty",
          },
          {
            label: "Hải Minh",
            value: 14233,
            slug: "hai-minh",
            status: "empty",
          },
          { label: "Hải Anh", value: 14236, slug: "hai-anh", status: "empty" },
          {
            label: "Hải Hưng",
            value: 14248,
            slug: "hai-hung",
            status: "empty",
          },
          {
            label: "Hải Long",
            value: 14254,
            slug: "hai-long",
            status: "empty",
          },
          {
            label: "Hải Đường",
            value: 14260,
            slug: "hai-duong",
            status: "empty",
          },
          { label: "Hải Lộc", value: 14263, slug: "hai-loc", status: "empty" },
          {
            label: "Hải Quang",
            value: 14266,
            slug: "hai-quang",
            status: "empty",
          },
          {
            label: "Hải Đông",
            value: 14269,
            slug: "hai-dong",
            status: "empty",
          },
          { label: "Hải Sơn", value: 14272, slug: "hai-son", status: "empty" },
          { label: "Hải Tân", value: 14275, slug: "hai-tan", status: "empty" },
          {
            label: "Hải Phong",
            value: 14281,
            slug: "hai-phong",
            status: "empty",
          },
          { label: "Hải An", value: 14284, slug: "hai-an", status: "empty" },
          { label: "Hải Tây", value: 14287, slug: "hai-tay", status: "empty" },
          { label: "Hải Phú", value: 14293, slug: "hai-phu", status: "empty" },
          {
            label: "Hải Giang",
            value: 14296,
            slug: "hai-giang",
            status: "empty",
          },
          {
            label: "Hải Ninh",
            value: 14302,
            slug: "hai-ninh",
            status: "empty",
          },
          {
            label: "Hải Xuân",
            value: 14308,
            slug: "hai-xuan",
            status: "empty",
          },
          {
            label: "Hải Châu",
            value: 14311,
            slug: "hai-chau",
            status: "empty",
          },
          { label: "Hải Hòa", value: 14317, slug: "hai-hoa", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Ninh Bình",
    value: 37,
    slug: "ninh-binh",
    status: "empty",
    district: [
      {
        label: "Thành phố Ninh Bình",
        value: 369,
        slug: "thanh-pho-ninh-binh",
        status: "empty",
        ward: [
          {
            label: "Bích Đào",
            value: 100300,
            slug: "bich-dao",
            status: "empty",
          },
          {
            label: "Đông Thành",
            value: 100301,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Nam Bình",
            value: 100302,
            slug: "nam-binh",
            status: "empty",
          },
          {
            label: "Nam Thành",
            value: 100303,
            slug: "nam-thanh",
            status: "empty",
          },
          {
            label: "Ninh Khánh",
            value: 100304,
            slug: "ninh-khanh",
            status: "empty",
          },
          {
            label: "Ninh Phong",
            value: 100305,
            slug: "ninh-phong",
            status: "empty",
          },
          {
            label: "Ninh Sơn",
            value: 100306,
            slug: "ninh-son",
            status: "empty",
          },
          {
            label: "Phúc Thành",
            value: 100307,
            slug: "phuc-thanh",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 100308,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Thanh Bình",
            value: 100309,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Vân Giang",
            value: 100310,
            slug: "van-giang",
            status: "empty",
          },
          {
            label: "Ninh Nhất",
            value: 100311,
            slug: "ninh-nhat",
            status: "empty",
          },
          {
            label: "Ninh Phúc",
            value: 100312,
            slug: "ninh-phuc",
            status: "empty",
          },
          {
            label: "Ninh Tiến",
            value: 100313,
            slug: "ninh-tien",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Tam Điệp",
        value: 370,
        slug: "thanh-pho-tam-diep",
        status: "empty",
        ward: [
          { label: "Bắc Sơn", value: 14362, slug: "bac-son", status: "empty" },
          {
            label: "Trung Sơn",
            value: 14365,
            slug: "trung-son",
            status: "empty",
          },
          { label: "Nam Sơn", value: 14368, slug: "nam-son", status: "empty" },
          { label: "Tây Sơn", value: 14369, slug: "tay-son", status: "empty" },
          { label: "Yên Sơn", value: 14371, slug: "yen-son", status: "empty" },
          {
            label: "Yên Bình",
            value: 14374,
            slug: "yen-binh",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 14375,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Quang Sơn",
            value: 14377,
            slug: "quang-son",
            status: "empty",
          },
          {
            label: "Đông Sơn",
            value: 14380,
            slug: "dong-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nho Quan",
        value: 372,
        slug: "huyen-nho-quan",
        status: "empty",
        ward: [
          {
            label: "Xích Thổ",
            value: 14386,
            slug: "xich-tho",
            status: "empty",
          },
          { label: "Gia Lâm", value: 14389, slug: "gia-lam", status: "empty" },
          { label: "Gia Sơn", value: 14392, slug: "gia-son", status: "empty" },
          {
            label: "Thạch Bình",
            value: 14395,
            slug: "thach-binh",
            status: "empty",
          },
          {
            label: "Gia Thủy",
            value: 14398,
            slug: "gia-thuy",
            status: "empty",
          },
          {
            label: "Gia Tường",
            value: 14401,
            slug: "gia-tuong",
            status: "empty",
          },
          {
            label: "Cúc Phương",
            value: 14404,
            slug: "cuc-phuong",
            status: "empty",
          },
          { label: "Phú Sơn", value: 14407, slug: "phu-son", status: "empty" },
          {
            label: "Đức Long",
            value: 14410,
            slug: "duc-long",
            status: "empty",
          },
          { label: "Lạc Vân", value: 14413, slug: "lac-van", status: "empty" },
          {
            label: "Đồng Phong",
            value: 14416,
            slug: "dong-phong",
            status: "empty",
          },
          {
            label: "Yên Quang",
            value: 14419,
            slug: "yen-quang",
            status: "empty",
          },
          {
            label: "Thượng Hòa",
            value: 14425,
            slug: "thuong-hoa",
            status: "empty",
          },
          {
            label: "Nho Quan",
            value: 14428,
            slug: "nho-quan",
            status: "empty",
          },
          {
            label: "Văn Phương",
            value: 14431,
            slug: "van-phuong",
            status: "empty",
          },
          {
            label: "Thanh Sơn",
            value: 14434,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Phúc Sơn",
            value: 14437,
            slug: "phuc-son",
            status: "empty",
          },
          { label: "Văn Phú", value: 14443, slug: "van-phu", status: "empty" },
          { label: "Phú Lộc", value: 14446, slug: "phu-loc", status: "empty" },
          { label: "Kỳ Phú", value: 14449, slug: "ky-phu", status: "empty" },
          {
            label: "Quỳnh Lưu",
            value: 14452,
            slug: "quynh-luu",
            status: "empty",
          },
          {
            label: "Phú Long",
            value: 14458,
            slug: "phu-long",
            status: "empty",
          },
          {
            label: "Quảng Lạc",
            value: 14461,
            slug: "quang-lac",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Gia Viễn",
        value: 373,
        slug: "huyen-gia-vien",
        status: "empty",
        ward: [
          {
            label: "Thịnh Vượng",
            value: 14464,
            slug: "thinh-vuong",
            status: "empty",
          },
          { label: "Gia Hòa", value: 14467, slug: "gia-hoa", status: "empty" },
          {
            label: "Gia Hưng",
            value: 14470,
            slug: "gia-hung",
            status: "empty",
          },
          {
            label: "Liên Sơn",
            value: 14473,
            slug: "lien-son",
            status: "empty",
          },
          {
            label: "Gia Thanh",
            value: 14476,
            slug: "gia-thanh",
            status: "empty",
          },
          { label: "Gia Vân", value: 14479, slug: "gia-van", status: "empty" },
          { label: "Gia Phú", value: 14482, slug: "gia-phu", status: "empty" },
          {
            label: "Gia Xuân",
            value: 14485,
            slug: "gia-xuan",
            status: "empty",
          },
          { label: "Gia Lập", value: 14488, slug: "gia-lap", status: "empty" },
          {
            label: "Gia Trấn",
            value: 14494,
            slug: "gia-tran",
            status: "empty",
          },
          {
            label: "Gia Phương",
            value: 14500,
            slug: "gia-phuong",
            status: "empty",
          },
          { label: "Gia Tân", value: 14503, slug: "gia-tan", status: "empty" },
          {
            label: "Tiến Thắng",
            value: 14506,
            slug: "tien-thang",
            status: "empty",
          },
          {
            label: "Gia Trung",
            value: 14509,
            slug: "gia-trung",
            status: "empty",
          },
          {
            label: "Gia Minh",
            value: 14512,
            slug: "gia-minh",
            status: "empty",
          },
          { label: "Gia Lạc", value: 14515, slug: "gia-lac", status: "empty" },
          {
            label: "Gia Sinh",
            value: 14521,
            slug: "gia-sinh",
            status: "empty",
          },
          {
            label: "Gia Phong",
            value: 14524,
            slug: "gia-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hoa Lư",
        value: 374,
        slug: "huyen-hoa-lu",
        status: "empty",
        ward: [
          {
            label: "Đông Thành",
            value: 14320,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 14323,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Vân Giang",
            value: 14329,
            slug: "van-giang",
            status: "empty",
          },
          {
            label: "Bích Đào",
            value: 14332,
            slug: "bich-dao",
            status: "empty",
          },
          {
            label: "Nam Bình",
            value: 14338,
            slug: "nam-binh",
            status: "empty",
          },
          {
            label: "Nam Thành",
            value: 14341,
            slug: "nam-thanh",
            status: "empty",
          },
          {
            label: "Ninh Khánh",
            value: 14344,
            slug: "ninh-khanh",
            status: "empty",
          },
          {
            label: "Ninh Nhất",
            value: 14347,
            slug: "ninh-nhat",
            status: "empty",
          },
          {
            label: "Ninh Tiến",
            value: 14350,
            slug: "ninh-tien",
            status: "empty",
          },
          {
            label: "Ninh Phúc",
            value: 14353,
            slug: "ninh-phuc",
            status: "empty",
          },
          {
            label: "Ninh Sơn",
            value: 14356,
            slug: "ninh-son",
            status: "empty",
          },
          {
            label: "Ninh Phong",
            value: 14359,
            slug: "ninh-phong",
            status: "empty",
          },
          { label: "Ninh Mỹ", value: 14527, slug: "ninh-my", status: "empty" },
          {
            label: "Ninh Giang",
            value: 14530,
            slug: "ninh-giang",
            status: "empty",
          },
          {
            label: "Trường Yên",
            value: 14533,
            slug: "truong-yen",
            status: "empty",
          },
          {
            label: "Ninh Khang",
            value: 14536,
            slug: "ninh-khang",
            status: "empty",
          },
          {
            label: "Ninh Hòa",
            value: 14542,
            slug: "ninh-hoa",
            status: "empty",
          },
          {
            label: "Ninh Hải",
            value: 14551,
            slug: "ninh-hai",
            status: "empty",
          },
          {
            label: "Ninh Vân",
            value: 14554,
            slug: "ninh-van",
            status: "empty",
          },
          { label: "Ninh An", value: 14557, slug: "ninh-an", status: "empty" },
        ],
      },
      {
        label: "Huyện Yên Khánh",
        value: 375,
        slug: "huyen-yen-khanh",
        status: "empty",
        ward: [
          {
            label: "Yên Ninh",
            value: 14560,
            slug: "yen-ninh",
            status: "empty",
          },
          {
            label: "Khánh Thiện",
            value: 14563,
            slug: "khanh-thien",
            status: "empty",
          },
          {
            label: "Khánh Phú",
            value: 14566,
            slug: "khanh-phu",
            status: "empty",
          },
          {
            label: "Khánh Hòa",
            value: 14569,
            slug: "khanh-hoa",
            status: "empty",
          },
          {
            label: "Khánh Lợi",
            value: 14572,
            slug: "khanh-loi",
            status: "empty",
          },
          {
            label: "Khánh An",
            value: 14575,
            slug: "khanh-an",
            status: "empty",
          },
          {
            label: "Khánh Cường",
            value: 14578,
            slug: "khanh-cuong",
            status: "empty",
          },
          {
            label: "Khánh Cư",
            value: 14581,
            slug: "khanh-cu",
            status: "empty",
          },
          {
            label: "Khánh Hải",
            value: 14587,
            slug: "khanh-hai",
            status: "empty",
          },
          {
            label: "Khánh Trung",
            value: 14590,
            slug: "khanh-trung",
            status: "empty",
          },
          {
            label: "Khánh Mậu",
            value: 14593,
            slug: "khanh-mau",
            status: "empty",
          },
          {
            label: "Khánh Vân",
            value: 14596,
            slug: "khanh-van",
            status: "empty",
          },
          {
            label: "Khánh Hội",
            value: 14599,
            slug: "khanh-hoi",
            status: "empty",
          },
          {
            label: "Khánh Công",
            value: 14602,
            slug: "khanh-cong",
            status: "empty",
          },
          {
            label: "Khánh Thành",
            value: 14608,
            slug: "khanh-thanh",
            status: "empty",
          },
          {
            label: "Khánh Nhạc",
            value: 14611,
            slug: "khanh-nhac",
            status: "empty",
          },
          {
            label: "Khánh Thủy",
            value: 14614,
            slug: "khanh-thuy",
            status: "empty",
          },
          {
            label: "Khánh Hồng",
            value: 14617,
            slug: "khanh-hong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Kim Sơn",
        value: 376,
        slug: "huyen-kim-son",
        status: "empty",
        ward: [
          {
            label: "Phát Diệm",
            value: 14620,
            slug: "phat-diem",
            status: "empty",
          },
          {
            label: "Bình Minh",
            value: 14623,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Hồi Ninh",
            value: 14629,
            slug: "hoi-ninh",
            status: "empty",
          },
          {
            label: "Xuân Chính",
            value: 14632,
            slug: "xuan-chinh",
            status: "empty",
          },
          {
            label: "Kim Định",
            value: 14635,
            slug: "kim-dinh",
            status: "empty",
          },
          { label: "Ân Hòa", value: 14638, slug: "an-hoa", status: "empty" },
          {
            label: "Hùng Tiến",
            value: 14641,
            slug: "hung-tien",
            status: "empty",
          },
          {
            label: "Quang Thiện",
            value: 14647,
            slug: "quang-thien",
            status: "empty",
          },
          { label: "Như Hòa", value: 14650, slug: "nhu-hoa", status: "empty" },
          {
            label: "Chất Bình",
            value: 14653,
            slug: "chat-binh",
            status: "empty",
          },
          {
            label: "Đồng Hướng",
            value: 14656,
            slug: "dong-huong",
            status: "empty",
          },
          {
            label: "Kim Chính",
            value: 14659,
            slug: "kim-chinh",
            status: "empty",
          },
          {
            label: "Thượng Kiệm",
            value: 14662,
            slug: "thuong-kiem",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 14668,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Yên Lộc", value: 14671, slug: "yen-loc", status: "empty" },
          {
            label: "Lai Thành",
            value: 14674,
            slug: "lai-thanh",
            status: "empty",
          },
          {
            label: "Định Hóa",
            value: 14677,
            slug: "dinh-hoa",
            status: "empty",
          },
          { label: "Văn Hải", value: 14680, slug: "van-hai", status: "empty" },
          { label: "Kim Tân", value: 14683, slug: "kim-tan", status: "empty" },
          { label: "Kim Mỹ", value: 14686, slug: "kim-my", status: "empty" },
          {
            label: "Cồn Thoi",
            value: 14689,
            slug: "con-thoi",
            status: "empty",
          },
          {
            label: "Kim Trung",
            value: 14695,
            slug: "kim-trung",
            status: "empty",
          },
          {
            label: "Kim Đông",
            value: 14698,
            slug: "kim-dong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Yên Mô",
        value: 377,
        slug: "huyen-yen-mo",
        status: "empty",
        ward: [
          {
            label: "Yên Thịnh",
            value: 14701,
            slug: "yen-thinh",
            status: "empty",
          },
          {
            label: "Khánh Thượng",
            value: 14704,
            slug: "khanh-thuong",
            status: "empty",
          },
          {
            label: "Khánh Dương",
            value: 14707,
            slug: "khanh-duong",
            status: "empty",
          },
          {
            label: "Yên Phong",
            value: 14719,
            slug: "yen-phong",
            status: "empty",
          },
          { label: "Yên Hòa", value: 14722, slug: "yen-hoa", status: "empty" },
          {
            label: "Yên Thắng",
            value: 14725,
            slug: "yen-thang",
            status: "empty",
          },
          { label: "Yên Từ", value: 14728, slug: "yen-tu", status: "empty" },
          {
            label: "Yên Thành",
            value: 14734,
            slug: "yen-thanh",
            status: "empty",
          },
          {
            label: "Yên Nhân",
            value: 14737,
            slug: "yen-nhan",
            status: "empty",
          },
          { label: "Yên Mỹ", value: 14740, slug: "yen-my", status: "empty" },
          { label: "Yên Mạc", value: 14743, slug: "yen-mac", status: "empty" },
          {
            label: "Yên Đồng",
            value: 14746,
            slug: "yen-dong",
            status: "empty",
          },
          {
            label: "Yên Thái",
            value: 14749,
            slug: "yen-thai",
            status: "empty",
          },
          { label: "Yên Lâm", value: 14752, slug: "yen-lam", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Thanh Hóa",
    value: 38,
    slug: "thanh-hoa",
    status: "filled",
    district: [
      {
        label: "Thành phố Thanh Hóa",
        value: 380,
        slug: "thanh-pho-thanh-hoa",
        status: "filled",
        ward: [
          {
            label: "Hàm Rồng",
            value: 14755,
            slug: "ham-rong",
            status: "empty",
          },
          {
            label: "Đông Thọ",
            value: 14758,
            slug: "dong-tho",
            status: "filled",
          },
          {
            label: "Nam Ngạn",
            value: 14761,
            slug: "nam-ngan",
            status: "empty",
          },
          {
            label: "Trường Thi",
            value: 14764,
            slug: "truong-thi",
            status: "empty",
          },
          {
            label: "Điện Biên",
            value: 14767,
            slug: "dien-bien",
            status: "empty",
          },
          { label: "Phú Sơn", value: 14770, slug: "phu-son", status: "empty" },
          { label: "Lam Sơn", value: 14773, slug: "lam-son", status: "empty" },
          { label: "Ba Đình", value: 14776, slug: "ba-dinh", status: "filled" },
          {
            label: "Ngọc Trạo",
            value: 14779,
            slug: "ngoc-trao",
            status: "empty",
          },
          { label: "Đông Vệ", value: 14782, slug: "dong-ve", status: "empty" },
          {
            label: "Đông Sơn",
            value: 14785,
            slug: "dong-son",
            status: "empty",
          },
          {
            label: "Đông Cương",
            value: 14791,
            slug: "dong-cuong",
            status: "empty",
          },
          {
            label: "Đông Hương",
            value: 14794,
            slug: "dong-huong",
            status: "empty",
          },
          {
            label: "Đông Hải",
            value: 14797,
            slug: "dong-hai",
            status: "empty",
          },
          {
            label: "Quảng Hưng",
            value: 14800,
            slug: "quang-hung",
            status: "empty",
          },
          {
            label: "Quảng Thắng",
            value: 14803,
            slug: "quang-thang",
            status: "empty",
          },
          {
            label: "Quảng Thành",
            value: 14806,
            slug: "quang-thanh",
            status: "empty",
          },
          {
            label: "Thiệu Vân",
            value: 15850,
            slug: "thieu-van",
            status: "empty",
          },
          {
            label: "Thiệu Khánh",
            value: 15856,
            slug: "thieu-khanh",
            status: "empty",
          },
          {
            label: "Thiệu Dương",
            value: 15859,
            slug: "thieu-duong",
            status: "empty",
          },
          {
            label: "Tào Xuyên",
            value: 15913,
            slug: "tao-xuyen",
            status: "empty",
          },
          {
            label: "Long Anh",
            value: 15922,
            slug: "long-anh",
            status: "empty",
          },
          {
            label: "Hoằng Quang",
            value: 15925,
            slug: "hoang-quang",
            status: "empty",
          },
          {
            label: "Hoằng Đại",
            value: 15970,
            slug: "hoang-dai",
            status: "empty",
          },
          {
            label: "Rừng Thông",
            value: 16378,
            slug: "rung-thong",
            status: "empty",
          },
          {
            label: "Đông Hoàng",
            value: 16381,
            slug: "dong-hoang",
            status: "empty",
          },
          {
            label: "Đông Ninh",
            value: 16384,
            slug: "dong-ninh",
            status: "empty",
          },
          {
            label: "Đông Khê",
            value: 16387,
            slug: "dong-khe",
            status: "empty",
          },
          {
            label: "Đông Hòa",
            value: 16390,
            slug: "dong-hoa",
            status: "empty",
          },
          {
            label: "Đông Yên",
            value: 16393,
            slug: "dong-yen",
            status: "empty",
          },
          {
            label: "Đông Lĩnh",
            value: 16396,
            slug: "dong-linh",
            status: "empty",
          },
          {
            label: "Đông Minh",
            value: 16399,
            slug: "dong-minh",
            status: "empty",
          },
          {
            label: "Đông Thanh",
            value: 16402,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Đông Tiến",
            value: 16405,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Đông Khê",
            value: 16408,
            slug: "dong-khe",
            status: "empty",
          },
          {
            label: "Đông Xuân",
            value: 16411,
            slug: "dong-xuan",
            status: "empty",
          },
          {
            label: "Đông Thịnh",
            value: 16414,
            slug: "dong-thinh",
            status: "empty",
          },
          {
            label: "Đông Văn",
            value: 16417,
            slug: "dong-van",
            status: "empty",
          },
          {
            label: "Đông Phú",
            value: 16420,
            slug: "dong-phu",
            status: "empty",
          },
          {
            label: "Đông Nam",
            value: 16423,
            slug: "dong-nam",
            status: "empty",
          },
          {
            label: "Đông Quang",
            value: 16426,
            slug: "dong-quang",
            status: "empty",
          },
          {
            label: "Đông Vinh",
            value: 16429,
            slug: "dong-vinh",
            status: "empty",
          },
          {
            label: "Đông Tân",
            value: 16432,
            slug: "dong-tan",
            status: "empty",
          },
          { label: "An Hưng", value: 16435, slug: "an-hung", status: "empty" },
          {
            label: "Quảng Thịnh",
            value: 16441,
            slug: "quang-thinh",
            status: "empty",
          },
          {
            label: "Quảng Đông",
            value: 16459,
            slug: "quang-dong",
            status: "empty",
          },
          {
            label: "Quảng Cát",
            value: 16507,
            slug: "quang-cat",
            status: "empty",
          },
          {
            label: "Quảng Phú",
            value: 16522,
            slug: "quang-phu",
            status: "empty",
          },
          {
            label: "Quảng Tâm",
            value: 16525,
            slug: "quang-tam",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Bỉm Sơn",
        value: 381,
        slug: "thi-xa-bim-son",
        status: "empty",
        ward: [
          { label: "Bắc Sơn", value: 14809, slug: "bac-son", status: "empty" },
          { label: "Ba Đình", value: 14812, slug: "ba-dinh", status: "empty" },
          { label: "Lam Sơn", value: 14815, slug: "lam-son", status: "empty" },
          {
            label: "Ngọc Trạo",
            value: 14818,
            slug: "ngoc-trao",
            status: "empty",
          },
          {
            label: "Đông Sơn",
            value: 14821,
            slug: "dong-son",
            status: "empty",
          },
          { label: "Phú Sơn", value: 14823, slug: "phu-son", status: "empty" },
          {
            label: "Quang Trung",
            value: 14824,
            slug: "quang-trung",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Sầm Sơn",
        value: 382,
        slug: "thanh-pho-sam-son",
        status: "empty",
        ward: [
          {
            label: "Trung Sơn",
            value: 14830,
            slug: "trung-son",
            status: "empty",
          },
          { label: "Bắc Sơn", value: 14833, slug: "bac-son", status: "empty" },
          {
            label: "Trường Sơn",
            value: 14836,
            slug: "truong-son",
            status: "empty",
          },
          {
            label: "Quảng Cư",
            value: 14839,
            slug: "quang-cu",
            status: "empty",
          },
          {
            label: "Quảng Tiến",
            value: 14842,
            slug: "quang-tien",
            status: "empty",
          },
          {
            label: "Quảng Minh",
            value: 16513,
            slug: "quang-minh",
            status: "empty",
          },
          {
            label: "Đại Hùng",
            value: 16516,
            slug: "dai-hung",
            status: "empty",
          },
          {
            label: "Quảng Thọ",
            value: 16528,
            slug: "quang-tho",
            status: "empty",
          },
          {
            label: "Quảng Châu",
            value: 16531,
            slug: "quang-chau",
            status: "empty",
          },
          {
            label: "Quảng Vinh",
            value: 16534,
            slug: "quang-vinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Mường Lát",
        value: 384,
        slug: "huyen-muong-lat",
        status: "empty",
        ward: [
          {
            label: "Mường Lát",
            value: 14845,
            slug: "muong-lat",
            status: "empty",
          },
          {
            label: "Tam Chung",
            value: 14848,
            slug: "tam-chung",
            status: "empty",
          },
          {
            label: "Mường Lý",
            value: 14854,
            slug: "muong-ly",
            status: "empty",
          },
          {
            label: "Trung Lý",
            value: 14857,
            slug: "trung-ly",
            status: "empty",
          },
          {
            label: "Quang Chiểu",
            value: 14860,
            slug: "quang-chieu",
            status: "empty",
          },
          { label: "Pù Nhi", value: 14863, slug: "pu-nhi", status: "empty" },
          { label: "Nhi Sơn", value: 14864, slug: "nhi-son", status: "empty" },
          {
            label: "Mường Chanh",
            value: 14866,
            slug: "muong-chanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Quan Hóa",
        value: 385,
        slug: "huyen-quan-hoa",
        status: "empty",
        ward: [
          {
            label: "Hồi Xuân",
            value: 14869,
            slug: "hoi-xuan",
            status: "empty",
          },
          {
            label: "Thành Sơn",
            value: 14872,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Trung Sơn",
            value: 14875,
            slug: "trung-son",
            status: "empty",
          },
          {
            label: "Phú Thanh",
            value: 14878,
            slug: "phu-thanh",
            status: "empty",
          },
          {
            label: "Trung Thành",
            value: 14881,
            slug: "trung-thanh",
            status: "empty",
          },
          { label: "Phú Lệ", value: 14884, slug: "phu-le", status: "empty" },
          { label: "Phú Sơn", value: 14887, slug: "phu-son", status: "empty" },
          {
            label: "Phú Xuân",
            value: 14890,
            slug: "phu-xuan",
            status: "empty",
          },
          {
            label: "Hiền Chung",
            value: 14896,
            slug: "hien-chung",
            status: "empty",
          },
          {
            label: "Hiền Kiệt",
            value: 14899,
            slug: "hien-kiet",
            status: "empty",
          },
          {
            label: "Nam Tiến",
            value: 14902,
            slug: "nam-tien",
            status: "empty",
          },
          {
            label: "Thiên Phủ",
            value: 14908,
            slug: "thien-phu",
            status: "empty",
          },
          {
            label: "Phú Nghiêm",
            value: 14911,
            slug: "phu-nghiem",
            status: "empty",
          },
          {
            label: "Nam Xuân",
            value: 14914,
            slug: "nam-xuan",
            status: "empty",
          },
          {
            label: "Nam Động",
            value: 14917,
            slug: "nam-dong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bá Thước",
        value: 386,
        slug: "huyen-ba-thuoc",
        status: "empty",
        ward: [
          {
            label: "Cành Nàng",
            value: 14923,
            slug: "canh-nang",
            status: "empty",
          },
          {
            label: "Điền Thượng",
            value: 14926,
            slug: "dien-thuong",
            status: "empty",
          },
          { label: "Điền Hạ", value: 14929, slug: "dien-ha", status: "empty" },
          {
            label: "Điền Quang",
            value: 14932,
            slug: "dien-quang",
            status: "empty",
          },
          {
            label: "Điền Trung",
            value: 14935,
            slug: "dien-trung",
            status: "empty",
          },
          {
            label: "Thành Sơn",
            value: 14938,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Lương Ngoại",
            value: 14941,
            slug: "luong-ngoai",
            status: "empty",
          },
          {
            label: "Ái Thượng",
            value: 14944,
            slug: "ai-thuong",
            status: "empty",
          },
          {
            label: "Lương Nội",
            value: 14947,
            slug: "luong-noi",
            status: "empty",
          },
          { label: "Điền Lư", value: 14950, slug: "dien-lu", status: "empty" },
          {
            label: "Lương Trung",
            value: 14953,
            slug: "luong-trung",
            status: "empty",
          },
          {
            label: "Lũng Niêm",
            value: 14956,
            slug: "lung-niem",
            status: "empty",
          },
          {
            label: "Lũng Cao",
            value: 14959,
            slug: "lung-cao",
            status: "empty",
          },
          {
            label: "Hạ Trung",
            value: 14962,
            slug: "ha-trung",
            status: "empty",
          },
          { label: "Cổ Lũng", value: 14965, slug: "co-lung", status: "empty" },
          {
            label: "Thành Lâm",
            value: 14968,
            slug: "thanh-lam",
            status: "empty",
          },
          {
            label: "Ban Công",
            value: 14971,
            slug: "ban-cong",
            status: "empty",
          },
          { label: "Kỳ Tân", value: 14974, slug: "ky-tan", status: "empty" },
          { label: "Văn Nho", value: 14977, slug: "van-nho", status: "empty" },
          {
            label: "Thiết Ống",
            value: 14980,
            slug: "thiet-ong",
            status: "empty",
          },
          {
            label: "Thiết Kế",
            value: 14986,
            slug: "thiet-ke",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Quan Sơn",
        value: 387,
        slug: "huyen-quan-son",
        status: "empty",
        ward: [
          {
            label: "Trung Xuân",
            value: 14995,
            slug: "trung-xuan",
            status: "empty",
          },
          {
            label: "Trung Thượng",
            value: 14998,
            slug: "trung-thuong",
            status: "empty",
          },
          {
            label: "Trung Tiến",
            value: 14999,
            slug: "trung-tien",
            status: "empty",
          },
          {
            label: "Trung Hạ",
            value: 15001,
            slug: "trung-ha",
            status: "empty",
          },
          { label: "Sơn Hà", value: 15004, slug: "son-ha", status: "empty" },
          {
            label: "Tam Thanh",
            value: 15007,
            slug: "tam-thanh",
            status: "empty",
          },
          {
            label: "Sơn Thủy",
            value: 15010,
            slug: "son-thuy",
            status: "empty",
          },
          { label: "Na Mèo", value: 15013, slug: "na-meo", status: "empty" },
          { label: "Sơn Lư", value: 15016, slug: "son-lu", status: "empty" },
          { label: "Tam Lư", value: 15019, slug: "tam-lu", status: "empty" },
          {
            label: "Sơn Điện",
            value: 15022,
            slug: "son-dien",
            status: "empty",
          },
          {
            label: "Mường Mìn",
            value: 15025,
            slug: "muong-min",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lang Chánh",
        value: 388,
        slug: "huyen-lang-chanh",
        status: "empty",
        ward: [
          {
            label: "Yên Khương",
            value: 15031,
            slug: "yen-khuong",
            status: "empty",
          },
          {
            label: "Yên Thắng",
            value: 15034,
            slug: "yen-thang",
            status: "empty",
          },
          {
            label: "Trí Nang",
            value: 15037,
            slug: "tri-nang",
            status: "empty",
          },
          { label: "Giao An", value: 15040, slug: "giao-an", status: "empty" },
          {
            label: "Giao Thiện",
            value: 15043,
            slug: "giao-thien",
            status: "empty",
          },
          {
            label: "Tân Phúc",
            value: 15046,
            slug: "tan-phuc",
            status: "empty",
          },
          { label: "Tam Văn", value: 15049, slug: "tam-van", status: "empty" },
          { label: "Lâm Phú", value: 15052, slug: "lam-phu", status: "empty" },
          {
            label: "Lang Chánh",
            value: 15055,
            slug: "lang-chanh",
            status: "empty",
          },
          {
            label: "Đồng Lương",
            value: 15058,
            slug: "dong-luong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ngọc Lặc",
        value: 389,
        slug: "huyen-ngoc-lac",
        status: "empty",
        ward: [
          {
            label: "Ngọc Lặc",
            value: 15061,
            slug: "ngoc-lac",
            status: "empty",
          },
          { label: "Lam Sơn", value: 15064, slug: "lam-son", status: "empty" },
          { label: "Mỹ Tân", value: 15067, slug: "my-tan", status: "empty" },
          {
            label: "Thúy Sơn",
            value: 15070,
            slug: "thuy-son",
            status: "empty",
          },
          {
            label: "Thạch Lập",
            value: 15073,
            slug: "thach-lap",
            status: "empty",
          },
          { label: "Vân Âm", value: 15076, slug: "van-am", status: "empty" },
          {
            label: "Cao Ngọc",
            value: 15079,
            slug: "cao-ngoc",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 15085,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Đồng Thịnh",
            value: 15088,
            slug: "dong-thinh",
            status: "empty",
          },
          {
            label: "Ngọc Liên",
            value: 15091,
            slug: "ngoc-lien",
            status: "empty",
          },
          {
            label: "Ngọc Sơn",
            value: 15094,
            slug: "ngoc-son",
            status: "empty",
          },
          {
            label: "Lộc Thịnh",
            value: 15097,
            slug: "loc-thinh",
            status: "empty",
          },
          {
            label: "Cao Thịnh",
            value: 15100,
            slug: "cao-thinh",
            status: "empty",
          },
          {
            label: "Ngọc Trung",
            value: 15103,
            slug: "ngoc-trung",
            status: "empty",
          },
          {
            label: "Phùng Giáo",
            value: 15106,
            slug: "phung-giao",
            status: "empty",
          },
          {
            label: "Phùng Minh",
            value: 15109,
            slug: "phung-minh",
            status: "empty",
          },
          {
            label: "Phúc Thịnh",
            value: 15112,
            slug: "phuc-thinh",
            status: "empty",
          },
          {
            label: "Nguyệt Ấn",
            value: 15115,
            slug: "nguyet-an",
            status: "empty",
          },
          {
            label: "Kiên Thọ",
            value: 15118,
            slug: "kien-tho",
            status: "empty",
          },
          {
            label: "Minh Tiến",
            value: 15121,
            slug: "minh-tien",
            status: "empty",
          },
          {
            label: "Minh Sơn",
            value: 15124,
            slug: "minh-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cẩm Thủy",
        value: 390,
        slug: "huyen-cam-thuy",
        status: "empty",
        ward: [
          {
            label: "Phong Sơn",
            value: 15127,
            slug: "phong-son",
            status: "empty",
          },
          {
            label: "Cẩm Thành",
            value: 15133,
            slug: "cam-thanh",
            status: "empty",
          },
          { label: "Cẩm Quý", value: 15136, slug: "cam-quy", status: "empty" },
          {
            label: "Cẩm Lương",
            value: 15139,
            slug: "cam-luong",
            status: "empty",
          },
          {
            label: "Cẩm Thạch",
            value: 15142,
            slug: "cam-thach",
            status: "empty",
          },
          {
            label: "Cẩm Liên",
            value: 15145,
            slug: "cam-lien",
            status: "empty",
          },
          {
            label: "Cẩm Giang",
            value: 15148,
            slug: "cam-giang",
            status: "empty",
          },
          {
            label: "Cẩm Bình",
            value: 15151,
            slug: "cam-binh",
            status: "empty",
          },
          { label: "Cẩm Tú", value: 15154, slug: "cam-tu", status: "empty" },
          {
            label: "Cẩm Châu",
            value: 15160,
            slug: "cam-chau",
            status: "empty",
          },
          { label: "Cẩm Tâm", value: 15163, slug: "cam-tam", status: "empty" },
          {
            label: "Cẩm Ngọc",
            value: 15169,
            slug: "cam-ngoc",
            status: "empty",
          },
          {
            label: "Cẩm Long",
            value: 15172,
            slug: "cam-long",
            status: "empty",
          },
          { label: "Cẩm Yên", value: 15175, slug: "cam-yen", status: "empty" },
          { label: "Cẩm Tân", value: 15178, slug: "cam-tan", status: "empty" },
          { label: "Cẩm Phú", value: 15181, slug: "cam-phu", status: "empty" },
          { label: "Cẩm Vân", value: 15184, slug: "cam-van", status: "empty" },
        ],
      },
      {
        label: "Huyện Thạch Thành",
        value: 391,
        slug: "huyen-thach-thanh",
        status: "empty",
        ward: [
          { label: "Kim Tân", value: 15187, slug: "kim-tan", status: "empty" },
          { label: "Vân Du", value: 15190, slug: "van-du", status: "empty" },
          {
            label: "Thạch Lâm",
            value: 15196,
            slug: "thach-lam",
            status: "empty",
          },
          {
            label: "Thạch Quảng",
            value: 15199,
            slug: "thach-quang",
            status: "empty",
          },
          {
            label: "Thạch Tượng",
            value: 15202,
            slug: "thach-tuong",
            status: "empty",
          },
          {
            label: "Thạch Cẩm",
            value: 15205,
            slug: "thach-cam",
            status: "empty",
          },
          {
            label: "Thạch Sơn",
            value: 15208,
            slug: "thach-son",
            status: "empty",
          },
          {
            label: "Thạch Bình",
            value: 15211,
            slug: "thach-binh",
            status: "empty",
          },
          {
            label: "Thạch Định",
            value: 15214,
            slug: "thach-dinh",
            status: "empty",
          },
          {
            label: "Thạch Long",
            value: 15220,
            slug: "thach-long",
            status: "empty",
          },
          {
            label: "Thành Mỹ",
            value: 15223,
            slug: "thanh-my",
            status: "empty",
          },
          {
            label: "Thành Yên",
            value: 15226,
            slug: "thanh-yen",
            status: "empty",
          },
          {
            label: "Thành Vinh",
            value: 15229,
            slug: "thanh-vinh",
            status: "empty",
          },
          {
            label: "Thành Minh",
            value: 15232,
            slug: "thanh-minh",
            status: "empty",
          },
          {
            label: "Thành Công",
            value: 15235,
            slug: "thanh-cong",
            status: "empty",
          },
          {
            label: "Thành Tân",
            value: 15238,
            slug: "thanh-tan",
            status: "empty",
          },
          {
            label: "Thành Trực",
            value: 15241,
            slug: "thanh-truc",
            status: "empty",
          },
          {
            label: "Thành Tâm",
            value: 15247,
            slug: "thanh-tam",
            status: "empty",
          },
          {
            label: "Thành An",
            value: 15250,
            slug: "thanh-an",
            status: "empty",
          },
          {
            label: "Thành Thọ",
            value: 15253,
            slug: "thanh-tho",
            status: "empty",
          },
          {
            label: "Thành Tiến",
            value: 15256,
            slug: "thanh-tien",
            status: "empty",
          },
          {
            label: "Thành Long",
            value: 15259,
            slug: "thanh-long",
            status: "empty",
          },
          {
            label: "Thành Hưng",
            value: 15265,
            slug: "thanh-hung",
            status: "empty",
          },
          {
            label: "Ngọc Trạo",
            value: 15268,
            slug: "ngoc-trao",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hà Trung",
        value: 392,
        slug: "huyen-ha-trung",
        status: "empty",
        ward: [
          {
            label: "Hà Trung",
            value: 15271,
            slug: "ha-trung",
            status: "empty",
          },
          { label: "Hà Long", value: 15274, slug: "ha-long", status: "empty" },
          { label: "Hà Vinh", value: 15277, slug: "ha-vinh", status: "empty" },
          { label: "Hà Bắc", value: 15280, slug: "ha-bac", status: "empty" },
          {
            label: "Hoạt Giang",
            value: 15283,
            slug: "hoat-giang",
            status: "empty",
          },
          {
            label: "Yên Dương",
            value: 15286,
            slug: "yen-duong",
            status: "empty",
          },
          {
            label: "Hà Giang",
            value: 15292,
            slug: "ha-giang",
            status: "empty",
          },
          {
            label: "Lĩnh Toại",
            value: 15298,
            slug: "linh-toai",
            status: "empty",
          },
          { label: "Hà Ngọc", value: 15304, slug: "ha-ngoc", status: "empty" },
          { label: "Yến Sơn", value: 15307, slug: "yen-son", status: "empty" },
          { label: "Hà Sơn", value: 15313, slug: "ha-son", status: "empty" },
          { label: "Hà Lĩnh", value: 15316, slug: "ha-linh", status: "empty" },
          { label: "Hà Đông", value: 15319, slug: "ha-dong", status: "empty" },
          { label: "Hà Tân", value: 15322, slug: "ha-tan", status: "empty" },
          { label: "Hà Tiến", value: 15325, slug: "ha-tien", status: "empty" },
          { label: "Hà Bình", value: 15328, slug: "ha-binh", status: "empty" },
          {
            label: "Thái Lai",
            value: 15331,
            slug: "thai-lai",
            status: "empty",
          },
          { label: "Hà Châu", value: 15334, slug: "ha-chau", status: "empty" },
          { label: "Hà Hải", value: 15343, slug: "ha-hai", status: "empty" },
        ],
      },
      {
        label: "Huyện Vĩnh Lộc",
        value: 393,
        slug: "huyen-vinh-loc",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Lộc",
            value: 15349,
            slug: "vinh-loc",
            status: "empty",
          },
          {
            label: "Vĩnh Quang",
            value: 15352,
            slug: "vinh-quang",
            status: "empty",
          },
          {
            label: "Vĩnh Yên",
            value: 15355,
            slug: "vinh-yen",
            status: "empty",
          },
          {
            label: "Vĩnh Tiến",
            value: 15358,
            slug: "vinh-tien",
            status: "empty",
          },
          {
            label: "Vĩnh Long",
            value: 15361,
            slug: "vinh-long",
            status: "empty",
          },
          {
            label: "Vĩnh Phúc",
            value: 15364,
            slug: "vinh-phuc",
            status: "empty",
          },
          {
            label: "Vĩnh Hưng",
            value: 15367,
            slug: "vinh-hung",
            status: "empty",
          },
          {
            label: "Vĩnh Hòa",
            value: 15376,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Vĩnh Hùng",
            value: 15379,
            slug: "vinh-hung",
            status: "empty",
          },
          {
            label: "Minh Tân",
            value: 15382,
            slug: "minh-tan",
            status: "empty",
          },
          {
            label: "Ninh Khang",
            value: 15385,
            slug: "ninh-khang",
            status: "empty",
          },
          {
            label: "Vĩnh Thịnh",
            value: 15388,
            slug: "vinh-thinh",
            status: "empty",
          },
          { label: "Vĩnh An", value: 15391, slug: "vinh-an", status: "empty" },
        ],
      },
      {
        label: "Huyện Yên Định",
        value: 394,
        slug: "huyen-yen-dinh",
        status: "empty",
        ward: [
          {
            label: "Thống Nhất",
            value: 15397,
            slug: "thong-nhat",
            status: "empty",
          },
          { label: "Yên Lâm", value: 15403, slug: "yen-lam", status: "empty" },
          { label: "Yên Tâm", value: 15406, slug: "yen-tam", status: "empty" },
          { label: "Yên Phú", value: 15409, slug: "yen-phu", status: "empty" },
          { label: "Quý Lộc", value: 15412, slug: "quy-loc", status: "empty" },
          { label: "Yên Thọ", value: 15415, slug: "yen-tho", status: "empty" },
          {
            label: "Yên Trung",
            value: 15418,
            slug: "yen-trung",
            status: "empty",
          },
          {
            label: "Yên Trường",
            value: 15421,
            slug: "yen-truong",
            status: "empty",
          },
          {
            label: "Yên Phong",
            value: 15427,
            slug: "yen-phong",
            status: "empty",
          },
          {
            label: "Yên Thái",
            value: 15430,
            slug: "yen-thai",
            status: "empty",
          },
          {
            label: "Yên Hùng",
            value: 15433,
            slug: "yen-hung",
            status: "empty",
          },
          {
            label: "Yên Thịnh",
            value: 15436,
            slug: "yen-thinh",
            status: "empty",
          },
          {
            label: "Yên Ninh",
            value: 15442,
            slug: "yen-ninh",
            status: "empty",
          },
          {
            label: "Định Tăng",
            value: 15445,
            slug: "dinh-tang",
            status: "empty",
          },
          {
            label: "Định Hòa",
            value: 15448,
            slug: "dinh-hoa",
            status: "empty",
          },
          {
            label: "Định Thành",
            value: 15451,
            slug: "dinh-thanh",
            status: "empty",
          },
          {
            label: "Định Công",
            value: 15454,
            slug: "dinh-cong",
            status: "empty",
          },
          {
            label: "Định Tân",
            value: 15457,
            slug: "dinh-tan",
            status: "empty",
          },
          {
            label: "Định Tiến",
            value: 15460,
            slug: "dinh-tien",
            status: "empty",
          },
          {
            label: "Định Long",
            value: 15463,
            slug: "dinh-long",
            status: "empty",
          },
          {
            label: "Định Liên",
            value: 15466,
            slug: "dinh-lien",
            status: "empty",
          },
          {
            label: "Quán Lào",
            value: 15469,
            slug: "quan-lao",
            status: "empty",
          },
          {
            label: "Định Hưng",
            value: 15472,
            slug: "dinh-hung",
            status: "empty",
          },
          {
            label: "Định Hải",
            value: 15475,
            slug: "dinh-hai",
            status: "empty",
          },
          {
            label: "Định Bình",
            value: 15478,
            slug: "dinh-binh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thọ Xuân",
        value: 395,
        slug: "huyen-tho-xuan",
        status: "empty",
        ward: [
          {
            label: "Xuân Hồng",
            value: 15493,
            slug: "xuan-hong",
            status: "empty",
          },
          {
            label: "Thọ Xuân",
            value: 15499,
            slug: "tho-xuan",
            status: "empty",
          },
          {
            label: "Bắc Lương",
            value: 15502,
            slug: "bac-luong",
            status: "empty",
          },
          {
            label: "Nam Giang",
            value: 15505,
            slug: "nam-giang",
            status: "empty",
          },
          {
            label: "Xuân Phong",
            value: 15508,
            slug: "xuan-phong",
            status: "empty",
          },
          { label: "Thọ Lộc", value: 15511, slug: "tho-loc", status: "empty" },
          {
            label: "Xuân Trường",
            value: 15514,
            slug: "xuan-truong",
            status: "empty",
          },
          {
            label: "Xuân Hòa",
            value: 15517,
            slug: "xuan-hoa",
            status: "empty",
          },
          { label: "Thọ Hải", value: 15520, slug: "tho-hai", status: "empty" },
          { label: "Tây Hồ", value: 15523, slug: "tay-ho", status: "empty" },
          {
            label: "Xuân Giang",
            value: 15526,
            slug: "xuan-giang",
            status: "empty",
          },
          {
            label: "Xuân Sinh",
            value: 15532,
            slug: "xuan-sinh",
            status: "empty",
          },
          {
            label: "Xuân Hưng",
            value: 15535,
            slug: "xuan-hung",
            status: "empty",
          },
          {
            label: "Thọ Diên",
            value: 15538,
            slug: "tho-dien",
            status: "empty",
          },
          { label: "Thọ Lâm", value: 15541, slug: "tho-lam", status: "empty" },
          {
            label: "Thọ Xương",
            value: 15544,
            slug: "tho-xuong",
            status: "empty",
          },
          {
            label: "Xuân Bái",
            value: 15547,
            slug: "xuan-bai",
            status: "empty",
          },
          {
            label: "Xuân Phú",
            value: 15550,
            slug: "xuan-phu",
            status: "empty",
          },
          {
            label: "Sao Vàng",
            value: 15553,
            slug: "sao-vang",
            status: "empty",
          },
          { label: "Lam Sơn", value: 15556, slug: "lam-son", status: "empty" },
          {
            label: "Xuân Thiên",
            value: 15559,
            slug: "xuan-thien",
            status: "empty",
          },
          {
            label: "Thuận Minh",
            value: 15565,
            slug: "thuan-minh",
            status: "empty",
          },
          { label: "Thọ Lập", value: 15568, slug: "tho-lap", status: "empty" },
          {
            label: "Quảng Phú",
            value: 15571,
            slug: "quang-phu",
            status: "empty",
          },
          {
            label: "Xuân Tín",
            value: 15574,
            slug: "xuan-tin",
            status: "empty",
          },
          {
            label: "Phú Xuân",
            value: 15577,
            slug: "phu-xuan",
            status: "empty",
          },
          {
            label: "Xuân Lai",
            value: 15583,
            slug: "xuan-lai",
            status: "empty",
          },
          {
            label: "Xuân Lập",
            value: 15586,
            slug: "xuan-lap",
            status: "empty",
          },
          {
            label: "Xuân Minh",
            value: 15592,
            slug: "xuan-minh",
            status: "empty",
          },
          {
            label: "Trường Xuân",
            value: 15598,
            slug: "truong-xuan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thường Xuân",
        value: 396,
        slug: "huyen-thuong-xuan",
        status: "empty",
        ward: [
          { label: "Bát Mọt", value: 15607, slug: "bat-mot", status: "empty" },
          {
            label: "Yên Nhân",
            value: 15610,
            slug: "yen-nhan",
            status: "empty",
          },
          { label: "Xuân Lẹ", value: 15619, slug: "xuan-le", status: "empty" },
          {
            label: "Vạn Xuân",
            value: 15622,
            slug: "van-xuan",
            status: "empty",
          },
          {
            label: "Lương Sơn",
            value: 15628,
            slug: "luong-son",
            status: "empty",
          },
          {
            label: "Xuân Cao",
            value: 15631,
            slug: "xuan-cao",
            status: "empty",
          },
          {
            label: "Luận Thành",
            value: 15634,
            slug: "luan-thanh",
            status: "empty",
          },
          {
            label: "Luận Khê",
            value: 15637,
            slug: "luan-khe",
            status: "empty",
          },
          {
            label: "Xuân Thắng",
            value: 15640,
            slug: "xuan-thang",
            status: "empty",
          },
          {
            label: "Xuân Lộc",
            value: 15643,
            slug: "xuan-loc",
            status: "empty",
          },
          {
            label: "Thường Xuân",
            value: 15646,
            slug: "thuong-xuan",
            status: "empty",
          },
          {
            label: "Xuân Dương",
            value: 15649,
            slug: "xuan-duong",
            status: "empty",
          },
          {
            label: "Thọ Thanh",
            value: 15652,
            slug: "tho-thanh",
            status: "empty",
          },
          {
            label: "Ngọc Phụng",
            value: 15655,
            slug: "ngoc-phung",
            status: "empty",
          },
          {
            label: "Xuân Chinh",
            value: 15658,
            slug: "xuan-chinh",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 15661,
            slug: "tan-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Triệu Sơn",
        value: 397,
        slug: "huyen-trieu-son",
        status: "empty",
        ward: [
          {
            label: "Triệu Sơn",
            value: 15664,
            slug: "trieu-son",
            status: "empty",
          },
          { label: "Thọ Sơn", value: 15667, slug: "tho-son", status: "empty" },
          {
            label: "Thọ Bình",
            value: 15670,
            slug: "tho-binh",
            status: "empty",
          },
          {
            label: "Thọ Tiến",
            value: 15673,
            slug: "tho-tien",
            status: "empty",
          },
          { label: "Hợp Lý", value: 15676, slug: "hop-ly", status: "empty" },
          {
            label: "Hợp Tiến",
            value: 15679,
            slug: "hop-tien",
            status: "empty",
          },
          {
            label: "Hợp Thành",
            value: 15682,
            slug: "hop-thanh",
            status: "empty",
          },
          {
            label: "Triệu Thành",
            value: 15685,
            slug: "trieu-thanh",
            status: "empty",
          },
          {
            label: "Hợp Thắng",
            value: 15688,
            slug: "hop-thang",
            status: "empty",
          },
          {
            label: "Minh Sơn",
            value: 15691,
            slug: "minh-son",
            status: "empty",
          },
          { label: "Dân Lực", value: 15700, slug: "dan-luc", status: "empty" },
          { label: "Dân Lý", value: 15703, slug: "dan-ly", status: "empty" },
          {
            label: "Dân Quyền",
            value: 15706,
            slug: "dan-quyen",
            status: "empty",
          },
          { label: "An Nông", value: 15709, slug: "an-nong", status: "empty" },
          { label: "Văn Sơn", value: 15712, slug: "van-son", status: "empty" },
          {
            label: "Thái Hòa",
            value: 15715,
            slug: "thai-hoa",
            status: "empty",
          },
          {
            label: "Thị trấn Nưa",
            value: 15718,
            slug: "thi-tran-nua",
            status: "empty",
          },
          {
            label: "Đồng Lợi",
            value: 15721,
            slug: "dong-loi",
            status: "empty",
          },
          {
            label: "Đồng Tiến",
            value: 15724,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Đồng Thắng",
            value: 15727,
            slug: "dong-thang",
            status: "empty",
          },
          {
            label: "Tiến Nông",
            value: 15730,
            slug: "tien-nong",
            status: "empty",
          },
          {
            label: "Khuyến Nông",
            value: 15733,
            slug: "khuyen-nong",
            status: "empty",
          },
          {
            label: "Xuân Lộc",
            value: 15736,
            slug: "xuan-loc",
            status: "empty",
          },
          { label: "Thọ Dân", value: 15742, slug: "tho-dan", status: "empty" },
          {
            label: "Xuân Thọ",
            value: 15745,
            slug: "xuan-tho",
            status: "empty",
          },
          { label: "Thọ Tân", value: 15748, slug: "tho-tan", status: "empty" },
          {
            label: "Thọ Ngọc",
            value: 15751,
            slug: "tho-ngoc",
            status: "empty",
          },
          {
            label: "Thọ Cường",
            value: 15754,
            slug: "tho-cuong",
            status: "empty",
          },
          { label: "Thọ Phú", value: 15760, slug: "tho-phu", status: "empty" },
          { label: "Thọ Thế", value: 15763, slug: "tho-the", status: "empty" },
          {
            label: "Nông Trường",
            value: 15766,
            slug: "nong-truong",
            status: "empty",
          },
          {
            label: "Bình Sơn",
            value: 15769,
            slug: "binh-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thiệu Hóa",
        value: 398,
        slug: "huyen-thieu-hoa",
        status: "empty",
        ward: [
          {
            label: "Thiệu Hóa",
            value: 15772,
            slug: "thieu-hoa",
            status: "empty",
          },
          {
            label: "Thiệu Ngọc",
            value: 15775,
            slug: "thieu-ngoc",
            status: "empty",
          },
          {
            label: "Thiệu Vũ",
            value: 15778,
            slug: "thieu-vu",
            status: "empty",
          },
          {
            label: "Thiệu Phúc",
            value: 15781,
            slug: "thieu-phuc",
            status: "empty",
          },
          {
            label: "Thiệu Tiến",
            value: 15784,
            slug: "thieu-tien",
            status: "empty",
          },
          {
            label: "Thiệu Công",
            value: 15787,
            slug: "thieu-cong",
            status: "empty",
          },
          {
            label: "Thiệu Long",
            value: 15793,
            slug: "thieu-long",
            status: "empty",
          },
          {
            label: "Thiệu Giang",
            value: 15796,
            slug: "thieu-giang",
            status: "empty",
          },
          {
            label: "Thiệu Duy",
            value: 15799,
            slug: "thieu-duy",
            status: "empty",
          },
          {
            label: "Thiệu Nguyên",
            value: 15802,
            slug: "thieu-nguyen",
            status: "empty",
          },
          {
            label: "Thiệu Hợp",
            value: 15805,
            slug: "thieu-hop",
            status: "empty",
          },
          {
            label: "Thiệu Thịnh",
            value: 15808,
            slug: "thieu-thinh",
            status: "empty",
          },
          {
            label: "Thiệu Quang",
            value: 15811,
            slug: "thieu-quang",
            status: "empty",
          },
          {
            label: "Thiệu Thành",
            value: 15814,
            slug: "thieu-thanh",
            status: "empty",
          },
          {
            label: "Thiệu Toán",
            value: 15817,
            slug: "thieu-toan",
            status: "empty",
          },
          {
            label: "Thiệu Chính",
            value: 15820,
            slug: "thieu-chinh",
            status: "empty",
          },
          {
            label: "Thiệu Hòa",
            value: 15823,
            slug: "thieu-hoa",
            status: "empty",
          },
          {
            label: "Hậu Hiền",
            value: 15829,
            slug: "hau-hien",
            status: "empty",
          },
          {
            label: "Thiệu Viên",
            value: 15832,
            slug: "thieu-vien",
            status: "empty",
          },
          {
            label: "Thiệu Lý",
            value: 15835,
            slug: "thieu-ly",
            status: "empty",
          },
          {
            label: "Thiệu Vận",
            value: 15838,
            slug: "thieu-van",
            status: "empty",
          },
          {
            label: "Thiệu Trung",
            value: 15841,
            slug: "thieu-trung",
            status: "empty",
          },
          {
            label: "Tân Châu",
            value: 15847,
            slug: "tan-chau",
            status: "empty",
          },
          {
            label: "Thiệu Giao",
            value: 15853,
            slug: "thieu-giao",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hoằng Hóa",
        value: 399,
        slug: "huyen-hoang-hoa",
        status: "empty",
        ward: [
          { label: "Bút Sơn", value: 15865, slug: "but-son", status: "empty" },
          {
            label: "Hoằng Xuân",
            value: 15877,
            slug: "hoang-xuan",
            status: "empty",
          },
          {
            label: "Hoằng Giang",
            value: 15880,
            slug: "hoang-giang",
            status: "empty",
          },
          {
            label: "Hoằng Phú",
            value: 15883,
            slug: "hoang-phu",
            status: "empty",
          },
          {
            label: "Hoằng Quỳ",
            value: 15886,
            slug: "hoang-quy",
            status: "empty",
          },
          {
            label: "Hoằng Kim",
            value: 15889,
            slug: "hoang-kim",
            status: "empty",
          },
          {
            label: "Hoằng Trung",
            value: 15892,
            slug: "hoang-trung",
            status: "empty",
          },
          {
            label: "Hoằng Trinh",
            value: 15895,
            slug: "hoang-trinh",
            status: "empty",
          },
          {
            label: "Hoằng Sơn",
            value: 15901,
            slug: "hoang-son",
            status: "empty",
          },
          {
            label: "Hoằng Cát",
            value: 15907,
            slug: "hoang-cat",
            status: "empty",
          },
          {
            label: "Hoằng Xuyên",
            value: 15910,
            slug: "hoang-xuyen",
            status: "empty",
          },
          {
            label: "Hoằng Quý",
            value: 15916,
            slug: "hoang-quy",
            status: "empty",
          },
          {
            label: "Hoằng Hợp",
            value: 15919,
            slug: "hoang-hop",
            status: "empty",
          },
          {
            label: "Hoằng Đức",
            value: 15928,
            slug: "hoang-duc",
            status: "empty",
          },
          {
            label: "Hoằng Hà",
            value: 15937,
            slug: "hoang-ha",
            status: "empty",
          },
          {
            label: "Hoằng Đạt",
            value: 15940,
            slug: "hoang-dat",
            status: "empty",
          },
          {
            label: "Hoằng Đạo",
            value: 15946,
            slug: "hoang-dao",
            status: "empty",
          },
          {
            label: "Hoằng Thắng",
            value: 15949,
            slug: "hoang-thang",
            status: "empty",
          },
          {
            label: "Hoằng Đồng",
            value: 15952,
            slug: "hoang-dong",
            status: "empty",
          },
          {
            label: "Hoằng Thái",
            value: 15955,
            slug: "hoang-thai",
            status: "empty",
          },
          {
            label: "Hoằng Thịnh",
            value: 15958,
            slug: "hoang-thinh",
            status: "empty",
          },
          {
            label: "Hoằng Thành",
            value: 15961,
            slug: "hoang-thanh",
            status: "empty",
          },
          {
            label: "Hoằng Lộc",
            value: 15964,
            slug: "hoang-loc",
            status: "empty",
          },
          {
            label: "Hoằng Trạch",
            value: 15967,
            slug: "hoang-trach",
            status: "empty",
          },
          {
            label: "Hoằng Phong",
            value: 15973,
            slug: "hoang-phong",
            status: "empty",
          },
          {
            label: "Hoằng Lưu",
            value: 15976,
            slug: "hoang-luu",
            status: "empty",
          },
          {
            label: "Hoằng Châu",
            value: 15979,
            slug: "hoang-chau",
            status: "empty",
          },
          {
            label: "Hoằng Tân",
            value: 15982,
            slug: "hoang-tan",
            status: "empty",
          },
          {
            label: "Hoằng Yến",
            value: 15985,
            slug: "hoang-yen",
            status: "empty",
          },
          {
            label: "Hoằng Tiến",
            value: 15988,
            slug: "hoang-tien",
            status: "empty",
          },
          {
            label: "Hoằng Hải",
            value: 15991,
            slug: "hoang-hai",
            status: "empty",
          },
          {
            label: "Hoằng Ngọc",
            value: 15994,
            slug: "hoang-ngoc",
            status: "empty",
          },
          {
            label: "Hoằng Đông",
            value: 15997,
            slug: "hoang-dong",
            status: "empty",
          },
          {
            label: "Hoằng Thanh",
            value: 16000,
            slug: "hoang-thanh",
            status: "empty",
          },
          {
            label: "Hoằng Phụ",
            value: 16003,
            slug: "hoang-phu",
            status: "empty",
          },
          {
            label: "Hoằng Trường",
            value: 16006,
            slug: "hoang-truong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hậu Lộc",
        value: 400,
        slug: "huyen-hau-loc",
        status: "empty",
        ward: [
          { label: "Hậu Lộc", value: 16012, slug: "hau-loc", status: "empty" },
          {
            label: "Đồng Lộc",
            value: 16015,
            slug: "dong-loc",
            status: "empty",
          },
          { label: "Đại Lộc", value: 16018, slug: "dai-loc", status: "empty" },
          {
            label: "Triệu Lộc",
            value: 16021,
            slug: "trieu-loc",
            status: "empty",
          },
          {
            label: "Tiến Lộc",
            value: 16027,
            slug: "tien-loc",
            status: "empty",
          },
          { label: "Lộc Sơn", value: 16030, slug: "loc-son", status: "empty" },
          { label: "Cầu Lộc", value: 16033, slug: "cau-loc", status: "empty" },
          {
            label: "Thành Lộc",
            value: 16036,
            slug: "thanh-loc",
            status: "empty",
          },
          { label: "Tuy Lộc", value: 16042, slug: "tuy-loc", status: "empty" },
          { label: "Mỹ Lộc", value: 16045, slug: "my-loc", status: "empty" },
          {
            label: "Thuần Lộc",
            value: 16048,
            slug: "thuan-loc",
            status: "empty",
          },
          {
            label: "Xuân Lộc",
            value: 16057,
            slug: "xuan-loc",
            status: "empty",
          },
          { label: "Hoa Lộc", value: 16063, slug: "hoa-loc", status: "empty" },
          {
            label: "Liên Lộc",
            value: 16066,
            slug: "lien-loc",
            status: "empty",
          },
          {
            label: "Quang Lộc",
            value: 16069,
            slug: "quang-loc",
            status: "empty",
          },
          { label: "Phú Lộc", value: 16072, slug: "phu-loc", status: "empty" },
          { label: "Hòa Lộc", value: 16075, slug: "hoa-loc", status: "empty" },
          {
            label: "Minh Lộc",
            value: 16078,
            slug: "minh-loc",
            status: "empty",
          },
          {
            label: "Hưng Lộc",
            value: 16081,
            slug: "hung-loc",
            status: "empty",
          },
          { label: "Hải Lộc", value: 16084, slug: "hai-loc", status: "empty" },
          { label: "Đa Lộc", value: 16087, slug: "da-loc", status: "empty" },
          { label: "Ngư Lộc", value: 16090, slug: "ngu-loc", status: "empty" },
        ],
      },
      {
        label: "Huyện Nga Sơn",
        value: 401,
        slug: "huyen-nga-son",
        status: "empty",
        ward: [
          { label: "Nga Sơn", value: 16093, slug: "nga-son", status: "empty" },
          { label: "Ba Đình", value: 16096, slug: "ba-dinh", status: "empty" },
          {
            label: "Nga Vịnh",
            value: 16099,
            slug: "nga-vinh",
            status: "empty",
          },
          { label: "Nga Văn", value: 16102, slug: "nga-van", status: "empty" },
          {
            label: "Nga Thiện",
            value: 16105,
            slug: "nga-thien",
            status: "empty",
          },
          {
            label: "Nga Tiến",
            value: 16108,
            slug: "nga-tien",
            status: "empty",
          },
          {
            label: "Nga Phượng",
            value: 16114,
            slug: "nga-phuong",
            status: "empty",
          },
          {
            label: "Nga Hiệp",
            value: 16120,
            slug: "nga-hiep",
            status: "empty",
          },
          {
            label: "Nga Thanh",
            value: 16123,
            slug: "nga-thanh",
            status: "empty",
          },
          { label: "Nga Yên", value: 16132, slug: "nga-yen", status: "empty" },
          {
            label: "Nga Giáp",
            value: 16135,
            slug: "nga-giap",
            status: "empty",
          },
          { label: "Nga Hải", value: 16138, slug: "nga-hai", status: "empty" },
          {
            label: "Nga Thành",
            value: 16141,
            slug: "nga-thanh",
            status: "empty",
          },
          { label: "Nga An", value: 16144, slug: "nga-an", status: "empty" },
          { label: "Nga Phú", value: 16147, slug: "nga-phu", status: "empty" },
          {
            label: "Nga Điền",
            value: 16150,
            slug: "nga-dien",
            status: "empty",
          },
          { label: "Nga Tân", value: 16153, slug: "nga-tan", status: "empty" },
          {
            label: "Nga Thủy",
            value: 16156,
            slug: "nga-thuy",
            status: "empty",
          },
          {
            label: "Nga Liên",
            value: 16159,
            slug: "nga-lien",
            status: "empty",
          },
          {
            label: "Nga Thái",
            value: 16162,
            slug: "nga-thai",
            status: "empty",
          },
          {
            label: "Nga Thạch",
            value: 16165,
            slug: "nga-thach",
            status: "empty",
          },
          {
            label: "Nga Thắng",
            value: 16168,
            slug: "nga-thang",
            status: "empty",
          },
          {
            label: "Nga Trường",
            value: 16171,
            slug: "nga-truong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Như Xuân",
        value: 402,
        slug: "huyen-nhu-xuan",
        status: "empty",
        ward: [
          { label: "Yên Cát", value: 16174, slug: "yen-cat", status: "empty" },
          {
            label: "Bãi Trành",
            value: 16177,
            slug: "bai-tranh",
            status: "empty",
          },
          {
            label: "Xuân Hòa",
            value: 16180,
            slug: "xuan-hoa",
            status: "empty",
          },
          {
            label: "Xuân Bình",
            value: 16183,
            slug: "xuan-binh",
            status: "empty",
          },
          { label: "Hóa Quỳ", value: 16186, slug: "hoa-quy", status: "empty" },
          { label: "Cát Vân", value: 16195, slug: "cat-van", status: "empty" },
          { label: "Cát Tân", value: 16198, slug: "cat-tan", status: "empty" },
          {
            label: "Tân Bình",
            value: 16201,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Bình Lương",
            value: 16204,
            slug: "binh-luong",
            status: "empty",
          },
          {
            label: "Thanh Quân",
            value: 16207,
            slug: "thanh-quan",
            status: "empty",
          },
          {
            label: "Thanh Xuân",
            value: 16210,
            slug: "thanh-xuan",
            status: "empty",
          },
          {
            label: "Thanh Hòa",
            value: 16213,
            slug: "thanh-hoa",
            status: "empty",
          },
          {
            label: "Thanh Phong",
            value: 16216,
            slug: "thanh-phong",
            status: "empty",
          },
          {
            label: "Thanh Lâm",
            value: 16219,
            slug: "thanh-lam",
            status: "empty",
          },
          {
            label: "Thanh Sơn",
            value: 16222,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Thượng Ninh",
            value: 16225,
            slug: "thuong-ninh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Như Thanh",
        value: 403,
        slug: "huyen-nhu-thanh",
        status: "empty",
        ward: [
          {
            label: "Bến Sung",
            value: 16228,
            slug: "ben-sung",
            status: "empty",
          },
          { label: "Cán Khê", value: 16231, slug: "can-khe", status: "empty" },
          { label: "Xuân Du", value: 16234, slug: "xuan-du", status: "empty" },
          {
            label: "Phượng Nghi",
            value: 16240,
            slug: "phuong-nghi",
            status: "empty",
          },
          { label: "Mậu Lâm", value: 16243, slug: "mau-lam", status: "empty" },
          {
            label: "Xuân Khang",
            value: 16246,
            slug: "xuan-khang",
            status: "empty",
          },
          {
            label: "Phú Nhuận",
            value: 16249,
            slug: "phu-nhuan",
            status: "empty",
          },
          {
            label: "Hải Long",
            value: 16252,
            slug: "hai-long",
            status: "empty",
          },
          {
            label: "Xuân Thái",
            value: 16258,
            slug: "xuan-thai",
            status: "empty",
          },
          {
            label: "Xuân Phúc",
            value: 16261,
            slug: "xuan-phuc",
            status: "empty",
          },
          { label: "Yên Thọ", value: 16264, slug: "yen-tho", status: "empty" },
          { label: "Yên Lạc", value: 16267, slug: "yen-lac", status: "empty" },
          {
            label: "Thanh Tân",
            value: 16273,
            slug: "thanh-tan",
            status: "empty",
          },
          {
            label: "Thanh Kỳ",
            value: 16276,
            slug: "thanh-ky",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nông Cống",
        value: 404,
        slug: "huyen-nong-cong",
        status: "empty",
        ward: [
          {
            label: "Nông Cống",
            value: 16279,
            slug: "nong-cong",
            status: "empty",
          },
          {
            label: "Tân Phúc",
            value: 16282,
            slug: "tan-phuc",
            status: "empty",
          },
          { label: "Tân Thọ", value: 16285, slug: "tan-tho", status: "empty" },
          {
            label: "Hoàng Sơn",
            value: 16288,
            slug: "hoang-son",
            status: "empty",
          },
          {
            label: "Tân Khang",
            value: 16291,
            slug: "tan-khang",
            status: "empty",
          },
          {
            label: "Hoàng Giang",
            value: 16294,
            slug: "hoang-giang",
            status: "empty",
          },
          {
            label: "Trung Chính",
            value: 16297,
            slug: "trung-chinh",
            status: "empty",
          },
          {
            label: "Trung Thành",
            value: 16303,
            slug: "trung-thanh",
            status: "empty",
          },
          {
            label: "Tế Thắng",
            value: 16309,
            slug: "te-thang",
            status: "empty",
          },
          { label: "Tế Lợi", value: 16315, slug: "te-loi", status: "empty" },
          { label: "Tế Nông", value: 16318, slug: "te-nong", status: "empty" },
          {
            label: "Minh Nghĩa",
            value: 16321,
            slug: "minh-nghia",
            status: "empty",
          },
          {
            label: "Minh Khôi",
            value: 16324,
            slug: "minh-khoi",
            status: "empty",
          },
          { label: "Vạn Hòa", value: 16327, slug: "van-hoa", status: "empty" },
          {
            label: "Trường Trung",
            value: 16330,
            slug: "truong-trung",
            status: "empty",
          },
          {
            label: "Vạn Thắng",
            value: 16333,
            slug: "van-thang",
            status: "empty",
          },
          {
            label: "Trường Giang",
            value: 16336,
            slug: "truong-giang",
            status: "empty",
          },
          {
            label: "Vạn Thiện",
            value: 16339,
            slug: "van-thien",
            status: "empty",
          },
          {
            label: "Thăng Long",
            value: 16342,
            slug: "thang-long",
            status: "empty",
          },
          {
            label: "Trường Minh",
            value: 16345,
            slug: "truong-minh",
            status: "empty",
          },
          {
            label: "Trường Sơn",
            value: 16348,
            slug: "truong-son",
            status: "empty",
          },
          {
            label: "Thăng Bình",
            value: 16351,
            slug: "thang-binh",
            status: "empty",
          },
          {
            label: "Công Liêm",
            value: 16354,
            slug: "cong-liem",
            status: "empty",
          },
          {
            label: "Tượng Văn",
            value: 16357,
            slug: "tuong-van",
            status: "empty",
          },
          {
            label: "Thăng Thọ",
            value: 16360,
            slug: "thang-tho",
            status: "empty",
          },
          {
            label: "Tượng Lĩnh",
            value: 16363,
            slug: "tuong-linh",
            status: "empty",
          },
          {
            label: "Tượng Sơn",
            value: 16366,
            slug: "tuong-son",
            status: "empty",
          },
          {
            label: "Công Chính",
            value: 16369,
            slug: "cong-chinh",
            status: "empty",
          },
          { label: "Yên Mỹ", value: 16375, slug: "yen-my", status: "empty" },
        ],
      },
      {
        label: "Huyện Đông Sơn",
        value: 405,
        slug: "huyen-dong-son",
        status: "empty",
        ward: [
          {
            label: "Rừng Thông",
            value: 100400,
            slug: "rung-thong",
            status: "empty",
          },
          {
            label: "Đông Hòa",
            value: 100401,
            slug: "dong-hoa",
            status: "empty",
          },
          {
            label: "Đông Hoàng",
            value: 100402,
            slug: "dong-hoang",
            status: "empty",
          },
          {
            label: "Đông Khê",
            value: 100403,
            slug: "dong-khe",
            status: "empty",
          },
          {
            label: "Đông Minh",
            value: 100404,
            slug: "dong-minh",
            status: "empty",
          },
          {
            label: "Đông Nam",
            value: 100405,
            slug: "dong-nam",
            status: "empty",
          },
          {
            label: "Đông Ninh",
            value: 100406,
            slug: "dong-ninh",
            status: "empty",
          },
          {
            label: "Đông Phú",
            value: 100407,
            slug: "dong-phu",
            status: "empty",
          },
          {
            label: "Đông Quang",
            value: 100408,
            slug: "dong-quang",
            status: "empty",
          },
          {
            label: "Đông Thanh",
            value: 100409,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Đông Thịnh",
            value: 100410,
            slug: "dong-thinh",
            status: "empty",
          },
          {
            label: "Đông Tiến",
            value: 100411,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Đông Văn",
            value: 100412,
            slug: "dong-van",
            status: "empty",
          },
          {
            label: "Đông Yên",
            value: 100413,
            slug: "dong-yen",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Quảng Xương",
        value: 406,
        slug: "huyen-quang-xuong",
        status: "empty",
        ward: [
          {
            label: "Tân Phong",
            value: 16438,
            slug: "tan-phong",
            status: "empty",
          },
          {
            label: "Quảng Trạch",
            value: 16447,
            slug: "quang-trach",
            status: "empty",
          },
          {
            label: "Quảng Đức",
            value: 16453,
            slug: "quang-duc",
            status: "empty",
          },
          {
            label: "Quảng Định",
            value: 16456,
            slug: "quang-dinh",
            status: "empty",
          },
          {
            label: "Quảng Nhân",
            value: 16462,
            slug: "quang-nhan",
            status: "empty",
          },
          {
            label: "Quảng Ninh",
            value: 16465,
            slug: "quang-ninh",
            status: "empty",
          },
          {
            label: "Quảng Bình",
            value: 16468,
            slug: "quang-binh",
            status: "empty",
          },
          {
            label: "Quảng Hợp",
            value: 16471,
            slug: "quang-hop",
            status: "empty",
          },
          {
            label: "Quảng Văn",
            value: 16474,
            slug: "quang-van",
            status: "empty",
          },
          {
            label: "Quảng Long",
            value: 16477,
            slug: "quang-long",
            status: "empty",
          },
          {
            label: "Quảng Yên",
            value: 16480,
            slug: "quang-yen",
            status: "empty",
          },
          {
            label: "Quảng Hòa",
            value: 16483,
            slug: "quang-hoa",
            status: "empty",
          },
          {
            label: "Quảng Khê",
            value: 16489,
            slug: "quang-khe",
            status: "empty",
          },
          {
            label: "Quảng Trung",
            value: 16492,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Quảng Chính",
            value: 16495,
            slug: "quang-chinh",
            status: "empty",
          },
          {
            label: "Quảng Ngọc",
            value: 16498,
            slug: "quang-ngoc",
            status: "empty",
          },
          {
            label: "Quảng Trường",
            value: 16501,
            slug: "quang-truong",
            status: "empty",
          },
          {
            label: "Quảng Phúc",
            value: 16510,
            slug: "quang-phuc",
            status: "empty",
          },
          {
            label: "Quảng Giao",
            value: 16519,
            slug: "quang-giao",
            status: "empty",
          },
          {
            label: "Quảng Hải",
            value: 16540,
            slug: "quang-hai",
            status: "empty",
          },
          {
            label: "Quảng Lưu",
            value: 16543,
            slug: "quang-luu",
            status: "empty",
          },
          {
            label: "Quảng Lộc",
            value: 16546,
            slug: "quang-loc",
            status: "empty",
          },
          {
            label: "Tiên Trang",
            value: 16549,
            slug: "tien-trang",
            status: "empty",
          },
          {
            label: "Quảng Nham",
            value: 16552,
            slug: "quang-nham",
            status: "empty",
          },
          {
            label: "Quảng Thạch",
            value: 16555,
            slug: "quang-thach",
            status: "empty",
          },
          {
            label: "Quảng Thái",
            value: 16558,
            slug: "quang-thai",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Nghi Sơn",
        value: 407,
        slug: "thi-xa-nghi-son",
        status: "empty",
        ward: [
          { label: "Hải Hòa", value: 16561, slug: "hai-hoa", status: "empty" },
          {
            label: "Hải Châu",
            value: 16564,
            slug: "hai-chau",
            status: "empty",
          },
          {
            label: "Thanh Thủy",
            value: 16567,
            slug: "thanh-thuy",
            status: "empty",
          },
          {
            label: "Thanh Sơn",
            value: 16570,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Hải Ninh",
            value: 16576,
            slug: "hai-ninh",
            status: "empty",
          },
          { label: "Anh Sơn", value: 16579, slug: "anh-son", status: "empty" },
          {
            label: "Ngọc Lĩnh",
            value: 16582,
            slug: "ngoc-linh",
            status: "empty",
          },
          { label: "Hải An", value: 16585, slug: "hai-an", status: "empty" },
          { label: "Các Sơn", value: 16591, slug: "cac-son", status: "empty" },
          { label: "Tân Dân", value: 16594, slug: "tan-dan", status: "empty" },
          {
            label: "Hải Lĩnh",
            value: 16597,
            slug: "hai-linh",
            status: "empty",
          },
          {
            label: "Định Hải",
            value: 16600,
            slug: "dinh-hai",
            status: "empty",
          },
          { label: "Phú Sơn", value: 16603, slug: "phu-son", status: "empty" },
          {
            label: "Ninh Hải",
            value: 16606,
            slug: "ninh-hai",
            status: "empty",
          },
          {
            label: "Nguyên Bình",
            value: 16609,
            slug: "nguyen-binh",
            status: "empty",
          },
          {
            label: "Hải Nhân",
            value: 16612,
            slug: "hai-nhan",
            status: "empty",
          },
          {
            label: "Bình Minh",
            value: 16618,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Hải Thanh",
            value: 16621,
            slug: "hai-thanh",
            status: "empty",
          },
          { label: "Phú Lâm", value: 16624, slug: "phu-lam", status: "empty" },
          {
            label: "Xuân Lâm",
            value: 16627,
            slug: "xuan-lam",
            status: "empty",
          },
          {
            label: "Trúc Lâm",
            value: 16630,
            slug: "truc-lam",
            status: "empty",
          },
          {
            label: "Hải Bình",
            value: 16633,
            slug: "hai-binh",
            status: "empty",
          },
          {
            label: "Tân Trường",
            value: 16636,
            slug: "tan-truong",
            status: "empty",
          },
          {
            label: "Tùng Lâm",
            value: 16639,
            slug: "tung-lam",
            status: "empty",
          },
          {
            label: "Tĩnh Hải",
            value: 16642,
            slug: "tinh-hai",
            status: "empty",
          },
          { label: "Mai Lâm", value: 16645, slug: "mai-lam", status: "empty" },
          {
            label: "Trường Lâm",
            value: 16648,
            slug: "truong-lam",
            status: "empty",
          },
          {
            label: "Hải Thượng",
            value: 16654,
            slug: "hai-thuong",
            status: "empty",
          },
          {
            label: "Nghi Sơn",
            value: 16657,
            slug: "nghi-son",
            status: "empty",
          },
          { label: "Hải Hà", value: 16660, slug: "hai-ha", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Nghệ An",
    value: 40,
    slug: "nghe-an",
    status: "filled",
    district: [
      {
        label: "Thành phố Vinh",
        value: 412,
        slug: "thanh-pho-vinh",
        status: "filled",
        ward: [
          {
            label: "Đông Vĩnh",
            value: 16663,
            slug: "dong-vinh",
            status: "empty",
          },
          {
            label: "Hà Huy Tập",
            value: 16666,
            slug: "ha-huy-tap",
            status: "filled",
          },
          { label: "Lê Lợi", value: 16669, slug: "le-loi", status: "empty" },
          {
            label: "Quán Bàu",
            value: 16670,
            slug: "quan-bau",
            status: "empty",
          },
          {
            label: "Hưng Bình",
            value: 16672,
            slug: "hung-binh",
            status: "filled",
          },
          {
            label: "Hưng Phúc",
            value: 16673,
            slug: "hung-phuc",
            status: "empty",
          },
          {
            label: "Hưng Dũng",
            value: 16675,
            slug: "hung-dung",
            status: "empty",
          },
          { label: "Cửa Nam", value: 16678, slug: "cua-nam", status: "empty" },
          {
            label: "Quang Trung",
            value: 16681,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Trường Thi",
            value: 16690,
            slug: "truong-thi",
            status: "filled",
          },
          {
            label: "Bến Thủy",
            value: 16693,
            slug: "ben-thuy",
            status: "empty",
          },
          {
            label: "Trung Đô",
            value: 16699,
            slug: "trung-do",
            status: "empty",
          },
          {
            label: "Nghi Phú",
            value: 16702,
            slug: "nghi-phu",
            status: "empty",
          },
          {
            label: "Hưng Đông",
            value: 16705,
            slug: "hung-dong",
            status: "empty",
          },
          {
            label: "Hưng Lộc",
            value: 16708,
            slug: "hung-loc",
            status: "empty",
          },
          {
            label: "Hưng Hòa",
            value: 16711,
            slug: "hung-hoa",
            status: "empty",
          },
          {
            label: "Vinh Tân",
            value: 16714,
            slug: "vinh-tan",
            status: "filled",
          },
          {
            label: "Nghi Thuỷ",
            value: 16717,
            slug: "nghi-thuy",
            status: "empty",
          },
          {
            label: "Nghi Tân",
            value: 16720,
            slug: "nghi-tan",
            status: "empty",
          },
          {
            label: "Thu Thuỷ",
            value: 16723,
            slug: "thu-thuy",
            status: "empty",
          },
          {
            label: "Nghi Hòa",
            value: 16726,
            slug: "nghi-hoa",
            status: "empty",
          },
          {
            label: "Nghi Hải",
            value: 16729,
            slug: "nghi-hai",
            status: "empty",
          },
          {
            label: "Nghi Hương",
            value: 16732,
            slug: "nghi-huong",
            status: "empty",
          },
          {
            label: "Nghi Thu",
            value: 16735,
            slug: "nghi-thu",
            status: "empty",
          },
          {
            label: "Nghi Phong",
            value: 17902,
            slug: "nghi-phong",
            status: "empty",
          },
          {
            label: "Nghi Xuân",
            value: 17905,
            slug: "nghi-xuan",
            status: "empty",
          },
          {
            label: "Nghi Liên",
            value: 17908,
            slug: "nghi-lien",
            status: "empty",
          },
          { label: "Nghi Ân", value: 17914, slug: "nghi-an", status: "empty" },
          {
            label: "Phúc Thọ",
            value: 17917,
            slug: "phuc-tho",
            status: "empty",
          },
          {
            label: "Nghi Kim",
            value: 17920,
            slug: "nghi-kim",
            status: "empty",
          },
          {
            label: "Nghi Đức",
            value: 17923,
            slug: "nghi-duc",
            status: "empty",
          },
          {
            label: "Nghi Thái",
            value: 17926,
            slug: "nghi-thai",
            status: "empty",
          },
          { label: "Lê Mao", value: 17927, slug: "le-mao", status: "empty" },
          {
            label: "Hưng Chính",
            value: 18013,
            slug: "hung-chinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Cửa Lò",
        value: 413,
        slug: "thi-xa-cua-lo",
        status: "filled",
        ward: [
          {
            label: "Nghi Hải",
            value: 100600,
            slug: "nghi-hai",
            status: "empty",
          },
          {
            label: "Nghi Hòa",
            value: 100601,
            slug: "nghi-hoa",
            status: "empty",
          },
          {
            label: "Nghi Tân",
            value: 100602,
            slug: "nghi-tan",
            status: "empty",
          },
          {
            label: "Nghi Thủy",
            value: 100603,
            slug: "nghi-thuy",
            status: "filled",
          },
          {
            label: "Thu Thủy",
            value: 100604,
            slug: "thu-thuy",
            status: "empty",
          },
          {
            label: "Nghi Hương",
            value: 100605,
            slug: "nghi-huong",
            status: "empty",
          },
          {
            label: "Nghi Thu",
            value: 100606,
            slug: "nghi-thu",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Thái Hoà",
        value: 414,
        slug: "thi-xa-thai-hoa",
        status: "empty",
        ward: [
          {
            label: "Hoà Hiếu",
            value: 16939,
            slug: "hoa-hieu",
            status: "empty",
          },
          {
            label: "Quang Phong",
            value: 16993,
            slug: "quang-phong",
            status: "empty",
          },
          {
            label: "Quang Tiến",
            value: 16994,
            slug: "quang-tien",
            status: "empty",
          },
          {
            label: "Long Sơn",
            value: 17003,
            slug: "long-son",
            status: "empty",
          },
          {
            label: "Nghĩa Tiến",
            value: 17005,
            slug: "nghia-tien",
            status: "empty",
          },
          {
            label: "Nghĩa Mỹ",
            value: 17008,
            slug: "nghia-my",
            status: "empty",
          },
          {
            label: "Tây Hiếu",
            value: 17011,
            slug: "tay-hieu",
            status: "empty",
          },
          {
            label: "Nghĩa Thuận",
            value: 17014,
            slug: "nghia-thuan",
            status: "empty",
          },
          {
            label: "Đông Hiếu",
            value: 17017,
            slug: "dong-hieu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Quế Phong",
        value: 415,
        slug: "huyen-que-phong",
        status: "empty",
        ward: [
          { label: "Kim Sơn", value: 16738, slug: "kim-son", status: "empty" },
          {
            label: "Thông Thụ",
            value: 16741,
            slug: "thong-thu",
            status: "empty",
          },
          {
            label: "Đồng Văn",
            value: 16744,
            slug: "dong-van",
            status: "empty",
          },
          {
            label: "Hạnh Dịch",
            value: 16747,
            slug: "hanh-dich",
            status: "empty",
          },
          {
            label: "Tiền Phong",
            value: 16750,
            slug: "tien-phong",
            status: "empty",
          },
          {
            label: "Nậm Giải",
            value: 16753,
            slug: "nam-giai",
            status: "empty",
          },
          { label: "Tri Lễ", value: 16756, slug: "tri-le", status: "empty" },
          {
            label: "Châu Kim",
            value: 16759,
            slug: "chau-kim",
            status: "empty",
          },
          {
            label: "Mường Nọc",
            value: 16763,
            slug: "muong-noc",
            status: "empty",
          },
          {
            label: "Châu Thôn",
            value: 16765,
            slug: "chau-thon",
            status: "empty",
          },
          {
            label: "Nậm Nhoóng",
            value: 16768,
            slug: "nam-nhoong",
            status: "empty",
          },
          {
            label: "Quang Phong",
            value: 16771,
            slug: "quang-phong",
            status: "empty",
          },
          {
            label: "Căm Muộn",
            value: 16774,
            slug: "cam-muon",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Quỳ Châu",
        value: 416,
        slug: "huyen-quy-chau",
        status: "empty",
        ward: [
          { label: "Tân Lạc", value: 16777, slug: "tan-lac", status: "empty" },
          {
            label: "Châu Bính",
            value: 16780,
            slug: "chau-binh",
            status: "empty",
          },
          {
            label: "Châu Thuận",
            value: 16783,
            slug: "chau-thuan",
            status: "empty",
          },
          {
            label: "Châu Hội",
            value: 16786,
            slug: "chau-hoi",
            status: "empty",
          },
          {
            label: "Châu Nga",
            value: 16789,
            slug: "chau-nga",
            status: "empty",
          },
          {
            label: "Châu Tiến",
            value: 16792,
            slug: "chau-tien",
            status: "empty",
          },
          {
            label: "Châu Hạnh",
            value: 16795,
            slug: "chau-hanh",
            status: "empty",
          },
          {
            label: "Châu Thắng",
            value: 16798,
            slug: "chau-thang",
            status: "empty",
          },
          {
            label: "Châu Phong",
            value: 16801,
            slug: "chau-phong",
            status: "empty",
          },
          {
            label: "Châu Bình",
            value: 16804,
            slug: "chau-binh",
            status: "empty",
          },
          {
            label: "Châu Hoàn",
            value: 16807,
            slug: "chau-hoan",
            status: "empty",
          },
          {
            label: "Diên Lãm",
            value: 16810,
            slug: "dien-lam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Kỳ Sơn",
        value: 417,
        slug: "huyen-ky-son",
        status: "empty",
        ward: [
          {
            label: "Mường Xén",
            value: 16813,
            slug: "muong-xen",
            status: "empty",
          },
          { label: "Mỹ Lý", value: 16816, slug: "my-ly", status: "empty" },
          { label: "Bắc Lý", value: 16819, slug: "bac-ly", status: "empty" },
          { label: "Keng Đu", value: 16822, slug: "keng-du", status: "empty" },
          {
            label: "Đoọc Mạy",
            value: 16825,
            slug: "dooc-may",
            status: "empty",
          },
          { label: "Huồi Tụ", value: 16828, slug: "huoi-tu", status: "empty" },
          {
            label: "Mường Lống",
            value: 16831,
            slug: "muong-long",
            status: "empty",
          },
          { label: "Na Loi", value: 16834, slug: "na-loi", status: "empty" },
          { label: "Nậm Cắn", value: 16837, slug: "nam-can", status: "empty" },
          { label: "Bảo Nam", value: 16840, slug: "bao-nam", status: "empty" },
          {
            label: "Phà Đánh",
            value: 16843,
            slug: "pha-danh",
            status: "empty",
          },
          {
            label: "Bảo Thắng",
            value: 16846,
            slug: "bao-thang",
            status: "empty",
          },
          { label: "Hữu Lập", value: 16849, slug: "huu-lap", status: "empty" },
          { label: "Tà Cạ", value: 16852, slug: "ta-ca", status: "empty" },
          {
            label: "Chiêu Lưu",
            value: 16855,
            slug: "chieu-luu",
            status: "empty",
          },
          {
            label: "Mường Típ",
            value: 16858,
            slug: "muong-tip",
            status: "empty",
          },
          {
            label: "Hữu Kiệm",
            value: 16861,
            slug: "huu-kiem",
            status: "empty",
          },
          { label: "Tây Sơn", value: 16864, slug: "tay-son", status: "empty" },
          {
            label: "Mường Ải",
            value: 16867,
            slug: "muong-ai",
            status: "empty",
          },
          { label: "Na Ngoi", value: 16870, slug: "na-ngoi", status: "empty" },
          { label: "Nậm Càn", value: 16873, slug: "nam-can", status: "empty" },
        ],
      },
      {
        label: "Huyện Tương Dương",
        value: 418,
        slug: "huyen-tuong-duong",
        status: "empty",
        ward: [
          {
            label: "Thạch Giám",
            value: 16876,
            slug: "thach-giam",
            status: "empty",
          },
          { label: "Mai Sơn", value: 16879, slug: "mai-son", status: "empty" },
          {
            label: "Nhôn Mai",
            value: 16882,
            slug: "nhon-mai",
            status: "empty",
          },
          {
            label: "Hữu Khuông",
            value: 16885,
            slug: "huu-khuong",
            status: "empty",
          },
          {
            label: "Yên Tĩnh",
            value: 16900,
            slug: "yen-tinh",
            status: "empty",
          },
          { label: "Nga My", value: 16903, slug: "nga-my", status: "empty" },
          {
            label: "Xiêng My",
            value: 16904,
            slug: "xieng-my",
            status: "empty",
          },
          {
            label: "Lưỡng Minh",
            value: 16906,
            slug: "luong-minh",
            status: "empty",
          },
          { label: "Yên Hòa", value: 16909, slug: "yen-hoa", status: "empty" },
          { label: "Yên Na", value: 16912, slug: "yen-na", status: "empty" },
          {
            label: "Lưu Kiền",
            value: 16915,
            slug: "luu-kien",
            status: "empty",
          },
          {
            label: "Xá Lượng",
            value: 16921,
            slug: "xa-luong",
            status: "empty",
          },
          {
            label: "Tam Thái",
            value: 16924,
            slug: "tam-thai",
            status: "empty",
          },
          {
            label: "Tam Đình",
            value: 16927,
            slug: "tam-dinh",
            status: "empty",
          },
          {
            label: "Yên Thắng",
            value: 16930,
            slug: "yen-thang",
            status: "empty",
          },
          {
            label: "Tam Quang",
            value: 16933,
            slug: "tam-quang",
            status: "empty",
          },
          { label: "Tam Hợp", value: 16936, slug: "tam-hop", status: "empty" },
        ],
      },
      {
        label: "Huyện Nghĩa Đàn",
        value: 419,
        slug: "huyen-nghia-dan",
        status: "empty",
        ward: [
          {
            label: "Nghĩa Đàn",
            value: 16941,
            slug: "nghia-dan",
            status: "empty",
          },
          {
            label: "Nghĩa Mai",
            value: 16942,
            slug: "nghia-mai",
            status: "empty",
          },
          {
            label: "Nghĩa Yên",
            value: 16945,
            slug: "nghia-yen",
            status: "empty",
          },
          {
            label: "Nghĩa Lạc",
            value: 16948,
            slug: "nghia-lac",
            status: "empty",
          },
          {
            label: "Nghĩa Lâm",
            value: 16951,
            slug: "nghia-lam",
            status: "empty",
          },
          {
            label: "Nghĩa Sơn",
            value: 16954,
            slug: "nghia-son",
            status: "empty",
          },
          {
            label: "Nghĩa Lợi",
            value: 16957,
            slug: "nghia-loi",
            status: "empty",
          },
          {
            label: "Nghĩa Bình",
            value: 16960,
            slug: "nghia-binh",
            status: "empty",
          },
          {
            label: "Nghĩa Minh",
            value: 16966,
            slug: "nghia-minh",
            status: "empty",
          },
          {
            label: "Nghĩa Thọ",
            value: 16969,
            slug: "nghia-tho",
            status: "empty",
          },
          {
            label: "Nghĩa Hưng",
            value: 16972,
            slug: "nghia-hung",
            status: "empty",
          },
          {
            label: "Nghĩa Hồng",
            value: 16975,
            slug: "nghia-hong",
            status: "empty",
          },
          {
            label: "Nghĩa Trung",
            value: 16981,
            slug: "nghia-trung",
            status: "empty",
          },
          {
            label: "Nghĩa Hội",
            value: 16984,
            slug: "nghia-hoi",
            status: "empty",
          },
          {
            label: "Nghĩa Thành",
            value: 16987,
            slug: "nghia-thanh",
            status: "empty",
          },
          {
            label: "Nghĩa Đức",
            value: 17020,
            slug: "nghia-duc",
            status: "empty",
          },
          {
            label: "Nghĩa An",
            value: 17023,
            slug: "nghia-an",
            status: "empty",
          },
          {
            label: "Nghĩa Long",
            value: 17026,
            slug: "nghia-long",
            status: "empty",
          },
          {
            label: "Nghĩa Lộc",
            value: 17029,
            slug: "nghia-loc",
            status: "empty",
          },
          {
            label: "Nghĩa Khánh",
            value: 17032,
            slug: "nghia-khanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Quỳ Hợp",
        value: 420,
        slug: "huyen-quy-hop",
        status: "empty",
        ward: [
          { label: "Quỳ Hợp", value: 17035, slug: "quy-hop", status: "empty" },
          { label: "Yên Hợp", value: 17038, slug: "yen-hop", status: "empty" },
          {
            label: "Châu Tiến",
            value: 17041,
            slug: "chau-tien",
            status: "empty",
          },
          {
            label: "Châu Hồng",
            value: 17044,
            slug: "chau-hong",
            status: "empty",
          },
          {
            label: "Đồng Hợp",
            value: 17047,
            slug: "dong-hop",
            status: "empty",
          },
          {
            label: "Châu Thành",
            value: 17050,
            slug: "chau-thanh",
            status: "empty",
          },
          {
            label: "Liên Hợp",
            value: 17053,
            slug: "lien-hop",
            status: "empty",
          },
          {
            label: "Châu Lộc",
            value: 17056,
            slug: "chau-loc",
            status: "empty",
          },
          { label: "Tam Hợp", value: 17059, slug: "tam-hop", status: "empty" },
          {
            label: "Châu Cường",
            value: 17062,
            slug: "chau-cuong",
            status: "empty",
          },
          {
            label: "Châu Quang",
            value: 17065,
            slug: "chau-quang",
            status: "empty",
          },
          { label: "Thọ Hợp", value: 17068, slug: "tho-hop", status: "empty" },
          {
            label: "Minh Hợp",
            value: 17071,
            slug: "minh-hop",
            status: "empty",
          },
          {
            label: "Nghĩa Xuân",
            value: 17074,
            slug: "nghia-xuan",
            status: "empty",
          },
          {
            label: "Châu Thái",
            value: 17077,
            slug: "chau-thai",
            status: "empty",
          },
          {
            label: "Châu Đình",
            value: 17080,
            slug: "chau-dinh",
            status: "empty",
          },
          { label: "Văn Lợi", value: 17083, slug: "van-loi", status: "empty" },
          { label: "Nam Sơn", value: 17086, slug: "nam-son", status: "empty" },
          { label: "Châu Lý", value: 17089, slug: "chau-ly", status: "empty" },
          { label: "Hạ Sơn", value: 17092, slug: "ha-son", status: "empty" },
          { label: "Bắc Sơn", value: 17095, slug: "bac-son", status: "empty" },
        ],
      },
      {
        label: "Huyện Quỳnh Lưu",
        value: 421,
        slug: "huyen-quynh-luu",
        status: "empty",
        ward: [
          {
            label: "Quỳnh Thắng",
            value: 17101,
            slug: "quynh-thang",
            status: "empty",
          },
          {
            label: "Quỳnh Tân",
            value: 17119,
            slug: "quynh-tan",
            status: "empty",
          },
          {
            label: "Quỳnh Châu",
            value: 17122,
            slug: "quynh-chau",
            status: "empty",
          },
          { label: "Tân Sơn", value: 17140, slug: "tan-son", status: "empty" },
          {
            label: "Quỳnh Văn",
            value: 17143,
            slug: "quynh-van",
            status: "empty",
          },
          {
            label: "Ngọc Sơn",
            value: 17146,
            slug: "ngoc-son",
            status: "empty",
          },
          {
            label: "Quỳnh Tam",
            value: 17149,
            slug: "quynh-tam",
            status: "empty",
          },
          {
            label: "Quỳnh Sơn",
            value: 17152,
            slug: "quynh-son",
            status: "empty",
          },
          {
            label: "Quỳnh Thạch",
            value: 17155,
            slug: "quynh-thach",
            status: "empty",
          },
          {
            label: "Quỳnh Bảng",
            value: 17158,
            slug: "quynh-bang",
            status: "empty",
          },
          {
            label: "Quỳnh Thanh",
            value: 17164,
            slug: "quynh-thanh",
            status: "empty",
          },
          {
            label: "Quỳnh Hậu",
            value: 17167,
            slug: "quynh-hau",
            status: "empty",
          },
          {
            label: "Quỳnh Lâm",
            value: 17170,
            slug: "quynh-lam",
            status: "empty",
          },
          {
            label: "Quỳnh Đôi",
            value: 17173,
            slug: "quynh-doi",
            status: "empty",
          },
          {
            label: "Minh Lương",
            value: 17176,
            slug: "minh-luong",
            status: "empty",
          },
          {
            label: "Cầu Giát",
            value: 17179,
            slug: "cau-giat",
            status: "empty",
          },
          {
            label: "Quỳnh Yên",
            value: 17182,
            slug: "quynh-yen",
            status: "empty",
          },
          {
            label: "Bình Sơn",
            value: 17185,
            slug: "binh-son",
            status: "empty",
          },
          {
            label: "Quỳnh Diễn",
            value: 17191,
            slug: "quynh-dien",
            status: "empty",
          },
          {
            label: "Quỳnh Giang",
            value: 17197,
            slug: "quynh-giang",
            status: "empty",
          },
          { label: "An Hòa", value: 17206, slug: "an-hoa", status: "empty" },
          {
            label: "Phú Nghĩa",
            value: 17209,
            slug: "phu-nghia",
            status: "empty",
          },
          { label: "Văn Hải", value: 17212, slug: "van-hai", status: "empty" },
          {
            label: "Thuận Long",
            value: 17218,
            slug: "thuan-long",
            status: "empty",
          },
          {
            label: "Tân Thắng",
            value: 17224,
            slug: "tan-thang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Con Cuông",
        value: 422,
        slug: "huyen-con-cuong",
        status: "empty",
        ward: [
          {
            label: "Bình Chuẩn",
            value: 17230,
            slug: "binh-chuan",
            status: "empty",
          },
          {
            label: "Lạng Khê",
            value: 17233,
            slug: "lang-khe",
            status: "empty",
          },
          { label: "Cam Lâm", value: 17236, slug: "cam-lam", status: "empty" },
          {
            label: "Thạch Ngàn",
            value: 17239,
            slug: "thach-ngan",
            status: "empty",
          },
          {
            label: "Đôn Phục",
            value: 17242,
            slug: "don-phuc",
            status: "empty",
          },
          { label: "Mậu Đức", value: 17245, slug: "mau-duc", status: "empty" },
          {
            label: "Châu Khê",
            value: 17248,
            slug: "chau-khe",
            status: "empty",
          },
          { label: "Chi Khê", value: 17251, slug: "chi-khe", status: "empty" },
          { label: "Trà Lân", value: 17254, slug: "tra-lan", status: "empty" },
          { label: "Yên Khê", value: 17257, slug: "yen-khe", status: "empty" },
          { label: "Lục Dạ", value: 17260, slug: "luc-da", status: "empty" },
          { label: "Môn Sơn", value: 17263, slug: "mon-son", status: "empty" },
        ],
      },
      {
        label: "Huyện Tân Kỳ",
        value: 423,
        slug: "huyen-tan-ky",
        status: "filled",
        ward: [
          { label: "Tân Kỳ", value: 17266, slug: "tan-ky", status: "filled" },
          { label: "Tân Hợp", value: 17269, slug: "tan-hop", status: "empty" },
          { label: "Tân Phú", value: 17272, slug: "tan-phu", status: "empty" },
          {
            label: "Tân Xuân",
            value: 17275,
            slug: "tan-xuan",
            status: "empty",
          },
          {
            label: "Giai Xuân",
            value: 17278,
            slug: "giai-xuan",
            status: "empty",
          },
          {
            label: "Bình Hợp",
            value: 17281,
            slug: "binh-hop",
            status: "empty",
          },
          {
            label: "Nghĩa Đồng",
            value: 17284,
            slug: "nghia-dong",
            status: "empty",
          },
          {
            label: "Đồng Văn",
            value: 17287,
            slug: "dong-van",
            status: "empty",
          },
          {
            label: "Nghĩa Thái",
            value: 17290,
            slug: "nghia-thai",
            status: "empty",
          },
          {
            label: "Hoàn Long",
            value: 17296,
            slug: "hoan-long",
            status: "empty",
          },
          {
            label: "Nghĩa Phúc",
            value: 17299,
            slug: "nghia-phuc",
            status: "empty",
          },
          { label: "Tiên Kỳ", value: 17302, slug: "tien-ky", status: "empty" },
          { label: "Tân An", value: 17305, slug: "tan-an", status: "empty" },
          {
            label: "Nghĩa Dũng",
            value: 17308,
            slug: "nghia-dung",
            status: "empty",
          },
          { label: "Kỳ Sơn", value: 17314, slug: "ky-son", status: "empty" },
          {
            label: "Hương Sơn",
            value: 17317,
            slug: "huong-son",
            status: "empty",
          },
          { label: "Kỳ Tân", value: 17320, slug: "ky-tan", status: "empty" },
          { label: "Phú Sơn", value: 17323, slug: "phu-son", status: "empty" },
          {
            label: "Tân Hương",
            value: 17325,
            slug: "tan-huong",
            status: "empty",
          },
          {
            label: "Nghĩa Hành",
            value: 17326,
            slug: "nghia-hanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Anh Sơn",
        value: 424,
        slug: "huyen-anh-son",
        status: "empty",
        ward: [
          {
            label: "Kim Nhan",
            value: 17329,
            slug: "kim-nhan",
            status: "empty",
          },
          { label: "Thọ Sơn", value: 17332, slug: "tho-son", status: "empty" },
          {
            label: "Thành Sơn",
            value: 17335,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Bình Sơn",
            value: 17338,
            slug: "binh-son",
            status: "empty",
          },
          {
            label: "Tam Đỉnh",
            value: 17344,
            slug: "tam-dinh",
            status: "empty",
          },
          {
            label: "Hùng Sơn",
            value: 17347,
            slug: "hung-son",
            status: "empty",
          },
          { label: "Cẩm Sơn", value: 17350, slug: "cam-son", status: "empty" },
          { label: "Đức Sơn", value: 17353, slug: "duc-son", status: "empty" },
          {
            label: "Tường Sơn",
            value: 17356,
            slug: "tuong-son",
            status: "empty",
          },
          { label: "Hoa Sơn", value: 17357, slug: "hoa-son", status: "empty" },
          { label: "Tào Sơn", value: 17359, slug: "tao-son", status: "empty" },
          {
            label: "Vĩnh Sơn",
            value: 17362,
            slug: "vinh-son",
            status: "empty",
          },
          {
            label: "Lạng Sơn",
            value: 17365,
            slug: "lang-son",
            status: "empty",
          },
          { label: "Hội Sơn", value: 17368, slug: "hoi-son", status: "empty" },
          {
            label: "Phúc Sơn",
            value: 17374,
            slug: "phuc-son",
            status: "empty",
          },
          {
            label: "Long Sơn",
            value: 17377,
            slug: "long-son",
            status: "empty",
          },
          {
            label: "Khai Sơn",
            value: 17380,
            slug: "khai-son",
            status: "empty",
          },
          {
            label: "Lĩnh Sơn",
            value: 17383,
            slug: "linh-son",
            status: "empty",
          },
          { label: "Cao Sơn", value: 17386, slug: "cao-son", status: "empty" },
        ],
      },
      {
        label: "Huyện Diễn Châu",
        value: 425,
        slug: "huyen-dien-chau",
        status: "empty",
        ward: [
          {
            label: "Diễn Lâm",
            value: 17392,
            slug: "dien-lam",
            status: "empty",
          },
          {
            label: "Diễn Đoài",
            value: 17395,
            slug: "dien-doai",
            status: "empty",
          },
          {
            label: "Diễn Trường",
            value: 17398,
            slug: "dien-truong",
            status: "empty",
          },
          {
            label: "Diễn Yên",
            value: 17401,
            slug: "dien-yen",
            status: "empty",
          },
          {
            label: "Diễn Hoàng",
            value: 17404,
            slug: "dien-hoang",
            status: "empty",
          },
          { label: "Diễn Mỹ", value: 17410, slug: "dien-my", status: "empty" },
          {
            label: "Diễn Hồng",
            value: 17413,
            slug: "dien-hong",
            status: "empty",
          },
          {
            label: "Diễn Phong",
            value: 17416,
            slug: "dien-phong",
            status: "empty",
          },
          {
            label: "Hùng Hải",
            value: 17419,
            slug: "hung-hai",
            status: "empty",
          },
          {
            label: "Diễn Liên",
            value: 17425,
            slug: "dien-lien",
            status: "empty",
          },
          {
            label: "Diễn Vạn",
            value: 17428,
            slug: "dien-van",
            status: "empty",
          },
          {
            label: "Diễn Kim",
            value: 17431,
            slug: "dien-kim",
            status: "empty",
          },
          { label: "Diễn Kỷ", value: 17434, slug: "dien-ky", status: "empty" },
          {
            label: "Xuân Tháp",
            value: 17437,
            slug: "xuan-thap",
            status: "empty",
          },
          {
            label: "Diễn Thái",
            value: 17440,
            slug: "dien-thai",
            status: "empty",
          },
          {
            label: "Diễn Đồng",
            value: 17443,
            slug: "dien-dong",
            status: "empty",
          },
          {
            label: "Hạnh Quảng",
            value: 17449,
            slug: "hanh-quang",
            status: "empty",
          },
          {
            label: "Ngọc Bích",
            value: 17452,
            slug: "ngoc-bich",
            status: "empty",
          },
          {
            label: "Diễn Nguyên",
            value: 17458,
            slug: "dien-nguyen",
            status: "empty",
          },
          {
            label: "Diễn Hoa",
            value: 17461,
            slug: "dien-hoa",
            status: "empty",
          },
          {
            label: "Diễn Thành",
            value: 17464,
            slug: "dien-thanh",
            status: "empty",
          },
          {
            label: "Diễn Phúc",
            value: 17467,
            slug: "dien-phuc",
            status: "empty",
          },
          {
            label: "Diễn Cát",
            value: 17476,
            slug: "dien-cat",
            status: "empty",
          },
          {
            label: "Diễn Thịnh",
            value: 17479,
            slug: "dien-thinh",
            status: "empty",
          },
          {
            label: "Diễn Tân",
            value: 17482,
            slug: "dien-tan",
            status: "empty",
          },
          {
            label: "Minh Châu",
            value: 17485,
            slug: "minh-chau",
            status: "empty",
          },
          {
            label: "Diễn Thọ",
            value: 17488,
            slug: "dien-tho",
            status: "empty",
          },
          {
            label: "Diễn Lợi",
            value: 17491,
            slug: "dien-loi",
            status: "empty",
          },
          {
            label: "Diễn Lộc",
            value: 17494,
            slug: "dien-loc",
            status: "empty",
          },
          {
            label: "Diễn Trung",
            value: 17497,
            slug: "dien-trung",
            status: "empty",
          },
          { label: "Diễn An", value: 17500, slug: "dien-an", status: "empty" },
          {
            label: "Diễn Phú",
            value: 17503,
            slug: "dien-phu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Yên Thành",
        value: 426,
        slug: "huyen-yen-thanh",
        status: "empty",
        ward: [
          {
            label: "Hoa Thành",
            value: 17506,
            slug: "hoa-thanh",
            status: "empty",
          },
          {
            label: "Mã Thành",
            value: 17509,
            slug: "ma-thanh",
            status: "empty",
          },
          {
            label: "Tiến Thành",
            value: 17510,
            slug: "tien-thanh",
            status: "empty",
          },
          {
            label: "Lăng Thành",
            value: 17512,
            slug: "lang-thanh",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 17515,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Đức Thành",
            value: 17518,
            slug: "duc-thanh",
            status: "empty",
          },
          {
            label: "Kim Thành",
            value: 17521,
            slug: "kim-thanh",
            status: "empty",
          },
          {
            label: "Hậu Thành",
            value: 17524,
            slug: "hau-thanh",
            status: "empty",
          },
          {
            label: "Đô Thành",
            value: 17527,
            slug: "do-thanh",
            status: "empty",
          },
          {
            label: "Thọ Thành",
            value: 17530,
            slug: "tho-thanh",
            status: "empty",
          },
          {
            label: "Quang Thành",
            value: 17533,
            slug: "quang-thanh",
            status: "empty",
          },
          {
            label: "Tây Thành",
            value: 17536,
            slug: "tay-thanh",
            status: "empty",
          },
          {
            label: "Phúc Thành",
            value: 17539,
            slug: "phuc-thanh",
            status: "empty",
          },
          {
            label: "Phú Thành",
            value: 17542,
            slug: "phu-thanh",
            status: "empty",
          },
          {
            label: "Đồng Thành",
            value: 17545,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Tăng Thành",
            value: 17554,
            slug: "tang-thanh",
            status: "empty",
          },
          {
            label: "Văn Thành",
            value: 17557,
            slug: "van-thanh",
            status: "empty",
          },
          {
            label: "Thịnh Thành",
            value: 17560,
            slug: "thinh-thanh",
            status: "empty",
          },
          {
            label: "Xuân Thành",
            value: 17566,
            slug: "xuan-thanh",
            status: "empty",
          },
          {
            label: "Bắc Thành",
            value: 17569,
            slug: "bac-thanh",
            status: "empty",
          },
          {
            label: "Đông Thành",
            value: 17572,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Trung Thành",
            value: 17575,
            slug: "trung-thanh",
            status: "empty",
          },
          {
            label: "Long Thành",
            value: 17578,
            slug: "long-thanh",
            status: "empty",
          },
          {
            label: "Minh Thành",
            value: 17581,
            slug: "minh-thanh",
            status: "empty",
          },
          {
            label: "Nam Thành",
            value: 17584,
            slug: "nam-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Thành",
            value: 17587,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Viên Thành",
            value: 17596,
            slug: "vien-thanh",
            status: "empty",
          },
          {
            label: "Liên Thành",
            value: 17602,
            slug: "lien-thanh",
            status: "empty",
          },
          {
            label: "Bảo Thành",
            value: 17605,
            slug: "bao-thanh",
            status: "empty",
          },
          {
            label: "Mỹ Thành",
            value: 17608,
            slug: "my-thanh",
            status: "empty",
          },
          { label: "Vân Tụ", value: 17611, slug: "van-tu", status: "empty" },
          {
            label: "Sơn Thành",
            value: 17614,
            slug: "son-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đô Lương",
        value: 427,
        slug: "huyen-do-luong",
        status: "filled",
        ward: [
          {
            label: "Đô Lương",
            value: 17617,
            slug: "do-luong",
            status: "empty",
          },
          {
            label: "Giang Sơn Đông",
            value: 17619,
            slug: "giang-son-dong",
            status: "empty",
          },
          {
            label: "Giang Sơn Tây",
            value: 17620,
            slug: "giang-son-tay",
            status: "empty",
          },
          {
            label: "Bạch Ngọc",
            value: 17623,
            slug: "bach-ngoc",
            status: "empty",
          },
          { label: "Bồi Sơn", value: 17626, slug: "boi-son", status: "empty" },
          {
            label: "Hồng Sơn",
            value: 17629,
            slug: "hong-son",
            status: "empty",
          },
          { label: "Bài Sơn", value: 17632, slug: "bai-son", status: "empty" },
          { label: "Bắc Sơn", value: 17638, slug: "bac-son", status: "empty" },
          {
            label: "Tràng Sơn",
            value: 17641,
            slug: "trang-son",
            status: "empty",
          },
          {
            label: "Thượng Sơn",
            value: 17644,
            slug: "thuong-son",
            status: "empty",
          },
          { label: "Hòa Sơn", value: 17647, slug: "hoa-son", status: "empty" },
          {
            label: "Đặng Sơn",
            value: 17650,
            slug: "dang-son",
            status: "empty",
          },
          {
            label: "Đông Sơn",
            value: 17653,
            slug: "dong-son",
            status: "empty",
          },
          { label: "Nam Sơn", value: 17656, slug: "nam-son", status: "empty" },
          { label: "Lưu Sơn", value: 17659, slug: "luu-son", status: "empty" },
          { label: "Yên Sơn", value: 17662, slug: "yen-son", status: "empty" },
          { label: "Văn Sơn", value: 17665, slug: "van-son", status: "empty" },
          { label: "Đà Sơn", value: 17668, slug: "da-son", status: "empty" },
          { label: "Lạc Sơn", value: 17671, slug: "lac-son", status: "filled" },
          { label: "Tân Sơn", value: 17674, slug: "tan-son", status: "empty" },
          {
            label: "Thái Sơn",
            value: 17677,
            slug: "thai-son",
            status: "empty",
          },
          {
            label: "Quang Sơn",
            value: 17680,
            slug: "quang-son",
            status: "empty",
          },
          {
            label: "Thịnh Sơn",
            value: 17683,
            slug: "thinh-son",
            status: "empty",
          },
          {
            label: "Trung Sơn",
            value: 17686,
            slug: "trung-son",
            status: "empty",
          },
          {
            label: "Xuân Sơn",
            value: 17689,
            slug: "xuan-son",
            status: "empty",
          },
          {
            label: "Minh Sơn",
            value: 17692,
            slug: "minh-son",
            status: "empty",
          },
          {
            label: "Thuận Sơn",
            value: 17695,
            slug: "thuan-son",
            status: "empty",
          },
          {
            label: "Nhân Sơn",
            value: 17698,
            slug: "nhan-son",
            status: "empty",
          },
          {
            label: "Hiến Sơn",
            value: 17701,
            slug: "hien-son",
            status: "empty",
          },
          { label: "Mỹ Sơn", value: 17704, slug: "my-son", status: "empty" },
          { label: "Trù Sơn", value: 17707, slug: "tru-son", status: "empty" },
          { label: "Đại Sơn", value: 17710, slug: "dai-son", status: "empty" },
        ],
      },
      {
        label: "Huyện Thanh Chương",
        value: 428,
        slug: "huyen-thanh-chuong",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Dùng",
            value: 17713,
            slug: "thi-tran-dung",
            status: "empty",
          },
          { label: "Cát Văn", value: 17716, slug: "cat-van", status: "empty" },
          {
            label: "Minh Sơn",
            value: 17719,
            slug: "minh-son",
            status: "empty",
          },
          {
            label: "Hạnh Lâm",
            value: 17722,
            slug: "hanh-lam",
            status: "empty",
          },
          {
            label: "Thanh Sơn",
            value: 17723,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Phong Thịnh",
            value: 17728,
            slug: "phong-thinh",
            status: "empty",
          },
          {
            label: "Thanh Phong",
            value: 17731,
            slug: "thanh-phong",
            status: "empty",
          },
          {
            label: "Thanh Mỹ",
            value: 17734,
            slug: "thanh-my",
            status: "empty",
          },
          {
            label: "Thanh Tiên",
            value: 17737,
            slug: "thanh-tien",
            status: "empty",
          },
          {
            label: "Thanh Liên",
            value: 17743,
            slug: "thanh-lien",
            status: "empty",
          },
          {
            label: "Đại Đồng",
            value: 17749,
            slug: "dai-dong",
            status: "empty",
          },
          {
            label: "Thanh Ngọc",
            value: 17755,
            slug: "thanh-ngoc",
            status: "empty",
          },
          {
            label: "Thanh Hương",
            value: 17758,
            slug: "thanh-huong",
            status: "empty",
          },
          {
            label: "Ngọc Lâm",
            value: 17759,
            slug: "ngoc-lam",
            status: "empty",
          },
          {
            label: "Đồng Văn",
            value: 17764,
            slug: "dong-van",
            status: "empty",
          },
          {
            label: "Ngọc Sơn",
            value: 17767,
            slug: "ngoc-son",
            status: "empty",
          },
          {
            label: "Thanh Thịnh",
            value: 17770,
            slug: "thanh-thinh",
            status: "empty",
          },
          {
            label: "Thanh An",
            value: 17773,
            slug: "thanh-an",
            status: "empty",
          },
          {
            label: "Thanh Quả",
            value: 17776,
            slug: "thanh-qua",
            status: "empty",
          },
          {
            label: "Xuân Dương",
            value: 17779,
            slug: "xuan-duong",
            status: "empty",
          },
          {
            label: "Minh Tiến",
            value: 17785,
            slug: "minh-tien",
            status: "empty",
          },
          {
            label: "Kim Bảng",
            value: 17791,
            slug: "kim-bang",
            status: "empty",
          },
          {
            label: "Thanh Thủy",
            value: 17797,
            slug: "thanh-thuy",
            status: "empty",
          },
          {
            label: "Thanh Hà",
            value: 17806,
            slug: "thanh-ha",
            status: "empty",
          },
          {
            label: "Thanh Tùng",
            value: 17812,
            slug: "thanh-tung",
            status: "empty",
          },
          {
            label: "Thanh Lâm",
            value: 17815,
            slug: "thanh-lam",
            status: "empty",
          },
          {
            label: "Mai Giang",
            value: 17818,
            slug: "mai-giang",
            status: "empty",
          },
          {
            label: "Thanh Xuân",
            value: 17821,
            slug: "thanh-xuan",
            status: "empty",
          },
          {
            label: "Thanh Đức",
            value: 17824,
            slug: "thanh-duc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nghi Lộc",
        value: 429,
        slug: "huyen-nghi-loc",
        status: "empty",
        ward: [
          {
            label: "Quán Hành",
            value: 17827,
            slug: "quan-hanh",
            status: "empty",
          },
          {
            label: "Nghi Văn",
            value: 17830,
            slug: "nghi-van",
            status: "empty",
          },
          {
            label: "Nghi Yên",
            value: 17833,
            slug: "nghi-yen",
            status: "empty",
          },
          {
            label: "Nghi Tiến",
            value: 17836,
            slug: "nghi-tien",
            status: "empty",
          },
          {
            label: "Nghi Hưng",
            value: 17839,
            slug: "nghi-hung",
            status: "empty",
          },
          {
            label: "Nghi Đồng",
            value: 17842,
            slug: "nghi-dong",
            status: "empty",
          },
          {
            label: "Nghi Thiết",
            value: 17845,
            slug: "nghi-thiet",
            status: "empty",
          },
          {
            label: "Nghi Lâm",
            value: 17848,
            slug: "nghi-lam",
            status: "empty",
          },
          {
            label: "Nghi Quang",
            value: 17851,
            slug: "nghi-quang",
            status: "empty",
          },
          {
            label: "Nghi Kiều",
            value: 17854,
            slug: "nghi-kieu",
            status: "empty",
          },
          { label: "Nghi Mỹ", value: 17857, slug: "nghi-my", status: "empty" },
          {
            label: "Nghi Phương",
            value: 17860,
            slug: "nghi-phuong",
            status: "empty",
          },
          {
            label: "Nghi Thuận",
            value: 17863,
            slug: "nghi-thuan",
            status: "empty",
          },
          {
            label: "Nghi Long",
            value: 17866,
            slug: "nghi-long",
            status: "empty",
          },
          { label: "Nghi Xá", value: 17869, slug: "nghi-xa", status: "empty" },
          {
            label: "Khánh Hợp",
            value: 17878,
            slug: "khanh-hop",
            status: "empty",
          },
          {
            label: "Nghi Công Bắc",
            value: 17884,
            slug: "nghi-cong-bac",
            status: "empty",
          },
          {
            label: "Nghi Công Nam",
            value: 17887,
            slug: "nghi-cong-nam",
            status: "empty",
          },
          {
            label: "Nghi Thạch",
            value: 17890,
            slug: "nghi-thach",
            status: "empty",
          },
          {
            label: "Nghi Trung",
            value: 17893,
            slug: "nghi-trung",
            status: "empty",
          },
          {
            label: "Thịnh Trường",
            value: 17896,
            slug: "thinh-truong",
            status: "empty",
          },
          {
            label: "Diên Hoa",
            value: 17899,
            slug: "dien-hoa",
            status: "empty",
          },
          {
            label: "Nghi Vạn",
            value: 17911,
            slug: "nghi-van",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nam Đàn",
        value: 430,
        slug: "huyen-nam-dan",
        status: "empty",
        ward: [
          {
            label: "Nam Hưng",
            value: 17932,
            slug: "nam-hung",
            status: "empty",
          },
          {
            label: "Nghĩa Thái",
            value: 17935,
            slug: "nghia-thai",
            status: "empty",
          },
          {
            label: "Nam Thanh",
            value: 17938,
            slug: "nam-thanh",
            status: "empty",
          },
          { label: "Nam Anh", value: 17941, slug: "nam-anh", status: "empty" },
          {
            label: "Nam Xuân",
            value: 17944,
            slug: "nam-xuan",
            status: "empty",
          },
          { label: "Nam Đàn", value: 17950, slug: "nam-dan", status: "empty" },
          {
            label: "Nam Lĩnh",
            value: 17953,
            slug: "nam-linh",
            status: "empty",
          },
          {
            label: "Nam Giang",
            value: 17956,
            slug: "nam-giang",
            status: "empty",
          },
          {
            label: "Xuân Hòa",
            value: 17959,
            slug: "xuan-hoa",
            status: "empty",
          },
          {
            label: "Hùng Tiến",
            value: 17962,
            slug: "hung-tien",
            status: "empty",
          },
          {
            label: "Thượng Tân Lộc",
            value: 17968,
            slug: "thuong-tan-loc",
            status: "empty",
          },
          {
            label: "Kim Liên",
            value: 17971,
            slug: "kim-lien",
            status: "empty",
          },
          {
            label: "Xuân Hồng",
            value: 17980,
            slug: "xuan-hong",
            status: "empty",
          },
          { label: "Nam Cát", value: 17983, slug: "nam-cat", status: "empty" },
          {
            label: "Khánh Sơn",
            value: 17986,
            slug: "khanh-son",
            status: "empty",
          },
          {
            label: "Trung Phúc Cường",
            value: 17989,
            slug: "trung-phuc-cuong",
            status: "empty",
          },
          { label: "Nam Kim", value: 17998, slug: "nam-kim", status: "empty" },
        ],
      },
      {
        label: "Huyện Hưng Nguyên",
        value: 431,
        slug: "huyen-hung-nguyen",
        status: "empty",
        ward: [
          {
            label: "Hưng Nguyên",
            value: 18001,
            slug: "hung-nguyen",
            status: "empty",
          },
          {
            label: "Hưng Trung",
            value: 18004,
            slug: "hung-trung",
            status: "empty",
          },
          {
            label: "Hưng Yên",
            value: 18007,
            slug: "hung-yen",
            status: "empty",
          },
          {
            label: "Hưng Yên Bắc",
            value: 18008,
            slug: "hung-yen-bac",
            status: "empty",
          },
          {
            label: "Hưng Tây",
            value: 18010,
            slug: "hung-tay",
            status: "empty",
          },
          {
            label: "Hưng Đạo",
            value: 18016,
            slug: "hung-dao",
            status: "empty",
          },
          {
            label: "Thịnh Mỹ",
            value: 18022,
            slug: "thinh-my",
            status: "empty",
          },
          {
            label: "Hưng Lĩnh",
            value: 18025,
            slug: "hung-linh",
            status: "empty",
          },
          {
            label: "Thông Tân",
            value: 18028,
            slug: "thong-tan",
            status: "empty",
          },
          {
            label: "Hưng Nghĩa",
            value: 18037,
            slug: "hung-nghia",
            status: "empty",
          },
          {
            label: "Phúc Lợi",
            value: 18040,
            slug: "phuc-loi",
            status: "empty",
          },
          { label: "Long Xá", value: 18043, slug: "long-xa", status: "empty" },
          {
            label: "Châu Nhân",
            value: 18052,
            slug: "chau-nhan",
            status: "empty",
          },
          {
            label: "Xuân Lam",
            value: 18055,
            slug: "xuan-lam",
            status: "empty",
          },
          {
            label: "Hưng Thành",
            value: 18064,
            slug: "hung-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Hoàng Mai",
        value: 432,
        slug: "thi-xa-hoang-mai",
        status: "empty",
        ward: [
          {
            label: "Quỳnh Vinh",
            value: 17104,
            slug: "quynh-vinh",
            status: "empty",
          },
          {
            label: "Quỳnh Lộc",
            value: 17107,
            slug: "quynh-loc",
            status: "empty",
          },
          {
            label: "Quỳnh Thiện",
            value: 17110,
            slug: "quynh-thien",
            status: "empty",
          },
          {
            label: "Quỳnh Lập",
            value: 17113,
            slug: "quynh-lap",
            status: "empty",
          },
          {
            label: "Quỳnh Trang",
            value: 17116,
            slug: "quynh-trang",
            status: "empty",
          },
          {
            label: "Mai Hùng",
            value: 17125,
            slug: "mai-hung",
            status: "empty",
          },
          {
            label: "Quỳnh Dị",
            value: 17128,
            slug: "quynh-di",
            status: "empty",
          },
          {
            label: "Quỳnh Xuân",
            value: 17131,
            slug: "quynh-xuan",
            status: "empty",
          },
          {
            label: "Quỳnh Phương",
            value: 17134,
            slug: "quynh-phuong",
            status: "empty",
          },
          {
            label: "Quỳnh Liên",
            value: 17137,
            slug: "quynh-lien",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Hà Tĩnh",
    value: 42,
    slug: "ha-tinh",
    status: "filled",
    district: [
      {
        label: "Thành phố Hà Tĩnh",
        value: 436,
        slug: "thanh-pho-ha-tinh",
        status: "empty",
        ward: [
          { label: "Nam Hà", value: 18073, slug: "nam-ha", status: "empty" },
          { label: "Bắc Hà", value: 18077, slug: "bac-ha", status: "empty" },
          {
            label: "Tân Giang",
            value: 18079,
            slug: "tan-giang",
            status: "empty",
          },
          { label: "Đại Nài", value: 18082, slug: "dai-nai", status: "empty" },
          {
            label: "Hà Huy Tập",
            value: 18085,
            slug: "ha-huy-tap",
            status: "empty",
          },
          {
            label: "Thạch Trung",
            value: 18088,
            slug: "thach-trung",
            status: "empty",
          },
          {
            label: "Thạch Quý",
            value: 18091,
            slug: "thach-quy",
            status: "empty",
          },
          {
            label: "Trần Phú",
            value: 18094,
            slug: "tran-phu",
            status: "empty",
          },
          { label: "Văn Yên", value: 18097, slug: "van-yen", status: "empty" },
          {
            label: "Thạch Hạ",
            value: 18100,
            slug: "thach-ha",
            status: "empty",
          },
          {
            label: "Đồng Môn",
            value: 18103,
            slug: "dong-mon",
            status: "empty",
          },
          {
            label: "Thạch Hưng",
            value: 18109,
            slug: "thach-hung",
            status: "empty",
          },
          {
            label: "Thạch Bình",
            value: 18112,
            slug: "thach-binh",
            status: "empty",
          },
          {
            label: "Thạch Hải",
            value: 18571,
            slug: "thach-hai",
            status: "empty",
          },
          {
            label: "Đỉnh Bàn",
            value: 18595,
            slug: "dinh-ban",
            status: "empty",
          },
          { label: "Hộ Độ", value: 18598, slug: "ho-do", status: "empty" },
          {
            label: "Thạch Khê",
            value: 18604,
            slug: "thach-khe",
            status: "empty",
          },
          {
            label: "Thạch Trị",
            value: 18619,
            slug: "thach-tri",
            status: "empty",
          },
          {
            label: "Thạch Lạc",
            value: 18622,
            slug: "thach-lac",
            status: "empty",
          },
          {
            label: "Tượng Sơn",
            value: 18628,
            slug: "tuong-son",
            status: "empty",
          },
          {
            label: "Thạch Văn",
            value: 18631,
            slug: "thach-van",
            status: "empty",
          },
          {
            label: "Thạch Thắng",
            value: 18637,
            slug: "thach-thang",
            status: "empty",
          },
          {
            label: "Thạch Đài",
            value: 18643,
            slug: "thach-dai",
            status: "empty",
          },
          {
            label: "Thạch Hội",
            value: 18649,
            slug: "thach-hoi",
            status: "empty",
          },
          {
            label: "Tân Lâm Hương",
            value: 18652,
            slug: "tan-lam-huong",
            status: "empty",
          },
          {
            label: "Cẩm Bình",
            value: 18685,
            slug: "cam-binh",
            status: "empty",
          },
          {
            label: "Cẩm Vịnh",
            value: 18691,
            slug: "cam-vinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Hồng Lĩnh",
        value: 437,
        slug: "thi-xa-hong-linh",
        status: "empty",
        ward: [
          {
            label: "Bắc Hồng",
            value: 18115,
            slug: "bac-hong",
            status: "empty",
          },
          {
            label: "Nam Hồng",
            value: 18118,
            slug: "nam-hong",
            status: "empty",
          },
          {
            label: "Trung Lương",
            value: 18121,
            slug: "trung-luong",
            status: "empty",
          },
          {
            label: "Đức Thuận",
            value: 18124,
            slug: "duc-thuan",
            status: "empty",
          },
          {
            label: "Đậu Liêu",
            value: 18127,
            slug: "dau-lieu",
            status: "empty",
          },
          {
            label: "Thuận Lộc",
            value: 18130,
            slug: "thuan-loc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hương Sơn",
        value: 439,
        slug: "huyen-huong-son",
        status: "empty",
        ward: [
          {
            label: "Phố Châu",
            value: 18133,
            slug: "pho-chau",
            status: "empty",
          },
          { label: "Tây Sơn", value: 18136, slug: "tay-son", status: "empty" },
          {
            label: "Sơn Hồng",
            value: 18139,
            slug: "son-hong",
            status: "empty",
          },
          {
            label: "Sơn Tiến",
            value: 18142,
            slug: "son-tien",
            status: "empty",
          },
          { label: "Sơn Lâm", value: 18145, slug: "son-lam", status: "empty" },
          { label: "Sơn Lễ", value: 18148, slug: "son-le", status: "empty" },
          {
            label: "Sơn Giang",
            value: 18157,
            slug: "son-giang",
            status: "empty",
          },
          {
            label: "Sơn Lĩnh",
            value: 18160,
            slug: "son-linh",
            status: "empty",
          },
          {
            label: "An Hòa Thịnh",
            value: 18163,
            slug: "an-hoa-thinh",
            status: "empty",
          },
          { label: "Sơn Tây", value: 18172, slug: "son-tay", status: "empty" },
          {
            label: "Sơn Ninh",
            value: 18175,
            slug: "son-ninh",
            status: "empty",
          },
          {
            label: "Châu Bình",
            value: 18178,
            slug: "chau-binh",
            status: "empty",
          },
          {
            label: "Tân Mỹ Hà",
            value: 18181,
            slug: "tan-my-ha",
            status: "empty",
          },
          {
            label: "Quang Diệm",
            value: 18184,
            slug: "quang-diem",
            status: "empty",
          },
          {
            label: "Sơn Trung",
            value: 18187,
            slug: "son-trung",
            status: "empty",
          },
          {
            label: "Sơn Bằng",
            value: 18190,
            slug: "son-bang",
            status: "empty",
          },
          {
            label: "Sơn Kim 1",
            value: 18196,
            slug: "son-kim-1",
            status: "empty",
          },
          {
            label: "Sơn Kim 2",
            value: 18199,
            slug: "son-kim-2",
            status: "empty",
          },
          { label: "Mỹ Long", value: 18202, slug: "my-long", status: "empty" },
          { label: "Kim Hoa", value: 18211, slug: "kim-hoa", status: "empty" },
          { label: "Sơn Phú", value: 18217, slug: "son-phu", status: "empty" },
          {
            label: "Hàm Trường",
            value: 18223,
            slug: "ham-truong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đức Thọ",
        value: 440,
        slug: "huyen-duc-tho",
        status: "empty",
        ward: [
          { label: "Đức Thọ", value: 18229, slug: "duc-tho", status: "empty" },
          {
            label: "Quang Vĩnh",
            value: 18235,
            slug: "quang-vinh",
            status: "empty",
          },
          {
            label: "Tùng Châu",
            value: 18241,
            slug: "tung-chau",
            status: "empty",
          },
          {
            label: "Trường Sơn",
            value: 18244,
            slug: "truong-son",
            status: "empty",
          },
          {
            label: "Liên Minh",
            value: 18247,
            slug: "lien-minh",
            status: "empty",
          },
          { label: "Yên Hồ", value: 18253, slug: "yen-ho", status: "empty" },
          {
            label: "Tùng Ảnh",
            value: 18259,
            slug: "tung-anh",
            status: "empty",
          },
          {
            label: "Bùi La Nhân",
            value: 18262,
            slug: "bui-la-nhan",
            status: "empty",
          },
          {
            label: "Thanh Bình Thịnh",
            value: 18274,
            slug: "thanh-binh-thinh",
            status: "empty",
          },
          {
            label: "Lâm Trung Thủy",
            value: 18277,
            slug: "lam-trung-thuy",
            status: "empty",
          },
          { label: "Hòa Lạc", value: 18280, slug: "hoa-lac", status: "empty" },
          { label: "Tân Dân", value: 18283, slug: "tan-dan", status: "empty" },
          { label: "An Dũng", value: 18298, slug: "an-dung", status: "empty" },
          {
            label: "Đức Đồng",
            value: 18304,
            slug: "duc-dong",
            status: "empty",
          },
          {
            label: "Đức Lạng",
            value: 18307,
            slug: "duc-lang",
            status: "empty",
          },
          {
            label: "Tân Hương",
            value: 18310,
            slug: "tan-huong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vũ Quang",
        value: 441,
        slug: "huyen-vu-quang",
        status: "empty",
        ward: [
          {
            label: "Vũ Quang",
            value: 18313,
            slug: "vu-quang",
            status: "empty",
          },
          { label: "Ân Phú", value: 18316, slug: "an-phu", status: "empty" },
          {
            label: "Đức Giang",
            value: 18319,
            slug: "duc-giang",
            status: "empty",
          },
          {
            label: "Đức Lĩnh",
            value: 18322,
            slug: "duc-linh",
            status: "empty",
          },
          {
            label: "Thọ Điền",
            value: 18325,
            slug: "tho-dien",
            status: "empty",
          },
          {
            label: "Đức Hương",
            value: 18328,
            slug: "duc-huong",
            status: "empty",
          },
          {
            label: "Đức Bồng",
            value: 18331,
            slug: "duc-bong",
            status: "empty",
          },
          {
            label: "Đức Liên",
            value: 18334,
            slug: "duc-lien",
            status: "empty",
          },
          {
            label: "Hương Minh",
            value: 18340,
            slug: "huong-minh",
            status: "empty",
          },
          {
            label: "Quang Thọ",
            value: 18343,
            slug: "quang-tho",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nghi Xuân",
        value: 442,
        slug: "huyen-nghi-xuan",
        status: "filled",
        ward: [
          { label: "Xuân An", value: 18352, slug: "xuan-an", status: "empty" },
          {
            label: "Xuân Hội",
            value: 18355,
            slug: "xuan-hoi",
            status: "empty",
          },
          {
            label: "Đan Trường",
            value: 18358,
            slug: "dan-truong",
            status: "empty",
          },
          {
            label: "Xuân Phổ",
            value: 18364,
            slug: "xuan-pho",
            status: "empty",
          },
          {
            label: "Xuân Hải",
            value: 18367,
            slug: "xuan-hai",
            status: "empty",
          },
          {
            label: "Xuân Giang",
            value: 18370,
            slug: "xuan-giang",
            status: "filled",
          },
          {
            label: "Tiên Điền",
            value: 18373,
            slug: "tien-dien",
            status: "empty",
          },
          {
            label: "Xuân Yên",
            value: 18376,
            slug: "xuan-yen",
            status: "empty",
          },
          { label: "Xuân Mỹ", value: 18379, slug: "xuan-my", status: "empty" },
          {
            label: "Xuân Thành",
            value: 18382,
            slug: "xuan-thanh",
            status: "empty",
          },
          {
            label: "Xuân Viên",
            value: 18385,
            slug: "xuan-vien",
            status: "empty",
          },
          {
            label: "Xuân Hồng",
            value: 18388,
            slug: "xuan-hong",
            status: "empty",
          },
          { label: "Cỗ Đạm", value: 18391, slug: "co-dam", status: "empty" },
          {
            label: "Xuân Liên",
            value: 18394,
            slug: "xuan-lien",
            status: "empty",
          },
          {
            label: "Xuân Lĩnh",
            value: 18397,
            slug: "xuan-linh",
            status: "empty",
          },
          {
            label: "Xuân Lam",
            value: 18400,
            slug: "xuan-lam",
            status: "empty",
          },
          {
            label: "Cương Gián",
            value: 18403,
            slug: "cuong-gian",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Can Lộc",
        value: 443,
        slug: "huyen-can-loc",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Nghèn",
            value: 18406,
            slug: "thi-tran-nghen",
            status: "empty",
          },
          {
            label: "Thiên Lộc",
            value: 18415,
            slug: "thien-loc",
            status: "empty",
          },
          {
            label: "Thuần Thiện",
            value: 18418,
            slug: "thuan-thien",
            status: "empty",
          },
          {
            label: "Vượng Lộc",
            value: 18427,
            slug: "vuong-loc",
            status: "empty",
          },
          {
            label: "Thanh Lộc",
            value: 18433,
            slug: "thanh-loc",
            status: "empty",
          },
          {
            label: "Kim Song Trường",
            value: 18436,
            slug: "kim-song-truong",
            status: "empty",
          },
          {
            label: "Thường Nga",
            value: 18439,
            slug: "thuong-nga",
            status: "empty",
          },
          {
            label: "Tùng Lộc",
            value: 18445,
            slug: "tung-loc",
            status: "empty",
          },
          { label: "Phú Lộc", value: 18454, slug: "phu-loc", status: "empty" },
          {
            label: "Gia Hanh",
            value: 18463,
            slug: "gia-hanh",
            status: "empty",
          },
          {
            label: "Khánh Vĩnh Yên",
            value: 18466,
            slug: "khanh-vinh-yen",
            status: "empty",
          },
          {
            label: "Xuân Lộc",
            value: 18475,
            slug: "xuan-loc",
            status: "empty",
          },
          {
            label: "Thượng Lộc",
            value: 18478,
            slug: "thuong-loc",
            status: "empty",
          },
          {
            label: "Quang Lộc",
            value: 18481,
            slug: "quang-loc",
            status: "empty",
          },
          {
            label: "Đồng Lộc",
            value: 18484,
            slug: "dong-loc",
            status: "empty",
          },
          { label: "Mỹ Lộc", value: 18487, slug: "my-loc", status: "empty" },
          { label: "Sơn Lộc", value: 18490, slug: "son-loc", status: "empty" },
        ],
      },
      {
        label: "Huyện Hương Khê",
        value: 444,
        slug: "huyen-huong-khe",
        status: "empty",
        ward: [
          {
            label: "Hương Khê",
            value: 18496,
            slug: "huong-khe",
            status: "empty",
          },
          { label: "Điền Mỹ", value: 18499, slug: "dien-my", status: "empty" },
          { label: "Hà Linh", value: 18502, slug: "ha-linh", status: "empty" },
          {
            label: "Hương Thủy",
            value: 18505,
            slug: "huong-thuy",
            status: "empty",
          },
          { label: "Hòa Hải", value: 18508, slug: "hoa-hai", status: "empty" },
          {
            label: "Phúc Đồng",
            value: 18514,
            slug: "phuc-dong",
            status: "empty",
          },
          {
            label: "Hương Giang",
            value: 18517,
            slug: "huong-giang",
            status: "empty",
          },
          { label: "Lộc Yên", value: 18520, slug: "loc-yen", status: "empty" },
          {
            label: "Hương Bình",
            value: 18523,
            slug: "huong-binh",
            status: "empty",
          },
          {
            label: "Hương Long",
            value: 18526,
            slug: "huong-long",
            status: "empty",
          },
          { label: "Phú Gia", value: 18529, slug: "phu-gia", status: "empty" },
          { label: "Gia Phố", value: 18532, slug: "gia-pho", status: "empty" },
          {
            label: "Hương Đô",
            value: 18538,
            slug: "huong-do",
            status: "empty",
          },
          {
            label: "Hương Vĩnh",
            value: 18541,
            slug: "huong-vinh",
            status: "empty",
          },
          {
            label: "Hương Xuân",
            value: 18544,
            slug: "huong-xuan",
            status: "empty",
          },
          {
            label: "Phúc Trạch",
            value: 18547,
            slug: "phuc-trach",
            status: "empty",
          },
          {
            label: "Hương Trà",
            value: 18550,
            slug: "huong-tra",
            status: "empty",
          },
          {
            label: "Hương Trạch",
            value: 18553,
            slug: "huong-trach",
            status: "empty",
          },
          {
            label: "Hương Lâm",
            value: 18556,
            slug: "huong-lam",
            status: "empty",
          },
          {
            label: "Hương Liên",
            value: 18559,
            slug: "huong-lien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thạch Hà",
        value: 445,
        slug: "huyen-thach-ha",
        status: "empty",
        ward: [
          { label: "Tân Lộc", value: 18409, slug: "tan-loc", status: "empty" },
          {
            label: "Hồng Lộc",
            value: 18412,
            slug: "hong-loc",
            status: "empty",
          },
          {
            label: "Thịnh Lộc",
            value: 18421,
            slug: "thinh-loc",
            status: "empty",
          },
          { label: "Bình An", value: 18430, slug: "binh-an", status: "empty" },
          {
            label: "Bình Lộc",
            value: 18448,
            slug: "binh-loc",
            status: "empty",
          },
          { label: "Ích Hậu", value: 18457, slug: "ich-hau", status: "empty" },
          { label: "Phù Lưu", value: 18493, slug: "phu-luu", status: "empty" },
          {
            label: "Thạch Hà",
            value: 18562,
            slug: "thach-ha",
            status: "empty",
          },
          {
            label: "Ngọc Sơn",
            value: 18565,
            slug: "ngoc-son",
            status: "empty",
          },
          { label: "Lộc Hà", value: 18568, slug: "loc-ha", status: "empty" },
          {
            label: "Thạch Mỹ",
            value: 18577,
            slug: "thach-my",
            status: "empty",
          },
          {
            label: "Thạch Kim",
            value: 18580,
            slug: "thach-kim",
            status: "empty",
          },
          {
            label: "Thạch Châu",
            value: 18583,
            slug: "thach-chau",
            status: "empty",
          },
          {
            label: "Thạch Kênh",
            value: 18586,
            slug: "thach-kenh",
            status: "empty",
          },
          {
            label: "Thạch Sơn",
            value: 18589,
            slug: "thach-son",
            status: "empty",
          },
          {
            label: "Thạch Liên",
            value: 18592,
            slug: "thach-lien",
            status: "empty",
          },
          {
            label: "Việt Tiến",
            value: 18601,
            slug: "viet-tien",
            status: "empty",
          },
          {
            label: "Thạch Long",
            value: 18607,
            slug: "thach-long",
            status: "empty",
          },
          {
            label: "Thạch Ngọc",
            value: 18625,
            slug: "thach-ngoc",
            status: "empty",
          },
          {
            label: "Lưu Vĩnh Sơn",
            value: 18634,
            slug: "luu-vinh-son",
            status: "empty",
          },
          {
            label: "Thạch Xuân",
            value: 18658,
            slug: "thach-xuan",
            status: "empty",
          },
          {
            label: "Nam Điền",
            value: 18667,
            slug: "nam-dien",
            status: "empty",
          },
          { label: "Mai Phụ", value: 18670, slug: "mai-phu", status: "empty" },
        ],
      },
      {
        label: "Huyện Cẩm Xuyên",
        value: 446,
        slug: "huyen-cam-xuyen",
        status: "empty",
        ward: [
          {
            label: "Cẩm Xuyên",
            value: 18673,
            slug: "cam-xuyen",
            status: "empty",
          },
          {
            label: "Thiên Cầm",
            value: 18676,
            slug: "thien-cam",
            status: "empty",
          },
          { label: "Yên Hòa", value: 18679, slug: "yen-hoa", status: "empty" },
          {
            label: "Cẩm Dương",
            value: 18682,
            slug: "cam-duong",
            status: "empty",
          },
          {
            label: "Cẩm Thành",
            value: 18694,
            slug: "cam-thanh",
            status: "empty",
          },
          {
            label: "Cẩm Quang",
            value: 18697,
            slug: "cam-quang",
            status: "empty",
          },
          {
            label: "Cẩm Thạch",
            value: 18706,
            slug: "cam-thach",
            status: "empty",
          },
          {
            label: "Cẩm Nhượng",
            value: 18709,
            slug: "cam-nhuong",
            status: "empty",
          },
          {
            label: "Nam Phúc Thăng",
            value: 18712,
            slug: "nam-phuc-thang",
            status: "empty",
          },
          { label: "Cẩm Duệ", value: 18715, slug: "cam-due", status: "empty" },
          {
            label: "Cẩm Lĩnh",
            value: 18721,
            slug: "cam-linh",
            status: "empty",
          },
          {
            label: "Cẩm Quan",
            value: 18724,
            slug: "cam-quan",
            status: "empty",
          },
          { label: "Cẩm Hà", value: 18727, slug: "cam-ha", status: "empty" },
          { label: "Cẩm Lộc", value: 18730, slug: "cam-loc", status: "empty" },
          {
            label: "Cẩm Hưng",
            value: 18733,
            slug: "cam-hung",
            status: "empty",
          },
          {
            label: "Cẩm Thịnh",
            value: 18736,
            slug: "cam-thinh",
            status: "empty",
          },
          { label: "Cẩm Mỹ", value: 18739, slug: "cam-my", status: "empty" },
          {
            label: "Cẩm Trung",
            value: 18742,
            slug: "cam-trung",
            status: "empty",
          },
          { label: "Cẩm Sơn", value: 18745, slug: "cam-son", status: "empty" },
          { label: "Cẩm Lạc", value: 18748, slug: "cam-lac", status: "empty" },
          {
            label: "Cẩm Minh",
            value: 18751,
            slug: "cam-minh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Kỳ Anh",
        value: 447,
        slug: "huyen-ky-anh",
        status: "empty",
        ward: [
          { label: "Kỳ Xuân", value: 18757, slug: "ky-xuan", status: "empty" },
          { label: "Kỳ Bắc", value: 18760, slug: "ky-bac", status: "empty" },
          { label: "Kỳ Phú", value: 18763, slug: "ky-phu", status: "empty" },
          {
            label: "Kỳ Phong",
            value: 18766,
            slug: "ky-phong",
            status: "empty",
          },
          { label: "Kỳ Tiến", value: 18769, slug: "ky-tien", status: "empty" },
          {
            label: "Kỳ Giang",
            value: 18772,
            slug: "ky-giang",
            status: "empty",
          },
          { label: "Kỳ Đồng", value: 18775, slug: "ky-dong", status: "empty" },
          {
            label: "Kỳ Khang",
            value: 18778,
            slug: "ky-khang",
            status: "empty",
          },
          { label: "Kỳ Văn", value: 18784, slug: "ky-van", status: "empty" },
          {
            label: "Kỳ Trung",
            value: 18787,
            slug: "ky-trung",
            status: "empty",
          },
          { label: "Kỳ Thọ", value: 18790, slug: "ky-tho", status: "empty" },
          { label: "Kỳ Tây", value: 18793, slug: "ky-tay", status: "empty" },
          {
            label: "Kỳ Thượng",
            value: 18799,
            slug: "ky-thuong",
            status: "empty",
          },
          { label: "Kỳ Hải", value: 18802, slug: "ky-hai", status: "empty" },
          { label: "Kỳ Thư", value: 18805, slug: "ky-thu", status: "empty" },
          { label: "Kỳ Châu", value: 18811, slug: "ky-chau", status: "empty" },
          { label: "Kỳ Tân", value: 18814, slug: "ky-tan", status: "empty" },
          { label: "Lâm Hợp", value: 18838, slug: "lam-hop", status: "empty" },
          { label: "Kỳ Sơn", value: 18844, slug: "ky-son", status: "empty" },
          { label: "Kỳ Lạc", value: 18850, slug: "ky-lac", status: "empty" },
        ],
      },
      {
        label: "Huyện Lộc Hà",
        value: 448,
        slug: "huyen-loc-ha",
        status: "empty",
        ward: [
          { label: "Lộc Hà", value: 100700, slug: "loc-ha", status: "empty" },
          { label: "An Lộc", value: 100701, slug: "an-loc", status: "empty" },
          {
            label: "Bình Lộc",
            value: 100702,
            slug: "binh-loc",
            status: "empty",
          },
          { label: "Hộ Độ", value: 100703, slug: "ho-do", status: "empty" },
          {
            label: "Hồng Lộc",
            value: 100704,
            slug: "hong-loc",
            status: "empty",
          },
          { label: "Ích Hậu", value: 100705, slug: "ich-hau", status: "empty" },
          { label: "Phù Lưu", value: 100706, slug: "phu-luu", status: "empty" },
          {
            label: "Thạch Mỹ",
            value: 100707,
            slug: "thach-my",
            status: "empty",
          },
          { label: "Mai Phụ", value: 100708, slug: "mai-phu", status: "empty" },
          { label: "Bình An", value: 100709, slug: "binh-an", status: "empty" },
          {
            label: "Thạch Kim",
            value: 100710,
            slug: "thach-kim",
            status: "empty",
          },
          {
            label: "Thạch Châu",
            value: 100711,
            slug: "thach-chau",
            status: "empty",
          },
          {
            label: "Thịnh Lộc",
            value: 100712,
            slug: "thinh-loc",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Kỳ Anh",
        value: 449,
        slug: "thi-xa-ky-anh",
        status: "filled",
        ward: [
          {
            label: "Hưng Trí",
            value: 18754,
            slug: "hung-tri",
            status: "filled",
          },
          { label: "Kỳ Ninh", value: 18781, slug: "ky-ninh", status: "empty" },
          { label: "Kỳ Lợi", value: 18796, slug: "ky-loi", status: "empty" },
          { label: "Kỳ Hà", value: 18808, slug: "ky-ha", status: "empty" },
          {
            label: "Kỳ Trinh",
            value: 18820,
            slug: "ky-trinh",
            status: "empty",
          },
          {
            label: "Kỳ Thịnh",
            value: 18823,
            slug: "ky-thinh",
            status: "empty",
          },
          { label: "Kỳ Hoa", value: 18829, slug: "ky-hoa", status: "empty" },
          {
            label: "Kỳ Phương",
            value: 18832,
            slug: "ky-phuong",
            status: "empty",
          },
          { label: "Kỳ Long", value: 18835, slug: "ky-long", status: "empty" },
          { label: "Kỳ Liên", value: 18841, slug: "ky-lien", status: "empty" },
          { label: "Kỳ Nam", value: 18847, slug: "ky-nam", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Quảng Bình",
    value: 44,
    slug: "quang-binh",
    status: "filled",
    district: [
      {
        label: "Thành Phố Đồng Hới",
        value: 450,
        slug: "thanh-pho-dong-hoi",
        status: "filled",
        ward: [
          {
            label: "Hải Thành",
            value: 18853,
            slug: "hai-thanh",
            status: "empty",
          },
          {
            label: "Đồng Phú",
            value: 18856,
            slug: "dong-phu",
            status: "empty",
          },
          { label: "Bắc Lý", value: 18859, slug: "bac-ly", status: "empty" },
          { label: "Nam Lý", value: 18865, slug: "nam-ly", status: "filled" },
          {
            label: "Đồng Hải",
            value: 18868,
            slug: "dong-hai",
            status: "empty",
          },
          {
            label: "Đồng Sơn",
            value: 18871,
            slug: "dong-son",
            status: "empty",
          },
          { label: "Phú Hải", value: 18874, slug: "phu-hai", status: "empty" },
          {
            label: "Bắc Nghĩa",
            value: 18877,
            slug: "bac-nghia",
            status: "empty",
          },
          {
            label: "Đức Ninh Đông",
            value: 18880,
            slug: "duc-ninh-dong",
            status: "empty",
          },
          {
            label: "Quang Phú",
            value: 18883,
            slug: "quang-phu",
            status: "empty",
          },
          {
            label: "Lộc Ninh",
            value: 18886,
            slug: "loc-ninh",
            status: "empty",
          },
          {
            label: "Bảo Ninh",
            value: 18889,
            slug: "bao-ninh",
            status: "empty",
          },
          {
            label: "Nghĩa Ninh",
            value: 18892,
            slug: "nghia-ninh",
            status: "empty",
          },
          {
            label: "Thuận Đức",
            value: 18895,
            slug: "thuan-duc",
            status: "empty",
          },
          {
            label: "Đức Ninh",
            value: 18898,
            slug: "duc-ninh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Minh Hóa",
        value: 452,
        slug: "huyen-minh-hoa",
        status: "empty",
        ward: [
          { label: "Quy Đạt", value: 18901, slug: "quy-dat", status: "empty" },
          { label: "Dân Hóa", value: 18904, slug: "dan-hoa", status: "empty" },
          {
            label: "Trọng Hóa",
            value: 18907,
            slug: "trong-hoa",
            status: "empty",
          },
          {
            label: "Hồng Hóa",
            value: 18913,
            slug: "hong-hoa",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 18919,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Hóa Hợp", value: 18922, slug: "hoa-hop", status: "empty" },
          {
            label: "Xuân Hóa",
            value: 18925,
            slug: "xuan-hoa",
            status: "empty",
          },
          { label: "Yên Hóa", value: 18928, slug: "yen-hoa", status: "empty" },
          {
            label: "Minh Hóa",
            value: 18931,
            slug: "minh-hoa",
            status: "empty",
          },
          { label: "Tân Hóa", value: 18934, slug: "tan-hoa", status: "empty" },
          { label: "Hóa Sơn", value: 18937, slug: "hoa-son", status: "empty" },
          {
            label: "Trung Hóa",
            value: 18943,
            slug: "trung-hoa",
            status: "empty",
          },
          {
            label: "Thượng Hóa",
            value: 18946,
            slug: "thuong-hoa",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tuyên Hóa",
        value: 453,
        slug: "huyen-tuyen-hoa",
        status: "empty",
        ward: [
          { label: "Đồng Lê", value: 18949, slug: "dong-le", status: "empty" },
          {
            label: "Hương Hóa",
            value: 18952,
            slug: "huong-hoa",
            status: "empty",
          },
          { label: "Kim Hóa", value: 18955, slug: "kim-hoa", status: "empty" },
          {
            label: "Thanh Hóa",
            value: 18958,
            slug: "thanh-hoa",
            status: "empty",
          },
          {
            label: "Thanh Thạch",
            value: 18961,
            slug: "thanh-thach",
            status: "empty",
          },
          {
            label: "Thuận Hóa",
            value: 18964,
            slug: "thuan-hoa",
            status: "empty",
          },
          { label: "Lâm Hóa", value: 18967, slug: "lam-hoa", status: "empty" },
          { label: "Lê Hóa", value: 18970, slug: "le-hoa", status: "empty" },
          { label: "Sơn Hóa", value: 18973, slug: "son-hoa", status: "empty" },
          {
            label: "Đồng Hóa",
            value: 18976,
            slug: "dong-hoa",
            status: "empty",
          },
          { label: "Ngư Hóa", value: 18979, slug: "ngu-hoa", status: "empty" },
          {
            label: "Thạch Hóa",
            value: 18985,
            slug: "thach-hoa",
            status: "empty",
          },
          { label: "Đức Hóa", value: 18988, slug: "duc-hoa", status: "empty" },
          {
            label: "Phong Hóa",
            value: 18991,
            slug: "phong-hoa",
            status: "empty",
          },
          { label: "Mai Hóa", value: 18994, slug: "mai-hoa", status: "empty" },
          {
            label: "Tiến Hóa",
            value: 18997,
            slug: "tien-hoa",
            status: "empty",
          },
          {
            label: "Châu Hóa",
            value: 19000,
            slug: "chau-hoa",
            status: "empty",
          },
          {
            label: "Cao Quảng",
            value: 19003,
            slug: "cao-quang",
            status: "empty",
          },
          { label: "Văn Hóa", value: 19006, slug: "van-hoa", status: "empty" },
        ],
      },
      {
        label: "Huyện Quảng Trạch",
        value: 454,
        slug: "huyen-quang-trach",
        status: "empty",
        ward: [
          {
            label: "Quảng Hợp",
            value: 19012,
            slug: "quang-hop",
            status: "empty",
          },
          {
            label: "Quảng Kim",
            value: 19015,
            slug: "quang-kim",
            status: "empty",
          },
          {
            label: "Quảng Đông",
            value: 19018,
            slug: "quang-dong",
            status: "empty",
          },
          {
            label: "Quảng Phú",
            value: 19021,
            slug: "quang-phu",
            status: "empty",
          },
          {
            label: "Quảng Châu",
            value: 19024,
            slug: "quang-chau",
            status: "empty",
          },
          {
            label: "Quảng Thạch",
            value: 19027,
            slug: "quang-thach",
            status: "empty",
          },
          {
            label: "Quảng Lưu",
            value: 19030,
            slug: "quang-luu",
            status: "empty",
          },
          {
            label: "Quảng Tùng",
            value: 19033,
            slug: "quang-tung",
            status: "empty",
          },
          {
            label: "Cảnh Dương",
            value: 19036,
            slug: "canh-duong",
            status: "empty",
          },
          {
            label: "Quảng Tiến",
            value: 19039,
            slug: "quang-tien",
            status: "empty",
          },
          {
            label: "Quảng Hưng",
            value: 19042,
            slug: "quang-hung",
            status: "empty",
          },
          {
            label: "Quảng Xuân",
            value: 19045,
            slug: "quang-xuan",
            status: "empty",
          },
          {
            label: "Liên Trường",
            value: 19051,
            slug: "lien-truong",
            status: "empty",
          },
          {
            label: "Quảng Phương",
            value: 19057,
            slug: "quang-phuong",
            status: "empty",
          },
          {
            label: "Phù Cảnh",
            value: 19063,
            slug: "phu-canh",
            status: "empty",
          },
          {
            label: "Quảng Thanh",
            value: 19072,
            slug: "quang-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bố Trạch",
        value: 455,
        slug: "huyen-bo-trach",
        status: "empty",
        ward: [
          {
            label: "Hoàn Lão",
            value: 19111,
            slug: "hoan-lao",
            status: "empty",
          },
          {
            label: "NT Việt Trung",
            value: 19114,
            slug: "nt-viet-trung",
            status: "empty",
          },
          {
            label: "Xuân Trạch",
            value: 19117,
            slug: "xuan-trach",
            status: "empty",
          },
          { label: "Hạ Mỹ", value: 19123, slug: "ha-my", status: "empty" },
          {
            label: "Bắc Trạch",
            value: 19126,
            slug: "bac-trach",
            status: "empty",
          },
          {
            label: "Lâm Trạch",
            value: 19129,
            slug: "lam-trach",
            status: "empty",
          },
          {
            label: "Thanh Trạch",
            value: 19132,
            slug: "thanh-trach",
            status: "empty",
          },
          {
            label: "Liên Trạch",
            value: 19135,
            slug: "lien-trach",
            status: "empty",
          },
          {
            label: "Phúc Trạch",
            value: 19138,
            slug: "phuc-trach",
            status: "empty",
          },
          { label: "Cự Nẫm", value: 19141, slug: "cu-nam", status: "empty" },
          { label: "Hải Phú", value: 19144, slug: "hai-phu", status: "empty" },
          {
            label: "Thượng Trạch",
            value: 19147,
            slug: "thuong-trach",
            status: "empty",
          },
          { label: "Sơn Lộc", value: 19150, slug: "son-loc", status: "empty" },
          {
            label: "Hưng Trạch",
            value: 19156,
            slug: "hung-trach",
            status: "empty",
          },
          {
            label: "Đồng Trạch",
            value: 19159,
            slug: "dong-trach",
            status: "empty",
          },
          {
            label: "Đức Trạch",
            value: 19162,
            slug: "duc-trach",
            status: "empty",
          },
          {
            label: "Phong Nha",
            value: 19165,
            slug: "phong-nha",
            status: "empty",
          },
          {
            label: "Vạn Trạch",
            value: 19168,
            slug: "van-trach",
            status: "empty",
          },
          {
            label: "Phú Định",
            value: 19174,
            slug: "phu-dinh",
            status: "empty",
          },
          {
            label: "Trung Trạch",
            value: 19177,
            slug: "trung-trach",
            status: "empty",
          },
          {
            label: "Tây Trạch",
            value: 19180,
            slug: "tay-trach",
            status: "empty",
          },
          {
            label: "Hòa Trạch",
            value: 19183,
            slug: "hoa-trach",
            status: "empty",
          },
          {
            label: "Đại Trạch",
            value: 19186,
            slug: "dai-trach",
            status: "empty",
          },
          {
            label: "Nhân Trạch",
            value: 19189,
            slug: "nhan-trach",
            status: "empty",
          },
          {
            label: "Tân Trạch",
            value: 19192,
            slug: "tan-trach",
            status: "empty",
          },
          { label: "Lý Nam", value: 19198, slug: "ly-nam", status: "empty" },
        ],
      },
      {
        label: "Huyện Quảng Ninh",
        value: 456,
        slug: "huyen-quang-ninh",
        status: "empty",
        ward: [
          {
            label: "Trường Sơn",
            value: 19204,
            slug: "truong-son",
            status: "empty",
          },
          {
            label: "Quán Hàu",
            value: 19207,
            slug: "quan-hau",
            status: "empty",
          },
          {
            label: "Vĩnh Ninh",
            value: 19210,
            slug: "vinh-ninh",
            status: "empty",
          },
          { label: "Võ Ninh", value: 19213, slug: "vo-ninh", status: "empty" },
          {
            label: "Hải Ninh",
            value: 19216,
            slug: "hai-ninh",
            status: "empty",
          },
          {
            label: "Hàm Ninh",
            value: 19219,
            slug: "ham-ninh",
            status: "empty",
          },
          {
            label: "Duy Ninh",
            value: 19222,
            slug: "duy-ninh",
            status: "empty",
          },
          {
            label: "Gia Ninh",
            value: 19225,
            slug: "gia-ninh",
            status: "empty",
          },
          {
            label: "Trường Xuân",
            value: 19228,
            slug: "truong-xuan",
            status: "empty",
          },
          {
            label: "Hiền Ninh",
            value: 19231,
            slug: "hien-ninh",
            status: "empty",
          },
          {
            label: "Tân Ninh",
            value: 19234,
            slug: "tan-ninh",
            status: "empty",
          },
          {
            label: "Xuân Ninh",
            value: 19237,
            slug: "xuan-ninh",
            status: "empty",
          },
          { label: "An Ninh", value: 19240, slug: "an-ninh", status: "empty" },
          {
            label: "Vạn Ninh",
            value: 19243,
            slug: "van-ninh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lệ Thủy",
        value: 457,
        slug: "huyen-le-thuy",
        status: "empty",
        ward: [
          {
            label: "NT Lệ Ninh",
            value: 19246,
            slug: "nt-le-ninh",
            status: "empty",
          },
          {
            label: "Kiến Giang",
            value: 19249,
            slug: "kien-giang",
            status: "empty",
          },
          {
            label: "Hồng Thủy",
            value: 19252,
            slug: "hong-thuy",
            status: "empty",
          },
          {
            label: "Ngư Thủy Bắc",
            value: 19255,
            slug: "ngu-thuy-bac",
            status: "empty",
          },
          {
            label: "Hoa Thủy",
            value: 19258,
            slug: "hoa-thuy",
            status: "empty",
          },
          {
            label: "Thanh Thủy",
            value: 19261,
            slug: "thanh-thuy",
            status: "empty",
          },
          { label: "An Thủy", value: 19264, slug: "an-thuy", status: "empty" },
          {
            label: "Phong Thủy",
            value: 19267,
            slug: "phong-thuy",
            status: "empty",
          },
          {
            label: "Cam Thủy",
            value: 19270,
            slug: "cam-thuy",
            status: "empty",
          },
          {
            label: "Ngân Thủy",
            value: 19273,
            slug: "ngan-thuy",
            status: "empty",
          },
          {
            label: "Sơn Thủy",
            value: 19276,
            slug: "son-thuy",
            status: "empty",
          },
          {
            label: "Lộc Thủy",
            value: 19279,
            slug: "loc-thuy",
            status: "empty",
          },
          {
            label: "Liên Thủy",
            value: 19285,
            slug: "lien-thuy",
            status: "empty",
          },
          {
            label: "Hưng Thủy",
            value: 19288,
            slug: "hung-thuy",
            status: "empty",
          },
          {
            label: "Dương Thủy",
            value: 19291,
            slug: "duong-thuy",
            status: "empty",
          },
          {
            label: "Tân Thủy",
            value: 19294,
            slug: "tan-thuy",
            status: "empty",
          },
          {
            label: "Phú Thủy",
            value: 19297,
            slug: "phu-thuy",
            status: "empty",
          },
          {
            label: "Xuân Thủy",
            value: 19300,
            slug: "xuan-thuy",
            status: "empty",
          },
          { label: "Mỹ Thủy", value: 19303, slug: "my-thuy", status: "empty" },
          {
            label: "Ngư Thủy",
            value: 19306,
            slug: "ngu-thuy",
            status: "empty",
          },
          {
            label: "Mai Thủy",
            value: 19309,
            slug: "mai-thuy",
            status: "empty",
          },
          {
            label: "Sen Thủy",
            value: 19312,
            slug: "sen-thuy",
            status: "empty",
          },
          {
            label: "Thái Thủy",
            value: 19315,
            slug: "thai-thuy",
            status: "empty",
          },
          {
            label: "Kim Thủy",
            value: 19318,
            slug: "kim-thuy",
            status: "empty",
          },
          {
            label: "Trường Thủy",
            value: 19321,
            slug: "truong-thuy",
            status: "empty",
          },
          {
            label: "Lâm Thủy",
            value: 19327,
            slug: "lam-thuy",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Ba Đồn",
        value: 458,
        slug: "thi-xa-ba-don",
        status: "empty",
        ward: [
          { label: "Ba Đồn", value: 19009, slug: "ba-don", status: "empty" },
          {
            label: "Quảng Long",
            value: 19060,
            slug: "quang-long",
            status: "empty",
          },
          {
            label: "Quảng Thọ",
            value: 19066,
            slug: "quang-tho",
            status: "empty",
          },
          {
            label: "Quảng Tiên",
            value: 19069,
            slug: "quang-tien",
            status: "empty",
          },
          {
            label: "Quảng Trung",
            value: 19075,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Quảng Phong",
            value: 19078,
            slug: "quang-phong",
            status: "empty",
          },
          {
            label: "Quảng Thuận",
            value: 19081,
            slug: "quang-thuan",
            status: "empty",
          },
          {
            label: "Quảng Tân",
            value: 19084,
            slug: "quang-tan",
            status: "empty",
          },
          {
            label: "Quảng Hải",
            value: 19087,
            slug: "quang-hai",
            status: "empty",
          },
          {
            label: "Quảng Sơn",
            value: 19090,
            slug: "quang-son",
            status: "empty",
          },
          {
            label: "Quảng Lộc",
            value: 19093,
            slug: "quang-loc",
            status: "empty",
          },
          {
            label: "Quảng Thủy",
            value: 19096,
            slug: "quang-thuy",
            status: "empty",
          },
          {
            label: "Quảng Văn",
            value: 19099,
            slug: "quang-van",
            status: "empty",
          },
          {
            label: "Quảng Phúc",
            value: 19102,
            slug: "quang-phuc",
            status: "empty",
          },
          {
            label: "Quảng Hòa",
            value: 19105,
            slug: "quang-hoa",
            status: "empty",
          },
          {
            label: "Quảng Minh",
            value: 19108,
            slug: "quang-minh",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Quảng Trị",
    value: 45,
    slug: "quang-tri",
    status: "filled",
    district: [
      {
        label: "Thành phố Đông Hà",
        value: 461,
        slug: "thanh-pho-dong-ha",
        status: "filled",
        ward: [
          {
            label: "Đông Giang",
            value: 19330,
            slug: "dong-giang",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 19333,
            slug: "phuong-1",
            status: "empty",
          },
          { label: "Đông Lễ", value: 19336, slug: "dong-le", status: "empty" },
          {
            label: "Đông Thanh",
            value: 19339,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 19342,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 19345,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 19348,
            slug: "phuong-5",
            status: "filled",
          },
          {
            label: "Đông Lương",
            value: 19351,
            slug: "dong-luong",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 19354,
            slug: "phuong-3",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Quảng Trị",
        value: 462,
        slug: "thi-xa-quang-tri",
        status: "empty",
        ward: [
          {
            label: "Phường 1",
            value: 19357,
            slug: "phuong-1",
            status: "empty",
          },
          { label: "An Đôn", value: 19358, slug: "an-don", status: "empty" },
          {
            label: "Phường 2",
            value: 19360,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 19361,
            slug: "phuong-3",
            status: "empty",
          },
          { label: "Hải Lệ", value: 19705, slug: "hai-le", status: "empty" },
        ],
      },
      {
        label: "Huyện Vĩnh Linh",
        value: 464,
        slug: "huyen-vinh-linh",
        status: "empty",
        ward: [
          { label: "Hồ Xá", value: 19363, slug: "ho-xa", status: "empty" },
          {
            label: "Bến Quan",
            value: 19366,
            slug: "ben-quan",
            status: "empty",
          },
          {
            label: "Vĩnh Thái",
            value: 19369,
            slug: "vinh-thai",
            status: "empty",
          },
          { label: "Vĩnh Tú", value: 19372, slug: "vinh-tu", status: "empty" },
          {
            label: "Vĩnh Chấp",
            value: 19375,
            slug: "vinh-chap",
            status: "empty",
          },
          {
            label: "Trung Nam",
            value: 19378,
            slug: "trung-nam",
            status: "empty",
          },
          {
            label: "Kim Thạch",
            value: 19384,
            slug: "kim-thach",
            status: "empty",
          },
          {
            label: "Vĩnh Long",
            value: 19387,
            slug: "vinh-long",
            status: "empty",
          },
          {
            label: "Vĩnh Khê",
            value: 19393,
            slug: "vinh-khe",
            status: "empty",
          },
          {
            label: "Vĩnh Hòa",
            value: 19396,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Vĩnh Thủy",
            value: 19402,
            slug: "vinh-thuy",
            status: "empty",
          },
          {
            label: "Vĩnh Lâm",
            value: 19405,
            slug: "vinh-lam",
            status: "empty",
          },
          {
            label: "Hiền Thành",
            value: 19408,
            slug: "hien-thanh",
            status: "empty",
          },
          {
            label: "Cửa Tùng",
            value: 19414,
            slug: "cua-tung",
            status: "empty",
          },
          { label: "Vĩnh Hà", value: 19417, slug: "vinh-ha", status: "empty" },
          {
            label: "Vĩnh Sơn",
            value: 19420,
            slug: "vinh-son",
            status: "empty",
          },
          {
            label: "Vĩnh Giang",
            value: 19423,
            slug: "vinh-giang",
            status: "empty",
          },
          { label: "Vĩnh Ô", value: 19426, slug: "vinh-o", status: "empty" },
        ],
      },
      {
        label: "Huyện Hướng Hóa",
        value: 465,
        slug: "huyen-huong-hoa",
        status: "empty",
        ward: [
          {
            label: "Khe Sanh",
            value: 19429,
            slug: "khe-sanh",
            status: "empty",
          },
          { label: "Lao Bảo", value: 19432, slug: "lao-bao", status: "empty" },
          {
            label: "Hướng Lập",
            value: 19435,
            slug: "huong-lap",
            status: "empty",
          },
          {
            label: "Hướng Việt",
            value: 19438,
            slug: "huong-viet",
            status: "empty",
          },
          {
            label: "Hướng Phùng",
            value: 19441,
            slug: "huong-phung",
            status: "empty",
          },
          {
            label: "Hướng Sơn",
            value: 19444,
            slug: "huong-son",
            status: "empty",
          },
          {
            label: "Hướng Linh",
            value: 19447,
            slug: "huong-linh",
            status: "empty",
          },
          { label: "Tân Hợp", value: 19450, slug: "tan-hop", status: "empty" },
          {
            label: "Hướng Tân",
            value: 19453,
            slug: "huong-tan",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 19456,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Tân Long",
            value: 19459,
            slug: "tan-long",
            status: "empty",
          },
          { label: "Tân Lập", value: 19462, slug: "tan-lap", status: "empty" },
          {
            label: "Tân Liên",
            value: 19465,
            slug: "tan-lien",
            status: "empty",
          },
          { label: "Xã Húc", value: 19468, slug: "xa-huc", status: "empty" },
          {
            label: "Xã Thuận",
            value: 19471,
            slug: "xa-thuan",
            status: "empty",
          },
          {
            label: "Hướng Lộc",
            value: 19474,
            slug: "huong-loc",
            status: "empty",
          },
          { label: "Ba Tầng", value: 19477, slug: "ba-tang", status: "empty" },
          {
            label: "Xã Thanh",
            value: 19480,
            slug: "xa-thanh",
            status: "empty",
          },
          { label: "A Dơi", value: 19483, slug: "a-doi", status: "empty" },
          { label: "Xã Lìa", value: 19489, slug: "xa-lia", status: "empty" },
          { label: "Xã Xy", value: 19492, slug: "xa-xy", status: "empty" },
        ],
      },
      {
        label: "Huyện Gio Linh",
        value: 466,
        slug: "huyen-gio-linh",
        status: "empty",
        ward: [
          {
            label: "Gio Linh",
            value: 19495,
            slug: "gio-linh",
            status: "empty",
          },
          {
            label: "Cửa Việt",
            value: 19496,
            slug: "cua-viet",
            status: "empty",
          },
          {
            label: "Trung Giang",
            value: 19498,
            slug: "trung-giang",
            status: "empty",
          },
          {
            label: "Trung Hải",
            value: 19501,
            slug: "trung-hai",
            status: "empty",
          },
          {
            label: "Trung Sơn",
            value: 19504,
            slug: "trung-son",
            status: "empty",
          },
          {
            label: "Phong Bình",
            value: 19507,
            slug: "phong-binh",
            status: "empty",
          },
          { label: "Gio Mỹ", value: 19510, slug: "gio-my", status: "empty" },
          { label: "Gio Hải", value: 19519, slug: "gio-hai", status: "empty" },
          { label: "Gio An", value: 19522, slug: "gio-an", status: "empty" },
          {
            label: "Linh Trường",
            value: 19534,
            slug: "linh-truong",
            status: "empty",
          },
          { label: "Gio Sơn", value: 19537, slug: "gio-son", status: "empty" },
          { label: "Gio Mai", value: 19543, slug: "gio-mai", status: "empty" },
          {
            label: "Hải Thái",
            value: 19546,
            slug: "hai-thai",
            status: "empty",
          },
          {
            label: "Gio Quang",
            value: 19552,
            slug: "gio-quang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đa Krông",
        value: 467,
        slug: "huyen-da-krong",
        status: "empty",
        ward: [
          {
            label: "Krông Klang",
            value: 19555,
            slug: "krong-klang",
            status: "empty",
          },
          { label: "Mò Ó", value: 19558, slug: "mo-o", status: "empty" },
          {
            label: "Hướng Hiệp",
            value: 19561,
            slug: "huong-hiep",
            status: "empty",
          },
          {
            label: "Đa Krông",
            value: 19564,
            slug: "da-krong",
            status: "empty",
          },
          {
            label: "Triệu Nguyên",
            value: 19567,
            slug: "trieu-nguyen",
            status: "empty",
          },
          { label: "Ba Lòng", value: 19570, slug: "ba-long", status: "empty" },
          { label: "Ba Nang", value: 19576, slug: "ba-nang", status: "empty" },
          { label: "Tà Long", value: 19579, slug: "ta-long", status: "empty" },
          {
            label: "Húc Nghì",
            value: 19582,
            slug: "huc-nghi",
            status: "empty",
          },
          { label: "A Vao", value: 19585, slug: "a-vao", status: "empty" },
          { label: "Tà Rụt", value: 19588, slug: "ta-rut", status: "empty" },
          { label: "A Bung", value: 19591, slug: "a-bung", status: "empty" },
          { label: "A Ngo", value: 19594, slug: "a-ngo", status: "empty" },
        ],
      },
      {
        label: "Huyện Cam Lộ",
        value: 468,
        slug: "huyen-cam-lo",
        status: "empty",
        ward: [
          { label: "Cam Lộ", value: 19597, slug: "cam-lo", status: "empty" },
          {
            label: "Cam Tuyền",
            value: 19600,
            slug: "cam-tuyen",
            status: "empty",
          },
          {
            label: "Thanh An",
            value: 19603,
            slug: "thanh-an",
            status: "empty",
          },
          {
            label: "Cam Thủy",
            value: 19606,
            slug: "cam-thuy",
            status: "empty",
          },
          {
            label: "Cam Thành",
            value: 19612,
            slug: "cam-thanh",
            status: "empty",
          },
          {
            label: "Cam Hiếu",
            value: 19615,
            slug: "cam-hieu",
            status: "empty",
          },
          {
            label: "Cam Chính",
            value: 19618,
            slug: "cam-chinh",
            status: "empty",
          },
          {
            label: "Cam Nghĩa",
            value: 19621,
            slug: "cam-nghia",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Triệu Phong",
        value: 469,
        slug: "huyen-trieu-phong",
        status: "empty",
        ward: [
          { label: "Ái Tử", value: 19624, slug: "ai-tu", status: "empty" },
          {
            label: "Triệu Tân",
            value: 19627,
            slug: "trieu-tan",
            status: "empty",
          },
          {
            label: "Triệu Phước",
            value: 19633,
            slug: "trieu-phuoc",
            status: "empty",
          },
          {
            label: "Triệu Độ",
            value: 19636,
            slug: "trieu-do",
            status: "empty",
          },
          {
            label: "Triệu Trạch",
            value: 19639,
            slug: "trieu-trach",
            status: "empty",
          },
          {
            label: "Triệu Thuận",
            value: 19642,
            slug: "trieu-thuan",
            status: "empty",
          },
          {
            label: "Triệu Đại",
            value: 19645,
            slug: "trieu-dai",
            status: "empty",
          },
          {
            label: "Triệu Hòa",
            value: 19648,
            slug: "trieu-hoa",
            status: "empty",
          },
          {
            label: "Triệu Cơ",
            value: 19654,
            slug: "trieu-co",
            status: "empty",
          },
          {
            label: "Triệu Long",
            value: 19657,
            slug: "trieu-long",
            status: "empty",
          },
          {
            label: "Triệu Tài",
            value: 19660,
            slug: "trieu-tai",
            status: "empty",
          },
          {
            label: "Triệu Trung",
            value: 19666,
            slug: "trieu-trung",
            status: "empty",
          },
          {
            label: "Triệu Ái",
            value: 19669,
            slug: "trieu-ai",
            status: "empty",
          },
          {
            label: "Triệu Thượng",
            value: 19672,
            slug: "trieu-thuong",
            status: "empty",
          },
          {
            label: "Triệu Giang",
            value: 19675,
            slug: "trieu-giang",
            status: "empty",
          },
          {
            label: "Triệu Thành",
            value: 19678,
            slug: "trieu-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hải Lăng",
        value: 470,
        slug: "huyen-hai-lang",
        status: "empty",
        ward: [
          {
            label: "Diên Sanh",
            value: 19681,
            slug: "dien-sanh",
            status: "empty",
          },
          { label: "Hải An", value: 19684, slug: "hai-an", status: "empty" },
          {
            label: "Hải Bình",
            value: 19687,
            slug: "hai-binh",
            status: "empty",
          },
          { label: "Hải Quy", value: 19693, slug: "hai-quy", status: "empty" },
          {
            label: "Hải Hưng",
            value: 19699,
            slug: "hai-hung",
            status: "empty",
          },
          { label: "Hải Phú", value: 19702, slug: "hai-phu", status: "empty" },
          {
            label: "Hải Thượng",
            value: 19708,
            slug: "hai-thuong",
            status: "empty",
          },
          {
            label: "Hải Dương",
            value: 19711,
            slug: "hai-duong",
            status: "empty",
          },
          {
            label: "Hải Định",
            value: 19714,
            slug: "hai-dinh",
            status: "empty",
          },
          { label: "Hải Lâm", value: 19717, slug: "hai-lam", status: "empty" },
          {
            label: "Hải Phong",
            value: 19726,
            slug: "hai-phong",
            status: "empty",
          },
          {
            label: "Hải Trường",
            value: 19729,
            slug: "hai-truong",
            status: "empty",
          },
          { label: "Hải Sơn", value: 19735, slug: "hai-son", status: "empty" },
          {
            label: "Hải Chánh",
            value: 19738,
            slug: "hai-chanh",
            status: "empty",
          },
          { label: "Hải Khê", value: 19741, slug: "hai-khe", status: "empty" },
        ],
      },
      {
        label: "Huyện Cồn Cỏ",
        value: 471,
        slug: "huyen-con-co",
        status: "empty",
        ward: [],
      },
    ],
  },
  {
    label: "Thừa Thiên Huế",
    value: 46,
    slug: "thua-thien-hue",
    status: "filled",
    district: [
      {
        label: "Thành phố Huế",
        value: 474,
        slug: "thanh-pho-hue",
        status: "filled",
        ward: [
          { label: "Vỹ Dạ", value: 19777, slug: "vy-da", status: "empty" },
          {
            label: "Phường Đúc",
            value: 19780,
            slug: "phuong-duc",
            status: "empty",
          },
          {
            label: "Vĩnh Ninh",
            value: 19783,
            slug: "vinh-ninh",
            status: "empty",
          },
          { label: "Phú Hội", value: 19786, slug: "phu-hoi", status: "empty" },
          {
            label: "Phú Nhuận",
            value: 19789,
            slug: "phu-nhuan",
            status: "filled",
          },
          {
            label: "Xuân Phú",
            value: 19792,
            slug: "xuan-phu",
            status: "empty",
          },
          {
            label: "Trường An",
            value: 19795,
            slug: "truong-an",
            status: "empty",
          },
          {
            label: "Phước Vĩnh",
            value: 19798,
            slug: "phuoc-vinh",
            status: "empty",
          },
          { label: "An Cựu", value: 19801, slug: "an-cuu", status: "empty" },
          {
            label: "Thuỷ Biều",
            value: 19807,
            slug: "thuy-bieu",
            status: "empty",
          },
          {
            label: "Thuỷ Xuân",
            value: 19813,
            slug: "thuy-xuan",
            status: "empty",
          },
          { label: "An Đông", value: 19815, slug: "an-dong", status: "empty" },
          { label: "An Tây", value: 19816, slug: "an-tay", status: "empty" },
          {
            label: "Thuận An",
            value: 19900,
            slug: "thuan-an",
            status: "empty",
          },
          {
            label: "Dương Nỗ",
            value: 19909,
            slug: "duong-no",
            status: "empty",
          },
          {
            label: "Phú Thượng",
            value: 19930,
            slug: "phu-thuong",
            status: "filled",
          },
          {
            label: "Thủy Vân",
            value: 19963,
            slug: "thuy-van",
            status: "filled",
          },
          {
            label: "Thủy Bằng",
            value: 19981,
            slug: "thuy-bang",
            status: "empty",
          },
          {
            label: "Phú Xuân",
            value: 19982,
            slug: "phu-xuan",
            status: "empty",
          },
          { label: "Phú Hậu", value: 19983, slug: "phu-hau", status: "filled" },
          {
            label: "Hương Phong",
            value: 20002,
            slug: "huong-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phong Điền",
        value: 476,
        slug: "huyen-phong-dien",
        status: "empty",
        ward: [
          {
            label: "Phong Thu",
            value: 19819,
            slug: "phong-thu",
            status: "empty",
          },
          {
            label: "Phong Thạnh",
            value: 19825,
            slug: "phong-thanh",
            status: "empty",
          },
          {
            label: "Phong Phú",
            value: 19828,
            slug: "phong-phu",
            status: "empty",
          },
          {
            label: "Phong Bình",
            value: 19831,
            slug: "phong-binh",
            status: "empty",
          },
          {
            label: "Phong Chương",
            value: 19837,
            slug: "phong-chuong",
            status: "empty",
          },
          {
            label: "Phong Hải",
            value: 19843,
            slug: "phong-hai",
            status: "empty",
          },
          {
            label: "Phong Hòa",
            value: 19846,
            slug: "phong-hoa",
            status: "empty",
          },
          {
            label: "Phong Hiền",
            value: 19852,
            slug: "phong-hien",
            status: "empty",
          },
          {
            label: "Phong Mỹ",
            value: 19855,
            slug: "phong-my",
            status: "empty",
          },
          {
            label: "Phong An",
            value: 19858,
            slug: "phong-an",
            status: "empty",
          },
          {
            label: "Phong Xuân",
            value: 19861,
            slug: "phong-xuan",
            status: "empty",
          },
          {
            label: "Phong Sơn",
            value: 19864,
            slug: "phong-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Quảng Điền",
        value: 477,
        slug: "huyen-quang-dien",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Sịa",
            value: 19867,
            slug: "thi-tran-sia",
            status: "empty",
          },
          {
            label: "Quảng Thái",
            value: 19870,
            slug: "quang-thai",
            status: "empty",
          },
          {
            label: "Quảng Ngạn",
            value: 19873,
            slug: "quang-ngan",
            status: "empty",
          },
          {
            label: "Quảng Lợi",
            value: 19876,
            slug: "quang-loi",
            status: "empty",
          },
          {
            label: "Quảng Công",
            value: 19879,
            slug: "quang-cong",
            status: "empty",
          },
          {
            label: "Quảng Phước",
            value: 19882,
            slug: "quang-phuoc",
            status: "empty",
          },
          {
            label: "Quảng Vinh",
            value: 19885,
            slug: "quang-vinh",
            status: "empty",
          },
          {
            label: "Quảng An",
            value: 19888,
            slug: "quang-an",
            status: "empty",
          },
          {
            label: "Quảng Thành",
            value: 19891,
            slug: "quang-thanh",
            status: "empty",
          },
          {
            label: "Quảng Thọ",
            value: 19894,
            slug: "quang-tho",
            status: "empty",
          },
          {
            label: "Quảng Phú",
            value: 19897,
            slug: "quang-phu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phú Vang",
        value: 478,
        slug: "huyen-phu-vang",
        status: "empty",
        ward: [
          {
            label: "Phú Thuận",
            value: 19903,
            slug: "phu-thuan",
            status: "empty",
          },
          { label: "Phú An", value: 19912, slug: "phu-an", status: "empty" },
          { label: "Phú Hải", value: 19915, slug: "phu-hai", status: "empty" },
          {
            label: "Phú Xuân",
            value: 19918,
            slug: "phu-xuan",
            status: "empty",
          },
          {
            label: "Phú Diên",
            value: 19921,
            slug: "phu-dien",
            status: "empty",
          },
          { label: "Phú Mỹ", value: 19927, slug: "phu-my", status: "empty" },
          { label: "Phú Hồ", value: 19933, slug: "phu-ho", status: "empty" },
          {
            label: "Vinh Xuân",
            value: 19936,
            slug: "vinh-xuan",
            status: "empty",
          },
          {
            label: "Phú Lương",
            value: 19939,
            slug: "phu-luong",
            status: "empty",
          },
          { label: "Phú Đa", value: 19942, slug: "phu-da", status: "empty" },
          {
            label: "Vinh Thanh",
            value: 19945,
            slug: "vinh-thanh",
            status: "empty",
          },
          { label: "Vinh An", value: 19948, slug: "vinh-an", status: "empty" },
          { label: "Phú Gia", value: 19954, slug: "phu-gia", status: "empty" },
          { label: "Vinh Hà", value: 19957, slug: "vinh-ha", status: "empty" },
        ],
      },
      {
        label: "Thị xã Hương Thủy",
        value: 479,
        slug: "thi-xa-huong-thuy",
        status: "empty",
        ward: [
          { label: "Phú Bài", value: 19960, slug: "phu-bai", status: "empty" },
          {
            label: "Thủy Thanh",
            value: 19966,
            slug: "thuy-thanh",
            status: "empty",
          },
          {
            label: "Thủy Dương",
            value: 19969,
            slug: "thuy-duong",
            status: "empty",
          },
          {
            label: "Thủy Phương",
            value: 19972,
            slug: "thuy-phuong",
            status: "empty",
          },
          {
            label: "Thủy Châu",
            value: 19975,
            slug: "thuy-chau",
            status: "empty",
          },
          {
            label: "Thủy Lương",
            value: 19978,
            slug: "thuy-luong",
            status: "empty",
          },
          {
            label: "Thủy Tân",
            value: 19984,
            slug: "thuy-tan",
            status: "empty",
          },
          {
            label: "Thủy Phù",
            value: 19987,
            slug: "thuy-phu",
            status: "empty",
          },
          { label: "Phú Sơn", value: 19990, slug: "phu-son", status: "empty" },
          {
            label: "Dương Hòa",
            value: 19993,
            slug: "duong-hoa",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Hương Trà",
        value: 480,
        slug: "thi-xa-huong-tra",
        status: "empty",
        ward: [
          { label: "Tứ Hạ", value: 19996, slug: "tu-ha", status: "empty" },
          {
            label: "Hương Toàn",
            value: 20005,
            slug: "huong-toan",
            status: "empty",
          },
          {
            label: "Hương Vân",
            value: 20008,
            slug: "huong-van",
            status: "empty",
          },
          {
            label: "Hương Văn",
            value: 20011,
            slug: "huong-van",
            status: "empty",
          },
          {
            label: "Hương Xuân",
            value: 20017,
            slug: "huong-xuan",
            status: "empty",
          },
          {
            label: "Hương Chữ",
            value: 20020,
            slug: "huong-chu",
            status: "empty",
          },
          {
            label: "Hương Bình",
            value: 20026,
            slug: "huong-binh",
            status: "empty",
          },
          {
            label: "Bình Tiến",
            value: 20035,
            slug: "binh-tien",
            status: "empty",
          },
          {
            label: "Bình Thành",
            value: 20041,
            slug: "binh-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện A Lưới",
        value: 481,
        slug: "huyen-a-luoi",
        status: "empty",
        ward: [
          { label: "A Lưới", value: 20044, slug: "a-luoi", status: "empty" },
          {
            label: "Hồng Vân",
            value: 20047,
            slug: "hong-van",
            status: "empty",
          },
          { label: "Hồng Hạ", value: 20050, slug: "hong-ha", status: "empty" },
          {
            label: "Hồng Kim",
            value: 20053,
            slug: "hong-kim",
            status: "empty",
          },
          {
            label: "Trung Sơn",
            value: 20056,
            slug: "trung-son",
            status: "empty",
          },
          {
            label: "Hương Nguyên",
            value: 20059,
            slug: "huong-nguyen",
            status: "empty",
          },
          {
            label: "Hồng Bắc",
            value: 20065,
            slug: "hong-bac",
            status: "empty",
          },
          { label: "A Ngo", value: 20068, slug: "a-ngo", status: "empty" },
          {
            label: "Sơn Thủy",
            value: 20071,
            slug: "son-thuy",
            status: "empty",
          },
          {
            label: "Phú Vinh",
            value: 20074,
            slug: "phu-vinh",
            status: "empty",
          },
          {
            label: "Hương Phong",
            value: 20080,
            slug: "huong-phong",
            status: "empty",
          },
          {
            label: "Quảng Nhâm",
            value: 20083,
            slug: "quang-nham",
            status: "empty",
          },
          {
            label: "Hồng Thượng",
            value: 20086,
            slug: "hong-thuong",
            status: "empty",
          },
          {
            label: "Hồng Thái",
            value: 20089,
            slug: "hong-thai",
            status: "empty",
          },
          { label: "A Roàng", value: 20095, slug: "a-roang", status: "empty" },
          {
            label: "Đông Sơn",
            value: 20098,
            slug: "dong-son",
            status: "empty",
          },
          { label: "Lâm Đớt", value: 20101, slug: "lam-dot", status: "empty" },
          {
            label: "Hồng Thủy",
            value: 20104,
            slug: "hong-thuy",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phú Lộc",
        value: 482,
        slug: "huyen-phu-loc",
        status: "empty",
        ward: [
          { label: "Phú Lộc", value: 20107, slug: "phu-loc", status: "empty" },
          { label: "Lăng Cô", value: 20110, slug: "lang-co", status: "empty" },
          { label: "Vinh Mỹ", value: 20113, slug: "vinh-my", status: "empty" },
          {
            label: "Vinh Hưng",
            value: 20116,
            slug: "vinh-hung",
            status: "empty",
          },
          {
            label: "Giang Hải",
            value: 20122,
            slug: "giang-hai",
            status: "empty",
          },
          {
            label: "Vinh Hiền",
            value: 20125,
            slug: "vinh-hien",
            status: "empty",
          },
          { label: "Lộc Bổn", value: 20128, slug: "loc-bon", status: "empty" },
          { label: "Lộc Sơn", value: 20131, slug: "loc-son", status: "empty" },
          {
            label: "Lộc Bình",
            value: 20134,
            slug: "loc-binh",
            status: "empty",
          },
          {
            label: "Lộc Vĩnh",
            value: 20137,
            slug: "loc-vinh",
            status: "empty",
          },
          { label: "Lộc An", value: 20140, slug: "loc-an", status: "empty" },
          {
            label: "Lộc Điền",
            value: 20143,
            slug: "loc-dien",
            status: "empty",
          },
          {
            label: "Lộc Thủy",
            value: 20146,
            slug: "loc-thuy",
            status: "empty",
          },
          { label: "Lộc Trì", value: 20149, slug: "loc-tri", status: "empty" },
          {
            label: "Lộc Tiến",
            value: 20152,
            slug: "loc-tien",
            status: "empty",
          },
          { label: "Lộc Hòa", value: 20155, slug: "loc-hoa", status: "empty" },
          {
            label: "Xuân Lộc",
            value: 20158,
            slug: "xuan-loc",
            status: "empty",
          },
          { label: "Khe Tre", value: 20161, slug: "khe-tre", status: "empty" },
          {
            label: "Hương Phú",
            value: 20164,
            slug: "huong-phu",
            status: "empty",
          },
          {
            label: "Hương Sơn",
            value: 20167,
            slug: "huong-son",
            status: "empty",
          },
          {
            label: "Hương Lộc",
            value: 20170,
            slug: "huong-loc",
            status: "empty",
          },
          {
            label: "Thượng Quảng",
            value: 20173,
            slug: "thuong-quang",
            status: "empty",
          },
          {
            label: "Hương Hòa",
            value: 20176,
            slug: "huong-hoa",
            status: "empty",
          },
          {
            label: "Hương Xuân",
            value: 20179,
            slug: "huong-xuan",
            status: "empty",
          },
          {
            label: "Hương Hữu",
            value: 20182,
            slug: "huong-huu",
            status: "empty",
          },
          {
            label: "Thượng Lộ",
            value: 20185,
            slug: "thuong-lo",
            status: "empty",
          },
          {
            label: "Thượng Long",
            value: 20188,
            slug: "thuong-long",
            status: "empty",
          },
          {
            label: "Thượng Nhật",
            value: 20191,
            slug: "thuong-nhat",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nam Đông",
        value: 483,
        slug: "huyen-nam-dong",
        status: "empty",
        ward: [
          {
            label: "Thị trấn Nam Đông",
            value: 100900,
            slug: "thi-tran-nam-dong",
            status: "empty",
          },
          {
            label: "Hương Phú",
            value: 100901,
            slug: "huong-phu",
            status: "empty",
          },
          {
            label: "Hương Sơn",
            value: 100902,
            slug: "huong-son",
            status: "empty",
          },
          {
            label: "Thượng Quảng",
            value: 100903,
            slug: "thuong-quang",
            status: "empty",
          },
          {
            label: "Thượng Lộ",
            value: 100904,
            slug: "thuong-lo",
            status: "empty",
          },
          {
            label: "Nam Đông",
            value: 100905,
            slug: "nam-dong",
            status: "empty",
          },
          {
            label: "Hương Giang",
            value: 100906,
            slug: "huong-giang",
            status: "empty",
          },
          { label: "Hòa Bắc", value: 100907, slug: "hoa-bac", status: "empty" },
          { label: "Hòa Phú", value: 100908, slug: "hoa-phu", status: "empty" },
          { label: "Hòa Quý", value: 100909, slug: "hoa-quy", status: "empty" },
          {
            label: "Hòa Tiến",
            value: 100910,
            slug: "hoa-tien",
            status: "empty",
          },
          {
            label: "Lộc Thủy",
            value: 100911,
            slug: "loc-thuy",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Đà Nẵng",
    value: 48,
    slug: "da-nang",
    status: "filled",
    district: [
      {
        label: "Liên Chiểu",
        value: 490,
        slug: "lien-chieu",
        status: "filled",
        ward: [
          {
            label: "Hòa Hiệp Bắc",
            value: 20194,
            slug: "hoa-hiep-bac",
            status: "empty",
          },
          {
            label: "Hòa Hiệp Nam",
            value: 20195,
            slug: "hoa-hiep-nam",
            status: "empty",
          },
          {
            label: "Hòa Khánh Bắc",
            value: 20197,
            slug: "hoa-khanh-bac",
            status: "filled",
          },
          {
            label: "Hòa Khánh Nam",
            value: 20198,
            slug: "hoa-khanh-nam",
            status: "empty",
          },
          {
            label: "Hòa Minh",
            value: 20200,
            slug: "hoa-minh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thanh Khê",
        value: 491,
        slug: "thanh-khe",
        status: "filled",
        ward: [
          {
            label: "Thanh Khê Tây",
            value: 20206,
            slug: "thanh-khe-tay",
            status: "empty",
          },
          {
            label: "Thanh Khê Đông",
            value: 20207,
            slug: "thanh-khe-dong",
            status: "empty",
          },
          { label: "Xuân Hà", value: 20209, slug: "xuan-ha", status: "empty" },
          {
            label: "Chính Gián",
            value: 20215,
            slug: "chinh-gian",
            status: "filled",
          },
          {
            label: "Thạc Gián",
            value: 20218,
            slug: "thac-gian",
            status: "empty",
          },
          { label: "An Khê", value: 20224, slug: "an-khe", status: "empty" },
        ],
      },
      {
        label: "Hải Châu",
        value: 492,
        slug: "hai-chau",
        status: "filled",
        ward: [
          {
            label: "Thanh Bình",
            value: 20227,
            slug: "thanh-binh",
            status: "filled",
          },
          {
            label: "Thuận Phước",
            value: 20230,
            slug: "thuan-phuoc",
            status: "empty",
          },
          {
            label: "Thạch Thang",
            value: 20233,
            slug: "thach-thang",
            status: "empty",
          },
          {
            label: "Hải Châu",
            value: 20236,
            slug: "hai-chau",
            status: "empty",
          },
          {
            label: "Phước Ninh",
            value: 20242,
            slug: "phuoc-ninh",
            status: "filled",
          },
          {
            label: "Hòa Thuận Tây",
            value: 20245,
            slug: "hoa-thuan-tay",
            status: "filled",
          },
          {
            label: "Hòa Thuận Nam",
            value: 20246,
            slug: "hoa-thuan-nam",
            status: "empty",
          },
          {
            label: "Bình Thuận",
            value: 20254,
            slug: "binh-thuan",
            status: "empty",
          },
          {
            label: "Hòa Thuận Đông",
            value: 20255,
            slug: "hoa-thuan-dong",
            status: "filled",
          },
          {
            label: "Bình Hiên",
            value: 20256,
            slug: "binh-hien",
            status: "empty",
          },
          {
            label: "Hòa Cường Bắc",
            value: 20257,
            slug: "hoa-cuong-bac",
            status: "empty",
          },
          {
            label: "Hòa Cường Nam",
            value: 20258,
            slug: "hoa-cuong-nam",
            status: "empty",
          },
        ],
      },
      {
        label: "Sơn Trà",
        value: 493,
        slug: "son-tra",
        status: "filled",
        ward: [
          {
            label: "Thọ Quang",
            value: 20263,
            slug: "tho-quang",
            status: "empty",
          },
          {
            label: "Nại Hiên Đông",
            value: 20266,
            slug: "nai-hien-dong",
            status: "empty",
          },
          {
            label: "Mân Thái",
            value: 20269,
            slug: "man-thai",
            status: "empty",
          },
          {
            label: "An Hải Bắc",
            value: 20272,
            slug: "an-hai-bac",
            status: "empty",
          },
          {
            label: "Phước Mỹ",
            value: 20275,
            slug: "phuoc-my",
            status: "empty",
          },
          {
            label: "An Hải Trung",
            value: 20276,
            slug: "an-hai-trung",
            status: "filled",
          },
          {
            label: "An Hải Nam",
            value: 20278,
            slug: "an-hai-nam",
            status: "empty",
          },
        ],
      },
      {
        label: "Ngũ Hành Sơn",
        value: 494,
        slug: "ngu-hanh-son",
        status: "empty",
        ward: [
          { label: "Mỹ An", value: 20284, slug: "my-an", status: "empty" },
          { label: "Khuê Mỹ", value: 20285, slug: "khue-my", status: "empty" },
          { label: "Hoà Quý", value: 20287, slug: "hoa-quy", status: "empty" },
          { label: "Hoà Hải", value: 20290, slug: "hoa-hai", status: "empty" },
        ],
      },
      {
        label: "Cẩm Lệ",
        value: 495,
        slug: "cam-le",
        status: "filled",
        ward: [
          {
            label: "Khuê Trung",
            value: 20260,
            slug: "khue-trung",
            status: "empty",
          },
          {
            label: "Hòa Phát",
            value: 20305,
            slug: "hoa-phat",
            status: "empty",
          },
          { label: "Hòa An", value: 20306, slug: "hoa-an", status: "empty" },
          {
            label: "Hòa Thọ Tây",
            value: 20311,
            slug: "hoa-tho-tay",
            status: "filled",
          },
          {
            label: "Hòa Thọ Đông",
            value: 20312,
            slug: "hoa-tho-dong",
            status: "empty",
          },
          {
            label: "Hòa Xuân",
            value: 20314,
            slug: "hoa-xuan",
            status: "empty",
          },
        ],
      },
      {
        label: "Hòa Vang",
        value: 497,
        slug: "hoa-vang",
        status: "empty",
        ward: [
          { label: "Hòa Bắc", value: 20293, slug: "hoa-bac", status: "empty" },
          {
            label: "Hòa Liên",
            value: 20296,
            slug: "hoa-lien",
            status: "empty",
          },
          {
            label: "Hòa Ninh",
            value: 20299,
            slug: "hoa-ninh",
            status: "empty",
          },
          { label: "Hòa Sơn", value: 20302, slug: "hoa-son", status: "empty" },
          {
            label: "Hòa Nhơn",
            value: 20308,
            slug: "hoa-nhon",
            status: "empty",
          },
          { label: "Hòa Phú", value: 20317, slug: "hoa-phu", status: "empty" },
          {
            label: "Hòa Phong",
            value: 20320,
            slug: "hoa-phong",
            status: "empty",
          },
          {
            label: "Hòa Châu",
            value: 20323,
            slug: "hoa-chau",
            status: "empty",
          },
          {
            label: "Hòa Tiến",
            value: 20326,
            slug: "hoa-tien",
            status: "empty",
          },
          {
            label: "Hòa Phước",
            value: 20329,
            slug: "hoa-phuoc",
            status: "empty",
          },
          {
            label: "Hòa Khương",
            value: 20332,
            slug: "hoa-khuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Hoàng Sa",
        value: 498,
        slug: "hoang-sa",
        status: "empty",
        ward: [],
      },
    ],
  },
  {
    label: "Quảng Nam",
    value: 49,
    slug: "quang-nam",
    status: "filled",
    district: [
      {
        label: "Thành phố Tam Kỳ",
        value: 502,
        slug: "thanh-pho-tam-ky",
        status: "empty",
        ward: [
          {
            label: "Tân Thạnh",
            value: 20335,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "An Mỹ", value: 20341, slug: "an-my", status: "empty" },
          {
            label: "Hòa Hương",
            value: 20344,
            slug: "hoa-huong",
            status: "empty",
          },
          { label: "An Xuân", value: 20347, slug: "an-xuan", status: "empty" },
          { label: "An Sơn", value: 20350, slug: "an-son", status: "empty" },
          {
            label: "Trường Xuân",
            value: 20353,
            slug: "truong-xuan",
            status: "empty",
          },
          { label: "An Phú", value: 20356, slug: "an-phu", status: "empty" },
          {
            label: "Tam Thanh",
            value: 20359,
            slug: "tam-thanh",
            status: "empty",
          },
          {
            label: "Tam Thăng",
            value: 20362,
            slug: "tam-thang",
            status: "empty",
          },
          { label: "Tam Phú", value: 20371, slug: "tam-phu", status: "empty" },
          {
            label: "Hoà Thuận",
            value: 20375,
            slug: "hoa-thuan",
            status: "empty",
          },
          {
            label: "Tam Ngọc",
            value: 20389,
            slug: "tam-ngoc",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Hội An",
        value: 503,
        slug: "thanh-pho-hoi-an",
        status: "empty",
        ward: [
          { label: "Minh An", value: 20398, slug: "minh-an", status: "empty" },
          { label: "Tân An", value: 20401, slug: "tan-an", status: "empty" },
          { label: "Cẩm Phô", value: 20404, slug: "cam-pho", status: "empty" },
          {
            label: "Thanh Hà",
            value: 20407,
            slug: "thanh-ha",
            status: "empty",
          },
          {
            label: "Sơn Phong",
            value: 20410,
            slug: "son-phong",
            status: "empty",
          },
          {
            label: "Cẩm Châu",
            value: 20413,
            slug: "cam-chau",
            status: "empty",
          },
          { label: "Cửa Đại", value: 20416, slug: "cua-dai", status: "empty" },
          { label: "Cẩm An", value: 20419, slug: "cam-an", status: "empty" },
          { label: "Cẩm Hà", value: 20422, slug: "cam-ha", status: "empty" },
          { label: "Cẩm Kim", value: 20425, slug: "cam-kim", status: "empty" },
          { label: "Cẩm Nam", value: 20428, slug: "cam-nam", status: "empty" },
          {
            label: "Cẩm Thanh",
            value: 20431,
            slug: "cam-thanh",
            status: "empty",
          },
          {
            label: "Tân Hiệp",
            value: 20434,
            slug: "tan-hiep",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tây Giang",
        value: 504,
        slug: "huyen-tay-giang",
        status: "empty",
        ward: [
          {
            label: "Xã Ch'ơm",
            value: 20437,
            slug: "xa-ch'om",
            status: "empty",
          },
          { label: "Ga Ri", value: 20440, slug: "ga-ri", status: "empty" },
          { label: "A Xan", value: 20443, slug: "a-xan", status: "empty" },
          {
            label: "Xã Tr'Hy",
            value: 20446,
            slug: "xa-tr'hy",
            status: "empty",
          },
          { label: "Xã Lăng", value: 20449, slug: "xa-lang", status: "empty" },
          { label: "A Nông", value: 20452, slug: "a-nong", status: "empty" },
          { label: "A Tiêng", value: 20455, slug: "a-tieng", status: "empty" },
          { label: "Bha Lê", value: 20458, slug: "bha-le", status: "empty" },
          { label: "A Vương", value: 20461, slug: "a-vuong", status: "empty" },
          { label: "Xã Dang", value: 20464, slug: "xa-dang", status: "empty" },
        ],
      },
      {
        label: "Huyện Đông Giang",
        value: 505,
        slug: "huyen-dong-giang",
        status: "empty",
        ward: [
          { label: "P Rao", value: 20467, slug: "p-rao", status: "empty" },
          { label: "Tà Lu", value: 20470, slug: "ta-lu", status: "empty" },
          {
            label: "Sông Kôn",
            value: 20473,
            slug: "song-kon",
            status: "empty",
          },
          { label: "Jơ Ngây", value: 20476, slug: "jo-ngay", status: "empty" },
          { label: "A Ting", value: 20479, slug: "a-ting", status: "empty" },
          { label: "Xã Tư", value: 20482, slug: "xa-tu", status: "empty" },
          { label: "Xã Ba", value: 20485, slug: "xa-ba", status: "empty" },
          { label: "A Rooi", value: 20488, slug: "a-rooi", status: "empty" },
          { label: "Za Hung", value: 20491, slug: "za-hung", status: "empty" },
          { label: "Mà Cooi", value: 20494, slug: "ma-cooi", status: "empty" },
          { label: "Ka Dăng", value: 20497, slug: "ka-dang", status: "empty" },
        ],
      },
      {
        label: "Huyện Đại Lộc",
        value: 506,
        slug: "huyen-dai-loc",
        status: "filled",
        ward: [
          {
            label: "Ái Nghĩa",
            value: 20500,
            slug: "ai-nghia",
            status: "filled",
          },
          { label: "Đại Sơn", value: 20503, slug: "dai-son", status: "empty" },
          {
            label: "Đại Lãnh",
            value: 20506,
            slug: "dai-lanh",
            status: "empty",
          },
          {
            label: "Đại Hưng",
            value: 20509,
            slug: "dai-hung",
            status: "empty",
          },
          {
            label: "Đại Hồng",
            value: 20512,
            slug: "dai-hong",
            status: "empty",
          },
          {
            label: "Đại Đồng",
            value: 20515,
            slug: "dai-dong",
            status: "empty",
          },
          {
            label: "Đại Quang",
            value: 20518,
            slug: "dai-quang",
            status: "empty",
          },
          {
            label: "Đại Nghĩa",
            value: 20521,
            slug: "dai-nghia",
            status: "empty",
          },
          {
            label: "Đại Hiệp",
            value: 20524,
            slug: "dai-hiep",
            status: "empty",
          },
          {
            label: "Đại Thạnh",
            value: 20527,
            slug: "dai-thanh",
            status: "empty",
          },
          {
            label: "Đại Chánh",
            value: 20530,
            slug: "dai-chanh",
            status: "empty",
          },
          { label: "Đại Tân", value: 20533, slug: "dai-tan", status: "empty" },
          {
            label: "Đại Phong",
            value: 20536,
            slug: "dai-phong",
            status: "empty",
          },
          {
            label: "Đại Minh",
            value: 20539,
            slug: "dai-minh",
            status: "empty",
          },
          {
            label: "Đại Thắng",
            value: 20542,
            slug: "dai-thang",
            status: "empty",
          },
          {
            label: "Đại Cường",
            value: 20545,
            slug: "dai-cuong",
            status: "empty",
          },
          { label: "Đại An", value: 20547, slug: "dai-an", status: "empty" },
          { label: "Đại Hòa", value: 20548, slug: "dai-hoa", status: "empty" },
        ],
      },
      {
        label: "Thị xã Điện Bàn",
        value: 507,
        slug: "thi-xa-dien-ban",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Điện",
            value: 20551,
            slug: "vinh-dien",
            status: "empty",
          },
          {
            label: "Điện Tiến",
            value: 20554,
            slug: "dien-tien",
            status: "empty",
          },
          {
            label: "Điện Hòa",
            value: 20557,
            slug: "dien-hoa",
            status: "empty",
          },
          {
            label: "Điện Thắng Bắc",
            value: 20560,
            slug: "dien-thang-bac",
            status: "empty",
          },
          {
            label: "Điện Thắng Trung",
            value: 20561,
            slug: "dien-thang-trung",
            status: "empty",
          },
          {
            label: "Điện Thắng Nam",
            value: 20562,
            slug: "dien-thang-nam",
            status: "empty",
          },
          {
            label: "Điện Ngọc",
            value: 20563,
            slug: "dien-ngoc",
            status: "empty",
          },
          {
            label: "Điện Hồng",
            value: 20566,
            slug: "dien-hong",
            status: "empty",
          },
          {
            label: "Điện Thọ",
            value: 20569,
            slug: "dien-tho",
            status: "empty",
          },
          {
            label: "Điện Phước",
            value: 20572,
            slug: "dien-phuoc",
            status: "empty",
          },
          { label: "Điện An", value: 20575, slug: "dien-an", status: "empty" },
          {
            label: "Điện Nam Bắc",
            value: 20578,
            slug: "dien-nam-bac",
            status: "empty",
          },
          {
            label: "Điện Nam Trung",
            value: 20579,
            slug: "dien-nam-trung",
            status: "empty",
          },
          {
            label: "Điện Nam Đông",
            value: 20580,
            slug: "dien-nam-dong",
            status: "empty",
          },
          {
            label: "Điện Dương",
            value: 20581,
            slug: "dien-duong",
            status: "empty",
          },
          {
            label: "Điện Quang",
            value: 20584,
            slug: "dien-quang",
            status: "empty",
          },
          {
            label: "Điện Trung",
            value: 20587,
            slug: "dien-trung",
            status: "empty",
          },
          {
            label: "Điện Phong",
            value: 20590,
            slug: "dien-phong",
            status: "empty",
          },
          {
            label: "Điện Minh",
            value: 20593,
            slug: "dien-minh",
            status: "empty",
          },
          {
            label: "Điện Phương",
            value: 20596,
            slug: "dien-phuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Duy Xuyên",
        value: 508,
        slug: "huyen-duy-xuyen",
        status: "empty",
        ward: [
          {
            label: "Nam Phước",
            value: 20599,
            slug: "nam-phuoc",
            status: "empty",
          },
          { label: "Duy Phú", value: 20605, slug: "duy-phu", status: "empty" },
          { label: "Duy Tân", value: 20608, slug: "duy-tan", status: "empty" },
          { label: "Duy Hòa", value: 20611, slug: "duy-hoa", status: "empty" },
          {
            label: "Duy Châu",
            value: 20614,
            slug: "duy-chau",
            status: "empty",
          },
          {
            label: "Duy Trinh",
            value: 20617,
            slug: "duy-trinh",
            status: "empty",
          },
          { label: "Duy Sơn", value: 20620, slug: "duy-son", status: "empty" },
          {
            label: "Duy Trung",
            value: 20623,
            slug: "duy-trung",
            status: "empty",
          },
          {
            label: "Duy Phước",
            value: 20626,
            slug: "duy-phuoc",
            status: "empty",
          },
          {
            label: "Duy Thành",
            value: 20629,
            slug: "duy-thanh",
            status: "empty",
          },
          {
            label: "Duy Vinh",
            value: 20632,
            slug: "duy-vinh",
            status: "empty",
          },
          {
            label: "Duy Nghĩa",
            value: 20635,
            slug: "duy-nghia",
            status: "empty",
          },
          { label: "Duy Hải", value: 20638, slug: "duy-hai", status: "empty" },
        ],
      },
      {
        label: "Huyện Quế Sơn",
        value: 509,
        slug: "huyen-que-son",
        status: "empty",
        ward: [
          {
            label: "Đông Phú",
            value: 20641,
            slug: "dong-phu",
            status: "empty",
          },
          {
            label: "Quế Xuân 1",
            value: 20644,
            slug: "que-xuan-1",
            status: "empty",
          },
          {
            label: "Quế Xuân 2",
            value: 20647,
            slug: "que-xuan-2",
            status: "empty",
          },
          { label: "Quế Phú", value: 20650, slug: "que-phu", status: "empty" },
          {
            label: "Hương An",
            value: 20651,
            slug: "huong-an",
            status: "empty",
          },
          {
            label: "Trung Phước",
            value: 20656,
            slug: "trung-phuoc",
            status: "empty",
          },
          {
            label: "Quế Hiệp",
            value: 20659,
            slug: "que-hiep",
            status: "empty",
          },
          {
            label: "Quế Thuận",
            value: 20662,
            slug: "que-thuan",
            status: "empty",
          },
          { label: "Quế Mỹ", value: 20665, slug: "que-my", status: "empty" },
          {
            label: "Ninh Phước",
            value: 20668,
            slug: "ninh-phuoc",
            status: "empty",
          },
          {
            label: "Phước Ninh",
            value: 20669,
            slug: "phuoc-ninh",
            status: "empty",
          },
          { label: "Quế Lộc", value: 20671, slug: "que-loc", status: "empty" },
          {
            label: "Quế Phước",
            value: 20674,
            slug: "que-phuoc",
            status: "empty",
          },
          {
            label: "Quế Long",
            value: 20677,
            slug: "que-long",
            status: "empty",
          },
          {
            label: "Quế Châu",
            value: 20680,
            slug: "que-chau",
            status: "empty",
          },
          {
            label: "Quế Phong",
            value: 20683,
            slug: "que-phong",
            status: "empty",
          },
          { label: "Quế An", value: 20686, slug: "que-an", status: "empty" },
          {
            label: "Quế Minh",
            value: 20689,
            slug: "que-minh",
            status: "empty",
          },
          { label: "Quế Lâm", value: 20692, slug: "que-lam", status: "empty" },
        ],
      },
      {
        label: "Huyện Nam Giang",
        value: 510,
        slug: "huyen-nam-giang",
        status: "empty",
        ward: [
          {
            label: "Thạnh Mỹ",
            value: 20695,
            slug: "thanh-my",
            status: "empty",
          },
          { label: "Xã Laêê", value: 20698, slug: "xa-laee", status: "empty" },
          {
            label: "Chơ Chun",
            value: 20699,
            slug: "cho-chun",
            status: "empty",
          },
          {
            label: "Xã Zuôich",
            value: 20701,
            slug: "xa-zuoich",
            status: "empty",
          },
          { label: "Tà Pơơ", value: 20702, slug: "ta-poo", status: "empty" },
          { label: "La Dêê", value: 20704, slug: "la-dee", status: "empty" },
          { label: "Đắc Tôi", value: 20705, slug: "dac-toi", status: "empty" },
          { label: "Chà Vàl", value: 20707, slug: "cha-val", status: "empty" },
          {
            label: "Tà Bhinh",
            value: 20710,
            slug: "ta-bhinh",
            status: "empty",
          },
          { label: "Cà Dy", value: 20713, slug: "ca-dy", status: "empty" },
          { label: "Đắc Pre", value: 20716, slug: "dac-pre", status: "empty" },
          {
            label: "Đắc Pring",
            value: 20719,
            slug: "dac-pring",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phước Sơn",
        value: 511,
        slug: "huyen-phuoc-son",
        status: "empty",
        ward: [
          {
            label: "Khâm Đức",
            value: 20722,
            slug: "kham-duc",
            status: "empty",
          },
          {
            label: "Phước Xuân",
            value: 20725,
            slug: "phuoc-xuan",
            status: "empty",
          },
          {
            label: "Phước Hiệp",
            value: 20728,
            slug: "phuoc-hiep",
            status: "empty",
          },
          {
            label: "Phước Hoà",
            value: 20729,
            slug: "phuoc-hoa",
            status: "empty",
          },
          {
            label: "Phước Đức",
            value: 20731,
            slug: "phuoc-duc",
            status: "empty",
          },
          {
            label: "Phước Năng",
            value: 20734,
            slug: "phuoc-nang",
            status: "empty",
          },
          {
            label: "Phước Mỹ",
            value: 20737,
            slug: "phuoc-my",
            status: "empty",
          },
          {
            label: "Phước Chánh",
            value: 20740,
            slug: "phuoc-chanh",
            status: "empty",
          },
          {
            label: "Phước Công",
            value: 20743,
            slug: "phuoc-cong",
            status: "empty",
          },
          {
            label: "Phước Kim",
            value: 20746,
            slug: "phuoc-kim",
            status: "empty",
          },
          {
            label: "Phước Lộc",
            value: 20749,
            slug: "phuoc-loc",
            status: "empty",
          },
          {
            label: "Phước Thành",
            value: 20752,
            slug: "phuoc-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hiệp Đức",
        value: 512,
        slug: "huyen-hiep-duc",
        status: "empty",
        ward: [
          { label: "Quế Tân", value: 20758, slug: "que-tan", status: "empty" },
          { label: "Quế Thọ", value: 20764, slug: "que-tho", status: "empty" },
          {
            label: "Bình Lâm",
            value: 20767,
            slug: "binh-lam",
            status: "empty",
          },
          {
            label: "Sông Trà",
            value: 20770,
            slug: "song-tra",
            status: "empty",
          },
          {
            label: "Phước Trà",
            value: 20773,
            slug: "phuoc-tra",
            status: "empty",
          },
          {
            label: "Phước Gia",
            value: 20776,
            slug: "phuoc-gia",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 20779,
            slug: "tan-binh",
            status: "empty",
          },
          { label: "Quế Lưu", value: 20782, slug: "que-luu", status: "empty" },
          {
            label: "Thăng Phước",
            value: 20785,
            slug: "thang-phuoc",
            status: "empty",
          },
          {
            label: "Bình Sơn",
            value: 20788,
            slug: "binh-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thăng Bình",
        value: 513,
        slug: "huyen-thang-binh",
        status: "empty",
        ward: [
          { label: "Hà Lam", value: 20791, slug: "ha-lam", status: "empty" },
          {
            label: "Bình Dương",
            value: 20794,
            slug: "binh-duong",
            status: "empty",
          },
          {
            label: "Bình Giang",
            value: 20797,
            slug: "binh-giang",
            status: "empty",
          },
          {
            label: "Bình Nguyên",
            value: 20800,
            slug: "binh-nguyen",
            status: "empty",
          },
          {
            label: "Bình Phục",
            value: 20803,
            slug: "binh-phuc",
            status: "empty",
          },
          {
            label: "Bình Triều",
            value: 20806,
            slug: "binh-trieu",
            status: "empty",
          },
          {
            label: "Bình Đào",
            value: 20809,
            slug: "binh-dao",
            status: "empty",
          },
          {
            label: "Bình Minh",
            value: 20812,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Bình Lãnh",
            value: 20815,
            slug: "binh-lanh",
            status: "empty",
          },
          {
            label: "Bình Trị",
            value: 20818,
            slug: "binh-tri",
            status: "empty",
          },
          {
            label: "Bình Định",
            value: 20821,
            slug: "binh-dinh",
            status: "empty",
          },
          {
            label: "Bình Quý",
            value: 20824,
            slug: "binh-quy",
            status: "empty",
          },
          {
            label: "Bình Phú",
            value: 20827,
            slug: "binh-phu",
            status: "empty",
          },
          { label: "Bình Tú", value: 20833, slug: "binh-tu", status: "empty" },
          { label: "Bình Sa", value: 20836, slug: "binh-sa", status: "empty" },
          {
            label: "Bình Hải",
            value: 20839,
            slug: "binh-hai",
            status: "empty",
          },
          {
            label: "Bình Quế",
            value: 20842,
            slug: "binh-que",
            status: "empty",
          },
          { label: "Bình An", value: 20845, slug: "binh-an", status: "empty" },
          {
            label: "Bình Trung",
            value: 20848,
            slug: "binh-trung",
            status: "empty",
          },
          {
            label: "Bình Nam",
            value: 20851,
            slug: "binh-nam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tiên Phước",
        value: 514,
        slug: "huyen-tien-phuoc",
        status: "empty",
        ward: [
          { label: "Tiên Kỳ", value: 20854, slug: "tien-ky", status: "empty" },
          {
            label: "Tiên Sơn",
            value: 20857,
            slug: "tien-son",
            status: "empty",
          },
          { label: "Tiên Hà", value: 20860, slug: "tien-ha", status: "empty" },
          {
            label: "Tiên Châu",
            value: 20866,
            slug: "tien-chau",
            status: "empty",
          },
          {
            label: "Tiên Lãnh",
            value: 20869,
            slug: "tien-lanh",
            status: "empty",
          },
          {
            label: "Tiên Ngọc",
            value: 20872,
            slug: "tien-ngoc",
            status: "empty",
          },
          {
            label: "Tiên Hiệp",
            value: 20875,
            slug: "tien-hiep",
            status: "empty",
          },
          {
            label: "Tiên Cảnh",
            value: 20878,
            slug: "tien-canh",
            status: "empty",
          },
          { label: "Tiên Mỹ", value: 20881, slug: "tien-my", status: "empty" },
          {
            label: "Tiên Phong",
            value: 20884,
            slug: "tien-phong",
            status: "empty",
          },
          {
            label: "Tiên Thọ",
            value: 20887,
            slug: "tien-tho",
            status: "empty",
          },
          { label: "Tiên An", value: 20890, slug: "tien-an", status: "empty" },
          {
            label: "Tiên Lộc",
            value: 20893,
            slug: "tien-loc",
            status: "empty",
          },
          {
            label: "Tiên Lập",
            value: 20896,
            slug: "tien-lap",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bắc Trà My",
        value: 515,
        slug: "huyen-bac-tra-my",
        status: "empty",
        ward: [
          { label: "Trà My", value: 20899, slug: "tra-my", status: "empty" },
          { label: "Trà Sơn", value: 20900, slug: "tra-son", status: "empty" },
          { label: "Trà Kót", value: 20902, slug: "tra-kot", status: "empty" },
          { label: "Trà Nú", value: 20905, slug: "tra-nu", status: "empty" },
          {
            label: "Trà Đông",
            value: 20908,
            slug: "tra-dong",
            status: "empty",
          },
          {
            label: "Trà Dương",
            value: 20911,
            slug: "tra-duong",
            status: "empty",
          },
          {
            label: "Trà Giang",
            value: 20914,
            slug: "tra-giang",
            status: "empty",
          },
          { label: "Trà Bui", value: 20917, slug: "tra-bui", status: "empty" },
          { label: "Trà Đốc", value: 20920, slug: "tra-doc", status: "empty" },
          { label: "Trà Tân", value: 20923, slug: "tra-tan", status: "empty" },
          {
            label: "Trà Giác",
            value: 20926,
            slug: "tra-giac",
            status: "empty",
          },
          {
            label: "Trà Giáp",
            value: 20929,
            slug: "tra-giap",
            status: "empty",
          },
          { label: "Trà Ka", value: 20932, slug: "tra-ka", status: "empty" },
        ],
      },
      {
        label: "Huyện Nam Trà My",
        value: 516,
        slug: "huyen-nam-tra-my",
        status: "empty",
        ward: [
          {
            label: "Trà Leng",
            value: 20935,
            slug: "tra-leng",
            status: "empty",
          },
          { label: "Trà Dơn", value: 20938, slug: "tra-don", status: "empty" },
          { label: "Trà Tập", value: 20941, slug: "tra-tap", status: "empty" },
          { label: "Trà Mai", value: 20944, slug: "tra-mai", status: "empty" },
          {
            label: "Trà Cang",
            value: 20947,
            slug: "tra-cang",
            status: "empty",
          },
          {
            label: "Trà Linh",
            value: 20950,
            slug: "tra-linh",
            status: "empty",
          },
          { label: "Trà Nam", value: 20953, slug: "tra-nam", status: "empty" },
          { label: "Trà Don", value: 20956, slug: "tra-don", status: "empty" },
          { label: "Trà Vân", value: 20959, slug: "tra-van", status: "empty" },
          {
            label: "Trà Vinh",
            value: 20962,
            slug: "tra-vinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Núi Thành",
        value: 517,
        slug: "huyen-nui-thanh",
        status: "empty",
        ward: [
          {
            label: "Núi Thành",
            value: 20965,
            slug: "nui-thanh",
            status: "empty",
          },
          {
            label: "Tam Xuân I",
            value: 20968,
            slug: "tam-xuan-i",
            status: "empty",
          },
          {
            label: "Tam Xuân II",
            value: 20971,
            slug: "tam-xuan-ii",
            status: "empty",
          },
          {
            label: "Tam Tiến",
            value: 20974,
            slug: "tam-tien",
            status: "empty",
          },
          { label: "Tam Sơn", value: 20977, slug: "tam-son", status: "empty" },
          {
            label: "Tam Thạnh",
            value: 20980,
            slug: "tam-thanh",
            status: "empty",
          },
          {
            label: "Tam Anh Bắc",
            value: 20983,
            slug: "tam-anh-bac",
            status: "empty",
          },
          {
            label: "Tam Anh Nam",
            value: 20984,
            slug: "tam-anh-nam",
            status: "empty",
          },
          { label: "Tam Hòa", value: 20986, slug: "tam-hoa", status: "empty" },
          {
            label: "Tam Hiệp",
            value: 20989,
            slug: "tam-hiep",
            status: "empty",
          },
          { label: "Tam Hải", value: 20992, slug: "tam-hai", status: "empty" },
          {
            label: "Tam Giang",
            value: 20995,
            slug: "tam-giang",
            status: "empty",
          },
          {
            label: "Tam Quang",
            value: 20998,
            slug: "tam-quang",
            status: "empty",
          },
          {
            label: "Tam Nghĩa",
            value: 21001,
            slug: "tam-nghia",
            status: "empty",
          },
          {
            label: "Tam Mỹ Tây",
            value: 21004,
            slug: "tam-my-tay",
            status: "empty",
          },
          {
            label: "Tam Mỹ Đông",
            value: 21005,
            slug: "tam-my-dong",
            status: "empty",
          },
          { label: "Tam Trà", value: 21007, slug: "tam-tra", status: "empty" },
        ],
      },
      {
        label: "Huyện Phú Ninh",
        value: 518,
        slug: "huyen-phu-ninh",
        status: "empty",
        ward: [
          {
            label: "Phú Thịnh",
            value: 20364,
            slug: "phu-thinh",
            status: "empty",
          },
          {
            label: "Tam Thành",
            value: 20365,
            slug: "tam-thanh",
            status: "empty",
          },
          { label: "Tam An", value: 20368, slug: "tam-an", status: "empty" },
          { label: "Tam Đàn", value: 20374, slug: "tam-dan", status: "empty" },
          { label: "Tam Lộc", value: 20377, slug: "tam-loc", status: "empty" },
          {
            label: "Tam Phước",
            value: 20380,
            slug: "tam-phuoc",
            status: "empty",
          },
          {
            label: "Tam Thái",
            value: 20386,
            slug: "tam-thai",
            status: "empty",
          },
          { label: "Tam Đại", value: 20387, slug: "tam-dai", status: "empty" },
          { label: "Tam Dân", value: 20392, slug: "tam-dan", status: "empty" },
          {
            label: "Tam Lãnh",
            value: 20395,
            slug: "tam-lanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nông Sơn",
        value: 519,
        slug: "huyen-nong-son",
        status: "empty",
        ward: [
          {
            label: "Nông Sơn",
            value: 101300,
            slug: "nong-son",
            status: "empty",
          },
          { label: "Quế Lâm", value: 101301, slug: "que-lam", status: "empty" },
          {
            label: "Quế Cường",
            value: 101302,
            slug: "que-cuong",
            status: "empty",
          },
          { label: "Quế Thọ", value: 101303, slug: "que-tho", status: "empty" },
          {
            label: "Quế Long",
            value: 101304,
            slug: "que-long",
            status: "empty",
          },
          {
            label: "Ninh Phước",
            value: 101305,
            slug: "ninh-phuoc",
            status: "empty",
          },
          {
            label: "Ninh Thượng",
            value: 101306,
            slug: "ninh-thuong",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Quảng Ngãi",
    value: 51,
    slug: "quang-ngai",
    status: "empty",
    district: [
      {
        label: "Thành phố Quảng Ngãi",
        value: 522,
        slug: "thanh-pho-quang-ngai",
        status: "empty",
        ward: [
          {
            label: "Lê Hồng Phong",
            value: 21010,
            slug: "le-hong-phong",
            status: "empty",
          },
          {
            label: "Trần Phú",
            value: 21013,
            slug: "tran-phu",
            status: "empty",
          },
          {
            label: "Quảng Phú",
            value: 21016,
            slug: "quang-phu",
            status: "empty",
          },
          {
            label: "Nghĩa Chánh",
            value: 21019,
            slug: "nghia-chanh",
            status: "empty",
          },
          {
            label: "Trần Hưng Đạo",
            value: 21022,
            slug: "tran-hung-dao",
            status: "empty",
          },
          {
            label: "Nguyễn Nghiêm",
            value: 21025,
            slug: "nguyen-nghiem",
            status: "empty",
          },
          {
            label: "Nghĩa Lộ",
            value: 21028,
            slug: "nghia-lo",
            status: "empty",
          },
          {
            label: "Chánh Lộ",
            value: 21031,
            slug: "chanh-lo",
            status: "empty",
          },
          {
            label: "Nghĩa Dũng",
            value: 21034,
            slug: "nghia-dung",
            status: "empty",
          },
          {
            label: "Nghĩa Dõng",
            value: 21037,
            slug: "nghia-dong",
            status: "empty",
          },
          {
            label: "Trương Quang Trọng",
            value: 21172,
            slug: "truong-quang-trong",
            status: "empty",
          },
          {
            label: "Tịnh Hòa",
            value: 21187,
            slug: "tinh-hoa",
            status: "empty",
          },
          { label: "Tịnh Kỳ", value: 21190, slug: "tinh-ky", status: "empty" },
          {
            label: "Tịnh Thiện",
            value: 21199,
            slug: "tinh-thien",
            status: "empty",
          },
          {
            label: "Tịnh Ấn Đông",
            value: 21202,
            slug: "tinh-an-dong",
            status: "empty",
          },
          {
            label: "Tịnh Châu",
            value: 21208,
            slug: "tinh-chau",
            status: "empty",
          },
          {
            label: "Tịnh Khê",
            value: 21211,
            slug: "tinh-khe",
            status: "empty",
          },
          {
            label: "Tịnh Long",
            value: 21214,
            slug: "tinh-long",
            status: "empty",
          },
          {
            label: "Tịnh Ấn Tây",
            value: 21223,
            slug: "tinh-an-tay",
            status: "empty",
          },
          { label: "Tịnh An", value: 21232, slug: "tinh-an", status: "empty" },
          {
            label: "Nghĩa Hà",
            value: 21256,
            slug: "nghia-ha",
            status: "empty",
          },
          { label: "An Phú", value: 21262, slug: "an-phu", status: "empty" },
        ],
      },
      {
        label: "Huyện Bình Sơn",
        value: 524,
        slug: "huyen-binh-son",
        status: "empty",
        ward: [
          { label: "Châu Ổ", value: 21040, slug: "chau-o", status: "empty" },
          {
            label: "Bình Thuận",
            value: 21043,
            slug: "binh-thuan",
            status: "empty",
          },
          {
            label: "Bình Thạnh",
            value: 21046,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Bình Đông",
            value: 21049,
            slug: "binh-dong",
            status: "empty",
          },
          {
            label: "Bình Chánh",
            value: 21052,
            slug: "binh-chanh",
            status: "empty",
          },
          {
            label: "Bình Nguyên",
            value: 21055,
            slug: "binh-nguyen",
            status: "empty",
          },
          {
            label: "Bình Khương",
            value: 21058,
            slug: "binh-khuong",
            status: "empty",
          },
          {
            label: "Bình Trị",
            value: 21061,
            slug: "binh-tri",
            status: "empty",
          },
          { label: "Bình An", value: 21064, slug: "binh-an", status: "empty" },
          {
            label: "Bình Hải",
            value: 21067,
            slug: "binh-hai",
            status: "empty",
          },
          {
            label: "Bình Dương",
            value: 21070,
            slug: "binh-duong",
            status: "empty",
          },
          {
            label: "Bình Phước",
            value: 21073,
            slug: "binh-phuoc",
            status: "empty",
          },
          {
            label: "Bình Hòa",
            value: 21079,
            slug: "binh-hoa",
            status: "empty",
          },
          {
            label: "Bình Trung",
            value: 21082,
            slug: "binh-trung",
            status: "empty",
          },
          {
            label: "Bình Minh",
            value: 21085,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Bình Long",
            value: 21088,
            slug: "binh-long",
            status: "empty",
          },
          {
            label: "Bình Thanh",
            value: 21091,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Bình Chương",
            value: 21100,
            slug: "binh-chuong",
            status: "empty",
          },
          {
            label: "Bình Hiệp",
            value: 21103,
            slug: "binh-hiep",
            status: "empty",
          },
          { label: "Bình Mỹ", value: 21106, slug: "binh-my", status: "empty" },
          {
            label: "Bình Tân Phú",
            value: 21109,
            slug: "binh-tan-phu",
            status: "empty",
          },
          {
            label: "Bình Châu",
            value: 21112,
            slug: "binh-chau",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Trà Bồng",
        value: 525,
        slug: "huyen-tra-bong",
        status: "empty",
        ward: [
          {
            label: "Trà Xuân",
            value: 21115,
            slug: "tra-xuan",
            status: "empty",
          },
          {
            label: "Trà Giang",
            value: 21118,
            slug: "tra-giang",
            status: "empty",
          },
          {
            label: "Trà Thủy",
            value: 21121,
            slug: "tra-thuy",
            status: "empty",
          },
          {
            label: "Trà Hiệp",
            value: 21124,
            slug: "tra-hiep",
            status: "empty",
          },
          {
            label: "Trà Bình",
            value: 21127,
            slug: "tra-binh",
            status: "empty",
          },
          { label: "Trà Phú", value: 21130, slug: "tra-phu", status: "empty" },
          { label: "Trà Lâm", value: 21133, slug: "tra-lam", status: "empty" },
          { label: "Trà Tân", value: 21136, slug: "tra-tan", status: "empty" },
          { label: "Trà Sơn", value: 21139, slug: "tra-son", status: "empty" },
          { label: "Trà Bùi", value: 21142, slug: "tra-bui", status: "empty" },
          {
            label: "Trà Thanh",
            value: 21145,
            slug: "tra-thanh",
            status: "empty",
          },
          { label: "Sơn Trà", value: 21148, slug: "son-tra", status: "empty" },
          {
            label: "Trà Phong",
            value: 21154,
            slug: "tra-phong",
            status: "empty",
          },
          {
            label: "Hương Trà",
            value: 21157,
            slug: "huong-tra",
            status: "empty",
          },
          {
            label: "Trà Xinh",
            value: 21163,
            slug: "tra-xinh",
            status: "empty",
          },
          { label: "Trà Tây", value: 21166, slug: "tra-tay", status: "empty" },
        ],
      },
      {
        label: "Huyện Sơn Tịnh",
        value: 527,
        slug: "huyen-son-tinh",
        status: "empty",
        ward: [
          {
            label: "Tịnh Thọ",
            value: 21175,
            slug: "tinh-tho",
            status: "empty",
          },
          {
            label: "Tịnh Trà",
            value: 21178,
            slug: "tinh-tra",
            status: "empty",
          },
          {
            label: "Tịnh Phong",
            value: 21181,
            slug: "tinh-phong",
            status: "empty",
          },
          {
            label: "Tịnh Hiệp",
            value: 21184,
            slug: "tinh-hiep",
            status: "empty",
          },
          {
            label: "Tịnh Bình",
            value: 21193,
            slug: "tinh-binh",
            status: "empty",
          },
          {
            label: "Tịnh Đông",
            value: 21196,
            slug: "tinh-dong",
            status: "empty",
          },
          {
            label: "Tịnh Bắc",
            value: 21205,
            slug: "tinh-bac",
            status: "empty",
          },
          {
            label: "Tịnh Sơn",
            value: 21217,
            slug: "tinh-son",
            status: "empty",
          },
          { label: "Tịnh Hà", value: 21220, slug: "tinh-ha", status: "empty" },
          {
            label: "Tịnh Giang",
            value: 21226,
            slug: "tinh-giang",
            status: "empty",
          },
          {
            label: "Tịnh Minh",
            value: 21229,
            slug: "tinh-minh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tư Nghĩa",
        value: 528,
        slug: "huyen-tu-nghia",
        status: "empty",
        ward: [
          { label: "La Hà", value: 21235, slug: "la-ha", status: "empty" },
          { label: "Sông Vệ", value: 21238, slug: "song-ve", status: "empty" },
          {
            label: "Nghĩa Lâm",
            value: 21241,
            slug: "nghia-lam",
            status: "empty",
          },
          {
            label: "Nghĩa Thắng",
            value: 21244,
            slug: "nghia-thang",
            status: "empty",
          },
          {
            label: "Nghĩa Thuận",
            value: 21247,
            slug: "nghia-thuan",
            status: "empty",
          },
          {
            label: "Nghĩa Kỳ",
            value: 21250,
            slug: "nghia-ky",
            status: "empty",
          },
          {
            label: "Nghĩa Sơn",
            value: 21259,
            slug: "nghia-son",
            status: "empty",
          },
          {
            label: "Nghĩa Hòa",
            value: 21268,
            slug: "nghia-hoa",
            status: "empty",
          },
          {
            label: "Nghĩa Điền",
            value: 21271,
            slug: "nghia-dien",
            status: "empty",
          },
          {
            label: "Nghĩa Thương",
            value: 21274,
            slug: "nghia-thuong",
            status: "empty",
          },
          {
            label: "Nghĩa Trung",
            value: 21277,
            slug: "nghia-trung",
            status: "empty",
          },
          {
            label: "Nghĩa Hiệp",
            value: 21280,
            slug: "nghia-hiep",
            status: "empty",
          },
          {
            label: "Nghĩa Phương",
            value: 21283,
            slug: "nghia-phuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Sơn Hà",
        value: 529,
        slug: "huyen-son-ha",
        status: "empty",
        ward: [
          { label: "Di Lăng", value: 21289, slug: "di-lang", status: "empty" },
          { label: "Sơn Hạ", value: 21292, slug: "son-ha", status: "empty" },
          {
            label: "Sơn Thành",
            value: 21295,
            slug: "son-thanh",
            status: "empty",
          },
          {
            label: "Sơn Nham",
            value: 21298,
            slug: "son-nham",
            status: "empty",
          },
          { label: "Sơn Bao", value: 21301, slug: "son-bao", status: "empty" },
          {
            label: "Sơn Linh",
            value: 21304,
            slug: "son-linh",
            status: "empty",
          },
          {
            label: "Sơn Giang",
            value: 21307,
            slug: "son-giang",
            status: "empty",
          },
          {
            label: "Sơn Trung",
            value: 21310,
            slug: "son-trung",
            status: "empty",
          },
          {
            label: "Sơn Thượng",
            value: 21313,
            slug: "son-thuong",
            status: "empty",
          },
          { label: "Sơn Cao", value: 21316, slug: "son-cao", status: "empty" },
          { label: "Sơn Hải", value: 21319, slug: "son-hai", status: "empty" },
          {
            label: "Sơn Thủy",
            value: 21322,
            slug: "son-thuy",
            status: "empty",
          },
          { label: "Sơn Kỳ", value: 21325, slug: "son-ky", status: "empty" },
          { label: "Sơn Ba", value: 21328, slug: "son-ba", status: "empty" },
        ],
      },
      {
        label: "Huyện Sơn Tây",
        value: 530,
        slug: "huyen-son-tay",
        status: "empty",
        ward: [
          { label: "Sơn Bua", value: 21331, slug: "son-bua", status: "empty" },
          { label: "Sơn Mùa", value: 21334, slug: "son-mua", status: "empty" },
          {
            label: "Sơn Liên",
            value: 21335,
            slug: "son-lien",
            status: "empty",
          },
          { label: "Sơn Tân", value: 21337, slug: "son-tan", status: "empty" },
          { label: "Sơn Màu", value: 21338, slug: "son-mau", status: "empty" },
          {
            label: "Sơn Dung",
            value: 21340,
            slug: "son-dung",
            status: "empty",
          },
          {
            label: "Sơn Long",
            value: 21341,
            slug: "son-long",
            status: "empty",
          },
          {
            label: "Sơn Tinh",
            value: 21343,
            slug: "son-tinh",
            status: "empty",
          },
          { label: "Sơn Lập", value: 21346, slug: "son-lap", status: "empty" },
        ],
      },
      {
        label: "Huyện Minh Long",
        value: 531,
        slug: "huyen-minh-long",
        status: "empty",
        ward: [
          {
            label: "Long Sơn",
            value: 21349,
            slug: "long-son",
            status: "empty",
          },
          {
            label: "Long Mai",
            value: 21352,
            slug: "long-mai",
            status: "empty",
          },
          {
            label: "Thanh An",
            value: 21355,
            slug: "thanh-an",
            status: "empty",
          },
          {
            label: "Long Môn",
            value: 21358,
            slug: "long-mon",
            status: "empty",
          },
          {
            label: "Long Hiệp",
            value: 21361,
            slug: "long-hiep",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nghĩa Hành",
        value: 532,
        slug: "huyen-nghia-hanh",
        status: "empty",
        ward: [
          {
            label: "Chợ Chùa",
            value: 21364,
            slug: "cho-chua",
            status: "empty",
          },
          {
            label: "Hành Thuận",
            value: 21367,
            slug: "hanh-thuan",
            status: "empty",
          },
          {
            label: "Hành Dũng",
            value: 21370,
            slug: "hanh-dung",
            status: "empty",
          },
          {
            label: "Hành Trung",
            value: 21373,
            slug: "hanh-trung",
            status: "empty",
          },
          {
            label: "Hành Nhân",
            value: 21376,
            slug: "hanh-nhan",
            status: "empty",
          },
          {
            label: "Hành Đức",
            value: 21379,
            slug: "hanh-duc",
            status: "empty",
          },
          {
            label: "Hành Minh",
            value: 21382,
            slug: "hanh-minh",
            status: "empty",
          },
          {
            label: "Hành Phước",
            value: 21385,
            slug: "hanh-phuoc",
            status: "empty",
          },
          {
            label: "Hành Thiện",
            value: 21388,
            slug: "hanh-thien",
            status: "empty",
          },
          {
            label: "Hành Thịnh",
            value: 21391,
            slug: "hanh-thinh",
            status: "empty",
          },
          {
            label: "Hành Tín Tây",
            value: 21394,
            slug: "hanh-tin-tay",
            status: "empty",
          },
          {
            label: "Hành Tín Đông",
            value: 21397,
            slug: "hanh-tin-dong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Mộ Đức",
        value: 533,
        slug: "huyen-mo-duc",
        status: "empty",
        ward: [
          { label: "Mộ Đức", value: 21400, slug: "mo-duc", status: "empty" },
          {
            label: "Thắng Lợi",
            value: 21406,
            slug: "thang-loi",
            status: "empty",
          },
          {
            label: "Đức Nhuận",
            value: 21409,
            slug: "duc-nhuan",
            status: "empty",
          },
          {
            label: "Đức Chánh",
            value: 21412,
            slug: "duc-chanh",
            status: "empty",
          },
          {
            label: "Đức Hiệp",
            value: 21415,
            slug: "duc-hiep",
            status: "empty",
          },
          {
            label: "Đức Minh",
            value: 21418,
            slug: "duc-minh",
            status: "empty",
          },
          {
            label: "Đức Thạnh",
            value: 21421,
            slug: "duc-thanh",
            status: "empty",
          },
          { label: "Đức Hòa", value: 21424, slug: "duc-hoa", status: "empty" },
          { label: "Đức Tân", value: 21427, slug: "duc-tan", status: "empty" },
          { label: "Đức Phú", value: 21430, slug: "duc-phu", status: "empty" },
          {
            label: "Đức Phong",
            value: 21433,
            slug: "duc-phong",
            status: "empty",
          },
          { label: "Đức Lân", value: 21436, slug: "duc-lan", status: "empty" },
        ],
      },
      {
        label: "Thị xã Đức Phổ",
        value: 534,
        slug: "thi-xa-duc-pho",
        status: "empty",
        ward: [
          {
            label: "Nguyễn Nghiêm",
            value: 21439,
            slug: "nguyen-nghiem",
            status: "empty",
          },
          { label: "Phổ An", value: 21442, slug: "pho-an", status: "empty" },
          {
            label: "Phổ Phong",
            value: 21445,
            slug: "pho-phong",
            status: "empty",
          },
          {
            label: "Phổ Thuận",
            value: 21448,
            slug: "pho-thuan",
            status: "empty",
          },
          { label: "Phổ Văn", value: 21451, slug: "pho-van", status: "empty" },
          {
            label: "Phổ Quang",
            value: 21454,
            slug: "pho-quang",
            status: "empty",
          },
          {
            label: "Phổ Nhơn",
            value: 21457,
            slug: "pho-nhon",
            status: "empty",
          },
          {
            label: "Phổ Ninh",
            value: 21460,
            slug: "pho-ninh",
            status: "empty",
          },
          {
            label: "Phổ Minh",
            value: 21463,
            slug: "pho-minh",
            status: "empty",
          },
          {
            label: "Phổ Vinh",
            value: 21466,
            slug: "pho-vinh",
            status: "empty",
          },
          { label: "Phổ Hòa", value: 21469, slug: "pho-hoa", status: "empty" },
          {
            label: "Phổ Cường",
            value: 21472,
            slug: "pho-cuong",
            status: "empty",
          },
          {
            label: "Phổ Khánh",
            value: 21475,
            slug: "pho-khanh",
            status: "empty",
          },
          {
            label: "Phổ Thạnh",
            value: 21478,
            slug: "pho-thanh",
            status: "empty",
          },
          {
            label: "Phổ Châu",
            value: 21481,
            slug: "pho-chau",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ba Tơ",
        value: 535,
        slug: "huyen-ba-to",
        status: "empty",
        ward: [
          { label: "Ba Tơ", value: 21484, slug: "ba-to", status: "empty" },
          { label: "Ba Điền", value: 21487, slug: "ba-dien", status: "empty" },
          { label: "Ba Vinh", value: 21490, slug: "ba-vinh", status: "empty" },
          {
            label: "Ba Thành",
            value: 21493,
            slug: "ba-thanh",
            status: "empty",
          },
          { label: "Ba Động", value: 21496, slug: "ba-dong", status: "empty" },
          { label: "Ba Dinh", value: 21499, slug: "ba-dinh", status: "empty" },
          {
            label: "Ba Giang",
            value: 21500,
            slug: "ba-giang",
            status: "empty",
          },
          { label: "Ba Liên", value: 21502, slug: "ba-lien", status: "empty" },
          { label: "Ba Ngạc", value: 21505, slug: "ba-ngac", status: "empty" },
          { label: "Ba Khâm", value: 21508, slug: "ba-kham", status: "empty" },
          { label: "Ba Cung", value: 21511, slug: "ba-cung", status: "empty" },
          { label: "Ba Tiêu", value: 21517, slug: "ba-tieu", status: "empty" },
          {
            label: "Ba Trang",
            value: 21520,
            slug: "ba-trang",
            status: "empty",
          },
          { label: "Ba Tô", value: 21523, slug: "ba-to", status: "empty" },
          { label: "Ba Bích", value: 21526, slug: "ba-bich", status: "empty" },
          { label: "Ba Vì", value: 21529, slug: "ba-vi", status: "empty" },
          { label: "Ba Lế", value: 21532, slug: "ba-le", status: "empty" },
          { label: "Ba Nam", value: 21535, slug: "ba-nam", status: "empty" },
          { label: "Ba Xa", value: 21538, slug: "ba-xa", status: "empty" },
        ],
      },
      {
        label: "Huyện Lý Sơn",
        value: 536,
        slug: "huyen-ly-son",
        status: "empty",
        ward: [
          { label: "An Vĩnh", value: 101400, slug: "an-vinh", status: "empty" },
          { label: "An Hải", value: 101401, slug: "an-hai", status: "empty" },
          { label: "An Bình", value: 101402, slug: "an-binh", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Bình Định",
    value: 52,
    slug: "binh-dinh",
    status: "empty",
    district: [
      {
        label: "Thành phố Quy Nhơn",
        value: 540,
        slug: "thanh-pho-quy-nhon",
        status: "empty",
        ward: [
          {
            label: "Nhơn Bình",
            value: 21550,
            slug: "nhon-binh",
            status: "empty",
          },
          {
            label: "Nhơn Phú",
            value: 21553,
            slug: "nhon-phu",
            status: "empty",
          },
          { label: "Đống Đa", value: 21556, slug: "dong-da", status: "empty" },
          {
            label: "Trần Quang Diệu",
            value: 21559,
            slug: "tran-quang-dieu",
            status: "empty",
          },
          {
            label: "Hải Cảng",
            value: 21562,
            slug: "hai-cang",
            status: "empty",
          },
          {
            label: "Quang Trung",
            value: 21565,
            slug: "quang-trung",
            status: "empty",
          },
          { label: "Ngô Mây", value: 21577, slug: "ngo-may", status: "empty" },
          {
            label: "Trần Phú",
            value: 21580,
            slug: "tran-phu",
            status: "empty",
          },
          { label: "Thị Nại", value: 21583, slug: "thi-nai", status: "empty" },
          {
            label: "Bùi Thị Xuân",
            value: 21589,
            slug: "bui-thi-xuan",
            status: "empty",
          },
          {
            label: "Nguyễn Văn Cừ",
            value: 21592,
            slug: "nguyen-van-cu",
            status: "empty",
          },
          {
            label: "Ghềnh Ráng",
            value: 21595,
            slug: "ghenh-rang",
            status: "empty",
          },
          { label: "Nhơn Lý", value: 21598, slug: "nhon-ly", status: "empty" },
          {
            label: "Nhơn Hội",
            value: 21601,
            slug: "nhon-hoi",
            status: "empty",
          },
          {
            label: "Nhơn Hải",
            value: 21604,
            slug: "nhon-hai",
            status: "empty",
          },
          {
            label: "Nhơn Châu",
            value: 21607,
            slug: "nhon-chau",
            status: "empty",
          },
          {
            label: "Phước Mỹ",
            value: 21991,
            slug: "phuoc-my",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện An Lão",
        value: 542,
        slug: "huyen-an-lao",
        status: "empty",
        ward: [
          { label: "An Lão", value: 21609, slug: "an-lao", status: "empty" },
          { label: "An Hưng", value: 21610, slug: "an-hung", status: "empty" },
          {
            label: "An Trung",
            value: 21613,
            slug: "an-trung",
            status: "empty",
          },
          { label: "An Dũng", value: 21616, slug: "an-dung", status: "empty" },
          { label: "An Vinh", value: 21619, slug: "an-vinh", status: "empty" },
          { label: "An Toàn", value: 21622, slug: "an-toan", status: "empty" },
          { label: "An Tân", value: 21625, slug: "an-tan", status: "empty" },
          { label: "An Hòa", value: 21628, slug: "an-hoa", status: "empty" },
          {
            label: "An Quang",
            value: 21631,
            slug: "an-quang",
            status: "empty",
          },
          {
            label: "An Nghĩa",
            value: 21634,
            slug: "an-nghia",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Hoài Nhơn",
        value: 543,
        slug: "thi-xa-hoai-nhon",
        status: "empty",
        ward: [
          {
            label: "Tam Quan",
            value: 21637,
            slug: "tam-quan",
            status: "empty",
          },
          {
            label: "Bồng Sơn",
            value: 21640,
            slug: "bong-son",
            status: "empty",
          },
          {
            label: "Hoài Sơn",
            value: 21643,
            slug: "hoai-son",
            status: "empty",
          },
          {
            label: "Hoài Châu Bắc",
            value: 21646,
            slug: "hoai-chau-bac",
            status: "empty",
          },
          {
            label: "Hoài Châu",
            value: 21649,
            slug: "hoai-chau",
            status: "empty",
          },
          {
            label: "Hoài Phú",
            value: 21652,
            slug: "hoai-phu",
            status: "empty",
          },
          {
            label: "Tam Quan Bắc",
            value: 21655,
            slug: "tam-quan-bac",
            status: "empty",
          },
          {
            label: "Tam Quan Nam",
            value: 21658,
            slug: "tam-quan-nam",
            status: "empty",
          },
          {
            label: "Hoài Hảo",
            value: 21661,
            slug: "hoai-hao",
            status: "empty",
          },
          {
            label: "Hoài Thanh Tây",
            value: 21664,
            slug: "hoai-thanh-tay",
            status: "empty",
          },
          {
            label: "Hoài Thanh",
            value: 21667,
            slug: "hoai-thanh",
            status: "empty",
          },
          {
            label: "Hoài Hương",
            value: 21670,
            slug: "hoai-huong",
            status: "empty",
          },
          {
            label: "Hoài Tân",
            value: 21673,
            slug: "hoai-tan",
            status: "empty",
          },
          {
            label: "Hoài Hải",
            value: 21676,
            slug: "hoai-hai",
            status: "empty",
          },
          {
            label: "Hoài Xuân",
            value: 21679,
            slug: "hoai-xuan",
            status: "empty",
          },
          { label: "Hoài Mỹ", value: 21682, slug: "hoai-my", status: "empty" },
          {
            label: "Hoài Đức",
            value: 21685,
            slug: "hoai-duc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hoài Ân",
        value: 544,
        slug: "huyen-hoai-an",
        status: "empty",
        ward: [
          {
            label: "Tăng Bạt Hổ",
            value: 21688,
            slug: "tang-bat-ho",
            status: "empty",
          },
          {
            label: "Ân Hảo Tây",
            value: 21690,
            slug: "an-hao-tay",
            status: "empty",
          },
          {
            label: "Ân Hảo Đông",
            value: 21691,
            slug: "an-hao-dong",
            status: "empty",
          },
          { label: "Ân Sơn", value: 21694, slug: "an-son", status: "empty" },
          { label: "Ân Mỹ", value: 21697, slug: "an-my", status: "empty" },
          {
            label: "Đak Mang",
            value: 21700,
            slug: "dak-mang",
            status: "empty",
          },
          { label: "Ân Tín", value: 21703, slug: "an-tin", status: "empty" },
          {
            label: "Ân Thạnh",
            value: 21706,
            slug: "an-thanh",
            status: "empty",
          },
          {
            label: "Ân Phong",
            value: 21709,
            slug: "an-phong",
            status: "empty",
          },
          { label: "Ân Đức", value: 21712, slug: "an-duc", status: "empty" },
          { label: "Ân Hữu", value: 21715, slug: "an-huu", status: "empty" },
          { label: "Bok Tới", value: 21718, slug: "bok-toi", status: "empty" },
          {
            label: "Ân Tường Tây",
            value: 21721,
            slug: "an-tuong-tay",
            status: "empty",
          },
          {
            label: "Ân Tường Đông",
            value: 21724,
            slug: "an-tuong-dong",
            status: "empty",
          },
          {
            label: "Ân Nghĩa",
            value: 21727,
            slug: "an-nghia",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phù Mỹ",
        value: 545,
        slug: "huyen-phu-my",
        status: "empty",
        ward: [
          { label: "Phù Mỹ", value: 21730, slug: "phu-my", status: "empty" },
          {
            label: "Bình Dương",
            value: 21733,
            slug: "binh-duong",
            status: "empty",
          },
          { label: "Mỹ Đức", value: 21736, slug: "my-duc", status: "empty" },
          { label: "Mỹ Châu", value: 21739, slug: "my-chau", status: "empty" },
          {
            label: "Mỹ Thắng",
            value: 21742,
            slug: "my-thang",
            status: "empty",
          },
          { label: "Mỹ Lộc", value: 21745, slug: "my-loc", status: "empty" },
          { label: "Mỹ Lợi", value: 21748, slug: "my-loi", status: "empty" },
          { label: "Mỹ An", value: 21751, slug: "my-an", status: "empty" },
          {
            label: "Mỹ Phong",
            value: 21754,
            slug: "my-phong",
            status: "empty",
          },
          {
            label: "Mỹ Trinh",
            value: 21757,
            slug: "my-trinh",
            status: "empty",
          },
          { label: "Mỹ Thọ", value: 21760, slug: "my-tho", status: "empty" },
          { label: "Mỹ Hòa", value: 21763, slug: "my-hoa", status: "empty" },
          {
            label: "Mỹ Thành",
            value: 21766,
            slug: "my-thanh",
            status: "empty",
          },
          {
            label: "Mỹ Chánh",
            value: 21769,
            slug: "my-chanh",
            status: "empty",
          },
          {
            label: "Mỹ Quang",
            value: 21772,
            slug: "my-quang",
            status: "empty",
          },
          { label: "Mỹ Hiệp", value: 21775, slug: "my-hiep", status: "empty" },
          { label: "Mỹ Tài", value: 21778, slug: "my-tai", status: "empty" },
          { label: "Mỹ Cát", value: 21781, slug: "my-cat", status: "empty" },
          {
            label: "Mỹ Chánh Tây",
            value: 21784,
            slug: "my-chanh-tay",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vĩnh Thạnh",
        value: 546,
        slug: "huyen-vinh-thanh",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Thạnh",
            value: 21786,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Sơn",
            value: 21787,
            slug: "vinh-son",
            status: "empty",
          },
          {
            label: "Vĩnh Kim",
            value: 21790,
            slug: "vinh-kim",
            status: "empty",
          },
          {
            label: "Vĩnh Hiệp",
            value: 21796,
            slug: "vinh-hiep",
            status: "empty",
          },
          {
            label: "Vĩnh Hảo",
            value: 21799,
            slug: "vinh-hao",
            status: "empty",
          },
          {
            label: "Vĩnh Hòa",
            value: 21801,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Vĩnh Thịnh",
            value: 21802,
            slug: "vinh-thinh",
            status: "empty",
          },
          {
            label: "Vĩnh Thuận",
            value: 21804,
            slug: "vinh-thuan",
            status: "empty",
          },
          {
            label: "Vĩnh Quang",
            value: 21805,
            slug: "vinh-quang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tây Sơn",
        value: 547,
        slug: "huyen-tay-son",
        status: "empty",
        ward: [
          {
            label: "Phú Phong",
            value: 21808,
            slug: "phu-phong",
            status: "empty",
          },
          {
            label: "Bình Tân",
            value: 21811,
            slug: "binh-tan",
            status: "empty",
          },
          {
            label: "Tây Thuận",
            value: 21814,
            slug: "tay-thuan",
            status: "empty",
          },
          {
            label: "Bình Thuận",
            value: 21817,
            slug: "binh-thuan",
            status: "empty",
          },
          {
            label: "Tây Giang",
            value: 21820,
            slug: "tay-giang",
            status: "empty",
          },
          {
            label: "Bình Thành",
            value: 21823,
            slug: "binh-thanh",
            status: "empty",
          },
          { label: "Tây An", value: 21826, slug: "tay-an", status: "empty" },
          {
            label: "Bình Hòa",
            value: 21829,
            slug: "binh-hoa",
            status: "empty",
          },
          {
            label: "Tây Bình",
            value: 21832,
            slug: "tay-binh",
            status: "empty",
          },
          {
            label: "Bình Tường",
            value: 21835,
            slug: "binh-tuong",
            status: "empty",
          },
          {
            label: "Tây Vinh",
            value: 21838,
            slug: "tay-vinh",
            status: "empty",
          },
          { label: "Vĩnh An", value: 21841, slug: "vinh-an", status: "empty" },
          {
            label: "Tây Xuân",
            value: 21844,
            slug: "tay-xuan",
            status: "empty",
          },
          {
            label: "Bình Nghi",
            value: 21847,
            slug: "binh-nghi",
            status: "empty",
          },
          { label: "Tây Phú", value: 21850, slug: "tay-phu", status: "empty" },
        ],
      },
      {
        label: "Huyện Phù Cát",
        value: 548,
        slug: "huyen-phu-cat",
        status: "empty",
        ward: [
          { label: "Ngô Mây", value: 21853, slug: "ngo-may", status: "empty" },
          { label: "Cát Sơn", value: 21856, slug: "cat-son", status: "empty" },
          {
            label: "Cát Minh",
            value: 21859,
            slug: "cat-minh",
            status: "empty",
          },
          {
            label: "Cát Khánh",
            value: 21862,
            slug: "cat-khanh",
            status: "empty",
          },
          { label: "Cát Tài", value: 21865, slug: "cat-tai", status: "empty" },
          { label: "Cát Lâm", value: 21868, slug: "cat-lam", status: "empty" },
          {
            label: "Cát Hanh",
            value: 21871,
            slug: "cat-hanh",
            status: "empty",
          },
          {
            label: "Cát Thành",
            value: 21874,
            slug: "cat-thanh",
            status: "empty",
          },
          {
            label: "Cát Trinh",
            value: 21877,
            slug: "cat-trinh",
            status: "empty",
          },
          { label: "Cát Hải", value: 21880, slug: "cat-hai", status: "empty" },
          {
            label: "Cát Hiệp",
            value: 21883,
            slug: "cat-hiep",
            status: "empty",
          },
          {
            label: "Cát Nhơn",
            value: 21886,
            slug: "cat-nhon",
            status: "empty",
          },
          {
            label: "Cát Hưng",
            value: 21889,
            slug: "cat-hung",
            status: "empty",
          },
          {
            label: "Cát Tường",
            value: 21892,
            slug: "cat-tuong",
            status: "empty",
          },
          { label: "Cát Tân", value: 21895, slug: "cat-tan", status: "empty" },
          {
            label: "Cát Tiến",
            value: 21898,
            slug: "cat-tien",
            status: "empty",
          },
          {
            label: "Cát Thắng",
            value: 21901,
            slug: "cat-thang",
            status: "empty",
          },
          {
            label: "Cát Chánh",
            value: 21904,
            slug: "cat-chanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã An Nhơn",
        value: 549,
        slug: "thi-xa-an-nhon",
        status: "empty",
        ward: [
          {
            label: "Bình Định",
            value: 21907,
            slug: "binh-dinh",
            status: "empty",
          },
          { label: "Đập Đá", value: 21910, slug: "dap-da", status: "empty" },
          { label: "Nhơn Mỹ", value: 21913, slug: "nhon-my", status: "empty" },
          {
            label: "Nhơn Thành",
            value: 21916,
            slug: "nhon-thanh",
            status: "empty",
          },
          {
            label: "Nhơn Hạnh",
            value: 21919,
            slug: "nhon-hanh",
            status: "empty",
          },
          {
            label: "Nhơn Hậu",
            value: 21922,
            slug: "nhon-hau",
            status: "empty",
          },
          {
            label: "Nhơn Phong",
            value: 21925,
            slug: "nhon-phong",
            status: "empty",
          },
          { label: "Nhơn An", value: 21928, slug: "nhon-an", status: "empty" },
          {
            label: "Nhơn Phúc",
            value: 21931,
            slug: "nhon-phuc",
            status: "empty",
          },
          {
            label: "Nhơn Hưng",
            value: 21934,
            slug: "nhon-hung",
            status: "empty",
          },
          {
            label: "Nhơn Khánh",
            value: 21937,
            slug: "nhon-khanh",
            status: "empty",
          },
          {
            label: "Nhơn Lộc",
            value: 21940,
            slug: "nhon-loc",
            status: "empty",
          },
          {
            label: "Nhơn Hoà",
            value: 21943,
            slug: "nhon-hoa",
            status: "empty",
          },
          {
            label: "Nhơn Tân",
            value: 21946,
            slug: "nhon-tan",
            status: "empty",
          },
          {
            label: "Nhơn Thọ",
            value: 21949,
            slug: "nhon-tho",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tuy Phước",
        value: 550,
        slug: "huyen-tuy-phuoc",
        status: "empty",
        ward: [
          {
            label: "Tuy Phước",
            value: 21952,
            slug: "tuy-phuoc",
            status: "empty",
          },
          {
            label: "Diêu Trì",
            value: 21955,
            slug: "dieu-tri",
            status: "empty",
          },
          {
            label: "Phước Thắng",
            value: 21958,
            slug: "phuoc-thang",
            status: "empty",
          },
          {
            label: "Phước Hưng",
            value: 21961,
            slug: "phuoc-hung",
            status: "empty",
          },
          {
            label: "Phước Quang",
            value: 21964,
            slug: "phuoc-quang",
            status: "empty",
          },
          {
            label: "Phước Hòa",
            value: 21967,
            slug: "phuoc-hoa",
            status: "empty",
          },
          {
            label: "Phước Sơn",
            value: 21970,
            slug: "phuoc-son",
            status: "empty",
          },
          {
            label: "Phước Hiệp",
            value: 21973,
            slug: "phuoc-hiep",
            status: "empty",
          },
          {
            label: "Phước Lộc",
            value: 21976,
            slug: "phuoc-loc",
            status: "empty",
          },
          {
            label: "Phước Nghĩa",
            value: 21979,
            slug: "phuoc-nghia",
            status: "empty",
          },
          {
            label: "Phước Thuận",
            value: 21982,
            slug: "phuoc-thuan",
            status: "empty",
          },
          {
            label: "Phước An",
            value: 21985,
            slug: "phuoc-an",
            status: "empty",
          },
          {
            label: "Phước Thành",
            value: 21988,
            slug: "phuoc-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vân Canh",
        value: 551,
        slug: "huyen-van-canh",
        status: "empty",
        ward: [
          {
            label: "Vân Canh",
            value: 21994,
            slug: "van-canh",
            status: "empty",
          },
          {
            label: "Canh Liên",
            value: 21997,
            slug: "canh-lien",
            status: "empty",
          },
          {
            label: "Canh Hiệp",
            value: 22000,
            slug: "canh-hiep",
            status: "empty",
          },
          {
            label: "Canh Vinh",
            value: 22003,
            slug: "canh-vinh",
            status: "empty",
          },
          {
            label: "Canh Hiển",
            value: 22006,
            slug: "canh-hien",
            status: "empty",
          },
          {
            label: "Canh Thuận",
            value: 22009,
            slug: "canh-thuan",
            status: "empty",
          },
          {
            label: "Canh Hòa",
            value: 22012,
            slug: "canh-hoa",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Phú Yên",
    value: 54,
    slug: "phu-yen",
    status: "filled",
    district: [
      {
        label: "Thành phố Tuy Hoà",
        value: 555,
        slug: "thanh-pho-tuy-hoa",
        status: "filled",
        ward: [
          {
            label: "Phường 1",
            value: 22015,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 22018,
            slug: "phuong-2",
            status: "filled",
          },
          {
            label: "Phường 9",
            value: 22024,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 22030,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 22033,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 22036,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Phú Thạnh",
            value: 22040,
            slug: "phu-thanh",
            status: "empty",
          },
          {
            label: "Phú Đông",
            value: 22041,
            slug: "phu-dong",
            status: "empty",
          },
          {
            label: "Hòa Kiến",
            value: 22042,
            slug: "hoa-kien",
            status: "empty",
          },
          {
            label: "Bình Kiến",
            value: 22045,
            slug: "binh-kien",
            status: "empty",
          },
          { label: "An Phú", value: 22162, slug: "an-phu", status: "empty" },
          { label: "Phú Lâm", value: 22240, slug: "phu-lam", status: "empty" },
        ],
      },
      {
        label: "Thị xã Sông Cầu",
        value: 557,
        slug: "thi-xa-song-cau",
        status: "empty",
        ward: [
          {
            label: "Xuân Phú",
            value: 22051,
            slug: "xuan-phu",
            status: "empty",
          },
          {
            label: "Xuân Lâm",
            value: 22052,
            slug: "xuan-lam",
            status: "empty",
          },
          {
            label: "Xuân Thành",
            value: 22053,
            slug: "xuan-thanh",
            status: "empty",
          },
          {
            label: "Xuân Hải",
            value: 22054,
            slug: "xuan-hai",
            status: "empty",
          },
          {
            label: "Xuân Lộc",
            value: 22057,
            slug: "xuan-loc",
            status: "empty",
          },
          {
            label: "Xuân Bình",
            value: 22060,
            slug: "xuan-binh",
            status: "empty",
          },
          {
            label: "Xuân Cảnh",
            value: 22066,
            slug: "xuan-canh",
            status: "empty",
          },
          {
            label: "Xuân Thịnh",
            value: 22069,
            slug: "xuan-thinh",
            status: "empty",
          },
          {
            label: "Xuân Phương",
            value: 22072,
            slug: "xuan-phuong",
            status: "empty",
          },
          {
            label: "Xuân Yên",
            value: 22073,
            slug: "xuan-yen",
            status: "empty",
          },
          {
            label: "Xuân Thọ 1",
            value: 22075,
            slug: "xuan-tho-1",
            status: "empty",
          },
          {
            label: "Xuân Đài",
            value: 22076,
            slug: "xuan-dai",
            status: "empty",
          },
          {
            label: "Xuân Thọ 2",
            value: 22078,
            slug: "xuan-tho-2",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đồng Xuân",
        value: 558,
        slug: "huyen-dong-xuan",
        status: "empty",
        ward: [
          { label: "La Hai", value: 22081, slug: "la-hai", status: "empty" },
          { label: "Đa Lộc", value: 22084, slug: "da-loc", status: "empty" },
          { label: "Phú Mỡ", value: 22087, slug: "phu-mo", status: "empty" },
          {
            label: "Xuân Lãnh",
            value: 22090,
            slug: "xuan-lanh",
            status: "empty",
          },
          {
            label: "Xuân Long",
            value: 22093,
            slug: "xuan-long",
            status: "empty",
          },
          {
            label: "Xuân Quang 1",
            value: 22096,
            slug: "xuan-quang-1",
            status: "empty",
          },
          {
            label: "Xuân Sơn Bắc",
            value: 22099,
            slug: "xuan-son-bac",
            status: "empty",
          },
          {
            label: "Xuân Quang 2",
            value: 22102,
            slug: "xuan-quang-2",
            status: "empty",
          },
          {
            label: "Xuân Sơn Nam",
            value: 22105,
            slug: "xuan-son-nam",
            status: "empty",
          },
          {
            label: "Xuân Quang 3",
            value: 22108,
            slug: "xuan-quang-3",
            status: "empty",
          },
          {
            label: "Xuân Phước",
            value: 22111,
            slug: "xuan-phuoc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tuy An",
        value: 559,
        slug: "huyen-tuy-an",
        status: "empty",
        ward: [
          {
            label: "Chí Thạnh",
            value: 22114,
            slug: "chi-thanh",
            status: "empty",
          },
          { label: "An Dân", value: 22117, slug: "an-dan", status: "empty" },
          {
            label: "An Ninh Tây",
            value: 22120,
            slug: "an-ninh-tay",
            status: "empty",
          },
          {
            label: "An Ninh Đông",
            value: 22123,
            slug: "an-ninh-dong",
            status: "empty",
          },
          {
            label: "An Thạch",
            value: 22126,
            slug: "an-thach",
            status: "empty",
          },
          { label: "An Định", value: 22129, slug: "an-dinh", status: "empty" },
          {
            label: "An Nghiệp",
            value: 22132,
            slug: "an-nghiep",
            status: "empty",
          },
          { label: "An Cư", value: 22138, slug: "an-cu", status: "empty" },
          { label: "An Xuân", value: 22141, slug: "an-xuan", status: "empty" },
          { label: "An Lĩnh", value: 22144, slug: "an-linh", status: "empty" },
          {
            label: "An Hòa Hải",
            value: 22147,
            slug: "an-hoa-hai",
            status: "empty",
          },
          { label: "An Hiệp", value: 22150, slug: "an-hiep", status: "empty" },
          { label: "An Mỹ", value: 22153, slug: "an-my", status: "empty" },
          { label: "An Chấn", value: 22156, slug: "an-chan", status: "empty" },
          { label: "An Thọ", value: 22159, slug: "an-tho", status: "empty" },
        ],
      },
      {
        label: "Huyện Sơn Hòa",
        value: 560,
        slug: "huyen-son-hoa",
        status: "empty",
        ward: [
          {
            label: "Củng Sơn",
            value: 22165,
            slug: "cung-son",
            status: "empty",
          },
          {
            label: "Phước Tân",
            value: 22168,
            slug: "phuoc-tan",
            status: "empty",
          },
          { label: "Sơn Hội", value: 22171, slug: "son-hoi", status: "empty" },
          {
            label: "Sơn Định",
            value: 22174,
            slug: "son-dinh",
            status: "empty",
          },
          {
            label: "Sơn Long",
            value: 22177,
            slug: "son-long",
            status: "empty",
          },
          { label: "Cà Lúi", value: 22180, slug: "ca-lui", status: "empty" },
          {
            label: "Sơn Phước",
            value: 22183,
            slug: "son-phuoc",
            status: "empty",
          },
          {
            label: "Sơn Xuân",
            value: 22186,
            slug: "son-xuan",
            status: "empty",
          },
          {
            label: "Sơn Nguyên",
            value: 22189,
            slug: "son-nguyen",
            status: "empty",
          },
          {
            label: "Eachà Rang",
            value: 22192,
            slug: "eacha-rang",
            status: "empty",
          },
          {
            label: "Krông Pa",
            value: 22195,
            slug: "krong-pa",
            status: "empty",
          },
          {
            label: "Suối Bạc",
            value: 22198,
            slug: "suoi-bac",
            status: "empty",
          },
          { label: "Sơn Hà", value: 22201, slug: "son-ha", status: "empty" },
          {
            label: "Suối Trai",
            value: 22204,
            slug: "suoi-trai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Sông Hinh",
        value: 561,
        slug: "huyen-song-hinh",
        status: "empty",
        ward: [
          {
            label: "Hai Riêng",
            value: 22207,
            slug: "hai-rieng",
            status: "empty",
          },
          { label: "Ea Lâm", value: 22210, slug: "ea-lam", status: "empty" },
          {
            label: "Đức Bình Tây",
            value: 22213,
            slug: "duc-binh-tay",
            status: "empty",
          },
          { label: "Ea Bá", value: 22216, slug: "ea-ba", status: "empty" },
          {
            label: "Sơn Giang",
            value: 22219,
            slug: "son-giang",
            status: "empty",
          },
          {
            label: "Đức Bình Đông",
            value: 22222,
            slug: "duc-binh-dong",
            status: "empty",
          },
          {
            label: "Xã EaBar",
            value: 22225,
            slug: "xa-eabar",
            status: "empty",
          },
          {
            label: "Xã EaBia",
            value: 22228,
            slug: "xa-eabia",
            status: "empty",
          },
          {
            label: "Xã EaTrol",
            value: 22231,
            slug: "xa-eatrol",
            status: "empty",
          },
          {
            label: "Sông Hinh",
            value: 22234,
            slug: "song-hinh",
            status: "empty",
          },
          { label: "Xã Ealy", value: 22237, slug: "xa-ealy", status: "empty" },
        ],
      },
      {
        label: "Huyện Tây Hoà",
        value: 562,
        slug: "huyen-tay-hoa",
        status: "empty",
        ward: [
          {
            label: "Sơn Thành Tây",
            value: 22249,
            slug: "son-thanh-tay",
            status: "empty",
          },
          {
            label: "Sơn Thành Đông",
            value: 22250,
            slug: "son-thanh-dong",
            status: "empty",
          },
          {
            label: "Hòa Bình 1",
            value: 22252,
            slug: "hoa-binh-1",
            status: "empty",
          },
          { label: "Phú Thứ", value: 22255, slug: "phu-thu", status: "empty" },
          {
            label: "Hòa Phong",
            value: 22264,
            slug: "hoa-phong",
            status: "empty",
          },
          { label: "Hòa Phú", value: 22270, slug: "hoa-phu", status: "empty" },
          {
            label: "Hòa Tân Tây",
            value: 22273,
            slug: "hoa-tan-tay",
            status: "empty",
          },
          {
            label: "Hòa Đồng",
            value: 22276,
            slug: "hoa-dong",
            status: "empty",
          },
          {
            label: "Hòa Mỹ Đông",
            value: 22285,
            slug: "hoa-my-dong",
            status: "empty",
          },
          {
            label: "Hòa Mỹ Tây",
            value: 22288,
            slug: "hoa-my-tay",
            status: "empty",
          },
          {
            label: "Hòa Thịnh",
            value: 22294,
            slug: "hoa-thinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phú Hoà",
        value: 563,
        slug: "huyen-phu-hoa",
        status: "empty",
        ward: [
          {
            label: "Hòa Quang Bắc",
            value: 22303,
            slug: "hoa-quang-bac",
            status: "empty",
          },
          {
            label: "Hòa Quang Nam",
            value: 22306,
            slug: "hoa-quang-nam",
            status: "empty",
          },
          { label: "Hòa Hội", value: 22309, slug: "hoa-hoi", status: "empty" },
          { label: "Hòa Trị", value: 22312, slug: "hoa-tri", status: "empty" },
          { label: "Hòa An", value: 22315, slug: "hoa-an", status: "empty" },
          {
            label: "Hòa Định Đông",
            value: 22318,
            slug: "hoa-dinh-dong",
            status: "empty",
          },
          { label: "Phú Hoà", value: 22319, slug: "phu-hoa", status: "empty" },
          {
            label: "Hòa Định Tây",
            value: 22321,
            slug: "hoa-dinh-tay",
            status: "empty",
          },
          {
            label: "Hòa Thắng",
            value: 22324,
            slug: "hoa-thang",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Đông Hòa",
        value: 564,
        slug: "thi-xa-dong-hoa",
        status: "empty",
        ward: [
          {
            label: "Hòa Thành",
            value: 22243,
            slug: "hoa-thanh",
            status: "empty",
          },
          {
            label: "Hòa Hiệp Bắc",
            value: 22246,
            slug: "hoa-hiep-bac",
            status: "empty",
          },
          {
            label: "Hoà Vinh",
            value: 22258,
            slug: "hoa-vinh",
            status: "empty",
          },
          {
            label: "Hoà Hiệp Trung",
            value: 22261,
            slug: "hoa-hiep-trung",
            status: "empty",
          },
          {
            label: "Hòa Tân Đông",
            value: 22267,
            slug: "hoa-tan-dong",
            status: "empty",
          },
          {
            label: "Hòa Xuân Tây",
            value: 22279,
            slug: "hoa-xuan-tay",
            status: "empty",
          },
          {
            label: "Hòa Hiệp Nam",
            value: 22282,
            slug: "hoa-hiep-nam",
            status: "empty",
          },
          {
            label: "Hòa Xuân Đông",
            value: 22291,
            slug: "hoa-xuan-dong",
            status: "empty",
          },
          { label: "Hòa Tâm", value: 22297, slug: "hoa-tam", status: "empty" },
          {
            label: "Hòa Xuân Nam",
            value: 22300,
            slug: "hoa-xuan-nam",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Khánh Hòa",
    value: 56,
    slug: "khanh-hoa",
    status: "empty",
    district: [
      {
        label: "Thành phố Nha Trang",
        value: 568,
        slug: "thanh-pho-nha-trang",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Hòa",
            value: 22327,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Vĩnh Hải",
            value: 22330,
            slug: "vinh-hai",
            status: "empty",
          },
          {
            label: "Vĩnh Phước",
            value: 22333,
            slug: "vinh-phuoc",
            status: "empty",
          },
          {
            label: "Ngọc Hiệp",
            value: 22336,
            slug: "ngoc-hiep",
            status: "empty",
          },
          {
            label: "Vĩnh Thọ",
            value: 22339,
            slug: "vinh-tho",
            status: "empty",
          },
          {
            label: "Vạn Thạnh",
            value: 22348,
            slug: "van-thanh",
            status: "empty",
          },
          {
            label: "Phương Sài",
            value: 22351,
            slug: "phuong-sai",
            status: "empty",
          },
          {
            label: "Phước Hải",
            value: 22357,
            slug: "phuoc-hai",
            status: "empty",
          },
          { label: "Lộc Thọ", value: 22363, slug: "loc-tho", status: "empty" },
          {
            label: "Tân Tiến",
            value: 22366,
            slug: "tan-tien",
            status: "empty",
          },
          {
            label: "Phước Hòa",
            value: 22372,
            slug: "phuoc-hoa",
            status: "empty",
          },
          {
            label: "Vĩnh Nguyên",
            value: 22375,
            slug: "vinh-nguyen",
            status: "empty",
          },
          {
            label: "Phước Long",
            value: 22378,
            slug: "phuoc-long",
            status: "empty",
          },
          {
            label: "Vĩnh Trường",
            value: 22381,
            slug: "vinh-truong",
            status: "empty",
          },
          {
            label: "Vĩnh Lương",
            value: 22384,
            slug: "vinh-luong",
            status: "empty",
          },
          {
            label: "Vĩnh Phương",
            value: 22387,
            slug: "vinh-phuong",
            status: "empty",
          },
          {
            label: "Vĩnh Ngọc",
            value: 22390,
            slug: "vinh-ngoc",
            status: "empty",
          },
          {
            label: "Vĩnh Thạnh",
            value: 22393,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Trung",
            value: 22396,
            slug: "vinh-trung",
            status: "empty",
          },
          {
            label: "Vĩnh Hiệp",
            value: 22399,
            slug: "vinh-hiep",
            status: "empty",
          },
          {
            label: "Vĩnh Thái",
            value: 22402,
            slug: "vinh-thai",
            status: "empty",
          },
          {
            label: "Phước Đồng",
            value: 22405,
            slug: "phuoc-dong",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Cam Ranh",
        value: 569,
        slug: "thanh-pho-cam-ranh",
        status: "empty",
        ward: [
          {
            label: "Cam Nghĩa",
            value: 22408,
            slug: "cam-nghia",
            status: "empty",
          },
          {
            label: "Cam Phúc Bắc",
            value: 22411,
            slug: "cam-phuc-bac",
            status: "empty",
          },
          {
            label: "Cam Phúc Nam",
            value: 22414,
            slug: "cam-phuc-nam",
            status: "empty",
          },
          { label: "Cam Lộc", value: 22417, slug: "cam-loc", status: "empty" },
          { label: "Cam Phú", value: 22420, slug: "cam-phu", status: "empty" },
          { label: "Ba Ngòi", value: 22423, slug: "ba-ngoi", status: "empty" },
          {
            label: "Cam Thuận",
            value: 22426,
            slug: "cam-thuan",
            status: "empty",
          },
          { label: "Cam Lợi", value: 22429, slug: "cam-loi", status: "empty" },
          {
            label: "Cam Linh",
            value: 22432,
            slug: "cam-linh",
            status: "empty",
          },
          {
            label: "Cam Thành Nam",
            value: 22468,
            slug: "cam-thanh-nam",
            status: "empty",
          },
          {
            label: "Cam Phước Đông",
            value: 22474,
            slug: "cam-phuoc-dong",
            status: "empty",
          },
          {
            label: "Cam Thịnh Tây",
            value: 22477,
            slug: "cam-thinh-tay",
            status: "empty",
          },
          {
            label: "Cam Thịnh Đông",
            value: 22480,
            slug: "cam-thinh-dong",
            status: "empty",
          },
          { label: "Cam Lập", value: 22483, slug: "cam-lap", status: "empty" },
          {
            label: "Cam Bình",
            value: 22486,
            slug: "cam-binh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cam Lâm",
        value: 570,
        slug: "huyen-cam-lam",
        status: "empty",
        ward: [
          { label: "Cam Tân", value: 22435, slug: "cam-tan", status: "empty" },
          { label: "Cam Hòa", value: 22438, slug: "cam-hoa", status: "empty" },
          {
            label: "Cam Hải Đông",
            value: 22441,
            slug: "cam-hai-dong",
            status: "empty",
          },
          {
            label: "Cam Hải Tây",
            value: 22444,
            slug: "cam-hai-tay",
            status: "empty",
          },
          { label: "Sơn Tân", value: 22447, slug: "son-tan", status: "empty" },
          {
            label: "Cam Hiệp Bắc",
            value: 22450,
            slug: "cam-hiep-bac",
            status: "empty",
          },
          { label: "Cam Đức", value: 22453, slug: "cam-duc", status: "empty" },
          {
            label: "Cam Hiệp Nam",
            value: 22456,
            slug: "cam-hiep-nam",
            status: "empty",
          },
          {
            label: "Cam Phước Tây",
            value: 22459,
            slug: "cam-phuoc-tay",
            status: "empty",
          },
          {
            label: "Cam Thành Bắc",
            value: 22462,
            slug: "cam-thanh-bac",
            status: "empty",
          },
          {
            label: "Cam An Bắc",
            value: 22465,
            slug: "cam-an-bac",
            status: "empty",
          },
          {
            label: "Cam An Nam",
            value: 22471,
            slug: "cam-an-nam",
            status: "empty",
          },
          {
            label: "Suối Cát",
            value: 22708,
            slug: "suoi-cat",
            status: "empty",
          },
          {
            label: "Suối Tân",
            value: 22711,
            slug: "suoi-tan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vạn Ninh",
        value: 571,
        slug: "huyen-van-ninh",
        status: "empty",
        ward: [
          { label: "Vạn Giã", value: 22489, slug: "van-gia", status: "empty" },
          {
            label: "Đại Lãnh",
            value: 22492,
            slug: "dai-lanh",
            status: "empty",
          },
          {
            label: "Vạn Phước",
            value: 22495,
            slug: "van-phuoc",
            status: "empty",
          },
          {
            label: "Vạn Long",
            value: 22498,
            slug: "van-long",
            status: "empty",
          },
          {
            label: "Vạn Bình",
            value: 22501,
            slug: "van-binh",
            status: "empty",
          },
          { label: "Vạn Thọ", value: 22504, slug: "van-tho", status: "empty" },
          {
            label: "Vạn Khánh",
            value: 22507,
            slug: "van-khanh",
            status: "empty",
          },
          { label: "Vạn Phú", value: 22510, slug: "van-phu", status: "empty" },
          {
            label: "Vạn Lương",
            value: 22513,
            slug: "van-luong",
            status: "empty",
          },
          {
            label: "Vạn Thắng",
            value: 22516,
            slug: "van-thang",
            status: "empty",
          },
          {
            label: "Vạn Thạnh",
            value: 22519,
            slug: "van-thanh",
            status: "empty",
          },
          {
            label: "Xuân Sơn",
            value: 22522,
            slug: "xuan-son",
            status: "empty",
          },
          {
            label: "Vạn Hưng",
            value: 22525,
            slug: "van-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Ninh Hòa",
        value: 572,
        slug: "thi-xa-ninh-hoa",
        status: "empty",
        ward: [
          {
            label: "Ninh Hiệp",
            value: 22528,
            slug: "ninh-hiep",
            status: "empty",
          },
          {
            label: "Ninh Sơn",
            value: 22531,
            slug: "ninh-son",
            status: "empty",
          },
          {
            label: "Ninh Tây",
            value: 22534,
            slug: "ninh-tay",
            status: "empty",
          },
          {
            label: "Ninh Thượng",
            value: 22537,
            slug: "ninh-thuong",
            status: "empty",
          },
          { label: "Ninh An", value: 22540, slug: "ninh-an", status: "empty" },
          {
            label: "Ninh Hải",
            value: 22543,
            slug: "ninh-hai",
            status: "empty",
          },
          {
            label: "Ninh Thọ",
            value: 22546,
            slug: "ninh-tho",
            status: "empty",
          },
          {
            label: "Ninh Trung",
            value: 22549,
            slug: "ninh-trung",
            status: "empty",
          },
          {
            label: "Ninh Sim",
            value: 22552,
            slug: "ninh-sim",
            status: "empty",
          },
          {
            label: "Ninh Xuân",
            value: 22555,
            slug: "ninh-xuan",
            status: "empty",
          },
          {
            label: "Ninh Thân",
            value: 22558,
            slug: "ninh-than",
            status: "empty",
          },
          {
            label: "Ninh Diêm",
            value: 22561,
            slug: "ninh-diem",
            status: "empty",
          },
          {
            label: "Ninh Đông",
            value: 22564,
            slug: "ninh-dong",
            status: "empty",
          },
          {
            label: "Ninh Thủy",
            value: 22567,
            slug: "ninh-thuy",
            status: "empty",
          },
          { label: "Ninh Đa", value: 22570, slug: "ninh-da", status: "empty" },
          {
            label: "Ninh Phụng",
            value: 22573,
            slug: "ninh-phung",
            status: "empty",
          },
          {
            label: "Ninh Bình",
            value: 22576,
            slug: "ninh-binh",
            status: "empty",
          },
          {
            label: "Ninh Phú",
            value: 22582,
            slug: "ninh-phu",
            status: "empty",
          },
          {
            label: "Ninh Tân",
            value: 22585,
            slug: "ninh-tan",
            status: "empty",
          },
          {
            label: "Ninh Quang",
            value: 22588,
            slug: "ninh-quang",
            status: "empty",
          },
          {
            label: "Ninh Giang",
            value: 22591,
            slug: "ninh-giang",
            status: "empty",
          },
          { label: "Ninh Hà", value: 22594, slug: "ninh-ha", status: "empty" },
          {
            label: "Ninh Hưng",
            value: 22597,
            slug: "ninh-hung",
            status: "empty",
          },
          {
            label: "Ninh Lộc",
            value: 22600,
            slug: "ninh-loc",
            status: "empty",
          },
          {
            label: "Ninh Ích",
            value: 22603,
            slug: "ninh-ich",
            status: "empty",
          },
          {
            label: "Ninh Phước",
            value: 22606,
            slug: "ninh-phuoc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Khánh Vĩnh",
        value: 573,
        slug: "huyen-khanh-vinh",
        status: "empty",
        ward: [
          {
            label: "Khánh Vĩnh",
            value: 22609,
            slug: "khanh-vinh",
            status: "empty",
          },
          {
            label: "Khánh Hiệp",
            value: 22612,
            slug: "khanh-hiep",
            status: "empty",
          },
          {
            label: "Khánh Bình",
            value: 22615,
            slug: "khanh-binh",
            status: "empty",
          },
          {
            label: "Khánh Trung",
            value: 22618,
            slug: "khanh-trung",
            status: "empty",
          },
          {
            label: "Khánh Đông",
            value: 22621,
            slug: "khanh-dong",
            status: "empty",
          },
          {
            label: "Khánh Thượng",
            value: 22624,
            slug: "khanh-thuong",
            status: "empty",
          },
          {
            label: "Khánh Nam",
            value: 22627,
            slug: "khanh-nam",
            status: "empty",
          },
          {
            label: "Sông Cầu",
            value: 22630,
            slug: "song-cau",
            status: "empty",
          },
          {
            label: "Giang Ly",
            value: 22633,
            slug: "giang-ly",
            status: "empty",
          },
          { label: "Cầu Bà", value: 22636, slug: "cau-ba", status: "empty" },
          {
            label: "Liên Sang",
            value: 22639,
            slug: "lien-sang",
            status: "empty",
          },
          {
            label: "Khánh Thành",
            value: 22642,
            slug: "khanh-thanh",
            status: "empty",
          },
          {
            label: "Khánh Phú",
            value: 22645,
            slug: "khanh-phu",
            status: "empty",
          },
          {
            label: "Sơn Thái",
            value: 22648,
            slug: "son-thai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Diên Khánh",
        value: 574,
        slug: "huyen-dien-khanh",
        status: "empty",
        ward: [
          {
            label: "Diên Khánh",
            value: 22651,
            slug: "dien-khanh",
            status: "empty",
          },
          {
            label: "Diên Lâm",
            value: 22654,
            slug: "dien-lam",
            status: "empty",
          },
          {
            label: "Diên Điền",
            value: 22657,
            slug: "dien-dien",
            status: "empty",
          },
          {
            label: "Xuân Đồng",
            value: 22660,
            slug: "xuan-dong",
            status: "empty",
          },
          {
            label: "Diên Sơn",
            value: 22663,
            slug: "dien-son",
            status: "empty",
          },
          {
            label: "Diên Phú",
            value: 22669,
            slug: "dien-phu",
            status: "empty",
          },
          {
            label: "Diên Thọ",
            value: 22672,
            slug: "dien-tho",
            status: "empty",
          },
          {
            label: "Diên Phước",
            value: 22675,
            slug: "dien-phuoc",
            status: "empty",
          },
          {
            label: "Diên Lạc",
            value: 22678,
            slug: "dien-lac",
            status: "empty",
          },
          {
            label: "Diên Tân",
            value: 22681,
            slug: "dien-tan",
            status: "empty",
          },
          {
            label: "Diên Hòa",
            value: 22684,
            slug: "dien-hoa",
            status: "empty",
          },
          {
            label: "Diên Thạnh",
            value: 22687,
            slug: "dien-thanh",
            status: "empty",
          },
          {
            label: "Diên Toàn",
            value: 22690,
            slug: "dien-toan",
            status: "empty",
          },
          { label: "Diên An", value: 22693, slug: "dien-an", status: "empty" },
          {
            label: "Bình Lộc",
            value: 22696,
            slug: "binh-loc",
            status: "empty",
          },
          {
            label: "Suối Hiệp",
            value: 22702,
            slug: "suoi-hiep",
            status: "empty",
          },
          {
            label: "Suối Tiên",
            value: 22705,
            slug: "suoi-tien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Khánh Sơn",
        value: 575,
        slug: "huyen-khanh-son",
        status: "empty",
        ward: [
          { label: "Tô Hạp", value: 22714, slug: "to-hap", status: "empty" },
          {
            label: "Thành Sơn",
            value: 22717,
            slug: "thanh-son",
            status: "empty",
          },
          { label: "Sơn Lâm", value: 22720, slug: "son-lam", status: "empty" },
          {
            label: "Sơn Hiệp",
            value: 22723,
            slug: "son-hiep",
            status: "empty",
          },
          {
            label: "Sơn Bình",
            value: 22726,
            slug: "son-binh",
            status: "empty",
          },
          {
            label: "Sơn Trung",
            value: 22729,
            slug: "son-trung",
            status: "empty",
          },
          {
            label: "Ba Cụm Bắc",
            value: 22732,
            slug: "ba-cum-bac",
            status: "empty",
          },
          {
            label: "Ba Cụm Nam",
            value: 22735,
            slug: "ba-cum-nam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Trường Sa",
        value: 576,
        slug: "huyen-truong-sa",
        status: "empty",
        ward: [
          {
            label: "Trường Sa",
            value: 22736,
            slug: "truong-sa",
            status: "empty",
          },
          {
            label: "Song Tử Tây",
            value: 22737,
            slug: "song-tu-tay",
            status: "empty",
          },
          {
            label: "Sinh Tồn",
            value: 22739,
            slug: "sinh-ton",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Ninh Thuận",
    value: 58,
    slug: "ninh-thuan",
    status: "filled",
    district: [
      {
        label: "Thành phố Phan Rang-Tháp Chàm",
        value: 582,
        slug: "thanh-pho-phan-rang-thap-cham",
        status: "filled",
        ward: [
          { label: "Đô Vinh", value: 22738, slug: "do-vinh", status: "empty" },
          {
            label: "Phước Mỹ",
            value: 22741,
            slug: "phuoc-my",
            status: "empty",
          },
          { label: "Bảo An", value: 22744, slug: "bao-an", status: "empty" },
          { label: "Phủ Hà", value: 22750, slug: "phu-ha", status: "empty" },
          {
            label: "Kinh Dinh",
            value: 22759,
            slug: "kinh-dinh",
            status: "filled",
          },
          {
            label: "Đạo Long",
            value: 22762,
            slug: "dao-long",
            status: "empty",
          },
          { label: "Đài Sơn", value: 22765, slug: "dai-son", status: "empty" },
          {
            label: "Đông Hải",
            value: 22768,
            slug: "dong-hai",
            status: "empty",
          },
          { label: "Mỹ Đông", value: 22771, slug: "my-dong", status: "empty" },
          {
            label: "Thành Hải",
            value: 22774,
            slug: "thanh-hai",
            status: "empty",
          },
          { label: "Văn Hải", value: 22777, slug: "van-hai", status: "empty" },
          { label: "Mỹ Bình", value: 22779, slug: "my-binh", status: "empty" },
          { label: "Mỹ Hải", value: 22780, slug: "my-hai", status: "empty" },
        ],
      },
      {
        label: "Huyện Bác Ái",
        value: 584,
        slug: "huyen-bac-ai",
        status: "empty",
        ward: [
          {
            label: "Phước Bình",
            value: 22783,
            slug: "phuoc-binh",
            status: "empty",
          },
          {
            label: "Phước Hòa",
            value: 22786,
            slug: "phuoc-hoa",
            status: "empty",
          },
          {
            label: "Phước Tân",
            value: 22789,
            slug: "phuoc-tan",
            status: "empty",
          },
          {
            label: "Phước Tiến",
            value: 22792,
            slug: "phuoc-tien",
            status: "empty",
          },
          {
            label: "Phước Thắng",
            value: 22795,
            slug: "phuoc-thang",
            status: "empty",
          },
          {
            label: "Phước Thành",
            value: 22798,
            slug: "phuoc-thanh",
            status: "empty",
          },
          {
            label: "Phước Đại",
            value: 22801,
            slug: "phuoc-dai",
            status: "empty",
          },
          {
            label: "Phước Chính",
            value: 22804,
            slug: "phuoc-chinh",
            status: "empty",
          },
          {
            label: "Phước Trung",
            value: 22807,
            slug: "phuoc-trung",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ninh Sơn",
        value: 585,
        slug: "huyen-ninh-son",
        status: "empty",
        ward: [
          { label: "Tân Sơn", value: 22810, slug: "tan-son", status: "empty" },
          { label: "Lâm Sơn", value: 22813, slug: "lam-son", status: "empty" },
          {
            label: "Lương Sơn",
            value: 22816,
            slug: "luong-son",
            status: "empty",
          },
          {
            label: "Quảng Sơn",
            value: 22819,
            slug: "quang-son",
            status: "empty",
          },
          { label: "Mỹ Sơn", value: 22822, slug: "my-son", status: "empty" },
          { label: "Hòa Sơn", value: 22825, slug: "hoa-son", status: "empty" },
          { label: "Ma Nới", value: 22828, slug: "ma-noi", status: "empty" },
          {
            label: "Nhơn Sơn",
            value: 22831,
            slug: "nhon-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ninh Hải",
        value: 586,
        slug: "huyen-ninh-hai",
        status: "empty",
        ward: [
          {
            label: "Khánh Hải",
            value: 22834,
            slug: "khanh-hai",
            status: "empty",
          },
          {
            label: "Vĩnh Hải",
            value: 22846,
            slug: "vinh-hai",
            status: "empty",
          },
          {
            label: "Phương Hải",
            value: 22852,
            slug: "phuong-hai",
            status: "empty",
          },
          { label: "Tân Hải", value: 22855, slug: "tan-hai", status: "empty" },
          {
            label: "Xuân Hải",
            value: 22858,
            slug: "xuan-hai",
            status: "empty",
          },
          { label: "Hộ Hải", value: 22861, slug: "ho-hai", status: "empty" },
          { label: "Tri Hải", value: 22864, slug: "tri-hai", status: "empty" },
          {
            label: "Nhơn Hải",
            value: 22867,
            slug: "nhon-hai",
            status: "empty",
          },
          {
            label: "Thanh Hải",
            value: 22868,
            slug: "thanh-hai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ninh Phước",
        value: 587,
        slug: "huyen-ninh-phuoc",
        status: "empty",
        ward: [
          {
            label: "Phước Dân",
            value: 22870,
            slug: "phuoc-dan",
            status: "empty",
          },
          {
            label: "Phước Sơn",
            value: 22873,
            slug: "phuoc-son",
            status: "empty",
          },
          {
            label: "Phước Thái",
            value: 22876,
            slug: "phuoc-thai",
            status: "empty",
          },
          {
            label: "Phước Hậu",
            value: 22879,
            slug: "phuoc-hau",
            status: "empty",
          },
          {
            label: "Phước Thuận",
            value: 22882,
            slug: "phuoc-thuan",
            status: "empty",
          },
          { label: "An Hải", value: 22888, slug: "an-hai", status: "empty" },
          {
            label: "Phước Hữu",
            value: 22891,
            slug: "phuoc-huu",
            status: "empty",
          },
          {
            label: "Phước Hải",
            value: 22894,
            slug: "phuoc-hai",
            status: "empty",
          },
          {
            label: "Phước Vinh",
            value: 22912,
            slug: "phuoc-vinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thuận Bắc",
        value: 588,
        slug: "huyen-thuan-bac",
        status: "empty",
        ward: [
          {
            label: "Phước Chiến",
            value: 22837,
            slug: "phuoc-chien",
            status: "empty",
          },
          {
            label: "Công Hải",
            value: 22840,
            slug: "cong-hai",
            status: "empty",
          },
          {
            label: "Phước Kháng",
            value: 22843,
            slug: "phuoc-khang",
            status: "empty",
          },
          { label: "Lợi Hải", value: 22849, slug: "loi-hai", status: "empty" },
          { label: "Bắc Sơn", value: 22853, slug: "bac-son", status: "empty" },
          {
            label: "Bắc Phong",
            value: 22856,
            slug: "bac-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thuận Nam",
        value: 589,
        slug: "huyen-thuan-nam",
        status: "empty",
        ward: [
          {
            label: "Phước Hà",
            value: 22885,
            slug: "phuoc-ha",
            status: "empty",
          },
          {
            label: "Phước Nam",
            value: 22897,
            slug: "phuoc-nam",
            status: "empty",
          },
          {
            label: "Phước Ninh",
            value: 22898,
            slug: "phuoc-ninh",
            status: "empty",
          },
          { label: "Nhị Hà", value: 22900, slug: "nhi-ha", status: "empty" },
          {
            label: "Phước Dinh",
            value: 22903,
            slug: "phuoc-dinh",
            status: "empty",
          },
          {
            label: "Phước Minh",
            value: 22906,
            slug: "phuoc-minh",
            status: "empty",
          },
          {
            label: "Phước Diêm",
            value: 22909,
            slug: "phuoc-diem",
            status: "empty",
          },
          { label: "Cà Ná", value: 22910, slug: "ca-na", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Bình Thuận",
    value: 60,
    slug: "binh-thuan",
    status: "empty",
    district: [
      {
        label: "Thành phố Phan Thiết",
        value: 593,
        slug: "thanh-pho-phan-thiet",
        status: "empty",
        ward: [
          { label: "Mũi Né", value: 22915, slug: "mui-ne", status: "empty" },
          {
            label: "Hàm Tiến",
            value: 22918,
            slug: "ham-tien",
            status: "empty",
          },
          { label: "Phú Hài", value: 22921, slug: "phu-hai", status: "empty" },
          {
            label: "Phú Thủy",
            value: 22924,
            slug: "phu-thuy",
            status: "empty",
          },
          { label: "Phú Tài", value: 22927, slug: "phu-tai", status: "empty" },
          {
            label: "Phú Trinh",
            value: 22930,
            slug: "phu-trinh",
            status: "empty",
          },
          { label: "Xuân An", value: 22933, slug: "xuan-an", status: "empty" },
          {
            label: "Thanh Hải",
            value: 22936,
            slug: "thanh-hai",
            status: "empty",
          },
          { label: "Lạc Đạo", value: 22945, slug: "lac-dao", status: "empty" },
          {
            label: "Bình Hưng",
            value: 22951,
            slug: "binh-hung",
            status: "empty",
          },
          {
            label: "Đức Long",
            value: 22954,
            slug: "duc-long",
            status: "empty",
          },
          {
            label: "Thiện Nghiệp",
            value: 22957,
            slug: "thien-nghiep",
            status: "empty",
          },
          {
            label: "Phong Nẫm",
            value: 22960,
            slug: "phong-nam",
            status: "empty",
          },
          {
            label: "Tiến Lợi",
            value: 22963,
            slug: "tien-loi",
            status: "empty",
          },
          {
            label: "Tiến Thành",
            value: 22966,
            slug: "tien-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã La Gi",
        value: 594,
        slug: "thi-xa-la-gi",
        status: "empty",
        ward: [
          {
            label: "Phước Hội",
            value: 23231,
            slug: "phuoc-hoi",
            status: "empty",
          },
          {
            label: "Phước Lộc",
            value: 23232,
            slug: "phuoc-loc",
            status: "empty",
          },
          {
            label: "Tân Thiện",
            value: 23234,
            slug: "tan-thien",
            status: "empty",
          },
          { label: "Tân An", value: 23235, slug: "tan-an", status: "empty" },
          {
            label: "Bình Tân",
            value: 23237,
            slug: "binh-tan",
            status: "empty",
          },
          { label: "Tân Hải", value: 23245, slug: "tan-hai", status: "empty" },
          {
            label: "Tân Tiến",
            value: 23246,
            slug: "tan-tien",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 23248,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Tân Phước",
            value: 23268,
            slug: "tan-phuoc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tuy Phong",
        value: 595,
        slug: "huyen-tuy-phong",
        status: "empty",
        ward: [
          {
            label: "Liên Hương",
            value: 22969,
            slug: "lien-huong",
            status: "empty",
          },
          {
            label: "Phan Rí Cửa",
            value: 22972,
            slug: "phan-ri-cua",
            status: "empty",
          },
          {
            label: "Phan Dũng",
            value: 22975,
            slug: "phan-dung",
            status: "empty",
          },
          {
            label: "Phong Phú",
            value: 22978,
            slug: "phong-phu",
            status: "empty",
          },
          {
            label: "Vĩnh Hảo",
            value: 22981,
            slug: "vinh-hao",
            status: "empty",
          },
          {
            label: "Vĩnh Tân",
            value: 22984,
            slug: "vinh-tan",
            status: "empty",
          },
          { label: "Phú Lạc", value: 22987, slug: "phu-lac", status: "empty" },
          {
            label: "Phước Thể",
            value: 22990,
            slug: "phuoc-the",
            status: "empty",
          },
          {
            label: "Hòa Minh",
            value: 22993,
            slug: "hoa-minh",
            status: "empty",
          },
          {
            label: "Chí Công",
            value: 22996,
            slug: "chi-cong",
            status: "empty",
          },
          {
            label: "Bình Thạnh",
            value: 22999,
            slug: "binh-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bắc Bình",
        value: 596,
        slug: "huyen-bac-binh",
        status: "empty",
        ward: [
          { label: "Chợ Lầu", value: 23005, slug: "cho-lau", status: "empty" },
          {
            label: "Phan Sơn",
            value: 23008,
            slug: "phan-son",
            status: "empty",
          },
          {
            label: "Phan Lâm",
            value: 23011,
            slug: "phan-lam",
            status: "empty",
          },
          { label: "Bình An", value: 23014, slug: "binh-an", status: "empty" },
          {
            label: "Phan Điền",
            value: 23017,
            slug: "phan-dien",
            status: "empty",
          },
          {
            label: "Hải Ninh",
            value: 23020,
            slug: "hai-ninh",
            status: "empty",
          },
          {
            label: "Sông Lũy",
            value: 23023,
            slug: "song-luy",
            status: "empty",
          },
          {
            label: "Phan Tiến",
            value: 23026,
            slug: "phan-tien",
            status: "empty",
          },
          {
            label: "Sông Bình",
            value: 23029,
            slug: "song-binh",
            status: "empty",
          },
          {
            label: "Lương Sơn",
            value: 23032,
            slug: "luong-son",
            status: "empty",
          },
          {
            label: "Phan Hòa",
            value: 23035,
            slug: "phan-hoa",
            status: "empty",
          },
          {
            label: "Phan Thanh",
            value: 23038,
            slug: "phan-thanh",
            status: "empty",
          },
          {
            label: "Hồng Thái",
            value: 23041,
            slug: "hong-thai",
            status: "empty",
          },
          {
            label: "Phan Hiệp",
            value: 23044,
            slug: "phan-hiep",
            status: "empty",
          },
          {
            label: "Bình Tân",
            value: 23047,
            slug: "binh-tan",
            status: "empty",
          },
          {
            label: "Phan Rí Thành",
            value: 23050,
            slug: "phan-ri-thanh",
            status: "empty",
          },
          {
            label: "Hòa Thắng",
            value: 23053,
            slug: "hoa-thang",
            status: "empty",
          },
          {
            label: "Hồng Phong",
            value: 23056,
            slug: "hong-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hàm Thuận Bắc",
        value: 597,
        slug: "huyen-ham-thuan-bac",
        status: "empty",
        ward: [
          { label: "Ma Lâm", value: 23059, slug: "ma-lam", status: "empty" },
          {
            label: "Phú Long",
            value: 23062,
            slug: "phu-long",
            status: "empty",
          },
          { label: "La Dạ", value: 23065, slug: "la-da", status: "empty" },
          {
            label: "Đông Tiến",
            value: 23068,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Thuận Hòa",
            value: 23071,
            slug: "thuan-hoa",
            status: "empty",
          },
          {
            label: "Đông Giang",
            value: 23074,
            slug: "dong-giang",
            status: "empty",
          },
          { label: "Hàm Phú", value: 23077, slug: "ham-phu", status: "empty" },
          {
            label: "Hồng Liêm",
            value: 23080,
            slug: "hong-liem",
            status: "empty",
          },
          {
            label: "Thuận Minh",
            value: 23083,
            slug: "thuan-minh",
            status: "empty",
          },
          {
            label: "Hồng Sơn",
            value: 23086,
            slug: "hong-son",
            status: "empty",
          },
          { label: "Hàm Trí", value: 23089, slug: "ham-tri", status: "empty" },
          { label: "Hàm Đức", value: 23092, slug: "ham-duc", status: "empty" },
          {
            label: "Hàm Liêm",
            value: 23095,
            slug: "ham-liem",
            status: "empty",
          },
          {
            label: "Hàm Chính",
            value: 23098,
            slug: "ham-chinh",
            status: "empty",
          },
          {
            label: "Hàm Hiệp",
            value: 23101,
            slug: "ham-hiep",
            status: "empty",
          },
          {
            label: "Hàm Thắng",
            value: 23104,
            slug: "ham-thang",
            status: "empty",
          },
          { label: "Đa Mi", value: 23107, slug: "da-mi", status: "empty" },
        ],
      },
      {
        label: "Huyện Hàm Thuận Nam",
        value: 598,
        slug: "huyen-ham-thuan-nam",
        status: "empty",
        ward: [
          {
            label: "Thuận Nam",
            value: 23110,
            slug: "thuan-nam",
            status: "empty",
          },
          {
            label: "Mỹ Thạnh",
            value: 23113,
            slug: "my-thanh",
            status: "empty",
          },
          { label: "Hàm Cần", value: 23116, slug: "ham-can", status: "empty" },
          {
            label: "Mương Mán",
            value: 23119,
            slug: "muong-man",
            status: "empty",
          },
          {
            label: "Hàm Thạnh",
            value: 23122,
            slug: "ham-thanh",
            status: "empty",
          },
          {
            label: "Hàm Kiệm",
            value: 23125,
            slug: "ham-kiem",
            status: "empty",
          },
          {
            label: "Hàm Cường",
            value: 23128,
            slug: "ham-cuong",
            status: "empty",
          },
          { label: "Hàm Mỹ", value: 23131, slug: "ham-my", status: "empty" },
          { label: "Tân Lập", value: 23134, slug: "tan-lap", status: "empty" },
          {
            label: "Hàm Minh",
            value: 23137,
            slug: "ham-minh",
            status: "empty",
          },
          {
            label: "Thuận Quí",
            value: 23140,
            slug: "thuan-qui",
            status: "empty",
          },
          {
            label: "Tân Thuận",
            value: 23143,
            slug: "tan-thuan",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 23146,
            slug: "tan-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tánh Linh",
        value: 599,
        slug: "huyen-tanh-linh",
        status: "empty",
        ward: [
          {
            label: "Lạc Tánh",
            value: 23149,
            slug: "lac-tanh",
            status: "empty",
          },
          {
            label: "Bắc Ruộng",
            value: 23152,
            slug: "bac-ruong",
            status: "empty",
          },
          {
            label: "Nghị Đức",
            value: 23158,
            slug: "nghi-duc",
            status: "empty",
          },
          { label: "La Ngâu", value: 23161, slug: "la-ngau", status: "empty" },
          {
            label: "Huy Khiêm",
            value: 23164,
            slug: "huy-khiem",
            status: "empty",
          },
          { label: "Măng Tố", value: 23167, slug: "mang-to", status: "empty" },
          { label: "Đức Phú", value: 23170, slug: "duc-phu", status: "empty" },
          {
            label: "Đồng Kho",
            value: 23173,
            slug: "dong-kho",
            status: "empty",
          },
          { label: "Gia An", value: 23176, slug: "gia-an", status: "empty" },
          {
            label: "Đức Bình",
            value: 23179,
            slug: "duc-binh",
            status: "empty",
          },
          {
            label: "Gia Huynh",
            value: 23182,
            slug: "gia-huynh",
            status: "empty",
          },
          {
            label: "Đức Thuận",
            value: 23185,
            slug: "duc-thuan",
            status: "empty",
          },
          {
            label: "Suối Kiết",
            value: 23188,
            slug: "suoi-kiet",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đức Linh",
        value: 600,
        slug: "huyen-duc-linh",
        status: "empty",
        ward: [
          { label: "Võ Xu", value: 23191, slug: "vo-xu", status: "empty" },
          { label: "Đức Tài", value: 23194, slug: "duc-tai", status: "empty" },
          { label: "Đa Kai", value: 23197, slug: "da-kai", status: "empty" },
          {
            label: "Sùng Nhơn",
            value: 23200,
            slug: "sung-nhon",
            status: "empty",
          },
          { label: "Mê Pu", value: 23203, slug: "me-pu", status: "empty" },
          {
            label: "Nam Chính",
            value: 23206,
            slug: "nam-chinh",
            status: "empty",
          },
          {
            label: "Đức Hạnh",
            value: 23212,
            slug: "duc-hanh",
            status: "empty",
          },
          { label: "Đức Tín", value: 23215, slug: "duc-tin", status: "empty" },
          { label: "Vũ Hoà", value: 23218, slug: "vu-hoa", status: "empty" },
          { label: "Tân Hà", value: 23221, slug: "tan-ha", status: "empty" },
          { label: "Đông Hà", value: 23224, slug: "dong-ha", status: "empty" },
          { label: "Trà Tân", value: 23227, slug: "tra-tan", status: "empty" },
        ],
      },
      {
        label: "Huyện Hàm Tân",
        value: 601,
        slug: "huyen-ham-tan",
        status: "empty",
        ward: [
          {
            label: "Tân Minh",
            value: 23230,
            slug: "tan-minh",
            status: "empty",
          },
          {
            label: "Tân Nghĩa",
            value: 23236,
            slug: "tan-nghia",
            status: "empty",
          },
          {
            label: "Sông Phan",
            value: 23239,
            slug: "song-phan",
            status: "empty",
          },
          {
            label: "Tân Phúc",
            value: 23242,
            slug: "tan-phuc",
            status: "empty",
          },
          { label: "Tân Đức", value: 23251, slug: "tan-duc", status: "empty" },
          {
            label: "Tân Thắng",
            value: 23254,
            slug: "tan-thang",
            status: "empty",
          },
          {
            label: "Thắng Hải",
            value: 23255,
            slug: "thang-hai",
            status: "empty",
          },
          { label: "Tân Hà", value: 23257, slug: "tan-ha", status: "empty" },
          {
            label: "Tân Xuân",
            value: 23260,
            slug: "tan-xuan",
            status: "empty",
          },
          { label: "Sơn Mỹ", value: 23266, slug: "son-my", status: "empty" },
        ],
      },
      {
        label: "Huyện Phú Quí",
        value: 602,
        slug: "huyen-phu-qui",
        status: "empty",
        ward: [
          {
            label: "Ngũ Phụng",
            value: 23272,
            slug: "ngu-phung",
            status: "empty",
          },
          {
            label: "Long Hải",
            value: 23275,
            slug: "long-hai",
            status: "empty",
          },
          {
            label: "Tam Thanh",
            value: 23278,
            slug: "tam-thanh",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Kon Tum",
    value: 62,
    slug: "kon-tum",
    status: "empty",
    district: [
      {
        label: "Thành phố Kon Tum",
        value: 608,
        slug: "thanh-pho-kon-tum",
        status: "empty",
        ward: [
          {
            label: "Quang Trung",
            value: 23281,
            slug: "quang-trung",
            status: "empty",
          },
          { label: "Duy Tân", value: 23284, slug: "duy-tan", status: "empty" },
          {
            label: "Quyết Thắng",
            value: 23287,
            slug: "quyet-thang",
            status: "empty",
          },
          {
            label: "Trường Chinh",
            value: 23290,
            slug: "truong-chinh",
            status: "empty",
          },
          {
            label: "Thắng Lợi",
            value: 23293,
            slug: "thang-loi",
            status: "empty",
          },
          { label: "Ngô Mây", value: 23296, slug: "ngo-may", status: "empty" },
          {
            label: "Thống Nhất",
            value: 23299,
            slug: "thong-nhat",
            status: "empty",
          },
          { label: "Lê Lợi", value: 23302, slug: "le-loi", status: "empty" },
          {
            label: "Nguyễn Trãi",
            value: 23305,
            slug: "nguyen-trai",
            status: "empty",
          },
          {
            label: "Trần Hưng Đạo",
            value: 23308,
            slug: "tran-hung-dao",
            status: "empty",
          },
          { label: "Đắk Cấm", value: 23311, slug: "dak-cam", status: "empty" },
          {
            label: "Xã Kroong",
            value: 23314,
            slug: "xa-kroong",
            status: "empty",
          },
          {
            label: "Ngọk Bay",
            value: 23317,
            slug: "ngok-bay",
            status: "empty",
          },
          {
            label: "Vinh Quang",
            value: 23320,
            slug: "vinh-quang",
            status: "empty",
          },
          { label: "Đắk Blà", value: 23323, slug: "dak-bla", status: "empty" },
          { label: "Ia Chim", value: 23326, slug: "ia-chim", status: "empty" },
          {
            label: "Đăk Năng",
            value: 23327,
            slug: "dak-nang",
            status: "empty",
          },
          {
            label: "Đoàn Kết",
            value: 23329,
            slug: "doan-ket",
            status: "empty",
          },
          {
            label: "Chư Hreng",
            value: 23332,
            slug: "chu-hreng",
            status: "empty",
          },
          {
            label: "Đắk Rơ Wa",
            value: 23335,
            slug: "dak-ro-wa",
            status: "empty",
          },
          {
            label: "Hòa Bình",
            value: 23338,
            slug: "hoa-binh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đắk Glei",
        value: 610,
        slug: "huyen-dak-glei",
        status: "empty",
        ward: [
          {
            label: "Đắk Glei",
            value: 23341,
            slug: "dak-glei",
            status: "empty",
          },
          { label: "Đắk Blô", value: 23344, slug: "dak-blo", status: "empty" },
          { label: "Đắk Man", value: 23347, slug: "dak-man", status: "empty" },
          {
            label: "Đắk Nhoong",
            value: 23350,
            slug: "dak-nhoong",
            status: "empty",
          },
          { label: "Đắk Pék", value: 23353, slug: "dak-pek", status: "empty" },
          {
            label: "Đắk Choong",
            value: 23356,
            slug: "dak-choong",
            status: "empty",
          },
          { label: "Xã Xốp", value: 23359, slug: "xa-xop", status: "empty" },
          {
            label: "Mường Hoong",
            value: 23362,
            slug: "muong-hoong",
            status: "empty",
          },
          {
            label: "Ngọc Linh",
            value: 23365,
            slug: "ngoc-linh",
            status: "empty",
          },
          {
            label: "Đắk Long",
            value: 23368,
            slug: "dak-long",
            status: "empty",
          },
          {
            label: "Đắk KRoong",
            value: 23371,
            slug: "dak-kroong",
            status: "empty",
          },
          { label: "Đắk Môn", value: 23374, slug: "dak-mon", status: "empty" },
        ],
      },
      {
        label: "Huyện Ngọc Hồi",
        value: 611,
        slug: "huyen-ngoc-hoi",
        status: "empty",
        ward: [
          {
            label: "Plei Kần",
            value: 23377,
            slug: "plei-kan",
            status: "empty",
          },
          { label: "Đắk Ang", value: 23380, slug: "dak-ang", status: "empty" },
          { label: "Đắk Dục", value: 23383, slug: "dak-duc", status: "empty" },
          {
            label: "Đắk Nông",
            value: 23386,
            slug: "dak-nong",
            status: "empty",
          },
          { label: "Đắk Xú", value: 23389, slug: "dak-xu", status: "empty" },
          { label: "Đắk Kan", value: 23392, slug: "dak-kan", status: "empty" },
          { label: "Bờ Y", value: 23395, slug: "bo-y", status: "empty" },
          {
            label: "Sa Loong",
            value: 23398,
            slug: "sa-loong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đắk Tô",
        value: 612,
        slug: "huyen-dak-to",
        status: "empty",
        ward: [
          { label: "Đắk Tô", value: 23401, slug: "dak-to", status: "empty" },
          {
            label: "Đắk Rơ Nga",
            value: 23427,
            slug: "dak-ro-nga",
            status: "empty",
          },
          { label: "Ngọk Tụ", value: 23428, slug: "ngok-tu", status: "empty" },
          {
            label: "Đắk Trăm",
            value: 23430,
            slug: "dak-tram",
            status: "empty",
          },
          { label: "Văn Lem", value: 23431, slug: "van-lem", status: "empty" },
          { label: "Kon Đào", value: 23434, slug: "kon-dao", status: "empty" },
          {
            label: "Tân Cảnh",
            value: 23437,
            slug: "tan-canh",
            status: "empty",
          },
          {
            label: "Diên Bình",
            value: 23440,
            slug: "dien-binh",
            status: "empty",
          },
          { label: "Pô Kô", value: 23443, slug: "po-ko", status: "empty" },
        ],
      },
      {
        label: "Huyện Kon Plông",
        value: 613,
        slug: "huyen-kon-plong",
        status: "empty",
        ward: [
          { label: "Đắk Nên", value: 23452, slug: "dak-nen", status: "empty" },
          {
            label: "Đắk Ring",
            value: 23455,
            slug: "dak-ring",
            status: "empty",
          },
          {
            label: "Măng Buk",
            value: 23458,
            slug: "mang-buk",
            status: "empty",
          },
          {
            label: "Đắk Tăng",
            value: 23461,
            slug: "dak-tang",
            status: "empty",
          },
          {
            label: "Ngok Tem",
            value: 23464,
            slug: "ngok-tem",
            status: "empty",
          },
          { label: "Pờ Ê", value: 23467, slug: "po-e", status: "empty" },
          {
            label: "Măng Cành",
            value: 23470,
            slug: "mang-canh",
            status: "empty",
          },
          {
            label: "Măng Đen",
            value: 23473,
            slug: "mang-den",
            status: "empty",
          },
          { label: "Xã Hiếu", value: 23476, slug: "xa-hieu", status: "empty" },
        ],
      },
      {
        label: "Huyện Kon Rẫy",
        value: 614,
        slug: "huyen-kon-ray",
        status: "empty",
        ward: [
          { label: "Đắk Rve", value: 23479, slug: "dak-rve", status: "empty" },
          { label: "Đắk Kôi", value: 23482, slug: "dak-koi", status: "empty" },
          {
            label: "Đắk Tơ Lung",
            value: 23485,
            slug: "dak-to-lung",
            status: "empty",
          },
          {
            label: "Đắk Ruồng",
            value: 23488,
            slug: "dak-ruong",
            status: "empty",
          },
          { label: "Đắk Pne", value: 23491, slug: "dak-pne", status: "empty" },
          {
            label: "Đắk Tờ Re",
            value: 23494,
            slug: "dak-to-re",
            status: "empty",
          },
          { label: "Tân Lập", value: 23497, slug: "tan-lap", status: "empty" },
        ],
      },
      {
        label: "Huyện Đắk Hà",
        value: 615,
        slug: "huyen-dak-ha",
        status: "empty",
        ward: [
          { label: "Đắk Hà", value: 23500, slug: "dak-ha", status: "empty" },
          { label: "Đắk PXi", value: 23503, slug: "dak-pxi", status: "empty" },
          {
            label: "Đăk Long",
            value: 23504,
            slug: "dak-long",
            status: "empty",
          },
          {
            label: "Đắk HRing",
            value: 23506,
            slug: "dak-hring",
            status: "empty",
          },
          { label: "Đắk Ui", value: 23509, slug: "dak-ui", status: "empty" },
          {
            label: "Đăk Ngọk",
            value: 23510,
            slug: "dak-ngok",
            status: "empty",
          },
          { label: "Đắk Mar", value: 23512, slug: "dak-mar", status: "empty" },
          {
            label: "Ngok Wang",
            value: 23515,
            slug: "ngok-wang",
            status: "empty",
          },
          {
            label: "Ngok Réo",
            value: 23518,
            slug: "ngok-reo",
            status: "empty",
          },
          { label: "Hà Mòn", value: 23521, slug: "ha-mon", status: "empty" },
          { label: "Đắk La", value: 23524, slug: "dak-la", status: "empty" },
        ],
      },
      {
        label: "Huyện Sa Thầy",
        value: 616,
        slug: "huyen-sa-thay",
        status: "empty",
        ward: [
          { label: "Sa Thầy", value: 23527, slug: "sa-thay", status: "empty" },
          { label: "Rơ Kơi", value: 23530, slug: "ro-koi", status: "empty" },
          { label: "Sa Nhơn", value: 23533, slug: "sa-nhon", status: "empty" },
          {
            label: "Hơ Moong",
            value: 23534,
            slug: "ho-moong",
            status: "empty",
          },
          { label: "Mô Rai", value: 23536, slug: "mo-rai", status: "empty" },
          { label: "Sa Sơn", value: 23539, slug: "sa-son", status: "empty" },
          {
            label: "Sa Nghĩa",
            value: 23542,
            slug: "sa-nghia",
            status: "empty",
          },
          { label: "Sa Bình", value: 23545, slug: "sa-binh", status: "empty" },
          { label: "Ya Xiêr", value: 23548, slug: "ya-xier", status: "empty" },
          { label: "Ya Tăng", value: 23551, slug: "ya-tang", status: "empty" },
          { label: "Ya ly", value: 23554, slug: "ya-ly", status: "empty" },
        ],
      },
      {
        label: "Huyện Tu Mơ Rông",
        value: 617,
        slug: "huyen-tu-mo-rong",
        status: "empty",
        ward: [
          {
            label: "Ngọc Lây",
            value: 23404,
            slug: "ngoc-lay",
            status: "empty",
          },
          { label: "Đắk Na", value: 23407, slug: "dak-na", status: "empty" },
          { label: "Măng Ri", value: 23410, slug: "mang-ri", status: "empty" },
          {
            label: "Ngọc Yêu",
            value: 23413,
            slug: "ngoc-yeu",
            status: "empty",
          },
          { label: "Đắk Sao", value: 23416, slug: "dak-sao", status: "empty" },
          {
            label: "Đắk Rơ Ông",
            value: 23417,
            slug: "dak-ro-ong",
            status: "empty",
          },
          {
            label: "Đắk Tờ Kan",
            value: 23419,
            slug: "dak-to-kan",
            status: "empty",
          },
          {
            label: "Tu Mơ Rông",
            value: 23422,
            slug: "tu-mo-rong",
            status: "empty",
          },
          { label: "Đắk Hà", value: 23425, slug: "dak-ha", status: "empty" },
          { label: "Tê Xăng", value: 23446, slug: "te-xang", status: "empty" },
          {
            label: "Văn Xuôi",
            value: 23449,
            slug: "van-xuoi",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ia H' Drai",
        value: 618,
        slug: "huyen-ia-h-drai",
        status: "empty",
        ward: [
          { label: "Ia Đal", value: 23535, slug: "ia-dal", status: "empty" },
          { label: "Ia Dom", value: 23537, slug: "ia-dom", status: "empty" },
          { label: "Ia Tơi", value: 23538, slug: "ia-toi", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Gia Lai",
    value: 64,
    slug: "gia-lai",
    status: "empty",
    district: [
      {
        label: "Thành phố Pleiku",
        value: 622,
        slug: "thanh-pho-pleiku",
        status: "empty",
        ward: [
          { label: "Yên Đỗ", value: 23557, slug: "yen-do", status: "empty" },
          {
            label: "Diên Hồng",
            value: 23560,
            slug: "dien-hong",
            status: "empty",
          },
          {
            label: "Ia Kring",
            value: 23563,
            slug: "ia-kring",
            status: "empty",
          },
          {
            label: "Hội Thương",
            value: 23566,
            slug: "hoi-thuong",
            status: "empty",
          },
          { label: "Hội Phú", value: 23569, slug: "hoi-phu", status: "empty" },
          {
            label: "Phù Đổng",
            value: 23570,
            slug: "phu-dong",
            status: "empty",
          },
          { label: "Hoa Lư", value: 23572, slug: "hoa-lu", status: "empty" },
          { label: "Tây Sơn", value: 23575, slug: "tay-son", status: "empty" },
          {
            label: "Thống Nhất",
            value: 23578,
            slug: "thong-nhat",
            status: "empty",
          },
          { label: "Đống Đa", value: 23579, slug: "dong-da", status: "empty" },
          { label: "Trà Bá", value: 23581, slug: "tra-ba", status: "empty" },
          {
            label: "Thắng Lợi",
            value: 23582,
            slug: "thang-loi",
            status: "empty",
          },
          { label: "Yên Thế", value: 23584, slug: "yen-the", status: "empty" },
          {
            label: "Chi Lăng",
            value: 23586,
            slug: "chi-lang",
            status: "empty",
          },
          { label: "Biển Hồ", value: 23590, slug: "bien-ho", status: "empty" },
          { label: "Trà Đa", value: 23596, slug: "tra-da", status: "empty" },
          { label: "Chư Á", value: 23599, slug: "chu-a", status: "empty" },
          { label: "An Phú", value: 23602, slug: "an-phu", status: "empty" },
          {
            label: "Diên Phú",
            value: 23605,
            slug: "dien-phu",
            status: "empty",
          },
          { label: "Ia Kênh", value: 23608, slug: "ia-kenh", status: "empty" },
          { label: "Xã Gào", value: 23611, slug: "xa-gao", status: "empty" },
        ],
      },
      {
        label: "Thị xã An Khê",
        value: 623,
        slug: "thi-xa-an-khe",
        status: "empty",
        ward: [
          { label: "An Bình", value: 23614, slug: "an-binh", status: "empty" },
          { label: "Tây Sơn", value: 23617, slug: "tay-son", status: "empty" },
          { label: "An Phú", value: 23620, slug: "an-phu", status: "empty" },
          { label: "An Tân", value: 23623, slug: "an-tan", status: "empty" },
          { label: "Tú An", value: 23626, slug: "tu-an", status: "empty" },
          { label: "Xuân An", value: 23627, slug: "xuan-an", status: "empty" },
          { label: "Cửu An", value: 23629, slug: "cuu-an", status: "empty" },
          {
            label: "An Phước",
            value: 23630,
            slug: "an-phuoc",
            status: "empty",
          },
          { label: "Song An", value: 23632, slug: "song-an", status: "empty" },
          { label: "Ngô Mây", value: 23633, slug: "ngo-may", status: "empty" },
          {
            label: "Thành An",
            value: 23635,
            slug: "thanh-an",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Ayun Pa",
        value: 624,
        slug: "thi-xa-ayun-pa",
        status: "empty",
        ward: [
          {
            label: "Cheo Reo",
            value: 24041,
            slug: "cheo-reo",
            status: "empty",
          },
          {
            label: "Hòa Bình",
            value: 24042,
            slug: "hoa-binh",
            status: "empty",
          },
          {
            label: "Đoàn Kết",
            value: 24044,
            slug: "doan-ket",
            status: "empty",
          },
          { label: "Sông Bờ", value: 24045, slug: "song-bo", status: "empty" },
          { label: "Ia RBol", value: 24064, slug: "ia-rbol", status: "empty" },
          { label: "Chư Băh", value: 24065, slug: "chu-bah", status: "empty" },
          { label: "Ia RTô", value: 24070, slug: "ia-rto", status: "empty" },
          { label: "Ia Sao", value: 24073, slug: "ia-sao", status: "empty" },
        ],
      },
      {
        label: "Huyện KBang",
        value: 625,
        slug: "huyen-kbang",
        status: "empty",
        ward: [
          {
            label: "Thị trấn KBang",
            value: 23638,
            slug: "thi-tran-kbang",
            status: "empty",
          },
          { label: "Kon Pne", value: 23641, slug: "kon-pne", status: "empty" },
          {
            label: "Đăk Roong",
            value: 23644,
            slug: "dak-roong",
            status: "empty",
          },
          {
            label: "Sơn Lang",
            value: 23647,
            slug: "son-lang",
            status: "empty",
          },
          {
            label: "Xã KRong",
            value: 23650,
            slug: "xa-krong",
            status: "empty",
          },
          { label: "Sơ Pai", value: 23653, slug: "so-pai", status: "empty" },
          { label: "Lơ Ku", value: 23656, slug: "lo-ku", status: "empty" },
          { label: "Xã Đông", value: 23659, slug: "xa-dong", status: "empty" },
          {
            label: "Đak SMar",
            value: 23660,
            slug: "dak-smar",
            status: "empty",
          },
          {
            label: "Nghĩa An",
            value: 23662,
            slug: "nghia-an",
            status: "empty",
          },
          { label: "Tơ Tung", value: 23665, slug: "to-tung", status: "empty" },
          {
            label: "Kông Lơng Khơng",
            value: 23668,
            slug: "kong-long-khong",
            status: "empty",
          },
          {
            label: "Kông Bơ La",
            value: 23674,
            slug: "kong-bo-la",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đăk Đoa",
        value: 626,
        slug: "huyen-dak-doa",
        status: "empty",
        ward: [
          { label: "Đăk Đoa", value: 23677, slug: "dak-doa", status: "empty" },
          { label: "Hà Đông", value: 23680, slug: "ha-dong", status: "empty" },
          {
            label: "Đăk Sơmei",
            value: 23683,
            slug: "dak-somei",
            status: "empty",
          },
          {
            label: "Đăk Krong",
            value: 23684,
            slug: "dak-krong",
            status: "empty",
          },
          {
            label: "Hải Yang",
            value: 23686,
            slug: "hai-yang",
            status: "empty",
          },
          {
            label: "Kon Gang",
            value: 23689,
            slug: "kon-gang",
            status: "empty",
          },
          { label: "Hà Bầu", value: 23692, slug: "ha-bau", status: "empty" },
          {
            label: "Nam Yang",
            value: 23695,
            slug: "nam-yang",
            status: "empty",
          },
          { label: "K' Dang", value: 23698, slug: "k'-dang", status: "empty" },
          { label: "H' Neng", value: 23701, slug: "h'-neng", status: "empty" },
          {
            label: "Tân Bình",
            value: 23704,
            slug: "tan-binh",
            status: "empty",
          },
          { label: "Xã Glar", value: 23707, slug: "xa-glar", status: "empty" },
          { label: "A Dơk", value: 23710, slug: "a-dok", status: "empty" },
          {
            label: "Xã Trang",
            value: 23713,
            slug: "xa-trang",
            status: "empty",
          },
          { label: "Xã HNol", value: 23714, slug: "xa-hnol", status: "empty" },
          { label: "Ia Pết", value: 23716, slug: "ia-pet", status: "empty" },
          { label: "Ia Băng", value: 23719, slug: "ia-bang", status: "empty" },
        ],
      },
      {
        label: "Huyện Chư Păh",
        value: 627,
        slug: "huyen-chu-pah",
        status: "empty",
        ward: [
          { label: "Phú Hòa", value: 23722, slug: "phu-hoa", status: "empty" },
          { label: "Hà Tây", value: 23725, slug: "ha-tay", status: "empty" },
          {
            label: "Ia Khươl",
            value: 23728,
            slug: "ia-khuol",
            status: "empty",
          },
          { label: "Ia Phí", value: 23731, slug: "ia-phi", status: "empty" },
          { label: "Ia Ly", value: 23734, slug: "ia-ly", status: "empty" },
          {
            label: "Ia Mơ Nông",
            value: 23737,
            slug: "ia-mo-nong",
            status: "empty",
          },
          {
            label: "Ia Kreng",
            value: 23738,
            slug: "ia-kreng",
            status: "empty",
          },
          {
            label: "Đăk Tơ Ver",
            value: 23740,
            slug: "dak-to-ver",
            status: "empty",
          },
          { label: "Hòa Phú", value: 23743, slug: "hoa-phu", status: "empty" },
          {
            label: "Chư Đăng Ya",
            value: 23746,
            slug: "chu-dang-ya",
            status: "empty",
          },
          { label: "Ia Ka", value: 23749, slug: "ia-ka", status: "empty" },
          { label: "Ia Nhin", value: 23752, slug: "ia-nhin", status: "empty" },
          {
            label: "Nghĩa Hòa",
            value: 23755,
            slug: "nghia-hoa",
            status: "empty",
          },
          {
            label: "Nghĩa Hưng",
            value: 23761,
            slug: "nghia-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ia Grai",
        value: 628,
        slug: "huyen-ia-grai",
        status: "empty",
        ward: [
          { label: "Ia Kha", value: 23764, slug: "ia-kha", status: "empty" },
          { label: "Ia Sao", value: 23767, slug: "ia-sao", status: "empty" },
          { label: "Ia Yok", value: 23768, slug: "ia-yok", status: "empty" },
          {
            label: "Ia Hrung",
            value: 23770,
            slug: "ia-hrung",
            status: "empty",
          },
          { label: "Ia Bă", value: 23771, slug: "ia-ba", status: "empty" },
          { label: "Ia Khai", value: 23773, slug: "ia-khai", status: "empty" },
          { label: "Ia KRai", value: 23776, slug: "ia-krai", status: "empty" },
          {
            label: "Ia Grăng",
            value: 23778,
            slug: "ia-grang",
            status: "empty",
          },
          { label: "Ia Tô", value: 23779, slug: "ia-to", status: "empty" },
          { label: "Ia O", value: 23782, slug: "ia-o", status: "empty" },
          { label: "Ia Dêr", value: 23785, slug: "ia-der", status: "empty" },
          { label: "Ia Chia", value: 23788, slug: "ia-chia", status: "empty" },
          { label: "Ia Pếch", value: 23791, slug: "ia-pech", status: "empty" },
        ],
      },
      {
        label: "Huyện Mang Yang",
        value: 629,
        slug: "huyen-mang-yang",
        status: "empty",
        ward: [
          {
            label: "Kon Dơng",
            value: 23794,
            slug: "kon-dong",
            status: "empty",
          },
          { label: "Xã Ayun", value: 23797, slug: "xa-ayun", status: "empty" },
          {
            label: "Đak Jơ Ta",
            value: 23798,
            slug: "dak-jo-ta",
            status: "empty",
          },
          {
            label: "Đak Ta Ley",
            value: 23799,
            slug: "dak-ta-ley",
            status: "empty",
          },
          { label: "Xã Hra", value: 23800, slug: "xa-hra", status: "empty" },
          { label: "Đăk Yă", value: 23803, slug: "dak-ya", status: "empty" },
          {
            label: "Đăk Djrăng",
            value: 23806,
            slug: "dak-djrang",
            status: "empty",
          },
          { label: "Lơ Pang", value: 23809, slug: "lo-pang", status: "empty" },
          {
            label: "Kon Thụp",
            value: 23812,
            slug: "kon-thup",
            status: "empty",
          },
          { label: "Đê Ar", value: 23815, slug: "de-ar", status: "empty" },
          {
            label: "Kon Chiêng",
            value: 23818,
            slug: "kon-chieng",
            status: "empty",
          },
          {
            label: "Đăk Trôi",
            value: 23821,
            slug: "dak-troi",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Kông Chro",
        value: 630,
        slug: "huyen-kong-chro",
        status: "empty",
        ward: [
          {
            label: "Kông Chro",
            value: 23824,
            slug: "kong-chro",
            status: "empty",
          },
          {
            label: "Chư Krêy",
            value: 23827,
            slug: "chu-krey",
            status: "empty",
          },
          {
            label: "An Trung",
            value: 23830,
            slug: "an-trung",
            status: "empty",
          },
          {
            label: "Kông Yang",
            value: 23833,
            slug: "kong-yang",
            status: "empty",
          },
          {
            label: "Đăk Tơ Pang",
            value: 23836,
            slug: "dak-to-pang",
            status: "empty",
          },
          { label: "Xã SRó", value: 23839, slug: "xa-sro", status: "empty" },
          {
            label: "Đắk Kơ Ning",
            value: 23840,
            slug: "dak-ko-ning",
            status: "empty",
          },
          {
            label: "Đăk Song",
            value: 23842,
            slug: "dak-song",
            status: "empty",
          },
          {
            label: "Đăk Pling",
            value: 23843,
            slug: "dak-pling",
            status: "empty",
          },
          {
            label: "Yang Trung",
            value: 23845,
            slug: "yang-trung",
            status: "empty",
          },
          {
            label: "Đăk Pơ Pho",
            value: 23846,
            slug: "dak-po-pho",
            status: "empty",
          },
          { label: "Ya Ma", value: 23848, slug: "ya-ma", status: "empty" },
          {
            label: "Chơ Long",
            value: 23851,
            slug: "cho-long",
            status: "empty",
          },
          {
            label: "Yang Nam",
            value: 23854,
            slug: "yang-nam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đức Cơ",
        value: 631,
        slug: "huyen-duc-co",
        status: "empty",
        ward: [
          { label: "Chư Ty", value: 23857, slug: "chu-ty", status: "empty" },
          { label: "Ia Dơk", value: 23860, slug: "ia-dok", status: "empty" },
          { label: "Ia Krêl", value: 23863, slug: "ia-krel", status: "empty" },
          { label: "Ia Din", value: 23866, slug: "ia-din", status: "empty" },
          { label: "Ia Kla", value: 23869, slug: "ia-kla", status: "empty" },
          { label: "Ia Dom", value: 23872, slug: "ia-dom", status: "empty" },
          { label: "Ia Lang", value: 23875, slug: "ia-lang", status: "empty" },
          {
            label: "Ia Kriêng",
            value: 23878,
            slug: "ia-krieng",
            status: "empty",
          },
          { label: "Ia Pnôn", value: 23881, slug: "ia-pnon", status: "empty" },
          { label: "Ia Nan", value: 23884, slug: "ia-nan", status: "empty" },
        ],
      },
      {
        label: "Huyện Chư Prông",
        value: 632,
        slug: "huyen-chu-prong",
        status: "empty",
        ward: [
          {
            label: "Chư Prông",
            value: 23887,
            slug: "chu-prong",
            status: "empty",
          },
          { label: "Ia Kly", value: 23888, slug: "ia-kly", status: "empty" },
          {
            label: "Bình Giáo",
            value: 23890,
            slug: "binh-giao",
            status: "empty",
          },
          {
            label: "Ia Drăng",
            value: 23893,
            slug: "ia-drang",
            status: "empty",
          },
          {
            label: "Thăng Hưng",
            value: 23896,
            slug: "thang-hung",
            status: "empty",
          },
          { label: "Bàu Cạn", value: 23899, slug: "bau-can", status: "empty" },
          { label: "Ia Phìn", value: 23902, slug: "ia-phin", status: "empty" },
          { label: "Ia Băng", value: 23905, slug: "ia-bang", status: "empty" },
          { label: "Ia Tôr", value: 23908, slug: "ia-tor", status: "empty" },
          {
            label: "Ia Boòng",
            value: 23911,
            slug: "ia-boong",
            status: "empty",
          },
          { label: "Ia O", value: 23914, slug: "ia-o", status: "empty" },
          { label: "Ia Púch", value: 23917, slug: "ia-puch", status: "empty" },
          { label: "Ia Me", value: 23920, slug: "ia-me", status: "empty" },
          { label: "Ia Vê", value: 23923, slug: "ia-ve", status: "empty" },
          { label: "Ia Bang", value: 23924, slug: "ia-bang", status: "empty" },
          { label: "Ia Pia", value: 23926, slug: "ia-pia", status: "empty" },
          { label: "Ia Ga", value: 23929, slug: "ia-ga", status: "empty" },
          { label: "Ia Lâu", value: 23932, slug: "ia-lau", status: "empty" },
          { label: "Ia Piơr", value: 23935, slug: "ia-pior", status: "empty" },
          { label: "Ia Mơ", value: 23938, slug: "ia-mo", status: "empty" },
        ],
      },
      {
        label: "Huyện Chư Sê",
        value: 633,
        slug: "huyen-chu-se",
        status: "empty",
        ward: [
          { label: "Chư Sê", value: 23941, slug: "chu-se", status: "empty" },
          { label: "Ia Tiêm", value: 23944, slug: "ia-tiem", status: "empty" },
          {
            label: "Chư Pơng",
            value: 23945,
            slug: "chu-pong",
            status: "empty",
          },
          {
            label: "Bar Măih",
            value: 23946,
            slug: "bar-maih",
            status: "empty",
          },
          {
            label: "Bờ Ngoong",
            value: 23947,
            slug: "bo-ngoong",
            status: "empty",
          },
          { label: "Ia Glai", value: 23950, slug: "ia-glai", status: "empty" },
          { label: "AL Bá", value: 23953, slug: "al-ba", status: "empty" },
          {
            label: "Kông HTok",
            value: 23954,
            slug: "kong-htok",
            status: "empty",
          },
          { label: "Xã AYun", value: 23956, slug: "xa-ayun", status: "empty" },
          { label: "Ia HLốp", value: 23959, slug: "ia-hlop", status: "empty" },
          {
            label: "Ia Blang",
            value: 23962,
            slug: "ia-blang",
            status: "empty",
          },
          { label: "Xã Dun", value: 23965, slug: "xa-dun", status: "empty" },
          { label: "Ia Pal", value: 23966, slug: "ia-pal", status: "empty" },
          { label: "H Bông", value: 23968, slug: "h-bong", status: "empty" },
          { label: "Ia Ko", value: 23977, slug: "ia-ko", status: "empty" },
        ],
      },
      {
        label: "Huyện Đăk Pơ",
        value: 634,
        slug: "huyen-dak-po",
        status: "empty",
        ward: [
          { label: "Hà Tam", value: 23989, slug: "ha-tam", status: "empty" },
          {
            label: "An Thành",
            value: 23992,
            slug: "an-thanh",
            status: "empty",
          },
          { label: "Đak Pơ", value: 23995, slug: "dak-po", status: "empty" },
          {
            label: "Yang Bắc",
            value: 23998,
            slug: "yang-bac",
            status: "empty",
          },
          { label: "Cư An", value: 24001, slug: "cu-an", status: "empty" },
          { label: "Tân An", value: 24004, slug: "tan-an", status: "empty" },
          { label: "Phú An", value: 24007, slug: "phu-an", status: "empty" },
          { label: "Ya Hội", value: 24010, slug: "ya-hoi", status: "empty" },
        ],
      },
      {
        label: "Huyện Ia Pa",
        value: 635,
        slug: "huyen-ia-pa",
        status: "empty",
        ward: [
          { label: "Pờ Tó", value: 24013, slug: "po-to", status: "empty" },
          {
            label: "Chư Răng",
            value: 24016,
            slug: "chu-rang",
            status: "empty",
          },
          { label: "Ia KDăm", value: 24019, slug: "ia-kdam", status: "empty" },
          { label: "Kim Tân", value: 24022, slug: "kim-tan", status: "empty" },
          { label: "Chư Mố", value: 24025, slug: "chu-mo", status: "empty" },
          { label: "Ia Tul", value: 24028, slug: "ia-tul", status: "empty" },
          {
            label: "Ia Ma Rơn",
            value: 24031,
            slug: "ia-ma-ron",
            status: "empty",
          },
          {
            label: "Ia Broăi",
            value: 24034,
            slug: "ia-broai",
            status: "empty",
          },
          { label: "Ia Trok", value: 24037, slug: "ia-trok", status: "empty" },
        ],
      },
      {
        label: "Huyện Krông Pa",
        value: 637,
        slug: "huyen-krong-pa",
        status: "empty",
        ward: [
          { label: "Phú Túc", value: 24076, slug: "phu-tuc", status: "empty" },
          { label: "Ia RSai", value: 24079, slug: "ia-rsai", status: "empty" },
          {
            label: "Ia RSươm",
            value: 24082,
            slug: "ia-rsuom",
            status: "empty",
          },
          { label: "Chư Gu", value: 24085, slug: "chu-gu", status: "empty" },
          {
            label: "Đất Bằng",
            value: 24088,
            slug: "dat-bang",
            status: "empty",
          },
          { label: "Ia Mláh", value: 24091, slug: "ia-mlah", status: "empty" },
          {
            label: "Chư Drăng",
            value: 24094,
            slug: "chu-drang",
            status: "empty",
          },
          { label: "Phú Cần", value: 24097, slug: "phu-can", status: "empty" },
          {
            label: "Ia HDreh",
            value: 24100,
            slug: "ia-hdreh",
            status: "empty",
          },
          { label: "Ia RMok", value: 24103, slug: "ia-rmok", status: "empty" },
          {
            label: "Chư Ngọc",
            value: 24106,
            slug: "chu-ngoc",
            status: "empty",
          },
          { label: "Xã Uar", value: 24109, slug: "xa-uar", status: "empty" },
          {
            label: "Chư Rcăm",
            value: 24112,
            slug: "chu-rcam",
            status: "empty",
          },
          {
            label: "Krông Năng",
            value: 24115,
            slug: "krong-nang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phú Thiện",
        value: 638,
        slug: "huyen-phu-thien",
        status: "empty",
        ward: [
          {
            label: "Phú Thiện",
            value: 24043,
            slug: "phu-thien",
            status: "empty",
          },
          {
            label: "Chư A Thai",
            value: 24046,
            slug: "chu-a-thai",
            status: "empty",
          },
          { label: "Ayun Hạ", value: 24048, slug: "ayun-ha", status: "empty" },
          { label: "Ia Ake", value: 24049, slug: "ia-ake", status: "empty" },
          { label: "Ia Sol", value: 24052, slug: "ia-sol", status: "empty" },
          { label: "Ia Piar", value: 24055, slug: "ia-piar", status: "empty" },
          { label: "Ia Peng", value: 24058, slug: "ia-peng", status: "empty" },
          {
            label: "Chrôh Pơnan",
            value: 24060,
            slug: "chroh-ponan",
            status: "empty",
          },
          { label: "Ia Hiao", value: 24061, slug: "ia-hiao", status: "empty" },
          { label: "Ia Yeng", value: 24067, slug: "ia-yeng", status: "empty" },
        ],
      },
      {
        label: "Huyện Chư Pưh",
        value: 639,
        slug: "huyen-chu-puh",
        status: "empty",
        ward: [
          {
            label: "Nhơn Hoà",
            value: 23942,
            slug: "nhon-hoa",
            status: "empty",
          },
          { label: "Ia Hrú", value: 23971, slug: "ia-hru", status: "empty" },
          { label: "Ia Rong", value: 23972, slug: "ia-rong", status: "empty" },
          {
            label: "Ia Dreng",
            value: 23974,
            slug: "ia-dreng",
            status: "empty",
          },
          { label: "Ia Hla", value: 23978, slug: "ia-hla", status: "empty" },
          { label: "Chư Don", value: 23980, slug: "chu-don", status: "empty" },
          {
            label: "Ia Phang",
            value: 23983,
            slug: "ia-phang",
            status: "empty",
          },
          { label: "Ia Le", value: 23986, slug: "ia-le", status: "empty" },
          { label: "Ia BLứ", value: 23987, slug: "ia-blu", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Đắk Lắk",
    value: 66,
    slug: "dak-lak",
    status: "filled",
    district: [
      {
        label: "Thành phố Buôn Ma Thuột",
        value: 643,
        slug: "thanh-pho-buon-ma-thuot",
        status: "filled",
        ward: [
          { label: "Tân Lập", value: 24118, slug: "tan-lap", status: "empty" },
          { label: "Tân Hòa", value: 24121, slug: "tan-hoa", status: "empty" },
          { label: "Tân An", value: 24124, slug: "tan-an", status: "empty" },
          {
            label: "Thành Nhất",
            value: 24130,
            slug: "thanh-nhat",
            status: "empty",
          },
          {
            label: "Thành Công",
            value: 24133,
            slug: "thanh-cong",
            status: "filled",
          },
          { label: "Tân Lợi", value: 24136, slug: "tan-loi", status: "empty" },
          {
            label: "Tân Thành",
            value: 24142,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Tân Tiến",
            value: 24145,
            slug: "tan-tien",
            status: "empty",
          },
          { label: "Tự An", value: 24148, slug: "tu-an", status: "empty" },
          { label: "Ea Tam", value: 24151, slug: "ea-tam", status: "empty" },
          {
            label: "Khánh Xuân",
            value: 24154,
            slug: "khanh-xuan",
            status: "empty",
          },
          {
            label: "Hòa Thuận",
            value: 24157,
            slug: "hoa-thuan",
            status: "empty",
          },
          { label: "Cư ÊBur", value: 24160, slug: "cu-ebur", status: "empty" },
          { label: "Ea Tu", value: 24163, slug: "ea-tu", status: "empty" },
          {
            label: "Hòa Thắng",
            value: 24166,
            slug: "hoa-thang",
            status: "empty",
          },
          { label: "Ea Kao", value: 24169, slug: "ea-kao", status: "empty" },
          { label: "Hòa Phú", value: 24172, slug: "hoa-phu", status: "empty" },
          {
            label: "Hòa Khánh",
            value: 24175,
            slug: "hoa-khanh",
            status: "empty",
          },
          {
            label: "Hòa Xuân",
            value: 24178,
            slug: "hoa-xuan",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Buôn Hồ",
        value: 644,
        slug: "thi-xa-buon-ho",
        status: "empty",
        ward: [
          { label: "An Lạc", value: 24305, slug: "an-lac", status: "empty" },
          { label: "An Bình", value: 24308, slug: "an-binh", status: "empty" },
          {
            label: "Thiện An",
            value: 24311,
            slug: "thien-an",
            status: "empty",
          },
          {
            label: "Đạt Hiếu",
            value: 24318,
            slug: "dat-hieu",
            status: "empty",
          },
          {
            label: "Đoàn Kết",
            value: 24322,
            slug: "doan-ket",
            status: "empty",
          },
          {
            label: "Ea Drông",
            value: 24328,
            slug: "ea-drong",
            status: "empty",
          },
          {
            label: "Thống Nhất",
            value: 24331,
            slug: "thong-nhat",
            status: "empty",
          },
          {
            label: "Bình Tân",
            value: 24332,
            slug: "binh-tan",
            status: "empty",
          },
          { label: "Ea Siên", value: 24334, slug: "ea-sien", status: "empty" },
          {
            label: "Bình Thuận",
            value: 24337,
            slug: "binh-thuan",
            status: "empty",
          },
          { label: "Cư Bao", value: 24340, slug: "cu-bao", status: "empty" },
        ],
      },
      {
        label: "Huyện Ea H'leo",
        value: 645,
        slug: "huyen-ea-hleo",
        status: "empty",
        ward: [
          {
            label: "Ea Drăng",
            value: 24181,
            slug: "ea-drang",
            status: "empty",
          },
          {
            label: "Ea H'leo",
            value: 24184,
            slug: "ea-h'leo",
            status: "empty",
          },
          { label: "Ea Sol", value: 24187, slug: "ea-sol", status: "empty" },
          { label: "Ea Ral", value: 24190, slug: "ea-ral", status: "empty" },
          { label: "Ea Wy", value: 24193, slug: "ea-wy", status: "empty" },
          {
            label: "Cư A Mung",
            value: 24194,
            slug: "cu-a-mung",
            status: "empty",
          },
          { label: "Cư Mốt", value: 24196, slug: "cu-mot", status: "empty" },
          { label: "Ea Hiao", value: 24199, slug: "ea-hiao", status: "empty" },
          { label: "Ea Khal", value: 24202, slug: "ea-khal", status: "empty" },
          {
            label: "Dliê Yang",
            value: 24205,
            slug: "dlie-yang",
            status: "empty",
          },
          { label: "Ea Tir", value: 24207, slug: "ea-tir", status: "empty" },
          { label: "Ea Nam", value: 24208, slug: "ea-nam", status: "empty" },
        ],
      },
      {
        label: "Huyện Ea Súp",
        value: 646,
        slug: "huyen-ea-sup",
        status: "empty",
        ward: [
          { label: "Ea Súp", value: 24211, slug: "ea-sup", status: "empty" },
          { label: "Ia Lốp", value: 24214, slug: "ia-lop", status: "empty" },
          { label: "Ia JLơi", value: 24215, slug: "ia-jloi", status: "empty" },
          { label: "Ea Rốk", value: 24217, slug: "ea-rok", status: "empty" },
          {
            label: "Ya Tờ Mốt",
            value: 24220,
            slug: "ya-to-mot",
            status: "empty",
          },
          { label: "Ia RVê", value: 24221, slug: "ia-rve", status: "empty" },
          { label: "Ea Lê", value: 24223, slug: "ea-le", status: "empty" },
          {
            label: "Cư KBang",
            value: 24226,
            slug: "cu-kbang",
            status: "empty",
          },
          { label: "Ea Bung", value: 24229, slug: "ea-bung", status: "empty" },
          {
            label: "Cư M'Lan",
            value: 24232,
            slug: "cu-m'lan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Buôn Đôn",
        value: 647,
        slug: "huyen-buon-don",
        status: "empty",
        ward: [
          {
            label: "Krông Na",
            value: 24235,
            slug: "krong-na",
            status: "empty",
          },
          { label: "Ea Huar", value: 24238, slug: "ea-huar", status: "empty" },
          { label: "Ea Wer", value: 24241, slug: "ea-wer", status: "empty" },
          { label: "Tân Hoà", value: 24244, slug: "tan-hoa", status: "empty" },
          {
            label: "Cuôr KNia",
            value: 24247,
            slug: "cuor-knia",
            status: "empty",
          },
          { label: "Ea Bar", value: 24250, slug: "ea-bar", status: "empty" },
          { label: "Ea Nuôl", value: 24253, slug: "ea-nuol", status: "empty" },
        ],
      },
      {
        label: "Huyện Cư M'gar",
        value: 648,
        slug: "huyen-cu-mgar",
        status: "empty",
        ward: [
          { label: "Ea Pốk", value: 24256, slug: "ea-pok", status: "empty" },
          {
            label: "Quảng Phú",
            value: 24259,
            slug: "quang-phu",
            status: "empty",
          },
          {
            label: "Quảng Tiến",
            value: 24262,
            slug: "quang-tien",
            status: "empty",
          },
          { label: "Ea Kuêh", value: 24264, slug: "ea-kueh", status: "empty" },
          { label: "Ea Kiết", value: 24265, slug: "ea-kiet", status: "empty" },
          { label: "Ea Tar", value: 24268, slug: "ea-tar", status: "empty" },
          {
            label: "Cư Dliê M'nông",
            value: 24271,
            slug: "cu-dlie-m'nong",
            status: "empty",
          },
          {
            label: "Ea H'đinh",
            value: 24274,
            slug: "ea-h'dinh",
            status: "empty",
          },
          { label: "Ea Tul", value: 24277, slug: "ea-tul", status: "empty" },
          { label: "Ea KPam", value: 24280, slug: "ea-kpam", status: "empty" },
          {
            label: "Ea M'DRóh",
            value: 24283,
            slug: "ea-m'droh",
            status: "empty",
          },
          {
            label: "Quảng Hiệp",
            value: 24286,
            slug: "quang-hiep",
            status: "empty",
          },
          {
            label: "Cư M'gar",
            value: 24289,
            slug: "cu-m'gar",
            status: "empty",
          },
          {
            label: "Ea D'Rơng",
            value: 24292,
            slug: "ea-d'rong",
            status: "empty",
          },
          {
            label: "Ea M'nang",
            value: 24295,
            slug: "ea-m'nang",
            status: "empty",
          },
          { label: "Cư Suê", value: 24298, slug: "cu-sue", status: "empty" },
          {
            label: "Cuor Đăng",
            value: 24301,
            slug: "cuor-dang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Krông Búk",
        value: 649,
        slug: "huyen-krong-buk",
        status: "empty",
        ward: [
          { label: "Cư Né", value: 24307, slug: "cu-ne", status: "empty" },
          { label: "Chư KBô", value: 24310, slug: "chu-kbo", status: "empty" },
          { label: "Cư Pơng", value: 24313, slug: "cu-pong", status: "empty" },
          { label: "Ea Sin", value: 24314, slug: "ea-sin", status: "empty" },
          {
            label: "Pơng Drang",
            value: 24316,
            slug: "pong-drang",
            status: "empty",
          },
          { label: "Tân Lập", value: 24317, slug: "tan-lap", status: "empty" },
          { label: "Ea Ngai", value: 24319, slug: "ea-ngai", status: "empty" },
        ],
      },
      {
        label: "Huyện Krông Năng",
        value: 650,
        slug: "huyen-krong-nang",
        status: "empty",
        ward: [
          {
            label: "Krông Năng",
            value: 24343,
            slug: "krong-nang",
            status: "empty",
          },
          { label: "ĐLiê Ya", value: 24346, slug: "dlie-ya", status: "empty" },
          { label: "Ea Tóh", value: 24349, slug: "ea-toh", status: "empty" },
          { label: "Ea Tam", value: 24352, slug: "ea-tam", status: "empty" },
          { label: "Phú Lộc", value: 24355, slug: "phu-loc", status: "empty" },
          {
            label: "Tam Giang",
            value: 24358,
            slug: "tam-giang",
            status: "empty",
          },
          { label: "Ea Puk", value: 24359, slug: "ea-puk", status: "empty" },
          { label: "Ea Dăh", value: 24360, slug: "ea-dah", status: "empty" },
          { label: "Ea Hồ", value: 24361, slug: "ea-ho", status: "empty" },
          {
            label: "Phú Xuân",
            value: 24364,
            slug: "phu-xuan",
            status: "empty",
          },
          {
            label: "Cư Klông",
            value: 24367,
            slug: "cu-klong",
            status: "empty",
          },
          { label: "Ea Tân", value: 24370, slug: "ea-tan", status: "empty" },
        ],
      },
      {
        label: "Huyện Ea Kar",
        value: 651,
        slug: "huyen-ea-kar",
        status: "empty",
        ward: [
          { label: "Ea Kar", value: 24373, slug: "ea-kar", status: "empty" },
          { label: "Ea Knốp", value: 24376, slug: "ea-knop", status: "empty" },
          { label: "Ea Sô", value: 24379, slug: "ea-so", status: "empty" },
          { label: "Ea Sar", value: 24380, slug: "ea-sar", status: "empty" },
          {
            label: "Xuân Phú",
            value: 24382,
            slug: "xuan-phu",
            status: "empty",
          },
          { label: "Cư Huê", value: 24385, slug: "cu-hue", status: "empty" },
          { label: "Ea Tih", value: 24388, slug: "ea-tih", status: "empty" },
          { label: "Ea Đar", value: 24391, slug: "ea-dar", status: "empty" },
          { label: "Ea Kmút", value: 24394, slug: "ea-kmut", status: "empty" },
          { label: "Cư Ni", value: 24397, slug: "cu-ni", status: "empty" },
          { label: "Ea Păl", value: 24400, slug: "ea-pal", status: "empty" },
          {
            label: "Cư Prông",
            value: 24401,
            slug: "cu-prong",
            status: "empty",
          },
          { label: "Ea Ô", value: 24403, slug: "ea-o", status: "empty" },
          {
            label: "Cư ELang",
            value: 24404,
            slug: "cu-elang",
            status: "empty",
          },
          { label: "Cư Bông", value: 24406, slug: "cu-bong", status: "empty" },
          { label: "Cư Jang", value: 24409, slug: "cu-jang", status: "empty" },
        ],
      },
      {
        label: "Huyện M'Đrắk",
        value: 652,
        slug: "huyen-mdrak",
        status: "empty",
        ward: [
          {
            label: "Thị trấn M'Đrắk",
            value: 24412,
            slug: "thi-tran-m'drak",
            status: "empty",
          },
          { label: "Cư Prao", value: 24415, slug: "cu-prao", status: "empty" },
          { label: "Ea Pil", value: 24418, slug: "ea-pil", status: "empty" },
          { label: "Ea Lai", value: 24421, slug: "ea-lai", status: "empty" },
          {
            label: "Ea H'MLay",
            value: 24424,
            slug: "ea-h'mlay",
            status: "empty",
          },
          {
            label: "Krông Jing",
            value: 24427,
            slug: "krong-jing",
            status: "empty",
          },
          {
            label: "Ea M' Doal",
            value: 24430,
            slug: "ea-m'-doal",
            status: "empty",
          },
          {
            label: "Ea Riêng",
            value: 24433,
            slug: "ea-rieng",
            status: "empty",
          },
          { label: "Cư M'ta", value: 24436, slug: "cu-m'ta", status: "empty" },
          {
            label: "Cư K Róa",
            value: 24439,
            slug: "cu-k-roa",
            status: "empty",
          },
          { label: "Krông Á", value: 24442, slug: "krong-a", status: "empty" },
          { label: "Cư San", value: 24444, slug: "cu-san", status: "empty" },
          {
            label: "Ea Trang",
            value: 24445,
            slug: "ea-trang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Krông Bông",
        value: 653,
        slug: "huyen-krong-bong",
        status: "filled",
        ward: [
          {
            label: "Krông Kmar",
            value: 24448,
            slug: "krong-kmar",
            status: "filled",
          },
          {
            label: "Dang Kang",
            value: 24451,
            slug: "dang-kang",
            status: "empty",
          },
          { label: "Cư KTy", value: 24454, slug: "cu-kty", status: "empty" },
          {
            label: "Hòa Thành",
            value: 24457,
            slug: "hoa-thanh",
            status: "empty",
          },
          {
            label: "Hòa Phong",
            value: 24463,
            slug: "hoa-phong",
            status: "empty",
          },
          { label: "Hòa Lễ", value: 24466, slug: "hoa-le", status: "empty" },
          {
            label: "Yang Reh",
            value: 24469,
            slug: "yang-reh",
            status: "empty",
          },
          { label: "Ea Trul", value: 24472, slug: "ea-trul", status: "empty" },
          {
            label: "Khuê Ngọc Điền",
            value: 24475,
            slug: "khue-ngoc-dien",
            status: "empty",
          },
          { label: "Cư Pui", value: 24478, slug: "cu-pui", status: "empty" },
          { label: "Hòa Sơn", value: 24481, slug: "hoa-son", status: "empty" },
          { label: "Cư Drăm", value: 24484, slug: "cu-dram", status: "empty" },
          {
            label: "Yang Mao",
            value: 24487,
            slug: "yang-mao",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Krông Pắc",
        value: 654,
        slug: "huyen-krong-pac",
        status: "empty",
        ward: [
          {
            label: "Phước An",
            value: 24490,
            slug: "phuoc-an",
            status: "empty",
          },
          {
            label: "KRông Búk",
            value: 24493,
            slug: "krong-buk",
            status: "empty",
          },
          { label: "Ea Kly", value: 24496, slug: "ea-kly", status: "empty" },
          { label: "Ea Kênh", value: 24499, slug: "ea-kenh", status: "empty" },
          { label: "Ea Phê", value: 24502, slug: "ea-phe", status: "empty" },
          {
            label: "Ea KNuec",
            value: 24505,
            slug: "ea-knuec",
            status: "empty",
          },
          { label: "Ea Yông", value: 24508, slug: "ea-yong", status: "empty" },
          { label: "Hòa An", value: 24511, slug: "hoa-an", status: "empty" },
          {
            label: "Ea Kuăng",
            value: 24514,
            slug: "ea-kuang",
            status: "empty",
          },
          {
            label: "Hòa Đông",
            value: 24517,
            slug: "hoa-dong",
            status: "empty",
          },
          { label: "Ea Hiu", value: 24520, slug: "ea-hiu", status: "empty" },
          {
            label: "Hòa Tiến",
            value: 24523,
            slug: "hoa-tien",
            status: "empty",
          },
          {
            label: "Tân Tiến",
            value: 24526,
            slug: "tan-tien",
            status: "empty",
          },
          { label: "Vụ Bổn", value: 24529, slug: "vu-bon", status: "empty" },
          { label: "Ea Uy", value: 24532, slug: "ea-uy", status: "empty" },
          {
            label: "Ea Yiêng",
            value: 24535,
            slug: "ea-yieng",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Krông A Na",
        value: 655,
        slug: "huyen-krong-a-na",
        status: "filled",
        ward: [
          {
            label: "Buôn Trấp",
            value: 24538,
            slug: "buon-trap",
            status: "filled",
          },
          {
            label: "Dray Sáp",
            value: 24556,
            slug: "dray-sap",
            status: "empty",
          },
          { label: "Ea Na", value: 24559, slug: "ea-na", status: "empty" },
          { label: "Ea Bông", value: 24565, slug: "ea-bong", status: "empty" },
          {
            label: "Băng A Drênh",
            value: 24568,
            slug: "bang-a-drenh",
            status: "empty",
          },
          {
            label: "Dur KMăl",
            value: 24571,
            slug: "dur-kmal",
            status: "empty",
          },
          {
            label: "Bình Hòa",
            value: 24574,
            slug: "binh-hoa",
            status: "empty",
          },
          {
            label: "Quảng Điền",
            value: 24577,
            slug: "quang-dien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lắk",
        value: 656,
        slug: "huyen-lak",
        status: "empty",
        ward: [
          {
            label: "Liên Sơn",
            value: 24580,
            slug: "lien-son",
            status: "empty",
          },
          {
            label: "Yang Tao",
            value: 24583,
            slug: "yang-tao",
            status: "empty",
          },
          {
            label: "Bông Krang",
            value: 24586,
            slug: "bong-krang",
            status: "empty",
          },
          {
            label: "Đắk Liêng",
            value: 24589,
            slug: "dak-lieng",
            status: "empty",
          },
          {
            label: "Buôn Triết",
            value: 24592,
            slug: "buon-triet",
            status: "empty",
          },
          {
            label: "Buôn Tría",
            value: 24595,
            slug: "buon-tria",
            status: "empty",
          },
          {
            label: "Đắk Phơi",
            value: 24598,
            slug: "dak-phoi",
            status: "empty",
          },
          { label: "Đắk Nuê", value: 24601, slug: "dak-nue", status: "empty" },
          {
            label: "Krông Nô",
            value: 24604,
            slug: "krong-no",
            status: "empty",
          },
          { label: "Nam Ka", value: 24607, slug: "nam-ka", status: "empty" },
          {
            label: "Ea R'Bin",
            value: 24610,
            slug: "ea-r'bin",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cư Kuin",
        value: 657,
        slug: "huyen-cu-kuin",
        status: "empty",
        ward: [
          { label: "Ea Ning", value: 24540, slug: "ea-ning", status: "empty" },
          { label: "Cư Ê Wi", value: 24541, slug: "cu-e-wi", status: "empty" },
          { label: "Ea Ktur", value: 24544, slug: "ea-ktur", status: "empty" },
          { label: "Ea Tiêu", value: 24547, slug: "ea-tieu", status: "empty" },
          { label: "Ea BHốk", value: 24550, slug: "ea-bhok", status: "empty" },
          { label: "Ea Hu", value: 24553, slug: "ea-hu", status: "empty" },
          {
            label: "Dray Bhăng",
            value: 24561,
            slug: "dray-bhang",
            status: "empty",
          },
          {
            label: "Hòa Hiệp",
            value: 24562,
            slug: "hoa-hiep",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Đắk Nông",
    value: 67,
    slug: "dak-nong",
    status: "empty",
    district: [
      {
        label: "Thành phố Gia Nghĩa",
        value: 660,
        slug: "thanh-pho-gia-nghia",
        status: "empty",
        ward: [
          {
            label: "Nghĩa Đức",
            value: 24611,
            slug: "nghia-duc",
            status: "empty",
          },
          {
            label: "Nghĩa Thành",
            value: 24612,
            slug: "nghia-thanh",
            status: "empty",
          },
          {
            label: "Nghĩa Phú",
            value: 24614,
            slug: "nghia-phu",
            status: "empty",
          },
          {
            label: "Nghĩa Tân",
            value: 24615,
            slug: "nghia-tan",
            status: "empty",
          },
          {
            label: "Nghĩa Trung",
            value: 24617,
            slug: "nghia-trung",
            status: "empty",
          },
          {
            label: "Đăk R'Moan",
            value: 24618,
            slug: "dak-r'moan",
            status: "empty",
          },
          {
            label: "Quảng Thành",
            value: 24619,
            slug: "quang-thanh",
            status: "empty",
          },
          { label: "Đắk Nia", value: 24628, slug: "dak-nia", status: "empty" },
        ],
      },
      {
        label: "Huyện Đăk Glong",
        value: 661,
        slug: "huyen-dak-glong",
        status: "empty",
        ward: [
          {
            label: "Quảng Sơn",
            value: 24616,
            slug: "quang-son",
            status: "empty",
          },
          {
            label: "Quảng Hoà",
            value: 24620,
            slug: "quang-hoa",
            status: "empty",
          },
          { label: "Đắk Ha", value: 24622, slug: "dak-ha", status: "empty" },
          {
            label: "Đắk R'Măng",
            value: 24625,
            slug: "dak-r'mang",
            status: "empty",
          },
          {
            label: "Quảng Khê",
            value: 24631,
            slug: "quang-khe",
            status: "empty",
          },
          {
            label: "Đắk Plao",
            value: 24634,
            slug: "dak-plao",
            status: "empty",
          },
          { label: "Đắk Som", value: 24637, slug: "dak-som", status: "empty" },
        ],
      },
      {
        label: "Huyện Cư Jút",
        value: 662,
        slug: "huyen-cu-jut",
        status: "empty",
        ward: [
          {
            label: "Ea T'Ling",
            value: 24640,
            slug: "ea-t'ling",
            status: "empty",
          },
          { label: "Đắk Wil", value: 24643, slug: "dak-wil", status: "empty" },
          { label: "Ea Pô", value: 24646, slug: "ea-po", status: "empty" },
          {
            label: "Nam Dong",
            value: 24649,
            slug: "nam-dong",
            status: "empty",
          },
          {
            label: "Đắk DRông",
            value: 24652,
            slug: "dak-drong",
            status: "empty",
          },
          {
            label: "Tâm Thắng",
            value: 24655,
            slug: "tam-thang",
            status: "empty",
          },
          { label: "Cư Knia", value: 24658, slug: "cu-knia", status: "empty" },
          {
            label: "Trúc Sơn",
            value: 24661,
            slug: "truc-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đắk Mil",
        value: 663,
        slug: "huyen-dak-mil",
        status: "empty",
        ward: [
          { label: "Đắk Mil", value: 24664, slug: "dak-mil", status: "empty" },
          { label: "Đắk Lao", value: 24667, slug: "dak-lao", status: "empty" },
          {
            label: "Đắk R'La",
            value: 24670,
            slug: "dak-r'la",
            status: "empty",
          },
          { label: "Đắk Gằn", value: 24673, slug: "dak-gan", status: "empty" },
          {
            label: "Đức Mạnh",
            value: 24676,
            slug: "duc-manh",
            status: "empty",
          },
          {
            label: "Đắk N'Drót",
            value: 24677,
            slug: "dak-n'drot",
            status: "empty",
          },
          {
            label: "Long Sơn",
            value: 24678,
            slug: "long-son",
            status: "empty",
          },
          { label: "Đắk Sắk", value: 24679, slug: "dak-sak", status: "empty" },
          {
            label: "Thuận An",
            value: 24682,
            slug: "thuan-an",
            status: "empty",
          },
          {
            label: "Đức Minh",
            value: 24685,
            slug: "duc-minh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Krông Nô",
        value: 664,
        slug: "huyen-krong-no",
        status: "empty",
        ward: [
          { label: "Đắk Mâm", value: 24688, slug: "dak-mam", status: "empty" },
          { label: "Đắk Sôr", value: 24691, slug: "dak-sor", status: "empty" },
          {
            label: "Nam Xuân",
            value: 24692,
            slug: "nam-xuan",
            status: "empty",
          },
          {
            label: "Buôn Choah",
            value: 24694,
            slug: "buon-choah",
            status: "empty",
          },
          { label: "Nam Đà", value: 24697, slug: "nam-da", status: "empty" },
          {
            label: "Tân Thành",
            value: 24699,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Đắk Drô", value: 24700, slug: "dak-dro", status: "empty" },
          {
            label: "Nâm Nung",
            value: 24703,
            slug: "nam-nung",
            status: "empty",
          },
          {
            label: "Đức Xuyên",
            value: 24706,
            slug: "duc-xuyen",
            status: "empty",
          },
          {
            label: "Đắk Nang",
            value: 24709,
            slug: "dak-nang",
            status: "empty",
          },
          {
            label: "Quảng Phú",
            value: 24712,
            slug: "quang-phu",
            status: "empty",
          },
          {
            label: "Nâm N'Đir",
            value: 24715,
            slug: "nam-n'dir",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đắk Song",
        value: 665,
        slug: "huyen-dak-song",
        status: "empty",
        ward: [
          { label: "Đức An", value: 24717, slug: "duc-an", status: "empty" },
          { label: "Đắk Môl", value: 24718, slug: "dak-mol", status: "empty" },
          { label: "Đắk Hòa", value: 24719, slug: "dak-hoa", status: "empty" },
          {
            label: "Nam Bình",
            value: 24721,
            slug: "nam-binh",
            status: "empty",
          },
          {
            label: "Thuận Hà",
            value: 24722,
            slug: "thuan-ha",
            status: "empty",
          },
          {
            label: "Thuận Hạnh",
            value: 24724,
            slug: "thuan-hanh",
            status: "empty",
          },
          {
            label: "Đắk N'Dung",
            value: 24727,
            slug: "dak-n'dung",
            status: "empty",
          },
          {
            label: "Nâm N'Jang",
            value: 24728,
            slug: "nam-n'jang",
            status: "empty",
          },
          {
            label: "Trường Xuân",
            value: 24730,
            slug: "truong-xuan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đắk R'Lấp",
        value: 666,
        slug: "huyen-dak-rlap",
        status: "empty",
        ward: [
          {
            label: "Kiến Đức",
            value: 24733,
            slug: "kien-duc",
            status: "empty",
          },
          {
            label: "Quảng Tín",
            value: 24745,
            slug: "quang-tin",
            status: "empty",
          },
          { label: "Đắk Wer", value: 24750, slug: "dak-wer", status: "empty" },
          { label: "Nhân Cơ", value: 24751, slug: "nhan-co", status: "empty" },
          {
            label: "Kiến Thành",
            value: 24754,
            slug: "kien-thanh",
            status: "empty",
          },
          {
            label: "Nghĩa Thắng",
            value: 24756,
            slug: "nghia-thang",
            status: "empty",
          },
          {
            label: "Đạo Nghĩa",
            value: 24757,
            slug: "dao-nghia",
            status: "empty",
          },
          { label: "Đắk Sin", value: 24760, slug: "dak-sin", status: "empty" },
          {
            label: "Hưng Bình",
            value: 24761,
            slug: "hung-binh",
            status: "empty",
          },
          { label: "Đắk Ru", value: 24763, slug: "dak-ru", status: "empty" },
          {
            label: "Nhân Đạo",
            value: 24766,
            slug: "nhan-dao",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tuy Đức",
        value: 667,
        slug: "huyen-tuy-duc",
        status: "empty",
        ward: [
          {
            label: "Quảng Trực",
            value: 24736,
            slug: "quang-truc",
            status: "empty",
          },
          {
            label: "Đắk Búk So",
            value: 24739,
            slug: "dak-buk-so",
            status: "empty",
          },
          {
            label: "Quảng Tâm",
            value: 24740,
            slug: "quang-tam",
            status: "empty",
          },
          {
            label: "Đắk R'Tíh",
            value: 24742,
            slug: "dak-r'tih",
            status: "empty",
          },
          { label: "Đắk Ngo", value: 24746, slug: "dak-ngo", status: "empty" },
          {
            label: "Quảng Tân",
            value: 24748,
            slug: "quang-tan",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Lâm Đồng",
    value: 68,
    slug: "lam-dong",
    status: "filled",
    district: [
      {
        label: "Thành phố Đà Lạt",
        value: 672,
        slug: "thanh-pho-da-lat",
        status: "empty",
        ward: [
          {
            label: "Phường 7",
            value: 24769,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Phường 8",
            value: 24772,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Phường 12",
            value: 24775,
            slug: "phuong-12",
            status: "empty",
          },
          {
            label: "Phường 9",
            value: 24778,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 24781,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 24784,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 6",
            value: 24787,
            slug: "phuong-6",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 24790,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 24793,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 10",
            value: 24796,
            slug: "phuong-10",
            status: "empty",
          },
          {
            label: "Phường 11",
            value: 24799,
            slug: "phuong-11",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 24802,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Xuân Thọ",
            value: 24805,
            slug: "xuan-tho",
            status: "empty",
          },
          { label: "Tà Nung", value: 24808, slug: "ta-nung", status: "empty" },
          {
            label: "Trạm Hành",
            value: 24810,
            slug: "tram-hanh",
            status: "empty",
          },
          {
            label: "Xuân Trường",
            value: 24811,
            slug: "xuan-truong",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Bảo Lộc",
        value: 673,
        slug: "thanh-pho-bao-loc",
        status: "filled",
        ward: [
          {
            label: "Lộc Phát",
            value: 24814,
            slug: "loc-phat",
            status: "empty",
          },
          {
            label: "Lộc Tiến",
            value: 24817,
            slug: "loc-tien",
            status: "filled",
          },
          {
            label: "Phường 2",
            value: 24820,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 24823,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường B'lao",
            value: 24826,
            slug: "phuong-b'lao",
            status: "empty",
          },
          { label: "Lộc Sơn", value: 24829, slug: "loc-son", status: "empty" },
          { label: "Đạm Bri", value: 24832, slug: "dam-bri", status: "empty" },
          {
            label: "Lộc Thanh",
            value: 24835,
            slug: "loc-thanh",
            status: "empty",
          },
          { label: "Lộc Nga", value: 24838, slug: "loc-nga", status: "empty" },
          {
            label: "Lộc Châu",
            value: 24841,
            slug: "loc-chau",
            status: "empty",
          },
          { label: "Đại Lào", value: 24844, slug: "dai-lao", status: "empty" },
        ],
      },
      {
        label: "Huyện Đam Rông",
        value: 674,
        slug: "huyen-dam-rong",
        status: "empty",
        ward: [
          { label: "Đạ Tông", value: 24853, slug: "da-tong", status: "empty" },
          { label: "Đạ Long", value: 24856, slug: "da-long", status: "empty" },
          {
            label: "Đạ M' Rong",
            value: 24859,
            slug: "da-m'-rong",
            status: "empty",
          },
          {
            label: "Liêng Srônh",
            value: 24874,
            slug: "lieng-sronh",
            status: "empty",
          },
          { label: "Đạ Rsal", value: 24875, slug: "da-rsal", status: "empty" },
          { label: "Rô Men", value: 24877, slug: "ro-men", status: "empty" },
          {
            label: "Phi Liêng",
            value: 24886,
            slug: "phi-lieng",
            status: "empty",
          },
          {
            label: "Đạ K' Nàng",
            value: 24889,
            slug: "da-k'-nang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lạc Dương",
        value: 675,
        slug: "huyen-lac-duong",
        status: "empty",
        ward: [
          {
            label: "Lạc Dương",
            value: 24846,
            slug: "lac-duong",
            status: "empty",
          },
          {
            label: "Đạ Chais",
            value: 24847,
            slug: "da-chais",
            status: "empty",
          },
          { label: "Đạ Nhim", value: 24848, slug: "da-nhim", status: "empty" },
          {
            label: "Đưng KNớ",
            value: 24850,
            slug: "dung-kno",
            status: "empty",
          },
          { label: "Xã Lát", value: 24862, slug: "xa-lat", status: "empty" },
          { label: "Đạ Sar", value: 24865, slug: "da-sar", status: "empty" },
        ],
      },
      {
        label: "Huyện Lâm Hà",
        value: 676,
        slug: "huyen-lam-ha",
        status: "empty",
        ward: [
          { label: "Nam Ban", value: 24868, slug: "nam-ban", status: "empty" },
          {
            label: "Đinh Văn",
            value: 24871,
            slug: "dinh-van",
            status: "empty",
          },
          { label: "Phú Sơn", value: 24880, slug: "phu-son", status: "empty" },
          { label: "Phi Tô", value: 24883, slug: "phi-to", status: "empty" },
          { label: "Mê Linh", value: 24892, slug: "me-linh", status: "empty" },
          { label: "Đạ Đờn", value: 24895, slug: "da-don", status: "empty" },
          {
            label: "Phúc Thọ",
            value: 24898,
            slug: "phuc-tho",
            status: "empty",
          },
          {
            label: "Đông Thanh",
            value: 24901,
            slug: "dong-thanh",
            status: "empty",
          },
          { label: "Gia Lâm", value: 24904, slug: "gia-lam", status: "empty" },
          {
            label: "Tân Thanh",
            value: 24907,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Tân Văn", value: 24910, slug: "tan-van", status: "empty" },
          {
            label: "Hoài Đức",
            value: 24913,
            slug: "hoai-duc",
            status: "empty",
          },
          { label: "Tân Hà", value: 24916, slug: "tan-ha", status: "empty" },
          { label: "Liên Hà", value: 24919, slug: "lien-ha", status: "empty" },
          {
            label: "Đan Phượng",
            value: 24922,
            slug: "dan-phuong",
            status: "empty",
          },
          { label: "Nam Hà", value: 24925, slug: "nam-ha", status: "empty" },
        ],
      },
      {
        label: "Huyện Đơn Dương",
        value: 677,
        slug: "huyen-don-duong",
        status: "empty",
        ward: [
          {
            label: "Thị trấn D'Ran",
            value: 24928,
            slug: "thi-tran-d'ran",
            status: "empty",
          },
          {
            label: "Thạnh Mỹ",
            value: 24931,
            slug: "thanh-my",
            status: "empty",
          },
          {
            label: "Lạc Xuân",
            value: 24934,
            slug: "lac-xuan",
            status: "empty",
          },
          { label: "Đạ Ròn", value: 24937, slug: "da-ron", status: "empty" },
          { label: "Lạc Lâm", value: 24940, slug: "lac-lam", status: "empty" },
          { label: "Ka Đô", value: 24943, slug: "ka-do", status: "empty" },
          { label: "Ka Đơn", value: 24949, slug: "ka-don", status: "empty" },
          { label: "Tu Tra", value: 24952, slug: "tu-tra", status: "empty" },
          {
            label: "Quảng Lập",
            value: 24955,
            slug: "quang-lap",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đức Trọng",
        value: 678,
        slug: "huyen-duc-trong",
        status: "empty",
        ward: [
          {
            label: "Liên Nghĩa",
            value: 24958,
            slug: "lien-nghia",
            status: "empty",
          },
          { label: "Hiệp An", value: 24961, slug: "hiep-an", status: "empty" },
          {
            label: "Liên Hiệp",
            value: 24964,
            slug: "lien-hiep",
            status: "empty",
          },
          {
            label: "Hiệp Thạnh",
            value: 24967,
            slug: "hiep-thanh",
            status: "empty",
          },
          {
            label: "Bình Thạnh",
            value: 24970,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "N'Thol Hạ",
            value: 24973,
            slug: "n'thol-ha",
            status: "empty",
          },
          { label: "Tân Hội", value: 24976, slug: "tan-hoi", status: "empty" },
          {
            label: "Tân Thành",
            value: 24979,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Phú Hội", value: 24982, slug: "phu-hoi", status: "empty" },
          {
            label: "Ninh Gia",
            value: 24985,
            slug: "ninh-gia",
            status: "empty",
          },
          { label: "Tà Năng", value: 24988, slug: "ta-nang", status: "empty" },
          { label: "Đa Quyn", value: 24989, slug: "da-quyn", status: "empty" },
          { label: "Tà Hine", value: 24991, slug: "ta-hine", status: "empty" },
          { label: "Đà Loan", value: 24994, slug: "da-loan", status: "empty" },
          {
            label: "Ninh Loan",
            value: 24997,
            slug: "ninh-loan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Di Linh",
        value: 679,
        slug: "huyen-di-linh",
        status: "empty",
        ward: [
          { label: "Di Linh", value: 25000, slug: "di-linh", status: "empty" },
          {
            label: "Đinh Trang Thượng",
            value: 25003,
            slug: "dinh-trang-thuong",
            status: "empty",
          },
          {
            label: "Tân Thượng",
            value: 25006,
            slug: "tan-thuong",
            status: "empty",
          },
          { label: "Tân Lâm", value: 25007, slug: "tan-lam", status: "empty" },
          {
            label: "Tân Châu",
            value: 25009,
            slug: "tan-chau",
            status: "empty",
          },
          {
            label: "Tân Nghĩa",
            value: 25012,
            slug: "tan-nghia",
            status: "empty",
          },
          {
            label: "Gia Hiệp",
            value: 25015,
            slug: "gia-hiep",
            status: "empty",
          },
          {
            label: "Đinh Lạc",
            value: 25018,
            slug: "dinh-lac",
            status: "empty",
          },
          { label: "Tam Bố", value: 25021, slug: "tam-bo", status: "empty" },
          {
            label: "Đinh Trang Hòa",
            value: 25024,
            slug: "dinh-trang-hoa",
            status: "empty",
          },
          {
            label: "Liên Đầm",
            value: 25027,
            slug: "lien-dam",
            status: "empty",
          },
          { label: "Gung Ré", value: 25030, slug: "gung-re", status: "empty" },
          {
            label: "Bảo Thuận",
            value: 25033,
            slug: "bao-thuan",
            status: "empty",
          },
          {
            label: "Hòa Ninh",
            value: 25036,
            slug: "hoa-ninh",
            status: "empty",
          },
          {
            label: "Hòa Trung",
            value: 25039,
            slug: "hoa-trung",
            status: "empty",
          },
          { label: "Hòa Nam", value: 25042, slug: "hoa-nam", status: "empty" },
          { label: "Hòa Bắc", value: 25045, slug: "hoa-bac", status: "empty" },
          {
            label: "Sơn Điền",
            value: 25048,
            slug: "son-dien",
            status: "empty",
          },
          { label: "Gia Bắc", value: 25051, slug: "gia-bac", status: "empty" },
        ],
      },
      {
        label: "Huyện Bảo Lâm",
        value: 680,
        slug: "huyen-bao-lam",
        status: "empty",
        ward: [
          {
            label: "Lộc Thắng",
            value: 25054,
            slug: "loc-thang",
            status: "empty",
          },
          { label: "Lộc Bảo", value: 25057, slug: "loc-bao", status: "empty" },
          { label: "Lộc Lâm", value: 25060, slug: "loc-lam", status: "empty" },
          { label: "Lộc Phú", value: 25063, slug: "loc-phu", status: "empty" },
          { label: "Lộc Bắc", value: 25066, slug: "loc-bac", status: "empty" },
          { label: "B' Lá", value: 25069, slug: "b'-la", status: "empty" },
          {
            label: "Lộc Ngãi",
            value: 25072,
            slug: "loc-ngai",
            status: "empty",
          },
          {
            label: "Lộc Quảng",
            value: 25075,
            slug: "loc-quang",
            status: "empty",
          },
          { label: "Lộc Tân", value: 25078, slug: "loc-tan", status: "empty" },
          { label: "Lộc Đức", value: 25081, slug: "loc-duc", status: "empty" },
          { label: "Lộc An", value: 25084, slug: "loc-an", status: "empty" },
          { label: "Tân Lạc", value: 25087, slug: "tan-lac", status: "empty" },
          {
            label: "Lộc Thành",
            value: 25090,
            slug: "loc-thanh",
            status: "empty",
          },
          { label: "Lộc Nam", value: 25093, slug: "loc-nam", status: "empty" },
        ],
      },
      {
        label: "Huyện Đạ Huoai",
        value: 681,
        slug: "huyen-da-huoai",
        status: "empty",
        ward: [
          {
            label: "Đạ Huoai",
            value: 101500,
            slug: "da-huoai",
            status: "empty",
          },
          {
            label: "Đạ K'Nàng",
            value: 101501,
            slug: "da-knang",
            status: "empty",
          },
          {
            label: "Đạ M'Rông",
            value: 101502,
            slug: "da-mrong",
            status: "empty",
          },
          { label: "Đạ Tẻh", value: 101503, slug: "da-teh", status: "empty" },
          {
            label: "Ma Đa Guôi",
            value: 101504,
            slug: "ma-da-guoi",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đạ Tẻh",
        value: 682,
        slug: "huyen-da-teh",
        status: "empty",
        ward: [
          { label: "Đạ M'ri", value: 25096, slug: "da-m'ri", status: "empty" },
          {
            label: "Ma Đa Guôi",
            value: 25099,
            slug: "ma-da-guoi",
            status: "empty",
          },
          { label: "Đạ M'ri", value: 25102, slug: "da-m'ri", status: "empty" },
          { label: "Hà Lâm", value: 25105, slug: "ha-lam", status: "empty" },
          { label: "Đạ Oai", value: 25111, slug: "da-oai", status: "empty" },
          { label: "Bà Gia", value: 25114, slug: "ba-gia", status: "empty" },
          {
            label: "Ma Đa Guôi",
            value: 25117,
            slug: "ma-da-guoi",
            status: "empty",
          },
          { label: "Đạ Tẻh", value: 25126, slug: "da-teh", status: "empty" },
          { label: "An Nhơn", value: 25129, slug: "an-nhon", status: "empty" },
          {
            label: "Quốc Oai",
            value: 25132,
            slug: "quoc-oai",
            status: "empty",
          },
          { label: "Mỹ Đức", value: 25135, slug: "my-duc", status: "empty" },
          {
            label: "Quảng Trị",
            value: 25138,
            slug: "quang-tri",
            status: "empty",
          },
          { label: "Đạ Lây", value: 25141, slug: "da-lay", status: "empty" },
          { label: "Đạ Kho", value: 25153, slug: "da-kho", status: "empty" },
          { label: "Đạ Pal", value: 25156, slug: "da-pal", status: "empty" },
          {
            label: "Cát Tiên",
            value: 25159,
            slug: "cat-tien",
            status: "empty",
          },
          {
            label: "Tiên Hoàng",
            value: 25162,
            slug: "tien-hoang",
            status: "empty",
          },
          {
            label: "Phước Cát 2",
            value: 25165,
            slug: "phuoc-cat-2",
            status: "empty",
          },
          {
            label: "Gia Viễn",
            value: 25168,
            slug: "gia-vien",
            status: "empty",
          },
          {
            label: "Nam Ninh",
            value: 25171,
            slug: "nam-ninh",
            status: "empty",
          },
          { label: "Mỹ Lâm", value: 25174, slug: "my-lam", status: "empty" },
          {
            label: "Tư Nghĩa",
            value: 25177,
            slug: "tu-nghia",
            status: "empty",
          },
          {
            label: "Phước Cát",
            value: 25180,
            slug: "phuoc-cat",
            status: "empty",
          },
          { label: "Đức Phổ", value: 25183, slug: "duc-pho", status: "empty" },
          { label: "Phù Mỹ", value: 25186, slug: "phu-my", status: "empty" },
          {
            label: "Quảng Ngãi",
            value: 25189,
            slug: "quang-ngai",
            status: "empty",
          },
          {
            label: "Đồng Nai Thượng",
            value: 25192,
            slug: "dong-nai-thuong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cát Tiên",
        value: 683,
        slug: "huyen-cat-tien",
        status: "empty",
        ward: [
          {
            label: "Cát Tiên",
            value: 101600,
            slug: "cat-tien",
            status: "empty",
          },
          {
            label: "Gia Viễn",
            value: 101601,
            slug: "gia-vien",
            status: "empty",
          },
          { label: "Phú An", value: 101602, slug: "phu-an", status: "empty" },
          {
            label: "Phước Cát 1",
            value: 101603,
            slug: "phuoc-cat-1",
            status: "empty",
          },
          {
            label: "Phước Cát 2",
            value: 101604,
            slug: "phuoc-cat-2",
            status: "empty",
          },
          {
            label: "Quảng Ngãi",
            value: 101605,
            slug: "quang-ngai",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Bình Phước",
    value: 70,
    slug: "binh-phuoc",
    status: "empty",
    district: [
      {
        label: "Thị xã Phước Long",
        value: 688,
        slug: "thi-xa-phuoc-long",
        status: "empty",
        ward: [
          { label: "Thác Mơ", value: 25216, slug: "thac-mo", status: "empty" },
          {
            label: "Long Thủy",
            value: 25217,
            slug: "long-thuy",
            status: "empty",
          },
          {
            label: "Phước Bình",
            value: 25219,
            slug: "phuoc-binh",
            status: "empty",
          },
          {
            label: "Long Phước",
            value: 25220,
            slug: "long-phuoc",
            status: "empty",
          },
          {
            label: "Sơn Giang",
            value: 25237,
            slug: "son-giang",
            status: "empty",
          },
          {
            label: "Long Giang",
            value: 25245,
            slug: "long-giang",
            status: "empty",
          },
          {
            label: "Phước Tín",
            value: 25249,
            slug: "phuoc-tin",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Đồng Xoài",
        value: 689,
        slug: "thanh-pho-dong-xoai",
        status: "empty",
        ward: [
          { label: "Tân Phú", value: 25195, slug: "tan-phu", status: "empty" },
          {
            label: "Tân Đồng",
            value: 25198,
            slug: "tan-dong",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 25201,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Tân Xuân",
            value: 25204,
            slug: "tan-xuan",
            status: "empty",
          },
          {
            label: "Tân Thiện",
            value: 25205,
            slug: "tan-thien",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 25207,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Tiến Thành",
            value: 25210,
            slug: "tien-thanh",
            status: "empty",
          },
          {
            label: "Tiến Hưng",
            value: 25213,
            slug: "tien-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Bình Long",
        value: 690,
        slug: "thi-xa-binh-long",
        status: "empty",
        ward: [
          {
            label: "Hưng Chiến",
            value: 25320,
            slug: "hung-chien",
            status: "empty",
          },
          { label: "An Lộc", value: 25324, slug: "an-loc", status: "empty" },
          {
            label: "Phú Thịnh",
            value: 25325,
            slug: "phu-thinh",
            status: "empty",
          },
          { label: "Phú Đức", value: 25326, slug: "phu-duc", status: "empty" },
          {
            label: "Thanh Lương",
            value: 25333,
            slug: "thanh-luong",
            status: "empty",
          },
          {
            label: "Thanh Phú",
            value: 25336,
            slug: "thanh-phu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bù Gia Mập",
        value: 691,
        slug: "huyen-bu-gia-map",
        status: "empty",
        ward: [
          {
            label: "Bù Gia Mập",
            value: 25222,
            slug: "bu-gia-map",
            status: "empty",
          },
          { label: "Đak Ơ", value: 25225, slug: "dak-o", status: "empty" },
          {
            label: "Đức Hạnh",
            value: 25228,
            slug: "duc-hanh",
            status: "empty",
          },
          { label: "Phú Văn", value: 25229, slug: "phu-van", status: "empty" },
          { label: "Đa Kia", value: 25231, slug: "da-kia", status: "empty" },
          {
            label: "Phước Minh",
            value: 25232,
            slug: "phuoc-minh",
            status: "empty",
          },
          {
            label: "Bình Thắng",
            value: 25234,
            slug: "binh-thang",
            status: "empty",
          },
          {
            label: "Phú Nghĩa",
            value: 25267,
            slug: "phu-nghia",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lộc Ninh",
        value: 692,
        slug: "huyen-loc-ninh",
        status: "empty",
        ward: [
          {
            label: "Lộc Ninh",
            value: 25270,
            slug: "loc-ninh",
            status: "empty",
          },
          { label: "Lộc Hòa", value: 25273, slug: "loc-hoa", status: "empty" },
          { label: "Lộc An", value: 25276, slug: "loc-an", status: "empty" },
          { label: "Lộc Tấn", value: 25279, slug: "loc-tan", status: "empty" },
          {
            label: "Lộc Thạnh",
            value: 25280,
            slug: "loc-thanh",
            status: "empty",
          },
          {
            label: "Lộc Hiệp",
            value: 25282,
            slug: "loc-hiep",
            status: "empty",
          },
          {
            label: "Lộc Thiện",
            value: 25285,
            slug: "loc-thien",
            status: "empty",
          },
          {
            label: "Lộc Thuận",
            value: 25288,
            slug: "loc-thuan",
            status: "empty",
          },
          {
            label: "Lộc Quang",
            value: 25291,
            slug: "loc-quang",
            status: "empty",
          },
          { label: "Lộc Phú", value: 25292, slug: "loc-phu", status: "empty" },
          {
            label: "Lộc Thành",
            value: 25294,
            slug: "loc-thanh",
            status: "empty",
          },
          {
            label: "Lộc Thái",
            value: 25297,
            slug: "loc-thai",
            status: "empty",
          },
          {
            label: "Lộc Điền",
            value: 25300,
            slug: "loc-dien",
            status: "empty",
          },
          {
            label: "Lộc Hưng",
            value: 25303,
            slug: "loc-hung",
            status: "empty",
          },
          {
            label: "Lộc Thịnh",
            value: 25305,
            slug: "loc-thinh",
            status: "empty",
          },
          {
            label: "Lộc Khánh",
            value: 25306,
            slug: "loc-khanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bù Đốp",
        value: 693,
        slug: "huyen-bu-dop",
        status: "empty",
        ward: [
          {
            label: "Thanh Bình",
            value: 25308,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Hưng Phước",
            value: 25309,
            slug: "hung-phuoc",
            status: "empty",
          },
          {
            label: "Phước Thiện",
            value: 25310,
            slug: "phuoc-thien",
            status: "empty",
          },
          {
            label: "Thiện Hưng",
            value: 25312,
            slug: "thien-hung",
            status: "empty",
          },
          {
            label: "Thanh Hòa",
            value: 25315,
            slug: "thanh-hoa",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 25318,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Tân Tiến",
            value: 25321,
            slug: "tan-tien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hớn Quản",
        value: 694,
        slug: "huyen-hon-quan",
        status: "empty",
        ward: [
          {
            label: "Thanh An",
            value: 25327,
            slug: "thanh-an",
            status: "empty",
          },
          {
            label: "An Khương",
            value: 25330,
            slug: "an-khuong",
            status: "empty",
          },
          { label: "An Phú", value: 25339, slug: "an-phu", status: "empty" },
          { label: "Tân Lợi", value: 25342, slug: "tan-loi", status: "empty" },
          {
            label: "Tân Hưng",
            value: 25345,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "Minh Đức",
            value: 25348,
            slug: "minh-duc",
            status: "empty",
          },
          {
            label: "Minh Tâm",
            value: 25349,
            slug: "minh-tam",
            status: "empty",
          },
          {
            label: "Phước An",
            value: 25351,
            slug: "phuoc-an",
            status: "empty",
          },
          {
            label: "Thanh Bình",
            value: 25354,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Tân Khai",
            value: 25357,
            slug: "tan-khai",
            status: "empty",
          },
          { label: "Đồng Nơ", value: 25360, slug: "dong-no", status: "empty" },
          {
            label: "Tân Hiệp",
            value: 25361,
            slug: "tan-hiep",
            status: "empty",
          },
          {
            label: "Tân Quan",
            value: 25438,
            slug: "tan-quan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đồng Phú",
        value: 695,
        slug: "huyen-dong-phu",
        status: "empty",
        ward: [
          { label: "Tân Phú", value: 25363, slug: "tan-phu", status: "empty" },
          {
            label: "Thuận Lợi",
            value: 25366,
            slug: "thuan-loi",
            status: "empty",
          },
          {
            label: "Đồng Tâm",
            value: 25369,
            slug: "dong-tam",
            status: "empty",
          },
          {
            label: "Tân Phước",
            value: 25372,
            slug: "tan-phuoc",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 25375,
            slug: "tan-hung",
            status: "empty",
          },
          { label: "Tân Lợi", value: 25378, slug: "tan-loi", status: "empty" },
          { label: "Tân Lập", value: 25381, slug: "tan-lap", status: "empty" },
          { label: "Tân Hòa", value: 25384, slug: "tan-hoa", status: "empty" },
          {
            label: "Thuận Phú",
            value: 25387,
            slug: "thuan-phu",
            status: "empty",
          },
          {
            label: "Đồng Tiến",
            value: 25390,
            slug: "dong-tien",
            status: "empty",
          },
          {
            label: "Tân Tiến",
            value: 25393,
            slug: "tan-tien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bù Đăng",
        value: 696,
        slug: "huyen-bu-dang",
        status: "empty",
        ward: [
          {
            label: "Đức Phong",
            value: 25396,
            slug: "duc-phong",
            status: "empty",
          },
          {
            label: "Đường 10",
            value: 25398,
            slug: "duong-10",
            status: "empty",
          },
          {
            label: "Đak Nhau",
            value: 25399,
            slug: "dak-nhau",
            status: "empty",
          },
          { label: "Phú Sơn", value: 25400, slug: "phu-son", status: "empty" },
          { label: "Thọ Sơn", value: 25402, slug: "tho-son", status: "empty" },
          {
            label: "Bình Minh",
            value: 25404,
            slug: "binh-minh",
            status: "empty",
          },
          { label: "Bom Bo", value: 25405, slug: "bom-bo", status: "empty" },
          {
            label: "Minh Hưng",
            value: 25408,
            slug: "minh-hung",
            status: "empty",
          },
          {
            label: "Đoàn Kết",
            value: 25411,
            slug: "doan-ket",
            status: "empty",
          },
          {
            label: "Đồng Nai",
            value: 25414,
            slug: "dong-nai",
            status: "empty",
          },
          {
            label: "Đức Liễu",
            value: 25417,
            slug: "duc-lieu",
            status: "empty",
          },
          {
            label: "Thống Nhất",
            value: 25420,
            slug: "thong-nhat",
            status: "empty",
          },
          {
            label: "Nghĩa Trung",
            value: 25423,
            slug: "nghia-trung",
            status: "empty",
          },
          {
            label: "Nghĩa Bình",
            value: 25424,
            slug: "nghia-binh",
            status: "empty",
          },
          { label: "Đăng Hà", value: 25426, slug: "dang-ha", status: "empty" },
          {
            label: "Phước Sơn",
            value: 25429,
            slug: "phuoc-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Chơn Thành",
        value: 697,
        slug: "thi-xa-chon-thanh",
        status: "empty",
        ward: [
          {
            label: "Hưng Long",
            value: 25432,
            slug: "hung-long",
            status: "empty",
          },
          {
            label: "Thành Tâm",
            value: 25433,
            slug: "thanh-tam",
            status: "empty",
          },
          {
            label: "Minh Lập",
            value: 25435,
            slug: "minh-lap",
            status: "empty",
          },
          {
            label: "Quang Minh",
            value: 25439,
            slug: "quang-minh",
            status: "empty",
          },
          {
            label: "Minh Hưng",
            value: 25441,
            slug: "minh-hung",
            status: "empty",
          },
          {
            label: "Minh Long",
            value: 25444,
            slug: "minh-long",
            status: "empty",
          },
          {
            label: "Minh Thành",
            value: 25447,
            slug: "minh-thanh",
            status: "empty",
          },
          {
            label: "Nha Bích",
            value: 25450,
            slug: "nha-bich",
            status: "empty",
          },
          {
            label: "Minh Thắng",
            value: 25453,
            slug: "minh-thang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phú Riềng",
        value: 698,
        slug: "huyen-phu-rieng",
        status: "empty",
        ward: [
          {
            label: "Long Bình",
            value: 25240,
            slug: "long-binh",
            status: "empty",
          },
          {
            label: "Bình Tân",
            value: 25243,
            slug: "binh-tan",
            status: "empty",
          },
          {
            label: "Bình Sơn",
            value: 25244,
            slug: "binh-son",
            status: "empty",
          },
          {
            label: "Long Hưng",
            value: 25246,
            slug: "long-hung",
            status: "empty",
          },
          {
            label: "Phước Tân",
            value: 25250,
            slug: "phuoc-tan",
            status: "empty",
          },
          { label: "Bù Nho", value: 25252, slug: "bu-nho", status: "empty" },
          { label: "Long Hà", value: 25255, slug: "long-ha", status: "empty" },
          {
            label: "Long Tân",
            value: 25258,
            slug: "long-tan",
            status: "empty",
          },
          {
            label: "Phú Trung",
            value: 25261,
            slug: "phu-trung",
            status: "empty",
          },
          {
            label: "Phú Riềng",
            value: 25264,
            slug: "phu-rieng",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Tây Ninh",
    value: 72,
    slug: "tay-ninh",
    status: "empty",
    district: [
      {
        label: "Thành phố Tây Ninh",
        value: 703,
        slug: "thanh-pho-tay-ninh",
        status: "empty",
        ward: [
          {
            label: "Phường 1",
            value: 25456,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 25459,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 25462,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Hiệp Ninh",
            value: 25465,
            slug: "hiep-ninh",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 25468,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Thạnh Tân",
            value: 25471,
            slug: "thanh-tan",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 25474,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Bình Minh",
            value: 25477,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Ninh Sơn",
            value: 25480,
            slug: "ninh-son",
            status: "empty",
          },
          {
            label: "Ninh Thạnh",
            value: 25483,
            slug: "ninh-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Biên",
        value: 705,
        slug: "huyen-tan-bien",
        status: "empty",
        ward: [
          {
            label: "Tân Biên",
            value: 25486,
            slug: "tan-bien",
            status: "empty",
          },
          { label: "Tân Lập", value: 25489, slug: "tan-lap", status: "empty" },
          {
            label: "Thạnh Bắc",
            value: 25492,
            slug: "thanh-bac",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 25495,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Thạnh Bình",
            value: 25498,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Thạnh Tây",
            value: 25501,
            slug: "thanh-tay",
            status: "empty",
          },
          {
            label: "Hòa Hiệp",
            value: 25504,
            slug: "hoa-hiep",
            status: "empty",
          },
          {
            label: "Tân Phong",
            value: 25507,
            slug: "tan-phong",
            status: "empty",
          },
          { label: "Mỏ Công", value: 25510, slug: "mo-cong", status: "empty" },
          {
            label: "Trà Vong",
            value: 25513,
            slug: "tra-vong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Châu",
        value: 706,
        slug: "huyen-tan-chau",
        status: "empty",
        ward: [
          {
            label: "Tân Châu",
            value: 25516,
            slug: "tan-chau",
            status: "empty",
          },
          { label: "Tân Hà", value: 25519, slug: "tan-ha", status: "empty" },
          {
            label: "Tân Đông",
            value: 25522,
            slug: "tan-dong",
            status: "empty",
          },
          { label: "Tân Hội", value: 25525, slug: "tan-hoi", status: "empty" },
          { label: "Tân Hòa", value: 25528, slug: "tan-hoa", status: "empty" },
          {
            label: "Suối Ngô",
            value: 25531,
            slug: "suoi-ngo",
            status: "empty",
          },
          {
            label: "Suối Dây",
            value: 25534,
            slug: "suoi-day",
            status: "empty",
          },
          {
            label: "Tân Hiệp",
            value: 25537,
            slug: "tan-hiep",
            status: "empty",
          },
          {
            label: "Thạnh Đông",
            value: 25540,
            slug: "thanh-dong",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 25543,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Tân Phú", value: 25546, slug: "tan-phu", status: "empty" },
          {
            label: "Tân Hưng",
            value: 25549,
            slug: "tan-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Dương Minh Châu",
        value: 707,
        slug: "huyen-duong-minh-chau",
        status: "empty",
        ward: [
          {
            label: "Dương Minh Châu",
            value: 25552,
            slug: "duong-minh-chau",
            status: "empty",
          },
          { label: "Suối Đá", value: 25555, slug: "suoi-da", status: "empty" },
          { label: "Xã Phan", value: 25558, slug: "xa-phan", status: "empty" },
          {
            label: "Phước Ninh",
            value: 25561,
            slug: "phuoc-ninh",
            status: "empty",
          },
          {
            label: "Phước Minh",
            value: 25564,
            slug: "phuoc-minh",
            status: "empty",
          },
          {
            label: "Bàu Năng",
            value: 25567,
            slug: "bau-nang",
            status: "empty",
          },
          { label: "Chà Là", value: 25570, slug: "cha-la", status: "empty" },
          {
            label: "Cầu Khởi",
            value: 25573,
            slug: "cau-khoi",
            status: "empty",
          },
          { label: "Bến Củi", value: 25576, slug: "ben-cui", status: "empty" },
          {
            label: "Lộc Ninh",
            value: 25579,
            slug: "loc-ninh",
            status: "empty",
          },
          {
            label: "Truông Mít",
            value: 25582,
            slug: "truong-mit",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Châu Thành",
        value: 708,
        slug: "huyen-chau-thanh",
        status: "empty",
        ward: [
          {
            label: "Châu Thành",
            value: 25585,
            slug: "chau-thanh",
            status: "empty",
          },
          {
            label: "Hảo Đước",
            value: 25588,
            slug: "hao-duoc",
            status: "empty",
          },
          {
            label: "Phước Vinh",
            value: 25591,
            slug: "phuoc-vinh",
            status: "empty",
          },
          {
            label: "Đồng Khởi",
            value: 25594,
            slug: "dong-khoi",
            status: "empty",
          },
          {
            label: "Thái Bình",
            value: 25597,
            slug: "thai-binh",
            status: "empty",
          },
          { label: "An Cơ", value: 25600, slug: "an-co", status: "empty" },
          {
            label: "Biên Giới",
            value: 25603,
            slug: "bien-gioi",
            status: "empty",
          },
          {
            label: "Hòa Thạnh",
            value: 25606,
            slug: "hoa-thanh",
            status: "empty",
          },
          {
            label: "Trí Bình",
            value: 25609,
            slug: "tri-binh",
            status: "empty",
          },
          { label: "Hòa Hội", value: 25612, slug: "hoa-hoi", status: "empty" },
          { label: "An Bình", value: 25615, slug: "an-binh", status: "empty" },
          {
            label: "Thanh Điền",
            value: 25618,
            slug: "thanh-dien",
            status: "empty",
          },
          {
            label: "Thành Long",
            value: 25621,
            slug: "thanh-long",
            status: "empty",
          },
          {
            label: "Ninh Điền",
            value: 25624,
            slug: "ninh-dien",
            status: "empty",
          },
          {
            label: "Long Vĩnh",
            value: 25627,
            slug: "long-vinh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Hòa Thành",
        value: 709,
        slug: "thi-xa-hoa-thanh",
        status: "empty",
        ward: [
          {
            label: "Long Hoa",
            value: 25630,
            slug: "long-hoa",
            status: "empty",
          },
          {
            label: "Hiệp Tân",
            value: 25633,
            slug: "hiep-tan",
            status: "empty",
          },
          {
            label: "Long Thành Bắc",
            value: 25636,
            slug: "long-thanh-bac",
            status: "empty",
          },
          {
            label: "Trường Hòa",
            value: 25639,
            slug: "truong-hoa",
            status: "empty",
          },
          {
            label: "Trường Đông",
            value: 25642,
            slug: "truong-dong",
            status: "empty",
          },
          {
            label: "Long Thành Trung",
            value: 25645,
            slug: "long-thanh-trung",
            status: "empty",
          },
          {
            label: "Trường Tây",
            value: 25648,
            slug: "truong-tay",
            status: "empty",
          },
          {
            label: "Long Thành Nam",
            value: 25651,
            slug: "long-thanh-nam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Gò Dầu",
        value: 710,
        slug: "huyen-go-dau",
        status: "empty",
        ward: [
          { label: "Gò Dầu", value: 25654, slug: "go-dau", status: "empty" },
          {
            label: "Thạnh Đức",
            value: 25657,
            slug: "thanh-duc",
            status: "empty",
          },
          {
            label: "Cẩm Giang",
            value: 25660,
            slug: "cam-giang",
            status: "empty",
          },
          {
            label: "Hiệp Thạnh",
            value: 25663,
            slug: "hiep-thanh",
            status: "empty",
          },
          { label: "Bàu Đồn", value: 25666, slug: "bau-don", status: "empty" },
          {
            label: "Phước Thạnh",
            value: 25669,
            slug: "phuoc-thanh",
            status: "empty",
          },
          {
            label: "Phước Đông",
            value: 25672,
            slug: "phuoc-dong",
            status: "empty",
          },
          {
            label: "Phước Trạch",
            value: 25675,
            slug: "phuoc-trach",
            status: "empty",
          },
          {
            label: "Thanh Phước",
            value: 25678,
            slug: "thanh-phuoc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bến Cầu",
        value: 711,
        slug: "huyen-ben-cau",
        status: "empty",
        ward: [
          { label: "Bến Cầu", value: 25681, slug: "ben-cau", status: "empty" },
          {
            label: "Long Chữ",
            value: 25684,
            slug: "long-chu",
            status: "empty",
          },
          {
            label: "Long Phước",
            value: 25687,
            slug: "long-phuoc",
            status: "empty",
          },
          {
            label: "Long Giang",
            value: 25690,
            slug: "long-giang",
            status: "empty",
          },
          {
            label: "Tiên Thuận",
            value: 25693,
            slug: "tien-thuan",
            status: "empty",
          },
          {
            label: "Long Khánh",
            value: 25696,
            slug: "long-khanh",
            status: "empty",
          },
          {
            label: "Lợi Thuận",
            value: 25699,
            slug: "loi-thuan",
            status: "empty",
          },
          {
            label: "Long Thuận",
            value: 25702,
            slug: "long-thuan",
            status: "empty",
          },
          {
            label: "An Thạnh",
            value: 25705,
            slug: "an-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Trảng Bàng",
        value: 712,
        slug: "thi-xa-trang-bang",
        status: "empty",
        ward: [
          {
            label: "Trảng Bàng",
            value: 25708,
            slug: "trang-bang",
            status: "empty",
          },
          {
            label: "Đôn Thuận",
            value: 25711,
            slug: "don-thuan",
            status: "empty",
          },
          {
            label: "Hưng Thuận",
            value: 25714,
            slug: "hung-thuan",
            status: "empty",
          },
          {
            label: "Lộc Hưng",
            value: 25717,
            slug: "loc-hung",
            status: "empty",
          },
          { label: "Gia Lộc", value: 25720, slug: "gia-loc", status: "empty" },
          {
            label: "Gia Bình",
            value: 25723,
            slug: "gia-binh",
            status: "empty",
          },
          {
            label: "Phước Bình",
            value: 25729,
            slug: "phuoc-binh",
            status: "empty",
          },
          { label: "An Tịnh", value: 25732, slug: "an-tinh", status: "empty" },
          { label: "An Hòa", value: 25735, slug: "an-hoa", status: "empty" },
          {
            label: "Phước Chỉ",
            value: 25738,
            slug: "phuoc-chi",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Bình Dương",
    value: 74,
    slug: "binh-duong",
    status: "filled",
    district: [
      {
        label: "Thành phố Thủ Dầu Một",
        value: 718,
        slug: "thanh-pho-thu-dau-mot",
        status: "filled",
        ward: [
          {
            label: "Hiệp Thành",
            value: 25741,
            slug: "hiep-thanh",
            status: "filled",
          },
          { label: "Phú Lợi", value: 25744, slug: "phu-loi", status: "filled" },
          {
            label: "Phú Cường",
            value: 25747,
            slug: "phu-cuong",
            status: "filled",
          },
          { label: "Phú Hòa", value: 25750, slug: "phu-hoa", status: "filled" },
          { label: "Phú Thọ", value: 25753, slug: "phu-tho", status: "empty" },
          {
            label: "Chánh Nghĩa",
            value: 25756,
            slug: "chanh-nghia",
            status: "filled",
          },
          {
            label: "Định Hoà",
            value: 25759,
            slug: "dinh-hoa",
            status: "empty",
          },
          { label: "Hoà Phú", value: 25760, slug: "hoa-phu", status: "empty" },
          { label: "Phú Mỹ", value: 25762, slug: "phu-my", status: "empty" },
          { label: "Phú Tân", value: 25763, slug: "phu-tan", status: "empty" },
          { label: "Tân An", value: 25765, slug: "tan-an", status: "empty" },
          { label: "Hiệp An", value: 25768, slug: "hiep-an", status: "empty" },
          {
            label: "Tương Bình Hiệp",
            value: 25771,
            slug: "tuong-binh-hiep",
            status: "empty",
          },
          {
            label: "Chánh Mỹ",
            value: 25774,
            slug: "chanh-my",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bàu Bàng",
        value: 719,
        slug: "huyen-bau-bang",
        status: "empty",
        ward: [
          {
            label: "Trừ Văn Thố",
            value: 25816,
            slug: "tru-van-tho",
            status: "empty",
          },
          {
            label: "Cây Trường II",
            value: 25819,
            slug: "cay-truong-ii",
            status: "empty",
          },
          {
            label: "Lai Uyên",
            value: 25822,
            slug: "lai-uyen",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 25825,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "Long Nguyên",
            value: 25828,
            slug: "long-nguyen",
            status: "empty",
          },
          {
            label: "Hưng Hòa",
            value: 25831,
            slug: "hung-hoa",
            status: "empty",
          },
          {
            label: "Lai Hưng",
            value: 25834,
            slug: "lai-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Dầu Tiếng",
        value: 720,
        slug: "huyen-dau-tieng",
        status: "filled",
        ward: [
          {
            label: "Dầu Tiếng",
            value: 25777,
            slug: "dau-tieng",
            status: "filled",
          },
          {
            label: "Minh Hoà",
            value: 25780,
            slug: "minh-hoa",
            status: "empty",
          },
          {
            label: "Minh Thạnh",
            value: 25783,
            slug: "minh-thanh",
            status: "empty",
          },
          {
            label: "Minh Tân",
            value: 25786,
            slug: "minh-tan",
            status: "empty",
          },
          { label: "Định An", value: 25789, slug: "dinh-an", status: "empty" },
          {
            label: "Long Hoà",
            value: 25792,
            slug: "long-hoa",
            status: "empty",
          },
          {
            label: "Định Thành",
            value: 25795,
            slug: "dinh-thanh",
            status: "empty",
          },
          {
            label: "Định Hiệp",
            value: 25798,
            slug: "dinh-hiep",
            status: "empty",
          },
          { label: "An Lập", value: 25801, slug: "an-lap", status: "empty" },
          {
            label: "Long Tân",
            value: 25804,
            slug: "long-tan",
            status: "empty",
          },
          {
            label: "Thanh An",
            value: 25807,
            slug: "thanh-an",
            status: "empty",
          },
          {
            label: "Thanh Tuyền",
            value: 25810,
            slug: "thanh-tuyen",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Bến Cát",
        value: 721,
        slug: "thi-xa-ben-cat",
        status: "filled",
        ward: [
          {
            label: "Mỹ Phước",
            value: 25813,
            slug: "my-phuoc",
            status: "filled",
          },
          {
            label: "Chánh Phú Hòa",
            value: 25837,
            slug: "chanh-phu-hoa",
            status: "empty",
          },
          { label: "An Điền", value: 25840, slug: "an-dien", status: "empty" },
          { label: "An Tây", value: 25843, slug: "an-tay", status: "empty" },
          {
            label: "Thới Hòa",
            value: 25846,
            slug: "thoi-hoa",
            status: "empty",
          },
          { label: "Hòa Lợi", value: 25849, slug: "hoa-loi", status: "empty" },
          {
            label: "Tân Định",
            value: 25852,
            slug: "tan-dinh",
            status: "empty",
          },
          { label: "Phú An", value: 25855, slug: "phu-an", status: "filled" },
        ],
      },
      {
        label: "Huyện Phú Giáo",
        value: 722,
        slug: "huyen-phu-giao",
        status: "empty",
        ward: [
          {
            label: "Phước Vĩnh",
            value: 25858,
            slug: "phuoc-vinh",
            status: "empty",
          },
          { label: "An Linh", value: 25861, slug: "an-linh", status: "empty" },
          {
            label: "Phước Sang",
            value: 25864,
            slug: "phuoc-sang",
            status: "empty",
          },
          { label: "An Thái", value: 25865, slug: "an-thai", status: "empty" },
          { label: "An Long", value: 25867, slug: "an-long", status: "empty" },
          { label: "An Bình", value: 25870, slug: "an-binh", status: "empty" },
          {
            label: "Tân Hiệp",
            value: 25873,
            slug: "tan-hiep",
            status: "empty",
          },
          { label: "Tam Lập", value: 25876, slug: "tam-lap", status: "empty" },
          {
            label: "Tân Long",
            value: 25879,
            slug: "tan-long",
            status: "empty",
          },
          {
            label: "Vĩnh Hoà",
            value: 25882,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Phước Hoà",
            value: 25885,
            slug: "phuoc-hoa",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Tân Uyên",
        value: 723,
        slug: "thanh-pho-tan-uyen",
        status: "filled",
        ward: [
          {
            label: "Uyên Hưng",
            value: 25888,
            slug: "uyen-hung",
            status: "empty",
          },
          {
            label: "Tân Phước Khánh",
            value: 25891,
            slug: "tan-phuoc-khanh",
            status: "empty",
          },
          {
            label: "Vĩnh Tân",
            value: 25912,
            slug: "vinh-tan",
            status: "filled",
          },
          {
            label: "Hội Nghĩa",
            value: 25915,
            slug: "hoi-nghia",
            status: "empty",
          },
          {
            label: "Tân Hiệp",
            value: 25920,
            slug: "tan-hiep",
            status: "empty",
          },
          {
            label: "Khánh Bình",
            value: 25921,
            slug: "khanh-binh",
            status: "empty",
          },
          {
            label: "Phú Chánh",
            value: 25924,
            slug: "phu-chanh",
            status: "empty",
          },
          {
            label: "Bạch Đằng",
            value: 25930,
            slug: "bach-dang",
            status: "empty",
          },
          {
            label: "Tân Vĩnh Hiệp",
            value: 25933,
            slug: "tan-vinh-hiep",
            status: "empty",
          },
          {
            label: "Thạnh Phước",
            value: 25936,
            slug: "thanh-phuoc",
            status: "empty",
          },
          {
            label: "Thạnh Hội",
            value: 25937,
            slug: "thanh-hoi",
            status: "empty",
          },
          {
            label: "Thái Hòa",
            value: 25939,
            slug: "thai-hoa",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Dĩ An",
        value: 724,
        slug: "thanh-pho-di-an",
        status: "filled",
        ward: [
          { label: "Dĩ An", value: 25942, slug: "di-an", status: "filled" },
          {
            label: "Tân Bình",
            value: 25945,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Tân Đông Hiệp",
            value: 25948,
            slug: "tan-dong-hiep",
            status: "empty",
          },
          { label: "Bình An", value: 25951, slug: "binh-an", status: "empty" },
          {
            label: "Bình Thắng",
            value: 25954,
            slug: "binh-thang",
            status: "empty",
          },
          {
            label: "Đông Hòa",
            value: 25957,
            slug: "dong-hoa",
            status: "empty",
          },
          { label: "An Bình", value: 25960, slug: "an-binh", status: "empty" },
        ],
      },
      {
        label: "Thành phố Thuận An",
        value: 725,
        slug: "thanh-pho-thuan-an",
        status: "filled",
        ward: [
          {
            label: "An Thạnh",
            value: 25963,
            slug: "an-thanh",
            status: "empty",
          },
          {
            label: "Lái Thiêu",
            value: 25966,
            slug: "lai-thieu",
            status: "filled",
          },
          {
            label: "Bình Chuẩn",
            value: 25969,
            slug: "binh-chuan",
            status: "empty",
          },
          {
            label: "Thuận Giao",
            value: 25972,
            slug: "thuan-giao",
            status: "empty",
          },
          { label: "An Phú", value: 25975, slug: "an-phu", status: "filled" },
          {
            label: "Hưng Định",
            value: 25978,
            slug: "hung-dinh",
            status: "empty",
          },
          { label: "An Sơn", value: 25981, slug: "an-son", status: "empty" },
          {
            label: "Bình Nhâm",
            value: 25984,
            slug: "binh-nham",
            status: "empty",
          },
          {
            label: "Bình Hòa",
            value: 25987,
            slug: "binh-hoa",
            status: "filled",
          },
          {
            label: "Vĩnh Phú",
            value: 25990,
            slug: "vinh-phu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bắc Tân Uyên",
        value: 726,
        slug: "huyen-bac-tan-uyen",
        status: "empty",
        ward: [
          {
            label: "Tân Định",
            value: 25894,
            slug: "tan-dinh",
            status: "empty",
          },
          { label: "Bình Mỹ", value: 25897, slug: "binh-my", status: "empty" },
          {
            label: "Tân Bình",
            value: 25900,
            slug: "tan-binh",
            status: "empty",
          },
          { label: "Tân Lập", value: 25903, slug: "tan-lap", status: "empty" },
          {
            label: "Tân Thành",
            value: 25906,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Đất Cuốc",
            value: 25907,
            slug: "dat-cuoc",
            status: "empty",
          },
          {
            label: "Hiếu Liêm",
            value: 25908,
            slug: "hieu-liem",
            status: "empty",
          },
          { label: "Lạc An", value: 25909, slug: "lac-an", status: "empty" },
          { label: "Tân Mỹ", value: 25918, slug: "tan-my", status: "empty" },
          {
            label: "Thường Tân",
            value: 25927,
            slug: "thuong-tan",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Đồng Nai",
    value: 75,
    slug: "dong-nai",
    status: "filled",
    district: [
      {
        label: "Thành phố Biên Hòa",
        value: 731,
        slug: "thanh-pho-bien-hoa",
        status: "filled",
        ward: [
          {
            label: "Trảng Dài",
            value: 25993,
            slug: "trang-dai",
            status: "empty",
          },
          {
            label: "Tân Phong",
            value: 25996,
            slug: "tan-phong",
            status: "filled",
          },
          {
            label: "Tân Biên",
            value: 25999,
            slug: "tan-bien",
            status: "filled",
          },
          { label: "Hố Nai", value: 26002, slug: "ho-nai", status: "empty" },
          { label: "Tân Hòa", value: 26005, slug: "tan-hoa", status: "empty" },
          {
            label: "Tân Hiệp",
            value: 26008,
            slug: "tan-hiep",
            status: "filled",
          },
          {
            label: "Bửu Long",
            value: 26011,
            slug: "buu-long",
            status: "empty",
          },
          { label: "Tân Mai", value: 26014, slug: "tan-mai", status: "filled" },
          {
            label: "Tam Hiệp",
            value: 26017,
            slug: "tam-hiep",
            status: "filled",
          },
          {
            label: "Long Bình",
            value: 26020,
            slug: "long-binh",
            status: "empty",
          },
          {
            label: "Quang Vinh",
            value: 26023,
            slug: "quang-vinh",
            status: "filled",
          },
          {
            label: "Thống Nhất",
            value: 26029,
            slug: "thong-nhat",
            status: "filled",
          },
          {
            label: "Trung Dũng",
            value: 26041,
            slug: "trung-dung",
            status: "empty",
          },
          { label: "Bình Đa", value: 26047, slug: "binh-da", status: "filled" },
          { label: "An Bình", value: 26050, slug: "an-binh", status: "empty" },
          { label: "Bửu Hòa", value: 26053, slug: "buu-hoa", status: "empty" },
          {
            label: "Long Bình Tân",
            value: 26056,
            slug: "long-binh-tan",
            status: "empty",
          },
          { label: "Tân Vạn", value: 26059, slug: "tan-van", status: "empty" },
          {
            label: "Tân Hạnh",
            value: 26062,
            slug: "tan-hanh",
            status: "empty",
          },
          {
            label: "Hiệp Hòa",
            value: 26065,
            slug: "hiep-hoa",
            status: "empty",
          },
          { label: "Hóa An", value: 26068, slug: "hoa-an", status: "empty" },
          { label: "An Hòa", value: 26371, slug: "an-hoa", status: "empty" },
          {
            label: "Tam Phước",
            value: 26374,
            slug: "tam-phuoc",
            status: "empty",
          },
          {
            label: "Phước Tân",
            value: 26377,
            slug: "phuoc-tan",
            status: "empty",
          },
          {
            label: "Long Hưng",
            value: 26380,
            slug: "long-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Long Khánh",
        value: 732,
        slug: "thanh-pho-long-khanh",
        status: "filled",
        ward: [
          {
            label: "Xuân Bình",
            value: 26077,
            slug: "xuan-binh",
            status: "empty",
          },
          { label: "Xuân An", value: 26080, slug: "xuan-an", status: "filled" },
          {
            label: "Xuân Hoà",
            value: 26083,
            slug: "xuan-hoa",
            status: "empty",
          },
          {
            label: "Phú Bình",
            value: 26086,
            slug: "phu-binh",
            status: "empty",
          },
          {
            label: "Bình Lộc",
            value: 26089,
            slug: "binh-loc",
            status: "empty",
          },
          {
            label: "Bảo Quang",
            value: 26092,
            slug: "bao-quang",
            status: "empty",
          },
          {
            label: "Suối Tre",
            value: 26095,
            slug: "suoi-tre",
            status: "empty",
          },
          {
            label: "Bảo Vinh",
            value: 26098,
            slug: "bao-vinh",
            status: "empty",
          },
          {
            label: "Xuân Lập",
            value: 26101,
            slug: "xuan-lap",
            status: "empty",
          },
          { label: "Bàu Sen", value: 26104, slug: "bau-sen", status: "empty" },
          {
            label: "Bàu Trâm",
            value: 26107,
            slug: "bau-tram",
            status: "empty",
          },
          {
            label: "Xuân Tân",
            value: 26110,
            slug: "xuan-tan",
            status: "empty",
          },
          {
            label: "Hàng Gòn",
            value: 26113,
            slug: "hang-gon",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Phú",
        value: 734,
        slug: "huyen-tan-phu",
        status: "filled",
        ward: [
          { label: "Tân Phú", value: 26116, slug: "tan-phu", status: "filled" },
          { label: "Dak Lua", value: 26119, slug: "dak-lua", status: "empty" },
          {
            label: "Nam Cát Tiên",
            value: 26122,
            slug: "nam-cat-tien",
            status: "empty",
          },
          { label: "Phú An", value: 26125, slug: "phu-an", status: "empty" },
          { label: "Tà Lài", value: 26131, slug: "ta-lai", status: "empty" },
          { label: "Phú Lập", value: 26134, slug: "phu-lap", status: "empty" },
          {
            label: "Phú Thịnh",
            value: 26140,
            slug: "phu-thinh",
            status: "empty",
          },
          {
            label: "Thanh Sơn",
            value: 26143,
            slug: "thanh-son",
            status: "empty",
          },
          { label: "Phú Sơn", value: 26146, slug: "phu-son", status: "empty" },
          {
            label: "Phú Xuân",
            value: 26149,
            slug: "phu-xuan",
            status: "empty",
          },
          { label: "Phú Lộc", value: 26152, slug: "phu-loc", status: "empty" },
          { label: "Phú Lâm", value: 26155, slug: "phu-lam", status: "empty" },
          {
            label: "Phú Bình",
            value: 26158,
            slug: "phu-binh",
            status: "empty",
          },
          {
            label: "Phú Thanh",
            value: 26161,
            slug: "phu-thanh",
            status: "empty",
          },
          { label: "Trà Cổ", value: 26164, slug: "tra-co", status: "empty" },
          {
            label: "Phú Điền",
            value: 26167,
            slug: "phu-dien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vĩnh Cửu",
        value: 735,
        slug: "huyen-vinh-cuu",
        status: "empty",
        ward: [
          { label: "Vĩnh An", value: 26170, slug: "vinh-an", status: "empty" },
          { label: "Phú Lý", value: 26173, slug: "phu-ly", status: "empty" },
          { label: "Trị An", value: 26176, slug: "tri-an", status: "empty" },
          { label: "Tân An", value: 26179, slug: "tan-an", status: "empty" },
          {
            label: "Vĩnh Tân",
            value: 26182,
            slug: "vinh-tan",
            status: "empty",
          },
          {
            label: "Bình Lợi",
            value: 26185,
            slug: "binh-loi",
            status: "empty",
          },
          {
            label: "Thạnh Phú",
            value: 26188,
            slug: "thanh-phu",
            status: "empty",
          },
          {
            label: "Thiện Tân",
            value: 26191,
            slug: "thien-tan",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 26194,
            slug: "tan-binh",
            status: "empty",
          },
          { label: "Mã Đà", value: 26200, slug: "ma-da", status: "empty" },
        ],
      },
      {
        label: "Huyện Định Quán",
        value: 736,
        slug: "huyen-dinh-quan",
        status: "filled",
        ward: [
          {
            label: "Định Quán",
            value: 26206,
            slug: "dinh-quan",
            status: "filled",
          },
          {
            label: "Thanh Sơn",
            value: 26209,
            slug: "thanh-son",
            status: "empty",
          },
          { label: "Phú Tân", value: 26212, slug: "phu-tan", status: "empty" },
          {
            label: "Phú Vinh",
            value: 26215,
            slug: "phu-vinh",
            status: "empty",
          },
          { label: "Phú Lợi", value: 26218, slug: "phu-loi", status: "filled" },
          { label: "Phú Hòa", value: 26221, slug: "phu-hoa", status: "empty" },
          {
            label: "Ngọc Định",
            value: 26224,
            slug: "ngoc-dinh",
            status: "empty",
          },
          { label: "La Ngà", value: 26227, slug: "la-nga", status: "empty" },
          {
            label: "Gia Canh",
            value: 26230,
            slug: "gia-canh",
            status: "empty",
          },
          {
            label: "Phú Ngọc",
            value: 26233,
            slug: "phu-ngoc",
            status: "empty",
          },
          {
            label: "Phú Cường",
            value: 26236,
            slug: "phu-cuong",
            status: "empty",
          },
          {
            label: "Túc Trưng",
            value: 26239,
            slug: "tuc-trung",
            status: "empty",
          },
          { label: "Phú Túc", value: 26242, slug: "phu-tuc", status: "empty" },
          {
            label: "Suối Nho",
            value: 26245,
            slug: "suoi-nho",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Trảng Bom",
        value: 737,
        slug: "huyen-trang-bom",
        status: "filled",
        ward: [
          {
            label: "Trảng Bom",
            value: 26248,
            slug: "trang-bom",
            status: "empty",
          },
          {
            label: "Thanh Bình",
            value: 26251,
            slug: "thanh-binh",
            status: "empty",
          },
          { label: "Cây Gáo", value: 26254, slug: "cay-gao", status: "empty" },
          { label: "Bàu Hàm", value: 26257, slug: "bau-ham", status: "empty" },
          {
            label: "Sông Thao",
            value: 26260,
            slug: "song-thao",
            status: "empty",
          },
          {
            label: "Sông Trầu",
            value: 26263,
            slug: "song-trau",
            status: "empty",
          },
          {
            label: "Đông Hoà",
            value: 26266,
            slug: "dong-hoa",
            status: "empty",
          },
          { label: "Bắc Sơn", value: 26269, slug: "bac-son", status: "filled" },
          {
            label: "Hố Nai 3",
            value: 26272,
            slug: "ho-nai-3",
            status: "filled",
          },
          { label: "Tây Hoà", value: 26275, slug: "tay-hoa", status: "empty" },
          {
            label: "Bình Minh",
            value: 26278,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Trung Hoà",
            value: 26281,
            slug: "trung-hoa",
            status: "empty",
          },
          { label: "Đồi 61", value: 26284, slug: "doi-61", status: "empty" },
          {
            label: "Hưng Thịnh",
            value: 26287,
            slug: "hung-thinh",
            status: "empty",
          },
          {
            label: "Quảng Tiến",
            value: 26290,
            slug: "quang-tien",
            status: "empty",
          },
          {
            label: "Giang Điền",
            value: 26293,
            slug: "giang-dien",
            status: "empty",
          },
          { label: "An Viễn", value: 26296, slug: "an-vien", status: "empty" },
        ],
      },
      {
        label: "Huyện Thống Nhất",
        value: 738,
        slug: "huyen-thong-nhat",
        status: "filled",
        ward: [
          {
            label: "Gia Tân 1",
            value: 26299,
            slug: "gia-tan-1",
            status: "empty",
          },
          {
            label: "Gia Tân 2",
            value: 26302,
            slug: "gia-tan-2",
            status: "empty",
          },
          {
            label: "Gia Tân 3",
            value: 26305,
            slug: "gia-tan-3",
            status: "filled",
          },
          {
            label: "Gia Kiệm",
            value: 26308,
            slug: "gia-kiem",
            status: "filled",
          },
          {
            label: "Quang Trung",
            value: 26311,
            slug: "quang-trung",
            status: "empty",
          },
          {
            label: "Bàu Hàm 2",
            value: 26314,
            slug: "bau-ham-2",
            status: "empty",
          },
          {
            label: "Hưng Lộc",
            value: 26317,
            slug: "hung-loc",
            status: "empty",
          },
          { label: "Lộ 25", value: 26320, slug: "lo-25", status: "empty" },
          {
            label: "Xuân Thiện",
            value: 26323,
            slug: "xuan-thien",
            status: "empty",
          },
          {
            label: "Dầu Giây",
            value: 26326,
            slug: "dau-giay",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cẩm Mỹ",
        value: 739,
        slug: "huyen-cam-my",
        status: "empty",
        ward: [
          {
            label: "Sông Nhạn",
            value: 26329,
            slug: "song-nhan",
            status: "empty",
          },
          {
            label: "Xuân Quế",
            value: 26332,
            slug: "xuan-que",
            status: "empty",
          },
          {
            label: "Nhân Nghĩa",
            value: 26335,
            slug: "nhan-nghia",
            status: "empty",
          },
          {
            label: "Xuân Đường",
            value: 26338,
            slug: "xuan-duong",
            status: "empty",
          },
          {
            label: "Long Giao",
            value: 26341,
            slug: "long-giao",
            status: "empty",
          },
          { label: "Xuân Mỹ", value: 26344, slug: "xuan-my", status: "empty" },
          {
            label: "Thừa Đức",
            value: 26347,
            slug: "thua-duc",
            status: "empty",
          },
          {
            label: "Bảo Bình",
            value: 26350,
            slug: "bao-binh",
            status: "empty",
          },
          {
            label: "Xuân Bảo",
            value: 26353,
            slug: "xuan-bao",
            status: "empty",
          },
          {
            label: "Xuân Tây",
            value: 26356,
            slug: "xuan-tay",
            status: "empty",
          },
          {
            label: "Xuân Đông",
            value: 26359,
            slug: "xuan-dong",
            status: "empty",
          },
          {
            label: "Sông Ray",
            value: 26362,
            slug: "song-ray",
            status: "empty",
          },
          { label: "Lâm San", value: 26365, slug: "lam-san", status: "empty" },
        ],
      },
      {
        label: "Huyện Long Thành",
        value: 740,
        slug: "huyen-long-thanh",
        status: "filled",
        ward: [
          {
            label: "Long Thành",
            value: 26368,
            slug: "long-thanh",
            status: "filled",
          },
          {
            label: "An Phước",
            value: 26383,
            slug: "an-phuoc",
            status: "empty",
          },
          { label: "Bình An", value: 26386, slug: "binh-an", status: "empty" },
          {
            label: "Long Đức",
            value: 26389,
            slug: "long-duc",
            status: "empty",
          },
          { label: "Lộc An", value: 26392, slug: "loc-an", status: "empty" },
          {
            label: "Bình Sơn",
            value: 26395,
            slug: "binh-son",
            status: "empty",
          },
          { label: "Tam An", value: 26398, slug: "tam-an", status: "empty" },
          {
            label: "Cẩm Đường",
            value: 26401,
            slug: "cam-duong",
            status: "empty",
          },
          { label: "Long An", value: 26404, slug: "long-an", status: "empty" },
          { label: "Bàu Cạn", value: 26410, slug: "bau-can", status: "empty" },
          {
            label: "Long Phước",
            value: 26413,
            slug: "long-phuoc",
            status: "empty",
          },
          {
            label: "Phước Bình",
            value: 26416,
            slug: "phuoc-binh",
            status: "empty",
          },
          {
            label: "Tân Hiệp",
            value: 26419,
            slug: "tan-hiep",
            status: "empty",
          },
          {
            label: "Phước Thái",
            value: 26422,
            slug: "phuoc-thai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Xuân Lộc",
        value: 741,
        slug: "huyen-xuan-loc",
        status: "empty",
        ward: [
          { label: "Gia Ray", value: 26425, slug: "gia-ray", status: "empty" },
          {
            label: "Xuân Bắc",
            value: 26428,
            slug: "xuan-bac",
            status: "empty",
          },
          {
            label: "Suối Cao",
            value: 26431,
            slug: "suoi-cao",
            status: "empty",
          },
          {
            label: "Xuân Thành",
            value: 26434,
            slug: "xuan-thanh",
            status: "empty",
          },
          {
            label: "Xuân Thọ",
            value: 26437,
            slug: "xuan-tho",
            status: "empty",
          },
          {
            label: "Xuân Trường",
            value: 26440,
            slug: "xuan-truong",
            status: "empty",
          },
          {
            label: "Xuân Hòa",
            value: 26443,
            slug: "xuan-hoa",
            status: "empty",
          },
          {
            label: "Xuân Hưng",
            value: 26446,
            slug: "xuan-hung",
            status: "empty",
          },
          {
            label: "Xuân Tâm",
            value: 26449,
            slug: "xuan-tam",
            status: "empty",
          },
          {
            label: "Suối Cát",
            value: 26452,
            slug: "suoi-cat",
            status: "empty",
          },
          {
            label: "Xuân Hiệp",
            value: 26455,
            slug: "xuan-hiep",
            status: "empty",
          },
          {
            label: "Xuân Phú",
            value: 26458,
            slug: "xuan-phu",
            status: "empty",
          },
          {
            label: "Xuân Định",
            value: 26461,
            slug: "xuan-dinh",
            status: "empty",
          },
          { label: "Bảo Hoà", value: 26464, slug: "bao-hoa", status: "empty" },
          {
            label: "Lang Minh",
            value: 26467,
            slug: "lang-minh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Nhơn Trạch",
        value: 742,
        slug: "huyen-nhon-trach",
        status: "filled",
        ward: [
          {
            label: "Phước Thiền",
            value: 26470,
            slug: "phuoc-thien",
            status: "empty",
          },
          {
            label: "Long Tân",
            value: 26473,
            slug: "long-tan",
            status: "empty",
          },
          {
            label: "Đại Phước",
            value: 26476,
            slug: "dai-phuoc",
            status: "filled",
          },
          {
            label: "Hiệp Phước",
            value: 26479,
            slug: "hiep-phuoc",
            status: "empty",
          },
          { label: "Phú Hữu", value: 26482, slug: "phu-huu", status: "empty" },
          { label: "Phú Hội", value: 26485, slug: "phu-hoi", status: "empty" },
          {
            label: "Phú Thạnh",
            value: 26488,
            slug: "phu-thanh",
            status: "filled",
          },
          {
            label: "Phú Đông",
            value: 26491,
            slug: "phu-dong",
            status: "empty",
          },
          {
            label: "Long Thọ",
            value: 26494,
            slug: "long-tho",
            status: "filled",
          },
          {
            label: "Vĩnh Thanh",
            value: 26497,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Phước Khánh",
            value: 26500,
            slug: "phuoc-khanh",
            status: "empty",
          },
          {
            label: "Phước An",
            value: 26503,
            slug: "phuoc-an",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Bà Rịa - Vũng Tàu",
    value: 77,
    slug: "ba-ria-vung-tau",
    status: "empty",
    district: [
      {
        label: "Thành phố Vũng Tàu",
        value: 747,
        slug: "thanh-pho-vung-tau",
        status: "empty",
        ward: [
          {
            label: "Phường 1",
            value: 26506,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Thắng Tam",
            value: 26508,
            slug: "thang-tam",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 26509,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 26512,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 26515,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 26518,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Thắng Nhì",
            value: 26521,
            slug: "thang-nhi",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 26524,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Nguyễn An Ninh",
            value: 26526,
            slug: "nguyen-an-ninh",
            status: "empty",
          },
          {
            label: "Phường 8",
            value: 26527,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Phường 9",
            value: 26530,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Thắng Nhất",
            value: 26533,
            slug: "thang-nhat",
            status: "empty",
          },
          {
            label: "Rạch Dừa",
            value: 26535,
            slug: "rach-dua",
            status: "empty",
          },
          {
            label: "Phường 10",
            value: 26536,
            slug: "phuong-10",
            status: "empty",
          },
          {
            label: "Phường 11",
            value: 26539,
            slug: "phuong-11",
            status: "empty",
          },
          {
            label: "Phường 12",
            value: 26542,
            slug: "phuong-12",
            status: "empty",
          },
          {
            label: "Long Sơn",
            value: 26545,
            slug: "long-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Bà Rịa",
        value: 748,
        slug: "thanh-pho-ba-ria",
        status: "empty",
        ward: [
          {
            label: "Phước Hưng",
            value: 26548,
            slug: "phuoc-hung",
            status: "empty",
          },
          {
            label: "Phước Nguyên",
            value: 26554,
            slug: "phuoc-nguyen",
            status: "empty",
          },
          {
            label: "Long Toàn",
            value: 26557,
            slug: "long-toan",
            status: "empty",
          },
          {
            label: "Long Tâm",
            value: 26558,
            slug: "long-tam",
            status: "empty",
          },
          {
            label: "Phước Trung",
            value: 26560,
            slug: "phuoc-trung",
            status: "empty",
          },
          {
            label: "Long Hương",
            value: 26563,
            slug: "long-huong",
            status: "empty",
          },
          {
            label: "Kim Dinh",
            value: 26566,
            slug: "kim-dinh",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 26567,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "Long Phước",
            value: 26569,
            slug: "long-phuoc",
            status: "empty",
          },
          {
            label: "Hoà Long",
            value: 26572,
            slug: "hoa-long",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Châu Đức",
        value: 750,
        slug: "huyen-chau-duc",
        status: "empty",
        ward: [
          {
            label: "Bàu Chinh",
            value: 26574,
            slug: "bau-chinh",
            status: "empty",
          },
          {
            label: "Ngãi Giao",
            value: 26575,
            slug: "ngai-giao",
            status: "empty",
          },
          { label: "Bình Ba", value: 26578, slug: "binh-ba", status: "empty" },
          {
            label: "Suối Nghệ",
            value: 26581,
            slug: "suoi-nghe",
            status: "empty",
          },
          {
            label: "Xuân Sơn",
            value: 26584,
            slug: "xuan-son",
            status: "empty",
          },
          {
            label: "Sơn Bình",
            value: 26587,
            slug: "son-binh",
            status: "empty",
          },
          {
            label: "Bình Giã",
            value: 26590,
            slug: "binh-gia",
            status: "empty",
          },
          {
            label: "Bình Trung",
            value: 26593,
            slug: "binh-trung",
            status: "empty",
          },
          { label: "Xà Bang", value: 26596, slug: "xa-bang", status: "empty" },
          { label: "Cù Bị", value: 26599, slug: "cu-bi", status: "empty" },
          {
            label: "Láng Lớn",
            value: 26602,
            slug: "lang-lon",
            status: "empty",
          },
          {
            label: "Quảng Thành",
            value: 26605,
            slug: "quang-thanh",
            status: "empty",
          },
          {
            label: "Kim Long",
            value: 26608,
            slug: "kim-long",
            status: "empty",
          },
          {
            label: "Suối Rao",
            value: 26611,
            slug: "suoi-rao",
            status: "empty",
          },
          { label: "Đá Bạc", value: 26614, slug: "da-bac", status: "empty" },
          {
            label: "Nghĩa Thành",
            value: 26617,
            slug: "nghia-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Xuyên Mộc",
        value: 751,
        slug: "huyen-xuyen-moc",
        status: "empty",
        ward: [
          {
            label: "Phước Bửu",
            value: 26620,
            slug: "phuoc-buu",
            status: "empty",
          },
          {
            label: "Phước Thuận",
            value: 26623,
            slug: "phuoc-thuan",
            status: "empty",
          },
          {
            label: "Phước Tân",
            value: 26626,
            slug: "phuoc-tan",
            status: "empty",
          },
          {
            label: "Xuyên Mộc",
            value: 26629,
            slug: "xuyen-moc",
            status: "empty",
          },
          {
            label: "Bông Trang",
            value: 26632,
            slug: "bong-trang",
            status: "empty",
          },
          { label: "Tân Lâm", value: 26635, slug: "tan-lam", status: "empty" },
          { label: "Bàu Lâm", value: 26638, slug: "bau-lam", status: "empty" },
          {
            label: "Hòa Bình",
            value: 26641,
            slug: "hoa-binh",
            status: "empty",
          },
          {
            label: "Hòa Hưng",
            value: 26644,
            slug: "hoa-hung",
            status: "empty",
          },
          {
            label: "Hòa Hiệp",
            value: 26647,
            slug: "hoa-hiep",
            status: "empty",
          },
          { label: "Hòa Hội", value: 26650, slug: "hoa-hoi", status: "empty" },
          {
            label: "Bưng Riềng",
            value: 26653,
            slug: "bung-rieng",
            status: "empty",
          },
          {
            label: "Bình Châu",
            value: 26656,
            slug: "binh-chau",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Long Điền",
        value: 752,
        slug: "huyen-long-dien",
        status: "empty",
        ward: [
          {
            label: "Long Điền",
            value: 105600,
            slug: "long-dien",
            status: "empty",
          },
          {
            label: "Phước Tỉnh",
            value: 105601,
            slug: "phuoc-tinh",
            status: "empty",
          },
          { label: "An Ngãi", value: 105602, slug: "an-ngai", status: "empty" },
          {
            label: "Tam Phước",
            value: 105603,
            slug: "tam-phuoc",
            status: "empty",
          },
          {
            label: "Bình Giã",
            value: 105604,
            slug: "binh-gia",
            status: "empty",
          },
          { label: "An Hòa", value: 105605, slug: "an-hoa", status: "empty" },
        ],
      },
      {
        label: "Huyện Đất Đỏ",
        value: 753,
        slug: "huyen-dat-do",
        status: "empty",
        ward: [
          {
            label: "Long Điền",
            value: 26659,
            slug: "long-dien",
            status: "empty",
          },
          {
            label: "Long Hải",
            value: 26662,
            slug: "long-hai",
            status: "empty",
          },
          { label: "Tam An", value: 26668, slug: "tam-an", status: "empty" },
          {
            label: "Phước Tỉnh",
            value: 26674,
            slug: "phuoc-tinh",
            status: "empty",
          },
          {
            label: "Phước Hưng",
            value: 26677,
            slug: "phuoc-hung",
            status: "empty",
          },
          { label: "Đất Đỏ", value: 26680, slug: "dat-do", status: "empty" },
          {
            label: "Phước Long Thọ",
            value: 26683,
            slug: "phuoc-long-tho",
            status: "empty",
          },
          {
            label: "Phước Hội",
            value: 26686,
            slug: "phuoc-hoi",
            status: "empty",
          },
          {
            label: "Phước Hải",
            value: 26689,
            slug: "phuoc-hai",
            status: "empty",
          },
          {
            label: "Long Tân",
            value: 26695,
            slug: "long-tan",
            status: "empty",
          },
          {
            label: "Láng Dài",
            value: 26698,
            slug: "lang-dai",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Phú Mỹ",
        value: 754,
        slug: "thi-xa-phu-my",
        status: "empty",
        ward: [
          { label: "Phú Mỹ", value: 26704, slug: "phu-my", status: "empty" },
          { label: "Tân Hoà", value: 26707, slug: "tan-hoa", status: "empty" },
          { label: "Tân Hải", value: 26710, slug: "tan-hai", status: "empty" },
          {
            label: "Phước Hoà",
            value: 26713,
            slug: "phuoc-hoa",
            status: "empty",
          },
          {
            label: "Tân Phước",
            value: 26716,
            slug: "tan-phuoc",
            status: "empty",
          },
          { label: "Mỹ Xuân", value: 26719, slug: "my-xuan", status: "empty" },
          {
            label: "Sông Xoài",
            value: 26722,
            slug: "song-xoai",
            status: "empty",
          },
          {
            label: "Hắc Dịch",
            value: 26725,
            slug: "hac-dich",
            status: "empty",
          },
          {
            label: "Châu Pha",
            value: 26728,
            slug: "chau-pha",
            status: "empty",
          },
          {
            label: "Tóc Tiên",
            value: 26731,
            slug: "toc-tien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Côn Đảo",
        value: 755,
        slug: "huyen-con-dao",
        status: "empty",
        ward: [
          { label: "Côn Sơn", value: 105610, slug: "con-son", status: "empty" },
          {
            label: "Bình Hưng",
            value: 105611,
            slug: "binh-hung",
            status: "empty",
          },
          { label: "Cái Giá", value: 105612, slug: "cai-gia", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Hồ Chí Minh",
    value: 79,
    slug: "ho-chi-minh",
    status: "filled",
    district: [
      {
        label: "Quận 1",
        value: 760,
        slug: "quan-1",
        status: "filled",
        ward: [
          {
            label: "Tân Định",
            value: 26734,
            slug: "tan-dinh",
            status: "filled",
          },
          { label: "Đa Kao", value: 26737, slug: "da-kao", status: "filled" },
          {
            label: "Bến Nghé",
            value: 26740,
            slug: "ben-nghe",
            status: "empty",
          },
          {
            label: "Bến Thành",
            value: 26743,
            slug: "ben-thanh",
            status: "filled",
          },
          {
            label: "Nguyễn Thái Bình",
            value: 26746,
            slug: "nguyen-thai-binh",
            status: "filled",
          },
          {
            label: "Phạm Ngũ Lão",
            value: 26749,
            slug: "pham-ngu-lao",
            status: "filled",
          },
          {
            label: "Cầu Ông Lãnh",
            value: 26752,
            slug: "cau-ong-lanh",
            status: "filled",
          },
          {
            label: "Cô Giang",
            value: 26755,
            slug: "co-giang",
            status: "empty",
          },
          {
            label: "Nguyễn Cư Trinh",
            value: 26758,
            slug: "nguyen-cu-trinh",
            status: "filled",
          },
          { label: "Cầu Kho", value: 26761, slug: "cau-kho", status: "empty" },
        ],
      },
      {
        label: "Quận 12",
        value: 761,
        slug: "quan-12",
        status: "filled",
        ward: [
          {
            label: "Thạnh Xuân",
            value: 26764,
            slug: "thanh-xuan",
            status: "empty",
          },
          {
            label: "Thạnh Lộc",
            value: 26767,
            slug: "thanh-loc",
            status: "empty",
          },
          {
            label: "Hiệp Thành",
            value: 26770,
            slug: "hiep-thanh",
            status: "empty",
          },
          { label: "Thới An", value: 26773, slug: "thoi-an", status: "empty" },
          {
            label: "Tân Chánh Hiệp",
            value: 26776,
            slug: "tan-chanh-hiep",
            status: "filled",
          },
          {
            label: "An Phú Đông",
            value: 26779,
            slug: "an-phu-dong",
            status: "empty",
          },
          {
            label: "Tân Thới Hiệp",
            value: 26782,
            slug: "tan-thoi-hiep",
            status: "empty",
          },
          {
            label: "Trung Mỹ Tây",
            value: 26785,
            slug: "trung-my-tay",
            status: "empty",
          },
          {
            label: "Tân Hưng Thuận",
            value: 26787,
            slug: "tan-hung-thuan",
            status: "empty",
          },
          {
            label: "Đông Hưng Thuận",
            value: 26788,
            slug: "dong-hung-thuan",
            status: "empty",
          },
          {
            label: "Tân Thới Nhất",
            value: 26791,
            slug: "tan-thoi-nhat",
            status: "filled",
          },
        ],
      },
      {
        label: "Gò Vấp",
        value: 764,
        slug: "go-vap",
        status: "filled",
        ward: [
          {
            label: "Phường 15",
            value: 26872,
            slug: "phuong-15",
            status: "filled",
          },
          {
            label: "Phường 17",
            value: 26875,
            slug: "phuong-17",
            status: "empty",
          },
          {
            label: "Phường 6",
            value: 26876,
            slug: "phuong-6",
            status: "empty",
          },
          {
            label: "Phường 16",
            value: 26878,
            slug: "phuong-16",
            status: "empty",
          },
          {
            label: "Phường 12",
            value: 26881,
            slug: "phuong-12",
            status: "filled",
          },
          {
            label: "Phường 14",
            value: 26882,
            slug: "phuong-14",
            status: "empty",
          },
          {
            label: "Phường 10",
            value: 26884,
            slug: "phuong-10",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 26887,
            slug: "phuong-5",
            status: "filled",
          },
          {
            label: "Phường 1",
            value: 26890,
            slug: "phuong-1",
            status: "filled",
          },
          {
            label: "Phường 8",
            value: 26898,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Phường 11",
            value: 26899,
            slug: "phuong-11",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 26902,
            slug: "phuong-3",
            status: "empty",
          },
        ],
      },
      {
        label: "Bình Thạnh",
        value: 765,
        slug: "binh-thanh",
        status: "filled",
        ward: [
          {
            label: "Phường 13",
            value: 26905,
            slug: "phuong-13",
            status: "empty",
          },
          {
            label: "Phường 11",
            value: 26908,
            slug: "phuong-11",
            status: "empty",
          },
          {
            label: "Phường 27",
            value: 26911,
            slug: "phuong-27",
            status: "empty",
          },
          {
            label: "Phường 26",
            value: 26914,
            slug: "phuong-26",
            status: "empty",
          },
          {
            label: "Phường 12",
            value: 26917,
            slug: "phuong-12",
            status: "empty",
          },
          {
            label: "Phường 25",
            value: 26920,
            slug: "phuong-25",
            status: "filled",
          },
          {
            label: "Phường 5",
            value: 26923,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 26926,
            slug: "phuong-7",
            status: "filled",
          },
          {
            label: "Phường 14",
            value: 26935,
            slug: "phuong-14",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 26941,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 26944,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 17",
            value: 26950,
            slug: "phuong-17",
            status: "empty",
          },
          {
            label: "Phường 22",
            value: 26956,
            slug: "phuong-22",
            status: "empty",
          },
          {
            label: "Phường 19",
            value: 26959,
            slug: "phuong-19",
            status: "empty",
          },
          {
            label: "Phường 28",
            value: 26962,
            slug: "phuong-28",
            status: "empty",
          },
        ],
      },
      {
        label: "Tân Bình",
        value: 766,
        slug: "tan-binh",
        status: "filled",
        ward: [
          {
            label: "Phường 2",
            value: 26965,
            slug: "phuong-2",
            status: "filled",
          },
          {
            label: "Phường 4",
            value: 26968,
            slug: "phuong-4",
            status: "filled",
          },
          {
            label: "Phường 12",
            value: 26971,
            slug: "phuong-12",
            status: "filled",
          },
          {
            label: "Phường 13",
            value: 26974,
            slug: "phuong-13",
            status: "filled",
          },
          {
            label: "Phường 1",
            value: 26977,
            slug: "phuong-1",
            status: "filled",
          },
          {
            label: "Phường 3",
            value: 26980,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 11",
            value: 26983,
            slug: "phuong-11",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 26986,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 26989,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 10",
            value: 26992,
            slug: "phuong-10",
            status: "empty",
          },
          {
            label: "Phường 6",
            value: 26995,
            slug: "phuong-6",
            status: "empty",
          },
          {
            label: "Phường 8",
            value: 26998,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Phường 9",
            value: 27001,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Phường 14",
            value: 27004,
            slug: "phuong-14",
            status: "filled",
          },
          {
            label: "Phường 15",
            value: 27007,
            slug: "phuong-15",
            status: "empty",
          },
        ],
      },
      {
        label: "Tân Phú",
        value: 767,
        slug: "tan-phu",
        status: "filled",
        ward: [
          {
            label: "Tân Sơn Nhì",
            value: 27010,
            slug: "tan-son-nhi",
            status: "filled",
          },
          {
            label: "Tây Thạnh",
            value: 27013,
            slug: "tay-thanh",
            status: "filled",
          },
          { label: "Sơn Kỳ", value: 27016, slug: "son-ky", status: "filled" },
          { label: "Tân Quý", value: 27019, slug: "tan-quy", status: "filled" },
          {
            label: "Tân Thành",
            value: 27022,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Phú Thọ Hòa",
            value: 27025,
            slug: "phu-tho-hoa",
            status: "empty",
          },
          {
            label: "Phú Thạnh",
            value: 27028,
            slug: "phu-thanh",
            status: "filled",
          },
          {
            label: "Phú Trung",
            value: 27031,
            slug: "phu-trung",
            status: "empty",
          },
          {
            label: "Hòa Thạnh",
            value: 27034,
            slug: "hoa-thanh",
            status: "empty",
          },
          {
            label: "Hiệp Tân",
            value: 27037,
            slug: "hiep-tan",
            status: "empty",
          },
          {
            label: "Tân Thới Hòa",
            value: 27040,
            slug: "tan-thoi-hoa",
            status: "empty",
          },
        ],
      },
      {
        label: "Phú Nhuận",
        value: 768,
        slug: "phu-nhuan",
        status: "filled",
        ward: [
          {
            label: "Phường 4",
            value: 27043,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 27046,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 9",
            value: 27049,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 27052,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 27058,
            slug: "phuong-1",
            status: "filled",
          },
          {
            label: "Phường 2",
            value: 27061,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 8",
            value: 27064,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Phường 15",
            value: 27067,
            slug: "phuong-15",
            status: "empty",
          },
          {
            label: "Phường 10",
            value: 27070,
            slug: "phuong-10",
            status: "empty",
          },
          {
            label: "Phường 11",
            value: 27073,
            slug: "phuong-11",
            status: "empty",
          },
          {
            label: "Phường 17",
            value: 27074,
            slug: "phuong-17",
            status: "filled",
          },
          {
            label: "Phường 13",
            value: 27085,
            slug: "phuong-13",
            status: "empty",
          },
        ],
      },
      {
        label: "Thủ Đức",
        value: 769,
        slug: "thu-duc",
        status: "filled",
        ward: [
          {
            label: "Linh Xuân",
            value: 26794,
            slug: "linh-xuan",
            status: "filled",
          },
          {
            label: "Bình Chiểu",
            value: 26797,
            slug: "binh-chieu",
            status: "empty",
          },
          {
            label: "Linh Trung",
            value: 26800,
            slug: "linh-trung",
            status: "empty",
          },
          {
            label: "Tam Bình",
            value: 26803,
            slug: "tam-binh",
            status: "empty",
          },
          { label: "Tam Phú", value: 26806, slug: "tam-phu", status: "filled" },
          {
            label: "Hiệp Bình Phước",
            value: 26809,
            slug: "hiep-binh-phuoc",
            status: "filled",
          },
          {
            label: "Hiệp Bình Chánh",
            value: 26812,
            slug: "hiep-binh-chanh",
            status: "empty",
          },
          {
            label: "Linh Chiểu",
            value: 26815,
            slug: "linh-chieu",
            status: "empty",
          },
          {
            label: "Linh Tây",
            value: 26818,
            slug: "linh-tay",
            status: "filled",
          },
          {
            label: "Linh Đông",
            value: 26821,
            slug: "linh-dong",
            status: "filled",
          },
          {
            label: "Bình Thọ",
            value: 26824,
            slug: "binh-tho",
            status: "filled",
          },
          {
            label: "Trường Thọ",
            value: 26827,
            slug: "truong-tho",
            status: "empty",
          },
          {
            label: "Long Bình",
            value: 26830,
            slug: "long-binh",
            status: "empty",
          },
          {
            label: "Long Thạnh Mỹ",
            value: 26833,
            slug: "long-thanh-my",
            status: "empty",
          },
          { label: "Tân Phú", value: 26836, slug: "tan-phu", status: "filled" },
          {
            label: "Hiệp Phú",
            value: 26839,
            slug: "hiep-phu",
            status: "empty",
          },
          {
            label: "Tăng Nhơn Phú A",
            value: 26842,
            slug: "tang-nhon-phu-a",
            status: "filled",
          },
          {
            label: "Tăng Nhơn Phú B",
            value: 26845,
            slug: "tang-nhon-phu-b",
            status: "empty",
          },
          {
            label: "Phước Long B",
            value: 26848,
            slug: "phuoc-long-b",
            status: "filled",
          },
          {
            label: "Phước Long A",
            value: 26851,
            slug: "phuoc-long-a",
            status: "empty",
          },
          {
            label: "Trường Thạnh",
            value: 26854,
            slug: "truong-thanh",
            status: "empty",
          },
          {
            label: "Long Phước",
            value: 26857,
            slug: "long-phuoc",
            status: "empty",
          },
          {
            label: "Long Trường",
            value: 26860,
            slug: "long-truong",
            status: "empty",
          },
          {
            label: "Phước Bình",
            value: 26863,
            slug: "phuoc-binh",
            status: "empty",
          },
          { label: "Phú Hữu", value: 26866, slug: "phu-huu", status: "empty" },
          {
            label: "Thảo Điền",
            value: 27088,
            slug: "thao-dien",
            status: "empty",
          },
          { label: "An Phú", value: 27091, slug: "an-phu", status: "empty" },
          {
            label: "An Khánh",
            value: 27094,
            slug: "an-khanh",
            status: "empty",
          },
          {
            label: "Bình Trưng Đông",
            value: 27097,
            slug: "binh-trung-dong",
            status: "empty",
          },
          {
            label: "Bình Trưng Tây",
            value: 27100,
            slug: "binh-trung-tay",
            status: "empty",
          },
          { label: "Cát Lái", value: 27109, slug: "cat-lai", status: "empty" },
          {
            label: "Thạnh Mỹ Lợi",
            value: 27112,
            slug: "thanh-my-loi",
            status: "empty",
          },
          {
            label: "An Lợi Đông",
            value: 27115,
            slug: "an-loi-dong",
            status: "empty",
          },
          {
            label: "Thủ Thiêm",
            value: 27118,
            slug: "thu-thiem",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận 3",
        value: 770,
        slug: "quan-3",
        status: "filled",
        ward: [
          {
            label: "Phường 14",
            value: 27127,
            slug: "phuong-14",
            status: "filled",
          },
          {
            label: "Phường 12",
            value: 27130,
            slug: "phuong-12",
            status: "empty",
          },
          {
            label: "Phường 11",
            value: 27133,
            slug: "phuong-11",
            status: "empty",
          },
          {
            label: "Võ Thị Sáu",
            value: 27139,
            slug: "vo-thi-sau",
            status: "filled",
          },
          {
            label: "Phường 7",
            value: 27140,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Phường 9",
            value: 27142,
            slug: "phuong-9",
            status: "filled",
          },
          {
            label: "Phường 4",
            value: 27148,
            slug: "phuong-4",
            status: "filled",
          },
          {
            label: "Phường 5",
            value: 27151,
            slug: "phuong-5",
            status: "filled",
          },
          {
            label: "Phường 3",
            value: 27154,
            slug: "phuong-3",
            status: "filled",
          },
          {
            label: "Phường 2",
            value: 27157,
            slug: "phuong-2",
            status: "filled",
          },
          {
            label: "Phường 1",
            value: 27160,
            slug: "phuong-1",
            status: "filled",
          },
        ],
      },
      {
        label: "Quận 10",
        value: 771,
        slug: "quan-10",
        status: "filled",
        ward: [
          {
            label: "Phường 15",
            value: 27163,
            slug: "phuong-15",
            status: "filled",
          },
          {
            label: "Phường 13",
            value: 27166,
            slug: "phuong-13",
            status: "empty",
          },
          {
            label: "Phường 14",
            value: 27169,
            slug: "phuong-14",
            status: "empty",
          },
          {
            label: "Phường 12",
            value: 27172,
            slug: "phuong-12",
            status: "filled",
          },
          {
            label: "Phường 10",
            value: 27178,
            slug: "phuong-10",
            status: "filled",
          },
          {
            label: "Phường 9",
            value: 27181,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 27184,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 8",
            value: 27187,
            slug: "phuong-8",
            status: "filled",
          },
          {
            label: "Phường 2",
            value: 27190,
            slug: "phuong-2",
            status: "filled",
          },
          {
            label: "Phường 4",
            value: 27193,
            slug: "phuong-4",
            status: "filled",
          },
          {
            label: "Phường 6",
            value: 27202,
            slug: "phuong-6",
            status: "filled",
          },
          {
            label: "Phường 5",
            value: 27203,
            slug: "phuong-5",
            status: "filled",
          },
        ],
      },
      {
        label: "Quận 11",
        value: 772,
        slug: "quan-11",
        status: "filled",
        ward: [
          {
            label: "Phường 15",
            value: 27208,
            slug: "phuong-15",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 27211,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 14",
            value: 27214,
            slug: "phuong-14",
            status: "empty",
          },
          {
            label: "Phường 11",
            value: 27217,
            slug: "phuong-11",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 27220,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 10",
            value: 27223,
            slug: "phuong-10",
            status: "empty",
          },
          {
            label: "Phường 8",
            value: 27229,
            slug: "phuong-8",
            status: "filled",
          },
          {
            label: "Phường 7",
            value: 27238,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 27247,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 16",
            value: 27253,
            slug: "phuong-16",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận 4",
        value: 773,
        slug: "quan-4",
        status: "filled",
        ward: [
          {
            label: "Phường 13",
            value: 27259,
            slug: "phuong-13",
            status: "empty",
          },
          {
            label: "Phường 9",
            value: 27265,
            slug: "phuong-9",
            status: "filled",
          },
          {
            label: "Phường 8",
            value: 27271,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Phường 18",
            value: 27277,
            slug: "phuong-18",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 27283,
            slug: "phuong-4",
            status: "filled",
          },
          {
            label: "Phường 3",
            value: 27286,
            slug: "phuong-3",
            status: "filled",
          },
          {
            label: "Phường 16",
            value: 27289,
            slug: "phuong-16",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 27292,
            slug: "phuong-2",
            status: "filled",
          },
          {
            label: "Phường 15",
            value: 27295,
            slug: "phuong-15",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 27298,
            slug: "phuong-1",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận 5",
        value: 774,
        slug: "quan-5",
        status: "filled",
        ward: [
          {
            label: "Phường 4",
            value: 27301,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 9",
            value: 27304,
            slug: "phuong-9",
            status: "filled",
          },
          {
            label: "Phường 2",
            value: 27307,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 12",
            value: 27310,
            slug: "phuong-12",
            status: "filled",
          },
          {
            label: "Phường 7",
            value: 27316,
            slug: "phuong-7",
            status: "filled",
          },
          {
            label: "Phường 1",
            value: 27325,
            slug: "phuong-1",
            status: "filled",
          },
          {
            label: "Phường 11",
            value: 27328,
            slug: "phuong-11",
            status: "empty",
          },
          {
            label: "Phường 14",
            value: 27331,
            slug: "phuong-14",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 27334,
            slug: "phuong-5",
            status: "filled",
          },
          {
            label: "Phường 13",
            value: 27343,
            slug: "phuong-13",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận 6",
        value: 775,
        slug: "quan-6",
        status: "filled",
        ward: [
          {
            label: "Phường 14",
            value: 27346,
            slug: "phuong-14",
            status: "empty",
          },
          {
            label: "Phường 13",
            value: 27349,
            slug: "phuong-13",
            status: "empty",
          },
          {
            label: "Phường 9",
            value: 27352,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Phường 12",
            value: 27358,
            slug: "phuong-12",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 27361,
            slug: "phuong-2",
            status: "filled",
          },
          {
            label: "Phường 11",
            value: 27364,
            slug: "phuong-11",
            status: "filled",
          },
          {
            label: "Phường 8",
            value: 27376,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 27379,
            slug: "phuong-1",
            status: "filled",
          },
          {
            label: "Phường 7",
            value: 27382,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Phường 10",
            value: 27385,
            slug: "phuong-10",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận 8",
        value: 776,
        slug: "quan-8",
        status: "filled",
        ward: [
          {
            label: "Rạch Ông",
            value: 27397,
            slug: "rach-ong",
            status: "filled",
          },
          {
            label: "Hưng Phú",
            value: 27403,
            slug: "hung-phu",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 27409,
            slug: "phuong-4",
            status: "empty",
          },
          { label: "Xóm Củi", value: 27415, slug: "xom-cui", status: "filled" },
          {
            label: "Phường 5",
            value: 27418,
            slug: "phuong-5",
            status: "filled",
          },
          {
            label: "Phường 14",
            value: 27421,
            slug: "phuong-14",
            status: "empty",
          },
          {
            label: "Phường 6",
            value: 27424,
            slug: "phuong-6",
            status: "empty",
          },
          {
            label: "Phường 15",
            value: 27427,
            slug: "phuong-15",
            status: "empty",
          },
          {
            label: "Phường 16",
            value: 27430,
            slug: "phuong-16",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 27433,
            slug: "phuong-7",
            status: "empty",
          },
        ],
      },
      {
        label: "Bình Tân",
        value: 777,
        slug: "binh-tan",
        status: "filled",
        ward: [
          {
            label: "Bình Hưng Hòa",
            value: 27436,
            slug: "binh-hung-hoa",
            status: "empty",
          },
          {
            label: "Bình Hưng Hoà A",
            value: 27439,
            slug: "binh-hung-hoa-a",
            status: "empty",
          },
          {
            label: "Bình Hưng Hoà B",
            value: 27442,
            slug: "binh-hung-hoa-b",
            status: "empty",
          },
          {
            label: "Bình Trị Đông",
            value: 27445,
            slug: "binh-tri-dong",
            status: "filled",
          },
          {
            label: "Bình Trị Đông A",
            value: 27448,
            slug: "binh-tri-dong-a",
            status: "empty",
          },
          {
            label: "Bình Trị Đông B",
            value: 27451,
            slug: "binh-tri-dong-b",
            status: "empty",
          },
          { label: "Tân Tạo", value: 27454, slug: "tan-tao", status: "filled" },
          {
            label: "Tân Tạo A",
            value: 27457,
            slug: "tan-tao-a",
            status: "empty",
          },
          { label: "An Lạc", value: 27460, slug: "an-lac", status: "filled" },
          {
            label: "An Lạc A",
            value: 27463,
            slug: "an-lac-a",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận 7",
        value: 778,
        slug: "quan-7",
        status: "filled",
        ward: [
          {
            label: "Tân Thuận Đông",
            value: 27466,
            slug: "tan-thuan-dong",
            status: "empty",
          },
          {
            label: "Tân Thuận Tây",
            value: 27469,
            slug: "tan-thuan-tay",
            status: "empty",
          },
          {
            label: "Tân Kiểng",
            value: 27472,
            slug: "tan-kieng",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 27475,
            slug: "tan-hung",
            status: "filled",
          },
          {
            label: "Bình Thuận",
            value: 27478,
            slug: "binh-thuan",
            status: "filled",
          },
          { label: "Tân Quy", value: 27481, slug: "tan-quy", status: "empty" },
          {
            label: "Phú Thuận",
            value: 27484,
            slug: "phu-thuan",
            status: "empty",
          },
          { label: "Tân Phú", value: 27487, slug: "tan-phu", status: "filled" },
          {
            label: "Tân Phong",
            value: 27490,
            slug: "tan-phong",
            status: "empty",
          },
          { label: "Phú Mỹ", value: 27493, slug: "phu-my", status: "filled" },
        ],
      },
      {
        label: "Củ Chi",
        value: 783,
        slug: "cu-chi",
        status: "empty",
        ward: [
          { label: "Củ Chi", value: 27496, slug: "cu-chi", status: "empty" },
          {
            label: "Phú Mỹ Hưng",
            value: 27499,
            slug: "phu-my-hung",
            status: "empty",
          },
          { label: "An Phú", value: 27502, slug: "an-phu", status: "empty" },
          {
            label: "Trung Lập Thượng",
            value: 27505,
            slug: "trung-lap-thuong",
            status: "empty",
          },
          {
            label: "An Nhơn Tây",
            value: 27508,
            slug: "an-nhon-tay",
            status: "empty",
          },
          {
            label: "Nhuận Đức",
            value: 27511,
            slug: "nhuan-duc",
            status: "empty",
          },
          {
            label: "Phạm Văn Cội",
            value: 27514,
            slug: "pham-van-coi",
            status: "empty",
          },
          {
            label: "Phú Hòa Đông",
            value: 27517,
            slug: "phu-hoa-dong",
            status: "empty",
          },
          {
            label: "Trung Lập Hạ",
            value: 27520,
            slug: "trung-lap-ha",
            status: "empty",
          },
          {
            label: "Trung An",
            value: 27523,
            slug: "trung-an",
            status: "empty",
          },
          {
            label: "Phước Thạnh",
            value: 27526,
            slug: "phuoc-thanh",
            status: "empty",
          },
          {
            label: "Phước Hiệp",
            value: 27529,
            slug: "phuoc-hiep",
            status: "empty",
          },
          {
            label: "Tân An Hội",
            value: 27532,
            slug: "tan-an-hoi",
            status: "empty",
          },
          {
            label: "Phước Vĩnh An",
            value: 27535,
            slug: "phuoc-vinh-an",
            status: "empty",
          },
          { label: "Thái Mỹ", value: 27538, slug: "thai-my", status: "empty" },
          {
            label: "Tân Thạnh Tây",
            value: 27541,
            slug: "tan-thanh-tay",
            status: "empty",
          },
          { label: "Hòa Phú", value: 27544, slug: "hoa-phu", status: "empty" },
          {
            label: "Tân Thạnh Đông",
            value: 27547,
            slug: "tan-thanh-dong",
            status: "empty",
          },
          { label: "Bình Mỹ", value: 27550, slug: "binh-my", status: "empty" },
          {
            label: "Tân Phú Trung",
            value: 27553,
            slug: "tan-phu-trung",
            status: "empty",
          },
          {
            label: "Tân Thông Hội",
            value: 27556,
            slug: "tan-thong-hoi",
            status: "empty",
          },
        ],
      },
      {
        label: "Hóc Môn",
        value: 784,
        slug: "hoc-mon",
        status: "empty",
        ward: [
          { label: "Hóc Môn", value: 27559, slug: "hoc-mon", status: "empty" },
          {
            label: "Tân Hiệp",
            value: 27562,
            slug: "tan-hiep",
            status: "empty",
          },
          {
            label: "Nhị Bình",
            value: 27565,
            slug: "nhi-binh",
            status: "empty",
          },
          {
            label: "Đông Thạnh",
            value: 27568,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Tân Thới Nhì",
            value: 27571,
            slug: "tan-thoi-nhi",
            status: "empty",
          },
          {
            label: "Thới Tam Thôn",
            value: 27574,
            slug: "thoi-tam-thon",
            status: "empty",
          },
          {
            label: "Xuân Thới Sơn",
            value: 27577,
            slug: "xuan-thoi-son",
            status: "empty",
          },
          {
            label: "Tân Xuân",
            value: 27580,
            slug: "tan-xuan",
            status: "empty",
          },
          {
            label: "Xuân Thới Đông",
            value: 27583,
            slug: "xuan-thoi-dong",
            status: "empty",
          },
          {
            label: "Trung Chánh",
            value: 27586,
            slug: "trung-chanh",
            status: "empty",
          },
          {
            label: "Xuân Thới Thượng",
            value: 27589,
            slug: "xuan-thoi-thuong",
            status: "empty",
          },
          { label: "Bà Điểm", value: 27592, slug: "ba-diem", status: "empty" },
        ],
      },
      {
        label: "Bình Chánh",
        value: 785,
        slug: "binh-chanh",
        status: "filled",
        ward: [
          { label: "Tân Túc", value: 27595, slug: "tan-tuc", status: "empty" },
          {
            label: "Phạm Văn Hai",
            value: 27598,
            slug: "pham-van-hai",
            status: "empty",
          },
          {
            label: "Vĩnh Lộc A",
            value: 27601,
            slug: "vinh-loc-a",
            status: "empty",
          },
          {
            label: "Vĩnh Lộc B",
            value: 27604,
            slug: "vinh-loc-b",
            status: "empty",
          },
          {
            label: "Bình Lợi",
            value: 27607,
            slug: "binh-loi",
            status: "empty",
          },
          {
            label: "Lê Minh Xuân",
            value: 27610,
            slug: "le-minh-xuan",
            status: "empty",
          },
          {
            label: "Tân Nhựt",
            value: 27613,
            slug: "tan-nhut",
            status: "empty",
          },
          {
            label: "Tân Kiên",
            value: 27616,
            slug: "tan-kien",
            status: "empty",
          },
          {
            label: "Bình Hưng",
            value: 27619,
            slug: "binh-hung",
            status: "filled",
          },
          {
            label: "Phong Phú",
            value: 27622,
            slug: "phong-phu",
            status: "empty",
          },
          {
            label: "An Phú Tây",
            value: 27625,
            slug: "an-phu-tay",
            status: "empty",
          },
          {
            label: "Hưng Long",
            value: 27628,
            slug: "hung-long",
            status: "empty",
          },
          {
            label: "Đa Phước",
            value: 27631,
            slug: "da-phuoc",
            status: "empty",
          },
          {
            label: "Tân Quý Tây",
            value: 27634,
            slug: "tan-quy-tay",
            status: "empty",
          },
          {
            label: "Bình Chánh",
            value: 27637,
            slug: "binh-chanh",
            status: "empty",
          },
          { label: "Quy Đức", value: 27640, slug: "quy-duc", status: "empty" },
        ],
      },
      {
        label: "Nhà Bè",
        value: 786,
        slug: "nha-be",
        status: "filled",
        ward: [
          { label: "Nhà Bè", value: 27643, slug: "nha-be", status: "empty" },
          {
            label: "Phước Kiển",
            value: 27646,
            slug: "phuoc-kien",
            status: "filled",
          },
          {
            label: "Phước Lộc",
            value: 27649,
            slug: "phuoc-loc",
            status: "empty",
          },
          {
            label: "Nhơn Đức",
            value: 27652,
            slug: "nhon-duc",
            status: "empty",
          },
          {
            label: "Phú Xuân",
            value: 27655,
            slug: "phu-xuan",
            status: "empty",
          },
          {
            label: "Long Thới",
            value: 27658,
            slug: "long-thoi",
            status: "empty",
          },
          {
            label: "Hiệp Phước",
            value: 27661,
            slug: "hiep-phuoc",
            status: "empty",
          },
        ],
      },
      {
        label: "Cần Giờ",
        value: 787,
        slug: "can-gio",
        status: "empty",
        ward: [
          {
            label: "Cần Thạnh",
            value: 27664,
            slug: "can-thanh",
            status: "empty",
          },
          {
            label: "Bình Khánh",
            value: 27667,
            slug: "binh-khanh",
            status: "empty",
          },
          {
            label: "Tam Thôn Hiệp",
            value: 27670,
            slug: "tam-thon-hiep",
            status: "empty",
          },
          {
            label: "An Thới Đông",
            value: 27673,
            slug: "an-thoi-dong",
            status: "empty",
          },
          {
            label: "Thạnh An",
            value: 27676,
            slug: "thanh-an",
            status: "empty",
          },
          {
            label: "Long Hòa",
            value: 27679,
            slug: "long-hoa",
            status: "empty",
          },
          { label: "Lý Nhơn", value: 27682, slug: "ly-nhon", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Long An",
    value: 80,
    slug: "long-an",
    status: "filled",
    district: [
      {
        label: "Thành phố Tân An",
        value: 794,
        slug: "thanh-pho-tan-an",
        status: "empty",
        ward: [
          {
            label: "Phường 5",
            value: 27685,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 27691,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Tân Khánh",
            value: 27692,
            slug: "tan-khanh",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 27694,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 27697,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 27698,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Phường 6",
            value: 27700,
            slug: "phuong-6",
            status: "empty",
          },
          {
            label: "Hướng Thọ Phú",
            value: 27703,
            slug: "huong-tho-phu",
            status: "empty",
          },
          {
            label: "Nhơn Thạnh Trung",
            value: 27706,
            slug: "nhon-thanh-trung",
            status: "empty",
          },
          {
            label: "Lợi Bình Nhơn",
            value: 27709,
            slug: "loi-binh-nhon",
            status: "empty",
          },
          {
            label: "Bình Tâm",
            value: 27712,
            slug: "binh-tam",
            status: "empty",
          },
          {
            label: "Khánh Hậu",
            value: 27715,
            slug: "khanh-hau",
            status: "empty",
          },
          {
            label: "An Vĩnh Ngãi",
            value: 27718,
            slug: "an-vinh-ngai",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Kiến Tường",
        value: 795,
        slug: "thi-xa-kien-tuong",
        status: "empty",
        ward: [
          {
            label: "Phường 1",
            value: 27787,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 27788,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Thạnh Trị",
            value: 27790,
            slug: "thanh-tri",
            status: "empty",
          },
          {
            label: "Bình Hiệp",
            value: 27793,
            slug: "binh-hiep",
            status: "empty",
          },
          {
            label: "Bình Tân",
            value: 27799,
            slug: "binh-tan",
            status: "empty",
          },
          {
            label: "Tuyên Thạnh",
            value: 27805,
            slug: "tuyen-thanh",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 27806,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Thạnh Hưng",
            value: 27817,
            slug: "thanh-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Hưng",
        value: 796,
        slug: "huyen-tan-hung",
        status: "empty",
        ward: [
          {
            label: "Tân Hưng",
            value: 27721,
            slug: "tan-hung",
            status: "empty",
          },
          { label: "Hưng Hà", value: 27724, slug: "hung-ha", status: "empty" },
          {
            label: "Hưng Điền B",
            value: 27727,
            slug: "hung-dien-b",
            status: "empty",
          },
          {
            label: "Hưng Điền",
            value: 27730,
            slug: "hung-dien",
            status: "empty",
          },
          {
            label: "Thạnh Hưng",
            value: 27733,
            slug: "thanh-hung",
            status: "empty",
          },
          {
            label: "Hưng Thạnh",
            value: 27736,
            slug: "hung-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Thạnh",
            value: 27739,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Châu B",
            value: 27742,
            slug: "vinh-chau-b",
            status: "empty",
          },
          {
            label: "Vĩnh Lợi",
            value: 27745,
            slug: "vinh-loi",
            status: "empty",
          },
          {
            label: "Vĩnh Đại",
            value: 27748,
            slug: "vinh-dai",
            status: "empty",
          },
          {
            label: "Vĩnh Châu A",
            value: 27751,
            slug: "vinh-chau-a",
            status: "empty",
          },
          {
            label: "Vĩnh Bửu",
            value: 27754,
            slug: "vinh-buu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vĩnh Hưng",
        value: 797,
        slug: "huyen-vinh-hung",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Hưng",
            value: 27757,
            slug: "vinh-hung",
            status: "empty",
          },
          {
            label: "Hưng Điền A",
            value: 27760,
            slug: "hung-dien-a",
            status: "empty",
          },
          {
            label: "Khánh Hưng",
            value: 27763,
            slug: "khanh-hung",
            status: "empty",
          },
          {
            label: "Thái Trị",
            value: 27766,
            slug: "thai-tri",
            status: "empty",
          },
          {
            label: "Vĩnh Trị",
            value: 27769,
            slug: "vinh-tri",
            status: "empty",
          },
          {
            label: "Thái Bình Trung",
            value: 27772,
            slug: "thai-binh-trung",
            status: "empty",
          },
          {
            label: "Vĩnh Bình",
            value: 27775,
            slug: "vinh-binh",
            status: "empty",
          },
          {
            label: "Vĩnh Thuận",
            value: 27778,
            slug: "vinh-thuan",
            status: "empty",
          },
          {
            label: "Tuyên Bình",
            value: 27781,
            slug: "tuyen-binh",
            status: "empty",
          },
          {
            label: "Tuyên Bình Tây",
            value: 27784,
            slug: "tuyen-binh-tay",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Mộc Hóa",
        value: 798,
        slug: "huyen-moc-hoa",
        status: "empty",
        ward: [
          {
            label: "Bình Hòa Tây",
            value: 27796,
            slug: "binh-hoa-tay",
            status: "empty",
          },
          {
            label: "Bình Thạnh",
            value: 27802,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Bình Hòa Trung",
            value: 27808,
            slug: "binh-hoa-trung",
            status: "empty",
          },
          {
            label: "Bình Hòa Đông",
            value: 27811,
            slug: "binh-hoa-dong",
            status: "empty",
          },
          {
            label: "Bình Phong Thạnh",
            value: 27814,
            slug: "binh-phong-thanh",
            status: "empty",
          },
          { label: "Tân Lập", value: 27820, slug: "tan-lap", status: "empty" },
          {
            label: "Tân Thành",
            value: 27823,
            slug: "tan-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Thạnh",
        value: 799,
        slug: "huyen-tan-thanh",
        status: "empty",
        ward: [
          {
            label: "Tân Thạnh",
            value: 27826,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Bắc Hòa", value: 27829, slug: "bac-hoa", status: "empty" },
          {
            label: "Hậu Thạnh Tây",
            value: 27832,
            slug: "hau-thanh-tay",
            status: "empty",
          },
          {
            label: "Nhơn Hòa Lập",
            value: 27835,
            slug: "nhon-hoa-lap",
            status: "empty",
          },
          { label: "Tân Lập", value: 27838, slug: "tan-lap", status: "empty" },
          {
            label: "Hậu Thạnh Đông",
            value: 27841,
            slug: "hau-thanh-dong",
            status: "empty",
          },
          {
            label: "Nhơn Hoà",
            value: 27844,
            slug: "nhon-hoa",
            status: "empty",
          },
          {
            label: "Kiến Bình",
            value: 27847,
            slug: "kien-binh",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 27850,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 27853,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Tân Ninh",
            value: 27856,
            slug: "tan-ninh",
            status: "empty",
          },
          {
            label: "Nhơn Ninh",
            value: 27859,
            slug: "nhon-ninh",
            status: "empty",
          },
          { label: "Tân Hòa", value: 27862, slug: "tan-hoa", status: "empty" },
        ],
      },
      {
        label: "Huyện Thạnh Hóa",
        value: 800,
        slug: "huyen-thanh-hoa",
        status: "empty",
        ward: [
          {
            label: "Thạnh Hóa",
            value: 27865,
            slug: "thanh-hoa",
            status: "empty",
          },
          {
            label: "Tân Hiệp",
            value: 27868,
            slug: "tan-hiep",
            status: "empty",
          },
          {
            label: "Thuận Bình",
            value: 27871,
            slug: "thuan-binh",
            status: "empty",
          },
          {
            label: "Thạnh Phước",
            value: 27874,
            slug: "thanh-phuoc",
            status: "empty",
          },
          {
            label: "Thạnh Phú",
            value: 27877,
            slug: "thanh-phu",
            status: "empty",
          },
          {
            label: "Thuận Nghĩa Hòa",
            value: 27880,
            slug: "thuan-nghia-hoa",
            status: "empty",
          },
          {
            label: "Thủy Đông",
            value: 27883,
            slug: "thuy-dong",
            status: "empty",
          },
          {
            label: "Thủy Tây",
            value: 27886,
            slug: "thuy-tay",
            status: "empty",
          },
          { label: "Tân Tây", value: 27889, slug: "tan-tay", status: "empty" },
          {
            label: "Tân Đông",
            value: 27892,
            slug: "tan-dong",
            status: "empty",
          },
          {
            label: "Thạnh An",
            value: 27895,
            slug: "thanh-an",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đức Huệ",
        value: 801,
        slug: "huyen-duc-hue",
        status: "empty",
        ward: [
          {
            label: "Đông Thành",
            value: 27898,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Mỹ Quý Đông",
            value: 27901,
            slug: "my-quy-dong",
            status: "empty",
          },
          {
            label: "Mỹ Thạnh Bắc",
            value: 27904,
            slug: "my-thanh-bac",
            status: "empty",
          },
          {
            label: "Mỹ Quý Tây",
            value: 27907,
            slug: "my-quy-tay",
            status: "empty",
          },
          {
            label: "Mỹ Thạnh Tây",
            value: 27910,
            slug: "my-thanh-tay",
            status: "empty",
          },
          {
            label: "Mỹ Thạnh Đông",
            value: 27913,
            slug: "my-thanh-dong",
            status: "empty",
          },
          {
            label: "Bình Thành",
            value: 27916,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Bình Hòa Bắc",
            value: 27919,
            slug: "binh-hoa-bac",
            status: "empty",
          },
          {
            label: "Bình Hòa Hưng",
            value: 27922,
            slug: "binh-hoa-hung",
            status: "empty",
          },
          {
            label: "Bình Hòa Nam",
            value: 27925,
            slug: "binh-hoa-nam",
            status: "empty",
          },
          { label: "Mỹ Bình", value: 27928, slug: "my-binh", status: "empty" },
        ],
      },
      {
        label: "Huyện Đức Hòa",
        value: 802,
        slug: "huyen-duc-hoa",
        status: "empty",
        ward: [
          {
            label: "Hậu Nghĩa",
            value: 27931,
            slug: "hau-nghia",
            status: "empty",
          },
          {
            label: "Hiệp Hòa",
            value: 27934,
            slug: "hiep-hoa",
            status: "empty",
          },
          { label: "Đức Hòa", value: 27937, slug: "duc-hoa", status: "empty" },
          {
            label: "Lộc Giang",
            value: 27940,
            slug: "loc-giang",
            status: "empty",
          },
          {
            label: "An Ninh Đông",
            value: 27943,
            slug: "an-ninh-dong",
            status: "empty",
          },
          {
            label: "An Ninh Tây",
            value: 27946,
            slug: "an-ninh-tay",
            status: "empty",
          },
          { label: "Tân Mỹ", value: 27949, slug: "tan-my", status: "empty" },
          {
            label: "Hiệp Hòa",
            value: 27952,
            slug: "hiep-hoa",
            status: "empty",
          },
          {
            label: "Đức Lập Thượng",
            value: 27955,
            slug: "duc-lap-thuong",
            status: "empty",
          },
          {
            label: "Đức Lập Hạ",
            value: 27958,
            slug: "duc-lap-ha",
            status: "empty",
          },
          { label: "Tân Phú", value: 27961, slug: "tan-phu", status: "empty" },
          {
            label: "Mỹ Hạnh Bắc",
            value: 27964,
            slug: "my-hanh-bac",
            status: "empty",
          },
          {
            label: "Đức Hòa Thượng",
            value: 27967,
            slug: "duc-hoa-thuong",
            status: "empty",
          },
          {
            label: "Hòa Khánh Tây",
            value: 27970,
            slug: "hoa-khanh-tay",
            status: "empty",
          },
          {
            label: "Hòa Khánh Đông",
            value: 27973,
            slug: "hoa-khanh-dong",
            status: "empty",
          },
          {
            label: "Mỹ Hạnh Nam",
            value: 27976,
            slug: "my-hanh-nam",
            status: "empty",
          },
          {
            label: "Hòa Khánh Nam",
            value: 27979,
            slug: "hoa-khanh-nam",
            status: "empty",
          },
          {
            label: "Đức Hòa Đông",
            value: 27982,
            slug: "duc-hoa-dong",
            status: "empty",
          },
          {
            label: "Đức Hòa Hạ",
            value: 27985,
            slug: "duc-hoa-ha",
            status: "empty",
          },
          {
            label: "Hựu Thạnh",
            value: 27988,
            slug: "huu-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bến Lức",
        value: 803,
        slug: "huyen-ben-luc",
        status: "filled",
        ward: [
          { label: "Bến Lức", value: 27991, slug: "ben-luc", status: "filled" },
          {
            label: "Thạnh Lợi",
            value: 27994,
            slug: "thanh-loi",
            status: "empty",
          },
          {
            label: "Lương Bình",
            value: 27997,
            slug: "luong-binh",
            status: "empty",
          },
          {
            label: "Thạnh Hòa",
            value: 28000,
            slug: "thanh-hoa",
            status: "empty",
          },
          {
            label: "Lương Hòa",
            value: 28003,
            slug: "luong-hoa",
            status: "empty",
          },
          { label: "Tân Bửu", value: 28009, slug: "tan-buu", status: "empty" },
          {
            label: "An Thạnh",
            value: 28012,
            slug: "an-thanh",
            status: "empty",
          },
          {
            label: "Bình Đức",
            value: 28015,
            slug: "binh-duc",
            status: "empty",
          },
          { label: "Mỹ Yên", value: 28018, slug: "my-yen", status: "empty" },
          {
            label: "Thanh Phú",
            value: 28021,
            slug: "thanh-phu",
            status: "empty",
          },
          {
            label: "Long Hiệp",
            value: 28024,
            slug: "long-hiep",
            status: "empty",
          },
          {
            label: "Thạnh Đức",
            value: 28027,
            slug: "thanh-duc",
            status: "empty",
          },
          {
            label: "Phước Lợi",
            value: 28030,
            slug: "phuoc-loi",
            status: "empty",
          },
          {
            label: "Nhựt Chánh",
            value: 28033,
            slug: "nhut-chanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thủ Thừa",
        value: 804,
        slug: "huyen-thu-thua",
        status: "empty",
        ward: [
          {
            label: "Thủ Thừa",
            value: 28036,
            slug: "thu-thua",
            status: "empty",
          },
          {
            label: "Long Thạnh",
            value: 28039,
            slug: "long-thanh",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 28042,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Long Thuận",
            value: 28045,
            slug: "long-thuan",
            status: "empty",
          },
          { label: "Mỹ Lạc", value: 28048, slug: "my-lac", status: "empty" },
          {
            label: "Mỹ Thạnh",
            value: 28051,
            slug: "my-thanh",
            status: "empty",
          },
          { label: "Bình An", value: 28054, slug: "binh-an", status: "empty" },
          {
            label: "Nhị Thành",
            value: 28057,
            slug: "nhi-thanh",
            status: "empty",
          },
          { label: "Mỹ An", value: 28060, slug: "my-an", status: "empty" },
          {
            label: "Bình Thạnh",
            value: 28063,
            slug: "binh-thanh",
            status: "empty",
          },
          { label: "Mỹ Phú", value: 28066, slug: "my-phu", status: "empty" },
          {
            label: "Tân Long",
            value: 28072,
            slug: "tan-long",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Trụ",
        value: 805,
        slug: "huyen-tan-tru",
        status: "empty",
        ward: [
          { label: "Tân Trụ", value: 28075, slug: "tan-tru", status: "empty" },
          {
            label: "Tân Bình",
            value: 28078,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Quê Mỹ Thạnh",
            value: 28084,
            slug: "que-my-thanh",
            status: "empty",
          },
          { label: "Lạc Tấn", value: 28087, slug: "lac-tan", status: "empty" },
          {
            label: "Bình Trinh Đông",
            value: 28090,
            slug: "binh-trinh-dong",
            status: "empty",
          },
          {
            label: "Tân Phước Tây",
            value: 28093,
            slug: "tan-phuoc-tay",
            status: "empty",
          },
          {
            label: "Bình Lãng",
            value: 28096,
            slug: "binh-lang",
            status: "empty",
          },
          {
            label: "Bình Tịnh",
            value: 28099,
            slug: "binh-tinh",
            status: "empty",
          },
          { label: "Đức Tân", value: 28102, slug: "duc-tan", status: "empty" },
          {
            label: "Nhựt Ninh",
            value: 28105,
            slug: "nhut-ninh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cần Đước",
        value: 806,
        slug: "huyen-can-duoc",
        status: "empty",
        ward: [
          {
            label: "Cần Đước",
            value: 28108,
            slug: "can-duoc",
            status: "empty",
          },
          {
            label: "Long Trạch",
            value: 28111,
            slug: "long-trach",
            status: "empty",
          },
          {
            label: "Long Khê",
            value: 28114,
            slug: "long-khe",
            status: "empty",
          },
          {
            label: "Long Định",
            value: 28117,
            slug: "long-dinh",
            status: "empty",
          },
          {
            label: "Phước Vân",
            value: 28120,
            slug: "phuoc-van",
            status: "empty",
          },
          {
            label: "Long Hòa",
            value: 28123,
            slug: "long-hoa",
            status: "empty",
          },
          {
            label: "Long Cang",
            value: 28126,
            slug: "long-cang",
            status: "empty",
          },
          {
            label: "Long Sơn",
            value: 28129,
            slug: "long-son",
            status: "empty",
          },
          {
            label: "Tân Trạch",
            value: 28132,
            slug: "tan-trach",
            status: "empty",
          },
          { label: "Mỹ Lệ", value: 28135, slug: "my-le", status: "empty" },
          { label: "Tân Lân", value: 28138, slug: "tan-lan", status: "empty" },
          {
            label: "Phước Tuy",
            value: 28141,
            slug: "phuoc-tuy",
            status: "empty",
          },
          {
            label: "Long Hựu Đông",
            value: 28144,
            slug: "long-huu-dong",
            status: "empty",
          },
          { label: "Tân Ân", value: 28147, slug: "tan-an", status: "empty" },
          {
            label: "Phước Đông",
            value: 28150,
            slug: "phuoc-dong",
            status: "empty",
          },
          {
            label: "Long Hựu Tây",
            value: 28153,
            slug: "long-huu-tay",
            status: "empty",
          },
          {
            label: "Tân Chánh",
            value: 28156,
            slug: "tan-chanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cần Giuộc",
        value: 807,
        slug: "huyen-can-giuoc",
        status: "empty",
        ward: [
          {
            label: "Cần Giuộc",
            value: 28159,
            slug: "can-giuoc",
            status: "empty",
          },
          {
            label: "Phước Lý",
            value: 28162,
            slug: "phuoc-ly",
            status: "empty",
          },
          {
            label: "Long Thượng",
            value: 28165,
            slug: "long-thuong",
            status: "empty",
          },
          {
            label: "Long Hậu",
            value: 28168,
            slug: "long-hau",
            status: "empty",
          },
          {
            label: "Phước Hậu",
            value: 28174,
            slug: "phuoc-hau",
            status: "empty",
          },
          { label: "Mỹ Lộc", value: 28177, slug: "my-loc", status: "empty" },
          {
            label: "Phước Lại",
            value: 28180,
            slug: "phuoc-lai",
            status: "empty",
          },
          {
            label: "Phước Lâm",
            value: 28183,
            slug: "phuoc-lam",
            status: "empty",
          },
          {
            label: "Thuận Thành",
            value: 28189,
            slug: "thuan-thanh",
            status: "empty",
          },
          {
            label: "Phước Vĩnh Tây",
            value: 28192,
            slug: "phuoc-vinh-tay",
            status: "empty",
          },
          {
            label: "Phước Vĩnh Đông",
            value: 28195,
            slug: "phuoc-vinh-dong",
            status: "empty",
          },
          { label: "Long An", value: 28198, slug: "long-an", status: "empty" },
          {
            label: "Long Phụng",
            value: 28201,
            slug: "long-phung",
            status: "empty",
          },
          {
            label: "Đông Thạnh",
            value: 28204,
            slug: "dong-thanh",
            status: "empty",
          },
          { label: "Tân Tập", value: 28207, slug: "tan-tap", status: "empty" },
        ],
      },
      {
        label: "Huyện Châu Thành",
        value: 808,
        slug: "huyen-chau-thanh",
        status: "empty",
        ward: [
          { label: "Tầm Vu", value: 28210, slug: "tam-vu", status: "empty" },
          {
            label: "Bình Quới",
            value: 28213,
            slug: "binh-quoi",
            status: "empty",
          },
          { label: "Hòa Phú", value: 28216, slug: "hoa-phu", status: "empty" },
          {
            label: "Phú Ngãi Trị",
            value: 28219,
            slug: "phu-ngai-tri",
            status: "empty",
          },
          {
            label: "Vĩnh Công",
            value: 28222,
            slug: "vinh-cong",
            status: "empty",
          },
          {
            label: "Thuận Mỹ",
            value: 28225,
            slug: "thuan-my",
            status: "empty",
          },
          {
            label: "Hiệp Thạnh",
            value: 28228,
            slug: "hiep-thanh",
            status: "empty",
          },
          {
            label: "Phước Tân Hưng",
            value: 28231,
            slug: "phuoc-tan-hung",
            status: "empty",
          },
          {
            label: "Thanh Phú Long",
            value: 28234,
            slug: "thanh-phu-long",
            status: "empty",
          },
          {
            label: "Dương Xuân Hội",
            value: 28237,
            slug: "duong-xuan-hoi",
            status: "empty",
          },
          {
            label: "An Lục Long",
            value: 28240,
            slug: "an-luc-long",
            status: "empty",
          },
          {
            label: "Long Trì",
            value: 28243,
            slug: "long-tri",
            status: "empty",
          },
          {
            label: "Thanh Vĩnh Đông",
            value: 28246,
            slug: "thanh-vinh-dong",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Tiền Giang",
    value: 82,
    slug: "tien-giang",
    status: "filled",
    district: [
      {
        label: "Thành phố Mỹ Tho",
        value: 815,
        slug: "thanh-pho-my-tho",
        status: "filled",
        ward: [
          {
            label: "Phường 5",
            value: 28249,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 28252,
            slug: "phuong-4",
            status: "filled",
          },
          {
            label: "Phường 1",
            value: 28261,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 28264,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 6",
            value: 28270,
            slug: "phuong-6",
            status: "empty",
          },
          {
            label: "Phường 9",
            value: 28273,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Phường 10",
            value: 28276,
            slug: "phuong-10",
            status: "empty",
          },
          {
            label: "Tân Long",
            value: 28279,
            slug: "tan-long",
            status: "empty",
          },
          {
            label: "Đạo Thạnh",
            value: 28282,
            slug: "dao-thanh",
            status: "empty",
          },
          {
            label: "Trung An",
            value: 28285,
            slug: "trung-an",
            status: "empty",
          },
          {
            label: "Mỹ Phong",
            value: 28288,
            slug: "my-phong",
            status: "empty",
          },
          {
            label: "Tân Mỹ Chánh",
            value: 28291,
            slug: "tan-my-chanh",
            status: "empty",
          },
          {
            label: "Phước Thạnh",
            value: 28567,
            slug: "phuoc-thanh",
            status: "empty",
          },
          {
            label: "Thới Sơn",
            value: 28591,
            slug: "thoi-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Gò Công",
        value: 816,
        slug: "thi-xa-go-cong",
        status: "empty",
        ward: [
          {
            label: "Phường 2",
            value: 28297,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 28300,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 28306,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Long Hưng",
            value: 28309,
            slug: "long-hung",
            status: "empty",
          },
          {
            label: "Long Thuận",
            value: 28312,
            slug: "long-thuan",
            status: "empty",
          },
          {
            label: "Long Chánh",
            value: 28315,
            slug: "long-chanh",
            status: "empty",
          },
          {
            label: "Long Hòa",
            value: 28318,
            slug: "long-hoa",
            status: "empty",
          },
          {
            label: "Bình Đông",
            value: 28708,
            slug: "binh-dong",
            status: "empty",
          },
          {
            label: "Bình Xuân",
            value: 28717,
            slug: "binh-xuan",
            status: "empty",
          },
          {
            label: "Tân Trung",
            value: 28729,
            slug: "tan-trung",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Cai Lậy",
        value: 817,
        slug: "thi-xa-cai-lay",
        status: "empty",
        ward: [
          {
            label: "Phường 1",
            value: 28435,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 28436,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 28437,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 28439,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 28440,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Mỹ Phước Tây",
            value: 28447,
            slug: "my-phuoc-tay",
            status: "empty",
          },
          {
            label: "Mỹ Hạnh Đông",
            value: 28450,
            slug: "my-hanh-dong",
            status: "empty",
          },
          {
            label: "Mỹ Hạnh Trung",
            value: 28453,
            slug: "my-hanh-trung",
            status: "empty",
          },
          { label: "Tân Phú", value: 28459, slug: "tan-phu", status: "empty" },
          {
            label: "Tân Bình",
            value: 28462,
            slug: "tan-binh",
            status: "empty",
          },
          { label: "Tân Hội", value: 28468, slug: "tan-hoi", status: "empty" },
          { label: "Nhị Mỹ", value: 28474, slug: "nhi-my", status: "empty" },
          { label: "Nhị Quý", value: 28477, slug: "nhi-quy", status: "empty" },
          {
            label: "Thanh Hòa",
            value: 28480,
            slug: "thanh-hoa",
            status: "empty",
          },
          { label: "Phú Quý", value: 28483, slug: "phu-quy", status: "empty" },
          {
            label: "Long Khánh",
            value: 28486,
            slug: "long-khanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Phước",
        value: 818,
        slug: "huyen-tan-phuoc",
        status: "empty",
        ward: [
          {
            label: "Mỹ Phước",
            value: 28321,
            slug: "my-phuoc",
            status: "empty",
          },
          {
            label: "Tân Hòa Đông",
            value: 28324,
            slug: "tan-hoa-dong",
            status: "empty",
          },
          {
            label: "Thạnh Tân",
            value: 28327,
            slug: "thanh-tan",
            status: "empty",
          },
          {
            label: "Thạnh Mỹ",
            value: 28330,
            slug: "thanh-my",
            status: "empty",
          },
          {
            label: "Thạnh Hoà",
            value: 28333,
            slug: "thanh-hoa",
            status: "empty",
          },
          { label: "Phú Mỹ", value: 28336, slug: "phu-my", status: "empty" },
          {
            label: "Tân Hòa Thành",
            value: 28339,
            slug: "tan-hoa-thanh",
            status: "empty",
          },
          {
            label: "Hưng Thạnh",
            value: 28342,
            slug: "hung-thanh",
            status: "empty",
          },
          {
            label: "Tân Lập 1",
            value: 28345,
            slug: "tan-lap-1",
            status: "empty",
          },
          {
            label: "Tân Hòa Tây",
            value: 28348,
            slug: "tan-hoa-tay",
            status: "empty",
          },
          {
            label: "Tân Lập 2",
            value: 28354,
            slug: "tan-lap-2",
            status: "empty",
          },
          {
            label: "Phước Lập",
            value: 28357,
            slug: "phuoc-lap",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cái Bè",
        value: 819,
        slug: "huyen-cai-be",
        status: "empty",
        ward: [
          { label: "Cái Bè", value: 28360, slug: "cai-be", status: "empty" },
          {
            label: "Hậu Mỹ Bắc B",
            value: 28363,
            slug: "hau-my-bac-b",
            status: "empty",
          },
          {
            label: "Hậu Mỹ Bắc A",
            value: 28366,
            slug: "hau-my-bac-a",
            status: "empty",
          },
          {
            label: "Mỹ Trung",
            value: 28369,
            slug: "my-trung",
            status: "empty",
          },
          {
            label: "Hậu Mỹ Trinh",
            value: 28372,
            slug: "hau-my-trinh",
            status: "empty",
          },
          {
            label: "Hậu Mỹ Phú",
            value: 28375,
            slug: "hau-my-phu",
            status: "empty",
          },
          { label: "Mỹ Tân", value: 28378, slug: "my-tan", status: "empty" },
          {
            label: "Mỹ Lợi B",
            value: 28381,
            slug: "my-loi-b",
            status: "empty",
          },
          {
            label: "Thiện Trung",
            value: 28384,
            slug: "thien-trung",
            status: "empty",
          },
          { label: "Mỹ Hội", value: 28387, slug: "my-hoi", status: "empty" },
          { label: "An Cư", value: 28390, slug: "an-cu", status: "empty" },
          {
            label: "Hậu Thành",
            value: 28393,
            slug: "hau-thanh",
            status: "empty",
          },
          {
            label: "Mỹ Lợi A",
            value: 28396,
            slug: "my-loi-a",
            status: "empty",
          },
          {
            label: "Hòa Khánh",
            value: 28399,
            slug: "hoa-khanh",
            status: "empty",
          },
          {
            label: "Thiện Trí",
            value: 28402,
            slug: "thien-tri",
            status: "empty",
          },
          {
            label: "Mỹ Đức Đông",
            value: 28405,
            slug: "my-duc-dong",
            status: "empty",
          },
          {
            label: "Mỹ Đức Tây",
            value: 28408,
            slug: "my-duc-tay",
            status: "empty",
          },
          {
            label: "Đông Hòa Hiệp",
            value: 28411,
            slug: "dong-hoa-hiep",
            status: "empty",
          },
          {
            label: "An Thái Đông",
            value: 28414,
            slug: "an-thai-dong",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 28417,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "Mỹ Lương",
            value: 28420,
            slug: "my-luong",
            status: "empty",
          },
          {
            label: "Tân Thanh",
            value: 28423,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "An Thái Trung",
            value: 28426,
            slug: "an-thai-trung",
            status: "empty",
          },
          { label: "An Hữu", value: 28429, slug: "an-huu", status: "empty" },
          {
            label: "Hòa Hưng",
            value: 28432,
            slug: "hoa-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cai Lậy",
        value: 820,
        slug: "huyen-cai-lay",
        status: "empty",
        ward: [
          {
            label: "Thạnh Lộc",
            value: 28438,
            slug: "thanh-loc",
            status: "empty",
          },
          {
            label: "Mỹ Thành Bắc",
            value: 28441,
            slug: "my-thanh-bac",
            status: "empty",
          },
          {
            label: "Phú Cường",
            value: 28444,
            slug: "phu-cuong",
            status: "empty",
          },
          {
            label: "Mỹ Thành Nam",
            value: 28456,
            slug: "my-thanh-nam",
            status: "empty",
          },
          {
            label: "Phú Nhuận",
            value: 28465,
            slug: "phu-nhuan",
            status: "empty",
          },
          {
            label: "Bình Phú",
            value: 28471,
            slug: "binh-phu",
            status: "empty",
          },
          { label: "Cẩm Sơn", value: 28489, slug: "cam-son", status: "empty" },
          { label: "Phú An", value: 28492, slug: "phu-an", status: "empty" },
          { label: "Mỹ Long", value: 28495, slug: "my-long", status: "empty" },
          {
            label: "Long Tiên",
            value: 28498,
            slug: "long-tien",
            status: "empty",
          },
          {
            label: "Hiệp Đức",
            value: 28501,
            slug: "hiep-duc",
            status: "empty",
          },
          {
            label: "Long Trung",
            value: 28504,
            slug: "long-trung",
            status: "empty",
          },
          {
            label: "Hội Xuân",
            value: 28507,
            slug: "hoi-xuan",
            status: "empty",
          },
          {
            label: "Tân Phong",
            value: 28510,
            slug: "tan-phong",
            status: "empty",
          },
          {
            label: "Tam Bình",
            value: 28513,
            slug: "tam-binh",
            status: "empty",
          },
          {
            label: "Ngũ Hiệp",
            value: 28516,
            slug: "ngu-hiep",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Châu Thành",
        value: 821,
        slug: "huyen-chau-thanh",
        status: "filled",
        ward: [
          {
            label: "Tân Hiệp",
            value: 28519,
            slug: "tan-hiep",
            status: "filled",
          },
          {
            label: "Tân Hội Đông",
            value: 28522,
            slug: "tan-hoi-dong",
            status: "empty",
          },
          {
            label: "Tân Hương",
            value: 28525,
            slug: "tan-huong",
            status: "empty",
          },
          {
            label: "Tân Lý Đông",
            value: 28528,
            slug: "tan-ly-dong",
            status: "empty",
          },
          {
            label: "Thân Cửu Nghĩa",
            value: 28534,
            slug: "than-cuu-nghia",
            status: "empty",
          },
          {
            label: "Tam Hiệp",
            value: 28537,
            slug: "tam-hiep",
            status: "empty",
          },
          { label: "Điềm Hy", value: 28540, slug: "diem-hy", status: "empty" },
          {
            label: "Nhị Bình",
            value: 28543,
            slug: "nhi-binh",
            status: "empty",
          },
          {
            label: "Đông Hòa",
            value: 28549,
            slug: "dong-hoa",
            status: "empty",
          },
          {
            label: "Long Định",
            value: 28552,
            slug: "long-dinh",
            status: "empty",
          },
          { label: "Long An", value: 28558, slug: "long-an", status: "empty" },
          {
            label: "Long Hưng",
            value: 28561,
            slug: "long-hung",
            status: "empty",
          },
          {
            label: "Bình Trưng",
            value: 28564,
            slug: "binh-trung",
            status: "empty",
          },
          {
            label: "Thạnh Phú",
            value: 28570,
            slug: "thanh-phu",
            status: "empty",
          },
          {
            label: "Bàn Long",
            value: 28573,
            slug: "ban-long",
            status: "empty",
          },
          {
            label: "Vĩnh Kim",
            value: 28576,
            slug: "vinh-kim",
            status: "empty",
          },
          {
            label: "Bình Đức",
            value: 28579,
            slug: "binh-duc",
            status: "empty",
          },
          {
            label: "Song Thuận",
            value: 28582,
            slug: "song-thuan",
            status: "empty",
          },
          { label: "Kim Sơn", value: 28585, slug: "kim-son", status: "empty" },
          {
            label: "Phú Phong",
            value: 28588,
            slug: "phu-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Chợ Gạo",
        value: 822,
        slug: "huyen-cho-gao",
        status: "empty",
        ward: [
          { label: "Chợ Gạo", value: 28594, slug: "cho-gao", status: "empty" },
          {
            label: "Trung Hòa",
            value: 28597,
            slug: "trung-hoa",
            status: "empty",
          },
          {
            label: "Hòa Tịnh",
            value: 28600,
            slug: "hoa-tinh",
            status: "empty",
          },
          {
            label: "Mỹ Tịnh An",
            value: 28603,
            slug: "my-tinh-an",
            status: "empty",
          },
          {
            label: "Tân Bình Thạnh",
            value: 28606,
            slug: "tan-binh-thanh",
            status: "empty",
          },
          {
            label: "Phú Kiết",
            value: 28609,
            slug: "phu-kiet",
            status: "empty",
          },
          {
            label: "Lương Hòa Lạc",
            value: 28612,
            slug: "luong-hoa-lac",
            status: "empty",
          },
          {
            label: "Thanh Bình",
            value: 28615,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Quơn Long",
            value: 28618,
            slug: "quon-long",
            status: "empty",
          },
          {
            label: "Bình Phục Nhứt",
            value: 28621,
            slug: "binh-phuc-nhut",
            status: "empty",
          },
          {
            label: "Đăng Hưng Phước",
            value: 28624,
            slug: "dang-hung-phuoc",
            status: "empty",
          },
          {
            label: "Tân Thuận Bình",
            value: 28627,
            slug: "tan-thuan-binh",
            status: "empty",
          },
          {
            label: "Song Bình",
            value: 28630,
            slug: "song-binh",
            status: "empty",
          },
          {
            label: "Bình Phan",
            value: 28633,
            slug: "binh-phan",
            status: "empty",
          },
          {
            label: "Long Bình Điền",
            value: 28636,
            slug: "long-binh-dien",
            status: "empty",
          },
          {
            label: "An Thạnh Thủy",
            value: 28639,
            slug: "an-thanh-thuy",
            status: "empty",
          },
          {
            label: "Xuân Đông",
            value: 28642,
            slug: "xuan-dong",
            status: "empty",
          },
          {
            label: "Hòa Định",
            value: 28645,
            slug: "hoa-dinh",
            status: "empty",
          },
          {
            label: "Bình Ninh",
            value: 28648,
            slug: "binh-ninh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Gò Công Tây",
        value: 823,
        slug: "huyen-go-cong-tay",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Bình",
            value: 28651,
            slug: "vinh-binh",
            status: "empty",
          },
          {
            label: "Đồng Sơn",
            value: 28654,
            slug: "dong-son",
            status: "empty",
          },
          {
            label: "Bình Phú",
            value: 28657,
            slug: "binh-phu",
            status: "empty",
          },
          {
            label: "Đồng Thạnh",
            value: 28660,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Thành Công",
            value: 28663,
            slug: "thanh-cong",
            status: "empty",
          },
          {
            label: "Bình Nhì",
            value: 28666,
            slug: "binh-nhi",
            status: "empty",
          },
          {
            label: "Yên Luông",
            value: 28669,
            slug: "yen-luong",
            status: "empty",
          },
          {
            label: "Thạnh Trị",
            value: 28672,
            slug: "thanh-tri",
            status: "empty",
          },
          {
            label: "Thạnh Nhựt",
            value: 28675,
            slug: "thanh-nhut",
            status: "empty",
          },
          {
            label: "Long Vĩnh",
            value: 28678,
            slug: "long-vinh",
            status: "empty",
          },
          {
            label: "Bình Tân",
            value: 28681,
            slug: "binh-tan",
            status: "empty",
          },
          {
            label: "Vĩnh Hựu",
            value: 28684,
            slug: "vinh-huu",
            status: "empty",
          },
          {
            label: "Long Bình",
            value: 28687,
            slug: "long-binh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Gò Công Đông",
        value: 824,
        slug: "huyen-go-cong-dong",
        status: "empty",
        ward: [
          { label: "Tân Hòa", value: 28702, slug: "tan-hoa", status: "empty" },
          {
            label: "Tăng Hoà",
            value: 28705,
            slug: "tang-hoa",
            status: "empty",
          },
          {
            label: "Tân Phước",
            value: 28711,
            slug: "tan-phuoc",
            status: "empty",
          },
          {
            label: "Gia Thuận",
            value: 28714,
            slug: "gia-thuan",
            status: "empty",
          },
          {
            label: "Vàm Láng",
            value: 28720,
            slug: "vam-lang",
            status: "empty",
          },
          { label: "Tân Tây", value: 28723, slug: "tan-tay", status: "empty" },
          {
            label: "Kiểng Phước",
            value: 28726,
            slug: "kieng-phuoc",
            status: "empty",
          },
          {
            label: "Tân Đông",
            value: 28732,
            slug: "tan-dong",
            status: "empty",
          },
          { label: "Bình Ân", value: 28735, slug: "binh-an", status: "empty" },
          {
            label: "Tân Điền",
            value: 28738,
            slug: "tan-dien",
            status: "empty",
          },
          {
            label: "Bình Nghị",
            value: 28741,
            slug: "binh-nghi",
            status: "empty",
          },
          {
            label: "Phước Trung",
            value: 28744,
            slug: "phuoc-trung",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 28747,
            slug: "tan-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Phú Đông",
        value: 825,
        slug: "huyen-tan-phu-dong",
        status: "empty",
        ward: [
          {
            label: "Tân Thới",
            value: 28690,
            slug: "tan-thoi",
            status: "empty",
          },
          { label: "Tân Phú", value: 28693, slug: "tan-phu", status: "empty" },
          {
            label: "Phú Thạnh",
            value: 28696,
            slug: "phu-thanh",
            status: "empty",
          },
          {
            label: "Tân Thạnh",
            value: 28699,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Phú Đông",
            value: 28750,
            slug: "phu-dong",
            status: "empty",
          },
          { label: "Phú Tân", value: 28753, slug: "phu-tan", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Bến Tre",
    value: 83,
    slug: "ben-tre",
    status: "filled",
    district: [
      {
        label: "Thành phố Bến Tre",
        value: 829,
        slug: "thanh-pho-ben-tre",
        status: "filled",
        ward: [
          {
            label: "Phú Khương",
            value: 28756,
            slug: "phu-khuong",
            status: "empty",
          },
          { label: "Phú Tân", value: 28757, slug: "phu-tan", status: "filled" },
          {
            label: "Phường 8",
            value: 28759,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Phường 6",
            value: 28762,
            slug: "phuong-6",
            status: "empty",
          },
          { label: "An Hội", value: 28777, slug: "an-hoi", status: "filled" },
          {
            label: "Phường 7",
            value: 28780,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Sơn Đông",
            value: 28783,
            slug: "son-dong",
            status: "empty",
          },
          {
            label: "Phú Hưng",
            value: 28786,
            slug: "phu-hung",
            status: "empty",
          },
          {
            label: "Bình Phú",
            value: 28789,
            slug: "binh-phu",
            status: "empty",
          },
          {
            label: "Mỹ Thạnh An",
            value: 28792,
            slug: "my-thanh-an",
            status: "empty",
          },
          {
            label: "Nhơn Thạnh",
            value: 28795,
            slug: "nhon-thanh",
            status: "empty",
          },
          {
            label: "Phú Nhuận",
            value: 28798,
            slug: "phu-nhuan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Châu Thành",
        value: 831,
        slug: "huyen-chau-thanh",
        status: "filled",
        ward: [
          {
            label: "Tân Thạch",
            value: 28804,
            slug: "tan-thach",
            status: "empty",
          },
          {
            label: "Qưới Sơn",
            value: 28807,
            slug: "quoi-son",
            status: "filled",
          },
          {
            label: "Châu Thành",
            value: 28810,
            slug: "chau-thanh",
            status: "empty",
          },
          {
            label: "Giao Long",
            value: 28813,
            slug: "giao-long",
            status: "empty",
          },
          { label: "Phú Túc", value: 28819, slug: "phu-tuc", status: "empty" },
          { label: "Phú Đức", value: 28822, slug: "phu-duc", status: "empty" },
          {
            label: "An Phước",
            value: 28828,
            slug: "an-phuoc",
            status: "empty",
          },
          {
            label: "Tam Phước",
            value: 28831,
            slug: "tam-phuoc",
            status: "empty",
          },
          {
            label: "Thành Triệu",
            value: 28834,
            slug: "thanh-trieu",
            status: "empty",
          },
          { label: "Tân Phú", value: 28840, slug: "tan-phu", status: "empty" },
          {
            label: "Quới Thành",
            value: 28843,
            slug: "quoi-thanh",
            status: "empty",
          },
          {
            label: "Phước Thạnh",
            value: 28846,
            slug: "phuoc-thanh",
            status: "empty",
          },
          {
            label: "Tiên Long",
            value: 28852,
            slug: "tien-long",
            status: "empty",
          },
          {
            label: "Tường Đa",
            value: 28855,
            slug: "tuong-da",
            status: "empty",
          },
          {
            label: "Hữu Định",
            value: 28858,
            slug: "huu-dinh",
            status: "empty",
          },
          {
            label: "Tiên Thủy",
            value: 28861,
            slug: "tien-thuy",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Chợ Lách",
        value: 832,
        slug: "huyen-cho-lach",
        status: "empty",
        ward: [
          {
            label: "Chợ Lách",
            value: 28870,
            slug: "cho-lach",
            status: "empty",
          },
          {
            label: "Phú Phụng",
            value: 28873,
            slug: "phu-phung",
            status: "empty",
          },
          {
            label: "Sơn Định",
            value: 28876,
            slug: "son-dinh",
            status: "empty",
          },
          {
            label: "Vĩnh Bình",
            value: 28879,
            slug: "vinh-binh",
            status: "empty",
          },
          {
            label: "Hòa Nghĩa",
            value: 28882,
            slug: "hoa-nghia",
            status: "empty",
          },
          {
            label: "Long Thới",
            value: 28885,
            slug: "long-thoi",
            status: "empty",
          },
          { label: "Phú Sơn", value: 28888, slug: "phu-son", status: "empty" },
          {
            label: "Tân Thiềng",
            value: 28891,
            slug: "tan-thieng",
            status: "empty",
          },
          {
            label: "Vĩnh Thành",
            value: 28894,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Hòa",
            value: 28897,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Hưng Khánh Trung B",
            value: 28900,
            slug: "hung-khanh-trung-b",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Mỏ Cày Nam",
        value: 833,
        slug: "huyen-mo-cay-nam",
        status: "empty",
        ward: [
          { label: "Mỏ Cày", value: 28903, slug: "mo-cay", status: "empty" },
          {
            label: "Định Thủy",
            value: 28930,
            slug: "dinh-thuy",
            status: "empty",
          },
          {
            label: "Đa Phước Hội",
            value: 28939,
            slug: "da-phuoc-hoi",
            status: "empty",
          },
          { label: "Tân Hội", value: 28940, slug: "tan-hoi", status: "empty" },
          {
            label: "Phước Hiệp",
            value: 28942,
            slug: "phuoc-hiep",
            status: "empty",
          },
          {
            label: "Bình Khánh",
            value: 28945,
            slug: "binh-khanh",
            status: "empty",
          },
          {
            label: "An Thạnh",
            value: 28951,
            slug: "an-thanh",
            status: "empty",
          },
          { label: "An Định", value: 28957, slug: "an-dinh", status: "empty" },
          {
            label: "Thành Thới B",
            value: 28960,
            slug: "thanh-thoi-b",
            status: "empty",
          },
          {
            label: "Tân Trung",
            value: 28963,
            slug: "tan-trung",
            status: "empty",
          },
          { label: "An Thới", value: 28966, slug: "an-thoi", status: "empty" },
          {
            label: "Thành Thới A",
            value: 28969,
            slug: "thanh-thoi-a",
            status: "empty",
          },
          {
            label: "Minh Đức",
            value: 28972,
            slug: "minh-duc",
            status: "empty",
          },
          {
            label: "Ngãi Đăng",
            value: 28975,
            slug: "ngai-dang",
            status: "empty",
          },
          { label: "Cẩm Sơn", value: 28978, slug: "cam-son", status: "empty" },
          {
            label: "Hương Mỹ",
            value: 28981,
            slug: "huong-my",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Giồng Trôm",
        value: 834,
        slug: "huyen-giong-trom",
        status: "empty",
        ward: [
          {
            label: "Giồng Trôm",
            value: 28984,
            slug: "giong-trom",
            status: "empty",
          },
          {
            label: "Phong Nẫm",
            value: 28987,
            slug: "phong-nam",
            status: "empty",
          },
          {
            label: "Mỹ Thạnh",
            value: 28993,
            slug: "my-thanh",
            status: "empty",
          },
          {
            label: "Châu Hòa",
            value: 28996,
            slug: "chau-hoa",
            status: "empty",
          },
          {
            label: "Lương Hòa",
            value: 28999,
            slug: "luong-hoa",
            status: "empty",
          },
          {
            label: "Lương Quới",
            value: 29002,
            slug: "luong-quoi",
            status: "empty",
          },
          {
            label: "Lương Phú",
            value: 29005,
            slug: "luong-phu",
            status: "empty",
          },
          {
            label: "Châu Bình",
            value: 29008,
            slug: "chau-binh",
            status: "empty",
          },
          {
            label: "Thuận Điền",
            value: 29011,
            slug: "thuan-dien",
            status: "empty",
          },
          { label: "Sơn Phú", value: 29014, slug: "son-phu", status: "empty" },
          {
            label: "Bình Hoà",
            value: 29017,
            slug: "binh-hoa",
            status: "empty",
          },
          {
            label: "Phước Long",
            value: 29020,
            slug: "phuoc-long",
            status: "empty",
          },
          {
            label: "Hưng Phong",
            value: 29023,
            slug: "hung-phong",
            status: "empty",
          },
          { label: "Long Mỹ", value: 29026, slug: "long-my", status: "empty" },
          { label: "Tân Hào", value: 29029, slug: "tan-hao", status: "empty" },
          {
            label: "Bình Thành",
            value: 29032,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Tân Thanh",
            value: 29035,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Tân Lợi Thạnh",
            value: 29038,
            slug: "tan-loi-thanh",
            status: "empty",
          },
          {
            label: "Thạnh Phú Đông",
            value: 29041,
            slug: "thanh-phu-dong",
            status: "empty",
          },
          {
            label: "Hưng Nhượng",
            value: 29044,
            slug: "hung-nhuong",
            status: "empty",
          },
          { label: "Hưng Lễ", value: 29047, slug: "hung-le", status: "empty" },
        ],
      },
      {
        label: "Huyện Bình Đại",
        value: 835,
        slug: "huyen-binh-dai",
        status: "empty",
        ward: [
          {
            label: "Bình Đại",
            value: 29050,
            slug: "binh-dai",
            status: "empty",
          },
          {
            label: "Tam Hiệp",
            value: 29053,
            slug: "tam-hiep",
            status: "empty",
          },
          {
            label: "Long Định",
            value: 29056,
            slug: "long-dinh",
            status: "empty",
          },
          {
            label: "Long Hòa",
            value: 29059,
            slug: "long-hoa",
            status: "empty",
          },
          {
            label: "Phú Thuận",
            value: 29062,
            slug: "phu-thuan",
            status: "empty",
          },
          {
            label: "Vang Quới Tây",
            value: 29065,
            slug: "vang-quoi-tay",
            status: "empty",
          },
          {
            label: "Vang Quới Đông",
            value: 29068,
            slug: "vang-quoi-dong",
            status: "empty",
          },
          {
            label: "Châu Hưng",
            value: 29071,
            slug: "chau-hung",
            status: "empty",
          },
          {
            label: "Lộc Thuận",
            value: 29077,
            slug: "loc-thuan",
            status: "empty",
          },
          {
            label: "Định Trung",
            value: 29080,
            slug: "dinh-trung",
            status: "empty",
          },
          {
            label: "Thới Lai",
            value: 29083,
            slug: "thoi-lai",
            status: "empty",
          },
          {
            label: "Bình Thới",
            value: 29086,
            slug: "binh-thoi",
            status: "empty",
          },
          {
            label: "Phú Long",
            value: 29089,
            slug: "phu-long",
            status: "empty",
          },
          {
            label: "Bình Thắng",
            value: 29092,
            slug: "binh-thang",
            status: "empty",
          },
          {
            label: "Thạnh Trị",
            value: 29095,
            slug: "thanh-tri",
            status: "empty",
          },
          {
            label: "Đại Hòa Lộc",
            value: 29098,
            slug: "dai-hoa-loc",
            status: "empty",
          },
          {
            label: "Thừa Đức",
            value: 29101,
            slug: "thua-duc",
            status: "empty",
          },
          {
            label: "Thạnh Phước",
            value: 29104,
            slug: "thanh-phuoc",
            status: "empty",
          },
          {
            label: "Thới Thuận",
            value: 29107,
            slug: "thoi-thuan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ba Tri",
        value: 836,
        slug: "huyen-ba-tri",
        status: "empty",
        ward: [
          { label: "Ba Tri", value: 29110, slug: "ba-tri", status: "empty" },
          { label: "Mỹ Hòa", value: 29116, slug: "my-hoa", status: "empty" },
          {
            label: "Tân Xuân",
            value: 29119,
            slug: "tan-xuan",
            status: "empty",
          },
          {
            label: "Mỹ Chánh",
            value: 29122,
            slug: "my-chanh",
            status: "empty",
          },
          {
            label: "Bảo Thạnh",
            value: 29125,
            slug: "bao-thanh",
            status: "empty",
          },
          {
            label: "An Phú Trung",
            value: 29128,
            slug: "an-phu-trung",
            status: "empty",
          },
          {
            label: "Mỹ Thạnh",
            value: 29131,
            slug: "my-thanh",
            status: "empty",
          },
          { label: "Mỹ Nhơn", value: 29134, slug: "my-nhon", status: "empty" },
          {
            label: "Phước Ngãi",
            value: 29137,
            slug: "phuoc-ngai",
            status: "empty",
          },
          {
            label: "An Ngãi Trung",
            value: 29143,
            slug: "an-ngai-trung",
            status: "empty",
          },
          { label: "Phú Lễ", value: 29146, slug: "phu-le", status: "empty" },
          {
            label: "An Bình Tây",
            value: 29149,
            slug: "an-binh-tay",
            status: "empty",
          },
          {
            label: "Bảo Thuận",
            value: 29152,
            slug: "bao-thuan",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 29155,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "An Ngãi Tây",
            value: 29158,
            slug: "an-ngai-tay",
            status: "empty",
          },
          { label: "An Hiệp", value: 29161, slug: "an-hiep", status: "empty" },
          {
            label: "Vĩnh Hòa",
            value: 29164,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Tân Thủy",
            value: 29167,
            slug: "tan-thuy",
            status: "empty",
          },
          { label: "Vĩnh An", value: 29170, slug: "vinh-an", status: "empty" },
          { label: "An Đức", value: 29173, slug: "an-duc", status: "empty" },
          {
            label: "An Hòa Tây",
            value: 29176,
            slug: "an-hoa-tay",
            status: "empty",
          },
          {
            label: "Tiệm Tôm",
            value: 29179,
            slug: "tiem-tom",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thạnh Phú",
        value: 837,
        slug: "huyen-thanh-phu",
        status: "empty",
        ward: [
          {
            label: "Thạnh Phú",
            value: 29182,
            slug: "thanh-phu",
            status: "empty",
          },
          {
            label: "Phú Khánh",
            value: 29185,
            slug: "phu-khanh",
            status: "empty",
          },
          {
            label: "Đại Điền",
            value: 29188,
            slug: "dai-dien",
            status: "empty",
          },
          {
            label: "Quới Điền",
            value: 29191,
            slug: "quoi-dien",
            status: "empty",
          },
          {
            label: "Tân Phong",
            value: 29194,
            slug: "tan-phong",
            status: "empty",
          },
          { label: "Mỹ Hưng", value: 29197, slug: "my-hung", status: "empty" },
          {
            label: "An Thạnh",
            value: 29200,
            slug: "an-thanh",
            status: "empty",
          },
          {
            label: "Thới Thạnh",
            value: 29203,
            slug: "thoi-thanh",
            status: "empty",
          },
          { label: "Hòa Lợi", value: 29206, slug: "hoa-loi", status: "empty" },
          { label: "An Điền", value: 29209, slug: "an-dien", status: "empty" },
          {
            label: "Bình Thạnh",
            value: 29212,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "An Thuận",
            value: 29215,
            slug: "an-thuan",
            status: "empty",
          },
          { label: "An Quy", value: 29218, slug: "an-quy", status: "empty" },
          {
            label: "Thạnh Hải",
            value: 29221,
            slug: "thanh-hai",
            status: "empty",
          },
          { label: "An Nhơn", value: 29224, slug: "an-nhon", status: "empty" },
          {
            label: "Giao Thạnh",
            value: 29227,
            slug: "giao-thanh",
            status: "empty",
          },
          {
            label: "Thạnh Phong",
            value: 29230,
            slug: "thanh-phong",
            status: "empty",
          },
          { label: "Mỹ An", value: 29233, slug: "my-an", status: "empty" },
        ],
      },
      {
        label: "Huyện Mỏ Cày Bắc",
        value: 838,
        slug: "huyen-mo-cay-bac",
        status: "empty",
        ward: [
          { label: "Phú Mỹ", value: 28889, slug: "phu-my", status: "empty" },
          {
            label: "Hưng Khánh Trung A",
            value: 28901,
            slug: "hung-khanh-trung-a",
            status: "empty",
          },
          {
            label: "Thanh Tân",
            value: 28906,
            slug: "thanh-tan",
            status: "empty",
          },
          {
            label: "Thạnh Ngãi",
            value: 28909,
            slug: "thanh-ngai",
            status: "empty",
          },
          {
            label: "Tân Phú Tây",
            value: 28912,
            slug: "tan-phu-tay",
            status: "empty",
          },
          {
            label: "Phước Mỹ Trung",
            value: 28915,
            slug: "phuoc-my-trung",
            status: "empty",
          },
          {
            label: "Tân Thành Bình",
            value: 28918,
            slug: "tan-thanh-binh",
            status: "empty",
          },
          {
            label: "Thành An",
            value: 28921,
            slug: "thanh-an",
            status: "empty",
          },
          { label: "Hòa Lộc", value: 28924, slug: "hoa-loc", status: "empty" },
          {
            label: "Tân Thanh Tây",
            value: 28927,
            slug: "tan-thanh-tay",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 28933,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Nhuận Phú Tân",
            value: 28936,
            slug: "nhuan-phu-tan",
            status: "empty",
          },
          {
            label: "Khánh Thạnh Tân",
            value: 28948,
            slug: "khanh-thanh-tan",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Trà Vinh",
    value: 84,
    slug: "tra-vinh",
    status: "filled",
    district: [
      {
        label: "Thành phố Trà Vinh",
        value: 842,
        slug: "thanh-pho-tra-vinh",
        status: "filled",
        ward: [
          {
            label: "Phường 4",
            value: 29236,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 29239,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 29242,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 29248,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 29254,
            slug: "phuong-7",
            status: "filled",
          },
          {
            label: "Phường 8",
            value: 29257,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Phường 9",
            value: 29260,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Long Đức",
            value: 29263,
            slug: "long-duc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Càng Long",
        value: 844,
        slug: "huyen-cang-long",
        status: "empty",
        ward: [
          {
            label: "Càng Long",
            value: 29266,
            slug: "cang-long",
            status: "empty",
          },
          { label: "Mỹ Cẩm", value: 29269, slug: "my-cam", status: "empty" },
          {
            label: "An Trường A",
            value: 29272,
            slug: "an-truong-a",
            status: "empty",
          },
          {
            label: "An Trường",
            value: 29275,
            slug: "an-truong",
            status: "empty",
          },
          {
            label: "Huyền Hội",
            value: 29278,
            slug: "huyen-hoi",
            status: "empty",
          },
          { label: "Tân An", value: 29281, slug: "tan-an", status: "empty" },
          {
            label: "Tân Bình",
            value: 29284,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Bình Phú",
            value: 29287,
            slug: "binh-phu",
            status: "empty",
          },
          {
            label: "Phương Thạnh",
            value: 29290,
            slug: "phuong-thanh",
            status: "empty",
          },
          {
            label: "Đại Phúc",
            value: 29293,
            slug: "dai-phuc",
            status: "empty",
          },
          {
            label: "Đại Phước",
            value: 29296,
            slug: "dai-phuoc",
            status: "empty",
          },
          {
            label: "Nhị Long Phú",
            value: 29299,
            slug: "nhi-long-phu",
            status: "empty",
          },
          {
            label: "Nhị Long",
            value: 29302,
            slug: "nhi-long",
            status: "empty",
          },
          { label: "Đức Mỹ", value: 29305, slug: "duc-my", status: "empty" },
        ],
      },
      {
        label: "Huyện Cầu Kè",
        value: 845,
        slug: "huyen-cau-ke",
        status: "empty",
        ward: [
          { label: "Cầu Kè", value: 29308, slug: "cau-ke", status: "empty" },
          { label: "Hòa Ân", value: 29311, slug: "hoa-an", status: "empty" },
          {
            label: "Châu Điền",
            value: 29314,
            slug: "chau-dien",
            status: "empty",
          },
          {
            label: "An Phú Tân",
            value: 29317,
            slug: "an-phu-tan",
            status: "empty",
          },
          { label: "Hoà Tân", value: 29320, slug: "hoa-tan", status: "empty" },
          {
            label: "Ninh Thới",
            value: 29323,
            slug: "ninh-thoi",
            status: "empty",
          },
          {
            label: "Phong Phú",
            value: 29326,
            slug: "phong-phu",
            status: "empty",
          },
          {
            label: "Phong Thạnh",
            value: 29329,
            slug: "phong-thanh",
            status: "empty",
          },
          {
            label: "Tam Ngãi",
            value: 29332,
            slug: "tam-ngai",
            status: "empty",
          },
          {
            label: "Thông Hòa",
            value: 29335,
            slug: "thong-hoa",
            status: "empty",
          },
          {
            label: "Thạnh Phú",
            value: 29338,
            slug: "thanh-phu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tiểu Cần",
        value: 846,
        slug: "huyen-tieu-can",
        status: "empty",
        ward: [
          {
            label: "Tiểu Cần",
            value: 29341,
            slug: "tieu-can",
            status: "empty",
          },
          {
            label: "Cầu Quan",
            value: 29344,
            slug: "cau-quan",
            status: "empty",
          },
          { label: "Phú Cần", value: 29347, slug: "phu-can", status: "empty" },
          { label: "Hiếu Tử", value: 29350, slug: "hieu-tu", status: "empty" },
          {
            label: "Hiếu Trung",
            value: 29353,
            slug: "hieu-trung",
            status: "empty",
          },
          {
            label: "Long Thới",
            value: 29356,
            slug: "long-thoi",
            status: "empty",
          },
          {
            label: "Hùng Hòa",
            value: 29359,
            slug: "hung-hoa",
            status: "empty",
          },
          {
            label: "Tân Hùng",
            value: 29362,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "Tập Ngãi",
            value: 29365,
            slug: "tap-ngai",
            status: "empty",
          },
          {
            label: "Ngãi Hùng",
            value: 29368,
            slug: "ngai-hung",
            status: "empty",
          },
          { label: "Tân Hòa", value: 29371, slug: "tan-hoa", status: "empty" },
        ],
      },
      {
        label: "Huyện Châu Thành",
        value: 847,
        slug: "huyen-chau-thanh",
        status: "empty",
        ward: [
          {
            label: "Châu Thành",
            value: 29374,
            slug: "chau-thanh",
            status: "empty",
          },
          { label: "Đa Lộc", value: 29377, slug: "da-loc", status: "empty" },
          {
            label: "Mỹ Chánh",
            value: 29380,
            slug: "my-chanh",
            status: "empty",
          },
          {
            label: "Thanh Mỹ",
            value: 29383,
            slug: "thanh-my",
            status: "empty",
          },
          {
            label: "Lương Hoà A",
            value: 29386,
            slug: "luong-hoa-a",
            status: "empty",
          },
          {
            label: "Lương Hòa",
            value: 29389,
            slug: "luong-hoa",
            status: "empty",
          },
          {
            label: "Song Lộc",
            value: 29392,
            slug: "song-loc",
            status: "empty",
          },
          {
            label: "Nguyệt Hóa",
            value: 29395,
            slug: "nguyet-hoa",
            status: "empty",
          },
          {
            label: "Hòa Thuận",
            value: 29398,
            slug: "hoa-thuan",
            status: "empty",
          },
          { label: "Hòa Lợi", value: 29401, slug: "hoa-loi", status: "empty" },
          {
            label: "Phước Hảo",
            value: 29404,
            slug: "phuoc-hao",
            status: "empty",
          },
          { label: "Hưng Mỹ", value: 29407, slug: "hung-my", status: "empty" },
          {
            label: "Hòa Minh",
            value: 29410,
            slug: "hoa-minh",
            status: "empty",
          },
          {
            label: "Long Hòa",
            value: 29413,
            slug: "long-hoa",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cầu Ngang",
        value: 848,
        slug: "huyen-cau-ngang",
        status: "empty",
        ward: [
          {
            label: "Cầu Ngang",
            value: 29416,
            slug: "cau-ngang",
            status: "empty",
          },
          { label: "Mỹ Long", value: 29419, slug: "my-long", status: "empty" },
          {
            label: "Mỹ Long Bắc",
            value: 29422,
            slug: "my-long-bac",
            status: "empty",
          },
          {
            label: "Mỹ Long Nam",
            value: 29425,
            slug: "my-long-nam",
            status: "empty",
          },
          { label: "Mỹ Hòa", value: 29428, slug: "my-hoa", status: "empty" },
          {
            label: "Vĩnh Kim",
            value: 29431,
            slug: "vinh-kim",
            status: "empty",
          },
          { label: "Kim Hòa", value: 29434, slug: "kim-hoa", status: "empty" },
          {
            label: "Hiệp Hòa",
            value: 29437,
            slug: "hiep-hoa",
            status: "empty",
          },
          {
            label: "Thuận Hòa",
            value: 29440,
            slug: "thuan-hoa",
            status: "empty",
          },
          {
            label: "Long Sơn",
            value: 29443,
            slug: "long-son",
            status: "empty",
          },
          {
            label: "Nhị Trường",
            value: 29446,
            slug: "nhi-truong",
            status: "empty",
          },
          {
            label: "Trường Thọ",
            value: 29449,
            slug: "truong-tho",
            status: "empty",
          },
          {
            label: "Hiệp Mỹ Đông",
            value: 29452,
            slug: "hiep-my-dong",
            status: "empty",
          },
          {
            label: "Hiệp Mỹ Tây",
            value: 29455,
            slug: "hiep-my-tay",
            status: "empty",
          },
          {
            label: "Thạnh Hòa Sơn",
            value: 29458,
            slug: "thanh-hoa-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Trà Cú",
        value: 849,
        slug: "huyen-tra-cu",
        status: "empty",
        ward: [
          { label: "Trà Cú", value: 29461, slug: "tra-cu", status: "empty" },
          { label: "Định An", value: 29462, slug: "dinh-an", status: "empty" },
          {
            label: "Phước Hưng",
            value: 29464,
            slug: "phuoc-hung",
            status: "empty",
          },
          { label: "Tập Sơn", value: 29467, slug: "tap-son", status: "empty" },
          { label: "Tân Sơn", value: 29470, slug: "tan-son", status: "empty" },
          {
            label: "An Quảng Hữu",
            value: 29473,
            slug: "an-quang-huu",
            status: "empty",
          },
          {
            label: "Lưu Nghiệp Anh",
            value: 29476,
            slug: "luu-nghiep-anh",
            status: "empty",
          },
          {
            label: "Ngãi Xuyên",
            value: 29479,
            slug: "ngai-xuyen",
            status: "empty",
          },
          { label: "Kim Sơn", value: 29482, slug: "kim-son", status: "empty" },
          {
            label: "Thanh Sơn",
            value: 29485,
            slug: "thanh-son",
            status: "empty",
          },
          {
            label: "Hàm Giang",
            value: 29488,
            slug: "ham-giang",
            status: "empty",
          },
          { label: "Hàm Tân", value: 29489, slug: "ham-tan", status: "empty" },
          { label: "Đại An", value: 29491, slug: "dai-an", status: "empty" },
          { label: "Định An", value: 29494, slug: "dinh-an", status: "empty" },
          {
            label: "Ngọc Biên",
            value: 29503,
            slug: "ngoc-bien",
            status: "empty",
          },
          {
            label: "Long Hiệp",
            value: 29506,
            slug: "long-hiep",
            status: "empty",
          },
          {
            label: "Tân Hiệp",
            value: 29509,
            slug: "tan-hiep",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Duyên Hải",
        value: 850,
        slug: "huyen-duyen-hai",
        status: "empty",
        ward: [
          {
            label: "Đôn Xuân",
            value: 29497,
            slug: "don-xuan",
            status: "empty",
          },
          {
            label: "Đôn Châu",
            value: 29500,
            slug: "don-chau",
            status: "empty",
          },
          {
            label: "Long Thành",
            value: 29513,
            slug: "long-thanh",
            status: "empty",
          },
          {
            label: "Long Khánh",
            value: 29521,
            slug: "long-khanh",
            status: "empty",
          },
          { label: "Ngũ Lạc", value: 29530, slug: "ngu-lac", status: "empty" },
          {
            label: "Long Vĩnh",
            value: 29533,
            slug: "long-vinh",
            status: "empty",
          },
          {
            label: "Đông Hải",
            value: 29536,
            slug: "dong-hai",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Duyên Hải",
        value: 851,
        slug: "thi-xa-duyen-hai",
        status: "empty",
        ward: [
          {
            label: "Phường 1",
            value: 29512,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Long Toàn",
            value: 29515,
            slug: "long-toan",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 29516,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Long Hữu",
            value: 29518,
            slug: "long-huu",
            status: "empty",
          },
          {
            label: "Dân Thành",
            value: 29524,
            slug: "dan-thanh",
            status: "empty",
          },
          {
            label: "Trường Long Hòa",
            value: 29527,
            slug: "truong-long-hoa",
            status: "empty",
          },
          {
            label: "Hiệp Thạnh",
            value: 29539,
            slug: "hiep-thanh",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Vĩnh Long",
    value: 86,
    slug: "vinh-long",
    status: "filled",
    district: [
      {
        label: "Thành phố Vĩnh Long",
        value: 855,
        slug: "thanh-pho-vinh-long",
        status: "empty",
        ward: [
          {
            label: "Phường 9",
            value: 29542,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 29545,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 29551,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 29554,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 29557,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 8",
            value: 29560,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Tân Ngãi",
            value: 29563,
            slug: "tan-ngai",
            status: "empty",
          },
          { label: "Tân Hòa", value: 29566, slug: "tan-hoa", status: "empty" },
          { label: "Tân Hội", value: 29569, slug: "tan-hoi", status: "empty" },
          {
            label: "Trường An",
            value: 29572,
            slug: "truong-an",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Long Hồ",
        value: 857,
        slug: "huyen-long-ho",
        status: "filled",
        ward: [
          {
            label: "Đồng Phú",
            value: 29578,
            slug: "dong-phu",
            status: "empty",
          },
          {
            label: "Bình Hòa Phước",
            value: 29581,
            slug: "binh-hoa-phuoc",
            status: "empty",
          },
          {
            label: "Hòa Ninh",
            value: 29584,
            slug: "hoa-ninh",
            status: "empty",
          },
          { label: "An Bình", value: 29587, slug: "an-binh", status: "empty" },
          {
            label: "Thanh Đức",
            value: 29590,
            slug: "thanh-duc",
            status: "filled",
          },
          {
            label: "Tân Hạnh",
            value: 29593,
            slug: "tan-hanh",
            status: "empty",
          },
          {
            label: "Phước Hậu",
            value: 29596,
            slug: "phuoc-hau",
            status: "empty",
          },
          {
            label: "Long Phước",
            value: 29599,
            slug: "long-phuoc",
            status: "empty",
          },
          { label: "Long Hồ", value: 29602, slug: "long-ho", status: "empty" },
          { label: "Lộc Hòa", value: 29605, slug: "loc-hoa", status: "empty" },
          { label: "Long An", value: 29608, slug: "long-an", status: "empty" },
          {
            label: "Phú Quới",
            value: 29611,
            slug: "phu-quoi",
            status: "empty",
          },
          {
            label: "Thạnh Quới",
            value: 29614,
            slug: "thanh-quoi",
            status: "empty",
          },
          { label: "Hòa Phú", value: 29617, slug: "hoa-phu", status: "empty" },
        ],
      },
      {
        label: "Huyện Mang Thít",
        value: 858,
        slug: "huyen-mang-thit",
        status: "empty",
        ward: [
          { label: "Mỹ An", value: 29623, slug: "my-an", status: "empty" },
          {
            label: "Mỹ Phước",
            value: 29626,
            slug: "my-phuoc",
            status: "empty",
          },
          {
            label: "An Phước",
            value: 29629,
            slug: "an-phuoc",
            status: "empty",
          },
          {
            label: "Nhơn Phú",
            value: 29632,
            slug: "nhon-phu",
            status: "empty",
          },
          { label: "Long Mỹ", value: 29635, slug: "long-my", status: "empty" },
          {
            label: "Hòa Tịnh",
            value: 29638,
            slug: "hoa-tinh",
            status: "empty",
          },
          {
            label: "Cái Nhum",
            value: 29641,
            slug: "cai-nhum",
            status: "empty",
          },
          {
            label: "Bình Phước",
            value: 29644,
            slug: "binh-phuoc",
            status: "empty",
          },
          {
            label: "Chánh An",
            value: 29647,
            slug: "chanh-an",
            status: "empty",
          },
          {
            label: "Tân An Hội",
            value: 29650,
            slug: "tan-an-hoi",
            status: "empty",
          },
          {
            label: "Tân Long",
            value: 29653,
            slug: "tan-long",
            status: "empty",
          },
          {
            label: "Tân Long Hội",
            value: 29656,
            slug: "tan-long-hoi",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vũng Liêm",
        value: 859,
        slug: "huyen-vung-liem",
        status: "empty",
        ward: [
          {
            label: "Vũng Liêm",
            value: 29659,
            slug: "vung-liem",
            status: "empty",
          },
          {
            label: "Tân Quới Trung",
            value: 29662,
            slug: "tan-quoi-trung",
            status: "empty",
          },
          {
            label: "Quới Thiện",
            value: 29665,
            slug: "quoi-thien",
            status: "empty",
          },
          { label: "Quới An", value: 29668, slug: "quoi-an", status: "empty" },
          {
            label: "Trung Chánh",
            value: 29671,
            slug: "trung-chanh",
            status: "empty",
          },
          {
            label: "Tân An Luông",
            value: 29674,
            slug: "tan-an-luong",
            status: "empty",
          },
          {
            label: "Thanh Bình",
            value: 29677,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Trung Thành Tây",
            value: 29680,
            slug: "trung-thanh-tay",
            status: "empty",
          },
          {
            label: "Trung Hiệp",
            value: 29683,
            slug: "trung-hiep",
            status: "empty",
          },
          {
            label: "Hiếu Phụng",
            value: 29686,
            slug: "hieu-phung",
            status: "empty",
          },
          {
            label: "Trung Thành Đông",
            value: 29689,
            slug: "trung-thanh-dong",
            status: "empty",
          },
          {
            label: "Trung Thành",
            value: 29692,
            slug: "trung-thanh",
            status: "empty",
          },
          {
            label: "Trung Hiếu",
            value: 29695,
            slug: "trung-hieu",
            status: "empty",
          },
          {
            label: "Trung Ngãi",
            value: 29698,
            slug: "trung-ngai",
            status: "empty",
          },
          {
            label: "Hiếu Thuận",
            value: 29701,
            slug: "hieu-thuan",
            status: "empty",
          },
          {
            label: "Trung Nghĩa",
            value: 29704,
            slug: "trung-nghia",
            status: "empty",
          },
          {
            label: "Trung An",
            value: 29707,
            slug: "trung-an",
            status: "empty",
          },
          {
            label: "Hiếu Nhơn",
            value: 29710,
            slug: "hieu-nhon",
            status: "empty",
          },
          {
            label: "Hiếu Thành",
            value: 29713,
            slug: "hieu-thanh",
            status: "empty",
          },
          {
            label: "Hiếu Nghĩa",
            value: 29716,
            slug: "hieu-nghia",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tam Bình",
        value: 860,
        slug: "huyen-tam-binh",
        status: "empty",
        ward: [
          {
            label: "Tam Bình",
            value: 29719,
            slug: "tam-binh",
            status: "empty",
          },
          { label: "Tân Lộc", value: 29722, slug: "tan-loc", status: "empty" },
          {
            label: "Phú Thịnh",
            value: 29725,
            slug: "phu-thinh",
            status: "empty",
          },
          { label: "Hậu Lộc", value: 29728, slug: "hau-loc", status: "empty" },
          {
            label: "Hòa Thạnh",
            value: 29731,
            slug: "hoa-thanh",
            status: "empty",
          },
          { label: "Hoà Lộc", value: 29734, slug: "hoa-loc", status: "empty" },
          { label: "Phú Lộc", value: 29737, slug: "phu-loc", status: "empty" },
          {
            label: "Song Phú",
            value: 29740,
            slug: "song-phu",
            status: "empty",
          },
          {
            label: "Hòa Hiệp",
            value: 29743,
            slug: "hoa-hiep",
            status: "empty",
          },
          { label: "Mỹ Lộc", value: 29746, slug: "my-loc", status: "empty" },
          { label: "Tân Phú", value: 29749, slug: "tan-phu", status: "empty" },
          {
            label: "Long Phú",
            value: 29752,
            slug: "long-phu",
            status: "empty",
          },
          {
            label: "Mỹ Thạnh Trung",
            value: 29755,
            slug: "my-thanh-trung",
            status: "empty",
          },
          { label: "Loan Mỹ", value: 29761, slug: "loan-my", status: "empty" },
          { label: "Ngãi Tứ", value: 29764, slug: "ngai-tu", status: "empty" },
          {
            label: "Bình Ninh",
            value: 29767,
            slug: "binh-ninh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Bình Minh",
        value: 861,
        slug: "thi-xa-binh-minh",
        status: "filled",
        ward: [
          { label: "Cái Vồn", value: 29770, slug: "cai-von", status: "empty" },
          {
            label: "Thành Phước",
            value: 29771,
            slug: "thanh-phuoc",
            status: "empty",
          },
          {
            label: "Thuận An",
            value: 29806,
            slug: "thuan-an",
            status: "filled",
          },
          {
            label: "Đông Thạnh",
            value: 29809,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Đông Bình",
            value: 29812,
            slug: "dong-binh",
            status: "empty",
          },
          {
            label: "Đông Thuận",
            value: 29813,
            slug: "dong-thuan",
            status: "empty",
          },
          { label: "Mỹ Hòa", value: 29815, slug: "my-hoa", status: "empty" },
          {
            label: "Đông Thành",
            value: 29818,
            slug: "dong-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Trà Ôn",
        value: 862,
        slug: "huyen-tra-on",
        status: "filled",
        ward: [
          { label: "Trà Ôn", value: 29821, slug: "tra-on", status: "filled" },
          {
            label: "Xuân Hiệp",
            value: 29824,
            slug: "xuan-hiep",
            status: "empty",
          },
          {
            label: "Nhơn Bình",
            value: 29827,
            slug: "nhon-binh",
            status: "empty",
          },
          {
            label: "Hòa Bình",
            value: 29830,
            slug: "hoa-binh",
            status: "empty",
          },
          {
            label: "Thới Hòa",
            value: 29833,
            slug: "thoi-hoa",
            status: "empty",
          },
          { label: "Trà Côn", value: 29836, slug: "tra-con", status: "empty" },
          { label: "Tân Mỹ", value: 29839, slug: "tan-my", status: "empty" },
          {
            label: "Hựu Thành",
            value: 29842,
            slug: "huu-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Xuân",
            value: 29845,
            slug: "vinh-xuan",
            status: "empty",
          },
          {
            label: "Thuận Thới",
            value: 29848,
            slug: "thuan-thoi",
            status: "empty",
          },
          {
            label: "Phú Thành",
            value: 29851,
            slug: "phu-thanh",
            status: "empty",
          },
          {
            label: "Lục Sỹ Thành",
            value: 29857,
            slug: "luc-sy-thanh",
            status: "empty",
          },
          {
            label: "Tích Thiện",
            value: 29860,
            slug: "tich-thien",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Bình Tân",
        value: 863,
        slug: "huyen-binh-tan",
        status: "empty",
        ward: [
          {
            label: "Tân Thành",
            value: 29776,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Thành Trung",
            value: 29779,
            slug: "thanh-trung",
            status: "empty",
          },
          {
            label: "Tân An Thạnh",
            value: 29782,
            slug: "tan-an-thanh",
            status: "empty",
          },
          {
            label: "Tân Lược",
            value: 29785,
            slug: "tan-luoc",
            status: "empty",
          },
          {
            label: "Nguyễn Văn Thảnh",
            value: 29788,
            slug: "nguyen-van-thanh",
            status: "empty",
          },
          {
            label: "Thành Lợi",
            value: 29791,
            slug: "thanh-loi",
            status: "empty",
          },
          {
            label: "Mỹ Thuận",
            value: 29794,
            slug: "my-thuan",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 29797,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Tân Quới",
            value: 29800,
            slug: "tan-quoi",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Đồng Tháp",
    value: 87,
    slug: "dong-thap",
    status: "empty",
    district: [
      {
        label: "Thành phố Cao Lãnh",
        value: 866,
        slug: "thanh-pho-cao-lanh",
        status: "empty",
        ward: [
          {
            label: "Phường 1",
            value: 29869,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 29872,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 29875,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 6",
            value: 29878,
            slug: "phuong-6",
            status: "empty",
          },
          { label: "Mỹ Ngãi", value: 29881, slug: "my-ngai", status: "empty" },
          { label: "Mỹ Tân", value: 29884, slug: "my-tan", status: "empty" },
          { label: "Mỹ Trà", value: 29887, slug: "my-tra", status: "empty" },
          { label: "Mỹ Phú", value: 29888, slug: "my-phu", status: "empty" },
          {
            label: "Tân Thuận Tây",
            value: 29890,
            slug: "tan-thuan-tay",
            status: "empty",
          },
          {
            label: "Hoà Thuận",
            value: 29892,
            slug: "hoa-thuan",
            status: "empty",
          },
          { label: "Hòa An", value: 29893, slug: "hoa-an", status: "empty" },
          {
            label: "Tân Thuận Đông",
            value: 29896,
            slug: "tan-thuan-dong",
            status: "empty",
          },
          {
            label: "Tịnh Thới",
            value: 29899,
            slug: "tinh-thoi",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Sa Đéc",
        value: 867,
        slug: "thanh-pho-sa-dec",
        status: "empty",
        ward: [
          {
            label: "Phường 3",
            value: 29902,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 29905,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 29908,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 29911,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Tân Khánh Đông",
            value: 29914,
            slug: "tan-khanh-dong",
            status: "empty",
          },
          {
            label: "Tân Quy Đông",
            value: 29917,
            slug: "tan-quy-dong",
            status: "empty",
          },
          { label: "An Hoà", value: 29919, slug: "an-hoa", status: "empty" },
          {
            label: "Tân Quy Tây",
            value: 29920,
            slug: "tan-quy-tay",
            status: "empty",
          },
          {
            label: "Tân Phú Đông",
            value: 29923,
            slug: "tan-phu-dong",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Hồng Ngự",
        value: 868,
        slug: "thanh-pho-hong-ngu",
        status: "empty",
        ward: [
          { label: "An Lộc", value: 29954, slug: "an-loc", status: "empty" },
          {
            label: "An Thạnh",
            value: 29955,
            slug: "an-thanh",
            status: "empty",
          },
          {
            label: "Bình Thạnh",
            value: 29959,
            slug: "binh-thanh",
            status: "empty",
          },
          { label: "Tân Hội", value: 29965, slug: "tan-hoi", status: "empty" },
          { label: "An Lạc", value: 29978, slug: "an-lac", status: "empty" },
          {
            label: "An Bình B",
            value: 29986,
            slug: "an-binh-b",
            status: "empty",
          },
          {
            label: "An Bình A",
            value: 29989,
            slug: "an-binh-a",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Hồng",
        value: 869,
        slug: "huyen-tan-hong",
        status: "empty",
        ward: [
          { label: "Sa Rài", value: 29926, slug: "sa-rai", status: "empty" },
          {
            label: "Tân Hộ Cơ",
            value: 29929,
            slug: "tan-ho-co",
            status: "empty",
          },
          {
            label: "Thông Bình",
            value: 29932,
            slug: "thong-binh",
            status: "empty",
          },
          {
            label: "Bình Phú",
            value: 29935,
            slug: "binh-phu",
            status: "empty",
          },
          {
            label: "Tân Thành A",
            value: 29938,
            slug: "tan-thanh-a",
            status: "empty",
          },
          {
            label: "Tân Thành B",
            value: 29941,
            slug: "tan-thanh-b",
            status: "empty",
          },
          {
            label: "Tân Phước",
            value: 29944,
            slug: "tan-phuoc",
            status: "empty",
          },
          {
            label: "Tân Công Chí",
            value: 29947,
            slug: "tan-cong-chi",
            status: "empty",
          },
          {
            label: "An Phước",
            value: 29950,
            slug: "an-phuoc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hồng Ngự",
        value: 870,
        slug: "huyen-hong-ngu",
        status: "empty",
        ward: [
          {
            label: "Thường Phước 1",
            value: 29956,
            slug: "thuong-phuoc-1",
            status: "empty",
          },
          {
            label: "Thường Thới Hậu A",
            value: 29962,
            slug: "thuong-thoi-hau-a",
            status: "empty",
          },
          {
            label: "Thường Thới Tiền",
            value: 29971,
            slug: "thuong-thoi-tien",
            status: "empty",
          },
          {
            label: "Thường Phước 2",
            value: 29974,
            slug: "thuong-phuoc-2",
            status: "empty",
          },
          {
            label: "Thường Lạc",
            value: 29977,
            slug: "thuong-lac",
            status: "empty",
          },
          {
            label: "Long Khánh A",
            value: 29980,
            slug: "long-khanh-a",
            status: "empty",
          },
          {
            label: "Long Khánh B",
            value: 29983,
            slug: "long-khanh-b",
            status: "empty",
          },
          {
            label: "Long Thuận",
            value: 29992,
            slug: "long-thuan",
            status: "empty",
          },
          {
            label: "Phú Thuận B",
            value: 29995,
            slug: "phu-thuan-b",
            status: "empty",
          },
          {
            label: "Phú Thuận A",
            value: 29998,
            slug: "phu-thuan-a",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tam Nông",
        value: 871,
        slug: "huyen-tam-nong",
        status: "empty",
        ward: [
          {
            label: "Tràm Chim",
            value: 30001,
            slug: "tram-chim",
            status: "empty",
          },
          {
            label: "Hoà Bình",
            value: 30004,
            slug: "hoa-binh",
            status: "empty",
          },
          {
            label: "Tân Công Sính",
            value: 30007,
            slug: "tan-cong-sinh",
            status: "empty",
          },
          {
            label: "Phú Hiệp",
            value: 30010,
            slug: "phu-hiep",
            status: "empty",
          },
          { label: "Phú Đức", value: 30013, slug: "phu-duc", status: "empty" },
          {
            label: "Phú Thành B",
            value: 30016,
            slug: "phu-thanh-b",
            status: "empty",
          },
          { label: "An Hòa", value: 30019, slug: "an-hoa", status: "empty" },
          { label: "An Long", value: 30022, slug: "an-long", status: "empty" },
          {
            label: "Phú Cường",
            value: 30025,
            slug: "phu-cuong",
            status: "empty",
          },
          {
            label: "Phú Ninh",
            value: 30028,
            slug: "phu-ninh",
            status: "empty",
          },
          { label: "Phú Thọ", value: 30031, slug: "phu-tho", status: "empty" },
          {
            label: "Phú Thành A",
            value: 30034,
            slug: "phu-thanh-a",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tháp Mười",
        value: 872,
        slug: "huyen-thap-muoi",
        status: "empty",
        ward: [
          { label: "Mỹ An", value: 30037, slug: "my-an", status: "empty" },
          {
            label: "Thạnh Lợi",
            value: 30040,
            slug: "thanh-loi",
            status: "empty",
          },
          {
            label: "Hưng Thạnh",
            value: 30043,
            slug: "hung-thanh",
            status: "empty",
          },
          {
            label: "Trường Xuân",
            value: 30046,
            slug: "truong-xuan",
            status: "empty",
          },
          {
            label: "Tân Kiều",
            value: 30049,
            slug: "tan-kieu",
            status: "empty",
          },
          { label: "Mỹ Hòa", value: 30052, slug: "my-hoa", status: "empty" },
          { label: "Mỹ Quý", value: 30055, slug: "my-quy", status: "empty" },
          { label: "Mỹ Đông", value: 30058, slug: "my-dong", status: "empty" },
          {
            label: "Đốc Binh Kiều",
            value: 30061,
            slug: "doc-binh-kieu",
            status: "empty",
          },
          { label: "Mỹ An", value: 30064, slug: "my-an", status: "empty" },
          {
            label: "Phú Điền",
            value: 30067,
            slug: "phu-dien",
            status: "empty",
          },
          {
            label: "Láng Biển",
            value: 30070,
            slug: "lang-bien",
            status: "empty",
          },
          {
            label: "Thanh Mỹ",
            value: 30073,
            slug: "thanh-my",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cao Lãnh",
        value: 873,
        slug: "huyen-cao-lanh",
        status: "empty",
        ward: [
          { label: "Mỹ Thọ", value: 30076, slug: "my-tho", status: "empty" },
          {
            label: "Gáo Giồng",
            value: 30079,
            slug: "gao-giong",
            status: "empty",
          },
          {
            label: "Phương Thịnh",
            value: 30082,
            slug: "phuong-thinh",
            status: "empty",
          },
          { label: "Ba Sao", value: 30085, slug: "ba-sao", status: "empty" },
          {
            label: "Phong Mỹ",
            value: 30088,
            slug: "phong-my",
            status: "empty",
          },
          {
            label: "Tân Nghĩa",
            value: 30091,
            slug: "tan-nghia",
            status: "empty",
          },
          {
            label: "Phương Trà",
            value: 30094,
            slug: "phuong-tra",
            status: "empty",
          },
          { label: "Nhị Mỹ", value: 30097, slug: "nhi-my", status: "empty" },
          { label: "Mỹ Thọ", value: 30100, slug: "my-tho", status: "empty" },
          {
            label: "Tân Hội Trung",
            value: 30103,
            slug: "tan-hoi-trung",
            status: "empty",
          },
          { label: "An Bình", value: 30106, slug: "an-binh", status: "empty" },
          { label: "Mỹ Hội", value: 30109, slug: "my-hoi", status: "empty" },
          { label: "Mỹ Hiệp", value: 30112, slug: "my-hiep", status: "empty" },
          { label: "Mỹ Long", value: 30115, slug: "my-long", status: "empty" },
          {
            label: "Bình Hàng Trung",
            value: 30118,
            slug: "binh-hang-trung",
            status: "empty",
          },
          {
            label: "Mỹ Xương",
            value: 30121,
            slug: "my-xuong",
            status: "empty",
          },
          {
            label: "Bình Hàng Tây",
            value: 30124,
            slug: "binh-hang-tay",
            status: "empty",
          },
          {
            label: "Bình Thạnh",
            value: 30127,
            slug: "binh-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thanh Bình",
        value: 874,
        slug: "huyen-thanh-binh",
        status: "empty",
        ward: [
          {
            label: "Thanh Bình",
            value: 30130,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Tân Quới",
            value: 30133,
            slug: "tan-quoi",
            status: "empty",
          },
          { label: "Tân Hòa", value: 30136, slug: "tan-hoa", status: "empty" },
          {
            label: "An Phong",
            value: 30139,
            slug: "an-phong",
            status: "empty",
          },
          { label: "Phú Lợi", value: 30142, slug: "phu-loi", status: "empty" },
          { label: "Tân Mỹ", value: 30145, slug: "tan-my", status: "empty" },
          {
            label: "Bình Tấn",
            value: 30148,
            slug: "binh-tan",
            status: "empty",
          },
          { label: "Tân Huề", value: 30151, slug: "tan-hue", status: "empty" },
          {
            label: "Tân Bình",
            value: 30154,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Tân Thạnh",
            value: 30157,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Tân Phú", value: 30160, slug: "tan-phu", status: "empty" },
          {
            label: "Bình Thành",
            value: 30163,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Tân Long",
            value: 30166,
            slug: "tan-long",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lấp Vò",
        value: 875,
        slug: "huyen-lap-vo",
        status: "empty",
        ward: [
          { label: "Lấp Vò", value: 30169, slug: "lap-vo", status: "empty" },
          {
            label: "Mỹ An Hưng A",
            value: 30172,
            slug: "my-an-hung-a",
            status: "empty",
          },
          { label: "Tân Mỹ", value: 30175, slug: "tan-my", status: "empty" },
          {
            label: "Mỹ An Hưng B",
            value: 30178,
            slug: "my-an-hung-b",
            status: "empty",
          },
          {
            label: "Tân Khánh Trung",
            value: 30181,
            slug: "tan-khanh-trung",
            status: "empty",
          },
          {
            label: "Long Hưng A",
            value: 30184,
            slug: "long-hung-a",
            status: "empty",
          },
          {
            label: "Vĩnh Thạnh",
            value: 30187,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Long Hưng B",
            value: 30190,
            slug: "long-hung-b",
            status: "empty",
          },
          {
            label: "Bình Thành",
            value: 30193,
            slug: "binh-thanh",
            status: "empty",
          },
          { label: "Định An", value: 30196, slug: "dinh-an", status: "empty" },
          {
            label: "Định Yên",
            value: 30199,
            slug: "dinh-yen",
            status: "empty",
          },
          {
            label: "Hội An Đông",
            value: 30202,
            slug: "hoi-an-dong",
            status: "empty",
          },
          {
            label: "Bình Thạnh Trung",
            value: 30205,
            slug: "binh-thanh-trung",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Lai Vung",
        value: 876,
        slug: "huyen-lai-vung",
        status: "empty",
        ward: [
          {
            label: "Lai Vung",
            value: 30208,
            slug: "lai-vung",
            status: "empty",
          },
          {
            label: "Tân Dương",
            value: 30211,
            slug: "tan-duong",
            status: "empty",
          },
          {
            label: "Hòa Thành",
            value: 30214,
            slug: "hoa-thanh",
            status: "empty",
          },
          {
            label: "Long Hậu",
            value: 30217,
            slug: "long-hau",
            status: "empty",
          },
          {
            label: "Tân Phước",
            value: 30220,
            slug: "tan-phuoc",
            status: "empty",
          },
          {
            label: "Hòa Long",
            value: 30223,
            slug: "hoa-long",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 30226,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Long Thắng",
            value: 30229,
            slug: "long-thang",
            status: "empty",
          },
          {
            label: "Vĩnh Thới",
            value: 30232,
            slug: "vinh-thoi",
            status: "empty",
          },
          { label: "Tân Hòa", value: 30235, slug: "tan-hoa", status: "empty" },
          {
            label: "Định Hòa",
            value: 30238,
            slug: "dinh-hoa",
            status: "empty",
          },
          {
            label: "Phong Hòa",
            value: 30241,
            slug: "phong-hoa",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Châu Thành",
        value: 877,
        slug: "huyen-chau-thanh",
        status: "empty",
        ward: [
          {
            label: "Cái Tàu Hạ",
            value: 30244,
            slug: "cai-tau-ha",
            status: "empty",
          },
          { label: "An Hiệp", value: 30247, slug: "an-hiep", status: "empty" },
          { label: "An Nhơn", value: 30250, slug: "an-nhon", status: "empty" },
          {
            label: "Tân Nhuận Đông",
            value: 30253,
            slug: "tan-nhuan-dong",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 30256,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Tân Phú Trung",
            value: 30259,
            slug: "tan-phu-trung",
            status: "empty",
          },
          {
            label: "Phú Long",
            value: 30262,
            slug: "phu-long",
            status: "empty",
          },
          {
            label: "An Phú Thuận",
            value: 30265,
            slug: "an-phu-thuan",
            status: "empty",
          },
          { label: "Phú Hựu", value: 30268, slug: "phu-huu", status: "empty" },
          {
            label: "An Khánh",
            value: 30271,
            slug: "an-khanh",
            status: "empty",
          },
          { label: "Tân Phú", value: 30274, slug: "tan-phu", status: "empty" },
          { label: "Hòa Tân", value: 30277, slug: "hoa-tan", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "An Giang",
    value: 89,
    slug: "an-giang",
    status: "empty",
    district: [
      {
        label: "Thành phố Long Xuyên",
        value: 883,
        slug: "thanh-pho-long-xuyen",
        status: "empty",
        ward: [
          { label: "Mỹ Bình", value: 30280, slug: "my-binh", status: "empty" },
          { label: "Mỹ Long", value: 30283, slug: "my-long", status: "empty" },
          {
            label: "Mỹ Xuyên",
            value: 30286,
            slug: "my-xuyen",
            status: "empty",
          },
          {
            label: "Bình Đức",
            value: 30289,
            slug: "binh-duc",
            status: "empty",
          },
          {
            label: "Bình Khánh",
            value: 30292,
            slug: "binh-khanh",
            status: "empty",
          },
          {
            label: "Mỹ Phước",
            value: 30295,
            slug: "my-phuoc",
            status: "empty",
          },
          { label: "Mỹ Quý", value: 30298, slug: "my-quy", status: "empty" },
          { label: "Mỹ Thới", value: 30301, slug: "my-thoi", status: "empty" },
          {
            label: "Mỹ Thạnh",
            value: 30304,
            slug: "my-thanh",
            status: "empty",
          },
          { label: "Mỹ Hòa", value: 30307, slug: "my-hoa", status: "empty" },
          {
            label: "Mỹ Khánh",
            value: 30310,
            slug: "my-khanh",
            status: "empty",
          },
          {
            label: "Mỹ Hoà Hưng",
            value: 30313,
            slug: "my-hoa-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Châu Đốc",
        value: 884,
        slug: "thanh-pho-chau-doc",
        status: "empty",
        ward: [
          {
            label: "Châu Phú B",
            value: 30316,
            slug: "chau-phu-b",
            status: "empty",
          },
          {
            label: "Châu Phú A",
            value: 30319,
            slug: "chau-phu-a",
            status: "empty",
          },
          { label: "Vĩnh Mỹ", value: 30322, slug: "vinh-my", status: "empty" },
          { label: "Núi Sam", value: 30325, slug: "nui-sam", status: "empty" },
          {
            label: "Vĩnh Ngươn",
            value: 30328,
            slug: "vinh-nguon",
            status: "empty",
          },
          { label: "Vĩnh Tế", value: 30331, slug: "vinh-te", status: "empty" },
          {
            label: "Vĩnh Châu",
            value: 30334,
            slug: "vinh-chau",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện An Phú",
        value: 886,
        slug: "huyen-an-phu",
        status: "empty",
        ward: [
          { label: "An Phú", value: 30337, slug: "an-phu", status: "empty" },
          {
            label: "Khánh An",
            value: 30340,
            slug: "khanh-an",
            status: "empty",
          },
          {
            label: "Long Bình",
            value: 30341,
            slug: "long-binh",
            status: "empty",
          },
          {
            label: "Khánh Bình",
            value: 30343,
            slug: "khanh-binh",
            status: "empty",
          },
          {
            label: "Quốc Thái",
            value: 30346,
            slug: "quoc-thai",
            status: "empty",
          },
          {
            label: "Nhơn Hội",
            value: 30349,
            slug: "nhon-hoi",
            status: "empty",
          },
          { label: "Phú Hữu", value: 30352, slug: "phu-huu", status: "empty" },
          { label: "Phú Hội", value: 30355, slug: "phu-hoi", status: "empty" },
          {
            label: "Phước Hưng",
            value: 30358,
            slug: "phuoc-hung",
            status: "empty",
          },
          {
            label: "Vĩnh Lộc",
            value: 30361,
            slug: "vinh-loc",
            status: "empty",
          },
          {
            label: "Vĩnh Hậu",
            value: 30364,
            slug: "vinh-hau",
            status: "empty",
          },
          {
            label: "Vĩnh Trường",
            value: 30367,
            slug: "vinh-truong",
            status: "empty",
          },
          {
            label: "Vĩnh Hội Đông",
            value: 30370,
            slug: "vinh-hoi-dong",
            status: "empty",
          },
          {
            label: "Đa Phước",
            value: 30373,
            slug: "da-phuoc",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Tân Châu",
        value: 887,
        slug: "thi-xa-tan-chau",
        status: "empty",
        ward: [
          {
            label: "Long Thạnh",
            value: 30376,
            slug: "long-thanh",
            status: "empty",
          },
          {
            label: "Long Hưng",
            value: 30377,
            slug: "long-hung",
            status: "empty",
          },
          {
            label: "Long Châu",
            value: 30378,
            slug: "long-chau",
            status: "empty",
          },
          { label: "Phú Lộc", value: 30379, slug: "phu-loc", status: "empty" },
          {
            label: "Vĩnh Xương",
            value: 30382,
            slug: "vinh-xuong",
            status: "empty",
          },
          {
            label: "Vĩnh Hòa",
            value: 30385,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Tân Thạnh",
            value: 30387,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Tân An", value: 30388, slug: "tan-an", status: "empty" },
          { label: "Long An", value: 30391, slug: "long-an", status: "empty" },
          {
            label: "Long Phú",
            value: 30394,
            slug: "long-phu",
            status: "empty",
          },
          {
            label: "Châu Phong",
            value: 30397,
            slug: "chau-phong",
            status: "empty",
          },
          {
            label: "Phú Vĩnh",
            value: 30400,
            slug: "phu-vinh",
            status: "empty",
          },
          {
            label: "Lê Chánh",
            value: 30403,
            slug: "le-chanh",
            status: "empty",
          },
          {
            label: "Long Sơn",
            value: 30412,
            slug: "long-son",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phú Tân",
        value: 888,
        slug: "huyen-phu-tan",
        status: "empty",
        ward: [
          { label: "Phú Mỹ", value: 30406, slug: "phu-my", status: "empty" },
          { label: "Chợ Vàm", value: 30409, slug: "cho-vam", status: "empty" },
          {
            label: "Long Hoà",
            value: 30415,
            slug: "long-hoa",
            status: "empty",
          },
          {
            label: "Phú Long",
            value: 30418,
            slug: "phu-long",
            status: "empty",
          },
          { label: "Phú Lâm", value: 30421, slug: "phu-lam", status: "empty" },
          {
            label: "Phú Hiệp",
            value: 30424,
            slug: "phu-hiep",
            status: "empty",
          },
          {
            label: "Phú Thạnh",
            value: 30427,
            slug: "phu-thanh",
            status: "empty",
          },
          { label: "Hoà Lạc", value: 30430, slug: "hoa-lac", status: "empty" },
          {
            label: "Phú Thành",
            value: 30433,
            slug: "phu-thanh",
            status: "empty",
          },
          { label: "Phú An", value: 30436, slug: "phu-an", status: "empty" },
          {
            label: "Phú Xuân",
            value: 30439,
            slug: "phu-xuan",
            status: "empty",
          },
          {
            label: "Hiệp Xương",
            value: 30442,
            slug: "hiep-xuong",
            status: "empty",
          },
          {
            label: "Phú Bình",
            value: 30445,
            slug: "phu-binh",
            status: "empty",
          },
          { label: "Phú Thọ", value: 30448, slug: "phu-tho", status: "empty" },
          {
            label: "Phú Hưng",
            value: 30451,
            slug: "phu-hung",
            status: "empty",
          },
          {
            label: "Bình Thạnh Đông",
            value: 30454,
            slug: "binh-thanh-dong",
            status: "empty",
          },
          { label: "Tân Hòa", value: 30457, slug: "tan-hoa", status: "empty" },
          {
            label: "Tân Trung",
            value: 30460,
            slug: "tan-trung",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Châu Phú",
        value: 889,
        slug: "huyen-chau-phu",
        status: "empty",
        ward: [
          { label: "Cái Dầu", value: 30463, slug: "cai-dau", status: "empty" },
          {
            label: "Khánh Hòa",
            value: 30466,
            slug: "khanh-hoa",
            status: "empty",
          },
          { label: "Mỹ Đức", value: 30469, slug: "my-duc", status: "empty" },
          { label: "Mỹ Phú", value: 30472, slug: "my-phu", status: "empty" },
          {
            label: "Ô Long Vỹ",
            value: 30475,
            slug: "o-long-vy",
            status: "empty",
          },
          {
            label: "Vĩnh Thạnh Trung",
            value: 30478,
            slug: "vinh-thanh-trung",
            status: "empty",
          },
          {
            label: "Thạnh Mỹ Tây",
            value: 30481,
            slug: "thanh-my-tay",
            status: "empty",
          },
          {
            label: "Bình Long",
            value: 30484,
            slug: "binh-long",
            status: "empty",
          },
          { label: "Bình Mỹ", value: 30487, slug: "binh-my", status: "empty" },
          {
            label: "Bình Thủy",
            value: 30490,
            slug: "binh-thuy",
            status: "empty",
          },
          {
            label: "Đào Hữu Cảnh",
            value: 30493,
            slug: "dao-huu-canh",
            status: "empty",
          },
          {
            label: "Bình Phú",
            value: 30496,
            slug: "binh-phu",
            status: "empty",
          },
          {
            label: "Bình Chánh",
            value: 30499,
            slug: "binh-chanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Tịnh Biên",
        value: 890,
        slug: "thi-xa-tinh-bien",
        status: "empty",
        ward: [
          {
            label: "Nhà Bàng",
            value: 30502,
            slug: "nha-bang",
            status: "empty",
          },
          {
            label: "Chi Lăng",
            value: 30505,
            slug: "chi-lang",
            status: "empty",
          },
          { label: "Núi Voi", value: 30508, slug: "nui-voi", status: "empty" },
          {
            label: "Nhơn Hưng",
            value: 30511,
            slug: "nhon-hung",
            status: "empty",
          },
          { label: "An Phú", value: 30514, slug: "an-phu", status: "empty" },
          {
            label: "Thới Sơn",
            value: 30517,
            slug: "thoi-son",
            status: "empty",
          },
          {
            label: "Tịnh Biên",
            value: 30520,
            slug: "tinh-bien",
            status: "empty",
          },
          {
            label: "Văn Giáo",
            value: 30523,
            slug: "van-giao",
            status: "empty",
          },
          { label: "An Cư", value: 30526, slug: "an-cu", status: "empty" },
          { label: "An Nông", value: 30529, slug: "an-nong", status: "empty" },
          {
            label: "Vĩnh Trung",
            value: 30532,
            slug: "vinh-trung",
            status: "empty",
          },
          { label: "Tân Lợi", value: 30535, slug: "tan-loi", status: "empty" },
          { label: "An Hảo", value: 30538, slug: "an-hao", status: "empty" },
          { label: "Tân Lập", value: 30541, slug: "tan-lap", status: "empty" },
        ],
      },
      {
        label: "Huyện Tri Tôn",
        value: 891,
        slug: "huyen-tri-ton",
        status: "empty",
        ward: [
          { label: "Tri Tôn", value: 30544, slug: "tri-ton", status: "empty" },
          { label: "Ba Chúc", value: 30547, slug: "ba-chuc", status: "empty" },
          {
            label: "Lạc Quới",
            value: 30550,
            slug: "lac-quoi",
            status: "empty",
          },
          { label: "Lê Trì", value: 30553, slug: "le-tri", status: "empty" },
          {
            label: "Vĩnh Gia",
            value: 30556,
            slug: "vinh-gia",
            status: "empty",
          },
          {
            label: "Vĩnh Phước",
            value: 30559,
            slug: "vinh-phuoc",
            status: "empty",
          },
          {
            label: "Châu Lăng",
            value: 30562,
            slug: "chau-lang",
            status: "empty",
          },
          {
            label: "Lương Phi",
            value: 30565,
            slug: "luong-phi",
            status: "empty",
          },
          {
            label: "Lương An Trà",
            value: 30568,
            slug: "luong-an-tra",
            status: "empty",
          },
          { label: "Tà Đảnh", value: 30571, slug: "ta-danh", status: "empty" },
          { label: "Núi Tô", value: 30574, slug: "nui-to", status: "empty" },
          { label: "An Tức", value: 30577, slug: "an-tuc", status: "empty" },
          { label: "Cô Tô", value: 30580, slug: "co-to", status: "empty" },
          {
            label: "Tân Tuyến",
            value: 30583,
            slug: "tan-tuyen",
            status: "empty",
          },
          { label: "Ô Lâm", value: 30586, slug: "o-lam", status: "empty" },
        ],
      },
      {
        label: "Huyện Châu Thành",
        value: 892,
        slug: "huyen-chau-thanh",
        status: "empty",
        ward: [
          { label: "An Châu", value: 30589, slug: "an-chau", status: "empty" },
          { label: "An Hòa", value: 30592, slug: "an-hoa", status: "empty" },
          {
            label: "Cần Đăng",
            value: 30595,
            slug: "can-dang",
            status: "empty",
          },
          {
            label: "Vĩnh Hanh",
            value: 30598,
            slug: "vinh-hanh",
            status: "empty",
          },
          {
            label: "Bình Thạnh",
            value: 30601,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Bình",
            value: 30604,
            slug: "vinh-binh",
            status: "empty",
          },
          {
            label: "Bình Hòa",
            value: 30607,
            slug: "binh-hoa",
            status: "empty",
          },
          { label: "Vĩnh An", value: 30610, slug: "vinh-an", status: "empty" },
          {
            label: "Hòa Bình Thạnh",
            value: 30613,
            slug: "hoa-binh-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Lợi",
            value: 30616,
            slug: "vinh-loi",
            status: "empty",
          },
          {
            label: "Vĩnh Nhuận",
            value: 30619,
            slug: "vinh-nhuan",
            status: "empty",
          },
          { label: "Tân Phú", value: 30622, slug: "tan-phu", status: "empty" },
          {
            label: "Vĩnh Thành",
            value: 30625,
            slug: "vinh-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Chợ Mới",
        value: 893,
        slug: "huyen-cho-moi",
        status: "empty",
        ward: [
          { label: "Chợ Mới", value: 30628, slug: "cho-moi", status: "empty" },
          {
            label: "Mỹ Luông",
            value: 30631,
            slug: "my-luong",
            status: "empty",
          },
          { label: "Kiến An", value: 30634, slug: "kien-an", status: "empty" },
          {
            label: "Mỹ Hội Đông",
            value: 30637,
            slug: "my-hoi-dong",
            status: "empty",
          },
          {
            label: "Long Điền A",
            value: 30640,
            slug: "long-dien-a",
            status: "empty",
          },
          { label: "Tấn Mỹ", value: 30643, slug: "tan-my", status: "empty" },
          {
            label: "Long Điền B",
            value: 30646,
            slug: "long-dien-b",
            status: "empty",
          },
          {
            label: "Kiến Thành",
            value: 30649,
            slug: "kien-thanh",
            status: "empty",
          },
          { label: "Mỹ Hiệp", value: 30652, slug: "my-hiep", status: "empty" },
          { label: "Mỹ An", value: 30655, slug: "my-an", status: "empty" },
          { label: "Nhơn Mỹ", value: 30658, slug: "nhon-my", status: "empty" },
          {
            label: "Long Giang",
            value: 30661,
            slug: "long-giang",
            status: "empty",
          },
          {
            label: "Long Kiến",
            value: 30664,
            slug: "long-kien",
            status: "empty",
          },
          {
            label: "Bình Phước Xuân",
            value: 30667,
            slug: "binh-phuoc-xuan",
            status: "empty",
          },
          {
            label: "An Thạnh Trung",
            value: 30670,
            slug: "an-thanh-trung",
            status: "empty",
          },
          { label: "Hội An", value: 30673, slug: "hoi-an", status: "empty" },
          {
            label: "Hòa Bình",
            value: 30676,
            slug: "hoa-binh",
            status: "empty",
          },
          { label: "Hòa An", value: 30679, slug: "hoa-an", status: "empty" },
        ],
      },
      {
        label: "Huyện Thoại Sơn",
        value: 894,
        slug: "huyen-thoai-son",
        status: "empty",
        ward: [
          { label: "Núi Sập", value: 30682, slug: "nui-sap", status: "empty" },
          { label: "Phú Hoà", value: 30685, slug: "phu-hoa", status: "empty" },
          { label: "Óc Eo", value: 30688, slug: "oc-eo", status: "empty" },
          { label: "Tây Phú", value: 30691, slug: "tay-phu", status: "empty" },
          { label: "An Bình", value: 30692, slug: "an-binh", status: "empty" },
          {
            label: "Vĩnh Phú",
            value: 30694,
            slug: "vinh-phu",
            status: "empty",
          },
          {
            label: "Vĩnh Trạch",
            value: 30697,
            slug: "vinh-trach",
            status: "empty",
          },
          {
            label: "Phú Thuận",
            value: 30700,
            slug: "phu-thuan",
            status: "empty",
          },
          {
            label: "Vĩnh Chánh",
            value: 30703,
            slug: "vinh-chanh",
            status: "empty",
          },
          { label: "Định Mỹ", value: 30706, slug: "dinh-my", status: "empty" },
          {
            label: "Định Thành",
            value: 30709,
            slug: "dinh-thanh",
            status: "empty",
          },
          {
            label: "Mỹ Phú Đông",
            value: 30712,
            slug: "my-phu-dong",
            status: "empty",
          },
          {
            label: "Vọng Đông",
            value: 30715,
            slug: "vong-dong",
            status: "empty",
          },
          {
            label: "Vĩnh Khánh",
            value: 30718,
            slug: "vinh-khanh",
            status: "empty",
          },
          {
            label: "Thoại Giang",
            value: 30721,
            slug: "thoai-giang",
            status: "empty",
          },
          {
            label: "Bình Thành",
            value: 30724,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Vọng Thê",
            value: 30727,
            slug: "vong-the",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Kiên Giang",
    value: 91,
    slug: "kien-giang",
    status: "filled",
    district: [
      {
        label: "Thành phố Rạch Giá",
        value: 899,
        slug: "thanh-pho-rach-gia",
        status: "filled",
        ward: [
          {
            label: "Vĩnh Thanh",
            value: 30733,
            slug: "vinh-thanh",
            status: "filled",
          },
          {
            label: "Vĩnh Quang",
            value: 30736,
            slug: "vinh-quang",
            status: "empty",
          },
          {
            label: "Vĩnh Hiệp",
            value: 30739,
            slug: "vinh-hiep",
            status: "empty",
          },
          {
            label: "Vĩnh Thanh Vân",
            value: 30742,
            slug: "vinh-thanh-van",
            status: "empty",
          },
          {
            label: "Vĩnh Lạc",
            value: 30745,
            slug: "vinh-lac",
            status: "filled",
          },
          { label: "An Hòa", value: 30748, slug: "an-hoa", status: "empty" },
          { label: "An Bình", value: 30751, slug: "an-binh", status: "empty" },
          {
            label: "Rạch Sỏi",
            value: 30754,
            slug: "rach-soi",
            status: "empty",
          },
          {
            label: "Vĩnh Lợi",
            value: 30757,
            slug: "vinh-loi",
            status: "filled",
          },
          {
            label: "Vĩnh Thông",
            value: 30760,
            slug: "vinh-thong",
            status: "empty",
          },
          {
            label: "Phi Thông",
            value: 30763,
            slug: "phi-thong",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Hà Tiên",
        value: 900,
        slug: "thanh-pho-ha-tien",
        status: "filled",
        ward: [
          { label: "Tô Châu", value: 30766, slug: "to-chau", status: "empty" },
          { label: "Đông Hồ", value: 30769, slug: "dong-ho", status: "empty" },
          {
            label: "Bình San",
            value: 30772,
            slug: "binh-san",
            status: "empty",
          },
          {
            label: "Pháo Đài",
            value: 30775,
            slug: "phao-dai",
            status: "filled",
          },
          { label: "Mỹ Đức", value: 30778, slug: "my-duc", status: "empty" },
          {
            label: "Tiên Hải",
            value: 30781,
            slug: "tien-hai",
            status: "empty",
          },
          {
            label: "Thuận Yên",
            value: 30784,
            slug: "thuan-yen",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Kiên Lương",
        value: 902,
        slug: "huyen-kien-luong",
        status: "filled",
        ward: [
          {
            label: "Kiên Lương",
            value: 30787,
            slug: "kien-luong",
            status: "filled",
          },
          {
            label: "Kiên Bình",
            value: 30790,
            slug: "kien-binh",
            status: "empty",
          },
          {
            label: "Hòa Điền",
            value: 30802,
            slug: "hoa-dien",
            status: "empty",
          },
          {
            label: "Dương Hòa",
            value: 30805,
            slug: "duong-hoa",
            status: "empty",
          },
          { label: "Bình An", value: 30808, slug: "binh-an", status: "empty" },
          {
            label: "Bình Trị",
            value: 30809,
            slug: "binh-tri",
            status: "empty",
          },
          { label: "Sơn Hải", value: 30811, slug: "son-hai", status: "empty" },
          {
            label: "Hòn Nghệ",
            value: 30814,
            slug: "hon-nghe",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hòn Đất",
        value: 903,
        slug: "huyen-hon-dat",
        status: "empty",
        ward: [
          { label: "Hòn Đất", value: 30817, slug: "hon-dat", status: "empty" },
          { label: "Sóc Sơn", value: 30820, slug: "soc-son", status: "empty" },
          {
            label: "Bình Sơn",
            value: 30823,
            slug: "binh-son",
            status: "empty",
          },
          {
            label: "Bình Giang",
            value: 30826,
            slug: "binh-giang",
            status: "empty",
          },
          { label: "Mỹ Thái", value: 30828, slug: "my-thai", status: "empty" },
          {
            label: "Nam Thái Sơn",
            value: 30829,
            slug: "nam-thai-son",
            status: "empty",
          },
          {
            label: "Mỹ Hiệp Sơn",
            value: 30832,
            slug: "my-hiep-son",
            status: "empty",
          },
          {
            label: "Sơn Kiên",
            value: 30835,
            slug: "son-kien",
            status: "empty",
          },
          {
            label: "Sơn Bình",
            value: 30836,
            slug: "son-binh",
            status: "empty",
          },
          {
            label: "Mỹ Thuận",
            value: 30838,
            slug: "my-thuan",
            status: "empty",
          },
          {
            label: "Lình Huỳnh",
            value: 30840,
            slug: "linh-huynh",
            status: "empty",
          },
          { label: "Thổ Sơn", value: 30841, slug: "tho-son", status: "empty" },
          { label: "Mỹ Lâm", value: 30844, slug: "my-lam", status: "empty" },
          {
            label: "Mỹ Phước",
            value: 30847,
            slug: "my-phuoc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Tân Hiệp",
        value: 904,
        slug: "huyen-tan-hiep",
        status: "empty",
        ward: [
          {
            label: "Tân Hiệp",
            value: 30850,
            slug: "tan-hiep",
            status: "empty",
          },
          { label: "Tân Hội", value: 30853, slug: "tan-hoi", status: "empty" },
          {
            label: "Tân Thành",
            value: 30856,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Tân Hiệp B",
            value: 30859,
            slug: "tan-hiep-b",
            status: "empty",
          },
          { label: "Tân Hoà", value: 30860, slug: "tan-hoa", status: "empty" },
          {
            label: "Thạnh Đông B",
            value: 30862,
            slug: "thanh-dong-b",
            status: "empty",
          },
          {
            label: "Thạnh Đông",
            value: 30865,
            slug: "thanh-dong",
            status: "empty",
          },
          {
            label: "Tân Hiệp A",
            value: 30868,
            slug: "tan-hiep-a",
            status: "empty",
          },
          { label: "Tân An", value: 30871, slug: "tan-an", status: "empty" },
          {
            label: "Thạnh Đông A",
            value: 30874,
            slug: "thanh-dong-a",
            status: "empty",
          },
          {
            label: "Thạnh Trị",
            value: 30877,
            slug: "thanh-tri",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Châu Thành",
        value: 905,
        slug: "huyen-chau-thanh",
        status: "empty",
        ward: [
          {
            label: "Minh Lương",
            value: 30880,
            slug: "minh-luong",
            status: "empty",
          },
          {
            label: "Mong Thọ A",
            value: 30883,
            slug: "mong-tho-a",
            status: "empty",
          },
          {
            label: "Mong Thọ B",
            value: 30886,
            slug: "mong-tho-b",
            status: "empty",
          },
          {
            label: "Mong Thọ",
            value: 30887,
            slug: "mong-tho",
            status: "empty",
          },
          {
            label: "Giục Tượng",
            value: 30889,
            slug: "giuc-tuong",
            status: "empty",
          },
          {
            label: "Vĩnh Hòa Hiệp",
            value: 30892,
            slug: "vinh-hoa-hiep",
            status: "empty",
          },
          {
            label: "Vĩnh Hoà Phú",
            value: 30893,
            slug: "vinh-hoa-phu",
            status: "empty",
          },
          {
            label: "Minh Hòa",
            value: 30895,
            slug: "minh-hoa",
            status: "empty",
          },
          { label: "Bình An", value: 30898, slug: "binh-an", status: "empty" },
          {
            label: "Thạnh Lộc",
            value: 30901,
            slug: "thanh-loc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Giồng Riềng",
        value: 906,
        slug: "huyen-giong-rieng",
        status: "empty",
        ward: [
          {
            label: "Giồng Riềng",
            value: 30904,
            slug: "giong-rieng",
            status: "empty",
          },
          {
            label: "Thạnh Hưng",
            value: 30907,
            slug: "thanh-hung",
            status: "empty",
          },
          {
            label: "Thạnh Phước",
            value: 30910,
            slug: "thanh-phuoc",
            status: "empty",
          },
          {
            label: "Thạnh Lộc",
            value: 30913,
            slug: "thanh-loc",
            status: "empty",
          },
          {
            label: "Thạnh Hòa",
            value: 30916,
            slug: "thanh-hoa",
            status: "empty",
          },
          {
            label: "Thạnh Bình",
            value: 30917,
            slug: "thanh-binh",
            status: "empty",
          },
          {
            label: "Bàn Thạch",
            value: 30919,
            slug: "ban-thach",
            status: "empty",
          },
          {
            label: "Bàn Tân Định",
            value: 30922,
            slug: "ban-tan-dinh",
            status: "empty",
          },
          {
            label: "Ngọc Thành",
            value: 30925,
            slug: "ngoc-thanh",
            status: "empty",
          },
          {
            label: "Ngọc Chúc",
            value: 30928,
            slug: "ngoc-chuc",
            status: "empty",
          },
          {
            label: "Ngọc Thuận",
            value: 30931,
            slug: "ngoc-thuan",
            status: "empty",
          },
          {
            label: "Hòa Hưng",
            value: 30934,
            slug: "hoa-hung",
            status: "empty",
          },
          { label: "Hoà Lợi", value: 30937, slug: "hoa-loi", status: "empty" },
          { label: "Hoà An", value: 30940, slug: "hoa-an", status: "empty" },
          {
            label: "Long Thạnh",
            value: 30943,
            slug: "long-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Thạnh",
            value: 30946,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Vĩnh Phú",
            value: 30947,
            slug: "vinh-phu",
            status: "empty",
          },
          {
            label: "Hòa Thuận",
            value: 30949,
            slug: "hoa-thuan",
            status: "empty",
          },
          {
            label: "Ngọc Hoà",
            value: 30950,
            slug: "ngoc-hoa",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Gò Quao",
        value: 907,
        slug: "huyen-go-quao",
        status: "empty",
        ward: [
          { label: "Gò Quao", value: 30952, slug: "go-quao", status: "empty" },
          {
            label: "Vĩnh Hòa Hưng Bắc",
            value: 30955,
            slug: "vinh-hoa-hung-bac",
            status: "empty",
          },
          {
            label: "Định Hòa",
            value: 30958,
            slug: "dinh-hoa",
            status: "empty",
          },
          {
            label: "Thới Quản",
            value: 30961,
            slug: "thoi-quan",
            status: "empty",
          },
          { label: "Định An", value: 30964, slug: "dinh-an", status: "empty" },
          {
            label: "Thủy Liễu",
            value: 30967,
            slug: "thuy-lieu",
            status: "empty",
          },
          {
            label: "Vĩnh Hòa Hưng Nam",
            value: 30970,
            slug: "vinh-hoa-hung-nam",
            status: "empty",
          },
          {
            label: "Vĩnh Phước A",
            value: 30973,
            slug: "vinh-phuoc-a",
            status: "empty",
          },
          {
            label: "Vĩnh Phước B",
            value: 30976,
            slug: "vinh-phuoc-b",
            status: "empty",
          },
          {
            label: "Vĩnh Tuy",
            value: 30979,
            slug: "vinh-tuy",
            status: "empty",
          },
          {
            label: "Vĩnh Thắng",
            value: 30982,
            slug: "vinh-thang",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện An Biên",
        value: 908,
        slug: "huyen-an-bien",
        status: "empty",
        ward: [
          { label: "Thứ Ba", value: 30985, slug: "thu-ba", status: "empty" },
          { label: "Tây Yên", value: 30988, slug: "tay-yen", status: "empty" },
          {
            label: "Tây Yên A",
            value: 30991,
            slug: "tay-yen-a",
            status: "empty",
          },
          { label: "Nam Yên", value: 30994, slug: "nam-yen", status: "empty" },
          {
            label: "Hưng Yên",
            value: 30997,
            slug: "hung-yen",
            status: "empty",
          },
          {
            label: "Nam Thái",
            value: 31000,
            slug: "nam-thai",
            status: "empty",
          },
          {
            label: "Nam Thái A",
            value: 31003,
            slug: "nam-thai-a",
            status: "empty",
          },
          {
            label: "Đông Thái",
            value: 31006,
            slug: "dong-thai",
            status: "empty",
          },
          {
            label: "Đông Yên",
            value: 31009,
            slug: "dong-yen",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện An Minh",
        value: 909,
        slug: "huyen-an-minh",
        status: "empty",
        ward: [
          {
            label: "Thứ Mười Một",
            value: 31018,
            slug: "thu-muoi-mot",
            status: "empty",
          },
          {
            label: "Thuận Hoà",
            value: 31021,
            slug: "thuan-hoa",
            status: "empty",
          },
          {
            label: "Đông Hòa",
            value: 31024,
            slug: "dong-hoa",
            status: "empty",
          },
          {
            label: "Đông Thạnh",
            value: 31030,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Tân Thạnh",
            value: 31031,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Đông Hưng",
            value: 31033,
            slug: "dong-hung",
            status: "empty",
          },
          {
            label: "Đông Hưng A",
            value: 31036,
            slug: "dong-hung-a",
            status: "empty",
          },
          {
            label: "Đông Hưng B",
            value: 31039,
            slug: "dong-hung-b",
            status: "empty",
          },
          {
            label: "Vân Khánh",
            value: 31042,
            slug: "van-khanh",
            status: "empty",
          },
          {
            label: "Vân Khánh Đông",
            value: 31045,
            slug: "van-khanh-dong",
            status: "empty",
          },
          {
            label: "Vân Khánh Tây",
            value: 31048,
            slug: "van-khanh-tay",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vĩnh Thuận",
        value: 910,
        slug: "huyen-vinh-thuan",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Thuận",
            value: 31051,
            slug: "vinh-thuan",
            status: "empty",
          },
          {
            label: "Vĩnh Bình Bắc",
            value: 31060,
            slug: "vinh-binh-bac",
            status: "empty",
          },
          {
            label: "Vĩnh Bình Nam",
            value: 31063,
            slug: "vinh-binh-nam",
            status: "empty",
          },
          {
            label: "Bình Minh",
            value: 31064,
            slug: "binh-minh",
            status: "empty",
          },
          {
            label: "Vĩnh Thuận",
            value: 31069,
            slug: "vinh-thuan",
            status: "empty",
          },
          {
            label: "Tân Thuận",
            value: 31072,
            slug: "tan-thuan",
            status: "empty",
          },
          {
            label: "Phong Đông",
            value: 31074,
            slug: "phong-dong",
            status: "empty",
          },
          {
            label: "Vĩnh Phong",
            value: 31075,
            slug: "vinh-phong",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Phú Quốc",
        value: 911,
        slug: "thanh-pho-phu-quoc",
        status: "empty",
        ward: [
          {
            label: "Dương Đông",
            value: 31078,
            slug: "duong-dong",
            status: "empty",
          },
          { label: "An Thới", value: 31081, slug: "an-thoi", status: "empty" },
          { label: "Cửa Cạn", value: 31084, slug: "cua-can", status: "empty" },
          {
            label: "Gành Dầu",
            value: 31087,
            slug: "ganh-dau",
            status: "empty",
          },
          {
            label: "Cửa Dương",
            value: 31090,
            slug: "cua-duong",
            status: "empty",
          },
          {
            label: "Hàm Ninh",
            value: 31093,
            slug: "ham-ninh",
            status: "empty",
          },
          {
            label: "Dương Tơ",
            value: 31096,
            slug: "duong-to",
            status: "empty",
          },
          {
            label: "Bãi Thơm",
            value: 31102,
            slug: "bai-thom",
            status: "empty",
          },
          {
            label: "Thổ Châu",
            value: 31105,
            slug: "tho-chau",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Kiên Hải",
        value: 912,
        slug: "huyen-kien-hai",
        status: "empty",
        ward: [
          { label: "Hòn Tre", value: 31108, slug: "hon-tre", status: "empty" },
          { label: "Lại Sơn", value: 31111, slug: "lai-son", status: "empty" },
          { label: "An Sơn", value: 31114, slug: "an-son", status: "empty" },
          { label: "Nam Du", value: 31115, slug: "nam-du", status: "empty" },
        ],
      },
      {
        label: "Huyện U Minh Thượng",
        value: 913,
        slug: "huyen-u-minh-thuong",
        status: "empty",
        ward: [
          {
            label: "Thạnh Yên",
            value: 31012,
            slug: "thanh-yen",
            status: "empty",
          },
          {
            label: "Thạnh Yên A",
            value: 31015,
            slug: "thanh-yen-a",
            status: "empty",
          },
          {
            label: "An Minh Bắc",
            value: 31027,
            slug: "an-minh-bac",
            status: "empty",
          },
          {
            label: "Vĩnh Hòa",
            value: 31054,
            slug: "vinh-hoa",
            status: "empty",
          },
          {
            label: "Hoà Chánh",
            value: 31057,
            slug: "hoa-chanh",
            status: "empty",
          },
          {
            label: "Minh Thuận",
            value: 31066,
            slug: "minh-thuan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Giang Thành",
        value: 914,
        slug: "huyen-giang-thanh",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Phú",
            value: 30791,
            slug: "vinh-phu",
            status: "empty",
          },
          {
            label: "Vĩnh Điều",
            value: 30793,
            slug: "vinh-dieu",
            status: "empty",
          },
          {
            label: "Tân Khánh Hòa",
            value: 30796,
            slug: "tan-khanh-hoa",
            status: "empty",
          },
          { label: "Phú Lợi", value: 30797, slug: "phu-loi", status: "empty" },
          { label: "Phú Mỹ", value: 30799, slug: "phu-my", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Cần Thơ",
    value: 92,
    slug: "can-tho",
    status: "filled",
    district: [
      {
        label: "Quận Ninh Kiều",
        value: 916,
        slug: "quan-ninh-kieu",
        status: "filled",
        ward: [
          { label: "Cái Khế", value: 31117, slug: "cai-khe", status: "empty" },
          { label: "An Hòa", value: 31120, slug: "an-hoa", status: "filled" },
          {
            label: "Thới Bình",
            value: 31123,
            slug: "thoi-binh",
            status: "filled",
          },
          { label: "Tân An", value: 31135, slug: "tan-an", status: "empty" },
          {
            label: "Xuân Khánh",
            value: 31144,
            slug: "xuan-khanh",
            status: "empty",
          },
          {
            label: "Hưng Lợi",
            value: 31147,
            slug: "hung-loi",
            status: "empty",
          },
          {
            label: "An Khánh",
            value: 31149,
            slug: "an-khanh",
            status: "empty",
          },
          { label: "An Bình", value: 31150, slug: "an-binh", status: "empty" },
        ],
      },
      {
        label: "Quận Ô Môn",
        value: 917,
        slug: "quan-o-mon",
        status: "empty",
        ward: [
          {
            label: "Châu Văn Liêm",
            value: 31153,
            slug: "chau-van-liem",
            status: "empty",
          },
          {
            label: "Thới Hòa",
            value: 31154,
            slug: "thoi-hoa",
            status: "empty",
          },
          {
            label: "Thới Long",
            value: 31156,
            slug: "thoi-long",
            status: "empty",
          },
          {
            label: "Long Hưng",
            value: 31157,
            slug: "long-hung",
            status: "empty",
          },
          { label: "Thới An", value: 31159, slug: "thoi-an", status: "empty" },
          {
            label: "Phước Thới",
            value: 31162,
            slug: "phuoc-thoi",
            status: "empty",
          },
          {
            label: "Trường Lạc",
            value: 31165,
            slug: "truong-lac",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận Bình Thuỷ",
        value: 918,
        slug: "quan-binh-thuy",
        status: "empty",
        ward: [
          {
            label: "Bình Thủy",
            value: 31168,
            slug: "binh-thuy",
            status: "empty",
          },
          { label: "Trà An", value: 31169, slug: "tra-an", status: "empty" },
          { label: "Trà Nóc", value: 31171, slug: "tra-noc", status: "empty" },
          {
            label: "Thới An Đông",
            value: 31174,
            slug: "thoi-an-dong",
            status: "empty",
          },
          { label: "An Thới", value: 31177, slug: "an-thoi", status: "empty" },
          {
            label: "Bùi Hữu Nghĩa",
            value: 31178,
            slug: "bui-huu-nghia",
            status: "empty",
          },
          {
            label: "Long Hòa",
            value: 31180,
            slug: "long-hoa",
            status: "empty",
          },
          {
            label: "Long Tuyền",
            value: 31183,
            slug: "long-tuyen",
            status: "empty",
          },
        ],
      },
      {
        label: "Quận Cái Răng",
        value: 919,
        slug: "quan-cai-rang",
        status: "filled",
        ward: [
          { label: "Lê Bình", value: 31186, slug: "le-binh", status: "empty" },
          {
            label: "Hưng Phú",
            value: 31189,
            slug: "hung-phu",
            status: "empty",
          },
          {
            label: "Hưng Thạnh",
            value: 31192,
            slug: "hung-thanh",
            status: "empty",
          },
          { label: "Ba Láng", value: 31195, slug: "ba-lang", status: "empty" },
          {
            label: "Thường Thạnh",
            value: 31198,
            slug: "thuong-thanh",
            status: "empty",
          },
          { label: "Phú Thứ", value: 31201, slug: "phu-thu", status: "filled" },
          { label: "Tân Phú", value: 31204, slug: "tan-phu", status: "empty" },
        ],
      },
      {
        label: "Quận Thốt Nốt",
        value: 923,
        slug: "quan-thot-not",
        status: "empty",
        ward: [
          {
            label: "Thốt Nốt",
            value: 31207,
            slug: "thot-not",
            status: "empty",
          },
          {
            label: "Thới Thuận",
            value: 31210,
            slug: "thoi-thuan",
            status: "empty",
          },
          {
            label: "Thuận An",
            value: 31212,
            slug: "thuan-an",
            status: "empty",
          },
          { label: "Tân Lộc", value: 31213, slug: "tan-loc", status: "empty" },
          {
            label: "Trung Nhứt",
            value: 31216,
            slug: "trung-nhut",
            status: "empty",
          },
          {
            label: "Thạnh Hoà",
            value: 31217,
            slug: "thanh-hoa",
            status: "empty",
          },
          {
            label: "Trung Kiên",
            value: 31219,
            slug: "trung-kien",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 31227,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "Thuận Hưng",
            value: 31228,
            slug: "thuan-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vĩnh Thạnh",
        value: 924,
        slug: "huyen-vinh-thanh",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Bình",
            value: 31211,
            slug: "vinh-binh",
            status: "empty",
          },
          {
            label: "Thanh An",
            value: 31231,
            slug: "thanh-an",
            status: "empty",
          },
          {
            label: "Vĩnh Thạnh",
            value: 31232,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Thạnh Mỹ",
            value: 31234,
            slug: "thanh-my",
            status: "empty",
          },
          {
            label: "Vĩnh Trinh",
            value: 31237,
            slug: "vinh-trinh",
            status: "empty",
          },
          {
            label: "Thạnh An",
            value: 31240,
            slug: "thanh-an",
            status: "empty",
          },
          {
            label: "Thạnh Tiến",
            value: 31241,
            slug: "thanh-tien",
            status: "empty",
          },
          {
            label: "Thạnh Thắng",
            value: 31243,
            slug: "thanh-thang",
            status: "empty",
          },
          {
            label: "Thạnh Lợi",
            value: 31244,
            slug: "thanh-loi",
            status: "empty",
          },
          {
            label: "Thạnh Qưới",
            value: 31246,
            slug: "thanh-quoi",
            status: "empty",
          },
          {
            label: "Thạnh Lộc",
            value: 31252,
            slug: "thanh-loc",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cờ Đỏ",
        value: 925,
        slug: "huyen-co-do",
        status: "empty",
        ward: [
          {
            label: "Trung An",
            value: 31222,
            slug: "trung-an",
            status: "empty",
          },
          {
            label: "Trung Thạnh",
            value: 31225,
            slug: "trung-thanh",
            status: "empty",
          },
          {
            label: "Thạnh Phú",
            value: 31249,
            slug: "thanh-phu",
            status: "empty",
          },
          {
            label: "Trung Hưng",
            value: 31255,
            slug: "trung-hung",
            status: "empty",
          },
          { label: "Cờ Đỏ", value: 31261, slug: "co-do", status: "empty" },
          {
            label: "Thới Hưng",
            value: 31264,
            slug: "thoi-hung",
            status: "empty",
          },
          {
            label: "Đông Hiệp",
            value: 31273,
            slug: "dong-hiep",
            status: "empty",
          },
          {
            label: "Đông Thắng",
            value: 31274,
            slug: "dong-thang",
            status: "empty",
          },
          {
            label: "Thới Đông",
            value: 31276,
            slug: "thoi-dong",
            status: "empty",
          },
          {
            label: "Thới Xuân",
            value: 31277,
            slug: "thoi-xuan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phong Điền",
        value: 926,
        slug: "huyen-phong-dien",
        status: "empty",
        ward: [
          {
            label: "Phong Điền",
            value: 31299,
            slug: "phong-dien",
            status: "empty",
          },
          { label: "Nhơn Ái", value: 31300, slug: "nhon-ai", status: "empty" },
          {
            label: "Giai Xuân",
            value: 31303,
            slug: "giai-xuan",
            status: "empty",
          },
          {
            label: "Tân Thới",
            value: 31306,
            slug: "tan-thoi",
            status: "empty",
          },
          {
            label: "Trường Long",
            value: 31309,
            slug: "truong-long",
            status: "empty",
          },
          {
            label: "Mỹ Khánh",
            value: 31312,
            slug: "my-khanh",
            status: "empty",
          },
          {
            label: "Nhơn Nghĩa",
            value: 31315,
            slug: "nhon-nghia",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thới Lai",
        value: 927,
        slug: "huyen-thoi-lai",
        status: "empty",
        ward: [
          {
            label: "Thới Lai",
            value: 31258,
            slug: "thoi-lai",
            status: "empty",
          },
          {
            label: "Thới Thạnh",
            value: 31267,
            slug: "thoi-thanh",
            status: "empty",
          },
          {
            label: "Tân Thạnh",
            value: 31268,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Xuân Thắng",
            value: 31270,
            slug: "xuan-thang",
            status: "empty",
          },
          {
            label: "Đông Bình",
            value: 31279,
            slug: "dong-binh",
            status: "empty",
          },
          {
            label: "Đông Thuận",
            value: 31282,
            slug: "dong-thuan",
            status: "empty",
          },
          {
            label: "Thới Tân",
            value: 31285,
            slug: "thoi-tan",
            status: "empty",
          },
          {
            label: "Trường Thắng",
            value: 31286,
            slug: "truong-thang",
            status: "empty",
          },
          {
            label: "Định Môn",
            value: 31288,
            slug: "dinh-mon",
            status: "empty",
          },
          {
            label: "Trường Thành",
            value: 31291,
            slug: "truong-thanh",
            status: "empty",
          },
          {
            label: "Trường Xuân",
            value: 31294,
            slug: "truong-xuan",
            status: "empty",
          },
          {
            label: "Trường Xuân A",
            value: 31297,
            slug: "truong-xuan-a",
            status: "empty",
          },
          {
            label: "Trường Xuân B",
            value: 31298,
            slug: "truong-xuan-b",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Hậu Giang",
    value: 93,
    slug: "hau-giang",
    status: "filled",
    district: [
      {
        label: "Thành phố Vị Thanh",
        value: 930,
        slug: "thanh-pho-vi-thanh",
        status: "filled",
        ward: [
          {
            label: "Phường I",
            value: 31318,
            slug: "phuong-i",
            status: "empty",
          },
          {
            label: "Phường III",
            value: 31321,
            slug: "phuong-iii",
            status: "empty",
          },
          {
            label: "Phường IV",
            value: 31324,
            slug: "phuong-iv",
            status: "empty",
          },
          {
            label: "Phường V",
            value: 31327,
            slug: "phuong-v",
            status: "filled",
          },
          {
            label: "Phường VII",
            value: 31330,
            slug: "phuong-vii",
            status: "empty",
          },
          { label: "Vị Tân", value: 31333, slug: "vi-tan", status: "empty" },
          { label: "Hoả Lựu", value: 31336, slug: "hoa-luu", status: "empty" },
          {
            label: "Tân Tiến",
            value: 31338,
            slug: "tan-tien",
            status: "empty",
          },
          {
            label: "Hoả Tiến",
            value: 31339,
            slug: "hoa-tien",
            status: "empty",
          },
        ],
      },
      {
        label: "Thành phố Ngã Bảy",
        value: 931,
        slug: "thanh-pho-nga-bay",
        status: "empty",
        ward: [
          { label: "Ngã Bảy", value: 31340, slug: "nga-bay", status: "empty" },
          {
            label: "Lái Hiếu",
            value: 31341,
            slug: "lai-hieu",
            status: "empty",
          },
          {
            label: "Hiệp Thành",
            value: 31343,
            slug: "hiep-thanh",
            status: "empty",
          },
          {
            label: "Hiệp Lợi",
            value: 31344,
            slug: "hiep-loi",
            status: "empty",
          },
          {
            label: "Đại Thành",
            value: 31411,
            slug: "dai-thanh",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 31414,
            slug: "tan-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Châu Thành A",
        value: 932,
        slug: "huyen-chau-thanh-a",
        status: "empty",
        ward: [
          {
            label: "Một Ngàn",
            value: 31342,
            slug: "mot-ngan",
            status: "empty",
          },
          { label: "Tân Hoà", value: 31345, slug: "tan-hoa", status: "empty" },
          {
            label: "Bảy Ngàn",
            value: 31346,
            slug: "bay-ngan",
            status: "empty",
          },
          {
            label: "Trường Long Tây",
            value: 31348,
            slug: "truong-long-tay",
            status: "empty",
          },
          {
            label: "Trường Long A",
            value: 31351,
            slug: "truong-long-a",
            status: "empty",
          },
          {
            label: "Nhơn Nghĩa A",
            value: 31357,
            slug: "nhon-nghia-a",
            status: "empty",
          },
          {
            label: "Rạch Gòi",
            value: 31359,
            slug: "rach-goi",
            status: "empty",
          },
          {
            label: "Thạnh Xuân",
            value: 31360,
            slug: "thanh-xuan",
            status: "empty",
          },
          { label: "Cái Tắc", value: 31362, slug: "cai-tac", status: "empty" },
          {
            label: "Tân Phú Thạnh",
            value: 31363,
            slug: "tan-phu-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Châu Thành",
        value: 933,
        slug: "huyen-chau-thanh",
        status: "empty",
        ward: [
          { label: "Ngã Sáu", value: 31366, slug: "nga-sau", status: "empty" },
          {
            label: "Đông Thạnh",
            value: 31369,
            slug: "dong-thanh",
            status: "empty",
          },
          {
            label: "Đông Phú",
            value: 31375,
            slug: "dong-phu",
            status: "empty",
          },
          { label: "Phú Hữu", value: 31378, slug: "phu-huu", status: "empty" },
          { label: "Phú Tân", value: 31379, slug: "phu-tan", status: "empty" },
          { label: "Mái Dầm", value: 31381, slug: "mai-dam", status: "empty" },
          {
            label: "Đông Phước",
            value: 31384,
            slug: "dong-phuoc",
            status: "empty",
          },
          {
            label: "Đông Phước A",
            value: 31387,
            slug: "dong-phuoc-a",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phụng Hiệp",
        value: 934,
        slug: "huyen-phung-hiep",
        status: "empty",
        ward: [
          {
            label: "Kinh Cùng",
            value: 31393,
            slug: "kinh-cung",
            status: "empty",
          },
          {
            label: "Cây Dương",
            value: 31396,
            slug: "cay-duong",
            status: "empty",
          },
          {
            label: "Tân Bình",
            value: 31399,
            slug: "tan-binh",
            status: "empty",
          },
          {
            label: "Bình Thành",
            value: 31402,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Thạnh Hòa",
            value: 31405,
            slug: "thanh-hoa",
            status: "empty",
          },
          {
            label: "Long Thạnh",
            value: 31408,
            slug: "long-thanh",
            status: "empty",
          },
          {
            label: "Phụng Hiệp",
            value: 31417,
            slug: "phung-hiep",
            status: "empty",
          },
          { label: "Hòa Mỹ", value: 31420, slug: "hoa-my", status: "empty" },
          { label: "Hòa An", value: 31423, slug: "hoa-an", status: "empty" },
          {
            label: "Phương Bình",
            value: 31426,
            slug: "phuong-binh",
            status: "empty",
          },
          {
            label: "Hiệp Hưng",
            value: 31429,
            slug: "hiep-hung",
            status: "empty",
          },
          {
            label: "Tân Phước Hưng",
            value: 31432,
            slug: "tan-phuoc-hung",
            status: "empty",
          },
          {
            label: "Búng Tàu",
            value: 31433,
            slug: "bung-tau",
            status: "empty",
          },
          {
            label: "Phương Phú",
            value: 31435,
            slug: "phuong-phu",
            status: "empty",
          },
          {
            label: "Tân Long",
            value: 31438,
            slug: "tan-long",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vị Thuỷ",
        value: 935,
        slug: "huyen-vi-thuy",
        status: "empty",
        ward: [
          {
            label: "Nàng Mau",
            value: 31441,
            slug: "nang-mau",
            status: "empty",
          },
          {
            label: "Vị Trung",
            value: 31444,
            slug: "vi-trung",
            status: "empty",
          },
          { label: "Vị Thuỷ", value: 31447, slug: "vi-thuy", status: "empty" },
          {
            label: "Vị Thắng",
            value: 31450,
            slug: "vi-thang",
            status: "empty",
          },
          {
            label: "Vĩnh Thuận Tây",
            value: 31453,
            slug: "vinh-thuan-tay",
            status: "empty",
          },
          {
            label: "Vĩnh Trung",
            value: 31456,
            slug: "vinh-trung",
            status: "empty",
          },
          {
            label: "Vĩnh Tường",
            value: 31459,
            slug: "vinh-tuong",
            status: "empty",
          },
          { label: "Vị Đông", value: 31462, slug: "vi-dong", status: "empty" },
          {
            label: "Vị Thanh",
            value: 31465,
            slug: "vi-thanh",
            status: "empty",
          },
          { label: "Vị Bình", value: 31468, slug: "vi-binh", status: "empty" },
        ],
      },
      {
        label: "Huyện Long Mỹ",
        value: 936,
        slug: "huyen-long-my",
        status: "filled",
        ward: [
          {
            label: "Thuận Hưng",
            value: 31483,
            slug: "thuan-hung",
            status: "filled",
          },
          {
            label: "Thuận Hòa",
            value: 31484,
            slug: "thuan-hoa",
            status: "empty",
          },
          {
            label: "Vĩnh Thuận Đông",
            value: 31486,
            slug: "vinh-thuan-dong",
            status: "empty",
          },
          {
            label: "Vĩnh Viễn",
            value: 31489,
            slug: "vinh-vien",
            status: "empty",
          },
          {
            label: "Vĩnh Viễn A",
            value: 31490,
            slug: "vinh-vien-a",
            status: "empty",
          },
          {
            label: "Lương Tâm",
            value: 31492,
            slug: "luong-tam",
            status: "empty",
          },
          {
            label: "Lương Nghĩa",
            value: 31493,
            slug: "luong-nghia",
            status: "empty",
          },
          {
            label: "Xà Phiên",
            value: 31495,
            slug: "xa-phien",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Long Mỹ",
        value: 937,
        slug: "thi-xa-long-my",
        status: "empty",
        ward: [
          {
            label: "Thuận An",
            value: 31471,
            slug: "thuan-an",
            status: "empty",
          },
          {
            label: "Trà Lồng",
            value: 31472,
            slug: "tra-long",
            status: "empty",
          },
          {
            label: "Bình Thạnh",
            value: 31473,
            slug: "binh-thanh",
            status: "empty",
          },
          {
            label: "Long Bình",
            value: 31474,
            slug: "long-binh",
            status: "empty",
          },
          {
            label: "Vĩnh Tường",
            value: 31475,
            slug: "vinh-tuong",
            status: "empty",
          },
          {
            label: "Long Trị",
            value: 31477,
            slug: "long-tri",
            status: "empty",
          },
          {
            label: "Long Trị A",
            value: 31478,
            slug: "long-tri-a",
            status: "empty",
          },
          {
            label: "Long Phú",
            value: 31480,
            slug: "long-phu",
            status: "empty",
          },
          { label: "Tân Phú", value: 31481, slug: "tan-phu", status: "empty" },
        ],
      },
    ],
  },
  {
    label: "Sóc Trăng",
    value: 94,
    slug: "soc-trang",
    status: "filled",
    district: [
      {
        label: "Thành phố Sóc Trăng",
        value: 941,
        slug: "thanh-pho-soc-trang",
        status: "empty",
        ward: [
          {
            label: "Phường 5",
            value: 31498,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 31501,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Phường 8",
            value: 31504,
            slug: "phuong-8",
            status: "empty",
          },
          {
            label: "Phường 6",
            value: 31507,
            slug: "phuong-6",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 31510,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 4",
            value: 31516,
            slug: "phuong-4",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 31519,
            slug: "phuong-3",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 31522,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 10",
            value: 31525,
            slug: "phuong-10",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Châu Thành",
        value: 942,
        slug: "huyen-chau-thanh",
        status: "empty",
        ward: [
          {
            label: "Châu Thành",
            value: 31569,
            slug: "chau-thanh",
            status: "empty",
          },
          {
            label: "Hồ Đắc Kiện",
            value: 31570,
            slug: "ho-dac-kien",
            status: "empty",
          },
          { label: "Phú Tâm", value: 31573, slug: "phu-tam", status: "empty" },
          {
            label: "Thuận Hòa",
            value: 31576,
            slug: "thuan-hoa",
            status: "empty",
          },
          { label: "Phú Tân", value: 31582, slug: "phu-tan", status: "empty" },
          {
            label: "Thiện Mỹ",
            value: 31585,
            slug: "thien-my",
            status: "empty",
          },
          { label: "An Hiệp", value: 31594, slug: "an-hiep", status: "empty" },
          { label: "An Ninh", value: 31600, slug: "an-ninh", status: "empty" },
        ],
      },
      {
        label: "Huyện Kế Sách",
        value: 943,
        slug: "huyen-ke-sach",
        status: "empty",
        ward: [
          { label: "Kế Sách", value: 31528, slug: "ke-sach", status: "empty" },
          {
            label: "An Lạc Thôn",
            value: 31531,
            slug: "an-lac-thon",
            status: "empty",
          },
          {
            label: "Xuân Hòa",
            value: 31534,
            slug: "xuan-hoa",
            status: "empty",
          },
          {
            label: "Phong Nẫm",
            value: 31537,
            slug: "phong-nam",
            status: "empty",
          },
          {
            label: "An Lạc Tây",
            value: 31540,
            slug: "an-lac-tay",
            status: "empty",
          },
          {
            label: "Trinh Phú",
            value: 31543,
            slug: "trinh-phu",
            status: "empty",
          },
          {
            label: "Ba Trinh",
            value: 31546,
            slug: "ba-trinh",
            status: "empty",
          },
          {
            label: "Thới An Hội",
            value: 31549,
            slug: "thoi-an-hoi",
            status: "empty",
          },
          { label: "Nhơn Mỹ", value: 31552, slug: "nhon-my", status: "empty" },
          {
            label: "Kế Thành",
            value: 31555,
            slug: "ke-thanh",
            status: "empty",
          },
          { label: "Kế An", value: 31558, slug: "ke-an", status: "empty" },
          { label: "Đại Hải", value: 31561, slug: "dai-hai", status: "empty" },
          { label: "An Mỹ", value: 31564, slug: "an-my", status: "empty" },
        ],
      },
      {
        label: "Huyện Mỹ Tú",
        value: 944,
        slug: "huyen-my-tu",
        status: "empty",
        ward: [
          {
            label: "Huỳnh Hữu Nghĩa",
            value: 31567,
            slug: "huynh-huu-nghia",
            status: "empty",
          },
          {
            label: "Long Hưng",
            value: 31579,
            slug: "long-hung",
            status: "empty",
          },
          {
            label: "Hưng Phú",
            value: 31588,
            slug: "hung-phu",
            status: "empty",
          },
          {
            label: "Mỹ Hương",
            value: 31591,
            slug: "my-huong",
            status: "empty",
          },
          { label: "Mỹ Tú", value: 31597, slug: "my-tu", status: "empty" },
          {
            label: "Mỹ Phước",
            value: 31603,
            slug: "my-phuoc",
            status: "empty",
          },
          {
            label: "Thuận Hưng",
            value: 31606,
            slug: "thuan-hung",
            status: "empty",
          },
          {
            label: "Mỹ Thuận",
            value: 31609,
            slug: "my-thuan",
            status: "empty",
          },
          { label: "Phú Mỹ", value: 31612, slug: "phu-my", status: "empty" },
        ],
      },
      {
        label: "Huyện Cù Lao Dung",
        value: 945,
        slug: "huyen-cu-lao-dung",
        status: "empty",
        ward: [
          {
            label: "Cù Lao Dung",
            value: 31615,
            slug: "cu-lao-dung",
            status: "empty",
          },
          {
            label: "An Thạnh 1",
            value: 31618,
            slug: "an-thanh-1",
            status: "empty",
          },
          {
            label: "An Thạnh Tây",
            value: 31621,
            slug: "an-thanh-tay",
            status: "empty",
          },
          {
            label: "An Thạnh Đông",
            value: 31624,
            slug: "an-thanh-dong",
            status: "empty",
          },
          {
            label: "Đại Ân 1",
            value: 31627,
            slug: "dai-an-1",
            status: "empty",
          },
          {
            label: "An Thạnh 2",
            value: 31630,
            slug: "an-thanh-2",
            status: "empty",
          },
          {
            label: "An Thạnh 3",
            value: 31633,
            slug: "an-thanh-3",
            status: "empty",
          },
          {
            label: "An Thạnh Nam",
            value: 31636,
            slug: "an-thanh-nam",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Long Phú",
        value: 946,
        slug: "huyen-long-phu",
        status: "filled",
        ward: [
          {
            label: "Long Phú",
            value: 31639,
            slug: "long-phu",
            status: "filled",
          },
          {
            label: "Song Phụng",
            value: 31642,
            slug: "song-phung",
            status: "empty",
          },
          {
            label: "Đại Ngãi",
            value: 31645,
            slug: "dai-ngai",
            status: "empty",
          },
          {
            label: "Hậu Thạnh",
            value: 31648,
            slug: "hau-thanh",
            status: "empty",
          },
          {
            label: "Long Đức",
            value: 31651,
            slug: "long-duc",
            status: "empty",
          },
          {
            label: "Trường Khánh",
            value: 31654,
            slug: "truong-khanh",
            status: "empty",
          },
          { label: "Phú Hữu", value: 31657, slug: "phu-huu", status: "empty" },
          {
            label: "Tân Hưng",
            value: 31660,
            slug: "tan-hung",
            status: "empty",
          },
          {
            label: "Châu Khánh",
            value: 31663,
            slug: "chau-khanh",
            status: "empty",
          },
          {
            label: "Tân Thạnh",
            value: 31666,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Long Phú",
            value: 31669,
            slug: "long-phu",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Mỹ Xuyên",
        value: 947,
        slug: "huyen-my-xuyen",
        status: "empty",
        ward: [
          {
            label: "Mỹ Xuyên",
            value: 31684,
            slug: "my-xuyen",
            status: "empty",
          },
          { label: "Đại Tâm", value: 31690, slug: "dai-tam", status: "empty" },
          {
            label: "Tham Đôn",
            value: 31693,
            slug: "tham-don",
            status: "empty",
          },
          {
            label: "Thạnh Phú",
            value: 31708,
            slug: "thanh-phu",
            status: "empty",
          },
          {
            label: "Ngọc Đông",
            value: 31711,
            slug: "ngoc-dong",
            status: "empty",
          },
          {
            label: "Thạnh Quới",
            value: 31714,
            slug: "thanh-quoi",
            status: "empty",
          },
          {
            label: "Hòa Tú 1",
            value: 31717,
            slug: "hoa-tu-1",
            status: "empty",
          },
          {
            label: "Gia Hòa 1",
            value: 31720,
            slug: "gia-hoa-1",
            status: "empty",
          },
          { label: "Ngọc Tố", value: 31723, slug: "ngoc-to", status: "empty" },
          {
            label: "Gia Hòa 2",
            value: 31726,
            slug: "gia-hoa-2",
            status: "empty",
          },
          {
            label: "Hòa Tú II",
            value: 31729,
            slug: "hoa-tu-ii",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Ngã Năm",
        value: 948,
        slug: "thi-xa-nga-nam",
        status: "empty",
        ward: [
          {
            label: "Phường 1",
            value: 31732,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 31735,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Vĩnh Quới",
            value: 31738,
            slug: "vinh-quoi",
            status: "empty",
          },
          {
            label: "Tân Long",
            value: 31741,
            slug: "tan-long",
            status: "empty",
          },
          {
            label: "Long Bình",
            value: 31744,
            slug: "long-binh",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 31747,
            slug: "phuong-3",
            status: "empty",
          },
          { label: "Mỹ Bình", value: 31750, slug: "my-binh", status: "empty" },
          { label: "Mỹ Quới", value: 31753, slug: "my-quoi", status: "empty" },
        ],
      },
      {
        label: "Huyện Thạnh Trị",
        value: 949,
        slug: "huyen-thanh-tri",
        status: "empty",
        ward: [
          { label: "Phú Lộc", value: 31756, slug: "phu-loc", status: "empty" },
          {
            label: "Hưng Lợi",
            value: 31757,
            slug: "hung-loi",
            status: "empty",
          },
          { label: "Lâm Tân", value: 31759, slug: "lam-tan", status: "empty" },
          {
            label: "Thạnh Tân",
            value: 31762,
            slug: "thanh-tan",
            status: "empty",
          },
          {
            label: "Lâm Kiết",
            value: 31765,
            slug: "lam-kiet",
            status: "empty",
          },
          {
            label: "Tuân Tức",
            value: 31768,
            slug: "tuan-tuc",
            status: "empty",
          },
          {
            label: "Vĩnh Thành",
            value: 31771,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Thạnh Trị",
            value: 31774,
            slug: "thanh-tri",
            status: "empty",
          },
          {
            label: "Vĩnh Lợi",
            value: 31777,
            slug: "vinh-loi",
            status: "empty",
          },
          {
            label: "Châu Hưng",
            value: 31780,
            slug: "chau-hung",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Vĩnh Châu",
        value: 950,
        slug: "thi-xa-vinh-chau",
        status: "empty",
        ward: [
          {
            label: "Phường 1",
            value: 31783,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Hòa Đông",
            value: 31786,
            slug: "hoa-dong",
            status: "empty",
          },
          {
            label: "Khánh Hòa",
            value: 31789,
            slug: "khanh-hoa",
            status: "empty",
          },
          {
            label: "Vĩnh Hiệp",
            value: 31792,
            slug: "vinh-hiep",
            status: "empty",
          },
          {
            label: "Vĩnh Hải",
            value: 31795,
            slug: "vinh-hai",
            status: "empty",
          },
          { label: "Lạc Hòa", value: 31798, slug: "lac-hoa", status: "empty" },
          {
            label: "Phường 2",
            value: 31801,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Vĩnh Phước",
            value: 31804,
            slug: "vinh-phuoc",
            status: "empty",
          },
          {
            label: "Vĩnh Tân",
            value: 31807,
            slug: "vinh-tan",
            status: "empty",
          },
          { label: "Lai Hòa", value: 31810, slug: "lai-hoa", status: "empty" },
        ],
      },
      {
        label: "Huyện Trần Đề",
        value: 951,
        slug: "huyen-tran-de",
        status: "empty",
        ward: [
          {
            label: "Đại Ân 2",
            value: 31672,
            slug: "dai-an-2",
            status: "empty",
          },
          { label: "Trần Đề", value: 31673, slug: "tran-de", status: "empty" },
          { label: "Liêu Tú", value: 31675, slug: "lieu-tu", status: "empty" },
          {
            label: "Lịch Hội Thượng",
            value: 31678,
            slug: "lich-hoi-thuong",
            status: "empty",
          },
          {
            label: "Lịch Hội Thượng",
            value: 31679,
            slug: "lich-hoi-thuong",
            status: "empty",
          },
          {
            label: "Trung Bình",
            value: 31681,
            slug: "trung-binh",
            status: "empty",
          },
          { label: "Tài Văn", value: 31687, slug: "tai-van", status: "empty" },
          { label: "Viên An", value: 31696, slug: "vien-an", status: "empty" },
          {
            label: "Thạnh Thới An",
            value: 31699,
            slug: "thanh-thoi-an",
            status: "empty",
          },
          {
            label: "Thạnh Thới Thuận",
            value: 31702,
            slug: "thanh-thoi-thuan",
            status: "empty",
          },
          {
            label: "Viên Bình",
            value: 31705,
            slug: "vien-binh",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Bạc Liêu",
    value: 95,
    slug: "bac-lieu",
    status: "filled",
    district: [
      {
        label: "Thành phố Bạc Liêu",
        value: 954,
        slug: "thanh-pho-bac-lieu",
        status: "filled",
        ward: [
          {
            label: "Phường 2",
            value: 31813,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 3",
            value: 31816,
            slug: "phuong-3",
            status: "filled",
          },
          {
            label: "Phường 5",
            value: 31819,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 31822,
            slug: "phuong-7",
            status: "filled",
          },
          {
            label: "Phường 1",
            value: 31825,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 8",
            value: 31828,
            slug: "phuong-8",
            status: "empty",
          },
          { label: "Nhà Mát", value: 31831, slug: "nha-mat", status: "empty" },
          {
            label: "Vĩnh Trạch",
            value: 31834,
            slug: "vinh-trach",
            status: "empty",
          },
          {
            label: "Vĩnh Trạch Đông",
            value: 31837,
            slug: "vinh-trach-dong",
            status: "empty",
          },
          {
            label: "Hiệp Thành",
            value: 31840,
            slug: "hiep-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hồng Dân",
        value: 956,
        slug: "huyen-hong-dan",
        status: "empty",
        ward: [
          {
            label: "Ngan Dừa",
            value: 31843,
            slug: "ngan-dua",
            status: "empty",
          },
          {
            label: "Ninh Quới",
            value: 31846,
            slug: "ninh-quoi",
            status: "empty",
          },
          {
            label: "Ninh Quới A",
            value: 31849,
            slug: "ninh-quoi-a",
            status: "empty",
          },
          {
            label: "Ninh Hòa",
            value: 31852,
            slug: "ninh-hoa",
            status: "empty",
          },
          {
            label: "Lộc Ninh",
            value: 31855,
            slug: "loc-ninh",
            status: "empty",
          },
          {
            label: "Vĩnh Lộc",
            value: 31858,
            slug: "vinh-loc",
            status: "empty",
          },
          {
            label: "Vĩnh Lộc A",
            value: 31861,
            slug: "vinh-loc-a",
            status: "empty",
          },
          {
            label: "Ninh Thạnh Lợi A",
            value: 31863,
            slug: "ninh-thanh-loi-a",
            status: "empty",
          },
          {
            label: "Ninh Thạnh Lợi",
            value: 31864,
            slug: "ninh-thanh-loi",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phước Long",
        value: 957,
        slug: "huyen-phuoc-long",
        status: "empty",
        ward: [
          {
            label: "Phước Long",
            value: 31867,
            slug: "phuoc-long",
            status: "empty",
          },
          {
            label: "Vĩnh Phú Đông",
            value: 31870,
            slug: "vinh-phu-dong",
            status: "empty",
          },
          {
            label: "Vĩnh Phú Tây",
            value: 31873,
            slug: "vinh-phu-tay",
            status: "empty",
          },
          {
            label: "Phước Long",
            value: 31876,
            slug: "phuoc-long",
            status: "empty",
          },
          {
            label: "Hưng Phú",
            value: 31879,
            slug: "hung-phu",
            status: "empty",
          },
          {
            label: "Vĩnh Thanh",
            value: 31882,
            slug: "vinh-thanh",
            status: "empty",
          },
          {
            label: "Phong Thạnh Tây A",
            value: 31885,
            slug: "phong-thanh-tay-a",
            status: "empty",
          },
          {
            label: "Phong Thạnh Tây B",
            value: 31888,
            slug: "phong-thanh-tay-b",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Vĩnh Lợi",
        value: 958,
        slug: "huyen-vinh-loi",
        status: "empty",
        ward: [
          {
            label: "Vĩnh Hưng",
            value: 31894,
            slug: "vinh-hung",
            status: "empty",
          },
          {
            label: "Vĩnh Hưng A",
            value: 31897,
            slug: "vinh-hung-a",
            status: "empty",
          },
          {
            label: "Châu Hưng",
            value: 31900,
            slug: "chau-hung",
            status: "empty",
          },
          {
            label: "Châu Hưng A",
            value: 31903,
            slug: "chau-hung-a",
            status: "empty",
          },
          {
            label: "Hưng Thành",
            value: 31906,
            slug: "hung-thanh",
            status: "empty",
          },
          {
            label: "Hưng Hội",
            value: 31909,
            slug: "hung-hoi",
            status: "empty",
          },
          {
            label: "Châu Thới",
            value: 31912,
            slug: "chau-thoi",
            status: "empty",
          },
          {
            label: "Long Thạnh",
            value: 31921,
            slug: "long-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Thị xã Giá Rai",
        value: 959,
        slug: "thi-xa-gia-rai",
        status: "empty",
        ward: [
          {
            label: "Phường 1",
            value: 31942,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Hộ Phòng",
            value: 31945,
            slug: "ho-phong",
            status: "empty",
          },
          {
            label: "Phong Thạnh Đông",
            value: 31948,
            slug: "phong-thanh-dong",
            status: "empty",
          },
          {
            label: "Láng Tròn",
            value: 31951,
            slug: "lang-tron",
            status: "empty",
          },
          {
            label: "Phong Tân",
            value: 31954,
            slug: "phong-tan",
            status: "empty",
          },
          {
            label: "Tân Phong",
            value: 31957,
            slug: "tan-phong",
            status: "empty",
          },
          {
            label: "Phong Thạnh",
            value: 31960,
            slug: "phong-thanh",
            status: "empty",
          },
          {
            label: "Phong Thạnh A",
            value: 31963,
            slug: "phong-thanh-a",
            status: "empty",
          },
          {
            label: "Phong Thạnh Tây",
            value: 31966,
            slug: "phong-thanh-tay",
            status: "empty",
          },
          {
            label: "Tân Thạnh",
            value: 31969,
            slug: "tan-thanh",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đông Hải",
        value: 960,
        slug: "huyen-dong-hai",
        status: "empty",
        ward: [
          {
            label: "Gành Hào",
            value: 31972,
            slug: "ganh-hao",
            status: "empty",
          },
          {
            label: "Long Điền Đông",
            value: 31975,
            slug: "long-dien-dong",
            status: "empty",
          },
          {
            label: "Long Điền Đông A",
            value: 31978,
            slug: "long-dien-dong-a",
            status: "empty",
          },
          {
            label: "Long Điền",
            value: 31981,
            slug: "long-dien",
            status: "empty",
          },
          {
            label: "Long Điền Tây",
            value: 31984,
            slug: "long-dien-tay",
            status: "empty",
          },
          {
            label: "Điền Hải",
            value: 31985,
            slug: "dien-hai",
            status: "empty",
          },
          {
            label: "An Trạch",
            value: 31987,
            slug: "an-trach",
            status: "empty",
          },
          {
            label: "An Trạch A",
            value: 31988,
            slug: "an-trach-a",
            status: "empty",
          },
          { label: "An Phúc", value: 31990, slug: "an-phuc", status: "empty" },
          {
            label: "Định Thành",
            value: 31993,
            slug: "dinh-thanh",
            status: "empty",
          },
          {
            label: "Định Thành A",
            value: 31996,
            slug: "dinh-thanh-a",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Hoà Bình",
        value: 961,
        slug: "huyen-hoa-binh",
        status: "empty",
        ward: [
          {
            label: "Hòa Bình",
            value: 31891,
            slug: "hoa-binh",
            status: "empty",
          },
          {
            label: "Minh Diệu",
            value: 31915,
            slug: "minh-dieu",
            status: "empty",
          },
          {
            label: "Vĩnh Bình",
            value: 31918,
            slug: "vinh-binh",
            status: "empty",
          },
          {
            label: "Vĩnh Mỹ B",
            value: 31924,
            slug: "vinh-my-b",
            status: "empty",
          },
          {
            label: "Vĩnh Hậu",
            value: 31927,
            slug: "vinh-hau",
            status: "empty",
          },
          {
            label: "Vĩnh Hậu A",
            value: 31930,
            slug: "vinh-hau-a",
            status: "empty",
          },
          {
            label: "Vĩnh Mỹ A",
            value: 31933,
            slug: "vinh-my-a",
            status: "empty",
          },
          {
            label: "Vĩnh Thịnh",
            value: 31936,
            slug: "vinh-thinh",
            status: "empty",
          },
        ],
      },
    ],
  },
  {
    label: "Cà Mau",
    value: 96,
    slug: "ca-mau",
    status: "filled",
    district: [
      {
        label: "Thành phố Cà Mau",
        value: 964,
        slug: "thanh-pho-ca-mau",
        status: "filled",
        ward: [
          {
            label: "Phường 9",
            value: 31999,
            slug: "phuong-9",
            status: "empty",
          },
          {
            label: "Phường 2",
            value: 32002,
            slug: "phuong-2",
            status: "empty",
          },
          {
            label: "Phường 1",
            value: 32005,
            slug: "phuong-1",
            status: "empty",
          },
          {
            label: "Phường 5",
            value: 32008,
            slug: "phuong-5",
            status: "empty",
          },
          {
            label: "Phường 8",
            value: 32014,
            slug: "phuong-8",
            status: "filled",
          },
          {
            label: "Phường 6",
            value: 32017,
            slug: "phuong-6",
            status: "empty",
          },
          {
            label: "Phường 7",
            value: 32020,
            slug: "phuong-7",
            status: "empty",
          },
          {
            label: "Tân Xuyên",
            value: 32022,
            slug: "tan-xuyen",
            status: "empty",
          },
          {
            label: "An Xuyên",
            value: 32023,
            slug: "an-xuyen",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 32025,
            slug: "tan-thanh",
            status: "empty",
          },
          {
            label: "Tân Thành",
            value: 32026,
            slug: "tan-thanh",
            status: "empty",
          },
          { label: "Tắc Vân", value: 32029, slug: "tac-van", status: "empty" },
          {
            label: "Lý Văn Lâm",
            value: 32032,
            slug: "ly-van-lam",
            status: "empty",
          },
          {
            label: "Định Bình",
            value: 32035,
            slug: "dinh-binh",
            status: "empty",
          },
          {
            label: "Hòa Thành",
            value: 32038,
            slug: "hoa-thanh",
            status: "empty",
          },
          { label: "Hòa Tân", value: 32041, slug: "hoa-tan", status: "empty" },
        ],
      },
      {
        label: "Huyện U Minh",
        value: 966,
        slug: "huyen-u-minh",
        status: "empty",
        ward: [
          { label: "U Minh", value: 32044, slug: "u-minh", status: "empty" },
          {
            label: "Khánh Hòa",
            value: 32047,
            slug: "khanh-hoa",
            status: "empty",
          },
          {
            label: "Khánh Thuận",
            value: 32048,
            slug: "khanh-thuan",
            status: "empty",
          },
          {
            label: "Khánh Tiến",
            value: 32050,
            slug: "khanh-tien",
            status: "empty",
          },
          {
            label: "Nguyễn Phích",
            value: 32053,
            slug: "nguyen-phich",
            status: "empty",
          },
          {
            label: "Khánh Lâm",
            value: 32056,
            slug: "khanh-lam",
            status: "empty",
          },
          {
            label: "Khánh An",
            value: 32059,
            slug: "khanh-an",
            status: "empty",
          },
          {
            label: "Khánh Hội",
            value: 32062,
            slug: "khanh-hoi",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Thới Bình",
        value: 967,
        slug: "huyen-thoi-binh",
        status: "empty",
        ward: [
          {
            label: "Thới Bình",
            value: 32065,
            slug: "thoi-binh",
            status: "empty",
          },
          {
            label: "Biển Bạch",
            value: 32068,
            slug: "bien-bach",
            status: "empty",
          },
          {
            label: "Tân Bằng",
            value: 32069,
            slug: "tan-bang",
            status: "empty",
          },
          {
            label: "Trí Phải",
            value: 32071,
            slug: "tri-phai",
            status: "empty",
          },
          { label: "Trí Lực", value: 32072, slug: "tri-luc", status: "empty" },
          {
            label: "Biển Bạch Đông",
            value: 32074,
            slug: "bien-bach-dong",
            status: "empty",
          },
          {
            label: "Thới Bình",
            value: 32077,
            slug: "thoi-binh",
            status: "empty",
          },
          { label: "Tân Phú", value: 32080, slug: "tan-phu", status: "empty" },
          {
            label: "Tân Lộc Bắc",
            value: 32083,
            slug: "tan-loc-bac",
            status: "empty",
          },
          { label: "Tân Lộc", value: 32086, slug: "tan-loc", status: "empty" },
          {
            label: "Tân Lộc Đông",
            value: 32089,
            slug: "tan-loc-dong",
            status: "empty",
          },
          {
            label: "Hồ Thị Kỷ",
            value: 32092,
            slug: "ho-thi-ky",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Trần Văn Thời",
        value: 968,
        slug: "huyen-tran-van-thoi",
        status: "empty",
        ward: [
          {
            label: "Trần Văn Thời",
            value: 32095,
            slug: "tran-van-thoi",
            status: "empty",
          },
          {
            label: "Sông Đốc",
            value: 32098,
            slug: "song-doc",
            status: "empty",
          },
          {
            label: "Khánh Bình Tây Bắc",
            value: 32101,
            slug: "khanh-binh-tay-bac",
            status: "empty",
          },
          {
            label: "Khánh Bình Tây",
            value: 32104,
            slug: "khanh-binh-tay",
            status: "empty",
          },
          {
            label: "Trần Hợi",
            value: 32107,
            slug: "tran-hoi",
            status: "empty",
          },
          {
            label: "Khánh Lộc",
            value: 32108,
            slug: "khanh-loc",
            status: "empty",
          },
          {
            label: "Khánh Bình",
            value: 32110,
            slug: "khanh-binh",
            status: "empty",
          },
          {
            label: "Khánh Hưng",
            value: 32113,
            slug: "khanh-hung",
            status: "empty",
          },
          {
            label: "Khánh Bình Đông",
            value: 32116,
            slug: "khanh-binh-dong",
            status: "empty",
          },
          {
            label: "Khánh Hải",
            value: 32119,
            slug: "khanh-hai",
            status: "empty",
          },
          { label: "Lợi An", value: 32122, slug: "loi-an", status: "empty" },
          {
            label: "Phong Điền",
            value: 32124,
            slug: "phong-dien",
            status: "empty",
          },
          {
            label: "Phong Lạc",
            value: 32125,
            slug: "phong-lac",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Cái Nước",
        value: 969,
        slug: "huyen-cai-nuoc",
        status: "filled",
        ward: [
          {
            label: "Cái Nước",
            value: 32128,
            slug: "cai-nuoc",
            status: "filled",
          },
          {
            label: "Thạnh Phú",
            value: 32130,
            slug: "thanh-phu",
            status: "empty",
          },
          {
            label: "Lương Thế Trân",
            value: 32131,
            slug: "luong-the-tran",
            status: "empty",
          },
          {
            label: "Phú Hưng",
            value: 32134,
            slug: "phu-hung",
            status: "empty",
          },
          {
            label: "Tân Hưng",
            value: 32137,
            slug: "tan-hung",
            status: "empty",
          },
          { label: "Hưng Mỹ", value: 32140, slug: "hung-my", status: "empty" },
          { label: "Hoà Mỹ", value: 32141, slug: "hoa-my", status: "empty" },
          {
            label: "Đông Hưng",
            value: 32142,
            slug: "dong-hung",
            status: "empty",
          },
          {
            label: "Đông Thới",
            value: 32143,
            slug: "dong-thoi",
            status: "empty",
          },
          {
            label: "Tân Hưng Đông",
            value: 32146,
            slug: "tan-hung-dong",
            status: "empty",
          },
          {
            label: "Trần Thới",
            value: 32149,
            slug: "tran-thoi",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Đầm Dơi",
        value: 970,
        slug: "huyen-dam-doi",
        status: "empty",
        ward: [
          { label: "Đầm Dơi", value: 32152, slug: "dam-doi", status: "empty" },
          {
            label: "Tạ An Khương",
            value: 32155,
            slug: "ta-an-khuong",
            status: "empty",
          },
          {
            label: "Tạ An Khương Đông",
            value: 32158,
            slug: "ta-an-khuong-dong",
            status: "empty",
          },
          {
            label: "Trần Phán",
            value: 32161,
            slug: "tran-phan",
            status: "empty",
          },
          {
            label: "Tân Trung",
            value: 32162,
            slug: "tan-trung",
            status: "empty",
          },
          { label: "Tân Đức", value: 32164, slug: "tan-duc", status: "empty" },
          {
            label: "Tân Thuận",
            value: 32167,
            slug: "tan-thuan",
            status: "empty",
          },
          {
            label: "Tạ An Khương Nam",
            value: 32170,
            slug: "ta-an-khuong-nam",
            status: "empty",
          },
          {
            label: "Tân Duyệt",
            value: 32173,
            slug: "tan-duyet",
            status: "empty",
          },
          { label: "Tân Dân", value: 32174, slug: "tan-dan", status: "empty" },
          {
            label: "Tân Tiến",
            value: 32176,
            slug: "tan-tien",
            status: "empty",
          },
          {
            label: "Quách Phẩm Bắc",
            value: 32179,
            slug: "quach-pham-bac",
            status: "empty",
          },
          {
            label: "Quách Phẩm",
            value: 32182,
            slug: "quach-pham",
            status: "empty",
          },
          {
            label: "Thanh Tùng",
            value: 32185,
            slug: "thanh-tung",
            status: "empty",
          },
          {
            label: "Ngọc Chánh",
            value: 32186,
            slug: "ngoc-chanh",
            status: "empty",
          },
          {
            label: "Nguyễn Huân",
            value: 32188,
            slug: "nguyen-huan",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Năm Căn",
        value: 971,
        slug: "huyen-nam-can",
        status: "empty",
        ward: [
          { label: "Năm Căn", value: 32191, slug: "nam-can", status: "empty" },
          {
            label: "Hàm Rồng",
            value: 32194,
            slug: "ham-rong",
            status: "empty",
          },
          {
            label: "Hiệp Tùng",
            value: 32197,
            slug: "hiep-tung",
            status: "empty",
          },
          { label: "Đất Mới", value: 32200, slug: "dat-moi", status: "empty" },
          { label: "Lâm Hải", value: 32201, slug: "lam-hai", status: "empty" },
          {
            label: "Hàng Vịnh",
            value: 32203,
            slug: "hang-vinh",
            status: "empty",
          },
          {
            label: "Tam Giang",
            value: 32206,
            slug: "tam-giang",
            status: "empty",
          },
          {
            label: "Tam Giang Đông",
            value: 32209,
            slug: "tam-giang-dong",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Phú Tân",
        value: 972,
        slug: "huyen-phu-tan",
        status: "empty",
        ward: [
          {
            label: "Cái Đôi Vàm",
            value: 32212,
            slug: "cai-doi-vam",
            status: "empty",
          },
          {
            label: "Phú Thuận",
            value: 32214,
            slug: "phu-thuan",
            status: "empty",
          },
          { label: "Phú Mỹ", value: 32215, slug: "phu-my", status: "empty" },
          { label: "Phú Tân", value: 32218, slug: "phu-tan", status: "empty" },
          { label: "Tân Hải", value: 32221, slug: "tan-hai", status: "empty" },
          {
            label: "Việt Thắng",
            value: 32224,
            slug: "viet-thang",
            status: "empty",
          },
          {
            label: "Tân Hưng Tây",
            value: 32227,
            slug: "tan-hung-tay",
            status: "empty",
          },
          {
            label: "Rạch Chèo",
            value: 32228,
            slug: "rach-cheo",
            status: "empty",
          },
          {
            label: "Nguyễn Việt Khái",
            value: 32230,
            slug: "nguyen-viet-khai",
            status: "empty",
          },
        ],
      },
      {
        label: "Huyện Ngọc Hiển",
        value: 973,
        slug: "huyen-ngoc-hien",
        status: "empty",
        ward: [
          {
            label: "Tam Giang Tây",
            value: 32233,
            slug: "tam-giang-tay",
            status: "empty",
          },
          {
            label: "Tân Ân Tây",
            value: 32236,
            slug: "tan-an-tay",
            status: "empty",
          },
          {
            label: "Viên An Đông",
            value: 32239,
            slug: "vien-an-dong",
            status: "empty",
          },
          { label: "Viên An", value: 32242, slug: "vien-an", status: "empty" },
          {
            label: "Rạch Gốc",
            value: 32244,
            slug: "rach-goc",
            status: "empty",
          },
          { label: "Tân Ân", value: 32245, slug: "tan-an", status: "empty" },
          { label: "Đất Mũi", value: 32248, slug: "dat-mui", status: "empty" },
        ],
      },
    ],
  },
];
export const PROVINCE_FILLTED_FOR_SELECT = [
  {
    label: "Hà Nội",
    value: 1,
    district: [
      {
        label: "Ba Đình",
        value: 1,
        ward: [
          {
            label: "Giảng Võ",
            value: 31,
            slug: "giang-vo",
          },
        ],
        slug: "ba-dinh",
      },
      {
        label: "Hoàn Kiếm",
        value: 2,
        ward: [
          {
            label: "Cửa Nam",
            value: 73,
            slug: "cua-nam",
          },
          {
            label: "Trần Hưng Đạo",
            value: 82,
            slug: "tran-hung-dao",
          },
        ],
        slug: "hoan-kiem",
      },
      {
        label: "Tây Hồ",
        value: 3,
        ward: [
          {
            label: "Phường Bưởi",
            value: 109,
            slug: "phuong-buoi",
          },
        ],
        slug: "tay-ho",
      },
      {
        label: "Long Biên",
        value: 4,
        ward: [
          {
            label: "Đức Giang",
            value: 124,
            slug: "duc-giang",
          },
          {
            label: "Phúc Lợi",
            value: 136,
            slug: "phuc-loi",
          },
        ],
        slug: "long-bien",
      },
      {
        label: "Cầu Giấy",
        value: 5,
        ward: [
          {
            label: "Nghĩa Đô",
            value: 157,
            slug: "nghia-do",
          },
          {
            label: "Mai Dịch",
            value: 163,
            slug: "mai-dich",
          },
          {
            label: "Dịch Vọng",
            value: 166,
            slug: "dich-vong",
          },
          {
            label: "Dịch Vọng Hậu",
            value: 167,
            slug: "dich-vong-hau",
          },
          {
            label: "Yên Hoà",
            value: 172,
            slug: "yen-hoa",
          },
          {
            label: "Trung Hoà",
            value: 175,
            slug: "trung-hoa",
          },
        ],
        slug: "cau-giay",
      },
      {
        label: "Đống Đa",
        value: 6,
        ward: [
          {
            label: "Láng Hạ",
            value: 199,
            slug: "lang-ha",
          },
          {
            label: "Nam Đồng",
            value: 208,
            slug: "nam-dong",
          },
          {
            label: "Trung Liệt",
            value: 217,
            slug: "trung-liet",
          },
          {
            label: "Ngã Tư Sở",
            value: 236,
            slug: "nga-tu-so",
          },
        ],
        slug: "dong-da",
      },
      {
        label: "Hai Bà Trưng",
        value: 7,
        ward: [
          {
            label: "Nguyễn Du",
            value: 241,
            slug: "nguyen-du",
          },
          {
            label: "Lê Đại Hành",
            value: 256,
            slug: "le-dai-hanh",
          },
          {
            label: "Thanh Nhàn",
            value: 271,
            slug: "thanh-nhan",
          },
        ],
        slug: "hai-ba-trung",
      },
      {
        label: "Hoàng Mai",
        value: 8,
        ward: [
          {
            label: "Tương Mai",
            value: 313,
            slug: "tuong-mai",
          },
          {
            label: "Thịnh Liệt",
            value: 331,
            slug: "thinh-liet",
          },
          {
            label: "Hoàng Liệt",
            value: 337,
            slug: "hoang-liet",
          },
        ],
        slug: "hoang-mai",
      },
      {
        label: "Thanh Xuân",
        value: 9,
        ward: [
          {
            label: "Khương Trung",
            value: 349,
            slug: "khuong-trung",
          },
          {
            label: "Thanh Xuân Trung",
            value: 355,
            slug: "thanh-xuan-trung",
          },
          {
            label: "Khương Đình",
            value: 364,
            slug: "khuong-dinh",
          },
          {
            label: "Hạ Đình",
            value: 373,
            slug: "ha-dinh",
          },
        ],
        slug: "thanh-xuan",
      },
      {
        label: "Sóc Sơn",
        value: 16,
        ward: [
          {
            label: "Sóc Sơn",
            value: 376,
            slug: "soc-son",
          },
          {
            label: "Trung Giã",
            value: 391,
            slug: "trung-gia",
          },
          {
            label: "Phù Lỗ",
            value: 448,
            slug: "phu-lo",
          },
        ],
        slug: "soc-son",
      },
      {
        label: "Đông Anh",
        value: 17,
        ward: [
          {
            label: "Nguyên Khê",
            value: 466,
            slug: "nguyen-khe",
          },
          {
            label: "Uy Nỗ",
            value: 478,
            slug: "uy-no",
          },
        ],
        slug: "dong-anh",
      },
      {
        label: "Gia Lâm",
        value: 18,
        ward: [
          {
            label: "Ninh Hiệp",
            value: 535,
            slug: "ninh-hiep",
          },
          {
            label: "Trâu Quỳ",
            value: 565,
            slug: "trau-quy",
          },
        ],
        slug: "gia-lam",
      },
      {
        label: "Nam Từ Liêm",
        value: 19,
        ward: [
          {
            label: "Cầu Diễn",
            value: 592,
            slug: "cau-dien",
          },
          {
            label: "Mỹ Đình 1",
            value: 625,
            slug: "my-dinh-1",
          },
          {
            label: "Mỹ Đình 2",
            value: 626,
            slug: "my-dinh-2",
          },
          {
            label: "Tây Mỗ",
            value: 628,
            slug: "tay-mo",
          },
          {
            label: "Trung Văn",
            value: 637,
            slug: "trung-van",
          },
        ],
        slug: "nam-tu-liem",
      },
      {
        label: "Thanh Trì",
        value: 20,
        ward: [
          {
            label: "Văn Điển",
            value: 640,
            slug: "van-dien",
          },
          {
            label: "Tứ Hiệp",
            value: 658,
            slug: "tu-hiep",
          },
        ],
        slug: "thanh-tri",
      },
      {
        label: "Mê Linh",
        value: 250,
        ward: [
          {
            label: "Tiền Phong",
            value: 9019,
            slug: "tien-phong",
          },
        ],
        slug: "me-linh",
      },
      {
        label: "Hà Đông",
        value: 268,
        ward: [
          {
            label: "Mộ Lao",
            value: 9541,
            slug: "mo-lao",
          },
          {
            label: "Văn Quán",
            value: 9542,
            slug: "van-quan",
          },
          {
            label: "Vạn Phúc",
            value: 9544,
            slug: "van-phuc",
          },
          {
            label: "Phú La",
            value: 9552,
            slug: "phu-la",
          },
          {
            label: "Phúc La",
            value: 9553,
            slug: "phuc-la",
          },
        ],
        slug: "ha-dong",
      },
      {
        label: "Sơn Tây",
        value: 269,
        ward: [
          {
            label: "Phú Thịnh",
            value: 9577,
            slug: "phu-thinh",
          },
        ],
        slug: "son-tay",
      },
      {
        label: "Đan Phượng",
        value: 273,
        ward: [
          {
            label: "Thị trấn Phùng",
            value: 9784,
            slug: "thi-tran-phung",
          },
          {
            label: "Tân Lập",
            value: 9820,
            slug: "tan-lap",
          },
        ],
        slug: "dan-phuong",
      },
      {
        label: "Hoài Đức",
        value: 274,
        ward: [
          {
            label: "Sơn Đồng",
            value: 9859,
            slug: "son-dong",
          },
          {
            label: "An Khánh",
            value: 9877,
            slug: "an-khanh",
          },
        ],
        slug: "hoai-duc",
      },
      {
        label: "Quốc Oai",
        value: 275,
        ward: [
          {
            label: "Ngọc Mỹ",
            value: 9910,
            slug: "ngoc-my",
          },
          {
            label: "Phú Cát",
            value: 9922,
            slug: "phu-cat",
          },
        ],
        slug: "quoc-oai",
      },
      {
        label: "Thạch Thất",
        value: 276,
        ward: [
          {
            label: "Hương Ngải",
            value: 9970,
            slug: "huong-ngai",
          },
          {
            label: "Tân Xã",
            value: 10000,
            slug: "tan-xa",
          },
        ],
        slug: "thach-that",
      },
      {
        label: "Chương Mỹ",
        value: 277,
        ward: [
          {
            label: "Trường Yên",
            value: 10039,
            slug: "truong-yen",
          },
        ],
        slug: "chuong-my",
      },
      {
        label: "Thường Tín",
        value: 279,
        ward: [
          {
            label: "Thường Tín",
            value: 10183,
            slug: "thuong-tin",
          },
        ],
        slug: "thuong-tin",
      },
    ],
    slug: "ha-noi",
  },
  {
    label: "Hà Giang",
    value: 2,
    district: [
      {
        label: "Thành phố Hà Giang",
        value: 24,
        ward: [
          {
            label: "Trần Phú",
            value: 691,
            slug: "tran-phu",
          },
        ],
        slug: "thanh-pho-ha-giang",
      },
    ],
    slug: "ha-giang",
  },
  {
    label: "Lào Cai",
    value: 10,
    district: [
      {
        label: "Thành phố Lào Cai",
        value: 80,
        ward: [
          {
            label: "Bắc Cường",
            value: 2668,
            slug: "bac-cuong",
          },
        ],
        slug: "thanh-pho-lao-cai",
      },
    ],
    slug: "lao-cai",
  },
  {
    label: "Điện Biên",
    value: 11,
    district: [
      {
        label: "Thành phố Điện Biên Phủ",
        value: 94,
        ward: [
          {
            label: "Mường Thanh",
            value: 3136,
            slug: "muong-thanh",
          },
        ],
        slug: "thanh-pho-dien-bien-phu",
      },
    ],
    slug: "dien-bien",
  },
  {
    label: "Lai Châu",
    value: 12,
    district: [
      {
        label: "Thành phố Lai Châu",
        value: 105,
        ward: [
          {
            label: "Tân Phong",
            value: 3387,
            slug: "tan-phong",
          },
        ],
        slug: "thanh-pho-lai-chau",
      },
    ],
    slug: "lai-chau",
  },
  {
    label: "Yên Bái",
    value: 15,
    district: [
      {
        label: "Thành phố Yên Bái",
        value: 132,
        ward: [
          {
            label: "Yên Ninh",
            value: 4252,
            slug: "yen-ninh",
          },
          {
            label: "Minh Tân",
            value: 4255,
            slug: "minh-tan",
          },
        ],
        slug: "thanh-pho-yen-bai",
      },
    ],
    slug: "yen-bai",
  },
  {
    label: "Thái Nguyên",
    value: 19,
    district: [
      {
        label: "Thành phố Thái Nguyên",
        value: 164,
        ward: [
          {
            label: "Phan Đình Phùng",
            value: 5449,
            slug: "phan-dinh-phung",
          },
        ],
        slug: "thanh-pho-thai-nguyen",
      },
      {
        label: "Huyện Phú Bình",
        value: 173,
        ward: [
          {
            label: "Hương Sơn",
            value: 5908,
            slug: "huong-son",
          },
        ],
        slug: "huyen-phu-binh",
      },
    ],
    slug: "thai-nguyen",
  },
  {
    label: "Lạng Sơn",
    value: 20,
    district: [
      {
        label: "Thành phố Lạng Sơn",
        value: 178,
        ward: [
          {
            label: "Hoàng Văn Thụ",
            value: 5971,
            slug: "hoang-van-thu",
          },
        ],
        slug: "thanh-pho-lang-son",
      },
    ],
    slug: "lang-son",
  },
  {
    label: "Quảng Ninh",
    value: 22,
    district: [
      {
        label: "Thành phố Hạ Long",
        value: 193,
        ward: [
          {
            label: "Bãi Cháy",
            value: 6673,
            slug: "bai-chay",
          },
          {
            label: "Trần Hưng Đạo",
            value: 6685,
            slug: "tran-hung-dao",
          },
          {
            label: "Hồng Hải",
            value: 6688,
            slug: "hong-hai",
          },
          {
            label: "Bạch Đằng",
            value: 6694,
            slug: "bach-dang",
          },
          {
            label: "Tuần Châu",
            value: 6700,
            slug: "tuan-chau",
          },
        ],
        slug: "thanh-pho-ha-long",
      },
      {
        label: "Thành phố Móng Cái",
        value: 194,
        ward: [
          {
            label: "Trần Phú",
            value: 6712,
            slug: "tran-phu",
          },
        ],
        slug: "thanh-pho-mong-cai",
      },
      {
        label: "Thành phố Cẩm Phả",
        value: 195,
        ward: [
          {
            label: "Cẩm Đông",
            value: 6769,
            slug: "cam-dong",
          },
        ],
        slug: "thanh-pho-cam-pha",
      },
      {
        label: "Thành phố Uông Bí",
        value: 196,
        ward: [
          {
            label: "Quang Trung",
            value: 6817,
            slug: "quang-trung",
          },
        ],
        slug: "thanh-pho-uong-bi",
      },
      {
        label: "Thị xã Đông Triều",
        value: 205,
        ward: [
          {
            label: "Mạo Khê",
            value: 7069,
            slug: "mao-khe",
          },
        ],
        slug: "thi-xa-dong-trieu",
      },
    ],
    slug: "quang-ninh",
  },
  {
    label: "Bắc Giang",
    value: 24,
    district: [
      {
        label: "Huyện Tân Yên",
        value: 216,
        ward: [
          {
            label: "Nhã Nam",
            value: 7306,
            slug: "nha-nam",
          },
        ],
        slug: "huyen-tan-yen",
      },
    ],
    slug: "bac-giang",
  },
  {
    label: "Phú Thọ",
    value: 25,
    district: [
      {
        label: "Thành phố Việt Trì",
        value: 227,
        ward: [
          {
            label: "Dữu Lâu",
            value: 7888,
            slug: "duu-lau",
          },
          {
            label: "Gia Cẩm",
            value: 7900,
            slug: "gia-cam",
          },
        ],
        slug: "thanh-pho-viet-tri",
      },
      {
        label: "Huyện Đoan Hùng",
        value: 230,
        ward: [
          {
            label: "Đoan Hùng",
            value: 7969,
            slug: "doan-hung",
          },
        ],
        slug: "huyen-doan-hung",
      },
    ],
    slug: "phu-tho",
  },
  {
    label: "Vĩnh Phúc",
    value: 26,
    district: [
      {
        label: "Thành phố Vĩnh Yên",
        value: 243,
        ward: [
          {
            label: "Liên Bảo",
            value: 8710,
            slug: "lien-bao",
          },
        ],
        slug: "thanh-pho-vinh-yen",
      },
      {
        label: "Thành phố Phúc Yên",
        value: 244,
        ward: [
          {
            label: "Hai Bà Trưng",
            value: 8740,
            slug: "hai-ba-trung",
          },
          {
            label: "Xuân Hoà",
            value: 8746,
            slug: "xuan-hoa",
          },
          {
            label: "Đồng Xuân",
            value: 8747,
            slug: "dong-xuan",
          },
        ],
        slug: "thanh-pho-phuc-yen",
      },
      {
        label: "Huyện Tam Dương",
        value: 247,
        ward: [
          {
            label: "Kim Long",
            value: 8878,
            slug: "kim-long",
          },
        ],
        slug: "huyen-tam-duong",
      },
      {
        label: "Huyện Yên Lạc",
        value: 251,
        ward: [
          {
            label: "Yên Lạc",
            value: 9025,
            slug: "yen-lac",
          },
        ],
        slug: "huyen-yen-lac",
      },
      {
        label: "Huyện Vĩnh Tường",
        value: 252,
        ward: [
          {
            label: "Đại Đồng",
            value: 9100,
            slug: "dai-dong",
          },
        ],
        slug: "huyen-vinh-tuong",
      },
    ],
    slug: "vinh-phuc",
  },
  {
    label: "Bắc Ninh",
    value: 27,
    district: [
      {
        label: "Thành phố Bắc Ninh",
        value: 256,
        ward: [
          {
            label: "Vũ Ninh",
            value: 9163,
            slug: "vu-ninh",
          },
          {
            label: "Tiền Ninh Vệ",
            value: 9184,
            slug: "tien-ninh-ve",
          },
          {
            label: "Võ Cường",
            value: 9190,
            slug: "vo-cuong",
          },
        ],
        slug: "thanh-pho-bac-ninh",
      },
      {
        label: "Huyện Yên Phong",
        value: 258,
        ward: [
          {
            label: "Thị trấn Chờ",
            value: 9193,
            slug: "thi-tran-cho",
          },
        ],
        slug: "huyen-yen-phong",
      },
      {
        label: "Thị xã Quế Võ",
        value: 259,
        ward: [
          {
            label: "Phương Liễu",
            value: 9265,
            slug: "phuong-lieu",
          },
          {
            label: "Phượng Mao",
            value: 9280,
            slug: "phuong-mao",
          },
        ],
        slug: "thi-xa-que-vo",
      },
      {
        label: "Thành phố Từ Sơn",
        value: 261,
        ward: [
          {
            label: "Đồng Nguyên",
            value: 9385,
            slug: "dong-nguyen",
          },
          {
            label: "Đình Bảng",
            value: 9394,
            slug: "dinh-bang",
          },
        ],
        slug: "thanh-pho-tu-son",
      },
    ],
    slug: "bac-ninh",
  },
  {
    label: "Hải Dương",
    value: 30,
    district: [
      {
        label: "Thị xã Kinh Môn",
        value: 292,
        ward: [
          {
            label: "An Lưu",
            value: 10675,
            slug: "an-luu",
          },
        ],
        slug: "thi-xa-kinh-mon",
      },
      {
        label: "Huyện Cẩm Giàng",
        value: 295,
        ward: [
          {
            label: "Lai Cách",
            value: 10891,
            slug: "lai-cach",
          },
        ],
        slug: "huyen-cam-giang",
      },
    ],
    slug: "hai-duong",
  },
  {
    label: "Hải Phòng",
    value: 31,
    district: [
      {
        label: "Quận Hồng Bàng",
        value: 303,
        ward: [
          {
            label: "Thượng Lý",
            value: 11305,
            slug: "thuong-ly",
          },
        ],
        slug: "quan-hong-bang",
      },
      {
        label: "Quận Ngô Quyền",
        value: 304,
        ward: [
          {
            label: "Cầu Tre",
            value: 11338,
            slug: "cau-tre",
          },
          {
            label: "Cầu Đất",
            value: 11344,
            slug: "cau-dat",
          },
        ],
        slug: "quan-ngo-quyen",
      },
      {
        label: "Quận Lê Chân",
        value: 305,
        ward: [
          {
            label: "An Biên",
            value: 11371,
            slug: "an-bien",
          },
          {
            label: "An Dương",
            value: 11401,
            slug: "an-duong",
          },
          {
            label: "Hồ Nam",
            value: 11406,
            slug: "ho-nam",
          },
        ],
        slug: "quan-le-chan",
      },
      {
        label: "Quận Hải An",
        value: 306,
        ward: [
          {
            label: "Đằng Hải",
            value: 11416,
            slug: "dang-hai",
          },
          {
            label: "Cát Bi",
            value: 11422,
            slug: "cat-bi",
          },
        ],
        slug: "quan-hai-an",
      },
      {
        label: "Huyện Thuỷ Nguyên",
        value: 311,
        ward: [
          {
            label: "Thuỷ Đường",
            value: 11560,
            slug: "thuy-duong",
          },
        ],
        slug: "huyen-thuy-nguyen",
      },
    ],
    slug: "hai-phong",
  },
  {
    label: "Thái Bình",
    value: 34,
    district: [
      {
        label: "Huyện Quỳnh Phụ",
        value: 338,
        ward: [
          {
            label: "Quỳnh Côi",
            value: 12472,
            slug: "quynh-coi",
          },
        ],
        slug: "huyen-quynh-phu",
      },
      {
        label: "Huyện Thái Thụy",
        value: 341,
        ward: [
          {
            label: "Tân Học",
            value: 12958,
            slug: "tan-hoc",
          },
        ],
        slug: "huyen-thai-thuy",
      },
      {
        label: "Huyện Tiền Hải",
        value: 342,
        ward: [
          {
            label: "Tây Giang",
            value: 13021,
            slug: "tay-giang",
          },
        ],
        slug: "huyen-tien-hai",
      },
    ],
    slug: "thai-binh",
  },
  {
    label: "Nam Định",
    value: 36,
    district: [
      {
        label: "Thành phố Nam Định",
        value: 356,
        ward: [
          {
            label: "Cửa Nam",
            value: 13690,
            slug: "cua-nam",
          },
        ],
        slug: "thanh-pho-nam-dinh",
      },
    ],
    slug: "nam-dinh",
  },
  {
    label: "Thanh Hóa",
    value: 38,
    district: [
      {
        label: "Thành phố Thanh Hóa",
        value: 380,
        ward: [
          {
            label: "Đông Thọ",
            value: 14758,
            slug: "dong-tho",
          },
          {
            label: "Ba Đình",
            value: 14776,
            slug: "ba-dinh",
          },
        ],
        slug: "thanh-pho-thanh-hoa",
      },
    ],
    slug: "thanh-hoa",
  },
  {
    label: "Nghệ An",
    value: 40,
    district: [
      {
        label: "Thành phố Vinh",
        value: 412,
        ward: [
          {
            label: "Hà Huy Tập",
            value: 16666,
            slug: "ha-huy-tap",
          },
          {
            label: "Hưng Bình",
            value: 16672,
            slug: "hung-binh",
          },
          {
            label: "Trường Thi",
            value: 16690,
            slug: "truong-thi",
          },
          {
            label: "Vinh Tân",
            value: 16714,
            slug: "vinh-tan",
          },
        ],
        slug: "thanh-pho-vinh",
      },
      {
        label: "Thị xã Cửa Lò",
        value: 413,
        ward: [
          {
            label: "Nghi Thủy",
            value: 100603,
            slug: "nghi-thuy",
          },
        ],
        slug: "thi-xa-cua-lo",
      },
      {
        label: "Huyện Tân Kỳ",
        value: 423,
        ward: [
          {
            label: "Tân Kỳ",
            value: 17266,
            slug: "tan-ky",
          },
        ],
        slug: "huyen-tan-ky",
      },
      {
        label: "Huyện Đô Lương",
        value: 427,
        ward: [
          {
            label: "Lạc Sơn",
            value: 17671,
            slug: "lac-son",
          },
        ],
        slug: "huyen-do-luong",
      },
    ],
    slug: "nghe-an",
  },
  {
    label: "Hà Tĩnh",
    value: 42,
    district: [
      {
        label: "Huyện Nghi Xuân",
        value: 442,
        ward: [
          {
            label: "Xuân Giang",
            value: 18370,
            slug: "xuan-giang",
          },
        ],
        slug: "huyen-nghi-xuan",
      },
      {
        label: "Thị xã Kỳ Anh",
        value: 449,
        ward: [
          {
            label: "Hưng Trí",
            value: 18754,
            slug: "hung-tri",
          },
        ],
        slug: "thi-xa-ky-anh",
      },
    ],
    slug: "ha-tinh",
  },
  {
    label: "Quảng Bình",
    value: 44,
    district: [
      {
        label: "Thành Phố Đồng Hới",
        value: 450,
        ward: [
          {
            label: "Nam Lý",
            value: 18865,
            slug: "nam-ly",
          },
        ],
        slug: "thanh-pho-dong-hoi",
      },
    ],
    slug: "quang-binh",
  },
  {
    label: "Quảng Trị",
    value: 45,
    district: [
      {
        label: "Thành phố Đông Hà",
        value: 461,
        ward: [
          {
            label: "Phường 5",
            value: 19348,
            slug: "phuong-5",
          },
        ],
        slug: "thanh-pho-dong-ha",
      },
    ],
    slug: "quang-tri",
  },
  {
    label: "Thừa Thiên Huế",
    value: 46,
    district: [
      {
        label: "Thành phố Huế",
        value: 474,
        ward: [
          {
            label: "Phú Nhuận",
            value: 19789,
            slug: "phu-nhuan",
          },
          {
            label: "Phú Thượng",
            value: 19930,
            slug: "phu-thuong",
          },
          {
            label: "Thủy Vân",
            value: 19963,
            slug: "thuy-van",
          },
          {
            label: "Phú Hậu",
            value: 19983,
            slug: "phu-hau",
          },
        ],
        slug: "thanh-pho-hue",
      },
    ],
    slug: "thua-thien-hue",
  },
  {
    label: "Đà Nẵng",
    value: 48,
    district: [
      {
        label: "Liên Chiểu",
        value: 490,
        ward: [
          {
            label: "Hòa Khánh Bắc",
            value: 20197,
            slug: "hoa-khanh-bac",
          },
        ],
        slug: "lien-chieu",
      },
      {
        label: "Thanh Khê",
        value: 491,
        ward: [
          {
            label: "Chính Gián",
            value: 20215,
            slug: "chinh-gian",
          },
        ],
        slug: "thanh-khe",
      },
      {
        label: "Hải Châu",
        value: 492,
        ward: [
          {
            label: "Thanh Bình",
            value: 20227,
            slug: "thanh-binh",
          },
          {
            label: "Phước Ninh",
            value: 20242,
            slug: "phuoc-ninh",
          },
          {
            label: "Hòa Thuận Tây",
            value: 20245,
            slug: "hoa-thuan-tay",
          },
          {
            label: "Hòa Thuận Đông",
            value: 20255,
            slug: "hoa-thuan-dong",
          },
        ],
        slug: "hai-chau",
      },
      {
        label: "Sơn Trà",
        value: 493,
        ward: [
          {
            label: "An Hải Trung",
            value: 20276,
            slug: "an-hai-trung",
          },
        ],
        slug: "son-tra",
      },
      {
        label: "Cẩm Lệ",
        value: 495,
        ward: [
          {
            label: "Hòa Thọ Tây",
            value: 20311,
            slug: "hoa-tho-tay",
          },
        ],
        slug: "cam-le",
      },
    ],
    slug: "da-nang",
  },
  {
    label: "Quảng Nam",
    value: 49,
    district: [
      {
        label: "Huyện Đại Lộc",
        value: 506,
        ward: [
          {
            label: "Ái Nghĩa",
            value: 20500,
            slug: "ai-nghia",
          },
        ],
        slug: "huyen-dai-loc",
      },
    ],
    slug: "quang-nam",
  },
  {
    label: "Phú Yên",
    value: 54,
    district: [
      {
        label: "Thành phố Tuy Hoà",
        value: 555,
        ward: [
          {
            label: "Phường 2",
            value: 22018,
            slug: "phuong-2",
          },
        ],
        slug: "thanh-pho-tuy-hoa",
      },
    ],
    slug: "phu-yen",
  },
  {
    label: "Ninh Thuận",
    value: 58,
    district: [
      {
        label: "Thành phố Phan Rang-Tháp Chàm",
        value: 582,
        ward: [
          {
            label: "Kinh Dinh",
            value: 22759,
            slug: "kinh-dinh",
          },
        ],
        slug: "thanh-pho-phan-rang-thap-cham",
      },
    ],
    slug: "ninh-thuan",
  },
  {
    label: "Đắk Lắk",
    value: 66,
    district: [
      {
        label: "Thành phố Buôn Ma Thuột",
        value: 643,
        ward: [
          {
            label: "Thành Công",
            value: 24133,
            slug: "thanh-cong",
          },
        ],
        slug: "thanh-pho-buon-ma-thuot",
      },
      {
        label: "Huyện Krông Bông",
        value: 653,
        ward: [
          {
            label: "Krông Kmar",
            value: 24448,
            slug: "krong-kmar",
          },
        ],
        slug: "huyen-krong-bong",
      },
      {
        label: "Huyện Krông A Na",
        value: 655,
        ward: [
          {
            label: "Buôn Trấp",
            value: 24538,
            slug: "buon-trap",
          },
        ],
        slug: "huyen-krong-a-na",
      },
    ],
    slug: "dak-lak",
  },
  {
    label: "Lâm Đồng",
    value: 68,
    district: [
      {
        label: "Thành phố Bảo Lộc",
        value: 673,
        ward: [
          {
            label: "Lộc Tiến",
            value: 24817,
            slug: "loc-tien",
          },
        ],
        slug: "thanh-pho-bao-loc",
      },
    ],
    slug: "lam-dong",
  },
  {
    label: "Bình Dương",
    value: 74,
    district: [
      {
        label: "Thành phố Thủ Dầu Một",
        value: 718,
        ward: [
          {
            label: "Hiệp Thành",
            value: 25741,
            slug: "hiep-thanh",
          },
          {
            label: "Phú Lợi",
            value: 25744,
            slug: "phu-loi",
          },
          {
            label: "Phú Cường",
            value: 25747,
            slug: "phu-cuong",
          },
          {
            label: "Phú Hòa",
            value: 25750,
            slug: "phu-hoa",
          },
          {
            label: "Chánh Nghĩa",
            value: 25756,
            slug: "chanh-nghia",
          },
        ],
        slug: "thanh-pho-thu-dau-mot",
      },
      {
        label: "Huyện Dầu Tiếng",
        value: 720,
        ward: [
          {
            label: "Dầu Tiếng",
            value: 25777,
            slug: "dau-tieng",
          },
        ],
        slug: "huyen-dau-tieng",
      },
      {
        label: "Thị xã Bến Cát",
        value: 721,
        ward: [
          {
            label: "Mỹ Phước",
            value: 25813,
            slug: "my-phuoc",
          },
          {
            label: "Phú An",
            value: 25855,
            slug: "phu-an",
          },
        ],
        slug: "thi-xa-ben-cat",
      },
      {
        label: "Thành phố Tân Uyên",
        value: 723,
        ward: [
          {
            label: "Vĩnh Tân",
            value: 25912,
            slug: "vinh-tan",
          },
        ],
        slug: "thanh-pho-tan-uyen",
      },
      {
        label: "Thành phố Dĩ An",
        value: 724,
        ward: [
          {
            label: "Dĩ An",
            value: 25942,
            slug: "di-an",
          },
        ],
        slug: "thanh-pho-di-an",
      },
      {
        label: "Thành phố Thuận An",
        value: 725,
        ward: [
          {
            label: "Lái Thiêu",
            value: 25966,
            slug: "lai-thieu",
          },
          {
            label: "An Phú",
            value: 25975,
            slug: "an-phu",
          },
          {
            label: "Bình Hòa",
            value: 25987,
            slug: "binh-hoa",
          },
        ],
        slug: "thanh-pho-thuan-an",
      },
    ],
    slug: "binh-duong",
  },
  {
    label: "Đồng Nai",
    value: 75,
    district: [
      {
        label: "Thành phố Biên Hòa",
        value: 731,
        ward: [
          {
            label: "Tân Phong",
            value: 25996,
            slug: "tan-phong",
          },
          {
            label: "Tân Biên",
            value: 25999,
            slug: "tan-bien",
          },
          {
            label: "Tân Hiệp",
            value: 26008,
            slug: "tan-hiep",
          },
          {
            label: "Tân Mai",
            value: 26014,
            slug: "tan-mai",
          },
          {
            label: "Tam Hiệp",
            value: 26017,
            slug: "tam-hiep",
          },
          {
            label: "Quang Vinh",
            value: 26023,
            slug: "quang-vinh",
          },
          {
            label: "Thống Nhất",
            value: 26029,
            slug: "thong-nhat",
          },
          {
            label: "Bình Đa",
            value: 26047,
            slug: "binh-da",
          },
        ],
        slug: "thanh-pho-bien-hoa",
      },
      {
        label: "Thành phố Long Khánh",
        value: 732,
        ward: [
          {
            label: "Xuân An",
            value: 26080,
            slug: "xuan-an",
          },
        ],
        slug: "thanh-pho-long-khanh",
      },
      {
        label: "Huyện Tân Phú",
        value: 734,
        ward: [
          {
            label: "Tân Phú",
            value: 26116,
            slug: "tan-phu",
          },
        ],
        slug: "huyen-tan-phu",
      },
      {
        label: "Huyện Định Quán",
        value: 736,
        ward: [
          {
            label: "Định Quán",
            value: 26206,
            slug: "dinh-quan",
          },
          {
            label: "Phú Lợi",
            value: 26218,
            slug: "phu-loi",
          },
        ],
        slug: "huyen-dinh-quan",
      },
      {
        label: "Huyện Trảng Bom",
        value: 737,
        ward: [
          {
            label: "Bắc Sơn",
            value: 26269,
            slug: "bac-son",
          },
          {
            label: "Hố Nai 3",
            value: 26272,
            slug: "ho-nai-3",
          },
        ],
        slug: "huyen-trang-bom",
      },
      {
        label: "Huyện Thống Nhất",
        value: 738,
        ward: [
          {
            label: "Gia Tân 3",
            value: 26305,
            slug: "gia-tan-3",
          },
          {
            label: "Gia Kiệm",
            value: 26308,
            slug: "gia-kiem",
          },
        ],
        slug: "huyen-thong-nhat",
      },
      {
        label: "Huyện Long Thành",
        value: 740,
        ward: [
          {
            label: "Long Thành",
            value: 26368,
            slug: "long-thanh",
          },
        ],
        slug: "huyen-long-thanh",
      },
      {
        label: "Huyện Nhơn Trạch",
        value: 742,
        ward: [
          {
            label: "Đại Phước",
            value: 26476,
            slug: "dai-phuoc",
          },
          {
            label: "Phú Thạnh",
            value: 26488,
            slug: "phu-thanh",
          },
          {
            label: "Long Thọ",
            value: 26494,
            slug: "long-tho",
          },
        ],
        slug: "huyen-nhon-trach",
      },
    ],
    slug: "dong-nai",
  },
  {
    label: "Hồ Chí Minh",
    value: 79,
    district: [
      {
        label: "Quận 1",
        value: 760,
        ward: [
          {
            label: "Tân Định",
            value: 26734,
            slug: "tan-dinh",
          },
          {
            label: "Đa Kao",
            value: 26737,
            slug: "da-kao",
          },
          {
            label: "Bến Thành",
            value: 26743,
            slug: "ben-thanh",
          },
          {
            label: "Nguyễn Thái Bình",
            value: 26746,
            slug: "nguyen-thai-binh",
          },
          {
            label: "Phạm Ngũ Lão",
            value: 26749,
            slug: "pham-ngu-lao",
          },
          {
            label: "Cầu Ông Lãnh",
            value: 26752,
            slug: "cau-ong-lanh",
          },
          {
            label: "Nguyễn Cư Trinh",
            value: 26758,
            slug: "nguyen-cu-trinh",
          },
        ],
        slug: "quan-1",
      },
      {
        label: "Quận 12",
        value: 761,
        ward: [
          {
            label: "Tân Chánh Hiệp",
            value: 26776,
            slug: "tan-chanh-hiep",
          },
          {
            label: "Tân Thới Nhất",
            value: 26791,
            slug: "tan-thoi-nhat",
          },
        ],
        slug: "quan-12",
      },
      {
        label: "Gò Vấp",
        value: 764,
        ward: [
          {
            label: "Phường 15",
            value: 26872,
            slug: "phuong-15",
          },
          {
            label: "Phường 12",
            value: 26881,
            slug: "phuong-12",
          },
          {
            label: "Phường 5",
            value: 26887,
            slug: "phuong-5",
          },
          {
            label: "Phường 1",
            value: 26890,
            slug: "phuong-1",
          },
        ],
        slug: "go-vap",
      },
      {
        label: "Bình Thạnh",
        value: 765,
        ward: [
          {
            label: "Phường 25",
            value: 26920,
            slug: "phuong-25",
          },
          {
            label: "Phường 7",
            value: 26926,
            slug: "phuong-7",
          },
        ],
        slug: "binh-thanh",
      },
      {
        label: "Tân Bình",
        value: 766,
        ward: [
          {
            label: "Phường 2",
            value: 26965,
            slug: "phuong-2",
          },
          {
            label: "Phường 4",
            value: 26968,
            slug: "phuong-4",
          },
          {
            label: "Phường 12",
            value: 26971,
            slug: "phuong-12",
          },
          {
            label: "Phường 13",
            value: 26974,
            slug: "phuong-13",
          },
          {
            label: "Phường 1",
            value: 26977,
            slug: "phuong-1",
          },
          {
            label: "Phường 14",
            value: 27004,
            slug: "phuong-14",
          },
        ],
        slug: "tan-binh",
      },
      {
        label: "Tân Phú",
        value: 767,
        ward: [
          {
            label: "Tân Sơn Nhì",
            value: 27010,
            slug: "tan-son-nhi",
          },
          {
            label: "Tây Thạnh",
            value: 27013,
            slug: "tay-thanh",
          },
          {
            label: "Sơn Kỳ",
            value: 27016,
            slug: "son-ky",
          },
          {
            label: "Tân Quý",
            value: 27019,
            slug: "tan-quy",
          },
          {
            label: "Phú Thạnh",
            value: 27028,
            slug: "phu-thanh",
          },
        ],
        slug: "tan-phu",
      },
      {
        label: "Phú Nhuận",
        value: 768,
        ward: [
          {
            label: "Phường 1",
            value: 27058,
            slug: "phuong-1",
          },
          {
            label: "Phường 17",
            value: 27074,
            slug: "phuong-17",
          },
        ],
        slug: "phu-nhuan",
      },
      {
        label: "Thủ Đức",
        value: 769,
        ward: [
          {
            label: "Linh Xuân",
            value: 26794,
            slug: "linh-xuan",
          },
          {
            label: "Tam Phú",
            value: 26806,
            slug: "tam-phu",
          },
          {
            label: "Hiệp Bình Phước",
            value: 26809,
            slug: "hiep-binh-phuoc",
          },
          {
            label: "Linh Tây",
            value: 26818,
            slug: "linh-tay",
          },
          {
            label: "Linh Đông",
            value: 26821,
            slug: "linh-dong",
          },
          {
            label: "Bình Thọ",
            value: 26824,
            slug: "binh-tho",
          },
          {
            label: "Tân Phú",
            value: 26836,
            slug: "tan-phu",
          },
          {
            label: "Tăng Nhơn Phú A",
            value: 26842,
            slug: "tang-nhon-phu-a",
          },
          {
            label: "Phước Long B",
            value: 26848,
            slug: "phuoc-long-b",
          },
        ],
        slug: "thu-duc",
      },
      {
        label: "Quận 3",
        value: 770,
        ward: [
          {
            label: "Phường 14",
            value: 27127,
            slug: "phuong-14",
          },
          {
            label: "Võ Thị Sáu",
            value: 27139,
            slug: "vo-thi-sau",
          },
          {
            label: "Phường 9",
            value: 27142,
            slug: "phuong-9",
          },
          {
            label: "Phường 4",
            value: 27148,
            slug: "phuong-4",
          },
          {
            label: "Phường 5",
            value: 27151,
            slug: "phuong-5",
          },
          {
            label: "Phường 3",
            value: 27154,
            slug: "phuong-3",
          },
          {
            label: "Phường 2",
            value: 27157,
            slug: "phuong-2",
          },
          {
            label: "Phường 1",
            value: 27160,
            slug: "phuong-1",
          },
        ],
        slug: "quan-3",
      },
      {
        label: "Quận 10",
        value: 771,
        ward: [
          {
            label: "Phường 15",
            value: 27163,
            slug: "phuong-15",
          },
          {
            label: "Phường 12",
            value: 27172,
            slug: "phuong-12",
          },
          {
            label: "Phường 10",
            value: 27178,
            slug: "phuong-10",
          },
          {
            label: "Phường 8",
            value: 27187,
            slug: "phuong-8",
          },
          {
            label: "Phường 2",
            value: 27190,
            slug: "phuong-2",
          },
          {
            label: "Phường 4",
            value: 27193,
            slug: "phuong-4",
          },
          {
            label: "Phường 6",
            value: 27202,
            slug: "phuong-6",
          },
          {
            label: "Phường 5",
            value: 27203,
            slug: "phuong-5",
          },
        ],
        slug: "quan-10",
      },
      {
        label: "Quận 11",
        value: 772,
        ward: [
          {
            label: "Phường 8",
            value: 27229,
            slug: "phuong-8",
          },
        ],
        slug: "quan-11",
      },
      {
        label: "Quận 4",
        value: 773,
        ward: [
          {
            label: "Phường 9",
            value: 27265,
            slug: "phuong-9",
          },
          {
            label: "Phường 4",
            value: 27283,
            slug: "phuong-4",
          },
          {
            label: "Phường 3",
            value: 27286,
            slug: "phuong-3",
          },
          {
            label: "Phường 2",
            value: 27292,
            slug: "phuong-2",
          },
        ],
        slug: "quan-4",
      },
      {
        label: "Quận 5",
        value: 774,
        ward: [
          {
            label: "Phường 9",
            value: 27304,
            slug: "phuong-9",
          },
          {
            label: "Phường 12",
            value: 27310,
            slug: "phuong-12",
          },
          {
            label: "Phường 7",
            value: 27316,
            slug: "phuong-7",
          },
          {
            label: "Phường 1",
            value: 27325,
            slug: "phuong-1",
          },
          {
            label: "Phường 5",
            value: 27334,
            slug: "phuong-5",
          },
        ],
        slug: "quan-5",
      },
      {
        label: "Quận 6",
        value: 775,
        ward: [
          {
            label: "Phường 2",
            value: 27361,
            slug: "phuong-2",
          },
          {
            label: "Phường 11",
            value: 27364,
            slug: "phuong-11",
          },
          {
            label: "Phường 1",
            value: 27379,
            slug: "phuong-1",
          },
        ],
        slug: "quan-6",
      },
      {
        label: "Quận 8",
        value: 776,
        ward: [
          {
            label: "Rạch Ông",
            value: 27397,
            slug: "rach-ong",
          },
          {
            label: "Xóm Củi",
            value: 27415,
            slug: "xom-cui",
          },
          {
            label: "Phường 5",
            value: 27418,
            slug: "phuong-5",
          },
        ],
        slug: "quan-8",
      },
      {
        label: "Bình Tân",
        value: 777,
        ward: [
          {
            label: "Bình Trị Đông",
            value: 27445,
            slug: "binh-tri-dong",
          },
          {
            label: "Tân Tạo",
            value: 27454,
            slug: "tan-tao",
          },
          {
            label: "An Lạc",
            value: 27460,
            slug: "an-lac",
          },
        ],
        slug: "binh-tan",
      },
      {
        label: "Quận 7",
        value: 778,
        ward: [
          {
            label: "Tân Hưng",
            value: 27475,
            slug: "tan-hung",
          },
          {
            label: "Bình Thuận",
            value: 27478,
            slug: "binh-thuan",
          },
          {
            label: "Tân Phú",
            value: 27487,
            slug: "tan-phu",
          },
          {
            label: "Phú Mỹ",
            value: 27493,
            slug: "phu-my",
          },
        ],
        slug: "quan-7",
      },
      {
        label: "Bình Chánh",
        value: 785,
        ward: [
          {
            label: "Bình Hưng",
            value: 27619,
            slug: "binh-hung",
          },
        ],
        slug: "binh-chanh",
      },
      {
        label: "Nhà Bè",
        value: 786,
        ward: [
          {
            label: "Phước Kiển",
            value: 27646,
            slug: "phuoc-kien",
          },
        ],
        slug: "nha-be",
      },
    ],
    slug: "ho-chi-minh",
  },
  {
    label: "Long An",
    value: 80,
    district: [
      {
        label: "Huyện Bến Lức",
        value: 803,
        ward: [
          {
            label: "Bến Lức",
            value: 27991,
            slug: "ben-luc",
          },
        ],
        slug: "huyen-ben-luc",
      },
    ],
    slug: "long-an",
  },
  {
    label: "Tiền Giang",
    value: 82,
    district: [
      {
        label: "Thành phố Mỹ Tho",
        value: 815,
        ward: [
          {
            label: "Phường 4",
            value: 28252,
            slug: "phuong-4",
          },
        ],
        slug: "thanh-pho-my-tho",
      },
      {
        label: "Huyện Châu Thành",
        value: 821,
        ward: [
          {
            label: "Tân Hiệp",
            value: 28519,
            slug: "tan-hiep",
          },
        ],
        slug: "huyen-chau-thanh",
      },
    ],
    slug: "tien-giang",
  },
  {
    label: "Bến Tre",
    value: 83,
    district: [
      {
        label: "Thành phố Bến Tre",
        value: 829,
        ward: [
          {
            label: "Phú Tân",
            value: 28757,
            slug: "phu-tan",
          },
          {
            label: "An Hội",
            value: 28777,
            slug: "an-hoi",
          },
        ],
        slug: "thanh-pho-ben-tre",
      },
      {
        label: "Huyện Châu Thành",
        value: 831,
        ward: [
          {
            label: "Qưới Sơn",
            value: 28807,
            slug: "quoi-son",
          },
        ],
        slug: "huyen-chau-thanh",
      },
    ],
    slug: "ben-tre",
  },
  {
    label: "Trà Vinh",
    value: 84,
    district: [
      {
        label: "Thành phố Trà Vinh",
        value: 842,
        ward: [
          {
            label: "Phường 7",
            value: 29254,
            slug: "phuong-7",
          },
        ],
        slug: "thanh-pho-tra-vinh",
      },
    ],
    slug: "tra-vinh",
  },
  {
    label: "Vĩnh Long",
    value: 86,
    district: [
      {
        label: "Huyện Long Hồ",
        value: 857,
        ward: [
          {
            label: "Thanh Đức",
            value: 29590,
            slug: "thanh-duc",
          },
        ],
        slug: "huyen-long-ho",
      },
      {
        label: "Thị xã Bình Minh",
        value: 861,
        ward: [
          {
            label: "Thuận An",
            value: 29806,
            slug: "thuan-an",
          },
        ],
        slug: "thi-xa-binh-minh",
      },
      {
        label: "Huyện Trà Ôn",
        value: 862,
        ward: [
          {
            label: "Trà Ôn",
            value: 29821,
            slug: "tra-on",
          },
        ],
        slug: "huyen-tra-on",
      },
    ],
    slug: "vinh-long",
  },
  {
    label: "Kiên Giang",
    value: 91,
    district: [
      {
        label: "Thành phố Rạch Giá",
        value: 899,
        ward: [
          {
            label: "Vĩnh Thanh",
            value: 30733,
            slug: "vinh-thanh",
          },
          {
            label: "Vĩnh Lạc",
            value: 30745,
            slug: "vinh-lac",
          },
          {
            label: "Vĩnh Lợi",
            value: 30757,
            slug: "vinh-loi",
          },
        ],
        slug: "thanh-pho-rach-gia",
      },
      {
        label: "Thành phố Hà Tiên",
        value: 900,
        ward: [
          {
            label: "Pháo Đài",
            value: 30775,
            slug: "phao-dai",
          },
        ],
        slug: "thanh-pho-ha-tien",
      },
      {
        label: "Huyện Kiên Lương",
        value: 902,
        ward: [
          {
            label: "Kiên Lương",
            value: 30787,
            slug: "kien-luong",
          },
        ],
        slug: "huyen-kien-luong",
      },
    ],
    slug: "kien-giang",
  },
  {
    label: "Cần Thơ",
    value: 92,
    district: [
      {
        label: "Quận Ninh Kiều",
        value: 916,
        ward: [
          {
            label: "An Hòa",
            value: 31120,
            slug: "an-hoa",
          },
          {
            label: "Thới Bình",
            value: 31123,
            slug: "thoi-binh",
          },
        ],
        slug: "quan-ninh-kieu",
      },
      {
        label: "Quận Cái Răng",
        value: 919,
        ward: [
          {
            label: "Phú Thứ",
            value: 31201,
            slug: "phu-thu",
          },
        ],
        slug: "quan-cai-rang",
      },
    ],
    slug: "can-tho",
  },
  {
    label: "Hậu Giang",
    value: 93,
    district: [
      {
        label: "Thành phố Vị Thanh",
        value: 930,
        ward: [
          {
            label: "Phường V",
            value: 31327,
            slug: "phuong-v",
          },
        ],
        slug: "thanh-pho-vi-thanh",
      },
      {
        label: "Huyện Long Mỹ",
        value: 936,
        ward: [
          {
            label: "Thuận Hưng",
            value: 31483,
            slug: "thuan-hung",
          },
        ],
        slug: "huyen-long-my",
      },
    ],
    slug: "hau-giang",
  },
  {
    label: "Sóc Trăng",
    value: 94,
    district: [
      {
        label: "Huyện Long Phú",
        value: 946,
        ward: [
          {
            label: "Long Phú",
            value: 31639,
            slug: "long-phu",
          },
        ],
        slug: "huyen-long-phu",
      },
    ],
    slug: "soc-trang",
  },
  {
    label: "Bạc Liêu",
    value: 95,
    district: [
      {
        label: "Thành phố Bạc Liêu",
        value: 954,
        ward: [
          {
            label: "Phường 3",
            value: 31816,
            slug: "phuong-3",
          },
          {
            label: "Phường 7",
            value: 31822,
            slug: "phuong-7",
          },
        ],
        slug: "thanh-pho-bac-lieu",
      },
    ],
    slug: "bac-lieu",
  },
  {
    label: "Cà Mau",
    value: 96,
    district: [
      {
        label: "Thành phố Cà Mau",
        value: 964,
        ward: [
          {
            label: "Phường 8",
            value: 32014,
            slug: "phuong-8",
          },
        ],
        slug: "thanh-pho-ca-mau",
      },
      {
        label: "Huyện Cái Nước",
        value: 969,
        ward: [
          {
            label: "Cái Nước",
            value: 32128,
            slug: "cai-nuoc",
          },
        ],
        slug: "huyen-cai-nuoc",
      },
    ],
    slug: "ca-mau",
  },
];
